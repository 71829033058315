import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {Link} from "react-router-dom";
import ChillButton from "../../../shared/ChillButton";
import {RadioButton, RadioButtonGroup} from "material-ui/RadioButton";
import {REGULAR_ORDER, DSOD_ORDER} from "../../../../constants/order";

const labelStyle = {
  color: "#0c5271",
  fontSize: 14,
  fontWeight: "bold",
  width: "calc(100% - 3px)",
};

class OrderStatus extends Component {
  constructor(props) {
    super(props);

    if (props.order) {
      const {order: {orderStatusId}} = props;
      this.state = {orderStatusId};
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.order) {
      const {order: {orderStatusId}} = nextProps;
      this.setState({orderStatusId});
    }
  }

  _handleChangeStatus = (event, selectedOption) => {
    event.preventDefault();
    this.setState({orderStatusId: selectedOption});
  };

  _handleUpdate = () => {
    this.props.onUpdateOrderStatus(this.state.orderStatusId);
    this.props.onClose();
  };

  _getOrderStatusOptions() {
    const {orderStatus, isDSOD, order} = this.props;
    const nextStatus = _get(order, "stateMachine.nextStates", []);
    return (
      orderStatus &&
      orderStatus
        .filter(
          ({orderType}) => orderType === (isDSOD ? DSOD_ORDER : REGULAR_ORDER)
        )
        .map(status => {
          const disabled = !isDSOD && !nextStatus.includes(status.code);
          return (
            <RadioButton
              disabled={disabled}
              value={status.id}
              key={status.id}
              label={status.name}
              labelStyle={labelStyle}
              style={{paddingTop: 4, paddingBotoom: 4}}
            />
          );
        })
    );
  }

  render() {
    const {onClose} = this.props;
    return (
      <div className="flex flex-center flex-column">
        <h4 className="page-title" style={{fontSize: 24, padding: "18px 0"}}>
          Update Order Status
        </h4>
        <RadioButtonGroup
          valueSelected={`${this.state.orderStatusId}`}
          onChange={this._handleChangeStatus}
          name={""}
        >
          {this._getOrderStatusOptions()}
        </RadioButtonGroup>
        <ChillButton
          className="btn--formCardHeader"
          name="Save"
          height={40}
          width={337}
          marginTop={32}
          marginLeft={0}
          icon={"check"}
          onClick={this._handleUpdate}
        />
        <Link
          to={""}
          onClick={onClose}
          style={{marginTop: 24}}
          className="text-cancel"
        >
          Cancel
        </Link>
      </div>
    );
  }
}

OrderStatus.propTypes = {
  orderStatus: PropTypes.array,
  onClose: PropTypes.func,
  order: PropTypes.object,
  onUpdateOrderStatus: PropTypes.func,
  isDSOD: PropTypes.bool,
};

export default OrderStatus;
