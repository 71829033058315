import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QuoteStorageService from "../../../../../services/QuoteStorageService";
import QuoteInfo from "./QuoteInfo";
import {
  createQuote,
  fetchQuoteItemPriceTable,
  fetchQuoteItemPrice,
  uploadArtFile,
} from "../../../../../actions/v2/quoteActions";
import {fetchCountries} from "../../../../../actions/geoActions";
import {fetchProductCategories} from "../../../../../actions/v2/productCategoriesActions";
import {fetchProducts} from "../../../../../actions/productActions";
import {fetchFabrics} from "../../../../../actions/fabricActions";
import {fetchColors} from "../../../../../actions/colorActions";
import {fetchBagTypes} from "../../../../../actions/v2/bagTypesActions";
import {fetchBagCapacities} from "../../../../../actions/v2/bagCapacitiesActions";
import {fetchAdornmentLocationsByProduct} from "../../../../../actions/adornmentLocationActions";
import {openNotification} from "../../../../../actions/notificationActions";
import {checkAuth, fetchUserEmailValidationByCompany, logout} from "../../../../../actions/authActions";
import {fetchArtRevisionsByCompany} from "../../../../../actions/artActions";

function mapStateToProps(state) {
  const {
    quotes: {quote, isLoading, itemPrices, isLoadingPrices, isLoadingArtFile},
    geo: {countries},
    users:{
      userEmailValidationByCompany
    },
    productCategories: {categories, isLoading: isLoadingCategories},
    products: {products, isLoading: isLoadingProducts},
    fabrics: {fabrics, isLoading: isLoadingFabrics},
    colors: {colors, isLoading: isLoadingColors},
    bagTypes: {types},
    bagCapacities: {capacities},
    adornmentLocations: {adornmentLocations: locations},
    auth: {
      isAuthenticated,
      selectedCompany,
      selectedCompanyName,
      user,
      isInternal,
      email,
    },
    art: {artRevisions},
  } = state;

  return {
    quote,
    isAuthenticated,
    isLoadingArtFile,
    user,
    email,
    isInternal,
    companyId: selectedCompany,
    companyName: selectedCompanyName,
    isLoading,
    itemPrices,
    isLoadingPrices,
    countries,
    categories,
    products,
    fabrics,
    colors,
    types,
    capacities,
    locations,
    companyArts: artRevisions,
    isLoadingCategories,
    isLoadingProducts,
    isLoadingFabrics,
    isLoadingColors,
    userEmailValidationByCompany,
    store: new QuoteStorageService(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createQuote: bindActionCreators(createQuote, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchCategories: bindActionCreators(fetchProductCategories, dispatch),
    fetchProducts: bindActionCreators(fetchProducts, dispatch),
    fetchFabrics: bindActionCreators(fetchFabrics, dispatch),
    fetchColors: bindActionCreators(fetchColors, dispatch),
    fetchBagTypes: bindActionCreators(fetchBagTypes, dispatch),
    fetchBagCapacities: bindActionCreators(fetchBagCapacities, dispatch),
    fetchUserEmailValidationByCompany:bindActionCreators(fetchUserEmailValidationByCompany, dispatch),
    fetchAdornmentLocationsByProduct: bindActionCreators(
      fetchAdornmentLocationsByProduct,
      dispatch
    ),
    fetchItemPriceTable: bindActionCreators(fetchQuoteItemPriceTable, dispatch),
    fetchItemPrice: bindActionCreators(fetchQuoteItemPrice, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    checkAuth: bindActionCreators(checkAuth, dispatch),
    fetchArtRevisionsByCompany: bindActionCreators(
      fetchArtRevisionsByCompany,
      dispatch
    ),
    logout: bindActionCreators(logout, dispatch),
    uploadArtFile: bindActionCreators(uploadArtFile, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteInfo)
);
