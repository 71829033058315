import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function artReducer(
  state = initialState.notification,
  {type, notification, payload, error}
) {
  switch (type) {
  case actions.OPEN_ERROR_MESSAGE:
    return {
      ...state,
      show: true,
      message: payload ? payload.error : error,
    };
  case actions.OPEN_NOTIFICATION:
    return {
      ...state,
      ...notification,
    };

  case actions.CLOSE_NOTIFICATION:
    return {
      ...state,
      show: false,
    };
  default:
    return state;
  }
}
