import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Footer extends Component {
  static propTypes = {
    styles: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    let styles = this.props.styles ? this.props.styles : "";
    const currentYear = new Date().getFullYear();
    return (
      <footer
        className={`footer footer--auth ${styles}`}
        style={this.props.style}
      >
        <p className="small-text ">Copyright 2017 - {currentYear} by chill-n-go, Inc</p>
      </footer>
    );
  }
}
