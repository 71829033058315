export const loadingMessages = [
  {
    messageId: 1,
    isCurrentUser: false,
    sender: {nickname: "Chill N Go"},
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  },
  {
    messageId: 2,
    isCurrentUser: true,
    sender: {nickname: "User Name"},
    message: "Nullam consequat mi eros, eget mattis augue ullamcorper sed",
  },
  {
    messageId: 3,
    isCurrentUser: false,
    sender: {nickname: "Chill N Go"},
    message:
      "Suspendisse vestibulum bibendum dolor, vitae convallis velit viverra eget",
  },
  {
    messageId: 4,
    isCurrentUser: true,
    sender: {nickname: "User Name"},
    message: "Duis ac lectus fringilla, efficitur lectus quis, iaculis purus",
  },
  {
    messageId: 5,
    isCurrentUser: true,
    sender: {nickname: "User Name"},
    message: "Aliquam at erat ut lorem gravida ornare a nec nibh",
  },
  {
    messageId: 6,
    isCurrentUser: false,
    sender: {nickname: "Chill N Go"},
    message: "Fusce rhoncus felis sed libero pharetra sollicitudin",
  },
];
