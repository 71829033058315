import React, {Component} from "react";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import CNGMenuItem from "../shared/CNGMenuItem";
import ArrowDropRight from "material-ui/svg-icons/navigation-arrow-drop-right";
import * as PropTypes from "prop-types";

export default class RecipientMoreAction extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    recipient: PropTypes.object,
    element: PropTypes.object,
    disabledMove: PropTypes.bool,
    disabledCopy: PropTypes.bool,
    showEdit: PropTypes.bool,
    moveItems: PropTypes.array,
    copyItems: PropTypes.array,
  };

  static defaultProps = {
    showEdit: true,
  };

  state = {};

  render() {
    const {
      element,
      disabledMove,
      disabledCopy,
      moveItems,
      copyItems,
      showEdit,
      onEdit,
      onDelete,
    } = this.props;
    return (
      <IconMenu
        iconButtonElement={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        anchorOrigin={{horizontal: "left", vertical: "top"}}
        targetOrigin={{horizontal: "left", vertical: "top"}}
      >
        {copyItems ? (
          <CNGMenuItem
            primaryText="Copy to"
            rightIcon={<ArrowDropRight />}
            disabled={disabledCopy}
            style={MenuItemStyle}
            menuItems={copyItems}
          />
        ) : null}

        {moveItems ? (
          <CNGMenuItem
            primaryText="Move to"
            rightIcon={<ArrowDropRight />}
            disabled={disabledMove}
            style={MenuItemStyle}
            menuItems={moveItems}
          />
        ) : null}

        {showEdit ? (
          <CNGMenuItem
            primaryText="Edit"
            style={MenuItemStyle}
            onClick={() => onEdit(element)}
          />
        ) : null}

        <CNGMenuItem
          primaryText="Delete"
          style={MenuItemStyle}
          onClick={() => onDelete(element)}
        />
      </IconMenu>
    );
  }
}

const MenuItemStyle = {
  color: "#747D86",
  fontSize: 14,
};
