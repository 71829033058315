import React, {Component} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";

import ArchiveModal from "../../../shared/ArchiveModal";
import ListCommaLinks from "../../../shared/ListCommaLinks";
import ChillButton from "../../../shared/ChillButton";
import PageTitle from "../../../shared/PageTitle";
import SectionHeading from "../../../shared/SectionHeading";

export default class UserDetailCardHeader extends Component {
  static propTypes = {
    userDetail: PropTypes.object.isRequired,
    archiveUser: PropTypes.func.isRequired,
    isInternal: PropTypes.bool,
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    useCompanyBreadcrumbs: PropTypes.bool,
  };

  _renderLeft() {
    const {userDetail, isInternal} = this.props;
    return (
      <div>
        <PageTitle text={userDetail ? userDetail.name : ""} />
        <SectionHeading text={"COMPANIES"} marginTop={16} />
        <div>
          {userDetail.companies &&
            userDetail.companies.map((company, index) => {
              return (
                <ListCommaLinks
                  key={index}
                  index={index}
                  disabled={!isInternal}
                  linkCount={userDetail.companies.length}
                  text={company.name}
                  url={`/companies/${company.id}`}
                  className="link-header"
                />
              );
            })}
        </div>
      </div>
    );
  }
  _renderRight() {
    const {
      userDetail,
      isInternal,
      companyId,
      useCompanyBreadcrumbs,
    } = this.props;
    return (
      <div className="column-xs middle-xs">
        <Link
          to={
            useCompanyBreadcrumbs || !isInternal
              ? `/companies/${companyId}/users/${userDetail.id}/edit`
              : `/users/${userDetail.id}/edit`
          }
          className="reset-link-styles"
        >
          <ChillButton
            className="userDetail_editbtn"
            name={"EDIT USER"}
            height={32}
            width={110}
            icon={"edit"}
          />
        </Link>

        {isInternal ? (
          <ArchiveModal
            archiveUser={id => this.props.archiveUser(id)}
            user={this.props.userDetail}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const {isInternal} = this.props;
    return (
      <div
        className={
          isInternal ? "row between-xs middle-xs" : "row between-xs top-xs"
        }
      >
        {this._renderLeft()}
        {this._renderRight()}
      </div>
    );
  }
}
