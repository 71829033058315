import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import lodash from "lodash";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";
import ArtRevisionDetails from "./../shared/ArtRevisionDetails";

const blankStyle = {
  height: 66,
  width: "100%",
  borderRadius: 4,
  backgroundColor: "rgba(189,198,207,0.07)",
  color: "#BDC6CF",
  textAlign: "center",
  lineHeight: "66px",
  fontSize: 16,
};

export default class ArtHistoryList extends Component {
  static propTypes = {
    revisions: PropTypes.array.isRequired,
    brand: PropTypes.object,
    isLoading: PropTypes.bool,
  };
  _renderRow(revision, index) {
    const {adornmentType} = revision;
    const isLoading = this.props.isLoading;
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: "15%",
          }}
        >
          <Link
            to={`/brands/${lodash.get(this.props, "brand.id")}/arts/${
              revision.artId
            }/revisions/${revision.id}`}
            className={`no-link-styles ${isLoading ? "disabled-link" : null}`}
            style={{
              color: "#0c5371",
            }}
          >
            {revision.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "25%",
          }}
        >
          {adornmentType && adornmentType.name}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "60%",
          }}
        >
          <ArtRevisionDetails artRevision={revision} />
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderEmpty() {
    return <div style={blankStyle}>No Art Versions</div>;
  }

  _renderRevisions() {
    const {revisions} = this.props;
    return revisions.map((revision, index) => {
      return this._renderRow(revision, index);
    });
  }

  render() {
    const header = [
      {
        columnContent: "Name",
        customStyles: {
          width: "15%",
        },
      },
      {
        columnContent: "Adornment Type",
        customStyles: {
          width: "25%",
        },
      },
      {
        columnContent: "Details",
        customStyles: {
          width: "60%",
        },
      },
    ];
    const {revisions} = this.props;
    return (
      <CNGTable tableHeader={header} displayRowCheckbox={false}>
        {revisions && !revisions.length
          ? this._renderEmpty()
          : this._renderRevisions()}
      </CNGTable>
    );
  }
}
