import React, {Component} from "react";
import PropTypes from "prop-types";
import ContentLoader from "../ContentLoader";
import {Table, TableHeader} from "material-ui/Table";

import CNGTableHeader from "./CNGTableHeader";

export default class CNGTable extends Component {
  _renderTableHeader(tableHeader) {
    if (this.props.customTableHeader) {
      return this.props.customTableHeader();
    } else if (tableHeader) {
      return (
        <TableHeader
          displaySelectAll={true}
          adjustForCheckbox={this.props.displayRowCheckbox}
          displayBorder={false}
          style={{borderWidth: "0", height: 16, lineHeight: "16px"}}
        >
          <CNGTableHeader
            tableHeader={tableHeader}
            displayRowCheckbox={this.props.displayRowCheckbox}
          />
        </TableHeader>
      );
    }
  }
  render() {
    const {
      tableHeader,
      children,
      tableStyle,
      className,
      isLoading,
      tableBodyStyle,
    } = this.props;
    return (
      <ContentLoader
        zIndex={1}
        isLoading={isLoading}
        customContainerStyles={{
          minHeight: "133px",
          top: "34px",
        }}
      >
        <div
          style={{
            minHeight: isLoading ? "133px" : 0,
          }}
        >
          <Table
            style={{...tableStyles, ...tableStyle}}
            bodyStyle={tableBodyStyle}
            className={className}
          >
            {this._renderTableHeader(tableHeader)}
            {children}
          </Table>
        </div>
      </ContentLoader>
    );
  }
}

CNGTable.propTypes = {
  customTableHeader: PropTypes.func,
  displayRowCheckbox: PropTypes.bool,
  tableHeader: PropTypes.array,
  tableStyle: PropTypes.object,
  tableBodyStyle: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

const tableStyles = {
  borderCollapse: "separate",
  borderSpacing: "0 8px",
};
