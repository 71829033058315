import React from "react";
import {Container, Row, Col, W100} from "fluid-react";
import Typography from "../shared/Typography";
import RaisedButton from "../shared/RaisedButton";
import FontIcon from "material-ui/FontIcon";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import {Tabs, Tab} from "material-ui/Tabs";
import withStyles from "../shared/WithStyles";
import PropTypes from "prop-types";

const pageStyle = {
  rowContainer: {
    marginTop: 84,
    maxWidth: "1116px",
  },
  block: {
    marginBottom: 24,
  },
  marginTitle: {
    marginBottom: 26,
  },
  marginMedium: {
    marginBottom: 18,
  },
  marginSmall: {
    marginBottom: 8,
  },
  colorBox: {
    width: 166,
    height: 166,
    color: "white",
  },
};

const StyleGuides = ({styles}) => {
  window.closeSplash();
  return (
    <Container className="flex-center version--2">
      <Row style={pageStyle.rowContainer}>
        <Col lg="6">
          <div style={pageStyle.block}>
            <Typography variant="h1" style={pageStyle.marginTitle}>
              H1 Roboto Light 36pt
            </Typography>
            <Typography variant="h2" style={pageStyle.marginMedium}>
              H2 Roboto Light 30pt
            </Typography>
            <Typography variant="h3" style={pageStyle.marginMedium}>
              H3 Roboto Regular 24pt
            </Typography>
            <Typography variant="h4">H4 Roboto Regulat 20pt</Typography>
            <Typography variant="h5">H5 Roboto Regulat 16pt</Typography>
          </div>

          <div style={pageStyle.block}>
            <Typography variant="body" style={pageStyle.marginSmall}>
              Body-Roboto Regular 14pt
            </Typography>
            <Typography variant="bodyMedium" style={pageStyle.marginSmall}>
              Body-Roboto Medium 14pt
            </Typography>
            <Typography variant="bodyBold" style={pageStyle.marginSmall}>
              Body-Roboto Bold 14pt
            </Typography>
          </div>

          <div style={pageStyle.block}>
            <Typography variant="subtitle" style={pageStyle.marginSmall}>
              Caption-Roboto Regular 12pt
            </Typography>
            <Typography variant="subtitleBold" style={pageStyle.marginSmall}>
              Caption-Roboto Bold 12pt
            </Typography>
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              style={pageStyle.marginSmall}
            >
              Caption-Roboto Bold-All Caps 12pt
            </Typography>
          </div>

          <div style={pageStyle.block}>
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              color="secondary.light"
              style={pageStyle.marginSmall}
            >
              COLUMN HEADING (SORTABLE) - Roboto Bold 12pt
            </Typography>
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              color="gray.light"
              style={pageStyle.marginSmall}
            >
              FIELD LABEL Roboto Bold 12pt
            </Typography>
          </div>

          <div style={pageStyle.block}>
            <Tabs initialSelectedIndex={1} className="tabs">
              <Tab label="Item One" style={styles.tab}>
                <div />
              </Tab>
              <Tab label="Item Two" style={styles.tab}>
                <div />
              </Tab>
              <Tab label="onActive" data-route="/home" style={styles.tab}>
                <div />
              </Tab>
            </Tabs>
          </div>

          <div style={pageStyle.block}>
            <div>
              <Typography component="a" variant="link">
                Primary Link
              </Typography>
            </div>

            <div>
              <Typography
                component="a"
                variant="link"
                className="secondary-link-style"
              >
                Secondary Link
              </Typography>
            </div>
          </div>
          <Row>
            <Col>
              <Typography variant="subtitle">Primary</Typography>
              <RaisedButton
                label="Small Button"
                style={pageStyle.marginSmall}
              />
              <W100 />
              <RaisedButton
                label="Small Button"
                disabled
                style={pageStyle.marginSmall}
              />
              <W100 />
              <RaisedButton
                label="Medium Button"
                medium
                style={pageStyle.marginSmall}
              />
              <W100 />
              <RaisedButton
                label="Icon Button"
                icon={<FontIcon className="material-icons">edit</FontIcon>}
                style={pageStyle.marginSmall}
                medium
              />
              <W100 />
              <RaisedButton label="Large Button" large />
            </Col>
            <Col>
              <Typography variant="subtitle">Secondary</Typography>
              <RaisedButton
                label="Small Button"
                style={pageStyle.marginSmall}
                secondary
              />
              <W100 />
              <RaisedButton
                label="Small Button"
                style={pageStyle.marginSmall}
                secondary
                disabled
              />
              <W100 />
              <RaisedButton
                label="Medium Button"
                style={pageStyle.marginSmall}
                secondary
                medium
              />
              <W100 />
              <RaisedButton
                label="Large Button"
                style={pageStyle.marginSmall}
                secondary
                large
              />
            </Col>
          </Row>
          <Row style={pageStyle.block}>
            <TextField
              type="text"
              floatingLabelText="Floating label active"
              name="test"
              autoFocus
              value="Enter "
            />
            <TextField
              type="text"
              floatingLabelText="Floating label disabled"
              name="test"
              value="Disabled "
              className="margin-left-10"
              underlineDisabledStyle={styles.underlineDisabledStyle}
              disabled
            />
            <TextField
              type="text"
              floatingLabelText="Floating label error"
              name="test"
              value="Enter"
              errorText="Error Note"
            />
            <TextField
              type="text"
              floatingLabelText="Unfilled"
              name="test"
              className="margin-left-10"
            />
            <TextField
              type="text"
              floatingLabelText="Unfilled disabled"
              name="test"
              underlineDisabledStyle={styles.underlineDisabledStyle}
              disabled
            />

            <SelectField
              floatingLabelText="No selection"
              menuStyle={{borderRadius: "0px !important"}}
              iconStyle={styles.dropdownIcon}
              className="margin-left-10"
            >
              <MenuItem value={1} primaryText="Auto width" />
              <MenuItem value={2} primaryText="Every Night" />
              <MenuItem value={3} primaryText="Weeknights" />
              <MenuItem value={4} primaryText="Weekends" />
              <MenuItem value={5} primaryText="Weekly" />
            </SelectField>
            <SelectField
              floatingLabelText="Frequency"
              value={1}
              iconStyle={styles.dropdownIcon}
              menuStyle={{borderRadius: "0px !important"}}
            >
              <MenuItem value={1} primaryText="Auto width" />
              <MenuItem value={2} primaryText="Every Night" />
              <MenuItem value={3} primaryText="Weeknights" />
              <MenuItem value={4} primaryText="Weekends" />
              <MenuItem value={5} primaryText="Weekly" />
            </SelectField>
            <SelectField
              className="margin-left-10"
              floatingLabelText="Frequency"
              iconStyle={styles.dropdownIcon}
              disabled
              underlineDisabledStyle={styles.underlineDisabledStyle}
              menuStyle={{borderRadius: "0px !important"}}
            >
              <MenuItem value={5} primaryText="Weekly" />
            </SelectField>
          </Row>
        </Col>
        <Col lg="6">
          <Row style={pageStyle.marginMedium}>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.primary.main,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.primary.main}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.secondary.main,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.secondary.main}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.gray.main,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.gray.main}
              </Typography>
            </div>
          </Row>
          <Row style={pageStyle.marginMedium}>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.primary.dark,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.primary.dark}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.secondary.dark,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.secondary.dark}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.gray.dark,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.gray.dark}
              </Typography>
            </div>
          </Row>
          <Row style={pageStyle.marginMedium}>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.primary.light,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.primary.light}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.secondary.light,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.secondary.light}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.gray.light,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.gray.light}
              </Typography>
            </div>
          </Row>
          <Row>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.primary.lighter,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.primary.lighter}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.secondary.lighter,
                marginRight: 16,
              }}
            >
              <Typography variant="subtitleBold" color="white">
                {styles.colors.secondary.lighter}
              </Typography>
            </div>
            <div
              className="flex flex-center justify-content-center"
              style={{
                ...pageStyle.colorBox,
                backgroundColor: styles.colors.gray.lighter,
                color: styles.colors.gray.main,
              }}
            >
              <Typography variant="subtitleBold" color="black">
                {styles.colors.gray.lighter}
              </Typography>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

StyleGuides.propTypes = {
  styles: PropTypes.object,
};
export default withStyles(StyleGuides);
