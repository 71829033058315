import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import _get from "lodash/get";
import {Container, Row, Col} from "fluid-react";
import Snackbar from "material-ui/Snackbar";
import {fade} from "material-ui/utils/colorManipulator";
import FontIcon from "material-ui/FontIcon";
import Typography from "../../shared/Typography";
import withStyles from "../../shared/WithStyles";
import logoWhite from "../../../../assets/imgs/ChillNGo-white.png";
import Header from "../../../shared/Header/Header";

class QuoteLayout extends Component {
  componentDidMount() {
    window.closeSplash && window.closeSplash();
  }

  get pageStyle() {
    const {styles: {colors}} = this.props;
    return {
      sidebar: {
        borderRight: `1px solid ${colors.gray.border}`,
        // height: "calc(100vh - 117px)",
      },
      header: {
        backgroundColor: colors.primary.light,
        minHeight: 146,
        paddingTop: 46,
        paddingLeft: 31,
      },
      cardSection: {
        backgroundColor: fade(colors.gray.border, 0.2),
        borderBottom: `1px solid ${colors.gray.border}`,
        minHeight: 91,
        padding: "15px 7px 0 31px",
      },
      companyName: {
        marginBottom: 8,
        minHeight: 28,
      },
      logo: {
        width: 150,
        marginBottom: 11,
      },
      foldIcon: {
        fontSize: 24,
        marginLeft: 7,
        cursor: "pointer",
      },
      categoryContainer: {
        paddingLeft: 31,
        paddingTop: 16,
      },
      subHeader: {
        fontSize: 16,
        lineHeight: "40px",
        textAlign: "center",
        backgroundColor: "#195b77",
        height: 40,
        zIndex: 49,
        width: "100vw",
        color: "white",
      },
    };
  }

  renderSidebar = () => {
    const pageStyle = this.pageStyle;
    const {
      quote,
      onToggleQuoteDetails,
      styles,
      containerClass,
      companyName,
      sidebarComponent,
      title,
    } = this.props;
    return (
      <Col
        xs="0"
        sm="5"
        md="4"
        xl="3"
        style={pageStyle.sidebar}
        id="quote-sidebar"
      >
        <header style={pageStyle.header}>
          <img src={logoWhite} style={pageStyle.logo} />
          <Typography variant="h2" color="white">
            {title}
          </Typography>
        </header>
        <section>
          <div style={pageStyle.cardSection} className={containerClass}>
            <Typography
              variant="h4"
              color="black"
              style={pageStyle.companyName}
            >
              {companyName ? companyName : _get(quote, "companyName") || companyName}
            </Typography>
            {quote && (
              <div className="flex justify-content-between">
                <Typography>Items on Quote:</Typography>
                <div className="flex">
                  <Typography color="black">
                    ({quote.items && quote.items.length} Product{quote.items
                      .length !== 1 && "s"})
                  </Typography>
                  <div onClick={onToggleQuoteDetails}>
                    <FontIcon
                      hoverColor={styles.colors.primary.lighter}
                      className="material-icons"
                      style={pageStyle.foldIcon}
                    >
                      {quote.showQuoteDetails ? "unfold_less" : "unfold_more"}
                    </FontIcon>
                  </div>
                </div>
              </div>
            )}
          </div>
          {sidebarComponent ? (
            sidebarComponent
          ) : (
            <div style={pageStyle.categoryContainer}>
              <Typography
                variant="subtitleBold"
                color="gray.title"
                className="text-uppercase"
              >
                Category
              </Typography>
            </div>
          )}
        </section>
      </Col>
    );
  };

  render() {
    const pageStyle = this.pageStyle;
    const {
      subHeaderText,
      isLoading,
      notification,
      closeNotification,
      showSidebar,
      withTopMargin,
      showMainHeader,
      user,
      isInternal,
      logout,
      history,
      selectedCompany,
      styles: {colors},
    } = this.props;

    return (
      <Container
        className="version--2"
        style={{
          marginTop: withTopMargin && subHeaderText && 72,
          backgroundColor: !showSidebar && colors.gray.lighter,
        }}
      >
        <ReactCSSTransitionGroup
          transitionName="loader"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          transitionEnter={false}
          transitionLeave={false}
          transitionAppear={true}
          transitionAppearTimeout={500}
          component="div"
          aria-busy={isLoading}
        >
          <div key="loader-children-container">
            <Snackbar
              open={notification.show}
              message={notification.message}
              autoHideDuration={notification.duration || 4000}
              onRequestClose={closeNotification}
              className="alert-window non-printable"
            />
            {showMainHeader ? (
              <Header
                user={user}
                isInternal={isInternal}
                logout={logout}
                history={history}
                selectedCompany={selectedCompany}
                isLoading={isLoading}
                style={{position: "relative"}}
              />
            ) : null}
            {subHeaderText ? (
              <div id="sub-header" style={pageStyle.subHeader}>
                {subHeaderText}
              </div>
            ) : null}
            <Row>
              {showSidebar && this.renderSidebar()}
              <Col>{this.props.children}</Col>
            </Row>
          </div>
        </ReactCSSTransitionGroup>
      </Container>
    );
  }
}

QuoteLayout.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node,
  quote: PropTypes.object,
  onToggleQuoteDetails: PropTypes.func,
  containerClass: PropTypes.object,
  companyName: PropTypes.string,
  sidebarComponent: PropTypes.node,
  subHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  notification: PropTypes.object,
  closeNotification: PropTypes.func,
  showSidebar: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  showMainHeader: PropTypes.bool,
  selectedCompany: PropTypes.string,
  history: PropTypes.object,
  user: PropTypes.string,
  isInternal: PropTypes.bool,
  logout: PropTypes.func,
};

QuoteLayout.defaultProps = {
  notification: {show: false, message: ""},
  showSidebar: true,
  withTopMargin: true,
  title: "Request a Quote",
  showMainHeader: false,
};

export default withStyles(withRouter(QuoteLayout));
