import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import OrderDetailHeader from "./components/OrderDetailHeader";
import OrderItemsTable from "./components/OrderItemsTable";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import ConfirmationModal from "../../shared/ConfirmationModal";
import OrderSummary from "./components/OrderSummary";
import NotesForm from "../../shared/NotesForm";
import ContentLoader from "../../shared/ContentLoader";
import ChatSystem from "../../v2/shared/ChatSystem";
import FlatText from "../../shared/FlatText";
import { ORDER_DETAIL, BILLING_REPORT } from "../../../constants/order";

class OrderDetailPage extends Component {
  handlePrintOpen = () => {
    const {order} = this.props;
    window.open(
      `/companies/${order.companyId}/orders/${
        order.id
      }/print?billingReport=true`,
      "_blank"
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      openConfirmedOrder: this.isAOpenConfirmedOrder(),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({
        openConfirmedOrder: this.isAOpenConfirmedOrder(),
      });
    }
  }

  isAOpenConfirmedOrder = () => {
    const {history: {location: {state}}} = this.props;
    return !!(state && state.reorderCreated);
  };

  renderOrderWarning = () => {
    const {order, isInternal} = this.props;
    const orderRequiresApproval = _get(order, "status.code") === "OPA";
    if (!orderRequiresApproval || isInternal) return;
    return (
      <FlatText>
        Upon shipment of your proof the APPROVE ORDER button becomes active. If
        you find anything amiss upon receipt/review, please use the messaging
        below, email or call - we&apos;ll work through it. If all is well,
        simply click the button.
      </FlatText>
    );
  };

  renderChat = () => {
    const {order, isInternal, currentUserId} = this.props;
    if (_get(order, "status.code") !== "OPA") return;
    if (!isInternal && order.createdUserId !== currentUserId) return;
    return (
      <ChatSystem
        type="order"
        typeData={order}
        typeIdName="id"
        userId={isInternal ? order.createdUserId : currentUserId}
      />
    );
  };

  render() {
    const {
      company,
      order,
      isInternal,
      isLoading,
      orderItems,
      onUpdateOrderStatus,
      onUpdateShipmentItem,
      onReorderItems,
      orderStatus,
      isReordering,
      reorderItems,
      onToggleReorder,
      onToggleReorderItem,
      shipments,
      countries,
      inventoryItems,
      history,
      updateShipment,
      isLoadingOrderItems,
      revertOrder,
      openOrderModal,
      match: {params: {company_id: companyId, id: orderId}},
      onHideOpenOrderModal,
      approveOrder,
    } = this.props;
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company && company.name}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          pageName={"Order Detail"}
        />

        <ContentLoader isLoading={isLoading || isLoadingOrderItems}>
          <div className="only-print-version billing-report-title">
            {isInternal ? BILLING_REPORT: ORDER_DETAIL}
          </div>
          {this.renderOrderWarning()}
          <OrderDetailHeader
            revertOrder={revertOrder}
            onPrintOpen={this.handlePrintOpen}
            onUpdateOrderStatus={onUpdateOrderStatus}
            onToggleReorder={onToggleReorder}
            onReorderItems={onReorderItems}
            order={order}
            isInternal={isInternal}
            isReordering={isReordering}
            orderStatus={orderStatus}
            shipments={shipments}
            reorderItems={reorderItems}
            history={history}
            companyId={companyId}
            company={company}
            orderId={orderId}
            orderRequiresApproval={_get(order, "status.code") === "OPA"}
            approveOrder={approveOrder}
          />

          <OrderItemsTable
            countries={countries}
            companyId={companyId}
            isInternal={isInternal}
            items={orderItems}
            isReordering={isReordering}
            reorderItems={reorderItems}
            shipments={shipments}
            onToggleReorderItem={onToggleReorderItem}
            onUpdateShipmentItem={onUpdateShipmentItem}
            updateShipment={updateShipment}
            inventoryItems={inventoryItems}
            order={order}
          />

          <OrderSummary
            order={order}
            shipments={shipments}
            countries={countries}
            orderItems={orderItems}
          />
          <NotesForm order={order} isInternal={isInternal} />
          {this.renderChat()}
        </ContentLoader>
        <ConfirmationModal
          title="The Order Has Been Created"
          content={
            isInternal
              ? "A confirmation email was sent to customer."
              : "Thank you!"
          }
          confirmText="GO TO DASHBOARD"
          cancelText="VIEW ORDER"
          open={this.state && this.state.openConfirmedOrder}
          onConfirm={() => {
            const {history} = this.props;
            history.push({state: {}, pathname: "/"});
          }}
          onDismiss={() => {
            const {history, location} = this.props;
            history.push({state: {}, pathname: location.pathname});
          }}
        />
        <ConfirmationModal
          open={openOrderModal}
          height={400}
          title="OOPS - we’re sorry!!"
          content={
            <React.Fragment>
              <span>
                Another {company && company.name} user has your Order cart open.
                We can open only one cart for you at a time. Please be patient
                and try again later.
              </span>
              <br />
              <br />
              <span>Thanks for your understanding!</span>
              <br />
              <br />
              <span>Team chill-n-go</span>
            </React.Fragment>
          }
          confirmText="OK"
          cancelText=" "
          onConfirm={() => onHideOpenOrderModal()}
          onDismiss={() => onHideOpenOrderModal()}
        />
      </PageLayout>
    );
  }
}

OrderDetailPage.propTypes = {
  isLoadingOrderItems: PropTypes.bool,
  onUpdateOrderStatus: PropTypes.func,
  onToggleReorder: PropTypes.func,
  onToggleReorderItem: PropTypes.func,
  onUpdateShipmentItem: PropTypes.func,
  updateShipment: PropTypes.func,
  onReorderItems: PropTypes.func,
  fetchInventoryItems: PropTypes.func,
  inventoryItems: PropTypes.array,
  company: PropTypes.object,
  order: PropTypes.object,
  orderItems: PropTypes.array,
  countries: PropTypes.array,
  shipments: PropTypes.array,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReordering: PropTypes.bool,
  reorderItems: PropTypes.array,
  orderStatus: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  revertOrder: PropTypes.func,
  openOrderModal: PropTypes.bool,
  onHideOpenOrderModal: PropTypes.func,
  currentUserId: PropTypes.number,
  approveOrder: PropTypes.func,
};

export default OrderDetailPage;
