import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function bagTypesReducer(
  state = initialState.bagTypes,
  {type, payload, error}
) {
  switch (type) {
  case actions.FETCH_BAG_TYPES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_BAG_TYPES_SUCCESS:
    return {
      ...state,
      types: payload,
      isLoading: false,
    };
  case actions.FETCH_BAG_TYPES_ERROR:
    return {
      ...state,
      error: error,
      error_message: error,
      isLoading: false,
    };
  default:
    return state;
  }
}
