import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";
import {Colors} from "../../../styles/theme/Colors";

const ChillNavLink = ({
  materialIcon,
  showBadge,
  badgeNumber,
  to,
  icon,
  exact,
  title,
  className,
  isActiveHandler,
}) => {
  const activeStyle = {
    backgroundColor: Colors.activeColor,
  };

  const navLinkProps = {
    title,
    exact,
    to,
    activeStyle,
    className: `header_nav_link ${className} position-relative`,
  };

  if (isActiveHandler) {
    navLinkProps.isActive = isActiveHandler;
  }

  return (
    <NavLink {...navLinkProps}>
      {materialIcon ? (
        <FontIcon
          className="material-icons-outlined header_nav_icon"
          color="white"
        >
          {icon}
        </FontIcon>
      ) : (
        <img
          src={`/assets/imgs/icons/${icon}.svg`}
          className="header_nav_icon"
        />
      )}
      {showBadge && <span className="badge">{badgeNumber}</span>}
    </NavLink>
  );
};

ChillNavLink.propTypes = {
  materialIcon: PropTypes.bool,
  showBadge: PropTypes.bool,
  badgeNumber: PropTypes.number,
  exact: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  isActiveHandler: PropTypes.func,
};

export default ChillNavLink;
