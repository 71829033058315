import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";

import {Link} from "react-router-dom";
import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";

import ListComma from "../../../shared/ListComma";
import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import CNGTableRow from "../../../shared/CNGTable/CNGTableRow";
import CNGTableRowColumn from "../../../shared/CNGTable/CNGTableRowColumn";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
  thFirstLinkStyle,
  tableRowStyle,
} from "../../../../styles/components/tableStyles";
import {INACTIVE_ART_TITLE} from "../../../../constants/art";
import ToolTip from "react-portal-tooltip";

export const getArtTitle = revision => {
  if (!revision) return "";

  if (_get(revision, "active")) return _get(revision, "name");
  return INACTIVE_ART_TITLE;
};

export default class BrandIndexTable extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    brandsCount: PropTypes.number,
    toggleOrderDir: PropTypes.func,
    company: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  state = {
    isTooltipActive: false,
    tooltipId: null,
    artName: null,
  };

  showTooltip(event) {
    event.stopPropagation();
    const id = _get(event, "target.parentNode.dataset.id");
    let art;
    this.props.brands.find(brand => {
      return (art = brand.arts.find(art => art.id === id));
    });

    if (!art) return;
    const activeRevision =
      art.revisions && art.revisions.find(revision => revision.active);
    if (!activeRevision) return;

    this.setState({
      isTooltipActive: true,
      art: art && activeRevision,
      tooltipId: id,
    });
  }

  hideTooltip() {
    this.setState({isTooltipActive: false});
  }

  _renderRow(brand, index, companyId) {
    const brandArts = _get(brand, "arts") || [];
    const totalArt = brandArts.filter(
      art => _get(art, "revisions.length", 0) > 0
    ).length;

    return (
      <CNGTableRow key={index} styles={tableRowStyle}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: 300,
          }}
        >
          <Link
            to={`/companies/${companyId}/brands/${brand.id}`}
            className="no-link-styles"
          >
            {brand && brand.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn highlight={true}>
          {brandArts &&
            _sortBy(
              brandArts.filter(art => art.revisions.length > 0),
              art => art.revisions[0].createdAt * -1
            ).map((art, index) => {
              return (
                <ListComma
                  onMouseEnter={this.showTooltip.bind(this)}
                  onMouseLeave={this.hideTooltip.bind(this)}
                  key={index}
                  id={`art${art.id}`}
                  objectId={art.id}
                  index={index}
                  len={totalArt}
                  title={getArtTitle(_get(art, "revisions.0"))}
                  text={() => (
                    <Link
                      to={`/brands/${brand.id}/arts/${art.id}/revisions/${
                        art.revisions[0].id
                      }`}
                      className={`${
                        _get(art, "revisions.0.active") ? "" : "inactive-art"
                      }`}
                    >
                      {art ? art.revisions[0].name : ""}
                    </Link>
                  )}
                />
              );
            })}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderUserCompanyTableHeader() {
    const {brandsCount} = this.props;
    if (brandsCount === 0) return;

    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          <TableRowColumn
            style={thFirstLinkStyle}
            className="CNG-th pointer"
            width={300}
          >
            <div onClick={() => this.props.toggleOrderDir()}>
              BRAND NAME
              <img
                className="sorting-icon"
                src="/assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            ART
          </TableRowColumn>
        </TableRow>
      </TableHeader>
    );
  }

  render() {
    const {brands, company, isLoading} = this.props;
    const companyId = company && company.id;
    const {art, tooltipId} = this.state;
    const imageTooltip = {
      width: 200,
    };

    return [
      <CNGTableCustom
        isLoading={isLoading}
        tableStyle={tableStyle}
        displayRowCheckbox={false}
        key="table"
      >
        {this._renderUserCompanyTableHeader()}
        <TableBody displayRowCheckbox={false}>
          {brands &&
            brands.map((row, index) => this._renderRow(row, index, companyId))}
        </TableBody>
      </CNGTableCustom>,
      <ToolTip
        active={this.state.isTooltipActive}
        position="top"
        parent={`#art${tooltipId}`}
        key="tooltip"
        tooltipTimeout={0}
      >
        <img
          src={art && art.imageUrl}
          onError={e => {
            e.target.src = "/assets/imgs/image_not_found.png";
          }}
          style={imageTooltip}
        />
      </ToolTip>,
    ];
  }
}
