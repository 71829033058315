import {combineReducers} from "redux";
import auth from "./authReducer";
import users from "./userReducer";
import geo from "./geoReducer";
import companies from "./companyReducer";
import discounted from "./discountedReducer";
import businesses from "./businessesReducer";
import paymentTerms from "./paymentTermsReducer";
import brands from "./brandReducer";
import art from "./artReducer";
import embroiderydetails from "./embroideryDetailReducer";
import products from "./productReducer";
import fabrics from "./fabricReducer";
import settings from "./settingsReducer";
import colors from "./colorReducer";
import digitalizationTypePrices from "./digitalizationTypePriceReducer";
import orders from "./orderReducer";
import inventory from "./inventoryReducer";
import shipment from "./shipmentsReducer";
import adornmentTypes from "./adornmentTypeReducer";
import embroideryApplicabilities from "./embroideryApplicabilitiesReducer";
import embroideryTypes from "./embroideryTypesReducer";
import notification from "./notificationReducer";
import dsodItems from "./dsodItemReducer";
import quotes from "./quoteReducer";
import artAssignments from "./artAssignmentReducer";
import orderItemTypes from "./orderItemTypesReducer";
import adornmentLocations from "./adornmentLocationReducer";
import productCategories from "./productCategoriesReducer";
import bagTypes from "./bagTypesReducer";
import bagCapacities from "./bagCapacitiesReducer";
import priceTableLinks from "./priceTableLinksReducer";
import chat from "./chatReducer";
import router from "./routerReducer";
import {routerReducer} from "react-router-redux";

const rootReducer = combineReducers({
  auth,
  users,
  geo,
  companies,
  discounted,
  businesses,
  paymentTerms,
  brands,
  art,
  embroiderydetails,
  products,
  fabrics,
  colors,
  digitalizationTypePrices,
  orders,
  inventory,
  shipment,
  adornmentTypes,
  embroideryApplicabilities,
  embroideryTypes,
  routing: routerReducer,
  notification,
  dsodItems,
  orderItemTypes,
  adornmentLocations,
  quotes,
  artAssignments,
  productCategories,
  bagTypes,
  bagCapacities,
  priceTableLinks,
  chat,
  router,
  settings,
});

export default rootReducer;
