import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DSODShipmentTracking from "../../pages/DSOD/components/DSODShipmentTracking";

import {
  updateShipmentItem,
  updateShipment,
} from "../../../actions/shipmentActions";

function mapStateToProps(state) {
  const {
    auth: {isInternal},
    inventory: {inventoryItemsCount, inventoryItems, isLoading},
  } = state;

  return {inventoryItems, inventoryItemsCount, isLoading, isInternal};
}

function mapDispatchToProps(dispatch) {
  return {
    updateShipment: bindActionCreators(updateShipment, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DSODShipmentTracking)
);
