import React, {Component} from "react";
import PropTypes from "prop-types";

import {
  formStyles,
  SelectFieldIconStyle,
  TextFieldSmallLabelStyles,
} from "../../../../styles/components/formStyles";

import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import OrderShipmentForm from "./OrderShipmentForm";
import AttributeLine from "../../../shared/AttributeLine";
import {getDate, getFormattedDate, getTodayDate} from "../../../../utils/dates";
import CNGDatePicker from "../../../shared/CNGDatePicker";

const artNameStyle = {
  ...formStyles.HalfFieldStyles,
  marginRight: 23,
  width: 152,
};

const smallInputStyles = {
  ...formStyles.HalfFieldStyles,
  marginRight: 23,
  width: 120,
};

const artForm = {
  marginTop: 23,
};

const referenceNumberStyle = {
  color: "#BDC6CF",
  margin: 0,
  fontSize: 16,
  display: "inline-block",
  marginRight: 36,
};

const trackingInputStyle = {
  marginBottom: 12,
};

const formInputStyle = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 23,
};

const TODAY = getTodayDate();

class OrderForm extends Component {
  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(event, value);
    }
  };

  renderDSODFields() {
    const {isInternal, order} = this.props;
    return (
      <div style={{display: "inline-block"}}>
        <AttributeLine
          labelText="Date of Request"
          text={getFormattedDate(order.submittedOn) || ""}
          style={{
            ...smallInputStyles,
            borderBottom: "none",
            color: "#BDC6CF",
            width: 92,
          }}
          isInternal={isInternal}
        />
      </div>
    );
  }

  renderOrderFields() {
    const {orderData, onOrderChange} = this.props;
    return (
      <React.Fragment>
        <TextField
          floatingLabelText="Customer PO #"
          type="text"
          name="purchase_order_number"
          value={orderData.purchase_order_number}
          onChange={(event, value) =>
            this.handleChangeWithMaxLength(event, value, onOrderChange, 17)
          }
          style={artNameStyle}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
        />
        <CNGDatePicker
          floatingLabelText="Customer PO Date"
          name="purchase_order_date"
          value={
            orderData.purchase_order_date &&
            getDate(orderData.purchase_order_date)
          }
          onChange={(event, value) =>
            onOrderChange(event, value, "purchase_order_date")
          }
          style={{
            marginRight: 16,
            display: "inline-block",
          }}
          datePickerStyle={formStyles.HalfTextInputStyles}
          textFieldStyle={formStyles.HalfTextInputStyles}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      isDSOD,
      onOrderChange,
      onShipmentChange,
      order,
      orderData,
      shipmentData,
      shipments,
      shippingMethods,
      companyShippingCarrierAssignments,
      billingCompanyContacts,
      inputCostEditable,
      paymentTerms,
      isInternal,
    } = this.props;

    const shipment = (shipments && shipments[0]) || {};

    return (
      <div className="order-form " style={artForm}>
        <div style={trackingInputStyle}>
          <div style={referenceNumberStyle}>
            <label style={{lineHeight: 2.4, fontSize: 12, display: "block"}}>
              CNG Ref #
            </label>
            {order.cngReferenceNumber}
          </div>
          {isDSOD ? this.renderDSODFields() : this.renderOrderFields()}
          <CNGDatePicker
            showClear
            floatingLabelText="In-Hand Date"
            name="in_hand_date"
            value={orderData.in_hand_date && getDate(orderData.in_hand_date)}
            onChange={(event, value) =>
              onOrderChange(event, value, "in_hand_date")
            }
            style={{
              marginRight: 16,
              display: "inline-block",
            }}
            datePickerStyle={formStyles.HalfTextInputStyles}
            textFieldStyle={formStyles.HalfTextInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
          />
          <CNGDatePicker
            floatingLabelText="Est. Ship Date"
            name="estimated_ship_on"
            value={
              orderData.estimated_ship_on &&
              getDate(orderData.estimated_ship_on)
            }
            shouldDisableDate={day => day < TODAY}
            onChange={(event, value) =>
              onOrderChange(event, value, "estimated_ship_on")
            }
            style={{
              marginRight: 16,
              display: "inline-block",
            }}
            datePickerStyle={formStyles.HalfTextInputStyles}
            textFieldStyle={formStyles.HalfTextInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            minDate={TODAY}
          />
          {isDSOD ? null : (
            <SelectField
              floatingLabelText="Bill To *"
              name="billingCompanyContactId"
              value={orderData && orderData.billing_company_contact_id + ""}
              onChange={(e, index, value) => {
                onOrderChange(null, value, "billing_company_contact_id");
              }}
              style={{...formInputStyle, ...formStyles.HalfTextInputStyles}}
              inputStyle={{
                ...formStyles.HalfTextInputStyles,
                height: 68,
                marginTop: 0,
                top: 15,
              }}
              labelStyle={{
                ...formStyles.TextFieldLabelStyles,
                color: "#4A5158",
              }}
              floatingLabelStyle={formStyles.TextFieldLabelStyles}
              iconStyle={{...SelectFieldIconStyle, ...formStyles.HalfIconStyles}}
              menuStyle={formStyles.MenuStyle}
              underlineStyle={formStyles.underlineStyle}
            >
              {billingCompanyContacts &&
                billingCompanyContacts.map(({companyContact}) => {
                  return (
                    <CNGMenuItem
                      key={companyContact && companyContact.id}
                      value={companyContact && companyContact.id + ""}
                      primaryText={companyContact && companyContact.name}
                    />
                  );
                })}
            </SelectField>
          )}
        </div>
        <div className="section-header">Shipping Information</div>
        <OrderShipmentForm
          onShipmentChange={onShipmentChange}
          companyShippingCarrierAssignments={companyShippingCarrierAssignments}
          shipmentData={shipmentData}
          shippingMethods={shippingMethods}
          shipment={shipment}
          isDSOD={isDSOD}
          inputCostEditable={inputCostEditable}
          showInputCost={false}
          paymentTerms={paymentTerms}
          isInternal={isInternal}
          orderData={orderData}
          onOrderChange={onOrderChange}
          isDisabled={()=>false}
        />
      </div>
    );
  }
}

OrderForm.propTypes = {
  showInputCost: PropTypes.bool,
  isDSOD: PropTypes.bool,
  isInternal: PropTypes.bool,
  order: PropTypes.object,
  onOrderChange: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onFileChange: PropTypes.func,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingMethods: PropTypes.array,
  shipments: PropTypes.array,
  orderData: PropTypes.object,
  shipmentData: PropTypes.object,
  billingCompanyContacts: PropTypes.array,
  inputCostEditable: PropTypes.bool,
  paymentTerms: PropTypes.array,
};

export default OrderForm;
