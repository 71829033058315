import React, {Component} from "react";
import PropTypes from "prop-types";
import {ConnectedRouter} from "react-router-redux";
import * as Sentry from "@sentry/browser";
import {Provider} from "react-redux";
import App from "./App";

export default class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    const {store, history} = this.props;

    Sentry.init({ dsn: process.env.DSN_GLITCHTIP, environment: process.env.NODE_ENV });

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    );
  }
}
