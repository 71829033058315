import React, {Component} from "react";
import PropTypes from "prop-types";

import FilePreview from "react-preview-file";
import PlaceholderImg from "../../../shared/PlaceholderImg";
import ChillButton from "../../../shared/ChillButton";
import FileUploaderDialog from "../../../shared/FileUploaderDialog";

export default class ArtFileInputContainer extends Component {
  static propTypes = {
    file: PropTypes.object,
    latestRevision: PropTypes.object,
    filename: PropTypes.string,
    errorText: PropTypes.string,
    onFileChange: PropTypes.func,
    onUpdateImage: PropTypes.func,
    isInternal: PropTypes.bool,
    readOnly: PropTypes.bool,
    fileInputIcon: PropTypes.string,
    fileInputText: PropTypes.string,
    showDialogOption: PropTypes.bool,
    tabIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.inputField;
  }

  componentWillReceiveProps(nextProps) {
    const {file, latestRevision} = nextProps;
    if (
      this.state.errorImage &&
      file === this.props.file &&
      latestRevision === this.props.latestRevision
    )
      return;

    const hasAValidFile = file || (latestRevision && latestRevision.thumbnail);

    if (hasAValidFile) {
      this.setState({errorImage: false});
    }
  }

  state = {
    openDialog: false,
    errorImage: false,
  };

  _toggleDialog = event => {
    event && event.preventDefault();
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  openFileBrowserHandler = () => {
    if (this.props.showDialogOption) {
      this._toggleDialog();
    } else {
      this.inputField.click();
    }
  };

  handleError = () => {
    this.setState({errorImage: true});
  };

  _renderImageContainer = () => {
    const {file, latestRevision} = this.props;

    if (this.state.errorImage) {
      return <PlaceholderImg text="Image not found" style={placeHolderStyle} />;
    }
    if (!file && latestRevision) {
      return (
        <div style={imageContainerStyle}>
          <img
            src={latestRevision.imageUrl}
            style={imagePreviewStyle}
            onError={this.handleError}
          />
        </div>
      );
    }

    if (file && file.type === "application/pdf") {
      return <PlaceholderImg text="PDF" style={placeHolderStyle} />;
    }

    return (
      <div style={imageContainerStyle}>
        {file ? (
          <FilePreview file={file}>
            {preview => {
              return <img src={preview} style={imagePreviewStyle} />;
            }}
          </FilePreview>
        ) : (
          <PlaceholderImg
            text="Upload Art"
            style={placeHolderStyle}
            onClick={this.openFileBrowserHandler}
          />
        )}
      </div>
    );
  };

  _onUploadHandler() {
    const {onUpdateImage} = this.props;
    if (typeof onUpdateImage === "function") {
      onUpdateImage(...arguments);
    }
    this._toggleDialog();
  }

  render() {
    const {
      onFileChange,
      isInternal,
      file,
      latestRevision,
      readOnly,
      fileInputIcon,
      fileInputText,
      showDialogOption,
      tabIndex,
      errorText,
    } = this.props;
    const filename = file
      ? file.name
      : latestRevision ? latestRevision.name : "-";

    return (
      <div
        className="art-form row"
        style={{position: "relative", marginLeft: 2}}
      >
        <div style={previewContainer}>
          {this._renderImageContainer()}
          {errorText && (
            <span className="small-text" style={errorTextStyle}>
              {errorText}
            </span>
          )}
          <span className="small-text">Art file</span>
          <div
            className="flex flex-row align-items-center justify-content-between"
            style={{
              marginTop: 23,
            }}
          >
            {isInternal && (
              <input
                type="file"
                onChange={onFileChange}
                className="hidden-safe"
                name="file"
                tabIndex={-1}
                ref={input => (this.inputField = input)}
              />
            )}

            {isInternal &&
              showDialogOption && (
              <FileUploaderDialog
                open={this.state.openDialog}
                onClose={this._toggleDialog}
                onUpload={file => this._onUploadHandler(file)}
                saveText="UPDATE ART"
                title="Replace Art"
              />
            )}
            <span style={filenameStyle}>{filename}</span>
            {isInternal && (
              <ChillButton
                tabIndex={tabIndex}
                name={fileInputText || " UPLOAD"}
                icon={fileInputIcon || "add2-white"}
                width={120}
                widthIcon={12}
                height={32}
                secondary={true}
                isDisabled={readOnly ? true : false}
                marginLeft={50}
                onClick={readOnly ? () => {} : this.openFileBrowserHandler}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const imageContainerStyle = {
  width: 278,
  height: 360,
  marginBottom: 10,
};

const imagePreviewStyle = {
  objectFit: "cover",
  height: 360,
  width: "100%",
  boxShadow: "0px 0px 0px 1px #BDC6CF",
};

const placeHolderStyle = {
  marginBottom: 8,
  width: 278,
  height: 360,
  flex: 1,
};

const previewContainer = {
  width: 278,
  height: 360,
  display: "flex",
  flexDirection: "column",
  marginBottom: 33,
};

const filenameStyle = {
  whiteSpace: "nowrap",
  Width: 130,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const errorTextStyle = {
  fontSize: 12,
  lineHeight: "12px",
  color: "rgb(244, 67, 54)",
};
