/*eslint-disable react/no-string-refs*/
import React from "react";

import CircularProgress from "material-ui/CircularProgress";
import transitions from "material-ui/styles/transitions";
const SIZE = 44;

function getRelativeValue(value, min, max) {
  const clampedValue = Math.min(Math.max(min, value), max);
  return clampedValue / (max - min);
}

function getArcLength(fraction, props) {
  return fraction * Math.PI * (SIZE - props.thickness);
}

function getStyles(props, context) {
  const {max, min, value} = props;

  const {baseTheme: {palette}} = context.muiTheme;

  const styles = {
    root: {
      position: "relative",
      display: "inline-block",
      width: SIZE,
      height: SIZE,
    },
    wrapper: {
      width: SIZE,
      height: SIZE,
      display: "inline-block",
      transition: transitions.create("transform", "20s", null, "linear"),
      transitionTimingFunction: "linear",
    },
    svg: {
      width: SIZE,
      height: SIZE,
      position: "relative",
    },
    path: {
      stroke: props.color || palette.primary1Color,
      strokeLinecap: "round",
      transition: transitions.create("all", "1.5s", null, "ease-in-out"),
    },
  };

  if (props.mode === "determinate") {
    const relVal = getRelativeValue(value, min, max);
    styles.path.transition = transitions.create("all", "0.3s", null, "linear");
    styles.path.strokeDasharray = `${getArcLength(
      relVal,
      props
    )}, ${getArcLength(1, props)}`;
  }

  return styles;
}

export default class CNGCircleProgress extends CircularProgress {
  scalePath(path, step = 0) {
    if (this.props.mode !== "indeterminate") return;

    step %= 3;

    if (step === 0) {
      path.style.strokeDasharray = `${getArcLength(
        0,
        this.props
      )}, ${getArcLength(1, this.props)}`;
      path.style.strokeDashoffset = 0;
      path.style.transitionDuration = "0ms";
    } else if (step === 1) {
      path.style.strokeDasharray = `${getArcLength(
        0.7,
        this.props
      )}, ${getArcLength(1, this.props)}`;
      path.style.strokeDashoffset = getArcLength(-0.3, this.props);
      path.style.transitionDuration = "750ms";
    } else {
      path.style.strokeDasharray = `${getArcLength(
        0.7,
        this.props
      )}, ${getArcLength(1, this.props)}`;
      path.style.strokeDashoffset = getArcLength(-1, this.props);
      path.style.transitionDuration = "850ms";
    }

    this.scalePathTimer = setTimeout(
      () => this.scalePath(path, step + 1),
      step ? 750 : 250
    );
  }
  render() {
    const {style, innerStyle, thickness, ...other} = this.props;
    const {prepareStyles} = this.context.muiTheme;
    const styles = getStyles(this.props, this.context);

    return (
      <div {...other} style={prepareStyles(Object.assign(styles.root, style))}>
        <div
          ref="wrapper"
          style={prepareStyles(Object.assign(styles.wrapper, innerStyle))}
        >
          <svg
            viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
            style={prepareStyles(styles.svg)}
          >
            <circle
              ref="path"
              style={prepareStyles(styles.path)}
              cx={SIZE}
              cy={SIZE}
              r={(SIZE - thickness) / 2}
              fill="none"
              strokeWidth={thickness}
            />
          </svg>
        </div>
      </div>
    );
  }
}
