import React, {Component} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";

export default class CompanyLink extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    linkCount: PropTypes.number.isRequired,
    className: PropTypes.string,
    marginRight: PropTypes.string,
    url: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      text,
      index,
      linkCount,
      className,
      marginRight,
      url,
      disabled,
    } = this.props;
    const linkContent = (
      <span>
        {text}
        {linkCount > 0 && index != linkCount - 1 ? "," : null}
      </span>
    );
    if (disabled) {
      return (
        <a
          className={className}
          style={{
            marginRight: marginRight ? marginRight : 3,
            color: "#747D86",
          }}
        >
          {linkContent}
        </a>
      );
    }
    return (
      <Link
        className={className}
        style={{
          marginRight: marginRight ? marginRight : 3,
        }}
        to={url}
      >
        {linkContent}
      </Link>
    );
  }
}
