import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import withPagination from "../../../../modules/withPagination";
import withSorting from "../../../../modules/withSorting";
import {
  fetchQuotes,
  searchQuotes,
} from "../../../../../actions/v2/quoteActions";
import QuoteIndex from "./QuoteIndex";

function mapStateToProps(state) {
  const {quotes, auth} = state;
  return {
    quotes: quotes.quotes,
    quotesCount: quotes.quotesCount,
    quotesUnreadCounts: quotes.unreadCounts,
    isLoading: quotes.isLoading,
    isInternal: auth.isInternal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuotes: bindActionCreators(fetchQuotes, dispatch),
    searchQuotes: bindActionCreators(searchQuotes, dispatch),
  };
}

export default withSorting(
  withPagination(connect(mapStateToProps, mapDispatchToProps)(QuoteIndex))
);
