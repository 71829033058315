import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import OrderEditHeader from "./components/OrderEditHeader";
import OrderItemsTable from "./components/OrderItemsTable";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import NotesForm from "../../shared/NotesForm";
import OrderSummary from "./components/OrderSummary";
import ChatSystem from "../../v2/shared/ChatSystem";

class OrderEditPage extends Component {
  renderChat = () => {
    const {order, isInternal, currentUserId} = this.props;
    if (_get(order, "status.code") !== "OPA") return;
    if (!isInternal && order.createdUserId !== currentUserId) return;
    return (
      <ChatSystem
        type="order"
        typeData={order}
        typeIdName="id"
        userId={isInternal ? order.createdUserId : currentUserId}
      />
    );
  };

  render() {
    const {
      company,
      order,
      isInternal,
      orderItems,
      onUpdateOrderName,
      onUpdateOrderStatus,
      onUpdateShipmentItem,
      onOrderChange,
      onShipmentChange,
      orderStatus,
      isReordering,
      reorderItems,
      onToggleReorder,
      onToggleReorderItem,
      shipments,
      companyShippingCarrierAssignments,
      shippingCarriers,
      shippingMethods,
      orderData,
      shipmentData,
      onUpdateOrder,
      isLoading,
      billingCompanyContacts,
      countries,
      updateShipment,
      paymentTerms,
      onUpdateOrderItem,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company && company.name}
      >
        <BreadCrumbs
          rootName="Companies"
          middle={company && company.name}
          middleLink={company && company.id}
          pageName={"Order edit"}
        />
        <OrderEditHeader
          onUpdateOrderName={onUpdateOrderName}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onToggleReorder={onToggleReorder}
          onOrderChange={onOrderChange}
          onShipmentChange={onShipmentChange}
          onUpdateOrder={onUpdateOrder}
          company={company}
          order={order}
          isInternal={isInternal}
          isReordering={isReordering}
          orderStatus={orderStatus}
          shipments={shipments}
          orderData={orderData}
          shipmentData={shipmentData}
          shippingCarriers={shippingCarriers}
          shippingMethods={shippingMethods}
          companyShippingCarrierAssignments={companyShippingCarrierAssignments}
          billingCompanyContacts={billingCompanyContacts}
          paymentTerms={paymentTerms}
        />

        <OrderItemsTable
          isEditingOrder={_get(order, "status.code") === "OPA"}
          isInternal={isInternal}
          items={orderItems}
          isReordering={isReordering}
          reorderItems={reorderItems}
          shipments={shipments}
          onToggleReorderItem={onToggleReorderItem}
          onUpdateShipmentItem={onUpdateShipmentItem}
          updateShipment={updateShipment}
          onUpdateOrderItem={onUpdateOrderItem}
        />
        <OrderSummary
          order={order}
          shipments={shipments}
          countries={countries}
          orderItems={orderItems}
        />
        <NotesForm
          order={orderData}
          onChange={onOrderChange}
          classContainer="w-100"
          inputStyle={{width: "100%"}}
          isInternal={true}
        />
        {this.renderChat()}
      </PageLayout>
    );
  }
}

OrderEditPage.propTypes = {
  onUpdateShipmentItem: PropTypes.object,
  company: PropTypes.object,
  order: PropTypes.object,
  orderData: PropTypes.object,
  shipmentData: PropTypes.object,
  orderItems: PropTypes.array,
  countries: PropTypes.array,
  shipments: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingCarriers: PropTypes.array,
  shippingMethods: PropTypes.array,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReordering: PropTypes.bool,
  reorderItems: PropTypes.array,
  orderStatus: PropTypes.array,
  onUpdateOrderName: PropTypes.func,
  onUpdateOrderStatus: PropTypes.func,
  onToggleReorder: PropTypes.func,
  onToggleReorderItem: PropTypes.func,
  onUpdateShipmentTrackingNumber: PropTypes.func,
  onOrderChange: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  billingCompanyContacts: PropTypes.array,
  updateShipment: PropTypes.func,
  paymentTerms: PropTypes.array,
  currentUserId: PropTypes.number,
  onUpdateOrderItem: PropTypes.func,
};

export default OrderEditPage;
