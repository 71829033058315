import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function artReducer(
  state = initialState.art,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_ALL_ART_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ALL_ART_SUCCESS:
    return {
      ...state,
      artList: payload,
      artCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_ALL_ART_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_ART_REQUEST:
    return {
      ...state,
      art: null,
      isLoading: true,
    };
  case actions.FETCH_ART_SUCCESS:
    return {
      ...state,
      art: payload,
      isLoading: false,
    };
  case actions.FETCH_ART_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.CREATE_ART_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_ART_SUCCESS:
    return {
      ...state,
      artList: [...state.artList, payload],
      isLoading: false,
    };
  case actions.CREATE_ART_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_ART_REVISION_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_ART_REVISION_SUCCESS:
    return {
      ...state,
      artRevisions: state.artRevisions.map(artRevision => {
        return artRevision.id === payload.id ? payload : artRevision;
      }),
      isLoading: false,
    };
  case actions.UPDATE_ART_REVISION_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_ART_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_ART_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case actions.UPDATE_ART_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_ART_REVISIONS_REQUEST:
    return {
      ...state,
      artRevisions: [],
      isLoading: true,
    };
  case actions.FETCH_ART_REVISIONS_SUCCESS:
    return {
      ...state,
      artRevisions: payload,
      artRevisionsCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_ART_REVISIONS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.CLEAR_ART_REVISIONS:
    return {
      ...state,
      artRevisions: [],
      isLoading: false,
    };

  case actions.DELETE_ART_REVISION_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.DELETE_ART_REVISION_SUCCESS:
    return {
      ...state,
      artRevision: null,
      isLoading: false,
    };
  case actions.DELETE_ART_REVISION_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
