import React, {Component} from "react";
import _debounce from "lodash/debounce";
import _pickBy from "lodash/pickBy";
import TextField from "material-ui/TextField";

class DebouncedTextField extends Component {
  state = {
    value: "",
  };

  componentDidMount() {
    this.setState({value: this.props.initialValue});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({value: this.props.initialValue});
    }
  }

  handleChange = (event, value) => {
    this.setState({value});
    this.changeCallback(event, value);
  };

  changeCallback = _debounce(
    (event, value) => {
      const {onChange} = this.props;
      onChange(event, value);
    },
    500,
    {
      leading: false,
      trailing: true,
    }
  );

  render() {
    const {value} = this.state;
    const props = _pickBy(this.props, (value, key) => key !== "initialValue");
    return <TextField {...props} value={value} onChange={this.handleChange} />;
  }
}

DebouncedTextField.propTypes = {
  ...TextField.propTypes,
  initialValue: TextField.propTypes.value,
};

export default DebouncedTextField;
