export default {
  auth: {
    error: false,
    token: "",
    goto_order: false,
    error_message: "",
    success_message: "",
    isAuthenticated: false,
    isLoggedOut: false,
    user: "",
    email: "",
    searchQuery: "",
    isInternal: false,
    companies: [],
    selectedCompany: null,
    id: null,
  },
  users: {
    users: [],
    userDetail: {},
    isLoading: false,
    isUpdating: false,
    userCount: null,
    currentPage: 1,
    error: null,
    error_message: null,
    searchTerm: "",
    userNoteRequestLoading: false,
    userEmailValidationByCompany: false,
  },
  geo: {
    states: [],
    countries: [],
  },
  companies: {
    dsodCompanies: [],
    companiesWithDSOD: [],
    company: null,
    companies: [],
    companiesCount: 0,
    associatedCompanies: [],
    companyContacts: [],
    companyContactsCount: 0,
    companyDetail: {},
    companyContact: {},
    searchTerm: "",
    isCompanyContactsLoading: false,
    isLoading: false,
    isLoadingAssociatedCompanies: false,
    isNoteRequestLoading: false,
    isLoadingCompanyContactRequest: false,
    isLoadingWalletMembers: false,
    hasCompaniesWithoutDSODAndInventoryItems: false,
    walletMembers: null,
  },
  discounted: {
    discounts: [],
  },
  businesses: {
    businesses: [],
  },
  paymentTerms: {
    paymentTerms: [],
  },
  brands: {
    brands: [],
    brand: null,
    isLoading: false,
  },
  dsodItems: {
    dsodItems: [],
    isLoading: false,
  },
  art: {
    artList: [],
    art: null,
    artRevisions: [],
    artRevisionsCount: 0,
    searchTerm: "",
    isLoading: false,
  },
  products: {
    products: [],
    productShipments: [],
    product: null,
    productImages: [],
  },
  fabrics: {
    fabrics: [],
  },
  colors: {
    colors: [],
    leatherColors: [],
  },
  orders: {
    orders: [],
    DSODOrders: [],
    ordersCount: 0,
    DSODOrdersCount: 0,
    order: {},
    orderItems: [],
    orderItem: null,
    searchTerm: "",
    orderStatus: [],
    currentOpenOrders: [],
    unreadCounts: {},
  },
  inventory: {
    inventoryItems: [],
    inventoryItemsCount: 0,
    inventoryItem: null,
    inventory: [],
  },
  shipment: {
    shipments: null,
    shipmentsCount: null,
    shipment: null,
    companyShippingCarrierAssignments: [],
    companyShippingCarrierAssignment: null,
    shippingCarriers: [],
    shippingMethods: null,
    shipmentItems: [],
  },
  adornmentTypes: {
    adornmentTypes: [],
  },
  notification: {
    show: false,
    message: "",
  },
  embroideryApplicabilities: {
    embroideryApplicabilities: [],
  },
  recipients: {
    recipients: [{}],
  },
  orderItemTypes: {
    orderItemTypes: [],
    isLoading: false,
  },
  adornmentLocations: {
    adornmentLocations: [],
  },
  hardwareTypes: {
    hardwareTypes: [],
  },
  digitalizationTypePrices: {
    digitalizationTypePrices: [],
  },
  quotes: {
    quote: null,
    quotes: [],
    itemPrices: [],
    unreadCounts: {},
    isLoadingArtFile: false,
  },
  artAssignments: {
    artAssignment: null,
  },
  productCategories: {
    categories: [],
  },
  bagTypes: {
    types: [],
  },
  bagCapacities: {
    capacities: [],
  },
  priceTableLinks: {
    links: [],
  },
  chat: {
    isLoading: false,
    channels: [],
    channel: null,
    isLoadingMessages: false,
    messages: [],
    chatsUnreadCounts: 0,
  },
  settings: {},
};
