import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import Pagination from "../../shared/Pagination";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import Divider from "../../shared/Divider";
import Loader from "../../shared/Loader";
import ConfirmationModal from "../../shared/ConfirmationModal";

import ArtTabs from "./components/ArtTabs";
import ArtDetailCardHeader from "./components/ArtDetailCardHeader";
import ArtDetailInfo from "./components/ArtDetailInfo";

export default class ArtDetailPage extends Component {
  static propTypes = {
    brand: PropTypes.object,
    adornmentTypes: PropTypes.array,
    filename: PropTypes.string,
    name: PropTypes.string,
    adornment_type_id: PropTypes.number,
    file: PropTypes.object,
    artRevisions: PropTypes.array,
    artRevisionsCount: PropTypes.number,
    currentPage: PropTypes.number,
    details: PropTypes.object,
    onSelectPage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isInternal: PropTypes.bool.isRequired,
    selectedArt: PropTypes.object,
    history: PropTypes.object,
    deleteArtRevision: PropTypes.func,
    match: PropTypes.object,
    updateArtRevisionActive: PropTypes.func,
  };

  state = {
    selectedRevision: 0,
    openDeleteModal: false,
  };

  _renderPagination() {
    const {onSelectPage} = this.props;
    return (
      <div className="userindex_pagination">
        <Pagination
          getUsers={onSelectPage}
          count={this.props.artRevisionsCount}
          currentPage={this.props.currentPage}
        />
      </div>
    );
  }

  _moveToTop() {
    const pageContainer = document.querySelector(".PageContainer");
    if (pageContainer) return (pageContainer.scrollTop = 0);
  }

  redirectToRestoreVersion = () => {
    const {selectedArt, history} = this.props;
    history.push({
      pathname: `/brands/${selectedArt.brandId}/arts/${selectedArt.id}/edit`,
      state: {
        selectedArt,
      },
    });
  };

  onCancelRestoreVersion = () => {
    const {history, selectedArt} = this.props;
    history.push({
      pathname: `/brands/${selectedArt.brandId}/arts/${selectedArt.id}`,
    });
  };

  _getArtLatestRevision() {
    const {artRevisions, match: {params: {revision_id}}} = this.props;
    if (!artRevisions) return;
    this._moveToTop();
    return artRevisions.find(({id}) => Number(id) === Number(revision_id));
  }

  deleteArtRevision = () => {
    this.setState({openDeleteModal: true});
  };

  handleDeactivateCheck = () => {
    const latestRevision = this._getArtLatestRevision();
    const {isInternal, updateArtRevisionActive, brand, history} = this.props;
    if (!isInternal) return;
    updateArtRevisionActive(latestRevision.artId, latestRevision.id, () => {
      history.push(
        `/companies/${brand.companyId}/brands/${brand.id}`
      );
    });
  };

  renderHeader = () => {
    const {
      isInternal,
      brand,
      selectedArt,
      history,
      updateArtRevisionActive,
    } = this.props;
    const latestRevision = this._getArtLatestRevision();
    return (
      <div>
        <ArtDetailCardHeader
          title={latestRevision && latestRevision.name}
          art={selectedArt}
          latestRevision={latestRevision}
          brand={brand}
          noButton={!isInternal}
          history={history}
          onCancelRestoreVersion={this.onCancelRestoreVersion}
          onReplaceVersion={this.redirectToRestoreVersion}
          deleteArtRevision={this.deleteArtRevision}
        />
        <Divider marginBottom={24} marginTop={24} />
        <ArtDetailInfo
          art={selectedArt}
          latestRevision={latestRevision}
          isInternal={isInternal}
          updateArtRevisionActive={updateArtRevisionActive}
        />
      </div>
    );
  };

  render() {
    const {
      isInternal,
      isLoading,
      brand,
      artRevisions,
      artRevisionsCount,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={lodash.get(brand, "company.name")}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={lodash.get(brand, "company.name")}
          middleLink={lodash.get(brand, "company.id")}
          middletwo={lodash.get(brand, "name")}
          middletwoLink={`brands/${lodash.get(brand, "id")}`}
          pageName="Art details"
          disableMiddleTwoParams
        />

        <Card>
          {isLoading ? <Loader height={583} /> : this.renderHeader()}
          <ArtTabs
            revisions={artRevisions}
            isInternal={isInternal}
            brand={brand}
          />{" "}
          {artRevisions && artRevisionsCount > 7
            ? this._renderPagination()
            : null}
        </Card>

        <ConfirmationModal
          title={"Are you sure you want to delete this Art version?"}
          open={this.state.openDeleteModal}
          onConfirm={() => {
            this.setState(
              {
                openDeleteModal: false,
              },
              () => {
                this.handleDeactivateCheck();
              }
            );
          }}
          onDismiss={() => {
            this.setState({openDeleteModal: false});
          }}
          height={250}
        />
      </PageLayout>
    );
  }
}
