import React from "react";
import PropTypes from "prop-types";
import _defaultTo from "lodash/defaultTo";
import _get from "lodash/get";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import queryStringParser from "query-string";
import * as actions from "../actions/authActions";

import Header from "./shared/Header/Header";
import Loader from "./shared/Loader";
import GeneralChat from "./shared/GeneralChat";
import {fetchCompanies} from "../actions/companyActions";
import {
  fetchCurrentOpenOrders,
  fetchOrdersUnreadCounts,
} from "../actions/orderActions";
import {fetchCompaniesWithDSOD} from "../actions/dsodItemsActions";
import {
  searchQuotes,
  fetchQuotesUnreadCounts,
} from "../actions/v2/quoteActions";
import {fetchChatsUnreadCounts} from "../actions/v2/chatActions";
import Messenger from "../services/MessagingService";

export default function requireAuthentication(
  Component,
  header = true,
  restrictToExternal = false,
  extraProps = {}
) {
  class AuthenticatedComponent extends React.Component {
    state = {
      unreadMessageCount: 0,
    };

    componentWillMount() {
      this.initSetup();
    }

    async initSetup() {
      await this.checkAuth();
      const {
        isAuthenticated,
        isInternal,
        userId,
        user: userName,
        fetchCurrentOpenOrders,
        fetchCompaniesWithDSOD,
        searchQuotes,
      } = this.props;
      if (isAuthenticated) {
        fetchCurrentOpenOrders();
        fetchCompaniesWithDSOD();
        await searchQuotes("", {page: 1, perPage: 0});
        const user = {
          id: isInternal ? "internal" : userId,
          name: isInternal ? "Chill N Go" : userName,
        };
        const messenger = new Messenger(user);
        if (!messenger.sb.currentUser) await messenger.init();

        const {quotes, quotesUnreadCounts} = this.props;
        const unreadMessageCount = quotes.reduce((count, {uuid}) => {
          const messageCount =
            (quotesUnreadCounts && quotesUnreadCounts[uuid]) || 0;
          return count + messageCount;
        }, 0);
        this.setState({unreadMessageCount});

        this.onMessageReceiveHandler(messenger)();
        messenger.addOnMessageReceivedHandler(
          "internal-unread-counts",
          this.onMessageReceiveHandler(messenger)
        );
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.isLoggedOut) {
        this.props.history.push("/login");
      } else {
        this.checkAuth();
      }
    }

    onMessageReceiveHandler = messenger => {
      const {
        fetchChatsUnreadCounts,
        fetchQuotesUnreadCounts,
        fetchOrdersUnreadCounts,
      } = this.props;
      return async () => {
        const {quotes, orders} = this.props;
        const channels = await messenger.getChannelsList();
        const counts = channels.reduce(
          (counts, channel) => {
            const data = JSON.parse(channel.data);
            switch (data.type) {
            case "general":
              counts.chatUnreadCounts += channel.unreadMessageCount;
              break;
            case "quote":
              counts.quotesUnreadCounts[data["quote-uuid"]] =
                  channel.unreadMessageCount;
              break;
            case "order":
              counts.ordersUnreadCounts[data["order-id"]] =
                  channel.unreadMessageCount;
              break;
            default:
              break;
            }
            return counts;
          },
          {
            chatUnreadCounts: 0,
            quotesUnreadCounts: {},
            ordersUnreadCounts: {},
          }
        );
        let unreadMessageCount = quotes.reduce((count, {uuid}) => {
          const messageCount =
            (counts.quotesUnreadCounts && counts.quotesUnreadCounts[uuid]) || 0;
          return count + messageCount;
        }, 0);
        unreadMessageCount = orders.reduce((count, {id}) => {
          const messageCount =
            (counts.ordersUnreadCounts && counts.ordersUnreadCounts[id]) || 0;
          return count + messageCount;
        }, unreadMessageCount);
        this.setState({unreadMessageCount});
        fetchChatsUnreadCounts(counts.chatUnreadCounts);
        fetchQuotesUnreadCounts(counts.quotesUnreadCounts);
        fetchOrdersUnreadCounts(counts.ordersUnreadCounts);
      };
    };

    async checkAuth() {
      try {
        if (!this.props.isAuthenticated) {
          await this.props.checkAuth();
          await this.checkExternalRestrictions();
          if (!this.props.isAuthenticated) {
            this.props.history.push("/login");
          }
        } else {
          if (
            !this.props.isInternal &&
            this.props.selectedCompany === "null" &&
            this.props.location.pathname !== "/companyselect"
          ) {
            this.props.history.push("/companyselect");
          }
        }
        await this.checkExternalRestrictions();
      } catch (error) {
        return;
      }
    }

    checkExternalRestrictions() {
      const {isInternal, history} = this.props;
      if (!isInternal && restrictToExternal) {
        history.push("/");
        return Promise.reject(false);
      }
      return Promise.resolve(true);
    }

    _getCompanies() {
      const {currentOpenOrders} = this.props;
      const openOrders = _defaultTo(currentOpenOrders, []).filter(
        order => !order.isDsod
      );
      return _defaultTo(openOrders, []).map(order => order.company);
    }

    _isCreateOrderButtonVisible = () => {
      const {currentOpenOrders, companies} = this.props;
      if (!currentOpenOrders || !companies) return false;
      const openOrders = currentOpenOrders.filter(order => !order.isDsod);
      const companyIds = openOrders.map(order => order.companyId);
      return companies.some(
        company => !companyIds.includes(Number(company.id))
      );
    };

    _isCreateDSODButtonVisible = () => {
      const {hasCompaniesWithoutDSODAndInventoryItems} = this.props;
      return hasCompaniesWithoutDSODAndInventoryItems;
    };

    _getQueryStringParams() {
      const {location} = this.props;
      const defaultValue = {
        queryStringParams: {},
      };
      if (typeof location.search === "string" && location.search.length > 0) {
        const queryStringParams = queryStringParser.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        return Object.keys(queryStringParams).length > 0
          ? {
            queryStringParams,
          }
          : defaultValue;
      }
      return defaultValue;
    }

    _renderPrintLayout = () => {
      return (
        <Component
          {...{
            ...this.props,
            ...extraProps,
            ...this._getQueryStringParams(),
          }}
        />
      );
    };

    _renderGeneralChat = () => {
      const {
        isInternal,
        userId,
        user,
        location,
        isLoadingChat,
        isLoadingMessages,
      } = this.props;
      if (isInternal) return;
      if (location.pathname.match(/^\/companyselect/)) return;
      if (
        location.pathname.match(/^\/quotes\/info\//) ||
        location.pathname.match(/^\/quotes\/summary\//)
      ) {
        const {quote} = this.props;
        if (
          quote &&
          (quote.isApproved ||
            (quote.status !== "new" && (quote.noAdornment || quote.noNewArt)))
        )
          return;
      }
      if (location.pathname.match(/^\/companies\/\d+\/orders\//)) {
        const {order} = this.props;
        if (_get(order, "status.code") === "OPA") return;
      }
      return (
        <GeneralChat
          userId={userId}
          userName={user}
          isLoadingChat={isLoadingChat}
          isLoadingMessages={isLoadingMessages}
        />
      );
    };

    render() {
      const {
        isAuthenticated,
        isLoading,
        user,
        isInternal,
        logout,
        companiesWithDSOD,
        history,
        selectedCompany,
        chatsUnreadCounts,
      } = this.props;
      const {printMode = false} = extraProps;
      if (printMode) return this._renderPrintLayout();
      const {unreadMessageCount} = this.state;

      return (
        <div style={{position: "relative"}}>
          {isAuthenticated === true ? (
            <Loader isLoading={isLoading}>
              {header ? (
                <Header
                  isCreateDSODButtonVisible={this._isCreateDSODButtonVisible()}
                  isCreateOrderButtonVisible={this._isCreateOrderButtonVisible()}
                  user={user}
                  isInternal={isInternal}
                  logout={logout}
                  companies={this._getCompanies()}
                  companiesWithDsod={companiesWithDSOD}
                  history={history}
                  selectedCompany={selectedCompany}
                  isLoading={isLoading}
                  showBadge={unreadMessageCount > 0}
                  badgeNumber={unreadMessageCount}
                  chatBadgeNumber={chatsUnreadCounts}
                />
              ) : null}
              <Component
                {...this.props}
                {...extraProps}
                {...this._getQueryStringParams()}
              />
              {this._renderGeneralChat()}
            </Loader>
          ) : null}
        </div>
      );
    }
  }

  AuthenticatedComponent.propTypes = {
    checkAuth: PropTypes.func.isRequired,
    fetchCurrentOpenOrders: PropTypes.func.isRequired,
    fetchCompaniesWithDSOD: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isLoggedOut: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasCompaniesWithoutDSODAndInventoryItems: PropTypes.bool,
    userId: PropTypes.any,
    user: PropTypes.string,
    isInternal: PropTypes.bool,
    email: PropTypes.string,
    history: PropTypes.object,
    quote: PropTypes.object,
    order: PropTypes.object,
    companies: PropTypes.array,
    companiesWithDSOD: PropTypes.array,
    currentOpenOrders: PropTypes.array,
    selectedCompany: PropTypes.string,
    location: PropTypes.object,
    messenger: PropTypes.object,
    fetchCompanies: PropTypes.func,
    chatsUnreadCounts: PropTypes.number,
    fetchChatsUnreadCounts: PropTypes.func,
    isLoadingChat: PropTypes.bool,
    isLoadingMessages: PropTypes.bool,
    fetchQuotesUnreadCounts: PropTypes.func,
    fetchOrdersUnreadCounts: PropTypes.func,
  };

  function mapDispatchToProps(dispatch) {
    return {
      checkAuth: () => dispatch(actions.checkAuth()),
      logout: () => dispatch(actions.logout()),
      fetchCurrentOpenOrders: () => dispatch(fetchCurrentOpenOrders()),
      fetchCompaniesWithDSOD: bindActionCreators(
        fetchCompaniesWithDSOD,
        dispatch
      ),
      fetchCompanies: options => dispatch(fetchCompanies(options)),
      searchQuotes: options => dispatch(searchQuotes(options)),
      fetchChatsUnreadCounts: counts =>
        dispatch(fetchChatsUnreadCounts(counts)),
      fetchQuotesUnreadCounts: counts =>
        dispatch(fetchQuotesUnreadCounts(counts)),
      fetchOrdersUnreadCounts: counts =>
        dispatch(fetchOrdersUnreadCounts(counts)),
    };
  }

  const mapStateToProps = state => {
    const {
      orders: {order, orders, currentOpenOrders},
      auth,
      companies: {
        companiesWithDSOD,
        companies,
        hasCompaniesWithoutDSODAndInventoryItems,
      },
      quotes: {quotes, quote, unreadCounts},
      chat: {chatsUnreadCounts, isLoadingMessages},
    } = state;
    return {
      isAuthenticated: auth.isAuthenticated,
      isLoggedOut: auth.isLoggedOut,
      hasCompaniesWithoutDSODAndInventoryItems: hasCompaniesWithoutDSODAndInventoryItems,
      userId: auth.id,
      user: auth.user,
      email: auth.email,
      isInternal: auth.isInternal,
      companies: companies,
      companiesWithDSOD: companiesWithDSOD,
      selectedCompany: `${auth.selectedCompany}`,
      currentOpenOrders,
      order,
      orders,
      quote,
      quotes,
      quotesUnreadCounts: unreadCounts,
      chatsUnreadCounts: chatsUnreadCounts,
      isLoadingMessages: isLoadingMessages,
      isLoading:
        auth.isLoading ||
        state.users.isLoading ||
        state.geo.isLoading ||
        state.discounted.isLoading ||
        state.businesses.isLoading ||
        state.paymentTerms.isLoading ||
        state.brands.isLoading ||
        state.art.isLoading ||
        state.embroideryTypes.isLoading ||
        state.products.isLoading ||
        state.fabrics.isLoading ||
        state.orders.isLoading ||
        state.orders.isOpenOrderLoading ||
        state.inventory.isLoading ||
        state.shipment.isLoading ||
        state.adornmentTypes.isLoading ||
        state.embroideryTypes.isLoading ||
        state.embroideryTypes.isLoading ||
        state.dsodItems.isLoading,
    };
  };

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent)
  );
}
