import React, {Component} from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import ValidableSelect from "../../../../shared/ValidableSelect";
import ValidableTextField from "../../../../shared/ValidableTextField";
import ChillButton from "../../../../shared/ChillButton";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";
import CircularProgress from "material-ui/CircularProgress";
const iconStyle = {
  right: "-16px",
  ...SelectFieldIconStyle,
};

class SewOutForm extends Component {
  state = {
    notes: null,
  };

  handleChange = name => (event, index, value) => {
    value = _get(event, "target.value") || value;
    this.setState({
      [name]: value,
    });
  };

  handleCreate = () => {
    const {
      createOrderItem,
      companyId,
      onClose,
      onCreated,
      orderItemTypeId,
    } = this.props;
    createOrderItem(
      companyId,
      {
        notes: this.state.notes,
        unit_cost: 0,
        isSewOut: true,
        orderItemTypeId,
      },
      () => {
        onClose();
        onCreated();
      }
    );
  };

  isValidForm = () => {
    let attributes = ["notes"];
    return attributes.every(attribute => !!this.state[attribute]);
  };

  render() {
    const {
      itemTypeOptions,
      selectedItemTypeId,
      onChangeOrderItemType,
    } = this.props;

    const {notes} = this.state;

    return (
      <React.Fragment>
        <div className="flex">
          <ValidableSelect
            name="orderItemTypeId"
            label="Item"
            errorText={null}
            value={selectedItemTypeId || ""}
            handleChange={onChangeOrderItemType}
            underlineStyle={formStyles.underlineStyle}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            style={{width: 260, marginRight: 24}}
            iconStyle={iconStyle}
            inputStyle={{width: "100%"}}
            floatingLabelFixed={true}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            options={itemTypeOptions}
            hintText="Select"
          />

          <ValidableTextField
            multiLine
            rowsMax={4}
            name="notes"
            label="Description"
            type="text"
            value={notes}
            onChange={this.handleChange("notes")}
            style={{width: 260, marginRight: 24}}
            inputStyle={{...formStyles.TextInputStyles, width: 260}}
            underlineStyle={formStyles.underlineStyle}
          />
        </div>

        {this.props.isLoading ? (
          <div
            style={{
              margin: " 25px 0",
              textAlign: "center",
            }}
          >
            <div className="auth-button-progress-container">
              <CircularProgress
                size={24}
                thickness={4}
                color="rgba(255, 255, 255, 0.5)"
              />
            </div>
            <span>Loading...</span>
          </div>
        ) : (
          <ChillButton
            onClick={this.handleCreate}
            name="Save"
            useFontIcon={true}
            icon="check"
            fontIconStyles={{
              color: "#FFFFFF",
              fontSize: 18,
              marginRight: 4,
            }}
            isDisabled={!this.isValidForm()}
            className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
            height={41}
            marginTop={37}
            marginBottom={36}
          />
        )}
      </React.Fragment>
    );
  }
}

SewOutForm.propTypes = {
  isLoading: PropTypes.bool,
  companyId: PropTypes.number,
  itemTypeOptions: PropTypes.array,
  onChangeOrderItemType: PropTypes.func,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  createOrderItem: PropTypes.func,
  selectedItemTypeId: PropTypes.number,
  orderItemTypeId: PropTypes.number.isRequired,
};

export default SewOutForm;
