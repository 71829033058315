import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Paper from "material-ui/Paper";
import ChatIcon from "material-ui/svg-icons/communication/chat";
import FontIcon from "material-ui/FontIcon";

import Messenger from "../../services/MessagingService";
import ChatSystem from "../v2/shared/ChatSystem";
import CircularProgress from "./CircularProgress";
import Typography from "../v2/shared/Typography";

class GeneralChat extends Component {
  state = {
    chatOpen: false,
    unreadMessageCount: 0,
  };

  async componentDidMount() {
    const user = {
      id: this.props.userId,
      name: this.props.userName,
    };
    this.messenger = new Messenger(user);
    if (!this.messenger.sb.currentUser) await this.messenger.init();
    const channels = await this.messenger.getChannelsList();
    if (channels.length) {
      this.setState({unreadMessageCount: channels[0].unreadMessageCount});
    }
    this.messenger.addOnMessageReceivedHandler(
      "general",
      this.handleMessageReceived
    );
  }

  componentWillUnmount() {
    this.messenger.removeOnMessageReceivedHandler("general");
  }

  handleMessageReceived = channel => {
    const {chatOpen} = this.state;
    const data = JSON.parse(channel.data);
    if (data.type === "general") {
      if (chatOpen) {
        channel.markAsRead();
      } else {
        this.setState({unreadMessageCount: channel.unreadMessageCount});
      }
    }
  };

  handleOpenChat = () => this.setState({chatOpen: true, unreadMessageCount: 0});

  handleCloseChat = () => this.setState({chatOpen: false});

  renderTitle = () => (
    <Typography
      variant="h4"
      className="flex flex-center justify-content-between pointer w-100 padding-8"
      color="white"
    >
      <span className="flex flex-center">
        Chill N Go
        <span
          className="badge"
          style={{position: "relative", top: 0, backgroundColor: "lightgreen"}}
        />
      </span>
      <FontIcon
        className="material-icons"
        color="white"
        onClick={this.handleCloseChat}
      >
        close
      </FontIcon>
    </Typography>
  );

  render() {
    const {userId, userName, isLoadingMessages} = this.props;
    const {chatOpen, unreadMessageCount} = this.state;
    return (
      <Paper
        className="flex flex-center justify-content-center pointer general-chat"
        onClick={chatOpen ? null : this.handleOpenChat}
        style={{
          position: "absolute",
          bottom: chatOpen ? 1 : 80,
          right: chatOpen ? 24 : 32,
          backgroundColor: "#195B77",
          width: chatOpen ? 320 : 64,
          height: chatOpen ? 400 : 64,
          ...(chatOpen
            ? {borderTopRightRadius: 4, borderTopLeftRadius: 4}
            : {borderRadius: "50%"}),
        }}
      >
        {chatOpen && isLoadingMessages ? (
          <div style={{position: "absolute"}}>
            <CircularProgress color="white" />
          </div>
        ) : null}
        {chatOpen ? (
          <ChatSystem
            type="general"
            typeData={{id: userId, name: userName}}
            typeIdName="id"
            customTitle={this.renderTitle()}
            slimVersion
            style={isLoadingMessages ? {opacity: 0} : {opacity: 1}}
          />
        ) : (
          <Fragment>
            {unreadMessageCount ? (
              <span className="badge">{unreadMessageCount}</span>
            ) : null}
            <ChatIcon color="white" style={{minWidth: 32, height: 32}} />
          </Fragment>
        )}
      </Paper>
    );
  }
}

GeneralChat.propTypes = {
  userId: PropTypes.any,
  userName: PropTypes.string,
  isLoadingChat: PropTypes.bool,
  isLoadingMessages: PropTypes.bool,
};

export default GeneralChat;
