import React, {Component} from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";

export default class ChillButton extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    icon: PropTypes.string,
    secondary: PropTypes.bool,
    height: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widthIcon: PropTypes.number,
    marginRightIcon: PropTypes.number,
    backgroundColor: PropTypes.string,
    customStyles: PropTypes.object,
    title: PropTypes.string,
    id: PropTypes.string,
    useFontIcon: PropTypes.bool,
    fontIconStyles: PropTypes.object,
  };

  _renderIcon() {
    const {
      widthIcon,
      marginRightIcon,
      useFontIcon,
      fontIconStyles,
      icon,
    } = this.props;
    const iconStyles = {
      width: widthIcon,
      height: widthIcon,
      marginRight: marginRightIcon,
    };
    if (useFontIcon) {
      return (
        <FontIcon
          className="material-icons"
          style={{
            ...iconStyles,
            ...fontIconStyles,
          }}
        >
          {icon}
        </FontIcon>
      );
    }

    return (
      <img
        src={`/assets/imgs/icons/${icon}.svg`}
        className="btn-icon-size"
        style={iconStyles}
      />
    );
  }

  render() {
    const {
      height,
      width,
      onClick,
      name,
      icon,
      className,
      secondary,
      label,
      isDisabled,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      backgroundColor,
      tabIndex,
      customStyles,
      title,
      id,
    } = this.props;

    return (
      <button
        {...{id, title}}
        className={`chill-button pointer ${className} ${
          secondary ? "chill-button--secondary" : ""
        } ${isDisabled ? "chill-button--disabled " : ""} `}
        style={{
          width,
          height,
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
          backgroundColor,
          ...customStyles,
        }}
        onClick={onClick ? event => onClick(event) : null}
        disabled={isDisabled}
        label={label}
        tabIndex={tabIndex}
        title={title}
      >
        {icon ? this._renderIcon() : null}
        {name}
      </button>
    );
  }
}
