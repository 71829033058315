import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import _sumBy from "lodash/sumBy";
import FontIcon from "material-ui/FontIcon";
import Checkbox from "material-ui/Checkbox";
import _isNil from "lodash/isNil";
import ChillButton from "../../shared/ChillButton";
import BreadCrumbs from "../../shared/BreadCrumbs";
import { MISCELLANEOUS_COST } from "../../../constants/orderItemType";
import ReorderForm from "./components/ReorderForm";
import ReorderItems from "./components/ReorderItems";
import NewOrderItemModal from "./components/NewOrderItemModal";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import ReorderHeader from "./components/ReorderHeader";
import CNGCard from "../../shared/CNGCard";
import RecipientForm from "../../containers/DSOD/RecipientFormContainer";
import RecipientTable from "./components/RecipientTable";
import ConfirmationModal from "../../shared/ConfirmationModal";
import PageIndexHeader from "../../shared/PageComponents/PageIndexHeader";
import ContentLoader from "../../shared/ContentLoader";
import FlatText from "../../shared/FlatText";
import { SHIPPING_ACCOUNT_NONE } from "../../../constants/order";
import { orderCanBeSubmitted } from "../../../utils/orderSatusMachine";

const checkboxStyle = {
  block: {
    marginRight: 16,
    alignSelf: "center",
  },
  checkbox: {
    color: "#747D86",
    borderColor: "yellow",
  },
  label: {
    color: "#747D86",
    width: 187,
    fontSize: 14,
  },
  icon: {
    marginRight: 5,
    color: "#BDC6CF",
  },
};

class ReorderCreatePage extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.submitted !== null &&
      prevProps.submitted !== this.props.submitted
    ) {
      this.setState({ submitted: false });
    }
  }

  state = {
    submitted: false,
    confirmOpen: false,
    confirmArchiveOpen: false,
    openNewOrderItemModal: false,
    confirmOrderOpen: false,
    confirmationMessage: "",
    titleConfirmOrder: "",
    errors: {},
  };

  _totalDiscount = () => {
    const { orderItems } = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.totalDiscount,
      0
    );
  };

  _totalEmbroideryCost = () => {
    const { orderItems } = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.totalAdornmentCost,
      0
    );
  };

  _totalNetUnitCost = () => {
    const { orderItems } = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.netUnitCost,
      0
    );
  };

  _totalOrder = () => {
    const { orderItems } = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.orderTotal,
      0
    );
  };

  handleRefreshItems = async () => {
    const {
      fetchCompanyContacts,
      fetchOpenOrders,
      company,
      updateOrderState,
    } = this.props;

    await fetchCompanyContacts(company.id);
    const openOrders = await fetchOpenOrders(company.id);
    updateOrderState(openOrders);
  };

  handleToggleProofCheckboxes = checkName => () => {
    const {
      orderData,
      sameRecipient,
      shipments,
      onToggleSameRecipient,
      onOrderChange,
      onSaveDraft,
    } = this.props;
    const shipment = shipments && shipments[0];
    const check = _get(orderData, checkName);
    if (sameRecipient) {
      if (_get(shipment, "recipientId")) {
        this.setState({
          confirmOpen: true,
          confirmationMessage: "Are you sure? This will clear the recipient.",
          [checkName]: true,
        });
        return;
      } else {
        onToggleSameRecipient();
      }
    }
    onOrderChange(null, !check, checkName, onSaveDraft);
  };

  renderCheckbox = (isShippingAccountNone = false, requiredProof = false) => {
    const {
      sameRecipient,
      onToggleSameRecipient,
      shipments,
      submitted,
      order,
      isInternal,
      isDisabled
    } = this.props;

    const shipment = shipments && shipments[0];
    return (
      <Fragment>
        <div className="flex" style={checkboxStyle.block}>
          {
            !isShippingAccountNone && !isDisabled() &&
            !requiredProof && (
              <div>
                <Checkbox
                  label="Send all to same recipient."
                  iconStyle={checkboxStyle.icon}
                  inputStyle={checkboxStyle.checkbox}
                  labelStyle={checkboxStyle.label}
                  checked={sameRecipient}
                  onCheck={() => {
                    if (sameRecipient && _get(shipment, "recipientId")) {
                      this.setState({
                        confirmOpen: true,
                        confirmationMessage:
                          "Are you sure? This will clear the recipient.",
                      });
                    } else {
                      onToggleSameRecipient();
                    }
                  }}
                />
              </div>
            )}
        </div>
        <div className="flex" style={checkboxStyle.block}>
          {!isInternal && _get(order, "quote.requiresRecipient") && (
            <div>
              <Checkbox
                label="Ship instructions complete"
                iconStyle={checkboxStyle.icon}
                inputStyle={checkboxStyle.checkbox}
                labelStyle={checkboxStyle.label}
                checked={submitted}
                onCheck={() => { this.setState({ submitted: true }); }}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  hasInvalidMiscellaneous = orderItem => {
    const { shipments } = this.props;
    if (_get(orderItem, "orderItemType.name") !== MISCELLANEOUS_COST) return;
    if (_get(shipments, "[0].recipientId")) return;

    const shipmentItems = _get(shipments, "[0].items", []).filter(
      ({ orderItemId }) => Number(orderItemId) === Number(orderItem.id)
    );
    return _sumBy(shipmentItems, "shippedQuantity") < orderItem.orderQuantity;
  };

  isFormValid = () => {
    const {
      orderData: {
        createdUserId,
      },
      order,
      isInternal,
      currentUserId,
    } = this.props;

    if (currentUserId === createdUserId && isInternal) {
      return false;
    }
    const { shipments, shipmentData} = this.props;
    let haveShipmentItems = _get(shipments, "[0].items.length") > 0 || !_isNil(_get(shipments, "[0].recipientId"));
    let isNoneAllToInventory = _get(shipmentData, "shipment_account_number") === "NONE";

    if( !haveShipmentItems && !isNoneAllToInventory){
      return false;
    }

    if (order) {
      if (_get(order, "items", []).length === 0) return false;
      const hasInvalidItems = !!order.items.find(
        item => !item.orderQuantity || this.hasInvalidMiscellaneous(item)
      );
      if (hasInvalidItems) return false;
    }

    let { requiredAttributes } = this.requiredAttributes();

    const no_empty = requiredAttributes.every(attribute => {
      return !!attribute && name.trim() === "";
    });

    return no_empty;
  };

  validateFields = (validate) => {
    const { isInternal, isLoading, order} = this.props;
    const { requiredAttributes, requiredAttributesNames } = this.requiredAttributes();

    let errors = {};
    if (!isLoading && !isInternal && validate) {

      errors = requiredAttributes.reduce((errors, attribute, index) => {
        if (!attribute && name.trim() === "") {
          return { ...errors, [requiredAttributesNames[index]]: "required" };
        }
        return errors;
      }, {}
      );

      if (order) {
        const hasInvalidItems = !!order.items.find(item => !item.orderQuantity);
        if (hasInvalidItems) { errors = { ...errors, orderQuantity: "can't be 0" }; };
      }

      this.setState(
        {
          errors: errors
        }
      );

    }

    return isEmpty(errors);
  }

  requiredAttributes = () => {
    const {
      orderData: {
        estimatedShipOn,
        purchaseOrderNumber,
        jobName,
        billingCompanyContactId,
        createdUserId,
      },
      shipmentData: {
        shipment_account_id,
        shipping_method_id,
        shipment_account_number,
      },
      isInternal,
    } = this.props;

    let requiredAttributes = [purchaseOrderNumber, billingCompanyContactId];
    let requiredAttributesNames = ["purchaseOrderNumber", "billingCompanyContactId"];
    if (shipment_account_number !== SHIPPING_ACCOUNT_NONE) {
      requiredAttributes.push(shipment_account_id, shipping_method_id);
      requiredAttributesNames.push("shipment_account_id", "shipping_method_id");
    }

    if (isInternal) {
      requiredAttributes = [
        ...requiredAttributes,
        jobName,
        estimatedShipOn,
        createdUserId,
      ];
    }

    return { requiredAttributes, requiredAttributesNames };
  }

  handleOpenOrderItemModal = () => {
    this.setState({ openNewOrderItemModal: true });
  };

  handleConfirmOrderModal = () => {
    const { isInternal } = this.props;
    const titleModal = isInternal ? "Are you sure you want to remove EU control of input?" : "Are you sure you want to confirm the order?";

    this.setState({
      confirmOrderOpen: true,
      titleConfirmOrder: titleModal
    });
  };
  handleClearErrors = (field) => {
    this.setState({
      errors: {
        [field]: null
      }
    });
  }

  renderNewOrderItemSection = () => {
    return (
      <div className="new-order--section flex flex-center justify-content-center">
        <span
          className="small-header new-order--add-link flex flex-center uppercase pointer"
          onClick={this.handleOpenOrderItemModal}
        >
          <FontIcon
            className="material-icons"
            style={{
              color: "#1D9ABB",
            }}
          >
            add_circle_outline
          </FontIcon>
          <span>Add order item</span>
        </span>
      </div>
    );
  };

  archiveOrder = () => {
    const { archiveOrder, order, location } = this.props;
    archiveOrder(order.id, () => {
      if (_get(location, "state.fromQuote", false)) {
        return this.goToQuotePage();
      }
      this.goToCompanyPage();
    });
  };

  goToCompanyPage = () => {
    const { history, match: { params: { company_id: companyId } } } = this.props;
    history.push(`/companies/${companyId}?page=1&query=&tab=orders`);
  };

  goToQuotePage = () => {
    const { history, location } = this.props;

    history.push(`/quotes/info/${_get(location, "state.quoteId")}`);
  };

  renderOrderWarning = () => {
    const { order, shipments } = this.props;
    const sewOutRequested = _get(order, "sewOutRequested", false);
    const preProductionRequested = _get(order, "preProductionRequested", false);
    if (!sewOutRequested && !preProductionRequested) return;
    if (!orderCanBeSubmitted(order, shipments)) {
      return <FlatText>This Order pends physical proofing.</FlatText>;
    }
  };

  renderAddRecipientWarning = () => {
    const { order: { isOpen } } = this.props;
    if (!(isOpen)) return null;

    const text = ` Please complete the required fields (*). Under Shipping Account, 
    choose a shipper or select None. If shipper chosen, choose Shipping Method and then use 'Add Recipient' 
    under order item, or check the 'Send All To Same Recipient' box. Select or input recipient(s). Any items not shipped 
    will appear in your inventory for shipment at will (DSOD command). Click 'Confirm' to proceed.`;

    return <FlatText>{text}</FlatText>;
  };

  render() {
    const {
      sameRecipient,
      company,
      companyContacts,
      order,
      orderData,
      shipmentData,
      companyShippingCarrierAssignments,
      isLoading,
      isInternal,
      isDisabled,
      shippingMethods,
      onShipmentChange,
      onOrderChange,
      fetchOpenOrders,
      onSubmitReorder,
      fetchShipments,
      fetchCompanyContacts,
      onUpdateShipmentRecipient,
      shipments,
      onToggleSameRecipient,
      onSaveDraft,
      updateUserWithCompanies,
      walletMembers,
      createWalletMember,
      paymentTerms,
      currentUserId,
      deleteShipmentItem,
      location,
      navigateTo,
    } = this.props;
    const fromQuote = _get(location, "state.fromQuote", false);
    const shipment = shipments && shipments[0];
    const { openNewOrderItemModal } = this.state;
    const isShippingAccountNone =
      _get(shipmentData, "shipment_account_number") === SHIPPING_ACCOUNT_NONE;

    const existingOrderByOtherUser =
      !isInternal && !order && (order && order.createdUserId !== currentUserId);
    const requiredProof =
      _get(order, "sewOutRequested") === true ||
      _get(order, "preProductionRequested") === true;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
        subHeaderText={company && company.name}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          pageName={fromQuote ? "Order Cart" : "Reorder Cart"}
        />
        <ContentLoader isLoading={isLoading}>
          {existingOrderByOtherUser && (
            <ConfirmationModal
              open
              height={400}
              title="OOPS - we’re sorry!!"
              content={
                <React.Fragment>
                  <span>
                    Another {company && company.name} user has your Order cart
                    open. We can open only one cart for you at a time. Please be
                    patient and try again later.
                  </span>
                  <br />
                  <br />
                  <span>Thanks for your understanding!</span>
                  <br />
                  <br />
                  <span>Team chill-n-go</span>
                </React.Fragment>
              }
              confirmText="OK"
              cancelText=" "
              onConfirm={() => this.goToCompanyPage()}
              onDismiss={() => this.goToCompanyPage()}
            />
          )}
          {this.renderOrderWarning()}
          {!isInternal && !orderData.isConfirmed && this.renderAddRecipientWarning()}
          {order && (isInternal || order.createdUserId === currentUserId) ? (
            <div>
              <ReorderHeader
                noSearch
                noButton={!isInternal}
                titleMaterialIcon
                title="Create an Order"
                titleIcon="shopping_cart"
                createPage="/users/create"
                buttonIcon="check"
                buttonText={"CREATE ORDER"}
                buttonWidth={160}
                buttonIsDisabled={!this.isFormValid()}
                customOptions={this.renderCheckbox(
                  isShippingAccountNone,
                  requiredProof
                )}
                buttonClick={() => {
                  this.isFormValid() ? this.setState({ submitted: true }) : null;
                }}
                customElement={
                  isInternal &&
                  <ChillButton
                    className="pageIndexHeader_btn"
                    name={"REMOVE"}
                    icon={"trash3"}
                    height={40}
                    width={140}
                    onClick={() => this.setState({ confirmArchiveOpen: true })}
                  />
                }
                isConfirmed={orderData.isConfirmed}
                isInternal={isInternal}
                confirmOrderModal={this.handleConfirmOrderModal}
                validateFields={this.validateFields}
              />
              <ReorderForm
                isDisabled={isDisabled}
                createWalletMember={createWalletMember}
                order={order}
                orderData={orderData}
                onSaveDraft={onSaveDraft}
                shipmentData={shipmentData}
                shippingMethods={shippingMethods}
                companyShippingCarrierAssignments={
                  companyShippingCarrierAssignments
                }
                onShipmentChange={onShipmentChange}
                onOrderChange={onOrderChange}
                updateUserWithCompanies={updateUserWithCompanies}
                company={company}
                billingCompanyContacts={walletMembers}
                submitted={this.state.submitted}
                submitSuccess={onSubmitReorder}
                isInternal={isInternal}
                submitError={() => this.setState({ submitted: false })}
                paymentTerms={paymentTerms}
                sameRecipient={sameRecipient}
                onShippingAccountNone={shippingAccount => {
                  if (sameRecipient) {
                    this.setState({
                      confirmOpen: true,
                      confirmationMessage:
                        "Are you sure? This will clear the recipient.",
                      noneShippingAccount: shippingAccount,
                    });
                    return false;
                  }
                  if (shipment && shipment.items) {
                    shipment.items.map(shipmentItem =>
                      deleteShipmentItem(shipmentItem.id)
                    );
                  }
                  return true;
                }}
                onToggleProofCheckboxes={this.handleToggleProofCheckboxes}
                errors={this.state.errors}
                clearError={this.handleClearErrors}
              />{" "}
              {!isShippingAccountNone &&
                sameRecipient && (
                <CNGCard
                  title="Recipient"
                  style={{
                    paddingBottom: 1,
                    paddingTop: 27,
                  }}
                >
                  {shipment && shipment.recipientId ? (
                    <RecipientTable
                      isDisabled={isDisabled}
                      shipmentItems={shipment && [shipment]}
                      sameRecipient={sameRecipient}
                      shipments={this.props.shipments}
                      companyId={Number(company.id)}
                      companyContacts={companyContacts}
                      updateShipmentItem={(shipmentItem, recipientId) => {
                        onUpdateShipmentRecipient(recipientId);
                      }}
                      onDeleteShipmentItem={() => {
                        onUpdateShipmentRecipient(null);
                      }}
                    />
                  ) : (
                    <RecipientForm
                      hideIsProof
                      onClose={onToggleSameRecipient}
                      containerStyle={{
                        marginBottom: 32,
                      }}
                      companyId={Number(company.id)}
                      companyContacts={companyContacts}
                      onSaveRecipient={recipient => {
                        onUpdateShipmentRecipient(recipient.id);
                        this.handleClearErrors("orderQuantity");
                      }}
                    />
                  )}
                </CNGCard>
              )}
              {isInternal && !isDisabled() ? this.renderNewOrderItemSection() : null}
              <ReorderItems
                hasInvalidMiscellaneous={this.hasInvalidMiscellaneous}
                isInternal={isInternal}
                isDisabled={isDisabled}
                isConfirmed={orderData.isConfirmed}
                reorderItems={order && order.items}
                sameRecipient={isShippingAccountNone || sameRecipient}
                company={company}
                fetchOpenOrders={fetchOpenOrders}
                fetchShipments={fetchShipments}
                fetchCompanyContacts={fetchCompanyContacts}
                order={order}
                orderData={orderData}
                onOrderChange={onOrderChange}
                onSaveDraft={onSaveDraft}
                updateOrderState={this.props.updateOrderState}
                companyContacts={companyContacts}
                isOrderValid={this.isFormValid()}
                onSubmitOrder={() => {
                  this.isFormValid() ? this.setState({ submitted: true }) : null;
                }}
                confirmOrderModal={this.handleConfirmOrderModal}
                buttonIsDisabled={!this.isFormValid()}
                errors={this.state.errors}
                validateFields={this.validateFields}
                clearError={this.handleClearErrors}
              />
              {openNewOrderItemModal ? (
                <NewOrderItemModal
                  isLoading={isLoading}
                  onClose={() => {
                    this.setState({ openNewOrderItemModal: false });
                  }}
                  onCreated={() => {
                    this.handleRefreshItems();
                  }}
                />
              ) : null}
              <ConfirmationModal
                title={this.state.confirmationMessage}
                open={this.state.confirmOpen}
                onConfirm={() => {
                  const {
                    noneShippingAccount,
                    preProductionRequested,
                    sewOutRequested,
                  } = this.state;
                  const newState = { confirmOpen: false };
                  if (noneShippingAccount) {
                    newState.noneShippingAccount = null;
                  }
                  if (preProductionRequested) {
                    newState.preProductionRequested = null;
                  }
                  if (sewOutRequested) {
                    newState.sewOutRequested = null;
                  }
                  this.setState(newState, () => {
                    onUpdateShipmentRecipient(null);
                    if (noneShippingAccount) {
                      onShipmentChange(null, {
                        shipping_carrier_name: "",
                        shipping_carrier_id: null,
                        shipment_account_number:
                          noneShippingAccount.accountNumber,
                        shipment_account_id: Number(noneShippingAccount.id),
                        shipping_method_id: null,
                      });
                    }
                    if (preProductionRequested) {
                      onOrderChange(
                        null,
                        true,
                        "preProductionRequested",
                        onSaveDraft
                      );
                    }
                    if (sewOutRequested) {
                      onOrderChange(null, true, "sewOutRequested", onSaveDraft);
                    }
                  });
                }}
                onDismiss={() => {
                  this.setState({ confirmOpen: false });
                }}
                height={250}
              />
            </div>
          ) : (
            <React.Fragment>
              <PageIndexHeader
                noButton
                noSearch
                titleMaterialIcon
                containerClass="dsodindex_top"
                title="Create an Order"
                titleIcon="shopping_cart"
              />
              <CNGCard showDivider={false}>
                <h2
                  className="userindex_noUsers"
                  style={{
                    marginBottom: 32,
                  }}
                >
                  You currently do not have an active Re-Order Cart
                </h2>
              </CNGCard>
            </React.Fragment>
          )}
        </ContentLoader>

        <ConfirmationModal
          title="Are you sure you want to delete this order?"
          open={this.state.confirmArchiveOpen}
          onConfirm={() => {
            this.setState(
              {
                confirmArchiveOpen: false,
              },
              () => this.archiveOrder()
            );
          }}
          onDismiss={() => {
            this.setState({ confirmArchiveOpen: false });
          }}
          height={250}
        />
        <ConfirmationModal
          title={this.state.titleConfirmOrder}
          confirmText="Yes"
          cancelText="No"
          open={this.state.confirmOrderOpen}
          onConfirm={() => {
            this.setState(
              {
                confirmOrderOpen: false,
              },
              () => onOrderChange(null, true, "isConfirmed", () => {
                onSaveDraft();
                !isInternal && navigateTo("/");
              })
            );
          }}
          onDismiss={() => {
            this.setState({ confirmOrderOpen: false });
          }}
          height={250}
        />

      </PageLayout>
    );
  }
}

ReorderCreatePage.propTypes = {
  isDisabled: PropTypes.func,
  fetchOpenOrders: PropTypes.func,
  isOrderLoading: PropTypes.bool,
  updateOrderState: PropTypes.func.isRequired,
  onUpdateOrderName: PropTypes.func,
  currentUserId: PropTypes.number,
  createWalletMember: PropTypes.func,
  onUpdateOrderStatus: PropTypes.func,
  onToggleReorder: PropTypes.func,
  onToggleReorderItem: PropTypes.func,
  onToggleSameRecipient: PropTypes.func,
  onOrderChange: PropTypes.func,
  archiveOrder: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onSubmitReorder: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onUpdateShipmentRecipient: PropTypes.func,
  company: PropTypes.object,
  companyContacts: PropTypes.array,
  order: PropTypes.object,
  orderData: PropTypes.object,
  shipmentData: PropTypes.object,
  orderItems: PropTypes.array,
  countries: PropTypes.array,
  sameRecipient: PropTypes.bool,
  shipments: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingCarriers: PropTypes.array,
  shippingMethods: PropTypes.array,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReordering: PropTypes.bool,
  reorderItems: PropTypes.array,
  orderStatus: PropTypes.array,
  billingCompanyContacts: PropTypes.array,
  fetchShipments: PropTypes.func,
  fetchCompanyContacts: PropTypes.func,
  updateUserWithCompanies: PropTypes.func,
  walletMembers: PropTypes.array,
  submitted: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  paymentTerms: PropTypes.array,
  deleteShipmentItem: PropTypes.func,
  location: PropTypes.object,
  navigateTo: PropTypes.func,
};

export default ReorderCreatePage;
