import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import ResetPage from "../pages/ResetPage";
import * as qs from "query-string";

class Reset extends Component {
  render() {
    return (
      <ResetPage
        token={qs.parse(this.props.location.search).token}
        resetPassword={(token, password) =>
          this.props.resetPassword(token, password)
        }
        loading={this.props.loading}
        error_message={this.props.error_message}
        success_message={this.props.success_message}
      />
    );
  }
}

Reset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error_message: PropTypes.string,
  success_message: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    error_message: state.auth.error_message,
    success_message: state.auth.success_message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (token, password) =>
      dispatch(actions.resetPassword(token, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
