import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import changeCaseKeys from "change-case-keys";
import lodash from "lodash";

import ArtEditPage from "../../pages/Arts/ArtEditPage";
import {
  updateArt,
  updateArtRevision,
  fetchArtRevisions,
  fetchArt,
} from "../../../actions/artActions";
import {fetchBrand} from "../../../actions/brandActions";
import {openNotification} from "../../../actions/notificationActions";
import {fetchAdornmentTypes} from "../../../actions/adornmentTypeActions";
import {VALID_IMAGE_FORMATS} from "../../../constants/art";

class ArtEdit extends Component {
  static propTypes = {
    openNotification: PropTypes.func.isRequired,
    fetchBrand: PropTypes.func.isRequired,
    fetchAdornmentTypes: PropTypes.func.isRequired,
    fetchArtRevisions: PropTypes.func.isRequired,
    fetchArt: PropTypes.func.isRequired,
    updateArt: PropTypes.func.isRequired,
    updateArtRevision: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    brand: PropTypes.object,
    adornmentTypes: PropTypes.array,
    artRevisions: PropTypes.array,
    artCount: PropTypes.number,
    isLoading: PropTypes.bool,
    selectedArt: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    filename: null,
    name: null,
    adornment_type_id: null,
    file: null,
    currentPage: 1,
    details: {},
    restoringRevision: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedArt !== this.props.selectedArt) {
      const {brand, selectedArt} = this.props;
      this._redirectArts(selectedArt, brand);
    }
  }

  componentWillMount() {
    this._fetchArtEditData(...arguments);
  }

  _fetchArtWithRevision = (id, state) => {
    fetchArt(id, state.selectedArt.revisions[0].id);
  };

  async _fetchArtEditData() {
    const {
      match,
      fetchBrand,
      fetchArt,
      fetchArtRevisions,
      fetchAdornmentTypes,
    } = this.props;

    const {brand_id: brandId, id} = match.params;
    fetchBrand(brandId);
    fetchAdornmentTypes();
    fetchArtRevisions(id);
    fetchArt(id);
  }

  getArtRevision = selectedArt => {
    return lodash.get(selectedArt, "revisions[0]", null);
  };

  _redirectArts = selectedArt => {
    const art = this.getArtRevision(selectedArt);
    if (!art) return;

    const {id, adornmentTypeId, adornmentDetails, thumbnail} = art;

    this.setState({
      revision_id: id,
      thumbnail,
      adornment_type_id: adornmentTypeId,
      details: {
        ...changeCaseKeys(adornmentDetails, "underscored"),
        thread_colors: null,
      },
    });
  };

  fetchArtRevisions = options => {
    const {fetchArtRevisions, match: {id}} = this.props;
    fetchArtRevisions(id, {
      ...options,
      orderBy: "created_at",
      sortDirection: "desc",
    });
  };

  handleSelectPage = options => {
    this.setState(
      {
        currentPage: options.page,
      },
      () => this.fetchArtRevisions(options)
    );
  };

  handleChange = (error, index, value, formName) => {
    let name = formName || error.target.name;
    if (name === "adornment_type_id") {
      this.setState({details: {}});
    }

    this.setState({[name]: value || index});
  };

  handleDetailChange = (error, index, value, formName) => {
    const name = formName || error.target.name;
    this.setState({
      details: {
        ...this.state.details,
        [name]: value || index,
      },
    });
  };

  handleFileChange = inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      return openNotification(
        "Uploaded file is not a valid input image. Only png, jpg and jpg"
      );
    }

    this.setState({file: file, filename: file.name});
  };

  setRevisionImage = async file => {
    this.setState({file, filename: file.name});
  };

  handleUpdateArt = async () => {
    const {
      history,
      selectedArt: {id},
      match: {params: {id: artId, brand_id: brandId}},
    } = this.props;

    this.props.updateArt(
      id,
      {
        ...this.state,
      },
      art => {
        const revision = lodash.last(art.revisions);
        history.push(
          `/brands/${brandId}/arts/${artId}/revisions/${revision.id}`
        );
      }
    );
  };

  render() {
    return (
      <ArtEditPage
        onUpdateImage={this.setRevisionImage}
        onCreateArt={this.handleUpdateArt}
        onUpdateRevision={this.handleUpdateArtRevision}
        onChange={this.handleChange}
        onDetailChange={this.handleDetailChange}
        onFileChange={this.handleFileChange}
        onSelectPage={this.handleSelectPage}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    brands: {brand},
    art: {artCount, isLoading, art, artRevisions, artRevisionsCount},
    adornmentTypes: {adornmentTypes},
  } = state;

  return {
    brand,
    isLoading,
    adornmentTypes,
    artCount: artCount,
    selectedArt: art,
    artRevisions,
    artRevisionsCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArtRevisions: bindActionCreators(fetchArtRevisions, dispatch),
    fetchBrand: bindActionCreators(fetchBrand, dispatch),
    fetchAdornmentTypes: bindActionCreators(fetchAdornmentTypes, dispatch),
    updateArt: bindActionCreators(updateArt, dispatch),
    updateArtRevision: bindActionCreators(updateArtRevision, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchArt: bindActionCreators(fetchArt, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtEdit)
);
