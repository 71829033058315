import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
// import Checkbox from "material-ui/Checkbox";

import AttributeLine from "../../../shared/AttributeLine";
import ArtFileInputContainer from "./ArtFileInputContainer";
import SectionHeading from "../../../shared/SectionHeading";

class ArtDetailInfo extends Component {
  handleActiveCheck = () => {
    const {isInternal, latestRevision, updateArtRevisionActive} = this.props;
    if (!isInternal) return;
    updateArtRevisionActive(latestRevision.artId, latestRevision.id);
  };

  _renderEmbroideryDetail() {
    const {isInternal, latestRevision} = this.props;
    if (!latestRevision) return;
    const {adornmentDetails, adornmentTypeApplicability} = latestRevision;

    return (
      <div
        className="flex flex-wrap"
        style={{
          width: 710,
          marginTop: 16,
        }}
      >
        <AttributeLine
          isInternal={isInternal}
          text={
            adornmentTypeApplicability ? adornmentTypeApplicability.name : ""
          }
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Applicability"
        />

        <AttributeLine
          isInternal={isInternal}
          text={`${adornmentDetails && adornmentDetails.stitchCount}K`}
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Stitch Count (K)"
        />

        <AttributeLine
          isInternal={isInternal}
          text={adornmentDetails && adornmentDetails.width}
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Width Size (inch)"
        />

        <AttributeLine
          isInternal={isInternal}
          text={adornmentDetails && adornmentDetails.height}
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Height Size (inch)"
        />

        <AttributeLine
          isInternal={isInternal}
          text={adornmentDetails && adornmentDetails.threadColors}
          style={{
            width: 329,
            height: "none",
          }}
          marginTop={16}
          labelText="Thread Colors"
        />
      </div>
    );
  }

  _renderEtchingDetail() {
    const {isInternal, latestRevision} = this.props;
    if (!latestRevision) {
      return;
    }
    const {adornmentDetails} = latestRevision;
    const {adornmentType: {internalIdentifier}} = latestRevision;

    return (
      <div
        className="flex flex-wrap"
        style={{
          width: 710,
          marginTop: 16,
        }}
      >
        {internalIdentifier === "etch_and_dye" && (
          <AttributeLine
            isInternal={isInternal}
            text={adornmentDetails.dyeColor}
            style={{
              width: 157,
              marginRight: 16,
            }}
            labelText="Dye Color"
          />
        )}

        <AttributeLine
          isInternal={isInternal}
          text={adornmentDetails.width}
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Width Size (inch)"
        />

        <AttributeLine
          isInternal={isInternal}
          text={adornmentDetails.height}
          style={{
            width: 157,
            marginRight: 16,
          }}
          labelText="Height Size (inch)"
        />
      </div>
    );
  }

  renderDetails() {
    const {latestRevision} = this.props;
    if (!latestRevision || typeof latestRevision !== "object") {
      return;
    }
    const {adornmentType} = latestRevision;
    if (!adornmentType || typeof adornmentType !== "object") {
      return;
    }
    const {internalIdentifier} = adornmentType;
    if (!internalIdentifier) {
      return;
    }
    switch (internalIdentifier) {
    case "embroidery":
    case "embroidery_large_format":
      return this._renderEmbroideryDetail();
    case "etching":
    case "name_drop_or_monogram":
    case "etch_and_dye":
      return this._renderEtchingDetail();
    default:
      return null;
    }
  }

  render() {
    const {latestRevision, isInternal} = this.props;

    return (
      <div className="art-form row" style={artForm}>
        <ArtFileInputContainer
          latestRevision={latestRevision}
          readOnly={true}
          isInternal={isInternal}
          fileInputText="REPLACE"
          fileInputIcon="replacefile-white"
        />
        <div style={artFormDetails}>
          <div
            className="art-details"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SectionHeading
                text="Art Name"
                textTransform="uppercase"
                marginBottom={12}
              />
              <AttributeLine
                isInternal={isInternal}
                text={_get(latestRevision, "name")}
                style={{
                  width: 329,
                }}
                labelText="Art Name"
                marginBottom={15}
              />
            </div>
            <SectionHeading
              text="Adornment Details"
              marginTop={18}
              marginBottom={12}
            />
            <AttributeLine
              isInternal={isInternal}
              text={_get(latestRevision, "adornmentType.name")}
              style={{
                width: 329,
              }}
              labelText="Adornment Type"
            />{" "}
            {this.renderDetails()}
          </div>
        </div>
      </div>
    );
  }
}
const artFormDetails = {
  paddingLeft: 50,
};
const artForm = {
  marginTop: 45,
};

ArtDetailInfo.propTypes = {
  art: PropTypes.object,
  latestRevision: PropTypes.object,
  isInternal: PropTypes.bool,
  updateArtRevisionActive: PropTypes.func,
};

export default ArtDetailInfo;
