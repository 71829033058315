import React, {Component} from "react";
import PropTypes from "prop-types";

const styles = {
  fontSize: 16,
  lineHeight: "40px",
  textAlign: "center",
  backgroundColor: "#195b77",
  height: 40,
  zIndex: 1,
  position: "fixed",
  width: "100vw",
  color: "white",
};

export default class SubHeading extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  render() {
    const {text} = this.props;
    return (
      <div
        id="sub-header"
        style={{
          ...styles,
        }}
      >
        {text}
      </div>
    );
  }
}
