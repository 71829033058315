import React, {Component} from "react";
import lodash from "lodash";
import classNames from "classnames";
import {Link} from "react-router-dom";
import BreadCrumbs from "../../../../shared/BreadCrumbs";
import PropTypes from "prop-types";
import Typography from "../../../shared/Typography";
import QuoteLayout from "../../QuoteLayout";
import withStyles from "../../../shared/WithStyles";
import RaisedButton from "../../../shared/RaisedButton";
import ProductImage from "../../../../pages/Products/Components/ProductImage";
import ProductDisabled from "../Products/ProductDisabled";

class ProductsPage extends Component {
  static propTypes = {
    fetchOrderItemProducts: PropTypes.func,
    orderItemProducts: PropTypes.array,
    match: PropTypes.object,
    styles: PropTypes.object,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
    company: PropTypes.object,
    createReorderItem: PropTypes.func,
    selectedCompanyId: PropTypes.number,
    history: PropTypes.object,
  };

  state = {
    selectedItems: [],
  };

  handleSelectedItem = item => {
    const {selectedItems} = this.state;
    let results;
    if (!item) return;
    if (selectedItems.includes(item.id)) {
      results = selectedItems.filter(id => id !== item.id);
    } else {
      results = [...selectedItems, item.id];
    }

    this.setState({
      selectedItems: results,
    });
  };

  componentDidMount() {
    const {
      match: {params: {company_id}},
      selectedCompanyId,
      isInternal,
      history,
    } = this.props;
    if (!isInternal && Number(company_id) !== Number(selectedCompanyId)) {
      return history.push("/");
    }
    this.props.fetchOrderItemProducts(company_id);
  }

  get pageStyle() {
    const {styles: {colors}} = this.props;
    return {
      container: {
        paddingTop: 20,
        paddingLeft: 41,
        height: "calc(100vh - 181px)",
      },
      footer: {
        height: 64,
        backgroundColor: colors.gray.lighter,
      },
      submitButton: {
        marginRight: 40,
      },
      title: {
        marginBottom: 14,
      },
      sidebarTitle: {
        marginBottom: 17,
      },
      sidebarContainer: {
        paddingLeft: 31,
        paddingTop: 16,
      },
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getSubTitleText = (fabricColor, leatherColor) => {
    const fabricName = lodash.get(fabricColor, "fabric.name");
    const colorName = lodash.get(fabricColor, "color.name");
    const leatherColorName = lodash.get(leatherColor, "color.name", "");

    if (fabricName === "None") return null;
    let subtitle = `${fabricName} ${colorName}`;
    if (leatherColorName) subtitle += `, ${leatherColorName}`;
    return subtitle;
  };

  createOrderItems = () => {
    const {createReorderItem} = this.props;
    const orderItemProducts = this.getSelectedOrderItemProducts();
    if (orderItemProducts.length === 0) return;
    const company = lodash.get(orderItemProducts, "[0].company");

    createReorderItem(
      company.id,
      orderItemProducts.map(({orderItemId}) => orderItemId)
    );
  };

  getSelectedOrderItemProducts = () => {
    const {orderItemProducts} = this.props;
    const {selectedItems} = this.state;
    if (!orderItemProducts) return;
    return orderItemProducts.filter(({id}) => selectedItems.includes(id));
  };

  renderItem = orderItemProduct => {
    const {
      product,
      fabricColor,
      leatherColor,
      orderItem,
      company,
      orderItemProductAdornmentLocationArtAssignments,
    } = orderItemProduct;
    const {selectedItems} = this.state;
    const { isLoading} = this.props;

    const orderItemImage =
      lodash.get(orderItemProductAdornmentLocationArtAssignments, "[0]") || {};

    const url = orderItemImage.filename
      ? orderItemImage.imageUrl
      : lodash.get(orderItem, "product.productImages[0].imageUrl");

    return (
      !orderItemProduct.product.isDeleted ?
        <div
          className={classNames("product-item", {
            selected: selectedItems.includes(orderItemProduct.id),
          })}
          key={orderItemProduct.id}
        >
          <div className="product-container">
            <div
              className="product-image pointer"
              onClick={() => this.handleSelectedItem(orderItemProduct)}
            >
              <ProductImage
                src={url}
                emptyStyle={{
                  maxHeight: "inherit",
                  width: "100%",
                  margin: 0,
                }}
                style={{
                  maxHeight: "inherit",
                  objectFit: "contain",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="product-info">
            <Typography variant="subtitle" style={{marginBottom:"4px"}}>
            Item #{lodash.get(product, "itemNumber")}
            </Typography>
            <div
              className="reset-link-styles product-info--name"
              title={lodash.get(product, "productName")}
            >
              <Typography
                variant="h5"
                style={{lineHeight: "16px"}}
                className="overflow-ellipsis"
              >
                {lodash.get(product, "productName")}
              </Typography>
            </div>
            <Typography variant="body" className="overflow-ellipsis">
              {fabricColor && this.getSubTitleText(fabricColor, leatherColor)}
            </Typography>
            <Typography variant="bodyBold" className="overflow-ellipsis">
            Last order:
              <Link
                to={`/companies/${lodash.get(company, "id")}/orders/${lodash.get(
                  orderItem,
                  "order.id"
                )}`}
                style={{marginLeft: 4}}
                className="reset-link-styles"
              >
                {lodash.get(orderItem, "order.cngReferenceNumber")}
              </Link>
            </Typography>
          </div>
        </div>
        :
        <ProductDisabled isLoading={isLoading} getSubTitleText={this.getSubTitleText} key={orderItemProduct.id} orderItemProduct={orderItemProduct} selectedItems={selectedItems}/>
    );
  };

  renderSidebar = () => {
    const {orderItemProducts} = this.props;
    const {selectedItems} = this.state;
    if (!orderItemProducts) return;
    const selectedOrderItemProducts = orderItemProducts.filter(({id}) =>
      selectedItems.includes(id)
    );
    const itemsSection = selectedOrderItemProducts.map(orderItemProduct => (
      <div key={orderItemProduct.id}>
        <Typography variant="body">
          {lodash.get(orderItemProduct, "product.productName")}
        </Typography>
      </div>
    ));
    return (
      <div style={this.pageStyle.sidebarContainer}>
        <Typography
          variant="bodyBold"
          color="gray.title"
          className="text-uppercase"
          style={this.pageStyle.sidebarTitle}
        >
          Selected Items
        </Typography>
        {itemsSection}
      </div>
    );
  };

  render() {
    const {orderItemProducts, isInternal, isLoading} = this.props;
    const {selectedItems} = this.state;
    const company = lodash.get(orderItemProducts, "[0].company");

    return (
      <QuoteLayout
        title={"Re-order Items"}
        subHeaderText={company && company.name}
        sidebarComponent={this.renderSidebar()}
        companyName={lodash.get(company, "name")}
        hideHeader
        isInternal={isInternal}
        isLoading={isLoading}
      >
        <div
          style={this.pageStyle.container}
          className="order-item-products-cart"
        >
          <BreadCrumbs
            rootName={isInternal ? "Companies" : "Company"}
            rootUrl={"companies"}
            middle={company && company.name}
            middleLink={company && company.id}
            pageName={"Re-order Items"}
          />

          <Typography variant="h1" style={this.pageStyle.title}>
            Re-order Items
          </Typography>

          <div className="product-list">
            {lodash.defaultTo(orderItemProducts, []).map(this.renderItem)}
          </div>
        </div>
        <footer
          style={this.pageStyle.footer}
          className="flex justify-content-end align-items-center"
        >
          <Typography
            onClick={this.goBack}
            component="a"
            variant="link"
            className="margin-right-20 text-uppercase"
          >
            Cancel
          </Typography>
          <RaisedButton
            label="Re-order"
            style={this.pageStyle.submitButton}
            disabled={selectedItems.length === 0}
            onClick={() => {
              this.createOrderItems();
            }}
            large={true}
          />
        </footer>
      </QuoteLayout>
    );
  }
}

export default withStyles(ProductsPage);