import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import ArtRevisionDetails from "../Arts/shared/ArtRevisionDetails";
import CNGToolTip from "../../shared/CNGToolTip";

class ArtsTable extends Component {
  _renderRow = (art, index) => {
    const {showArtLocation} = this.props;
    const {brand, revisions, adornmentLocation} = art;
    const revision = revisions[0];
    if (!revision) return;
    const {adornmentType} = revision;
    const artUrl = `/brands/${brand && brand.id}/arts/${art.id}/revisions/${
      revision.id
    }`;
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          highlight
          customStyles={
            showArtLocation
              ? {
                width: "20%",
              }
              : {}
          }
        >
          <CNGToolTip
            parentRef={`art${revision.id}`}
            style={{
              width: 250,
              border: "1px solid #BDC6CF",
            }}
            element={revision}
          >
            <Link to={artUrl} className="no-link-styles">
              {revision.name}
            </Link>
          </CNGToolTip>
          <div className="table-subtext">{brand && brand.name}</div>
        </CNGTableRowColumn>
        {showArtLocation ? (
          <CNGTableRowColumn
            customStyles={
              showArtLocation
                ? {
                  width: "15%",
                }
                : {}
            }
          >
            {adornmentLocation && adornmentLocation.location}
          </CNGTableRowColumn>
        ) : (
          <CNGTableRowColumn>
            {adornmentType && adornmentType.name}
          </CNGTableRowColumn>
        )}
        <CNGTableRowColumn
          customStyles={
            showArtLocation
              ? {
                width: "35%",
              }
              : {}
          }
        >
          <ArtRevisionDetails artRevision={revision} />
        </CNGTableRowColumn>
        {showArtLocation ? (
          <CNGTableRowColumn>{art.digitizedArtPreview}</CNGTableRowColumn>
        ) : null}
      </CNGTableRow>
    );
  };

  render() {
    let header = ["Name", "Adornment Type", "Details"];
    const {arts, showArtLocation, isLoading} = this.props;
    if (showArtLocation) {
      header = [
        {
          columnContent: "Art",
          customStyles: {
            width: "20%",
          },
        },
        {
          columnContent: "Art Location",
          customStyles: {
            width: "15%",
          },
        },
        {
          columnContent: "Details",
          customStyles: {
            width: "35%",
          },
        },
        "Digitized Art File",
      ];
    }
    return (
      <CNGTable
        tableHeader={header}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {arts.map((art, index) => {
          return this._renderRow(art, index);
        })}
      </CNGTable>
    );
  }
}

ArtsTable.propTypes = {
  arts: PropTypes.array.isRequired,
  showArtLocation: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
};

export default ArtsTable;
