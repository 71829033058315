import React, {Component} from "react";
import PropTypes from "prop-types";
import _differenceBy from "lodash/differenceBy";
import _groupBy from "lodash/groupBy";
import _get from "lodash/get";
import Dialog from "material-ui/Dialog";

import ChillButton from "../../../shared/ChillButton";
import Pagination from "../../../shared/Pagination";
import {TableRow} from "material-ui/Table";
import {CNGTable, CNGTableRowColumn} from "../../../shared/CNGTable";
import ContentLoader from "../../../shared/ContentLoader";
import CNGToolTip from "../../../shared/CNGToolTip";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";

const styles = {
  height: 656,
  width: 960,
  maxWidth: 960,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
  cancelBtn: {
    color: "#0C5371",
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "24px",
    cursor: "pointer",
  },
  tableWrapper: {
    height: 420,
  },
  blankText: {
    padding: "24px",
    marginTop: "8px",
    textAlign: "center",
    background: "rgba(189,198,207,0.07)",
    color: "#BDC6CF",
  },
  wrapper: {
    position: "absolute",
    left: "40px",
    top: "40px",
    right: "40px",
    bottom: "40px",
  },
  rowStyles: {
    height: 66,
    borderRadius: 4,
    backgroundColor: "#fafbfb",
  },
};

class InventoryChooserModal extends Component {
  state = {
    currentPage: 1,
    perPage: 5,
    selectedItems: [],
    selected: [],
  };

  componentWillReceiveProps() {
    this._fetchInventoryItems(...arguments);
  }

  _fetchInventoryItems(nextProps) {
    const {open: nextOpen} = nextProps;
    const {open} = this.props;
    if (nextOpen && !open) {
      this.handleSelectPage({page: 1});
    }
  }

  handleSelectPage = async options => {
    const {match: {params: {company_id: companyId}}} = this.props;
    const {fetchInventoryItems} = this.props;
    this.setState({currentPage: options.page});
    await fetchInventoryItems(companyId, {
      page: options.page,
      perPage: this.state.perPage,
      filter: "used_quantity,lt,quantity",
      include:
        "orderItem.[order,artThreadColorAssignment,orderItemProduct.[orderItemProductAdornmentLo" +
        "cationArtAssignments.[art,productAdornmentLocation.adornmentLocation],art.artSource.bra" +
        "nd],fabricColor.[fabric,color],product]",
      orderBy: "id",
      orderDirection: "asc",
    });
    const {inventoryItems} = this.props;
    const {selectedItems} = this.state;
    const selected = inventoryItems.reduce((selected, item, index) => {
      const selectedItem = selectedItems.find(({id}) => item.id === id);
      if (selectedItem) {
        selected.push(index);
      }
      return selected;
    }, []);
    this.setState({selected});
  };

  _renderPagination() {
    const {inventoryItemsCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={this.handleSelectPage}
          count={inventoryItemsCount}
          currentPage={this.state.currentPage - 1}
          perPage={this.state.perPage}
        />
      </div>
    );
  }

  onRowSelection(newSelected) {
    const {inventoryItems} = this.props;
    const newSelectedItems = inventoryItems.filter((_, index) =>
      newSelected.includes(Number(index))
    );
    this.setState(({selected, selectedItems}) => {
      const currentSelectedItems = inventoryItems.filter((_, index) =>
        selected.includes(Number(index))
      );
      const itemsToRemove = _differenceBy(
        currentSelectedItems,
        newSelectedItems,
        "id"
      );
      const itemsToAdd = _differenceBy(
        newSelectedItems,
        currentSelectedItems,
        "id"
      );
      return {
        selected: newSelected,
        selectedItems: [
          ...selectedItems.filter(
            ({id}) => !itemsToRemove.find(item => item.id === id)
          ),
          ...itemsToAdd,
        ],
      };
    });
  }

  _renderRow = (inventoryItem, index) => {
    const {dsodItems} = this.props;
    if (!inventoryItem) return;
    if (!inventoryItem.orderItem) return;

    const id = _get(inventoryItem, "id");
    const orderItem = _get(inventoryItem, "orderItem");
    const product = _get(inventoryItem, "orderItem.product");
    if (!product) return;
    const fabricColor = _get(inventoryItem, "orderItem.fabricColor");
    const art = _get(inventoryItem, "orderItem.orderItemProduct.art");
    const assignment = _get(
      inventoryItem,
      "orderItem.orderItemProduct.orderItemProductAdornmentLocationArtAssignments[0]"
    );
    const selectedProductReferences = dsodItems.map(dsodItem =>
      [
        dsodItem.productId,
        dsodItem.artId,
        dsodItem.fabricAvailableColorId,
      ].toString()
    );
    const inventoryReference = [
      product.id,
      art && art.id,
      orderItem.fabricAvailableColorId,
    ].toString();
    if (selectedProductReferences.includes(inventoryReference)) return;

    const {fabric, color} = fabricColor || {};

    return (
      <TableRow
        key={id}
        displayBorder={false}
        style={styles.rowStyles}
        selected={this.state.selected.includes(index)}
        selectable={true}
      >
        <CNGTableRowColumn highlight={true}>
          {orderItem.productId}
          <CNGToolTip
            parentRef={`assignment-${assignment && assignment.id}`}
            style={{width: 250}}
            element={assignment}
          >
            {product.productName}
          </CNGToolTip>
          <div className="table-subtext">
            Item #{product && product.itemNumber}
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{_get(fabric, "name")}</CNGTableRowColumn>
        <CNGTableRowColumn>{_get(color, "name")}</CNGTableRowColumn>
        <CNGTableRowColumn highlight>
          <OrderItemArtDetails orderItem={orderItem}>
            {art && art.name}
          </OrderItemArtDetails>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{inventoryItem.balance}</CNGTableRowColumn>
      </TableRow>
    );
  };

  _filterInvetoryItems = consolidatedInventory => {
    const {dsodItems} = this.props;
    const selectedProductReferences = dsodItems.map(dsodItem =>
      [
        dsodItem.productId,
        dsodItem.artId,
        dsodItem.fabricAvailableColorId,
      ].toString()
    );
    return consolidatedInventory.filter(inventoryItemGroup => {
      const inventoryItem = inventoryItemGroup[1][0];
      const orderItem = inventoryItem.orderItem;

      const inventoryReference = [
        orderItem.product.id,
        orderItem.orderItemProduct.art.id,
        orderItem.fabricAvailableColorId,
      ].toString();
      return !selectedProductReferences.includes(inventoryReference);
    });
  };

  _renderTable(consolidatedInventory) {
    const header = ["ITEM NAME & ID", "FABRIC", "COLOR", "ART FILE", "BALANCE"];
    const {isLoading} = this.props;
    return (
      <div style={styles.tableWrapper}>
        <CNGTable
          isLoading={isLoading}
          tableHeader={header}
          onRowSelection={selected => this.onRowSelection(selected)}
          selectable={true}
          className="CNG-table CNG-table--removeHeaderHeight"
          multiSelectable={true}
        >
          {consolidatedInventory.map(this._renderRow)}
        </CNGTable>
      </div>
    );
  }
  _renderBlankProducts() {
    const {isLoading} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <div className="flex-one">
          <p style={styles.blankText}>No Products on Inventory Yet</p>
        </div>
      </ContentLoader>
    );
  }

  onSave = async () => {
    const {
      onConfirm,
      dsodItems,
      createDsodItemsFromInventory,
      companyId,
      onFetchDsodItems,
    } = this.props;
    if (!dsodItems.length) {
      return;
    }
    const {selectedItems} = this.state;
    const selectedIds = selectedItems.map(selectedItem => selectedItem.id);
    const recipientId = dsodItems[0].recipientId;

    await createDsodItemsFromInventory(companyId, recipientId, selectedIds, {
      include:
        "[sourceOrderItem.orderItemProduct,product,fabricAvailableColor.fabric,art.artSou" +
        "rce.brand]",
    });
    onFetchDsodItems();
    this.setState(
      {
        currentPage: 1,
        selectedItems: [],
        selected: [],
      },
      onConfirm
    );
  };

  _inventoryItemsGroupedByProductReference = () => {
    const {inventoryItems} = this.props;
    const groupedInventory = _groupBy(inventoryItems, inventorytItem => {
      const {orderItem} = inventorytItem;
      return [orderItem.product.id, orderItem.orderItemProduct.art.id];
    });
    return Object.keys(groupedInventory).map(index => [
      index,
      groupedInventory[index],
    ]);
  };

  render() {
    const {onDismiss, open, inventoryItemsCount, inventoryItems} = this.props;
    // const consolidatedInventory =
    // this._inventoryItemsGroupedByProductReference(); const inventoryItemGroups =
    // this._filterInvetoryItems( consolidatedInventory );

    return (
      <Dialog
        modal={false}
        open={open}
        contentStyle={styles}
        contentClassName={"archiveModalStyles"}
        overlayClassName={"dialog-overlay"}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="flex flex-column" style={styles.wrapper}>
          <h3 className="archiveModal_title">Choose Products from Inventory</h3>
          <div className="flex-one margin-bottom-16 position-relative">
            <div>
              {inventoryItems.length > 0
                ? this._renderTable(inventoryItems)
                : this._renderBlankProducts()}
              {inventoryItemsCount > 6 && this._renderPagination()}
            </div>
          </div>
          <div className="flex justify-content-end align-items-center">
            <span
              onClick={() => {
                this.setState(
                  {
                    currentPage: 1,
                    selectedItems: [],
                    selected: [],
                  },
                  () => onDismiss()
                );
              }}
              style={styles.cancelBtn}
            >
              CANCEL
            </span>
            <ChillButton
              icon="DSOD-white"
              name="ADD TO DROP SHIP CART"
              width={210}
              height={40}
              isDisabled={inventoryItems.length === 0}
              onClick={this.onSave}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

InventoryChooserModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onFetchDsodItems: PropTypes.func,
  open: PropTypes.bool.isRequired,
  inventoryItems: PropTypes.array,
  dsodItems: PropTypes.array,
  inventoryItemsCount: PropTypes.number,
  fetchInventoryItems: PropTypes.func,
  fetchDsodItems: PropTypes.func,
  createDsodItem: PropTypes.func,
  updateDsodItem: PropTypes.func,
  match: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default InventoryChooserModal;
