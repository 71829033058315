import React, { Component } from "react";
import * as PropTypes from "prop-types";
import lodash from "lodash";
import { withRouter, Link } from "react-router-dom";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";

import TrackingNumber from "../../../shared/TrackingNumber";

import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../../../styles/components/formStyles";
import EditInputAttributeWithoutIcon from "../../../shared/EditInputAttributeWithoutIcon";
import CNGDatePicker from "../../../shared/CNGDatePicker";
import FormattedAddress from "../../../shared/FormattedAddress";
import { getDate, getFormattedDate } from "../../../../utils/dates";
import FormattedPhone from "../../../shared/FormattedPhone";
import ChillButton from "../../../shared/ChillButton";
import { getCurrencyFormattedNumber } from "../../../../utils/numberFormat";
import IconButton from "material-ui/IconButton";

const pencilIconStyle = {
  width: 14,
  color: "#497E95",
};

class OrderShipmentTable extends Component {
  static propTypes = {
    shipmentItems: PropTypes.array.isRequired,
    onUpdateShipmentItem: PropTypes.func,
    updateShipment: PropTypes.func,
    isInternal: PropTypes.bool,
    shipment: PropTypes.object,
    match: PropTypes.object,
    orderItem: PropTypes.object,
  };

  state = {
    editableRows: [],
    editableData: {},
    deleteEstimatedDelivery: false,
    deleteActualShipDate: false,
  };

  handleEditableRow = (index, editable) => {
    const { editableRows } = this.state;

    if (editable) {
      this.setState({
        editableRows: [...editableRows, index],
      });
    } else {
      this.setState({
        editableRows: editableRows.filter(
          editableIndex => editableIndex !== index
        ),
      });
    }
  };

  saveShipmentItem = shipmentItemId => {
    const { editableData } = this.state;
    const { onUpdateShipmentItem, shipment, updateShipment } = this.props;
    const attributes = editableData[shipmentItemId];
    if (!attributes) return;
    if (shipment.isMultiple) {
      onUpdateShipmentItem(shipmentItemId, attributes);
    } else {
      updateShipment(shipment.id, attributes);
    }
  };

  _renderShipmentCost = (id, shipmentCost, index) => {
    const { editableData } = this.state;
    return (
      <EditInputAttributeWithoutIcon
        withCurrencyFormat
        notFocus
        maxLength={10}
        textStyle={{
          color: "#747D86",
          fontSize: 14,
          lineHeight: "20px",
          height: 18,
          fontWeight: "normal",
        }}
        containerStyle={{
          width: "70%",
        }}
        fieldStyle={{
          height: 30,
        }}
        initialValue={
          !shipmentCost || shipmentCost === "null" ? 0 : shipmentCost
        }
        formatLabel={label => {
          return getCurrencyFormattedNumber(label || 0);
        }}
        onSave={() => {
          this.saveShipmentItem(id);
          this.handleEditableRow(index, false);
        }}
        onToggleEditable={() => this.handleEditableRow(index, false)}
        width={170}
        hideUnderline={true}
        editMode={this.state.editableRows.includes(index)}
        onChange={text => {
          this.setState({
            editableData: {
              ...this.state.editableData,
              [id]: {
                ...(editableData[id] || {}),
                shipmentCost: text,
              },
            },
          });
        }}
      />
    );
  };

  _renderTrackingOption = (id, trackingNumber, index, shipment) => {
    const { editableData } = this.state;
    return (
      <EditInputAttributeWithoutIcon
        maxLength={18}
        textStyle={{
          color: "#747D86",
          fontSize: 14,
          lineHeight: "20px",
          height: 18,
          fontWeight: "normal",
        }}
        containerStyle={{
          width: "70%",
        }}
        fieldStyle={{
          height: 30,
        }}
        value={trackingNumber}
        initialValue={
          trackingNumber ? (
            <TrackingNumber shipment={shipment}>
              {trackingNumber}
            </TrackingNumber>
          ) : null
        }
        onSave={() => {
          this.saveShipmentItem(id);
          this.handleEditableRow(index, false);
        }}
        onToggleEditable={() => this.handleEditableRow(index, false)}
        width={170}
        hideUnderline={true}
        editMode={this.state.editableRows.includes(index)}
        onChange={text => {
          this.setState({
            editableData: {
              ...this.state.editableData,
              [id]: {
                ...(editableData[id] || {}),
                trackingNumber: text,
              },
            },
          });
        }}
      />
    );
  };

  handleActualShipDateDate = () => {
    this.setState({
      deleteActualShipDate: true,
    });
  }

  _renderActualShipdate = (id, initialValue) => {
    const { editableData, deleteActualShipDate } = this.state;
    const dataRow = editableData[id];
    const actualShipDate = lodash.get(dataRow, "actualShipDate");
    const value =
      actualShipDate === undefined ? (initialValue && new Date(initialValue)) :
        actualShipDate;
    return (
      <div className="flex">
        <CNGDatePicker
          name="actual_ship_date"
          value={value && getDate(value)}
          onChange={(event, value) => {
            this.setState({
              editableData: {
                ...this.state.editableData,
                [id]: {
                  ...(editableData[id] || {}),
                  actualShipDate: value,
                },
              },
              deleteActualShipDate: false,
            });
          }}
          datePickerStyle={formStyles.HalfTextInputStyles}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={{ ...formStyles.underlineStyle, bottom: 17, width: "50%" }}
          autoFocus={true}
          datePickerInputStyle={{
            marginTop: -15,
            fontSize: 14,
            color: "#747d86",
          }}
          style={{ position: "initial" }}
          clearDate={deleteActualShipDate}
        />
        <IconButton
          style={{ size: "9px", position: "absolute", marginLeft: 75, marginTop: -5 }}
          onClick={() => this.handleActualShipDateDate()}
        >
          <img src={"/assets/imgs/icons/trash2.svg"} className="btn-icon-size" />
        </IconButton>
      </div>
    );
  };

  handleDeleteEstimatedDeliveryDate = () => {
    this.setState({
      deleteEstimatedDelivery: true,
    });
  }

  _renderEstimatedDeliveryDate = (id, initialValue) => {
    const { editableData, deleteEstimatedDelivery } = this.state;
    const dataRow = editableData[id];
    const estimatedDeliveryDate = lodash.get(dataRow, "estimatedDeliveryDate");
    const value =
      estimatedDeliveryDate === undefined ? (initialValue && new Date(initialValue)) :
        estimatedDeliveryDate;
    return (
      <div className="flex">
        <CNGDatePicker
          name="purchase_order_date"
          value={value && getDate(value)}
          onChange={(event, value) => {
            this.setState({
              editableData: {
                ...this.state.editableData,
                [id]: {
                  ...(editableData[id] || {}),
                  estimatedDeliveryDate: value,
                },
              },
              deleteEstimatedDelivery: false
            });
          }}
          datePickerStyle={formStyles.HalfTextInputStyles}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={{ ...formStyles.underlineStyle, bottom: 17, width: "50%" }}
          autoFocus={true}
          datePickerInputStyle={{
            marginTop: -15,
            fontSize: 14,
            color: "#747d86",
          }}
          style={{ position: "initial" }}
          clearDate={deleteEstimatedDelivery}
        />
        <IconButton
          style={{ size: "9px", position: "absolute", marginLeft: 75, marginTop: -5 }}
          onClick={() => this.handleDeleteEstimatedDeliveryDate()}
        >
          <img src={"/assets/imgs/icons/trash2.svg"} className="btn-icon-size" />
        </IconButton>
      </div>
    );
  };

  _renderRow(shipmentItem, index) {
    const { isInternal, shipment, orderItem } = this.props;
    const orderQuantity = lodash.get(orderItem, "orderQuantity");
    const isMultiple = lodash.get(shipment, "isMultiple");
    const hasEditMode = this.state.editableRows.includes(index);
    const {
      recipientInformation,
      shippedQuantity,
      trackingNumber,
      id,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
      isProof,
    } = shipmentItem;
    const {
      name,
      phoneNumber,
      emailAddress,
      companyName,
      id: recipientId,
      countryCode,
    } =
      recipientInformation || {};
    const { editableRows } = this.state;
    const isEditable = editableRows.some(
      editableIndex => editableIndex === index
    );
    const rowClass = isEditable ? "editable-row" : "";
    const { match: { params: { company_id: companyId } } } = this.props;

    return (
      <CNGTableRow key={index} className={rowClass}>
        <CNGTableRowColumn
          customStyles={{
            width: 170,
          }}
          highlight={true}
        >
          <Link
            to={
              recipientInformation &&
              `/companies/${companyId}/contacts/${recipientId}`
            }
            className="no-link-styles"
            title={name}
          >
            {name}
            <div className="table-subtext">{companyName}</div>
            <div className="table-subtext">{emailAddress}</div>
            <div className="table-subtext">
              {
                <FormattedPhone
                  phoneNumber={phoneNumber}
                  countryCode={countryCode}
                />
              }
            </div>
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <FormattedAddress user={recipientInformation || {}} />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 150,
          }}
        >
          {isInternal && hasEditMode
            ? this._renderActualShipdate(id, actualShipDate)
            : getFormattedDate(actualShipDate && new Date(actualShipDate))}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 150,
          }}
        >
          {isInternal && hasEditMode
            ? this._renderEstimatedDeliveryDate(id, estimatedDeliveryDate)
            : getFormattedDate(
              estimatedDeliveryDate && new Date(estimatedDeliveryDate)
            )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 80,
            position: "relative",
          }}
        >
          {isMultiple ? shippedQuantity : orderQuantity}
          {isProof && (
            <span
              style={{ position: "absolute", top: 4, right: 4, color: "#1d9abb" }}
            >
              PP
            </span>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 175,
            fontWeight: "normal",
          }}
        >
          {isInternal ? (
            this._renderTrackingOption(id, trackingNumber, index, shipment)
          ) : (
            <TrackingNumber shipment={shipment}>
              {trackingNumber}
            </TrackingNumber>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          {isInternal
            ? this._renderShipmentCost(id, `${shipmentCost}`, index)
            : getCurrencyFormattedNumber(shipmentCost)}
        </CNGTableRowColumn>
        {isInternal ? (
          <CNGTableRowColumn
            customStyles={{
              width: 70,
              textAlign: "right",
              cursor: "pointer",
            }}
          >
            {!isEditable ? (
              <img
                src="/assets/imgs/icons/edit-blue.svg"
                style={pencilIconStyle}
                className="cng-icon non-printable"
                onClick={() => {
                  this.handleEditableRow(index, true);
                }}
              />
            ) : (
              <div className="row middle-xs">
                <ChillButton
                  height={30}
                  width={30}
                  marginLeft={0}
                  marginRightIcon={0}
                  widthIcon={16}
                  icon={"check"}
                  iconStyles={{
                    color: "#497E95",
                  }}
                  onClick={() => {
                    this.saveShipmentItem(id);
                    this.handleEditableRow(index, false);
                  }}
                  name=""
                />
              </div>
            )}
          </CNGTableRowColumn>
        ) : (
          <CNGTableRowColumn
            customStyles={{
              width: 70,
            }}
          />
        )}
      </CNGTableRow>
    );
  }
  render() {
    const header = [
      {
        columnContent: "RECIPIENT",
        customStyles: {
          width: 170,
        },
      },
      "ADDRESS",
      {
        columnContent: "Ship Date",
        customStyles: {
          width: 150,
        },
      },
      {
        columnContent: "Est. Del. Date",
        customStyles: {
          width: 150,
        },
      },
      {
        columnContent: "# TO SHIP",
        customStyles: {
          width: 80,
        },
      },
      {
        columnContent: "TRACKING #",
        customStyles: {
          width: 175,
        },
      },
      {
        columnContent: "SHIPMENT COST",
        customStyles: {
          width: 100,
        },
      },
      {
        columnContent: "",
        customStyles: {
          width: 70,
        },
      },
    ];

    const { shipmentItems, shipment } = this.props;

    return (
      <CNGTable tableHeader={header} displayRowCheckbox={false}>
        {shipment.isMultiple
          ? lodash.sortBy(shipmentItems, "id").map((shipmentItem, index) => {
            return this._renderRow(shipmentItem, index);
          })
          : this._renderRow(shipment, 1)}
      </CNGTable>
    );
  }
}

export default withRouter(OrderShipmentTable);
