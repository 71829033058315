import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import Pagination from "../../shared/Pagination";
import {getFormattedDate} from "../../../utils/dates";

class DSODRequestTable extends Component {
  static propTypes = {
    orders: PropTypes.array,
    companies: PropTypes.array,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
    ordersCount: PropTypes.number,
    searchTerm: PropTypes.string,
    searchOrders: PropTypes.func,
    minHeight: PropTypes.string,
  };
  state = {
    currentPage: 1,
  };

  _renderPagination() {
    const {ordersCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={this.handleSelectPage}
          count={ordersCount}
          currentPage={this.state.currentPage - 1}
        />
      </div>
    );
  }

  handleSelectPage = options => {
    const {searchOrders, searchTerm} = this.props;
    this.setState({currentPage: options.page});
    searchOrders(searchTerm, "status", {
      ...options,
      page: options.page,
      orderBy: "orders;created_at",
    });
  };

  _renderRow(order, _, qtytoShip, index) {
    const company = order.company;
    return (
      <CNGTableRow isSlimRow={true} key={index}>
        <CNGTableRowColumn highlight={true}>
          {company && (
            <Link
              to={`/companies/${order.companyId}/dsod/${order.id}`}
              className="no-link-styles"
            >
              {order.cngReferenceNumber}
            </Link>
          )}
        </CNGTableRowColumn>
        {this.props.isInternal && (
          <CNGTableRowColumn highlight={true}>
            {company && (
              <Link
                to={`/companies/${order.companyId}?tab=orders`}
                className="no-link-styles"
              >
                {company.name}
              </Link>
            )}
          </CNGTableRowColumn>
        )}
        <CNGTableRowColumn>{qtytoShip}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {order.submittedOn && getFormattedDate(order.submittedOn)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = ["ID", "COMPANY", "QTY. BEING SHIPPED", "DATE"];
    const {orders, ordersCount, isInternal, minHeight, isLoading} = this.props;

    if (!isInternal) {
      header.splice(1, 1);
    }

    return (
      <div>
        {isLoading !== true && ordersCount === 0 ? (
          <h2 className="userindex_noUsers">
            All DSOD commands have been executed.
          </h2>
        ) : (
          <React.Fragment>
            <div
              style={{
                minHeight: minHeight || "560px",
              }}
            >
              <CNGTable
                tableHeader={header}
                displayRowCheckbox={false}
                isLoading={isLoading}
              >
                {orders &&
                  orders.map((order, index) => {
                    const qtytoShip =
                      order &&
                      order.items &&
                      order.items.reduce((count, item) => {
                        return count + item.quantityToShip;
                      }, 0);
                    return this._renderRow(
                      order,
                      order.company,
                      qtytoShip,
                      index
                    );
                  })}
              </CNGTable>
            </div>
            {ordersCount > 7 ? this._renderPagination() : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DSODRequestTable;
