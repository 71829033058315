export const VALID_IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];

export const VALID_QUOTE_IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/bmp",
  "image/gif",
  "image/tiff",
  "image/psd",
  "image/eps",
  "image/x-eps",
  "image/vnd.adobe.photoshop",
  "application/pdf",
  "application/postscript",
  "application/x-photoshop",
  "application/photoshop",
  "application/psd",
  "application/eps",
  "application/x-eps",
  "application/x-indesign",
  "image/x-sony-arw",
  "image/x-canon-cr2",
  "image/x-canon-crw",
  "image/x-kodak-dcr",
  "image/x-adobe-dng",
  "image/x-epson-erf",
  "image/x-kodak-k25",
  "image/x-kodak-kdc",
  "image/x-minolta-mrw",
  "image/x-nikon-nef",
  "image/x-olympus-orf",
  "image/x-pentax-pef",
  "image/x-fuji-raf",
  "image/x-panasonic-raw",
  "image/x-sony-sr2",
  "image/x-sony-srf",
  "image/x-sigma-x3f",
];

export const VALID_CHAT_IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg",
  "image/bmp",
  "application/pdf",
];

export const PREVIEWABLE_IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg",
  "image/bmp",
];

export const numberRegExp = /^\d+(\.(\d*)?)?$/;
export const detailFieldValidations = {
  embroidery: [
    ["stitch_count", numberRegExp],
    ["width", numberRegExp],
    ["height", numberRegExp],
    "thread_colors",
    "applicability_id",
  ],
  etching: ["dye_color", ["width", numberRegExp], ["height", numberRegExp]],
  patch: [
    "shape_description",
    ["width", numberRegExp],
    ["height", numberRegExp],
  ],
};

export const INACTIVE_ART_TITLE =
  "This art version has been superseded or discontinued.  If you wish to revive this version please contact us.";

export const NO_CHILL_DIGITACION_PRICE_TITLE = "None - art on file";
