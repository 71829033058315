import React, {Component, Fragment} from "react";
import CircularProgress from "material-ui/CircularProgress";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import MenuItem from "material-ui/MenuItem";
import {RadioButton, RadioButtonGroup} from "material-ui/RadioButton";
import SelectField from "material-ui/SelectField";
import withStyles from "../../../shared/WithStyles";
import Typography from "../../../shared/Typography";

import "@drawbotics/file-icons/dist/style.css";

import {VALID_QUOTE_IMAGE_FORMATS} from "../../../../../constants/art";

class AddArt extends Component {
  inputField = null;

  openFileBrowserHandler = ev => {
    ev.preventDefault();
    this.inputField.click(ev);
  };

  handleFileChange = async inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!file) return;
    if (!VALID_QUOTE_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Unable To Accept This Format. Please Contact Us.",
        10000
      );
    } else {
      await this.setState({file, filename: file.name});
      this.handleNewArt();
    }
  };

  handleNewArt = () => {
    const {onCreateArt, arts, openNotification} = this.props;
    const {filename, file} = this.state;
    const artWithName = arts.find(art => art.filename === filename);
    if (artWithName) {
      openNotification("This art is already uploaded");
      return;
    }
    onCreateArt(filename, file);
    this.setState({filename: "", file: null});
  };

  state = {
    artModalOpen: false,
    filename: "",
    file: null,
  };

  handleCloseArtModal = async () => {
    await this.setState({artModalOpen: false});
    const {item, arts, onChangeItemArt} = this.props;
    if (arts.length === 0) return;
    item.arts.forEach((art, index) => {
      if (!art.art) {
        onChangeItemArt(index, "art")({target: {}}, arts[0].uuid);
      }
    });
  };

  handleOpenArtModal = ev => {
    ev.preventDefault();
    this.setState({artModalOpen: true});
  };

  handleAddArt = event => {
    event.preventDefault();
    this.props.onAddItemArt();
  };

  handleRemoveArt = index => event => {
    event.preventDefault();
    this.props.onRemoveItemArt(index)();
  };

  renderArtFileRow = (artFile, index, arts) => {
    const {
      arts: quoteArts,
      companyArts,
      locations,
      onChangeItemArt,
      pageStyle,
    } = this.props;
    return (
      <div key={`art-file-${index}`} className="flex align-items-center">
        <SelectField
          name="art"
          value={artFile.art}
          floatingLabelText="Art"
          menuStyle={{borderRadius: "0px !important"}}
          onChange={(event, i, value) =>
            onChangeItemArt(index, "art")({target: {}}, value)
          }
          style={pageStyle.input}
          className="margin-right-20 margin-left-20 z-index-3"
        >
          {companyArts.map(art => (
            <MenuItem
              className="art"
              key={art.id}
              value={art.id}
              primaryText={art.name}
            />
          ))}
          {quoteArts.map(art => (
            <MenuItem
              className="art"
              key={art.uuid}
              value={art.uuid}
              primaryText={art.filename}
            />
          ))}
          {companyArts.length === 0 &&
            quoteArts.length === 0 && (
            <MenuItem
              disabled
              className="art"
              value=""
              primaryText="No art uploaded"
            />
          )}
        </SelectField>
        <SelectField
          name="adornmentLocation"
          value={artFile.adornmentLocation}
          floatingLabelText="Art Location"
          menuStyle={{borderRadius: "0px !important"}}
          onChange={(event, i, value) =>
            onChangeItemArt(index, "adornmentLocation")({target: {}}, value)
          }
          style={pageStyle.input}
          className="margin-right-20 margin-left-20 z-index-3"
        >
          {locations
            .filter(({id}) => {
              return !arts.find(
                ({adornmentLocation}, i) =>
                  i !== index &&
                  (adornmentLocation && adornmentLocation.id === id)
              );
            })
            .map(adornmentLocation => (
              <MenuItem
                className="location"
                key={adornmentLocation.id}
                value={adornmentLocation}
                primaryText={adornmentLocation.location}
              />
            ))}
        </SelectField>
        {arts.length > 1 && (
          <FontIcon
            className="pointer material-icons z-index-3 "
            onClick={this.handleRemoveArt(index)}
            style={{
              ...this.pageStyle.deleteIcon,
              marginTop: "auto",
              marginBottom: 16,
            }}
          >
            delete
          </FontIcon>
        )}
      </div>
    );
  };

  renderUploadArtOption = () => {
    const {item, locations, isLoadingArt} = this.props;
    return (
      <div
        className="flex flex-column"
        style={{...this.pageStyle.group, ...this.pageStyle.lightGrayBackground}}
      >
        {isLoadingArt ? (
          <span
            className="small-header new-order--add-link flex flex-center uppercase pointer z-index-3"
            style={{
              position: "absolute",
              top: 24,
              right: 24,
              color: "#1D9ABB",
              width: 256,
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <span
            className="small-header new-order--add-link flex flex-center uppercase pointer z-index-3"
            style={{
              position: "absolute",
              top: 24,
              right: 24,
              color: "#1D9ABB",
              width: 256,
            }}
            onClick={this.openFileBrowserHandler}
          >
            <FontIcon
              className="material-icons"
              style={{
                color: "#1D9ABB",
                fontSize: 26,
                marginRight: 8,
              }}
            >
              add_circle_outline
            </FontIcon>
            <span>Add art</span>
          </span>
        )}
        {item.arts.map(this.renderArtFileRow)}
        <div className="flex">
          {item.arts.length < locations.length && (
            <span
              className="small-header new-order--add-link flex flex-center uppercase pointer z-index-3"
              style={{
                marginTop: 24,
                marginLeft: 18,
                color: "#1D9ABB",
              }}
              onClick={this.handleAddArt}
            >
              <FontIcon
                className="material-icons"
                style={{
                  color: "#1D9ABB",
                  fontSize: 26,
                  marginRight: 8,
                }}
              >
                add_circle_outline
              </FontIcon>
              <span>Add another art location</span>
            </span>
          )}
        </div>
      </div>
    );
  };

  get pageStyle() {
    const {styles: {colors}} = this.props;
    return {
      group: {
        padding: 24,
        marginTop: 12,
        position: "relative",
      },
      button: {
        margin: 8,
      },
      uploadArtButton: {},
      radio: {
        marginBottom: 40,
      },
      grayBackground: {
        backgroundColor: `${colors.gray.border}12`,
      },
      lightGrayBackground: {
        backgroundColor: `${colors.gray.border}1A`,
      },
      artFile: {
        marginTop: "auto",
        marginBottom: 8,
        width: 238,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artImage: {
        width: 32,
        height: 32,
        objectFit: "cover",
      },
      artFileName: {
        padding: "0 8px",
        whiteSpace: "nowrap",
        maxWidth: 150,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textDecorationLine: "underline",
      },
      deleteIcon: {
        fontSize: 26,
        marginRight: 8,
        color: colors.primary.light,
      },
    };
  }

  render() {
    const {
      item,
      onChange,
    } = this.props;
    return (
      <Fragment>
        <Typography variant="h1" className="margin-bottom-40">
          Add Art
        </Typography>

        <input
          type="file"
          onChange={this.handleFileChange}
          className="hidden-safe"
          style={{display: "none"}}
          name="artFile"
          tabIndex={-1}
          ref={input => (this.inputField = input)}
          accept={VALID_QUOTE_IMAGE_FORMATS.join(", ")}
        />
        <RadioButtonGroup
          name="artOption"
          defaultSelected={item.artOption || "upload-art"}
          onChange={(_event, value) => {
            const arts = value === "no-art" ? [] : [{}];
            onChange("arts")({target: {}}, arts);
            onChange("artOption")({target: {}}, value);
          }}
          value={item.artOption}
        >
          <RadioButton
            value="upload-art"
            label={
              <Fragment>
                <Typography variant="h4">Upload art for quote</Typography>
                <Typography variant="h5">
                  We appreciate ‘label size’ or larger art (4”/10cm). All
                  standard MS/Adobe image formats are acceptable - call/email if
                  problems.
                </Typography>

                {item.artOption === "upload-art" &&
                  this.renderUploadArtOption()}
              </Fragment>
            }
            style={this.pageStyle.radio}
          />
          <RadioButton
            value="no-art"
            label={
              <Fragment>
                <Typography variant="h4">
                  No adornment required/requested
                </Typography>
                <Typography variant="h5">
                  No further costing is required for this item. The product
                  pricing table for this item in your Quote Request is complete.
                  Thank you!!
                </Typography>
              </Fragment>
            }
            style={this.pageStyle.radio}
          />
        </RadioButtonGroup>
      </Fragment>
    );
  }
}

AddArt.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
  arts: PropTypes.array,
  locations: PropTypes.array,
  onChange: PropTypes.func,
  onChangeItemArt: PropTypes.func,
  onAddItemArt: PropTypes.func,
  onRemoveItemArt: PropTypes.func,
  onCreateArt: PropTypes.func,
  onDeleteArt: PropTypes.func,
  openNotification: PropTypes.func.isRequired,
  companyArts: PropTypes.array,
  styles: PropTypes.object,
  pageStyle: PropTypes.object,
  isLoadingArt: PropTypes.bool,
};

export default withStyles(AddArt);
