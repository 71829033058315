import React, {Component} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _find from "lodash/find";
import _defaultTo from "lodash/defaultTo";
import Divider from "material-ui/Divider";
import {TextValidator, SelectValidator} from "react-material-ui-form-validator";

import {USA_COUNTRY_ID} from "../../../../../constants/country";
import {getCountryRegionsFromCountryCode} from "../../../../../utils/countryRegionsHelper";
import MenuItem from "../../../../shared/CNGMenuItem";
import Typography from "../../../shared/Typography";
import PhoneNumberInput from "../../../../shared/PhoneNumberInput";

class ContactInformation extends Component {
  countryButtonEl = null;
  stateButtonEl = null;

  _handleSelectRefUpdated = (proxyElement, elementName, tabIndex) => {
    if (!proxyElement) return;
    const element = ReactDOM.findDOMNode(proxyElement); // eslint-disable-line react/no-find-dom-node
    this[elementName] = element.querySelector("button");
    if (!tabIndex) return;
    this[elementName].tabIndex = tabIndex;
  };

  getItemOptions = (isUSA, options) => {
    return _defaultTo(options, []).map(({name, shortCode}) => {
      const text = isUSA
        ? shortCode
        : `${name} ${shortCode && `(${shortCode})`}`;
      const title = isUSA ? name : `${name} ${shortCode && `(${shortCode})`}`;
      return (
        <MenuItem
          key={name}
          value={name}
          primaryText={text}
          title={title}
          className="menuItem"
        />
      );
    });
  };

  getCountrySelectOptions = countries => {
    return countries.reduce((countryOptions, country) => {
      const countryElement = (
        <MenuItem
          className="countryOption"
          key={country.id}
          value={country.id}
          primaryText={country.name}
        />
      );

      if (["USA", "US"].includes(country.code)) {
        return [
          countryElement,
          <Divider key={"usa_divider"} />,
          ...countryOptions,
        ];
      }
      countryOptions.push(countryElement);
      return countryOptions;
    }, []);
  };

  onChangeCountry = event => {
    const {countries, onChange} = this.props;
    const country = _find(countries, ["name", event.target.innerText]);
    const isUSA = Number(country && country.id) === USA_COUNTRY_ID;
    onChange("countryId")(event, country.id);
    if (!isUSA) {
      onChange("state")(event, null);
    }
    setTimeout(() => this.countryButtonEl && this.countryButtonEl.focus(), 600);
  };

  onChangeState = (event, index, value) => {
    const {onChange} = this.props;
    onChange("state")(event, value);
    setTimeout(() => this.stateButtonEl && this.stateButtonEl.focus(), 600);
  };

  onChangePhoneNumber = (event, value) => {
    const {onChange} = this.props;
    onChange("phoneNumber")({target: {value: value.replace(/\D/g, "")}});
  };

  render() {
    const {state, onError, onChange, pageStyle, countries} = this.props;
    const {
      state: stateName,
      countryId,
      name,
      lastName,
      emailAddress,
      address1,
      address2,
      zipCode,
      city,
      phoneNumber,
    } = state;
    const country = countries.find(
      country => Number(countryId) === Number(country.id)
    );
    const isUSA = Number(country && country.id) === USA_COUNTRY_ID;

    let zipCodeValidator = {
      validators: ["required"],
      messages: "this field is required",
    };

    let stateValidator = {
      validators: [],
      messages: [],
    };

    let phoneNumberValidator = {
      validators: ["required"],
      errorMessages: ["this field is required"],
    };

    if (isUSA) {
      zipCodeValidator = {
        validators: ["required", "matchRegexp:^[0-9]{5}$"],
        messages: ["this field is required", "must be a valid zipcode"],
      };

      stateValidator = {
        validators: ["required"],
        messages: "this field is required",
      };

      phoneNumberValidator = {
        validators: ["required", "minStringLength:10", "maxStringLength:10"],
        errorMessages: [
          "this field is required",
          "must be a valid phone",
          "must be a valid phone",
        ],
      };
    }

    const states = getCountryRegionsFromCountryCode(country && country.code);

    return (
      <div style={pageStyle.companyContacts}>
        <Typography variant="h5" color="black">
          Contact Information
        </Typography>

        <div className="flex flex-wrap">
          <TextValidator
            autoFocus
            style={pageStyle.input}
            name="name"
            floatingLabelText="Name"
            value={name}
            onChange={onChange("name")}
            autoComplete="off"
            margin="normal"
            validators={["required", "matchRegexp:^[A-Za-z ,.'-]+$"]}
            errorMessages={["this field is required", "must be a valid name"]}
            validatorListener={onError}
            className="margin-right-20"
          />
          <TextValidator
            style={pageStyle.input}
            name="lastName"
            floatingLabelText="Last name"
            value={lastName}
            onChange={onChange("lastName")}
            autoComplete="off"
            margin="normal"
            validators={["required", "matchRegexp:^[A-Za-z ,.'-]+$"]}
            errorMessages={["this field is required", "must be a valid name"]}
            validatorListener={onError}
            className="margin-right-20"
          />
          <TextValidator
            style={pageStyle.input}
            name="emailAddress"
            floatingLabelText="E-Mail"
            value={this.props.userEmail ? this.props.userEmail : emailAddress}
            onChange={onChange("emailAddress")}
            autoComplete="off"
            disabled={this.props.userEmail ?? emailAddress}
            margin="normal"
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            validatorListener={onError}
            className="margin-right-20"
          />
        </div>
        <div className="flex flex-wrap">
          <SelectValidator
            className="margin-right-20"
            name="countryId"
            value={`${countryId}`}
            floatingLabelText="Country"
            menuStyle={{borderRadius: "0px !important"}}
            onChange={this.onChangeCountry}
            style={pageStyle.input}
            validators={["required"]}
            errorMessages="this field is required"
            ref={el => this._handleSelectRefUpdated(el, "countryButtonEl", 0)}
          >
            {this.getCountrySelectOptions(countries)}
          </SelectValidator>
          <PhoneNumberInput
            component={TextValidator}
            style={pageStyle.input}
            name="phoneNumber"
            floatingLabelText="Phone"
            value={phoneNumber}
            countryCode={country ? country.code : ""}
            onChange={this.onChangePhoneNumber}
            autoComplete="off"
            margin="normal"
            validators={phoneNumberValidator.validators}
            errorMessages={phoneNumberValidator.errorMessages}
            validatorListener={onError}
            className="margin-right-20"
          />
        </div>
        <div className="flex flex-wrap">
          <TextValidator
            style={pageStyle.input}
            name="address1"
            floatingLabelText="Address 1"
            value={address1}
            onChange={onChange("address1")}
            autoComplete="off"
            margin="normal"
            validators={["required"]}
            errorMessages="this field is required"
            validatorListener={onError}
            className="margin-right-20"
          />
          <TextValidator
            style={pageStyle.input}
            name="address2"
            floatingLabelText="Address 2"
            value={address2}
            onChange={onChange("address2")}
            autoComplete="off"
            margin="normal"
            className="margin-right-20"
          />
        </div>
        <div className="flex flex-wrap">
          <TextValidator
            style={pageStyle.input}
            name="city"
            floatingLabelText="City"
            value={city}
            onChange={onChange("city")}
            autoComplete="off"
            margin="normal"
            validators={["required"]}
            errorMessages="this field is required"
            validatorListener={onError}
            className="margin-right-20"
          />
          <SelectValidator
            name="state"
            value={stateName && stateName + ""}
            floatingLabelText="State/Region/Province"
            menuStyle={{borderRadius: "0px !important"}}
            onChange={this.onChangeState}
            style={pageStyle.input}
            className="margin-right-20"
            validators={stateValidator.validators}
            errorMessages={stateValidator.messages}
            withRequiredValidator={true}
            ref={el => this._handleSelectRefUpdated(el, "stateButtonEl", 0)}
          >
            {this.getItemOptions(isUSA, states)}
          </SelectValidator>
          <TextValidator
            style={pageStyle.input}
            name="zipCode"
            floatingLabelText="Postal Code"
            value={zipCode}
            onChange={onChange("zipCode")}
            autoComplete="off"
            margin="normal"
            validators={zipCodeValidator.validators}
            errorMessages={zipCodeValidator.messages}
            validatorListener={onError}
            className="margin-right-20"
          />
        </div>
      </div>
    );
  }
}

ContactInformation.propTypes = {
  state: PropTypes.object,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  pageStyle: PropTypes.object,
  countries: PropTypes.array,
  userEmail: PropTypes.string,
};

export default ContactInformation;
