import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _get from "lodash/get";

import Messenger from "../../../../services/MessagingService";
import MessagesSection from "./MessagesSection";
import {
  fetchChatChannels,
  fetchChatChannel,
  fetchChatMessages,
  sendChatMessage,
  clearMessages,
  fetchChatMessagesRequest,
} from "../../../../actions/v2/chatActions";
import {openNotification} from "../../../../actions/notificationActions";

function mapStateToProps(state, props) {
  const {
    auth: {isInternal, id, user: userName},
    chat: {channel, channels, messages, isLoading, isLoadingMessages},
  } = state;

  const user = {
    id: props.type === "quote" ? _get(props.typeData, props.typeIdName) : id,
    name: props.type === "quote" ? props.name : userName,
  };

  if (isInternal) {
    user.id = "internal";
    user.name = "Chill N Go";
  }
  const messenger = new Messenger(user);

  return {
    user,
    messenger,
    channel,
    channels,
    messages,
    isLoading,
    isLoadingMessages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchChatChannels: bindActionCreators(fetchChatChannels, dispatch),
    fetchChatChannel: bindActionCreators(fetchChatChannel, dispatch),
    fetchChatMessages: bindActionCreators(fetchChatMessages, dispatch),
    sendChatMessage: bindActionCreators(sendChatMessage, dispatch),
    clearMessages: bindActionCreators(clearMessages, dispatch),
    fetchChatMessagesRequest: bindActionCreators(
      fetchChatMessagesRequest,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessagesSection)
);
