/* eslint-disable import/default */
import IEVersion from "ie-version";
import "regenerator-runtime/runtime";
import ReactGA from "react-ga";
import React from "react";
import {render} from "react-dom";
import {AppContainer} from "react-hot-loader";
import IEApp from "./components/IEApp";
import configureStore, {history} from "./store/configureStore";
import Root from "./components/Root";
import "../node_modules/flexboxgrid/css/flexboxgrid.min.css";
import "./styles/app.scss"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require("./favicon.ico"); // Tell webpack to load favicon.ico
import "./config/moment";
const store = configureStore();
ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  debug: process.env.NODE_ENV !== "production",
});

if (IEVersion.version) {
  render(<IEApp />, document.getElementById("app"));
} else {
  render(
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById("app")
  );

  if (module.hot) {
    module.hot.accept("./components/Root", () => {
      const NewRoot = require("./components/Root").default;
      render(
        <AppContainer>
          <NewRoot store={store} history={history} />
        </AppContainer>,
        document.getElementById("app")
      );
    });
  }
}
