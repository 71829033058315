import React, {Component} from "react";
import PlaceholderImg from "../../../shared/PlaceholderImg";
import PropTypes from "prop-types";

const placeHolderStyle = {
  width: 246,
  Height: 358,
  marginRight: 65,
  marginLeft: 46,
};

export default class ProductImage extends Component {
  static propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    emptyStyle: PropTypes.object,
  };

  state = {
    errorImage: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({errorImage: false});
    }
  }

  render() {
    const {src, style, emptyStyle = {}} = this.props;

    if (!src) {
      return (
        <PlaceholderImg
          text="Image not available"
          style={{...placeHolderStyle, ...emptyStyle}}
        />
      );
    }

    if (this.state.errorImage) {
      return (
        <PlaceholderImg
          text="Image not found"
          style={{...placeHolderStyle, ...emptyStyle}}
        />
      );
    }
    return (
      <img
        style={style}
        src={src}
        onError={() => this.setState({errorImage: true})}
      />
    );
  }
}
