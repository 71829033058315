import React, {Component} from "react";
import PropTypes from "prop-types";

import {formatAddress} from "../../utils/addressFormatter";

export default class FormattedAddress extends Component {
  render() {
    const {user, multilines, style, countries} = this.props;
    const {
      address1,
      address2,
      zipCode,
      city,
      countryCode,
      stateCode,
      title,
    } = formatAddress(user, countries);
    const address = `${address1}${address2}`;

    return (
      <span
        className="subtext"
        title={title}
        style={{
          whiteSpace: "pre-wrap",
          ...style,
        }}
      >
        {address}
        {multilines ? <br /> : "  "} {city}, {stateCode}
        {"  "}
        {zipCode}
        {"  "} {countryCode}
      </span>
    );
  }
}

FormattedAddress.propTypes = {
  style: PropTypes.object,
  user: PropTypes.object,
  countries: PropTypes.array,
  multilines: PropTypes.bool,
};
