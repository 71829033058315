import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ChillButton from "./ChillButton";

const FormCardHeader = ({
  title,
  buttonText,
  buttonIcon,
  cancelLink,
  buttonClick,
  buttonHeight,
  buttonWidth,
  noButton,
  includeHr,
  hideCancel,
}) => (
  <div className="column">
    <div className="row middle-xs between-xs">
      <h1>{title}</h1>
      <div className="row middle-xs">
        {!hideCancel && (
          <Link to={cancelLink} className="text-cancel">
            Cancel
          </Link>
        )}
        <ChillButton
          className="btn-1 btn--formCardHeader"
          name={buttonText}
          height={buttonHeight || 40}
          width={buttonWidth || 137}
          icon={buttonIcon}
          isDisabled={noButton}
          onClick={() => buttonClick()}
        />
      </div>
    </div>
    {includeHr ? <div className="header-separator" /> : ""}
  </div>
);

FormCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string,
  cancelLink: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  buttonHeight: PropTypes.number,
  buttonWidth: PropTypes.number,
  isDisabled: PropTypes.bool,
  noButton: PropTypes.bool,
  includeHr: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

export default FormCardHeader;
