import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import lodash from "lodash";

import CNGCard from "../../../shared/CNGCard";
import SectionHeading from "../../../shared/SectionHeading";
import FormattedPhone from "../../../shared/FormattedPhone";
import FormattedAddress from "../../../shared/FormattedAddress";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";
import DSODShipmentTracking from "../../../containers/DSOD/DSODShipmentTracking";
import CNGToolTip from "../../../shared/CNGToolTip";

import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";
import {CNGTableRow, CNGTableRowColumn} from "../../../shared/CNGTable";
import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
} from "../../../../styles/components/tableStyles";

export default class RecipientDetailCard extends Component {
  static propTypes = {
    shipment: PropTypes.object,
    companyId: PropTypes.string,
    recipient: PropTypes.object.isRequired,
    shippedQuantity: PropTypes.number.isRequired,
    orderItems: PropTypes.array,
    shipmentItem: PropTypes.object,
    fetchOrder: PropTypes.func,
    orderId: PropTypes.number,
    countries: PropTypes.array,
  };
  style = {
    container: {
      marginTop: 48,
      marginBottom: 24,
    },
    blankText: {
      padding: "24px",
      marginTop: "8px",
      textAlign: "center",
      background: "rgba(189,198,207,0.07)",
      color: "#BDC6CF",
    },
  };

  _renderRecipientSummary(recipient) {
    const {shippedQuantity, countries} = this.props;
    const {
      companyName,
      emailAddress,
      phoneNumber,
      country,
      countryCode,
    } = recipient;

    return (
      <div className="company_top_info">
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"COMPANY"} />
          <span className="subtext">{companyName}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"ADDRESS"} />
          <span className="subtext">
            <FormattedAddress user={recipient} countries={countries} />
          </span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"EMAIL"} />
          <span className="subtext no-wrap">{emailAddress}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"PHONE"} />
          <span className="subtext no-wrap">
            <FormattedPhone
              phoneNumber={phoneNumber}
              countryCode={country ? country.code : countryCode}
            />
          </span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"QTY BEING SHIPPED"} />
          <span className="subtext no-wrap">{shippedQuantity}</span>
        </div>
      </div>
    );
  }

  _renderOrderItems() {
    const {style} = this;

    return (
      <div style={style.container}>
        <CNGTableCustom tableStyle={tableStyle} displayRowCheckbox={false}>
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            displayRowCheckbox={false}
            displayBorder={false}
            style={tableHeaderStyle}
            className="flex-one"
          >
            <TableRow displayBorder={false} style={tableHeaderRowStyle}>
              <TableRowColumn style={thStyle} className="CNG-th">
                ITEM NAME & ID
              </TableRowColumn>
              <TableRowColumn style={thStyle} className="CNG-th">
                FABRIC
              </TableRowColumn>
              <TableRowColumn style={thStyle} className="CNG-th">
                COLOR
              </TableRowColumn>
              <TableRowColumn style={thStyle} className="CNG-th">
                BRAND
              </TableRowColumn>
              <TableRowColumn style={thStyle} className="CNG-th">
                ART FILE
              </TableRowColumn>
              <TableRowColumn style={thStyle} className="CNG-th">
                ADD QTY
              </TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>{this._renderBody()}</TableBody>
        </CNGTableCustom>
      </div>
    );
  }

  getProductInformationRow = (
    companyId,
    orderItemProduct,
    inventoryHistories,
    product
  ) => {
    const assignment = lodash.get(
      orderItemProduct,
      "orderItemProductAdornmentLocationArtAssignments[0]"
    );

    return (
      <React.Fragment>
        <CNGToolTip
          parentRef={`assignment-${assignment && assignment.id}`}
          style={{width: 250}}
          element={assignment}
        >
          <Link
            to={`/companies/${companyId}/products/${orderItemProduct.id}`}
            className="reset-link-styles"
            title={lodash.get(product, "productName")}
          >
            {product && product.productName}
          </Link>
        </CNGToolTip>
        <div className="table-subtext">
          Item #{product && product.itemNumber}{" "}
          {inventoryHistories &&
            inventoryHistories.map(inventoryHistory => (
              <div key={inventoryHistory.id}>
                CNG #{" "}
                {lodash.get(
                  inventoryHistory,
                  "inventoryItem.order.cngReferenceNumber"
                )}
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  };

  _renderRow = (orderItem, key) => {
    const {companyId} = this.props;
    const {
      product,
      fabricColor,
      orderItemProduct: {art},
      quantityToShip,
      inventoryHistories,
      orderItemProduct,
    } = orderItem;

    const brand = lodash.get(art, "artSource.brand");

    return (
      <CNGTableRow key={key}>
        <CNGTableRowColumn highlight={true}>
          {this.getProductInformationRow(
            companyId,
            orderItemProduct,
            inventoryHistories,
            product
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          {fabricColor && fabricColor.fabric && fabricColor.fabric.name}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          {fabricColor && fabricColor.color.name}
        </CNGTableRowColumn>
        <CNGTableRowColumn highlight={true}>
          {brand && (
            <Link
              to={`/companies/${companyId}/brands/${brand.id}`}
              className="no-link-styles"
            >
              {brand.name}
            </Link>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <OrderItemArtDetails orderItem={orderItem}>
            <Link to={`/brands/${brand && brand.id}/arts/${art && art.id}`}>
              {art && art.name}
            </Link>
          </OrderItemArtDetails>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{quantityToShip}</CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  _renderBody() {
    const {orderItems} = this.props;
    return orderItems && orderItems.map(this._renderRow);
  }

  render() {
    const {shipmentItem, fetchOrder, orderId, shipment} = this.props;
    const recipient = lodash.get(shipmentItem, "recipientInformation");
    return (
      <CNGCard title={recipient && recipient.name}>
        <div>
          {recipient && this._renderRecipientSummary(recipient)}
          {this._renderOrderItems()}
          {shipmentItem ? (
            <DSODShipmentTracking
              shipmentItem={shipmentItem}
              shipment={shipment}
              onUpdateShipmentItem={() => {
                fetchOrder && fetchOrder(orderId);
              }}
            />
          ) : null}
        </div>
      </CNGCard>
    );
  }
}
