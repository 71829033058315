import moment from "moment";

export function getFormattedDateTime(date = new Date()) {
  return `${date.getMonth() +
    1}/${date.getDate()} ${date.getHours()}:${padLeadingZero(
    date.getMinutes()
  )}:${padLeadingZero(date.getSeconds())}`;
}

export function getMomentDate(date, keepLocalTime = false) {
  if (!date) return;
  if (typeof date === "string") {
    date = moment(date, "MM-DD-YYYY");
  } else {
    date = moment(date).utc(keepLocalTime);
  }
  return date;
}

export function getDate(date) {
  return getMomentDate(date).toDate();
}

export function getFormattedDate(date = new Date()) {
  date = getMomentDate(date);
  if (!date) return;

  return date.format("MMM D, YYYY");
}

export function getFormattedDateAndTime(date = new Date()) {
  date = getMomentDate(date, true);
  if (!date) return;

  return date.format("MMM D, YYYY [at] hh:mm a");
}

export function getISO8601Date(value) {
  return moment(value)
    .utc(false)
    .format("YYYY-MM-DD");
}

export function padLeadingZero(value) {
  return value > 9 ? value : `0${value}`;
}

export function getTodayDate() {
  const today = moment(new Date());
  return today.startOf("day").toDate();
}
