export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORGOT_REQUEST = "FORGOT_REQUEST";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_ERROR = "FORGOT_ERROR";
export const RESET_REQUEST = "RESET_REQUEST";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const SELECT_COMPANY_REQUEST = "SELECT_COMPANY_REQUEST";
export const SELECT_COMPANY_SUCCESS = "SELECT_COMPANY_SUCCESS";
export const SELECT_COMPANY_ERROR = "SELECT_COMPANY_ERROR";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

export const ARCHIVE_USER_REQUEST = "ARCHIVE_USER_REQUEST";
export const ARCHIVE_USER_SUCCESS = "ARCHIVE_USER_SUCCESS";
export const ARCHIVE_USER_ERROR = "ARCHIVE_USER_ERROR";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const CREATE_USER_NOTE_REQUEST = "CREATE_USER_NOTE_REQUEST";
export const CREATE_USER_NOTE_SUCCESS = "CREATE_USER_NOTE_SUCCESS";
export const CREATE_USER_NOTE_ERROR = "CREATE_USER_NOTE_ERROR";

export const VALIDATE_USER_EMAIL_COMPANY_REQUEST = "VALIDATE_USER_EMAIL_COMPANY_REQUEST";
export const VALIDATE_USER_EMAIL_COMPANY_SUCCESS = "VALIDATE_USER_EMAIL_COMPANY_SUCCESS";
export const VALIDATE_USER_EMAIL_COMPANY_ERROR = "VALIDATE_USER_EMAIL_COMPANY_ERROR";

export const DELETE_USER_NOTE_REQUEST = "DELETE_USER_NOTE_REQUEST";
export const DELETE_USER_NOTE_SUCCESS = "DELETE_USER_NOTE_SUCCESS";
export const DELETE_USER_NOTE_ERROR = "DELETE_USER_NOTE_ERROR";

export const FETCH_STATES_REQUEST = "FETCH_STATES_REQUEST";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_ERROR = "FETCH_STATES_ERROR";

export const FETCH_COUNTRIES_REQUEST = "FETCH_COUNTRIES_REQUEST";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_ERROR = "FETCH_COUNTRIES_ERROR";

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_ERROR = "FETCH_COMPANIES_ERROR";

export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";

export const ARCHIVE_COMPANY_CONTACT_REQUEST =
  "ARCHIVE_COMPANY_CONTACT_REQUEST";
export const ARCHIVE_COMPANY_CONTACT_SUCCESS =
  "ARCHIVE_COMPANY_CONTACT_SUCCESS";
export const ARCHIVE_COMPANY_CONTACT_ERROR = "ARCHIVE_COMPANY_CONTACT_ERROR";

export const FETCH_COMPANY_CONTACTS_REQUEST = "FETCH_COMPANY_CONTACTS_REQUEST";
export const FETCH_COMPANY_CONTACTS_SUCCESS = "FETCH_COMPANY_CONTACTS_SUCCESS";
export const FETCH_COMPANY_CONTACTS_ERROR = "FETCH_COMPANY_CONTACTS_ERROR";

export const FETCH_COMPANY_CONTACT_REQUEST = "FETCH_COMPANY_CONTACT_REQUEST";
export const FETCH_COMPANY_CONTACT_SUCCESS = "FETCH_COMPANY_CONTACT_SUCCESS";
export const FETCH_COMPANY_CONTACT_ERROR = "FETCH_COMPANY_CONTACT_ERROR";

export const CREATE_COMPANY_CONTACT_REQUEST = "CREATE_COMPANY_CONTACT_REQUEST";
export const CREATE_COMPANY_CONTACT_SUCCESS = "CREATE_COMPANY_CONTACT_SUCCESS";
export const CREATE_COMPANY_CONTACT_ERROR = "CREATE_COMPANY_CONTACT_ERROR";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const UPDATE_COMPANY_CONTACT_REQUEST = "UPDATE_COMPANY_CONTACT_REQUEST";
export const UPDATE_COMPANY_CONTACT_SUCCESS = "UPDATE_COMPANY_CONTACT_SUCCESS";
export const UPDATE_COMPANY_CONTACT_ERROR = "UPDATE_COMPANY_CONTACT_ERROR";

export const FETCH_ASSOCIATED_COMPANIES_REQUEST =
  "FETCH_ASSOCIATED_COMPANIES_REQUEST";
export const FETCH_ASSOCIATED_COMPANIES_SUCCESS =
  "FETCH_ASSOCIATED_COMPANIES_SUCCESS";
export const FETCH_ASSOCIATED_COMPANIES_ERROR =
  "FETCH_ASSOCIATED_COMPANIES_ERROR";

export const CREATE_COMPANY_COMPANY_REQUEST = "CREATE_COMPANY_COMPANY_REQUEST";
export const CREATE_COMPANY_COMPANY_SUCCESS = "CREATE_COMPANY_COMPANY_SUCCESS";
export const CREATE_COMPANY_COMPANY_ERROR = "CREATE_COMPANY_COMPANY_ERROR";

export const DELETE_COMPANY_COMPANY_REQUEST = "DELETE_COMPANY_COMPANY_REQUEST";
export const DELETE_COMPANY_COMPANY_SUCCESS = "DELETE_COMPANY_COMPANY_SUCCESS";
export const DELETE_COMPANY_COMPANY_ERROR = "DELETE_COMPANY_COMPANY_ERROR";

export const CREATE_COMPANY_NOTE_REQUEST = "CREATE_COMPANY_NOTE_REQUEST";
export const CREATE_COMPANY_NOTE_SUCCESS = "CREATE_COMPANY_NOTE_SUCCESS";
export const CREATE_COMPANY_NOTE_ERROR = "CREATE_COMPANY_NOTE_ERROR";

export const DELETE_COMPANY_NOTE_REQUEST = "DELETE_COMPANY_NOTE_REQUEST";
export const DELETE_COMPANY_NOTE_SUCCESS = "DELETE_COMPANY_NOTE_SUCCESS";
export const DELETE_COMPANY_NOTE_ERROR = "DELETE_COMPANY_NOTE_ERROR";

export const FETCH_DISCOUNTED_REQUEST = "FETCH_DISCOUNTED_REQUEST";
export const FETCH_DISCOUNTED_SUCCESS = "FETCH_DISCOUNTED_SUCCESS";
export const FETCH_DISCOUNTED_ERROR = "FETCH_DISCOUNTED_ERROR";

export const FETCH_BUSINESSES_REQUEST = "FETCH_BUSINESSES_REQUEST";
export const FETCH_BUSINESSES_SUCCESS = "FETCH_BUSINESSES_SUCCESS";
export const FETCH_BUSINESSES_ERROR = "FETCH_BUSINESSES_ERROR";

export const FETCH_PAYMENTTERMS_REQUEST = "FETCH_PAYMENTTERMS_REQUEST";
export const FETCH_PAYMENTTERMS_SUCCESS = "FETCH_PAYMENTTERMS_SUCCESS";
export const FETCH_PAYMENTTERMS_ERROR = "FETCH_PAYMENTTERMS_ERROR";

export const FETCH_BRANDS_REQUEST = "FETCH_BRANDS_REQUEST";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_ERROR = "FETCH_BRANDS_ERROR";

export const FETCH_BRAND_REQUEST = "FETCH_BRAND_REQUEST";
export const FETCH_BRAND_SUCCESS = "FETCH_BRAND_SUCCESS";
export const FETCH_BRAND_ERROR = "FETCH_BRAND_ERROR";

export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_ERROR = "CREATE_BRAND_ERROR";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_ERROR = "UPDATE_BRAND_ERROR";

export const FETCH_ALL_ART_REQUEST = "FETCH_ALL_ART_REQUEST";
export const FETCH_ALL_ART_SUCCESS = "FETCH_ALL_ART_SUCCESS";
export const FETCH_ALL_ART_ERROR = "FETCH_ALL_ART_ERROR";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_ERROR = "DELETE_BRAND_ERROR";

export const FETCH_ART_REQUEST = "FETCH_ART_REQUEST";
export const FETCH_ART_SUCCESS = "FETCH_ART_SUCCESS";
export const FETCH_ART_ERROR = "FETCH_ART_ERROR";

export const CREATE_ART_REQUEST = "CREATE_ART_REQUEST";
export const CREATE_ART_SUCCESS = "CREATE_ART_SUCCESS";
export const CREATE_ART_ERROR = "CREATE_ART_ERROR";

export const UPDATE_ART_REQUEST = "UPDATE_ART_REQUEST";
export const UPDATE_ART_SUCCESS = "UPDATE_ART_SUCCESS";
export const UPDATE_ART_ERROR = "UPDATE_ART_ERROR";

export const UPDATE_ART_REVISION_REQUEST = "UPDATE_ART_REVISION_REQUEST";
export const UPDATE_ART_REVISION_SUCCESS = "UPDATE_ART_REVISION_SUCCESS";
export const UPDATE_ART_REVISION_ERROR = "UPDATE_ART_REVISION_ERROR";

export const DELETE_ART_REVISION_REQUEST = "DELETE_ART_REVISION_REQUEST";
export const DELETE_ART_REVISION_SUCCESS = "DELETE_ART_REVISION_SUCCESS";
export const DELETE_ART_REVISION_ERROR = "DELETE_ART_REVISION_ERROR";

export const FETCH_EMBROIDERY_DETAILS_REQUEST =
  "FETCH_EMBROIDERY_DETAILS_REQUEST";
export const FETCH_EMBROIDERY_DETAILS_SUCCESS =
  "FETCH_EMBROIDERY_DETAILS_SUCCESS";
export const FETCH_EMBROIDERY_DETAILS_ERROR = "FETCH_EMBROIDERY_DETAILS_ERROR";

export const CREATE_EMBROIDERY_DETAILS_REQUEST =
  "CREATE_EMBROIDERY_DETAILS_REQUEST";
export const CREATE_EMBROIDERY_DETAILS_SUCCESS =
  "CREATE_EMBROIDERY_DETAILS_SUCCESS";
export const CREATE_EMBROIDERY_DETAILS_ERROR =
  "CREATE_EMBROIDERY_DETAILS_ERROR";

export const SEARCH_PRODUCT_SHIPMENTS_REQUEST =
  "SEARCH_PRODUCT_SHIPMENTS_REQUEST";
export const SEARCH_PRODUCT_SHIPMENTS_SUCCESS =
  "SEARCH_PRODUCT_SHIPMENTS_SUCCESS";
export const SEARCH_PRODUCT_SHIPMENTS_ERROR = "SEARCH_PRODUCT_SHIPMENTS_ERROR";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR";

export const FETCH_PRODUCT_IMAGES_REQUEST = "FETCH_PRODUCT_IMAGES_REQUEST";
export const FETCH_PRODUCT_IMAGES_SUCCESS = "FETCH_PRODUCT_IMAGES_SUCCESS";
export const FETCH_PRODUCT_IMAGES_ERROR = "FETCH_PRODUCT_IMAGES_ERROR";

export const FETCH_FABRICS_REQUEST = "FETCH_FABRICS_REQUEST";
export const FETCH_FABRICS_SUCCESS = "FETCH_FABRICS_SUCCESS";
export const FETCH_FABRICS_ERROR = "FETCH_FABRICS_ERROR";

export const FETCH_FABRIC_REQUEST = "FETCH_FABRIC_REQUEST";
export const FETCH_FABRIC_SUCCESS = "FETCH_FABRIC_SUCCESS";
export const FETCH_FABRIC_ERROR = "FETCH_FABRIC_ERROR";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR";

export const FETCH_DSOD_ORDERS_REQUEST = "FETCH_DSOD_ORDERS_REQUEST";
export const FETCH_DSOD_ORDERS_SUCCESS = "FETCH_DSOD_ORDERS_SUCCESS";
export const FETCH_DSOD_ORDERS_ERROR = "FETCH_DSOD_ORDERS_ERROR";

export const ARCHIVE_ORDER_REQUEST = "ARCHIVE_ORDER_REQUEST";
export const ARCHIVE_ORDER_SUCCESS = "ARCHIVE_ORDER_SUCCESS";
export const ARCHIVE_ORDER_ERROR = "ARCHIVE_ORDER_ERROR";

export const FETCH_ORDERS_UNREAD_COUNTS = "FETCH_ORDERS_UNREAD_COUNTS";

export const FETCH_DSOD_COMPANIES_REQUEST = "FETCH_DSOD_COMPANIES_REQUEST";
export const FETCH_DSOD_COMPANIES_SUCCESS = "FETCH_DSOD_COMPANIES_SUCCESS";
export const FETCH_DSOD_COMPANIES_ERROR = "FETCH_DSOD_COMPANIES_ERROR";

export const FETCH_COMPANIES_WITH_DSOD_REQUEST =
  "FETCH_COMPANIES_WITH_DSOD_REQUEST";
export const FETCH_COMPANIES_WITH_DSOD_SUCCESS =
  "FETCH_COMPANIES_WITH_DSOD_SUCCESS";
export const FETCH_COMPANIES_WITH_DSOD_ERROR =
  "FETCH_COMPANIES_WITH_DSOD_ERROR";

export const FETCH_OPEN_ORDERS_REQUEST = "FETCH_OPEN_ORDERS_REQUEST";
export const FETCH_OPEN_ORDERS_SUCCESS = "FETCH_OPEN_ORDERS_SUCCESS";
export const FETCH_OPEN_ORDERS_ERROR = "FETCH_OPEN_ORDERS_ERROR";

export const FETCH_USER_OPEN_ORDERS_REQUEST = "FETCH_USER_OPEN_ORDERS_REQUEST";
export const FETCH_USER_OPEN_ORDERS_SUCCESS = "FETCH_USER_OPEN_ORDERS_SUCCESS";
export const FETCH_USER_OPEN_ORDERS_ERROR = "FETCH_USER_OPEN_ORDERS_ERROR";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_ERROR = "FETCH_ORDER_ERROR";

export const FETCH_ORDER_ITEMS_REQUEST = "FETCH_ORDER_ITEMS_REQUEST";
export const FETCH_ORDER_ITEMS_SUCCESS = "FETCH_ORDER_ITEMS_SUCCESS";
export const FETCH_ORDER_ITEMS_ERROR = "FETCH_ORDER_ITEMS_ERROR";

export const FETCH_ORDER_ITEM_REQUEST = "FETCH_ORDER_ITEM_REQUEST";
export const FETCH_ORDER_ITEM_SUCCESS = "FETCH_ORDER_ITEM_SUCCESS";
export const FETCH_ORDER_ITEM_ERROR = "FETCH_ORDER_ITEM_ERROR";

export const DELETE_ORDER_ITEM_REQUEST = "DELETE_ORDER_ITEM_REQUEST";
export const DELETE_ORDER_ITEM_SUCCESS = "DELETE_ORDER_ITEM_SUCCESS";
export const DELETE_ORDER_ITEM_ERROR = "DELETE_ORDER_ITEM_ERROR";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";

export const UPDATE_ORDER_ITEM_REQUEST = "UPDATE_ORDER_ITEM_REQUEST";
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS";
export const UPDATE_ORDER_ITEM_ERROR = "UPDATE_ORDER_ITEM_ERROR";

export const FETCH_ORDER_STATUS_REQUEST = "FETCH_ORDER_STATUS_REQUEST";
export const FETCH_ORDER_STATUS_SUCCESS = "FETCH_ORDER_STATUS_SUCCESS";
export const FETCH_ORDER_STATUS_ERROR = "FETCH_ORDER_STATUS_ERROR";

export const FETCH_INVENTORY_ITEMS_REQUEST = "FETCH_INVENTORY_ITEMS_REQUEST";
export const FETCH_INVENTORY_ITEMS_SUCCESS = "FETCH_INVENTORY_ITEMS_SUCCESS";
export const FETCH_INVENTORY_ITEMS_ERROR = "FETCH_INVENTORY_ERROR";

export const FETCH_INVENTORY_ITEM_REQUEST = "FETCH_INVENTORY_ITEM_REQUEST";
export const FETCH_INVENTORY_ITEM_SUCCESS = "FETCH_INVENTORY_ITEM_SUCCESS";

export const FETCH_INVENTORY_REQUEST = "FETCH_INVENTORY_REQUEST";
export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_ERROR = "FETCH_INVENTORY_ERROR";

export const SEARCH_INVENTORY_ITEMS_REQUEST = "SEARCH_INVENTORY_ITEMS_REQUEST";
export const SEARCH_INVENTORY_ITEMS_SUCCESS = "SEARCH_INVENTORY_ITEMS_SUCCESS";
export const SEARCH_INVENTORY_ITEMS_ERROR = "SEARCH_INVENTORY_ERROR";

export const FETCH_SHIPMENTS_REQUEST = "FETCH_SHIPMENTS_REQUEST";
export const FETCH_SHIPMENTS_SUCCESS = "FETCH_SHIPMENTS_SUCCESS";
export const FETCH_SHIPMENTS_ERROR = "FETCH_SHIPMENTS_ERROR";

export const FETCH_SHIPMENT_REQUEST = "FETCH_SHIPMENT_REQUEST";
export const FETCH_SHIPMENT_SUCCESS = "FETCH_SHIPMENT_SUCCESS";
export const FETCH_SHIPMENT_ERROR = "FETCH_SHIPMENT_ERROR";

export const UPDATE_SHIPMENT_REQUEST = "UPDATE_SHIPMENT_REQUEST";
export const UPDATE_SHIPMENT_SUCCESS = "UPDATE_SHIPMENT_SUCCESS";
export const UPDATE_SHIPMENT_ERROR = "UPDATE_SHIPMENT_ERROR";

export const CREATE_SHIPMENT_ITEM_REQUEST = "CREATE_SHIPMENT_ITEM_REQUEST";
export const CREATE_SHIPMENT_ITEM_SUCCESS = "CREATE_SHIPMENT_ITEM_SUCCESS";
export const CREATE_SHIPMENT_ITEM_ERROR = "CREATE_SHIPMENT_ITEM_ERROR";

export const FETCH_ADORNMENT_TYPE_REQUEST = "FETCH_ADORNMENT_TYPE_REQUEST";
export const FETCH_ADORNMENT_TYPE_SUCCESS = "FETCH_ADORNMENT_TYPE_SUCCESS";
export const FETCH_ADORNMENT_TYPE_ERROR = "FETCH_ADORNMENT_TYPE_ERROR";

export const UPDATE_SHIPMENT_ITEM_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_SHIPMENT_ITEM_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_SHIPMENT_ITEM_ERROR = "UPDATE_ORDER_ERROR";

export const FETCH_SHIPPING_METHODS_REQUEST = "FETCH_SHIPPING_METHODS_REQUEST";
export const FETCH_SHIPPING_METHODS_SUCCESS = "FETCH_SHIPPING_METHODS_SUCCESS";
export const FETCH_SHIPPING_METHODS_ERROR = "FETCH_SHIPPING_METHODS_ERROR";

export const FETCH_SHIPPING_CARRIERS_REQUEST =
  "FETCH_SHIPPING_CARRIERS_REQUEST";
export const FETCH_SHIPPING_CARRIERS_SUCCESS =
  "FETCH_SHIPPING_CARRIERS_SUCCESS";
export const FETCH_SHIPPING_CARRIERS_ERROR = "FETCH_SHIPPING_CARRIERS_ERROR";

export const PATCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST =
  "PATCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST";
export const PATCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS =
  "PATCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS";
export const PATCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR =
  "PATCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR";

export const FETCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST =
  "FETCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST";
export const FETCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS =
  "FETCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS";
export const FETCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR =
  "FETCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR";

export const DELETE_SHIPPING_CARRIER_ASSIGNMENT_REQUEST =
  "DELETE_SHIPPING_CARRIER_ASSIGNMENT_REQUEST";
export const DELETE_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS =
  "DELETE_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS";
export const DELETE_SHIPPING_CARRIER_ASSIGNMENT_ERROR =
  "DELETE_SHIPPING_CARRIER_ASSIGNMENT_ERROR";

export const FETCH_EMBROIDERY_TYPES_REQUEST = "FETCH_EMBROIDERY_TYPES_REQUEST";
export const FETCH_EMBROIDERY_TYPES_SUCCESS = "FETCH_EMBROIDERY_TYPES_SUCCESS";
export const FETCH_EMBROIDERY_TYPES_ERROR = "FETCH_EMBROIDERY_TYPES_ERROR";

export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_REQUEST =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_REQUEST";
export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_SUCCESS =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_SUCCESS";
export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_ERROR =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_ERROR";

export const CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST =
  "CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST";
export const CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS =
  "CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS";
export const CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR =
  "CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR";

export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST";
export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS";
export const FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR =
  "FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR";

export const FETCH_ADORNMENT_APPLICABILITIES_REQUEST =
  "FETCH_ADORNMENT_APPLICABILITIES_REQUEST";
export const FETCH_ADORNMENT_APPLICABILITIES_SUCCESS =
  "FETCH_ADORNMENT_APPLICABILITIES_SUCCESS";
export const FETCH_ADORNMENT_APPLICABILITIES_ERROR =
  "FETCH_ADORNMENT_APPLICABILITIES_ERROR";

export const CREATE_DSOD_ITEMS_REQUEST = "CREATE_DSOD_ITEMS_REQUEST";
export const CREATE_DSOD_ITEMS_SUCCESS = "CREATE_DSOD_ITEMS_SUCCESS";
export const CREATE_DSOD_ITEMS_ERROR = "CREATE_DSOD_ITEMS_ERROR";

export const FETCH_DSOD_ITEMS_REQUEST = "FETCH_DSOD_ITEMS_REQUEST";
export const FETCH_DSOD_ITEMS_SUCCESS = "FETCH_DSOD_ITEMS_SUCCESS";
export const FETCH_DSOD_ITEMS_ERROR = "FETCH_DSOD_ITEMS_ERROR";

export const CREATE_DSOD_ITEM_REQUEST = "CREATE_DSOD_ITEM_REQUEST";
export const CREATE_DSOD_ITEM_SUCCESS = "CREATE_DSOD_ITEM_SUCCESS";
export const CREATE_DSOD_ITEM_ERROR = "CREATE_DSOD_ITEM_ERROR";

export const UPDATE_DSOD_ITEM_REQUEST = "UPDATE_DSOD_ITEM_REQUEST";
export const UPDATE_DSOD_ITEM_SUCCESS = "UPDATE_DSOD_ITEM_SUCCESS";
export const UPDATE_DSOD_ITEM_ERROR = "UPDATE_DSOD_ITEM_ERROR";

export const DELETE_DSOD_ITEM_REQUEST = "DELETE_DSOD_ITEM_REQUEST";
export const DELETE_DSOD_ITEM_SUCCESS = "DELETE_DSOD_ITEM_SUCCESS";
export const DELETE_DSOD_ITEM_ERROR = "DELETE_DSOD_ITEM_ERROR";

export const FETCH_ART_REVISIONS_REQUEST = "FETCH_ART_REVISIONS_REQUEST";
export const FETCH_ART_REVISIONS_SUCCESS = "FETCH_ART_REVISIONS_SUCCESS";
export const FETCH_ART_REVISIONS_ERROR = "FETCH_ART_REVISIONS_ERROR";

export const CLEAR_ART_REVISIONS = "CLEAR_ART_REVISIONS";

export const ARCHIVE_USER_COMPANY_REQUEST = "ARCHIVE_USER_COMPANY_REQUEST";
export const ARCHIVE_USER_COMPANY_SUCCESS = "ARCHIVE_USER_COMPANY_SUCCESS";
export const ARCHIVE_USER_COMPANY_ERROR = "ARCHIVE_USER_COMPANY_ERROR";

export const FETCH_WALLET_MEMBERS_REQUEST = "FETCH_WALLET_MEMBERS_REQUEST";
export const FETCH_WALLET_MEMBERS_SUCCESS = "FETCH_WALLET_MEMBERS_SUCCESS";
export const FETCH_WALLET_MEMBERS_ERROR = "FETCH_WALLET_MEMBERS_ERROR";

export const UPDATE_WALLET_MEMBER_REQUEST = "UPDATE_WALLET_MEMBER_REQUEST";
export const UPDATE_WALLET_MEMBER_SUCCESS = "UPDATE_WALLET_MEMBER_SUCCESS";
export const UPDATE_WALLET_MEMBER_ERROR = "UPDATE_WALLET_MEMBER_ERROR";

export const CREATE_WALLET_MEMBER_REQUEST = "CREATE_WALLET_MEMBER_REQUEST";
export const CREATE_WALLET_MEMBER_SUCCESS = "CREATE_WALLET_MEMBER_SUCCESS";
export const CREATE_WALLET_MEMBER_ERROR = "CREATE_WALLET_MEMBER_ERROR";

export const FETCH_ORDER_ITEM_PRODUCT_REQUEST =
  "FETCH_ORDER_ITEM_PRODUCT_REQUEST";
export const FETCH_ORDER_ITEM_PRODUCT_SUCCESS =
  "FETCH_ORDER_ITEM_PRODUCT_SUCCESS";
export const FETCH_ORDER_ITEM_PRODUCT_ERROR = "FETCH_ORDER_ITEM_PRODUCT_ERROR";

export const FETCH_ORDER_ITEM_PRODUCTS_REQUEST =
  "FETCH_ORDER_ITEM_PRODUCTS_REQUEST";
export const FETCH_ORDER_ITEM_PRODUCTS_SUCCESS =
  "FETCH_ORDER_ITEM_PRODUCTS_SUCCESS";

export const FETCH_ORDER_ITEM_TYPES_REQUEST = "FETCH_ORDER_ITEM_TYPES_REQUEST";
export const FETCH_ORDER_ITEM_TYPES_SUCCESS = "FETCH_ORDER_ITEM_TYPES_SUCCESS";
export const FETCH_ORDER_ITEM_TYPES_ERROR = "FETCH_ORDER_ITEM_TYPES_ERROR";

export const FETCH_COLORS_REQUEST = "FETCH_COLORS_REQUEST";
export const FETCH_COLORS_SUCCESS = "FETCH_COLORS_SUCCESS";
export const FETCH_COLORS_ERROR = "FETCH_COLORS_ERROR";

export const FETCH_LEATHER_COLORS_REQUEST = "FETCH_LEATHER_COLORS_REQUEST";
export const FETCH_LEATHER_COLORS_SUCCESS = "FETCH_LEATHER_COLORS_SUCCESS";
export const FETCH_LEATHER_COLORS_ERROR = "FETCH_LEATHER_COLORS_ERROR";

export const FETCH_ADORNMENT_LOCATIONS_REQUEST =
  "FETCH_ADORNMENT_LOCATIONS_REQUEST";
export const FETCH_ADORNMENT_LOCATIONS_SUCCESS =
  "FETCH_ADORNMENT_LOCATIONS_SUCCESS";
export const FETCH_ADORNMENT_LOCATIONS_ERROR =
  "FETCH_ADORNMENT_LOCATIONS_ERROR";

export const CREATE_ORDER_ITEM_REQUEST = "CREATE_ORDER_ITEM_REQUEST";
export const CREATE_ORDER_ITEM_SUCCESS = "CREATE_ORDER_ITEM_SUCCESS";
export const CREATE_ORDER_ITEM_ERROR = "CREATE_ORDER_ITEM_ERROR";

export const FETCH_DIGITALIZATION_TYPE_PRICES_REQUEST =
  "FETCH_DIGITALIZATION_TYPE_PRICES_REQUEST";
export const FETCH_DIGITALIZATION_TYPE_PRICES_SUCCESS =
  "FETCH_DIGITALIZATION_TYPE_PRICES_SUCCESS";
export const FETCH_DIGITALIZATION_TYPE_PRICES_ERROR =
  "FETCH_DIGITALIZATION_TYPE_PRICES_ERROR";

export const OPEN_ERROR_MESSAGE = "OPEN_ERROR_MESSAGE";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";
export const CREATE_QUOTE_REQUEST = "CREATE_QUOTE_REQUEST";

export const FETCH_QUOTE_SUCCESS = "FETCH_QUOTE_SUCCESS";
export const FETCH_QUOTE_REQUEST = "FETCH_QUOTE_REQUEST";

export const FETCH_QUOTES_SUCCESS = "FETCH_QUOTES_SUCCESS";
export const FETCH_QUOTES_REQUEST = "FETCH_QUOTES_REQUEST";

export const FETCH_QUOTES_UNREAD_COUNTS = "FETCH_QUOTES_UNREAD_COUNTS";

export const UPDATE_QUOTE_REQUEST = "UPDATE_QUOTE_REQUEST";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";

export const DELETE_QUOTE_REQUEST = "DELETE_QUOTE_REQUEST";
export const DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS";

export const FETCH_QUOTE_ITEM_PRICES_REQUEST =
  "FETCH_QUOTE_ITEM_PRICES_REQUEST";
export const FETCH_QUOTE_ITEM_PRICES_SUCCESS =
  "FETCH_QUOTE_ITEM_PRICES_SUCCESS";

export const FETCH_QUOTE_ITEM_PRICE_REQUEST = "FETCH_QUOTE_ITEM_PRICE_REQUEST";
export const FETCH_QUOTE_ITEM_PRICE_SUCCESS = "FETCH_QUOTE_ITEM_PRICE_SUCCESS";

export const UPLOAD_ART_FILE_REQUEST = "UPLOAD_ART_FILE_REQUEST";
export const UPLOAD_ART_FILE_SUCCESS = "UPLOAD_ART_FILE_SUCCESS";

export const UPLOAD_CHAT_FILE_REQUEST = "UPLOAD_CHAT_FILE_REQUEST";
export const UPLOAD_CHAT_FILE_SUCCESS = "UPLOAD_CHAT_FILE_SUCCESS";

export const UPDATE_ART_ASSIGNMENT_SUCCESS = "UPDATE_ART_ASSIGNMENT_SUCCESS";
export const UPDATE_ART_ASSIGNMENT_REQUEST = "UPDATE_ART_ASSIGNMENT_REQUEST";
export const UPDATE_ORDER_ITEM_PRODUCT_ART_ASSIGNMENTS =
  "UPDATE_ORDER_ITEM_PRODUCT_ART_ASSIGNMENTS";

export const FETCH_PRODUCT_CATEGORIES_REQUEST =
  "FETCH_PRODUCT_CATEGORIES_REQUEST";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_ERROR = "FETCH_PRODUCT_CATEGORIES_ERROR";

export const FETCH_BAG_TYPES_REQUEST = "FETCH_BAG_TYPES_REQUEST";
export const FETCH_BAG_TYPES_SUCCESS = "FETCH_BAG_TYPES_SUCCESS";
export const FETCH_BAG_TYPES_ERROR = "FETCH_BAG_TYPES_ERROR";

export const FETCH_BAG_CAPACITIES_REQUEST = "FETCH_BAG_CAPACITIES_REQUEST";
export const FETCH_BAG_CAPACITIES_SUCCESS = "FETCH_BAG_CAPACITIES_SUCCESS";
export const FETCH_BAG_CAPACITIES_ERROR = "FETCH_BAG_CAPACITIES_ERROR";

export const FETCH_PRICE_TABLE_LINKS_REQUEST =
  "FETCH_PRICE_TABLE_LINKS_REQUEST";
export const FETCH_PRICE_TABLE_LINKS_SUCCESS =
  "FETCH_PRICE_TABLE_LINKS_SUCCESS";
export const FETCH_PRICE_TABLE_LINKS_ERROR = "FETCH_PRICE_TABLE_LINKS_ERROR";

export const FETCH_CHAT_CHANNELS_REQUEST = "FETCH_CHAT_CHANNELS_REQUEST";
export const FETCH_CHAT_CHANNELS_SUCCESS = "FETCH_CHAT_CHANNELS_SUCCESS";
export const FETCH_CHAT_CHANNELS_ERROR = "FETCH_CHAT_CHANNELS_ERROR";
export const FETCH_CHAT_CHANNEL_REQUEST = "FETCH_CHAT_CHANNEL_REQUEST";
export const FETCH_CHAT_CHANNEL_SUCCESS = "FETCH_CHAT_CHANNEL_SUCCESS";
export const FETCH_CHAT_CHANNEL_ERROR = "FETCH_CHAT_CHANNEL_ERROR";
export const FETCH_CHAT_MESSAGES_REQUEST = "FETCH_CHAT_MESSAGES_REQUEST";
export const FETCH_CHAT_MESSAGES_SUCCESS = "FETCH_CHAT_MESSAGES_SUCCESS";
export const FETCH_CHAT_MESSAGES_ERROR = "FETCH_CHAT_MESSAGES_ERROR";
export const SEND_CHAT_MESSAGES_REQUEST = "SEND_CHAT_MESSAGES_REQUEST";
export const SEND_CHAT_MESSAGES_SUCCESS = "SEND_CHAT_MESSAGES_SUCCESS";
export const SEND_CHAT_MESSAGES_ERROR = "SEND_CHAT_MESSAGES_ERROR";
export const RECEIVE_CHAT_MESSAGES_SUCCESS = "RECEIVE_CHAT_MESSAGES_SUCCESS";
export const FETCH_CHATS_UNREAD_COUNTS = "FETCH_CHATS_UNREAD_COUNTS";
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
