import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _cloneDeep from "lodash/cloneDeep";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import ImageEdit from "material-ui/svg-icons/image/edit";
import EditOrderItemModal from "../../../shared/EditOrderItemModal";

import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../../../styles/components/formStyles";

import RecipientForm from "../../../containers/DSOD/RecipientFormContainer";
import CNGCard from "../../../shared/CNGCard";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";
import LineHeader from "../../../shared/LineHeader";
import RecipientTable from "./RecipientTable";
import { getCurrencyFormattedNumber } from "../../../../utils/numberFormat";
import { INACTIVE_ART_TITLE } from "../../../../constants/art";
export const getArtTitle = revision => {
  if (!revision) return "";

  if (_get(revision, "active")) return _get(revision, "name");
  return INACTIVE_ART_TITLE;
};

const closeIconStyle = {
  color: "#0C5371",
  fontWeight: "300",
  marginLeft: 4,
};

const BeforeCardTitle = {
  color: "#747D86",
  fontSize: 12,
  display: "block",
  lineHeight: 2,
};

const RemoveStyle = {
  cursor: "pointer",
  color: "#0C5271",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 14,
};

const ItemStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
  },

  data: {
    color: "#747D86",
    fontSize: 14,
    lineHeight: "20px",
    height: 18,
  },

  dataLink: {
    color: "#0C5271",
    fontWeight: "bold",
  },
};

class ReorderItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecipientForm: false,
      reorderItem: props.reorderItem,
      errors: {},
      openEditOrderItemModal: false,
    };
  }

  componentDidUpdate(prevProps){
    const {errors} = this.props;
    if (prevProps.errors !== errors) {
      this.setState({errors});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.reorderItem.updatedAt !== this.props.reorderItem.updatedAt ||
      nextProps.reorderItem.product !== this.props.reorderItem.product
    ) {
      this.setState({
        reorderItem: nextProps.reorderItem,
      });
    }
  }

  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(event, value);
    }
  };

  handleToggleRecipientForm = () => {
    if (this.props.activeOption) {
      this.props.cleanSelectedAction();
    } else {
      this.setState({
        showRecipientForm: !this.state.showRecipientForm,
      });
    }
  };

  getProduct = () => {
    const { reorderItem } = this.props;
    if (!reorderItem) return null;
    return reorderItem.orderItemProduct.product;
  };

  getArt = () => {
    const { reorderItem } = this.props;
    if (!reorderItem) return null;

    return _get(reorderItem, "orderItemProduct.arts[0]");
  };

  getAdormentHeader = () => {
    const { reorderItem } = this.props;
    if (!reorderItem) return null;
    const adornmentTypeName = _get(
      reorderItem,
      "orderItemProduct.arts[0].adornmentTypeName",
      ""
    );
    if (adornmentTypeName.match(/emb/gi)) {
      return "Emb. Cost";
    }
    if (adornmentTypeName.match(/etch/gi)) {
      return "Etch. Cost";
    }
    return "Adorn. Cost";
  };

  handleUpdateOrderItem = async event => {
    event.preventDefault();
    const {
      updateOrderItem,
      reorderItem,
      openNotification,
      onItemUpdated,
    } = this.props;
    const { reorderItem: { orderQuantity }, errors } = this.state;

    if (errors.orderQuantity) return;
    if (!orderQuantity) {
      this.setState({
        reorderItem: {
          ...this.state.reorderItem,
          orderQuantity: 0,
        },
      });
    }

    try {
      await updateOrderItem(reorderItem.id, {
        orderQuantity: orderQuantity || 0,
      }, ()=>{
        openNotification("Order item updated successfully");
      });
      await onItemUpdated();
    } catch (error) {
      openNotification("Error Updating Order item");
    }
  };

  handleArchiveOrderItem = async () => {
    const {
      archiveOrderItem,
      reorderItem,
      openNotification,
      onItemArchived,
    } = this.props;

    try {
      await archiveOrderItem(reorderItem.id);
      openNotification("Order item was archived successfully");
      if (onItemArchived) onItemArchived();
    } catch (error) {
      openNotification("Error archiving Order item");
    }
  };

  handleChange = (event, value, formName) => {
    const { reorderItem, shipments } = this.props;
    const name = event ? event.target.name : formName;
    let errors = {};
    const shipment = shipments && shipments[0];

    if (value.length > 0 && !value.match(/^\d+$/gi)) return;
    if (
      shipment &&
      !shipment.recipientId &&
      Number(value) < Number(reorderItem.quantityToShip)
    ) {
      errors["orderQuantity"] = `must be greater or equal to ${reorderItem.quantityToShip
      }`;
    }

    this.setState({
      errors,
      reorderItem: {
        ...this.state.reorderItem,
        [name]: value,
      },
    });
  };

  handleCopyToDifferenteOrderItem = async (
    shipmentItem,
    orderItem,
    quantity
  ) => {
    const {
      createShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await createShipmentItem({
        ..._omit(_cloneDeep(shipmentItem), ["id"]),
        shippedQuantity: quantity,
        orderItemId: orderItem,
      });
      await onShipmentUpdated();
      openNotification("Recipient was copied successfully");
    } catch (error) {
      openNotification("Error copying recipient");
    }
  };

  handleMoveToDifferenteOrderItem = async (
    shipmentItem,
    orderItem,
    quantity
  ) => {
    const {
      updateShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await updateShipmentItem(shipmentItem.id, {
        orderItemId: orderItem,
        shippedQuantity: quantity,
      });
      await onShipmentUpdated();
      openNotification("Recipient was moved successfully");
    } catch (error) {
      openNotification("Error moving recipient");
    }
  };

  handleDeleteShipmentItem = async shipmentItem => {
    const {
      deleteShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await deleteShipmentItem(shipmentItem.id, ()=>{
        openNotification("Recipient was deleted successfully");
      });
      await onShipmentUpdated();
      
    } catch (error) {
      openNotification("Error deleting recipient");
    }
  };

  handleOpenEditOrderItemModal = () => {
    this.setState({ openEditOrderItemModal: true });
  };

  handleCloseEditOrderItemModal = () => {
    this.setState({ openEditOrderItemModal: false });
  };

  _performOrDash = (value, callback) => {
    if (!value || value <= 0) return "-";

    return callback(value);
  };

  renderItem = () => {
    if (!this.state.reorderItem) return null;

    const { reorderItem } = this.props;
    const art = this.getArt();
    const adormentHeader = this.getAdormentHeader();

    return (
      <div
        className="flex justify-content-around"
        style={{
          width: "100%",
          marginBottom: 26,
        }}
      >
        <div>
          <div style={ItemStyle.header}>Art used</div>
          <div
            style={{
              ...ItemStyle.dataLink,
            }}
          >
            <OrderItemArtDetails orderItem={reorderItem}>
              <span
                style={_get(art, "active") ? {} : { color: "red" }}
                title={getArtTitle(art)}
              >
                {_get(art, "name")}
              </span>
            </OrderItemArtDetails>
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Unit cost</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(
              reorderItem.unitCostValue,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Qty. Discount</div>
          <div style={ItemStyle.data}>
            ({this._performOrDash(
              reorderItem.quantityDiscountValue,
              getCurrencyFormattedNumber
            )})
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>{adormentHeader}</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(
              reorderItem.unitAdornmentCostValue,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Net unit cost</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(
              reorderItem.netUnitCost,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Total unit cost</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(
              reorderItem.orderQuantity
                ? reorderItem.netUnitCost * reorderItem.orderQuantity
                : 0,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Total to ship</div>
          <div style={ItemStyle.data}>{reorderItem.quantityToShip}</div>
        </div>
        <div>
          <div style={ItemStyle.header}>Total to inventory</div>
          <div style={ItemStyle.data}>{reorderItem.quantityToInventory}</div>
        </div>
      </div>
    );
  };

  createShipmentItem = async (recipientId, quantity, isProof) => {
    const {
      createShipmentItem,
      reorderItem,
      shipments,
      openNotification,
      onItemUpdated,
    } = this.props;
    const shipment = shipments[0];
    try {
      await createShipmentItem({
        recipientId,
        shippedQuantity: quantity,
        orderItemId: reorderItem.id,
        shipmentId: shipment.id,
        isProof,
      }, ()=>{
        openNotification("Recipient was created successfully");
      });
      onItemUpdated();
    } catch (error) {
      openNotification("Error creating Shipment Item");
    }
  };

  updateShipmentItem = async (shipmentItem, recipientId, quantity, isProof) => {
    const {
      updateShipmentItem,
      reorderItem,
      shipments,
      openNotification,
      onItemUpdated,
    } = this.props;
    const shipment = shipments[0];
    try {
      await updateShipmentItem(shipmentItem.id, {
        recipientId,
        shippedQuantity: quantity,
        orderItemId: reorderItem.id,
        shipmentId: shipment.id,
        isProof,
      });
      openNotification("Shipment Item was updated successfully");
      onItemUpdated();
    } catch (error) {
      openNotification("Error creating Shipment Item");
    }
  };

  handleSaveRecipient = (recipient, quantity, isProof) => {
    if (this.props.activeOption === "moveTo") {
      this.handleDeleteShipmentItem(this.props.shipmentItemSource);
    }
    this.createShipmentItem(recipient.id, quantity, isProof);
    this.props.cleanSelectedAction();
    this.setState({ showRecipientForm: false });
  };

  getFilteredRecipients = () => {
    const { companyContacts } = this.props;
    const shipmentItems = this._getShipmentItemsByOrderItem();
    const recipientIds = shipmentItems
      .filter(item => !item.isProof)
      .map(shipmentItem => shipmentItem.recipientId);
    return companyContacts.filter(
      contact => !recipientIds.includes(Number(contact.id))
    );
  };

  renderRecipient = requiredProof => {
    const {
      disableRecipient,
      companyId,
      reorderItem,
      activeOption,
      selectedRecipient,
      selectedOrderItem,
      isDisabled,
      clearError,
    } = this.props;
    if (disableRecipient) return;

    const filteredRecipients = this.getFilteredRecipients();

    const hasSelectedOption =
      activeOption && selectedOrderItem.id === reorderItem.id;
    const showRecipientForm = this.state.showRecipientForm || hasSelectedOption;

    return showRecipientForm ? (
      <RecipientForm
        onClose={this.handleToggleRecipientForm}
        containerStyle={{
          marginBottom: 32,
        }}
        hideIsProof={!requiredProof}
        companyId={companyId}
        companyContacts={filteredRecipients}
        showQuantityToShip={true}
        initialState={selectedRecipient}
        maxQuantityToShip={reorderItem.quantityToInventory}
        onSaveRecipient={this.handleSaveRecipient}
        clearError={clearError}
      />
    ) : (
      !isDisabled() &&
      <LineHeader
        title="Add recipient"
        icon="add"
        onClick={this.handleToggleRecipientForm}
      />
    );
  };

  _getShipmentItemsByOrderItem = () => {
    const { shipments, reorderItem } = this.props;
    if (!shipments || !reorderItem) return [];
    const shipment = shipments[0];
    if (!shipment) return [];

    return shipment.items.filter(item => {
      return item.orderItemId === Number(reorderItem.id);
    });
  };

  _topRightContent = (errors, reorderItem) => {
    const { isDisabled } = this.props;
    const removeOption =
      isDisabled() ? null : (
        <span
          style={RemoveStyle}
          key="remove"
          onClick={this.handleArchiveOrderItem}
        >
          Remove
          <span key="remove-icon" style={closeIconStyle}>
            X
          </span>
        </span>
      );
    const quantityForm = (
      <form key="quantity_field" onSubmit={this.handleUpdateOrderItem}>
        {" "}
        <TextField
          disabled={isDisabled()}
          floatingLabelText="Quantity *"
          type="text"
          name="orderQuantity"
          errorText={errors.orderQuantity}
          value={reorderItem && reorderItem.orderQuantity}
          onChange={this.handleChange}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          onBlur={this.handleUpdateOrderItem}
          style={{
            ...formStyles.quarterSelectInputStyles,
            marginRight: 32,
          }}
        />{" "}
      </form>
    );
    return [quantityForm, removeOption];
  };

  getSubTitleText = (fabric, color, leatherColor) => {
    const fabricName = _get(fabric, "name");
    const colorName = _get(color, "name");
    const leatherColorName = _get(leatherColor, "color.name", "");

    if (fabricName === "None") return null;
    let subtitle = `${fabricName} ${colorName}`;
    if (leatherColorName) subtitle += `, ${leatherColorName}`;
    return subtitle;
  };

  render() {
    const { reorderItem, errors, openEditOrderItemModal } = this.state;
    const {
      reorderItems,
      companyId,
      onSelectedOption,
      order,
      isInternal,
      isDisabled,
      onItemUpdated,
    } = this.props;
    const product = _get(reorderItem, "orderItemProduct.product", {});
    const color = _get(reorderItem, "orderItemProduct.fabricColor.color", {});
    const fabric = _get(reorderItem, "orderItemProduct.fabricColor.fabric", {});
    const leatherColor = _get(reorderItem, "orderItemProduct.leatherColor", {});
    const orderType = _get(reorderItem, "orderItemProduct.orderType");
    const title = (
      <div>
        <span style={BeforeCardTitle}>
          Item #{product && product.itemNumber}
        </span>
        <span>
          {product && product.productName}
          {!isInternal || orderType === "reorder" ? null : (
            <IconButton
              style={{ width: 24, height: 24, padding: 0, marginLeft: 8 }}
              onClick={this.handleOpenEditOrderItemModal}
            >
              <ImageEdit color="#0C5371" />
            </IconButton>
          )}
        </span>
        <div style={BeforeCardTitle}>
          {this.getSubTitleText(fabric, color, leatherColor)}
        </div>
      </div>
    );

    const requiredProof =
      _get(order, "preProductionRequested") === true ||
      _get(order, "sewOutRequested") === true;
    const shipmentItems = this._getShipmentItemsByOrderItem();
    const filteredRecipients = this.getFilteredRecipients();
    const hasShipments = shipmentItems && shipmentItems.length > 0;
    const hasItemToOrder =
      this.props.reorderItem &&
      this.props.reorderItem.orderQuantity > 0 &&
      this.props.reorderItem.quantityToInventory > 0;

    return (
      <CNGCard
        title={title}
        topRightContent={this._topRightContent(errors, reorderItem)}
        style={{
          paddingBottom: 1,
          paddingTop: 27,
        }}
      >
        <div>
          {this.renderItem()}
          {hasShipments ? (
            <RecipientTable
              isDisabled={isDisabled}
              shipmentItems={shipmentItems}
              shipments={this.props.shipments}
              companyId={companyId}
              companyContacts={filteredRecipients}
              reorderItem={reorderItem}
              reorderItems={reorderItems}
              onSelectedOption={onSelectedOption}
              updateShipmentItem={this.updateShipmentItem}
              onCopyToDifferenteOrderItem={this.handleCopyToDifferenteOrderItem}
              onMoveToDifferenteOrderItem={this.handleMoveToDifferenteOrderItem}
              onDeleteShipmentItem={this.handleDeleteShipmentItem}
            />
          ) : null}
          {hasItemToOrder ? this.renderRecipient(requiredProof) : null}
        </div>
        {openEditOrderItemModal ? (
          <EditOrderItemModal
            orderItem={reorderItem}
            onClose={this.handleCloseEditOrderItemModal}
            onSave={this.handleSaveOrderItem}
            onCreated={onItemUpdated}
          />
        ) : null}
      </CNGCard>
    );
  }
}

ReorderItemCard.propTypes = {
  order: PropTypes.object,
  reorderItem: PropTypes.object,
  reorderItems: PropTypes.array,
  shipments: PropTypes.array,
  updateOrderItem: PropTypes.func,
  disableRecipient: PropTypes.bool,
  onItemUpdated: PropTypes.func,
  onItemArchived: PropTypes.func,
  companyId: PropTypes.number,
  companyContacts: PropTypes.array,
  activeOption: PropTypes.string,
  selectedRecipient: PropTypes.object,
  selectedOrderItem: PropTypes.object,
  onSelectedOption: PropTypes.func,
  shipmentItemSource: PropTypes.object,
  cleanSelectedAction: PropTypes.func,
  isInternal: PropTypes.bool,
  isDisabled: PropTypes.func,
  errors: PropTypes.object,
  clearError: PropTypes.func,
};

export default ReorderItemCard;
