import React, {Component} from "react";
import PropTypes from "prop-types";

import OrderDetailHeader from "../Orders/components/OrderDetailHeader";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import RecipientDetailCard from "./components/RecipientDetailCard";
import ConfirmationModal from "../../shared/ConfirmationModal";
import NotesForm from "../../shared/NotesForm";
import lodash from "lodash";

export default class OrderDetailPage extends Component {
  static propTypes = {
    company: PropTypes.object,
    companyId: PropTypes.string,
    orderId: PropTypes.string,
    order: PropTypes.object,
    orderStatus: PropTypes.array,
    orderItems: PropTypes.array,
    shipments: PropTypes.array,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    states: PropTypes.array,
    countries: PropTypes.array,
    onUpdateOrderStatus: PropTypes.func,
    fetchOrder: PropTypes.func,
    revertOrder: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      openConfirmedOrder: this.isOpenConfirmModal(),
    };
  }

  handlePrintOpen = () => {
    const {order} = this.props;
    window.open(
      `/companies/${order.companyId}/orders/${
        order.id
      }/print?billingReport=true`,
      "_blank"
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({
        openConfirmedOrder: this.isOpenConfirmModal(),
      });
    }
  }

  isOpenConfirmModal = () => {
    const {history: {location: {state}}} = this.props;
    return lodash.get(state, "dsodCreated", false);
  };

  _getShipment() {
    const {shipments} = this.props;
    if (!shipments) return;
    return shipments[0];
  }

  getConfirmationMessage = () => {
    return (
      <div style={{textalign: "center"}}>
        <p>
          A confirmation email has been sent. If you have any questions or
          concerns, please contact us.
        </p>
      </div>
    );
  };

  getRecipientRows = (shipment, orderItems) => {
    if (!shipment) return [];

    return lodash(shipment.items)
      .groupBy("recipientId")
      .map(recipients => {
        const recipient = recipients[0] || {};
        return {
          shipmentItem: recipient,
          recipient: recipient.recipient,
          shippedQuantity: lodash.sumBy(recipients, "shippedQuantity"),
          orderItems: orderItems.filter(orderItem =>
            lodash.map(recipients, "orderItemId").includes(Number(orderItem.id))
          ),
          createdAt:
            recipient.createdAt && new Date(recipient.createdAt).getTime(),
          recipientName: recipient.name,
        };
      })
      .sortBy(["createdAt", "recipientName"])
      .value();
  };

  confirmationModal = () => {
    const {isInternal, history} = this.props;
    return (
      <ConfirmationModal
        title={
          isInternal
            ? "The DSOD Request Has Been Created"
            : "Thank you!  You’re DSOD Command Has Been Created."
        }
        content={
          isInternal
            ? "A Confirmation email has been sent."
            : this.getConfirmationMessage()
        }
        confirmText="GO TO DASHBOARD"
        cancelText="VIEW ORDER"
        open={this.state && this.state.openConfirmedOrder}
        onConfirm={() => {
          const {history} = this.props;
          history.push({state: {}, pathname: "/"});
        }}
        onDismiss={() => {
          history.push({state: {}});
        }}
      />
    );
  };

  render() {
    const {
      company,
      isLoading,
      order,
      orderItems,
      orderStatus,
      onUpdateOrderStatus,
      history,
      isInternal,
      shipments,
      companyId,
      orderId,
      revertOrder,
      fetchOrder,
      countries,
    } = this.props;

    const shipment = this._getShipment();
    const recipient_rows = this.getRecipientRows(shipment, orderItems);

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company && company.name}
        smallTop={true}
      >
        <BreadCrumbs
          rootName="Companies"
          middle={company && company.name}
          middleLink={company && company.id}
          pageName={"DSOD Detail"}
        />
        <div className="margin-bottom-24">
          <div className="only-print-version billing-report-title">
            Billing Report
          </div>
          <OrderDetailHeader
            revertOrder={revertOrder}
            company={company}
            isDSOD={true}
            onUpdateOrderStatus={onUpdateOrderStatus}
            companyId={companyId}
            orderId={orderId}
            order={order}
            isInternal={isInternal}
            orderStatus={orderStatus}
            shipments={shipments}
            history={history}
            onPrintOpen={this.handlePrintOpen}
          />
        </div>
        {recipient_rows.map((row, key) => (
          <RecipientDetailCard
            key={key}
            {...row}
            companyId={companyId}
            orderId={Number(orderId)}
            fetchOrder={fetchOrder}
            countries={countries}
            shipment={shipment}
          />
        ))}

        <NotesForm order={order} isInternal={isInternal} />
        {this.confirmationModal()}
      </PageLayout>
    );
  }
}
