import React from "react";
import PropTypes from "prop-types";

import withStyles from "../WithStyles";
import Typography from "../Typography";

const CompactTableRowColumn = ({
  style,
  styles: {colors},
  children,
  tdProps,
}) => {
  if (typeof children !== "string") {
    return (
      <td
        style={{
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: colors.gray.border,
          ...style,
        }}
        {...tdProps}
      >
        {children}
      </td>
    );
  }
  return (
    <Typography
      component="td"
      variant="subtitle"
      color="gray.dark"
      className="uppercase"
      style={{
        padding: 8,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: colors.gray.border,
        textAlign: "center",
        ...style,
      }}
      {...tdProps}
    >
      {children}
    </Typography>
  );
};

CompactTableRowColumn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  style: PropTypes.object,
  styles: PropTypes.object,
  tdProps: PropTypes.object,
};

export default withStyles(CompactTableRowColumn);
