import React, {Component} from "react";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";
import {Card, CardHeader, CardText} from "material-ui/Card";
import lodash from "lodash/object";

import Divider from "../../../shared/Divider";
import OrderShipmentTable from "./OrderShipmentTable";
import DSODTable from "./DSODTable";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";

import {tableRowStyle} from "../../../../styles/components/tableStyles";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

const cardHeader = {
  ...tableRowStyle,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 48,
  paddingLeft: 10,
};

const cardColumns = {
  paddingRight: 24,
  color: "#747D86",
  fontSize: 14,
  lineHeight: "20px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const smallColumn = {
  ...cardColumns,
  width: 119,
};

const orderTotalStyles = {
  color: "#1D9ABB",
  fontWeight: "bold",
};

export default class MiscellaneousOrderItem extends Component {
  static propTypes = {
    companyId: PropTypes.string,
    items: PropTypes.array.isRequired,
    isReordering: PropTypes.bool,
    isInternal: PropTypes.bool,
    reorderItems: PropTypes.array,
    shipments: PropTypes.array,
    onToggleReorderItem: PropTypes.func,
    onUpdateShipmentItem: PropTypes.func,
    updateShipment: PropTypes.func,
    states: PropTypes.array,
    countries: PropTypes.array,
    inventoryItems: PropTypes.array,
    isPrintActive: PropTypes.any,
    orderItem: PropTypes.object,
    index: PropTypes.number,
  };

  getSubTitleText = fabricColor => {
    const fabricName = lodash.get(fabricColor, "fabric.name");
    const colorName = lodash.get(fabricColor, "color.name");

    if (fabricName === "None") return null;
    return `${fabricName} ${colorName}`;
  };

  render() {
    const {
      isReordering,
      reorderItems,
      onToggleReorderItem,
      shipments,
      inventoryItems,
      orderItem,
      index,
    } = this.props;

    const shipment = shipments && shipments[0];

    const {
      unitCostValue,
      unitAdornmentCostValue,
      orderTotal,
      netUnitCost,
      quantityDiscountValue,
      id,
      notes,
      isSewOut,
    } = orderItem;

    const inventoryItem = (inventoryItems || []).find(
      inventoryItem =>
        Number(inventoryItem.orderItemId) === Number(orderItem.id)
    );

    const items =
      (shipment && shipment.items.filter(item => item.orderItemId == id)) || [];

    const renderRow = (
      <div
        style={{
          ...cardHeader,
          paddingLeft: 0,
          height: 80,
        }}
        key={index}
        className="order-item-row"
      >
        <div
          style={{
            ...cardColumns,
            width: 160,
            display: "flex",
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
            paddingRight: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {isReordering ? (
              <Checkbox
                style={{
                  width: "unset",
                }}
                checked={!!reorderItems.find(item => item.id === orderItem.id)}
                onCheck={() => {
                  onToggleReorderItem(orderItem);
                }}
              />
            ) : null}
          </div>

          {isSewOut ? (
            <div className="overflow-ellipsis">
              {notes}
              <div className="table-subtext">Sew-Out</div>
            </div>
          ) : (
            <div className="overflow-ellipsis">
              {notes}
              <div className="table-subtext">Miscellaneous</div>
            </div>
          )}
        </div>
        <div
          style={{
            ...cardColumns,
            width: 110,
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
          }}
        >
          <OrderItemArtDetails orderItem={orderItem} />
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 85,
          }}
        >
          {orderItem.quantityToShip}
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 110,
          }}
        >
          {orderItem.quantityToInventory}
        </div>
        <div
          style={{
            ...smallColumn,
            width: 75,
          }}
        >
          {orderItem.orderQuantity}{" "}
        </div>
        <div
          style={{
            ...smallColumn,
            width: 105,
          }}
        >
          {getCurrencyFormattedNumber(unitCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            width: 90,
          }}
        >
          ({getCurrencyFormattedNumber(quantityDiscountValue)})
        </div>
        <div
          style={{
            ...smallColumn,
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(unitAdornmentCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(netUnitCost, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            ...orderTotalStyles,
            width: 130,
            color: "#747D86",
          }}
        >
          {getCurrencyFormattedNumber(orderTotal, true)}
        </div>
      </div>
    );

    return this._renderCard(renderRow, index, shipment, items, inventoryItem);
  }

  _renderCard = (renderRow, index, shipment, items, inventoryItem) => {
    const {
      isInternal,
      companyId,
      onUpdateShipmentItem,
      updateShipment,
      states,
      countries,
    } = this.props;
    const isMultipleShipmentsOrder = lodash.get(shipment, "isMultiple");
    const hasShipments =
      isMultipleShipmentsOrder && items.length > 0
        ? true
        : shipment && !!shipment.recipientId;
    const inventoryHistories =
      lodash.get(inventoryItem, "inventoryHistories") || [];

    const hasInventoryHistories =
      inventoryHistories.filter(
        inventoryHistory => inventoryHistory.sourceType === "dsod"
      ).length > 0;
    const showExpandable = hasShipments || hasInventoryHistories;
    return (
      <Card
        style={{
          marginBottom: 8,
          boxShadow: "none",
        }}
        containerStyle={{
          margin: "0px 32px",
        }}
        className="cardtest"
        key={index}
      >
        <CardHeader
          showExpandableButton={showExpandable}
          title={renderRow}
          textStyle={{
            paddingRight: 0,
          }}
          style={{
            padding: "0 0",
          }}
          openIcon={<img src={"/assets/imgs/icons/ExpandMore.svg"} />}
          closeIcon={<img src={"/assets/imgs/icons/ExpandLess.svg"} />}
          className={"test order-item-card"}
        />
        <CardText
          expandable={true}
          style={{
            padding: 0,
            margin: "0 0px",
          }}
        >
          <Divider className={"ordershipment_card_divider"} />
          {hasShipments ? (
            <div
              style={{
                margin: "0 0",
                fontWeight: "normal",
              }}
            >
              <h1
                style={{
                  fontSize: 16,
                  color: "#4A5158",
                  fontWeight: "normal",
                }}
              >
                {isMultipleShipmentsOrder ? "Shipments" : "Shipment"}
              </h1>
            </div>
          ) : null}
          {hasShipments ? (
            <OrderShipmentTable
              shipmentItems={items}
              shipment={shipment}
              isInternal={isInternal}
              onUpdateShipmentItem={onUpdateShipmentItem}
              updateShipment={updateShipment}
            />
          ) : null}
          {hasInventoryHistories ? (
            <div
              style={{
                margin: "0 0",
                fontWeight: "normal",
              }}
            >
              <h1
                style={{
                  fontSize: 16,
                  color: "#4A5158",
                  fontWeight: "normal",
                }}
              >
                DSOD
              </h1>
            </div>
          ) : null}

          {hasInventoryHistories ? (
            <DSODTable
              inventoryItem={inventoryItem}
              states={states}
              countries={countries}
              companyId={companyId}
            />
          ) : null}
        </CardText>
      </Card>
    );
  };
}
