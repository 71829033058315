import React, {Component} from "react";
import PropTypes from "prop-types";
import SelectField from "material-ui/SelectField";
import CNGMenuItem from "../shared/CNGMenuItem";
import AuthFooter from "../shared/Footer/AuthFooter";
import {
  TextInputStyles,
  SelectFieldIconStyle,
  formStyles,
} from "../../styles/components/formStyles";
const styles = {
  customWidth: {
    width: 358,
  },
};

export default class CompanySelectPage extends Component {
  state = {
    selectedCompany: null,
  };

  componentDidMount() {
    window.closeSplash();
  }

  componentWillMount() {
    if (this.props.isInternal) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.companies !== prevProps.companies) {
      if (this.props.companies.length == 1) {
        this.props.setSelectedCompany(this.props.companies[0]);
      }
    }
  }

  handleChange(event, index, value) {
    this.setState({selectedCompany: value});
  }

  menuItems(companies) {
    return (
      companies.length > 0 &&
      companies.map(company => (
        <CNGMenuItem
          key={company.id}
          value={company.id}
          primaryText={company.name}
        />
      ))
    );
  }

  render() {
    const {companies} = this.props;
    const {selectedCompany} = this.state;
    return (
      <div className="bg-white auth-content">
        <div className="app-body auth-page-body bg-white">
          <div className="auth-page_top">
            <img
              className="auth-page_top_img"
              src="./assets/imgs/Icon@2x.png"
            />
          </div>

          <div className="auth-page_mid">
            <img
              className="auth-page_chillLogo auth-page_chillLogo--forgot"
              src="./assets/imgs/ChillNGo.svg"
            />

            <div className="auth-page_info">
              <h3 className="auth-page_info_heading">Select Company</h3>
            </div>

            <div className="auth-page_input auth-page_input--forgot">
              <SelectField
                floatingLabelText="Company"
                inputStyle={formStyles.inputStyle}
                name="selectedCompany"
                value={this.state.selectedCompany}
                style={styles.customWidth}
                floatingLabelStyle={TextInputStyles}
                iconStyle={SelectFieldIconStyle}
                onChange={(event, index, value) =>
                  this.handleChange(event, index, value)
                }
                underlineStyle={formStyles.underlineStyle}
              >
                {this.menuItems(companies)}
              </SelectField>
            </div>
            <div className="auth-page-btn-container flex flex-column align-items-center">
              <button
                disabled={!selectedCompany}
                className="btn-1 btn-icon auth-page_btn"
                onClick={() => {
                  const {companies} = this.props;
                  const company = companies.find(
                    ({id}) => Number(id) === Number(selectedCompany)
                  );
                  this.props.setSelectedCompany(company);
                }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>

        <AuthFooter styles={"footer--auth-company"} />
      </div>
    );
  }
}

CompanySelectPage.propTypes = {
  setSelectedCompany: PropTypes.func,
  companies: PropTypes.array,
  history: PropTypes.object,
  isInternal: PropTypes.bool,
};
