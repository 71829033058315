import React, {Component} from "react";
import PropTypes from "prop-types";

import OrderEditHeader from "../Orders/components/OrderEditHeader";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import NotesForm from "../../shared/NotesForm";
import RecipientDetailCard from "./components/RecipientDetailCard";

import lodash from "lodash";

class DSODEditPage extends Component {
  _getShipment() {
    const {shipments} = this.props;
    if (!shipments) return;
    return shipments[0];
  }

  _recipientRows() {
    const shipment = this._getShipment();
    const {orderItems} = this.props;
    return (
      (shipment &&
        lodash(shipment.items)
          .groupBy(item => item.recipient && item.recipient.id)
          .map(items => {
            const shipmentItem = items[0];
            return {
              recipient: shipmentItem.recipient,
              shippedQuantity: lodash.sumBy(items, "shippedQuantity"),
              orderItems: orderItems.filter(({id}) =>
                lodash.map(items, "orderItemId").includes(Number(id))
              ),
              createdAt: new Date(
                lodash.get(shipmentItem, "createdAt") || 0
              ).getTime(),
              recipientName: lodash.get(shipmentItem, "recipient.name"),
            };
          })
          .sortBy(["createdAt", "recipientName"])
          .value()) ||
      []
    );
  }

  render() {
    const {
      company,
      order,
      isInternal,
      onOrderChange,
      onShipmentChange,
      orderStatus,
      shipments,
      companyShippingCarrierAssignments,
      shippingCarriers,
      shippingMethods,
      orderData,
      shipmentData,
      onUpdateOrder,
      isLoading,
      paymentTerms,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company && company.name}
      >
        <BreadCrumbs
          rootName="Companies"
          middle={company && company.name}
          middleLink={company && company.id}
          pageName={"Order edit"}
        />
        <div className="margin-bottom-24">
          <OrderEditHeader
            isDSOD={true}
            onOrderChange={onOrderChange}
            onShipmentChange={onShipmentChange}
            onUpdateOrder={onUpdateOrder}
            company={company}
            order={order}
            isInternal={isInternal}
            orderStatus={orderStatus}
            shipments={shipments}
            orderData={orderData}
            shipmentData={shipmentData}
            shippingCarriers={shippingCarriers}
            shippingMethods={shippingMethods}
            companyShippingCarrierAssignments={
              companyShippingCarrierAssignments
            }
            paymentTerms={paymentTerms}
          />
        </div>
        {this._recipientRows().map((row, key) => (
          <RecipientDetailCard
            key={key}
            {...row}
            orderId={Number(row.orderId)}
          />
        ))}

        <NotesForm
          order={orderData}
          onChange={onOrderChange}
          isInternal={isInternal}
        />
      </PageLayout>
    );
  }
}

DSODEditPage.propTypes = {
  onOrderChange: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  company: PropTypes.object,
  order: PropTypes.object,
  orderData: PropTypes.object,
  orderStatus: PropTypes.array,
  shipmentData: PropTypes.object,
  orderItems: PropTypes.array,
  shipments: PropTypes.array,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingCarriers: PropTypes.array,
  shippingMethods: PropTypes.array,
  paymentTerms: PropTypes.array,
};

export default DSODEditPage;
