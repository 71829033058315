import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import _get from "lodash/get";
import FilePreview from "react-preview-file";
import FontIcon from "material-ui/FontIcon";
import {FileIcon} from "@drawbotics/file-icons";

import CNGMenuItem from "../../../../shared/CNGMenuItem";
import ValidableSelect from "../../../../shared/ValidableSelect";
import ValidableTextField from "../../../../shared/ValidableTextField";
import ChillButton from "../../../../shared/ChillButton";
import Typography from "../../../shared/Typography";
import {
  VALID_IMAGE_FORMATS,
  PREVIEWABLE_IMAGE_FORMATS,
  NO_CHILL_DIGITACION_PRICE_TITLE,
} from "../../../../../constants/art";
import {formStyles} from "../../../../../styles/components/formStyles";
import {validateForm} from "../../../../../utils/formValidations";
import {getDigitizationOptionsValues} from "../../../../../utils/digitizationOptionsValues";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import AdornmentTypeForm from "../../../../pages/Arts/components/AdornmentTypeForm";

class QuoteDigitizedArtModal extends Component {
  state = {
    name: "",
    digitizedFile: null,
    adornmentTypeId: 1,
    internalIdentifier: "embroidery",
    details: {},
    errors: {},
    submitted: false,
    adornmentTypeFormValid: true,
  };

  validations = {
    name: {
      format: "any",
      length: [2, 30],
    },
    adornmentTypeId: {
      format: "integer",
    },
    filename: {
      format: "any",
      nullable: false,
    },
    digitizedFile: {
      format: "any",
      nullable: false,
    },
  };

  inputField = null;

  componentDidMount() {
    const {art} = this.props;

    this.setState({
      name: "",
      digitizedFile: null,
      ...art,
      details: {
        ...art.details,
        stitch_count: _get(art, "details.stitchCount"),
        applicability_id: _get(art, "details.applicabilityId"),
        thread_colors: _get(art, "details.threadColors"),
      },
    });
  }

  adormentTypeOptions() {
    const {adornmentTypes} = this.props;
    return (
      adornmentTypes &&
      adornmentTypes.map(adornment => {
        return (
          <CNGMenuItem
            key={adornment.id}
            value={adornment.id}
            primaryText={adornment.name}
          />
        );
      })
    );
  }

  handleChange = (error, index, value, formName) => {
    if (!value) {
      value = index;
    }
    let name;
    if (!formName) {
      name = error.target.name;
    } else {
      name = formName;
    }

    if (name === "adornmentTypeId") {
      const {adornmentTypes} = this.props;
      const {internalIdentifier} = adornmentTypes.find(
        type => Number(type.id) === value
      );
      this.setState({details: {}, internalIdentifier});
    }

    this.setState({[name]: value});
  };

  handleDetailChange = (error, index, value, formName) => {
    if (!value) {
      value = index;
    }
    const name = formName ? formName : error.target.name;

    this.setState({
      details: {
        ...this.state.details,
        [name]: value,
      },
    });
  };

  handleAdornmentTypeFormValidChange = valid => {
    this.setState({adornmentTypeFormValid: valid});
  };

  handleOpenFileBrowserHandler = ev => {
    this.inputField.click(ev);
  };

  removeSelectedFile = event => {
    event.preventDefault();
    this.setState({digitizedFile: null, digitizedFilename: ""});
  };

  handleFileChange = inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!file) return;
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Uploaded file is not a valid input image. Only png, jpeg, and pdf."
      );
    } else {
      this.setState({digitizedFile: file, digitizedFilename: file.name});
    }
  };

  handleSave = async () => {
    const {onEditArt} = this.props;
    await this.setState({submitted: true});
    const {
      adornmentTypeFormValid,
      submitted, // eslint-disable-line no-unused-vars
      errors: stateErrors, // eslint-disable-line no-unused-vars
      ...state
    } = this.state;
    const errors = this.validate();
    const valid = Object.keys(errors).length === 0;
    if (!valid || !adornmentTypeFormValid || !state.digitizedFile) {
      return this.setState({submitted: false});
    }
    onEditArt({...state});
  };

  validateDebounced = _debounce(() => this.validate(), 300);

  validate = () => {
    const errors = validateForm(this);
    this.setState({errors});
    return errors;
  };

  getDigitizationOptions = digitizationTypePrices => {
    return digitizationTypePrices.map(digitizationTypePrice => {
      const level = digitizationTypePrice.complexityLevel
        ? digitizationTypePrice.complexityLevel
        : "";
      const price = digitizationTypePrice.price
        ? getCurrencyFormattedNumber(digitizationTypePrice.price)
        : "";
      const text = `${digitizationTypePrice.adornmentType} ${level} ${price}`;
      return (
        <CNGMenuItem
          key={digitizationTypePrice.key}
          value={digitizationTypePrice.key}
          primaryText={text}
        />
      );
    });
  };

  renderFilePreview = (url, name) => {
    return (
      <div
        className="flex justify-content-between align-items-center z-index-3"
        style={this.pageStyle.fileField}
      >
        <div className="flex align-items-center" style={{padding: 8}}>
          {PREVIEWABLE_IMAGE_FORMATS.some(format =>
            name.includes(format.split("/")[1])
          ) ? (
              <img src={url} style={this.pageStyle.image} />
            ) : (
              <FileIcon filename={name} style={this.pageStyle.image} />
            )}
          <Typography
            variant="bodyBold"
            color="secondary.light"
            className="version--2 secondary-link-style"
            style={this.pageStyle.fileName}
          >
            {name}
          </Typography>
        </div>
        <FontIcon
          className="pointer material-icons"
          onClick={this.removeSelectedFile}
          style={this.pageStyle.deleteIcon}
        >
          delete
        </FontIcon>
      </div>
    );
  };

  renderDigitizedFilePreview = () => {
    const {
      digitizedFile,
      digitizedFilename,
      digitizedImageUrl,
      errors,
    } = this.state;

    if (digitizedFilename) {
      return this.renderFilePreview(digitizedImageUrl, digitizedFilename);
    }

    if (digitizedFile && digitizedFile.name) {
      return (
        <FilePreview file={digitizedFile}>
          {preview => this.renderFilePreview(preview, digitizedFile.name)}
        </FilePreview>
      );
    }

    return (
      <span style={{margin: "18px 0"}}>
        <span
          className="small-header new-order--add-link flex flex-center pointer padding-left-24 z-index-3"
          style={{
            padding: 8,
            ...this.pageStyle.fileField,
          }}
          onClick={this.handleOpenFileBrowserHandler}
        >
          <FontIcon className="material-icons" style={this.pageStyle.addIcon}>
            add_circle_outline
          </FontIcon>
          <span className="uppercase">Upload Digitized Art</span>
        </span>
        {errors.digitizedFile && (
          <Typography variant="subtitle" color="red">
            {errors.digitizedFile}
          </Typography>
        )}
      </span>
    );
  };

  get pageStyle() {
    return {
      dialog: {maxWidth: 760},
      close: {position: "absolute", right: 10, top: 10},
      closeIcon: {
        color: "#1D9ABB",
        fontSize: 16,
        borderRadius: 9,
        borderWidth: 1,
        borderStyle: "solid",
      },
      title: {margin: "0 auto 24px"},
      fileInput: {display: "none"},
      fileField: {
        width: 260,
        color: "#1D9ABB",
        backgroundColor: "#F4F8F9",
      },
      image: {width: 32, height: 32, objectFit: "cover"},
      fileName: {
        padding: "0 8px",
        whiteSpace: "nowrap",
        maxWidth: 175,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      deleteIcon: {
        fontSize: 26,
        marginRight: 8,
        color: "#0C5371",
      },
      addIcon: {
        color: "#1D9ABB",
        fontSize: 26,
        marginRight: 8,
      },
      saveButton: {
        color: "#FFFFFF",
        fontSize: 18,
        marginRight: 4,
      },
    };
  }

  render() {
    const {art, adornmentTypes, onClose} = this.props;
    const {
      name,
      digitization,
      adornmentTypeId,
      details,
      errors,
      submitted,
    } = this.state;
    const digitizationTypePrices = getDigitizationOptionsValues(
      _get(this.props, "digitalizationTypePrices", [])
    );
    return (
      <Fragment>
        <Typography variant="h3" style={this.pageStyle.title}>
          Art Set Up
        </Typography>
        <div className="flex">
          <ValidableTextField
            autoFocus
            label="Art Name"
            type="text"
            name="name"
            value={name || ""}
            style={formStyles.tqTextInputStyles}
            inputStyle={formStyles.tqTextInputStyles}
            handleChange={this.handleChange}
            errorText={errors.name}
            validate={this.validateDebounced}
          />
          {_get(art, "digitization") ? null : (
            <ValidableSelect
              label="Digitization or Etch Set-Up Cost"
              menuStyle={{borderRadius: "0px !important"}}
              style={{width: 240}}
              inputStyle={{
                width: 240,
                color: "#747D86",
              }}
              name="digitization"
              value={digitization ? digitization.key : ""}
              handleChange={(event, index, value) => {
                const digitization = digitizationTypePrices.find(
                  type => type.key === value
                );
                this.setState({digitization});
              }}
              selectionRenderer={value => {
                const digitizationTypePrice = digitizationTypePrices.find(
                  ({key}) => key === value
                );
                const price = digitizationTypePrice.price
                  ? getCurrencyFormattedNumber(digitizationTypePrice.price)
                  : NO_CHILL_DIGITACION_PRICE_TITLE;
                return price;
              }}
              options={this.getDigitizationOptions(digitizationTypePrices)}
            />
          )}
        </div>
        <input
          type="file"
          onChange={this.handleFileChange}
          className="hidden-safe"
          style={this.pageStyle.fileInput}
          name="artFile"
          tabIndex={-1}
          ref={input => (this.inputField = input)}
        />
        {this.renderDigitizedFilePreview()}
        <ValidableSelect
          name="adornmentTypeId"
          label="Adornment Details"
          errorText={errors.adornmentTypeId}
          value={`${adornmentTypeId}` || ""}
          style={formStyles.tqTextInputStyles}
          inputStyle={formStyles.tqTextInputStyles}
          menuStyle={formStyles.tqSelectMenuInputStyles}
          listStyle={formStyles.tqSelectMenuListStyles}
          handleChange={(e, index, value) => {
            this.handleAdornmentTypeFormValidChange(false);
            this.validate();
            this.handleChange(e, index, Number(value), "adornmentTypeId");
          }}
          options={this.adormentTypeOptions()}
        />
        <AdornmentTypeForm
          details={details}
          adornmentTypeId={adornmentTypeId}
          adornmentTypes={adornmentTypes}
          onDetailChange={this.handleDetailChange}
          onValidChange={this.handleAdornmentTypeFormValidChange}
          submitted={submitted}
        />
        <ChillButton
          onClick={this.handleSave}
          name="Save"
          useFontIcon={true}
          icon="check"
          fontIconStyles={this.pageStyle.saveButton}
          className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
          height={40}
          marginTop={40}
          marginBottom={24}
        />
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer uppercase margin-bottom-16"
          color="primary.light"
          onClick={onClose}
        >
          Cancel
        </Typography>
      </Fragment>
    );
  }
}

QuoteDigitizedArtModal.propTypes = {
  art: PropTypes.object,
  adornmentTypes: PropTypes.array,
  pageStyle: PropTypes.object,
  onEditArt: PropTypes.func,
  onClose: PropTypes.func,
  openNotification: PropTypes.func,
  digitalizationTypePrices: PropTypes.array,
};

export default QuoteDigitizedArtModal;
