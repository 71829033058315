/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import Routes from "./Routes";

export default class App extends React.Component {
  static propTypes = {
    children: PropTypes.element,
  };

  render() {
    return <Routes />;
  }
}
