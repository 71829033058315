import React, {Component} from "react";
import PropTypes from "prop-types";
import {Logo} from "../Logo";
import HeaderUserMenu from "./HeaderUserMenu";
import HeaderNav from "./HeaderNav";

class Header extends Component {
  state = {
    completed: 0,
  };

  render() {
    const {
      isInternal,
      user,
      logout,
      companies,
      history,
      selectedCompany,
      companiesWithDsod,
      isCreateDSODButtonVisible,
      isCreateOrderButtonVisible,
      showBadge,
      badgeNumber,
      chatBadgeNumber,
      style,
    } = this.props;

    return (
      <header className="header non-printable" style={style}>
        {process.env.SHOW_STAGING_FLAG && (
          <React.Fragment>
            <div className="staging-flag">Staging</div>
            <div className="staging-flag left">Staging</div>
          </React.Fragment>
        )}
        <div className="site-container header_inner">
          <div className="header_left">
            <Logo className="header_left_img" />
          </div>
          <HeaderNav
            user={user}
            isInternal={isInternal}
            companies={companies}
            history={history}
            selectedCompany={selectedCompany}
            companiesWithDsod={companiesWithDsod}
            isCreateDSODButtonVisible={isCreateDSODButtonVisible}
            isCreateOrderButtonVisible={isCreateOrderButtonVisible}
            showBadge={showBadge}
            badgeNumber={badgeNumber}
          />
          <HeaderUserMenu
            user={user}
            logout={logout}
            isInternal={isInternal}
            history={history}
            chatBadgeNumber={chatBadgeNumber}
          />
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  user: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  isInternal: PropTypes.bool,
  companies: PropTypes.array,
  companiesWithDsod: PropTypes.array,
  history: PropTypes.object,
  selectedCompany: PropTypes.string,
  isCreateDSODButtonVisible: PropTypes.bool,
  isCreateOrderButtonVisible: PropTypes.bool,
  showBadge: PropTypes.bool,
  badgeNumber: PropTypes.number,
  chatBadgeNumber: PropTypes.number,
  style: PropTypes.object,
};

export default Header;
