import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Note extends Component {
  static propTypes = {
    user: PropTypes.object,
    note: PropTypes.object,
    deleteNote: PropTypes.func,
    disabled: PropTypes.bool,
  };
  state = {
    isActive: false,
  };

  componentWillReceiveProps() {
    this._evaluateActiveState(...arguments);
  }

  _evaluateActiveState(nextProps) {
    const {disabled} = nextProps;
    const {isActive} = this.state;
    if (disabled && isActive) {
      this.setState({isActive: false});
    }
  }

  toggleActive() {
    const {disabled} = this.props;
    if (disabled) {
      return;
    }
    this.setState({
      isActive: !this.state.isActive,
    });
  }

  _renderDelete() {
    const {note, disabled} = this.props;
    return (
      <div
        className={`note_delete ${
          disabled ? "disabled-button disabled-link" : ""
        }`}
        onClick={() => this.props.deleteNote(note.id)}
      >
        <span className="note_delete_text">DELETE</span>
        <img className="btn-icon-size" src="/assets/imgs/icons/trash2.svg" />
      </div>
    );
  }

  _renderAuthor() {
    const {note} = this.props;
    return (
      <div className="text-uppercase note-title note-author">
        {note.createdByUser && `by ${note.createdByUser.name}`}
      </div>
    );
  }

  _getFormatedDate(dateString) {
    const date = new Date(dateString);
    if (date.toDateString() === new Date().toDateString()) return "today";

    const month = date.toLocaleString("en-us", {month: "long"});
    const day = date.getDate();
    const year = date.getFullYear();

    return `on ${month} ${day}, ${year}`;
  }

  render() {
    const {note, disabled} = this.props;
    return (
      <div
        className={
          this.state.isActive
            ? "note note--active"
            : `note ${disabled ? "not-allowed" : ""}`
        }
        onClick={() => this.toggleActive()}
      >
        <div className="note-text text-uppercase note-title">
          {`note ${this._getFormatedDate(note.createdAt)}`}
        </div>
        <div className="note-text note-text-scroll">{note.content}</div>
        {this.state.isActive ? this._renderDelete() : this._renderAuthor()}
      </div>
    );
  }
}
