import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Dialog from "material-ui/Dialog";

import {createBrand} from "../../../../actions/brandActions";
import CreateBrand from "./CreateBrand";

const CreateBrandModal = ({open, ...props}) => (
  <Dialog
    modal={false}
    open={open}
    contentStyle={{width: 545}}
    bodyStyle={{padding: "40px 92px 24px 93px"}}
    bodyClassName="flex flex-column flex-center"
  >
    <CreateBrand {...props} />
  </Dialog>
);

CreateBrandModal.propTypes = {
  open: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    createBrand: bindActionCreators(createBrand, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CreateBrandModal);
