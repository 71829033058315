import React from "react";
import PropTypes from "prop-types";

import withStyles from "../WithStyles";

const CompactTableRow = ({style, children}) => (
  <tr style={style}>{children}</tr>
);

CompactTableRow.propTypes = {
  children: PropTypes.array,
  style: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(CompactTableRow);
