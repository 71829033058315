import React, {Component} from "react";
import PropTypes from "prop-types";
import MultipleArtModal from "./MultipleArtModal";
import _get from "lodash/get";
import CNGToolTip from "./CNGToolTip";

const styles = {
  seePreview: {
    marginLeft: 4,
  },
};

class OrderItemArtDetails extends Component {
  static propTypes = {
    orderItem: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  state = {
    openModal: false,
  };

  _hasMultipleLocations(orderItem) {
    if (!orderItem) return false;
    const locations = _get(
      orderItem,
      "orderItemProduct.orderItemProductAdornmentLocationArtAssignments",
      []
    );
    return Array.isArray(locations) ? locations.length > 1 : false;
  }

  render() {
    const {orderItem, children} = this.props;
    const {openModal} = this.state;
    const imageTooltip = {
      width: 250,
      border: "1px solid #BDC6CF",
    };
    const orderItemArts = _get(orderItem, "orderItemProduct.arts");
    const orderItemArt =
      (orderItemArts && orderItemArts[0]) ||
      _get(orderItem, "orderItemProduct.art");
    const allArtsActive = (
      _get(
        orderItem,
        "orderItemProduct.orderItemProductAdornmentLocationArtAssignments"
      ) || []
    ).every(assignment => _get(assignment, "art.active"));
    return (
      <React.Fragment>
        {this.state.openModal ? (
          <MultipleArtModal
            open={openModal}
            activeOrderItem={orderItem}
            onClose={() => this.setState({openModal: false})}
          />
        ) : null}
        {this._hasMultipleLocations(orderItem) ? (
          <div
            onClick={() =>
              this.setState({openModal: true, activeOrderItem: orderItem})
            }
            style={allArtsActive ? {} : {color: "red"}}
          >
            Multiple
            <img
              style={styles.seePreview}
              src="/assets/imgs/icons/seepreview.svg"
            />
          </div>
        ) : (
          <CNGToolTip
            parentRef={`art${orderItem && orderItem.id}`}
            style={imageTooltip}
            element={orderItemArt}
          >
            {children}
          </CNGToolTip>
        )}
      </React.Fragment>
    );
  }
}

export default OrderItemArtDetails;
