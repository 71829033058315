import React, {Component} from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import CNGImage from "../../../shared/CNGImage";
import classNames from "classnames";
import lodash from "lodash";

const artAssignmentModalStyles = {
  height: "85vh",
  width: "80%",
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
  margin: "-52px auto",
};

class ArtAssignmentsModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    assignments: PropTypes.array,
    activeAssignmentIndex: PropTypes.number,
    isLoadingArtAssignment: PropTypes.func,
    isInternal: PropTypes.bool,
    uploadArtAssignmentImage: PropTypes.func,
  };

  state = {
    state: null,
    selectedId: null,
    open: false,
    selectedAssignmentIndex: 0,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.onClose && this.props.onClose();
  };

  componentDidMount() {
    const {open, activeAssignmentIndex} = this.props;
    this.setState({open: open, selectedAssignmentIndex: activeAssignmentIndex});
  }

  openFileBrowserHandler = id => {
    this.setState({selectedId: id});
    this.inputField.click();
  };

  handleUploadFile = target => {
    const {currentTarget: {files}} = target;
    this.props.uploadArtAssignmentImage(files[0], this.state.selectedId);
  };

  render() {
    const {assignments, isLoadingArtAssignment, isInternal} = this.props;
    const {selectedAssignmentIndex} = this.state;

    return (
      <div>
        <Dialog
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          contentStyle={artAssignmentModalStyles}
          contentClassName={"art-assignments-modal"}
          bodyStyle={{padding: 0}}
          overlayClassName={"dialog-overlay"}
        >
          <img
            onClick={() => this.handleClose()}
            className="archiveModal_closeIcon"
            src="/assets/imgs/icons/close.svg"
          />

          <input
            type="file"
            onChange={target => {
              this.handleUploadFile(target);
            }}
            className="hidden"
            name="file"
            ref={input => (this.inputField = input)}
          />
          <div className="art-assignments-modal__assignments">
            <div className="assignments__images">
              {assignments.map((assignment, index) => (
                <div
                  key={assignment.id}
                  onClick={() => {
                    this.setState({selectedAssignmentIndex: index});
                  }}
                  style={{position: "relative"}}
                  className={classNames(
                    selectedAssignmentIndex === index
                      ? " image__container active"
                      : "image__container",
                    {
                      loading: isLoadingArtAssignment,
                    }
                  )}
                >
                  <div
                    className="flex justify-content-center w-100 align-items-center"
                    style={{height: "inherit"}}
                  >
                    <CNGImage src={assignment.imageUrl} />

                    {isLoadingArtAssignment ||
                      (isInternal && (
                        <div
                          onClick={() =>
                            this.openFileBrowserHandler(assignment.id)
                          }
                          className="uploader-container flex align-items-center justify-content-center pointer"
                        >
                          <CNGImage
                            src={"/assets/imgs/icons/upload-white.svg"}
                            className="btn-icon-size"
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                          <span>Upload image</span>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="assignments__art">
              <span>
                {lodash.get(
                  assignments,
                  `[${selectedAssignmentIndex}].art.name`
                )}
              </span>

              <img
                src={lodash.get(
                  assignments,
                  `[${selectedAssignmentIndex}].art.imageUrl`
                )}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default ArtAssignmentsModal;
