import React, {Component} from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";

import {formStyles} from "../../styles/components/formStyles";

export default class ValidableTextField extends Component {
  static propTypes = {
    ...TextField.propTypes,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    handleChange: PropTypes.func,
    validate: PropTypes.func,
    isPassword: PropTypes.bool,
    onChange: PropTypes.func,
  };
  state = {
    value: null,
  };

  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    const isLengthValid = value.length <= maxLength;

    if (isLengthValid) {
      callback(event, value);
    }
  };

  render() {
    const {
      TextFieldStyles,
      TextInputStyles,
      TextFieldLabelStyles,
      underlineStyle: TextFieldUnderlineStyle,
    } = formStyles;
    const {
      isPassword,
      value,
      label: floatingLabelText,
      handleChange,
      validate,
      maxLength,
      minLength,
      style,
      onChange,
      inputStyle = TextInputStyles,
      floatingLabelStyle = TextFieldLabelStyles,
      underlineStyle = TextFieldUnderlineStyle,
      ...textFieldProps
    } = this.props;

    return (
      <TextField
        type={isPassword ? "password" : "text"}
        {...textFieldProps}
        {...{
          style: {...TextFieldStyles, ...style},
          inputStyle,
          floatingLabelStyle,
          underlineStyle,
          floatingLabelText,
        }}
        value={value || ""}
        onChange={
          onChange
            ? onChange
            : (event, value) => {
              if (maxLength) {
                this.handleChangeWithMaxLength(
                  event,
                  value,
                  handleChange,
                  maxLength,
                  minLength
                );
              } else {
                handleChange && handleChange(event, value);
              }
            }
        }
        className="mui-text-field"
        onKeyUp={() => validate && validate()}
      />
    );
  }
}
