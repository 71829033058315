import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "../../shared/WithStyles";
import Typography from "../../shared/Typography";

const Message = ({message, styles, isLoading}) => {
  const {colors} = styles;
  const pageStyle = {
    wrapper: {
      alignItems: message.isCurrentUser ? "flex-end" : "flex-start",
    },
    messageText: {
      borderRadius: 4,
      maxWidth: "90%",
      padding: isLoading ? 0 : 8,
      height: isLoading ? 32 : undefined,
      backgroundColor: message.isCurrentUser
        ? "#daf1f8"
        : `${colors.gray.border}4d`,
    },
    imageWrapper: {
      borderRadius: 4,
      padding: 8,
      backgroundColor: message.isCurrentUser
        ? "#daf1f8"
        : `${colors.gray.border}4d`,
    },
    image: {
      width: 144,
      height: 144,
    },
  };

  let data;
  try {
    data = JSON.parse(message.data);
  } catch (_) {
    data = null;
  }
  return (
    <div className="flex flex-column margin-top-8" style={pageStyle.wrapper}>
      <Typography
        className={classNames({loading: isLoading})}
        variant="subtitleBold"
        color="primary.light"
      >
        {message.sender.nickname}:
      </Typography>
      {message.messageType === "file" ? (
        <div style={pageStyle.imageWrapper}>
          <img src={message.url} style={pageStyle.image} />
          <Typography variant="subtitle" color="gray.dark">
            {message.name}
          </Typography>
          {data &&
            data.message && (
            <Typography
              variant="subtitle"
              color="gray.dark"
              style={pageStyle.messageText}
            >
              {data.message}
            </Typography>
          )}
        </div>
      ) : (
        <Typography
          className={classNames({loading: isLoading})}
          variant="subtitle"
          color="gray.dark"
          style={pageStyle.messageText}
        >
          {message.message}
        </Typography>
      )}
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  styles: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default withStyles(Message);
