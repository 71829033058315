import React, {Component} from "react";
import {withRouter} from "react-router";
import TextField from "material-ui/TextField";
import PropTypes from "prop-types";
import {debounce} from "lodash";

const hintText = {
  color: "#747D86",
  fontSize: 16,
  transition: "none",
};
const textStyle = {
  height: 48,
  width: 221,
  borderWidth: 1,
  borderColor: "#BDC6CF",
  borderRadius: 20,
  backgroundolor: "#FFFFFF",
};

const closeImgStyle = {
  width: 14,
};

class SearchInput extends Component {
  _handleUpdateInput(event, value) {
    this.setState({value: value});
    this._searchCallback(value);
  }

  _searchCallback = debounce(
    searchTerm => {
      this.setState({value: searchTerm});
      this.props.search(null, searchTerm);
    },
    1000,
    {
      leading: false,
      trailing: true,
    }
  );

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue || "",
    };
  }

  componentDidUpdate(prevProps) {
    const {initialValue} = prevProps;
    if (this.props.initialValue !== initialValue) {
      this.setState({value: this.props.initialValue});
    }
  }

  removeValueHandler = event => {
    this.setState({value: ""});
    this._handleUpdateInput(event, "");
  };

  render() {
    const {value} = this.state;
    const {className} = this.props;

    return (
      <div
        className={
          `searchInput-container ${className}` +
          (this.props.disabled ? "searchInput-container--disabled" : "")
        }
      >
        <TextField
          autoFocus
          id="search-field"
          hintText="Search"
          hintStyle={hintText}
          inputStyle={textStyle}
          underlineShow={false}
          value={value}
          onChange={(e, value) => this._handleUpdateInput(e, value)}
          disabled={this.props.disabled}
        />{" "}
        {value === "" ? (
          <img
            src="/assets/imgs/icons/search.svg"
            className="searchInput_icon"
          />
        ) : (
          <img
            src="/assets/imgs/icons/X-blue.svg"
            className="closeInput_icon pointer"
            style={closeImgStyle}
            onClick={this.removeValueHandler}
          />
        )}
      </div>
    );
  }
}

SearchInput.propTypes = {
  search: PropTypes.func,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(SearchInput);
