import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import WalletTab from "../../pages/Companies/Components/Wallet/WalletTab";
import {
  fetchWalletMembers,
  updateWalletMember,
  createWalletMember,
} from "../../../actions/companyActions";

function mapStateToProps(state) {
  const {
    companies: {isLoadingWalletMembers, walletMembers, walletMemberCount},
  } = state;
  return {
    isLoadingWalletMembers,
    walletMembers,
    walletMemberCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWalletMembers: bindActionCreators(fetchWalletMembers, dispatch),
    updateWalletMember: bindActionCreators(updateWalletMember, dispatch),
    createWalletMember: bindActionCreators(createWalletMember, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletTab)
);
