import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../actions/companyActions";
import {fetchCountries} from "../../../actions/geoActions";
import {fetchDiscounted} from "../../../actions/discountedActions";
import {fetchBusinesses} from "../../../actions/businessActions";
import {fetchPaymentTerms} from "../../../actions/paymentTermsActions";
import {
  openNotification,
  closeNotification,
} from "../../../actions/notificationActions";
import {push} from "react-router-redux";

import CompaniesCreatePage from "../../pages/Companies/CompaniesCreatePage";

class CompaniesCreate extends Component {
  componentWillMount() {
    this.props.fetchCountries();
    this.props.fetchDiscounted();
    this.props.fetchBusinesses();
    this.props.fetchPaymentTerms();
  }

  render() {
    return (
      <CompaniesCreatePage
        createCompany={company => this.props.createCompany(company)}
        createCompanyContact={user => this.props.createCompanyContact(user)}
        {...this.props}
      />
    );
  }
}

CompaniesCreate.propTypes = {
  createCompany: PropTypes.func,
  createCompanyContact: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchDiscounted: PropTypes.func,
  fetchBusinesses: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  openNotification: PropTypes.func,
  closeNotification: PropTypes.func,
  isLoadingCompanyContactRequest: PropTypes.bool,
  company: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    companies: {isLoading, companies, isLoadingCompanyContactRequest, company},
    geo: {countries},
    discounted: {discounts},
    businesses: {businesses},
    paymentTerms: {paymentTerms},
    notification,
  } = state;

  return {
    isLoading,
    countries,
    companies,
    discounts,
    businesses,
    paymentTerms,
    notification,
    isLoadingCompanyContactRequest,
    company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDiscounted: () => dispatch(fetchDiscounted()),
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchPaymentTerms: () => dispatch(fetchPaymentTerms()),
    createCompany: (company, options) =>
      dispatch(actions.createCompany(company, options)),
    createCompanyContact: user => dispatch(actions.createCompanyContact(user)),
    fetchCountries: () => dispatch(fetchCountries()),
    navigateTo: (...payload) => dispatch(push(...payload)),
    openNotification: message => dispatch(openNotification(message)),
    closeNotification: () => dispatch(closeNotification()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompaniesCreate)
);
