import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "../WithStyles";
import Typography from "../Typography";
import CompactTableRow from "./CompactTableRow";

const CompactTable = ({
  headers,
  headerStyle,
  children,
  style,
  styles: {colors},
  isLoading,
}) => {
  return (
    <table
      className={classNames("w-100 margin-top-24 margin-bottom-24", {
        loading: isLoading,
      })}
      style={{borderCollapse: "collapse", ...style}}
    >
      <thead>
        <CompactTableRow>
          {headers.map(header => (
            <Typography
              key={header}
              component="th"
              variant="subtitleBold"
              color="gray.dark"
              className="uppercase"
              style={{
                backgroundColor: `${colors.gray.border}33`,
                padding: 8,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: colors.gray.border,
                ...headerStyle,
              }}
            >
              {header}
            </Typography>
          ))}
        </CompactTableRow>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

CompactTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.array,
  styles: PropTypes.object,
  headerStyle: PropTypes.object,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default withStyles(CompactTable);
