import React, {Component} from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import PropTypes from "prop-types";
import {formStyles} from "../../../../styles/components/formStyles";
import ValidableTextField from "../../../shared/ValidableTextField";
import TrackingNumber from "../../../shared/TrackingNumber";
import CNGDatePicker from "../../../shared/CNGDatePicker";
import ChillButton from "../../../shared/ChillButton";
import AttributeLine from "../../../shared/AttributeLine";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

const infoStyle = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 32,
};
export default class DSODShipmentTracking extends Component {
  static propTypes = {
    onUpdateShipmentItem: PropTypes.func,
    updateShipmentItem: PropTypes.func,
    isInternal: PropTypes.bool,
    shipment: PropTypes.object,
    shipmentItem: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (!props.shipmentItem) {
      return (this.state = {
        editable: false,
        errors: {},
      });
    }
    const {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
    } = props.shipmentItem;

    this.state = {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
      errors: {},
      edited: false,
      editable: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shipmentItem !== this.props.shipmentItem) {
      this.initShipmentValues(this.props);
    }
  }

  updateShipmentItem = event => {
    event && event.preventDefault();
    this.setState({editable: false});
    if (!this.state.edited) return;
    const {updateShipmentItem, shipmentItem, onUpdateShipmentItem} = this.props;
    const {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
    } = this.state;

    updateShipmentItem(
      shipmentItem.id,
      {
        trackingNumber,
        shipmentCost,
        estimatedDeliveryDate,
        actualShipDate,
      },
      () => {
        onUpdateShipmentItem && onUpdateShipmentItem();
      }
    );
  };

  initShipmentValues = props => {
    if (!props.shipmentItem) return;

    const {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
    } = props.shipmentItem;

    this.setState({
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
      errors: {},
    });
  };

  handleChange = (event, value, attributeName) => {
    const name = event ? event.target.name : attributeName;
    this.setState({edited: true});

    this.setState({
      [name]: value,
    });
  };

  _formatDate(date) {
    return moment(date)
      .utc(false)
      .format("MMM D, YYYY");
  }

  renderInformation = (header = true) => {
    const {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
      editable,
    } = this.state;
    const {shipment} = this.props;

    return (
      <div>
        {header ? (
          <h1
            style={{
              fontSize: 16,
              color: "#4A5158",
              fontWeight: "normal",
              lineHeight: "16px",
              marginBottom: editable ? 6 : 17,
            }}
          >
            Shipping Information
          </h1>
        ) : null}
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <AttributeLine
            style={infoStyle}
            labelText="Tracking Number"
            text={
              <TrackingNumber shipment={shipment}>
                {trackingNumber}
              </TrackingNumber>
            }
          />
          <AttributeLine
            style={infoStyle}
            labelText="Shipment Cost"
            text={shipmentCost && getCurrencyFormattedNumber(shipmentCost)}
          />
          <AttributeLine
            style={infoStyle}
            labelText="Est. Delivery Date"
            text={
              estimatedDeliveryDate && this._formatDate(estimatedDeliveryDate)
            }
          />
          <AttributeLine
            style={{...infoStyle, marginRight: 17}}
            labelText="Actual Ship Date"
            text={actualShipDate && this._formatDate(actualShipDate)}
          />
        </div>
      </div>
    );
  };

  render() {
    const {isInternal} = this.props;
    const {
      trackingNumber,
      shipmentCost,
      estimatedDeliveryDate,
      actualShipDate,
      editable,
    } = this.state;
    if (!isInternal && !trackingNumber) return <div />;
    if (!isInternal) return this.renderInformation();

    return (
      <form onSubmit={this.updateShipmentItem}>
        <h1
          style={{
            fontSize: 16,
            color: "#4A5158",
            fontWeight: "normal",
            lineHeight: "16px",
            marginBottom: editable ? 6 : 17,
          }}
        >
          Shipping Information
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {editable ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <ValidableTextField
                maxLength={18}
                label="Tracking Number"
                type="text"
                name="trackingNumber"
                value={trackingNumber}
                handleChange={this.handleChange}
                errorText={this.state.errors.name}
                floatingLabelStyle={formStyles.TextFieldLabelStyles}
                style={{...formStyles.HalfTextInputStyles, width: 160}}
                underlineStyle={formStyles.underlineStyle}
              />
              <NumberFormat
                thousandSeparator={true}
                prefix={"$"}
                customInput={ValidableTextField}
                label="Shipment Cost"
                type="text"
                name="shipmentCost"
                value={shipmentCost}
                onValueChange={values => {
                  return this.handleChange(null, values.value, "shipmentCost");
                }}
                handleChange={() => {}}
                style={formStyles.HalfTextInputStyles}
                underlineStyle={formStyles.underlineStyle}
              />
              <CNGDatePicker
                floatingLabelText="Est. Delivery Date"
                name="estimatedDeliveryDate"
                value={estimatedDeliveryDate}
                onChange={(event, value) => {
                  this.handleChange(null, value, "estimatedDeliveryDate");
                }}
                style={{
                  marginRight: 16,
                  display: "inline-block",
                }}
                datePickerStyle={formStyles.HalfTextInputStyles}
                textFieldStyle={formStyles.HalfTextInputStyles}
                underlineStyle={formStyles.underlineStyle}
              />
              <CNGDatePicker
                floatingLabelText="Actual Ship Date"
                name="actualShipDate"
                value={actualShipDate}
                onChange={(event, value) => {
                  this.handleChange(null, value, "actualShipDate");
                }}
                date={{
                  marginRight: 16,
                  display: "inline-block",
                }}
                datePickerStyle={formStyles.HalfTextInputStyles}
                textFieldStyle={formStyles.HalfTextInputStyles}
                underlineStyle={formStyles.underlineStyle}
              />
            </div>
          ) : (
            this.renderInformation(false)
          )}

          {editable ? (
            <ChillButton
              height={30}
              width={30}
              marginLeft={17}
              marginBottom={11}
              marginRightIcon={0}
              widthIcon={16}
              icon={"check"}
              iconStyles={{
                color: "#497E95",
              }}
              onClick={this.updateShipmentItem}
              name=""
            />
          ) : (
            <ChillButton
              name={"EDIT"}
              className="non-printable"
              height={30}
              width={82}
              marginLeft={0}
              icon={"edit"}
              marginBottom={4}
              secondary
              onClick={event => {
                event.preventDefault();
                this.setState({editable: true});
              }}
            />
          )}
        </div>
      </form>
    );
  }
}
