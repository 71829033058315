import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import NumberFormat from "react-number-format";

import CNGMenuItem from "../../../../shared/CNGMenuItem";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";
import ValidableSelect from "../../../../shared/ValidableSelect";
import ValidableTextField from "../../../../shared/ValidableTextField";
import {
  EMBROIDERY_COST,
  ETCHING_COST,
  ADORNMENT_TYPES,
} from "../../../../../constants/orderItemType";
import CNGCard from "../../../../shared/CNGCard";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";

const iconStyle = {
  right: "-16px",
  ...SelectFieldIconStyle,
};

const closeIconStyle = {
  color: "#0C5371",
  fontWeight: "300",
  marginLeft: 4,
};

const BeforeCardTitle = {
  color: "#747D86",
  fontSize: 12,
  display: "block",
  lineHeight: 2,
};

const RemoveStyle = {
  cursor: "pointer",
  color: "#0C5271",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 14,
};

const ItemStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
  },
};

export default class SetUpCostItemCard extends Component {
  static propTypes = {
    reorderItem: PropTypes.object,
    digitalizationTypePrices: PropTypes.array,
    fetchDigitalizationTypePrices: PropTypes.func,
    updateOrderItem: PropTypes.func,
    onItemUpdated: PropTypes.func,
    isDisabled: PropTypes.func,
  };

  state = {
    unitCost: null,
    digitalizationTypePriceId: null,
    adornmentTypeId: EMBROIDERY_COST,
    adornmentTypes: ADORNMENT_TYPES,
  };

  componentDidMount() {
    this.props.fetchDigitalizationTypePrices();
    const {reorderItem} = this.props;
    if (reorderItem) {
      this.setState({
        adornmentTypeCode: _get(reorderItem, "adornmentTypeCode"),
        digitalizationTypePriceId: _get(
          reorderItem,
          "digitalizationTypePriceId"
        ),
        unitCostValue: _get(reorderItem, "unitCostValue"),
      });
    }
  }

  handleChange = name => (event, index, value) => {
    value = _get(event, "target.value") || value;
    let doUpdateOrderItem = false;
    const newState = {
      unitCost: null,
      digitalizationTypePriceId: null,
      [name]: value,
    };
    if (name === "adornmentTypeCode") {
      const options = _get(this.props, "digitalizationTypePrices", []).filter(
        typePrice => typePrice.adornmentType === value
      );
      if (options.length === 1) {
        doUpdateOrderItem = true;
        newState.digitalizationTypePriceId = options[0].id.toString();
      }
    }
    this.setState(newState, () => {
      if (name === "digitalizationTypePriceId" || doUpdateOrderItem) {
        this.updateOrderItem();
      }
    });
  };

  handleArchiveOrderItem = async () => {
    const {
      archiveOrderItem,
      reorderItem,
      openNotification,
      onItemArchived,
    } = this.props;

    try {
      await archiveOrderItem(reorderItem.id);
      openNotification("Order item was archived successfully");
      if (onItemArchived) onItemArchived();
    } catch (error) {
      openNotification("Error archiving Order item");
    }
  };

  getDigitalizationOptions = () => {
    const {adornmentTypeCode} = this.state;
    return _get(this.props, "digitalizationTypePrices", [])
      .filter(typePrice => typePrice.adornmentType === adornmentTypeCode)
      .map(({id, code}) => (
        <CNGMenuItem key={id} value={id.toString()} primaryText={code} />
      ));
  };

  getAdornmentTypesOptions = () => {
    return _get(this.state, "adornmentTypes", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  updateOrderItem = event => {
    event && event.preventDefault();
    const {
      updateOrderItem,
      reorderItem,
      digitalizationTypePrices,
      onItemUpdated,
    } = this.props;
    const {
      digitalizationTypePriceId,
      unitCostValue,
      adornmentTypeCode,
    } = this.state;

    let unitCost = unitCostValue * 100; //cent format
    if (digitalizationTypePriceId) {
      const digitizedPrice = digitalizationTypePrices.find(
        ({id}) => Number(id) === Number(digitalizationTypePriceId)
      );
      unitCost = digitizedPrice.price;
    }

    updateOrderItem(
      reorderItem.id,
      {
        digitalizationTypePriceId,
        unitCost,
        adornmentTypeCode,
      },
      onItemUpdated
    );
  };

  _topRightContent = () => {
    const {
      digitalizationTypePriceId,
      unitCostValue,
      adornmentTypeCode,
    } = this.state;

    const {
      isDisabled,
    } = this.props;

    const removeOption = (
      <React.Fragment key="removeOption">
        {
          !isDisabled() &&
        <ValidableSelect
          name="adornmentTypeId"
          label="Adornment Type"
          errorText={null}
          value={adornmentTypeCode}
          handleChange={this.handleChange("adornmentTypeCode")}
          underlineStyle={formStyles.underlineStyle}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
          style={{width: 145, marginRight: 24}}
          iconStyle={iconStyle}
          inputStyle={{width: "100%"}}
          floatingLabelFixed={true}
          dropDownMenuProps={{
            style: {
              width: "100%",
            },
          }}
          options={this.getAdornmentTypesOptions()}
          hintText="Select"
        />
        }
        {
          !isDisabled() &&
          [EMBROIDERY_COST, ETCHING_COST].includes(adornmentTypeCode) ? (
              <ValidableSelect
                name="digitalizationTypePriceId"
                label="Complexity"
                errorText={null}
                value={digitalizationTypePriceId}
                handleChange={this.handleChange("digitalizationTypePriceId")}
                underlineStyle={formStyles.underlineStyle}
                underlineDisabledStyle={formStyles.underlineDisabledStyle}
                style={{width: 64, marginRight: 24}}
                iconStyle={iconStyle}
                inputStyle={{width: "100%"}}
                floatingLabelFixed={true}
                dropDownMenuProps={{
                  style: {
                    width: "100%",
                  },
                }}
                options={this.getDigitalizationOptions()}
                hintText="Select"
              />
            ) : (
              !isDisabled() &&
              <form onSubmit={this.updateOrderItem}>
                <NumberFormat
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={ValidableTextField}
                  name="unitCost"
                  label="Price"
                  type="text"
                  value={unitCostValue || ""}
                  onValueChange={({floatValue}) => {
                    return this.handleChange("unitCostValue")(
                      null,
                      null,
                      floatValue
                    );
                  }}
                  onBlur={this.updateOrderItem}
                  floatingLabelFixed={true}
                  style={{width: 64, marginRight: 24}}
                  inputStyle={{
                    ...formStyles.TextInputStyles,
                    width: 64,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  underlineStyle={formStyles.underlineStyle}
                />
              </form>
            )}

        { 
          !isDisabled() &&
        <span
          style={RemoveStyle}
          key="remove"
          className="labor--remove"
          onClick={this.handleArchiveOrderItem}
        >
          Remove
          <span key="remove-icon" style={closeIconStyle}>
            X
          </span>
        </span>
        }
      </React.Fragment>
    );
    return [removeOption];
  };

  renderItem = () => {
    const {reorderItem} = this.props;

    return (
      <div
        className="flex justify-content-between"
        style={{
          width: "75%",
          marginBottom: 26,
        }}
      >
        <div>
          <div style={ItemStyle.header}>Price</div>
          <div
            style={{
              color: "#747D86",
              fontSize: 14,
            }}
            className="labor--unit-cost"
          >
            {getCurrencyFormattedNumber(_get(reorderItem, "unitCostValue"))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const title = (
      <div>
        <span style={BeforeCardTitle} />
        <span className="labor--title">Set Up Cost</span>
      </div>
    );

    return (
      <CNGCard
        title={title}
        topRightContent={this._topRightContent()}
        headerStyle={{
          alignItems: "center",
          marginBottom: 5,
        }}
        style={{
          paddingBottom: 1,
          paddingTop: 20,
          height: 190,
          fontWeight: "normal",
        }}
      >
        <div>{this.renderItem()}</div>
      </CNGCard>
    );
  }
}
