import regionCountries from "country-region-data";
import {
  USA_COUNTRY_ID,
  CA_COUNTRY_ID,
  USA_COUNTRY_CODE,
  CA_COUNTRY_CODE,
} from "../constants/country";

export function getCountryRegionsFromCountryCode(code) {
  if (!code) return [];

  const countryCode = code === "USA" ? "US" : code;
  const country = regionCountries.find(
    countryRegions => countryRegions.countryShortCode === countryCode
  );
  if (!country) return [];

  return country.regions;
}

export function getValidCountryCodeForHistoricalOrders(countryCode) {
  return countryCode === "USA" ? "US" : countryCode;
}

export function isCountryUSAOrCanada(countryId) {
  return [USA_COUNTRY_ID, CA_COUNTRY_ID].includes(Number(countryId));
}

export function isCountryUSAOrCanadaByCode(countryCode) {
  return [USA_COUNTRY_CODE, CA_COUNTRY_CODE].includes(countryCode);
}
