import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchFabricsRequest = () => makeCommonAction(types.FETCH_FABRICS_REQUEST);
const fetchFabricsSuccess = payload =>
  makeCommonAction(types.FETCH_FABRICS_SUCCESS, payload);
const fetchFabricsError = error =>
  makeCommonAction(types.FETCH_FABRICS_ERROR, error, true);

export function fetchFabrics(opts) {
  const {delay, ...options} = opts || {};
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl("fabrics", null, options),
    fetchOptions: {method: "GET"},
    requestFunc: fetchFabricsRequest,
    successFunc: fetchFabricsSuccess,
    errorFunc: fetchFabricsError,
    error_message: "Error fetching Fabrics",
    delay,
  });
}
export function fetchFabricColors() {
  return makeActionCreator({
    endpoint: "/fabrics",
    fetchOptions: {method: "GET"},
    requestFunc: fetchFabricsRequest,
    successFunc: fetchFabricsSuccess,
    errorFunc: fetchFabricsError,
    error_message: "Error fetching Fabrics",
  });
}
