export default {
  white: "#FFFFFF",
  black: "#000000",
  red: "#D0021B",
  light: "#747D86",
  tabHover: "#397E8D",
  linkColors: "#0C5271",
  primary: {
    main: "#073143",
    dark: "#08384D",
    light: "#0C5371",
    lighter: "#10759F",
  },
  secondary: {
    main: "#136379",
    dark: "#16768F",
    light: "#1D9ABB",
    lighter: "#22B6DD",
  },
  gray: {
    main: "#4A5158",
    dark: "#747D86",
    light: "#BDBEC1",
    lighter: "#F4F8F9",
    title: "#929DA8",
    border: "#bdc6cf",
  },
};
