import React, {Component} from "react";
import * as t from "prop-types";

class PlaceholderImg extends Component {
  render() {
    const {text, title, style, onClick} = this.props;
    return (
      <div
        className={`flex justify-content-center align-items-center ${onClick &&
          "pointer"}`}
        title={title}
        style={{...imageBox, ...style}}
        onClick={onClick}
      >
        <label className="subtext">{text}</label>
      </div>
    );
  }
}

PlaceholderImg.propTypes = {
  text: t.string,
  title: t.string,
  style: t.object,
  onClick: t.func,
};

const imageBox = {
  height: "100%",
  width: 280,
  border: "1px solid #BDC6CF",
  backgroundColor: "#D8D8D8",
};

export default PlaceholderImg;
