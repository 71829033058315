import React from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";

import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";

const DigitizedArtsPreview = ({
  digitizedArt,
  onExpandDigitizedArt,
  pageStyle,
}) => {
  return (
    <div className="flex flex-column margin-right-20">
      <Typography variant="subtitle" color="gray.dark">
        Digitized Art
      </Typography>
      <div style={pageStyle.artImageContainer}>
        <CNGImage
          src={digitizedArt.imageUrl}
          placeHolderStyle={pageStyle.artImage}
          style={pageStyle.artImage}
        />
      </div>
      <Typography
        className="flex pointer"
        variant="bodyBold"
        style={{alignSelf: "center", color: "#269ABB", marginTop: 8}}
        onClick={onExpandDigitizedArt}
      >
        <FontIcon
          className="material-icons"
          style={{
            alignSelf: "center",
            marginLeft: 4,
            color: "#269ABB",
          }}
        >
          search
        </FontIcon>
        Expand
      </Typography>
    </div>
  );
};

DigitizedArtsPreview.propTypes = {
  digitizedArt: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
  }),
  onExpandDigitizedArt: PropTypes.func,
  pageStyle: PropTypes.object,
};

export default DigitizedArtsPreview;
