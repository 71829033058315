import React, {Component} from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";

export default class Loader extends Component {
  static propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
  };

  state = {
    isLoading: true,
  };

  componentDidUpdate(prevProps) {
    const {isLoading} = this.props;
    if (isLoading !== prevProps.isLoading) {
      this.setState({isLoading});
    }
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  _renderLoader() {
    const containerStyle = {
      background: "transparent",
      position: "fixed",
      top: "72px",
      bottom: "0",
      left: "0",
      right: "0",
      pointerEvents: "none",
      zIndex: "10000",
    };
    return (
      <div
        style={containerStyle}
        className="page-loader flex justify-content-center align-items-center"
      />
    );
  }
  render() {
    const {isLoading} = this.state;
    return (
      <div
        className={classNames({"disabled-link loading-page": isLoading})}
        style={{
          overflowY: "auto",
          flex: 1,
        }}
      >
        {isLoading ? this._renderLoader() : null}
        {this.props.children}
      </div>
    );
  }
}
