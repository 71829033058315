import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {fetchStates, fetchCountries} from "../../../actions/geoActions";
import {
  fetchCompany,
  fetchCompanyContact,
  updateCompanyContact,
} from "../../../actions/companyActions";
import {push} from "react-router-redux";

import ContactDetailPage from "../../pages/Contacts/ContactDetailPage";

class ContactDetail extends Component {
  static propTypes = {
    fetchStates: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchCompany: PropTypes.func,
    fetchCompanyContact: PropTypes.func,
    updateCompanyContact: PropTypes.func,
    company: PropTypes.object,
    companyContact: PropTypes.object,
    match: PropTypes.object,
  };

  componentWillMount() {
    const {match: {params: {company_id, id}}} = this.props;
    this.props.fetchCountries();
    this.props.fetchStates();
    company_id && this.props.fetchCompany(company_id, "");
    id && this.props.fetchCompanyContact(id);
  }

  render() {
    return (
      <ContactDetailPage
        updateCompanyContact={contact =>
          this.props.updateCompanyContact(contact)
        }
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.companies.isLoading,
    states: state.geo.states,
    countries: state.geo.countries,
    company: state.companies.company,
    isInternal: state.auth.isInternal,
    companyContact: state.companies.companyContact,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCompanyContact: contact => dispatch(updateCompanyContact(contact)),
    fetchStates: () => dispatch(fetchStates()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompany: id => dispatch(fetchCompany(id)),
    fetchCompanyContact: id => dispatch(fetchCompanyContact(id)),
    navigateTo: payload => dispatch(push(payload)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactDetail)
);
