import React, {Component} from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import Divider from "material-ui/Divider";
import Checkbox from "material-ui/Checkbox";

import {USA_COUNTRY_ID} from "../../../../constants/country";
import {formatAddress} from "../../../../utils/addressFormatter";
import {validateForm} from "../../../../utils/formValidations";
import {isCountryUSAOrCanada} from "../../../../utils/countryRegionsHelper";
import {formStyles} from "../../../../styles/components/formStyles";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import StateSelect from "../../../shared/StateSelect";
import PhoneNumberInput from "../../../shared/PhoneNumberInput";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableAutocomplete from "../../../shared/ValidableAutoComplete";
import ValidableSelect from "../../../shared/ValidableSelect";
import ChillButton from "../../../shared/ChillButton";
import FormattedPhone from "../../../shared/FormattedPhone";
import SectionHeading from "../../../shared/SectionHeading";
import ConfirmationModal from "../../../shared/ConfirmationModal";

const style = {
  outerDiv: {
    background: "rgba(52,184,221,0.03)",
    border: "1px solid #1d9abb",
    padding: "9px 32px 31px",
    borderRadius: "3px",
    position: "relative",
  },
  closeBtn: {
    height: "12px",
    width: "12px",
    position: "absolute",
    right: "8px",
    top: "8px",
    cursor: "pointer",
  },
  recipientMenuItem: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  address: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 12,
  },
};
const HalfTextInputStylesWithGap = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 32,
};

class RecipientForm extends Component {
  constructor(props) {
    super(props);
    const defaultState = {
      errors: {},
      hasError: false,
      confirmOpen: false,
      name: "",
      phoneNumber: "",
      emailAddress: "",
      address1: "",
      address2: "",
      city: "",
      state: null,
      countryId: 232,
      companyName: "",
      quantityToShip: 0,
      isDirty: false,
      confirmClose: false,
      zipCode: "",
      isProof: false,
    };
    let nextState = {
      ..._cloneDeep(defaultState),
    };
    if (props.initialState) {
      nextState = {
        ...nextState,
        ..._cloneDeep(props.initialState),
      };
    }
    this.state = {
      ...nextState,
      pristineState: {
        ...nextState,
      },
    };
  }
  validations = {
    name: {
      format: "any",
      length: [2, 60],
    },
    companyName: {
      format: "any",
      length: [2, 60],
      nullable: true,
    },
    phoneNumber: {
      format: "any",
      nullable: true,
      length: [10],
    },
    emailAddress: {
      format: "email",
      nullable: true,
    },
    address1: {
      format: "any",
      length: [2, 255],
    },
    address2: {
      format: "any",
      length: [2, 255],
      nullable: true,
    },
    state: {
      format: "any",
    },
    countryId: {
      format: "integer",
    },
    city: {
      format: "name",
      length: [2, 40],
    },
  };

  saveRecipient() {
    const {updateCompanyContact, createCompanyContact} = this.props;
    return this.state.id
      ? updateCompanyContact(this.getRecipientFromState())
      : createCompanyContact(this.getRecipientFromState());
  }

  isSelectedUSA() {
    const {countryId} = this.state;
    return Number(countryId) === USA_COUNTRY_ID;
  }

  getRecipientFromState() {
    const {companyId} = this.props;
    const {
      id,
      address1: address_1,
      address2: address_2,
      ...attributes
    } = this.state;
    return id
      ? {
        ..._cloneDeep(attributes),
        id,
        address_1,
        address_2,
        companyId,
      }
      : {
        ...attributes,
        address_1,
        address_2,
        companyId,
      };
  }

  handleConfirmSaveRecipient = async () => {
    const {openNotification, clearError} = this.props;
    const {quantityToShip, isProof} = this.state;
    try {
      const companyContact = this.state.isDirty
        ? await this.saveRecipient()
        : this.getRecipientFromState();
      await this.props.onSaveRecipient(companyContact, quantityToShip, isProof);
      clearError("orderQuantity");
    } catch (error) {
      openNotification("Error saving recipient information");
    }
    this.setState({confirmOpen: false});
  };
  handleCancelRecipient = () => {
    if (this.state.isDirty) {
      return this.setState({confirmClose: true});
    }
    this.props.onClose();
  };

  resetForm() {
    this.setState({
      name: "",
      company: null,
      companyName: "",
      phoneNumber: "",
      emailAddress: "",
      address1: "",
      address2: "",
      city: "",
      state: null,
      countryId: null,
    });
  }

  handleCancelSaveRecipient = () => {
    this.setState({confirmOpen: false, confirmClose: false});
  };

  countryValidation = selectedCountry => {
    let state = {countryId: selectedCountry};
    this.setState(state, this.triggerValidations);
  };

  stateValidation = stateValue => {
    let state = {state: stateValue};
    this.setState(state, this.triggerValidations);
  };

  handleChange = (e, index, value, formName) => {
    const name = formName || e.target.name;
    const state = {
      [name]: value || index,
    };

    if (!["quantityToShip", "isProof"].includes(name)) {
      state["isDirty"] = true;
    }
    if (name === "countryId") return this.countryValidation(state[name]);
    if (name === "state") return this.stateValidation(state[name]);

    this.setState(state);
  };

  triggerValidations = (submitted = false) => {
    const {countryId} = this.state;
    let hasError = false;
    if (this.isSelectedUSA()) {
      this.validations["zipCode"] = {
        format: "integer",
        shouldMatch: value => {
          return value && value.length === 5;
        },
        shouldMatchMessage: "invalid zipcode should be 5 characters long",
      };
      this.validations["state"] = {
        format: "any",
      };
    } else {
      this.validations["zipCode"] = {
        format: "any",
        nullable: true,
      };
      this.validations["state"] = {
        format: "any",
        nullable: true,
      };
    }
    if (isCountryUSAOrCanada(countryId)) {
      this.validations["phoneNumber"] = {
        format: "any",
        nullable: true,
        length: [10],
      };
    } else {
      this.validations["phoneNumber"] = {
        format: "any",
        nullable: true,
      };
    }
    const errors = {
      ...validateForm(this, this.state),
      ...this.validateMaxQuantity(),
    };
    const valid = Object.keys(errors).length === 0;
    if (!valid) {
      hasError = true;
    }

    if (valid && submitted) {
      if (this.state.isDirty) {
        this.setState({
          confirmOpen: true,
          confirmationMessage: this.state.id
            ? "Are you sure you want to edit this recipient?"
            : "Are you sure you want to create a new company recipient?",
        });
      } else {
        this.handleConfirmSaveRecipient();
      }
    }
    this.setState({errors, hasError});
  };

  validateMaxQuantity = () => {
    const {showQuantityToShip, maxQuantityToShip} = this.props;
    if (!showQuantityToShip) return {};
    if (this.state.quantityToShip <= 0)
      return {quantityToShip: "can't be blank"};
    if (this.state.quantityToShip <= maxQuantityToShip) return {};
    return {quantityToShip: `must be less or equal to ${maxQuantityToShip}`};
  };

  _validateDebounced = _debounce(() => {
    this.triggerValidations();
  }, 100);

  handleContactSelected = (chosenRequest, index) => {
    if (index != -1) {
      const {value: {key: contact_id}} = chosenRequest;
      const contact = this.props.companyContacts.find(
        contact => contact.id === contact_id
      );
      const nextState = _cloneDeep(contact);
      this.setState(
        {
          ...nextState,
          pristineState: {
            ...nextState,
          },
          isDirty: false,
        },
        () => {
          this.triggerValidations();
        }
      );
    }
  };

  _getCountrySelectOptions() {
    const {countries} = this.props;
    return (
      countries &&
      countries.reduce((countryOptions, country) => {
        const countryElement = (
          <CNGMenuItem
            key={country.id}
            value={country.id}
            primaryText={country.name.slice(0, 20)}
          />
        );
        if (["USA", "US"].includes(country.code)) {
          return [
            countryElement,
            <Divider key={"usa_divider"} />,
            ...countryOptions,
          ];
        }
        return [...countryOptions, countryElement];
      }, [])
    );
  }

  _getCompanyContactsDataSource() {
    const {companyContacts, countries} = this.props;
    if (!companyContacts) return [];
    return _sortBy(
      companyContacts.map(contact => {
        const {title} = formatAddress(contact, countries);
        return {
          text: contact.name,
          value: (
            <CNGMenuItem
              key={contact.id}
              value={contact.id}
              primaryText={contact.name}
              className="recipient-name-form-field"
              innerDivStyle={style.recipientMenuItem}
              title={title}
            />
          ),
        };
      }),
      ["text"]
    );
  }

  _renderFields() {
    const {
      hasError,
      errors,
      name,
      companyName,
      phoneNumber,
      emailAddress,
      address1,
      address2,
      city,
      state,
      countryId,
      quantityToShip,
      zipCode,
      isProof,
    } = this.state;

    const {showQuantityToShip, countries, hideIsProof} = this.props;
    const selectedCountry = countries.find(
      country => Number(country.id) === countryId
    );
    const countryCode = selectedCountry ? selectedCountry.code : "US";

    return (
      <div style={style.outerDiv}>
        <img
          src="/assets/imgs/icons/close.svg"
          style={style.closeBtn}
          onClick={() => {
            this.handleCancelRecipient();
            this.setState({submitted: false});
          }}
        />
        <div className="flex" style={{marginBottom: 8}}>
          <ValidableAutocomplete
            name="name"
            label="Recipient Name"
            value={name || ""}
            searchText={name || ""}
            errorText={errors.name}
            textFieldStyle={HalfTextInputStylesWithGap}
            maxSearchResults={10}
            listStyle={{
              width: 150,
            }}
            menuStyle={{
              width: 150,
            }}
            style={{
              width: 182,
            }}
            inputStyle={{
              width: 150,
            }}
            onUpdateInput={value => {
              this.handleChange(null, null, value, "name");
            }}
            validate={() => this.triggerValidations()}
            dataSource={this._getCompanyContactsDataSource() || []}
            onNewRequest={(chosenRequest, index) =>
              this.handleContactSelected(chosenRequest, index)
            }
            tabIndex="1"
          />
          <ValidableTextField
            name="companyName"
            label="Company"
            value={companyName || ""}
            inputStyle={{
              ...formStyles.HalfTextInputStyles,
              width: 90,
            }}
            errorText={errors.company}
            style={formStyles.HalfTextInputStyles}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <PhoneNumberInput
            name="phoneNumber"
            label="Phone"
            value={phoneNumber}
            countryCode={countryCode}
            errorText={errors.phoneNumber}
            style={HalfTextInputStylesWithGap}
            handleChange={(e, value) =>
              this.handleChange(
                e,
                null,
                value.replace(/\D/g, ""),
                "phoneNumber"
              )
            }
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <ValidableTextField
            name="emailAddress"
            label="Email"
            value={emailAddress || ""}
            errorText={errors.emailAddress}
            inputStyle={formStyles.HalfTextInputStyles}
            style={formStyles.HalfTextInputStyles}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />{" "}
          <ValidableSelect
            name="countryId"
            label="Country"
            value={countryId ? countryId : 232}
            errorText={errors.countryId}
            autoWidth={true}
            handleChange={(e, index, value) => {
              if (Number(value) !== this.state.countryId) {
                this.handleChange(e, index, Number(value), "countryId");
                this.handleChange(e, null, null, "state");
              }
            }}
            options={this._getCountrySelectOptions()}
            style={HalfTextInputStylesWithGap}
            iconStyle={formStyles.HalfTextInputIconStyles}
            tabIndex={1}
          />
          {showQuantityToShip && (
            <ValidableTextField
              name="quantityToShip"
              label="# To Ship"
              style={formStyles.quarterSelectStyles}
              value={quantityToShip || ""}
              maxLength={8}
              errorText={errors.quantityToShip}
              handleChange={(event, value) => {
                if (value.length > 0 && !value.match(/^\d+$/gi)) return;
                this.handleChange(event, value);
              }}
              validate={() => this._validateDebounced()}
              tabIndex={2}
            />
          )}
        </div>
        <div className="flex">
          <ValidableTextField
            name="address1"
            label="Address 1"
            value={address1 || ""}
            errorText={errors.address1}
            inputStyle={formStyles.HalfTextInputStyles}
            style={formStyles.HalfTextInputStyles}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <ValidableTextField
            name="address2"
            label="Address 2"
            value={address2 || ""}
            errorText={errors.address2}
            inputStyle={formStyles.HalfTextInputStyles}
            style={formStyles.HalfTextInputStyles}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <ValidableTextField
            name="city"
            maxLength={20}
            label="City"
            value={city || ""}
            errorText={errors.city}
            style={HalfTextInputStylesWithGap}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <StateSelect
            label="State/Region"
            countryCode={countryCode}
            value={state}
            errorText={errors.state}
            handleChange={this.handleChange}
            style={formStyles.halfSelectStyles}
            inputStyle={formStyles.halfSelectInputStyles}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            floatingLabelStyle={formStyles.halfSelectFloatingLabelStyles}
            menuStyle={formStyles.halfSelectMenuInputStyles}
            listStyle={formStyles.halfSelectMenuListStyles}
            iconStyle={{
              right: 5,
              width: 40,
            }}
            tabIndex={1}
          />
          <ValidableTextField
            name="zipCode"
            label="Zip code"
            value={zipCode || ""}
            errorText={errors.zipCode}
            inputStyle={formStyles.TinyInputStyles}
            style={formStyles.TinyInputStyles}
            handleChange={this.handleChange}
            validate={() => this._validateDebounced()}
            tabIndex={1}
          />
          <ChillButton
            className="chill-button--inline"
            icon="check"
            name="SAVE"
            small={false}
            width={113}
            height={40}
            isDisabled={hasError}
            onClick={() => {
              this.setState(
                {
                  submitted: true,
                },
                () => {
                  this.triggerValidations(true);
                }
              );
            }}
            tabIndex={2}
          />
        </div>
        {hideIsProof ? null : (
          <div className="flex">
            <Checkbox
              label="Pre-Production Shipment"
              style={{marginTop: 10, width: 300}}
              defaultChecked={isProof}
              onClick={event => {
                this.handleChange(null, null, event.target.checked, "isProof");
              }}
            />
          </div>
        )}
      </div>
    );
  }
  _renderContactSummary() {
    const {company, address1, emailAddress, phoneNumber} = this.props;
    return (
      <div className="company_top_info">
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"COMPANY"} />
          <span className="subtext">{company}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"ADDRESS"} />
          <span className="subtext">{address1}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"EMAIL"} />
          <span className="subtext no-wrap">{emailAddress}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"PHONE"} />
          <span className="subtext no-wrap">
            <FormattedPhone phoneNumber={phoneNumber} />
          </span>
        </div>
      </div>
    );
  }

  _comfirmClear = () => {
    if (this.props.clearForm) {
      this.resetForm();
    }
    this.setState({confirmClose: false, errors: {}});
    this.props.onClose();
  };

  render() {
    const {containerStyle} = this.props;
    return (
      <div className="dsod-recipient-form" style={containerStyle}>
        {this._renderFields()}

        <ConfirmationModal
          content={this.state.confirmationMessage}
          open={this.state.confirmOpen}
          onConfirm={this.handleConfirmSaveRecipient}
          onDismiss={this.handleCancelSaveRecipient}
        />

        <ConfirmationModal
          content={"You will lose all unsave work, are you sure?"}
          title={"Confirmation"}
          open={this.state.confirmClose}
          onConfirm={this._comfirmClear}
          onDismiss={() => {
            this.setState({confirmClose: false});
          }}
        />
      </div>
    );
  }
}

RecipientForm.propTypes = {
  handleChange: PropTypes.func,
  onSaveRecipient: PropTypes.func,
  companyContacts: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  onClose: PropTypes.func,
  submitSuccess: PropTypes.func,
  submitError: PropTypes.func,
  errors: PropTypes.object,
  editting: PropTypes.bool,
  submitted: PropTypes.bool,
  containerStyle: PropTypes.object,
  showQuantityToShip: PropTypes.bool,
  maxQuantityToShip: PropTypes.number,
  company: PropTypes.object,
  address1: PropTypes.string,
  emailAddress: PropTypes.string,
  phoneNumber: PropTypes.string,
  clearForm: PropTypes.bool,
  updateCompanyContact: PropTypes.func,
  createCompanyContact: PropTypes.func,
  companyId: PropTypes.number,
  isProof: PropTypes.bool,
  hideIsProof: PropTypes.bool,
  clearError: PropTypes.func,
};

export default RecipientForm;
