import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "../../../shared/ChillButton";

export default class ArtDetailCardHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    buttonHeight: PropTypes.number,
    buttonWidth: PropTypes.number,
    noButton: PropTypes.bool,
    art: PropTypes.object,
    latestRevision: PropTypes.object,
    brand: PropTypes.object,
    history: PropTypes.object.isRequired,
    onReplaceVersion: PropTypes.func,
    onCancelRestoreVersion: PropTypes.func,
    deleteArtRevision: PropTypes.func,
  };

  handleGoToEditPage = () => {
    const {history, art} = this.props;
    history.push(`/brands/${art.brandId}/arts/${art.id}/edit`);
  };

  renderButtons = () => {
    const {latestRevision, onReplaceVersion, deleteArtRevision} = this.props;
    if (!latestRevision) return;
    return (
      <React.Fragment>
        <ChillButton
          className="pageIndexHeader_btn"
          key="archiveButton"
          name="ARCHIVE"
          icon={"archive"}
          height={32}
          width={110}
          secondary={false}
          onClick={deleteArtRevision}
        />

        <ChillButton
          className="btn--formCardHeader"
          key="newVersionButton"
          name="NEW VERSION"
          icon="add2-white"
          height={32}
          width={130}
          secondary={false}
          onClick={onReplaceVersion}
        />
      </React.Fragment>
    );
  };

  render() {
    const {noButton} = this.props;
    return (
      <div className="row middle-xs between-xs">
        <h1>Art Details</h1>
        <div className="row middle-xs">
          {!noButton ? this.renderButtons() : <div />}
        </div>
      </div>
    );
  }
}
