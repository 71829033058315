import React, {Component} from "react";
import PropTypes from "prop-types";

class CNGCARD extends Component {
  state = {
    menu: false,
  };

  static defaultProps = {
    showDivider: true,
  };

  _renderIcon() {
    return (
      <img
        src={`/assets/imgs/icons/${this.props.headerIcon}.svg`}
        className="CNGCard_icon"
      />
    );
  }

  _renderActionIcon() {
    const {onTitleClick, actionIcon} = this.props;
    return (
      <img
        src={`/assets/imgs/icons/${actionIcon}.svg`}
        className="CNGCard_icon"
        style={{
          margin: "0 0 3px 8px",
          width: "14px",
          cursor: "pointer",
        }}
        onClick={() => onTitleClick && onTitleClick()}
      />
    );
  }

  _renderResultsCount() {
    const {resultsLimit, resultsCount} = this.props;
    let resultsText;
    if (resultsLimit && resultsCount > resultsLimit) {
      resultsText = `${resultsLimit} of ${resultsCount} Results`;
    } else {
      resultsText = `${resultsCount} Results`;
    }
    return <p className="CNGCard_results">{resultsText}</p>;
  }

  render() {
    const {
      style,
      headerIcon,
      title,
      actionIcon,
      resultsCount,
      topRightContent,
      children,
      showActionIcon,
      showDivider,
      headerStyle,
    } = this.props;
    return (
      <div className="CNGCard" style={style}>
        <div className="CNGCard_top flex " style={headerStyle}>
          <div className="CNGCARD_top-left flex flex-one flex-row align-items-baseline">
            {headerIcon ? this._renderIcon() : null}
            <h2 className="CNGCard_title flex align-items-end">
              {title}
              {showActionIcon && actionIcon ? this._renderActionIcon() : null}
            </h2>
            {resultsCount >= 0 ? this._renderResultsCount() : null}
          </div>
          <div className="CNGCARD_top-right flex align-items-center">
            {topRightContent}
          </div>
        </div>
        {showDivider ? <div className="CNGCard_divider" /> : null}
        {children}
      </div>
    );
  }
}

CNGCARD.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerIcon: PropTypes.string,
  actionIcon: PropTypes.string,
  resultsCount: PropTypes.number,
  resultsLimit: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  topRightContent: PropTypes.node,
  style: PropTypes.object,
  onTitleClick: PropTypes.func,
  showActionIcon: PropTypes.bool,
  showDivider: PropTypes.bool,
  headerStyle: PropTypes.object,
};

export default CNGCARD;
