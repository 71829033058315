import React from "react";
import PropTypes from "prop-types";
import {TableRow, TableRowColumn} from "material-ui/Table";

const CNGTableHeader = props => {
  return (
    <TableRow displayBorder={false} style={{height: 16}}>
      {props.tableHeader.map((headerItem, index) => {
        if (index == 0) {
          return (
            <TableRowColumn
              key={index}
              style={{...thStyle, paddingLeft: 33}}
              className="CNG-th"
            >
              {headerItem}
            </TableRowColumn>
          );
        }
        return (
          <TableRowColumn key={index} style={thStyle} className="CNG-th">
            {headerItem}
          </TableRowColumn>
        );
      })}
    </TableRow>
  );
};

CNGTableHeader.propTypes = {
  displayRowCheckbox: PropTypes.bool,
  tableHeader: PropTypes.array.isRequired,
};

const thStyle = {
  height: 16,
  fontWeight: "bold",
  color: "#929DA8",
  fontSize: 12,
};

export default CNGTableHeader;
