import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import { Link } from "react-router-dom";
import { TableRow, TableRowColumn } from "material-ui/Table";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";
import FormattedPhone from "../../../shared/FormattedPhone";
import RecipientForm from "../../../containers/DSOD/RecipientFormContainer";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import ArrowDropRight from "material-ui/svg-icons/navigation-arrow-drop-right";
import FormattedAddress from "../../../shared/FormattedAddress";
import {
  PRODUCT_ORDER_ITEM_NAME,
  MISCELLANEOUS_COST,
} from "../../../../constants/orderItemType";

export default class RecipientTable extends Component {
  static propTypes = {
    isDisabled: PropTypes.func,
    onSelectedOption: PropTypes.func,
    updateShipmentItem: PropTypes.func,
    onCopyToDifferenteOrderItem: PropTypes.func,
    onMoveToDifferenteOrderItem: PropTypes.func,
    onDeleteShipmentItem: PropTypes.func,
    shipmentItems: PropTypes.array.isRequired,
    reorderItems: PropTypes.array,
    reorderItem: PropTypes.object,
    companyId: PropTypes.number,
    companyContacts: PropTypes.array,
    shipments: PropTypes.array,
    sameRecipient: PropTypes.bool,
  };

  state = {
    editableRow: null,
  };

  formatItemLine = item => {
    const productName = _get(item, "orderItemProduct.product.productName");
    const name = productName
      ? productName
      : `Miscellaneous ${_get(item, "notes")}`;
    const itemNumber = _get(item, "orderItemProduct.product.itemNumber");

    const details = itemNumber ? `- Item #${itemNumber}` : "";
    return [name, details].join(" ");
  };

  _renderRow(shipmentItem, index) {
    const {
      isDisabled,
      reorderItems,
      reorderItem,
      onDeleteShipmentItem,
      companyId,
      companyContacts,
      updateShipmentItem,
      shipments,
      sameRecipient,
      onSelectedOption,
    } = this.props;
    const { editableRow } = this.state;
    const { recipient } = shipmentItem;
    let reorderList = [];

    const shipmentItemsByOrderItem = _groupBy(
      shipments[0].items,
      "orderItemId"
    );

    if (reorderItems) {
      reorderList = reorderItems.filter(item => {
        if (item.id === reorderItem.id || item.quantityToInventory === 0)
          return;
        const shipmentItems = shipmentItemsByOrderItem[item.id];
        if (!shipmentItems) return true;
        const currentRecipientIds = shipmentItems.map(
          shipmentItem => shipmentItem.recipientId
        );
        return !currentRecipientIds.includes(shipmentItem.recipientId);
      });
    }

    let maxQuantityToShip = null;
    let showQuantityToShip = false;

    if (!sameRecipient && shipmentItem && reorderItem) {
      maxQuantityToShip =
        reorderItem.quantityToInventory + shipmentItem.shippedQuantity;
      showQuantityToShip = true;
    }

    if (index === editableRow) {
      return (
        <TableRow key={index} selectable={false}>
          <TableRowColumn colSpan={6}>
            <RecipientForm
              onClose={() => {
                this.setState({ editableRow: null });
              }}
              containerStyle={{ marginBottom: 32 }}
              companyId={companyId}
              initialState={{
                ...recipient,
                quantityToShip: shipmentItem.shippedQuantity,
                isProof: shipmentItem.isProof,
              }}
              companyContacts={companyContacts}
              showQuantityToShip={showQuantityToShip}
              maxQuantityToShip={maxQuantityToShip}
              onSaveRecipient={(recipient, quantity, isProof) => {
                updateShipmentItem(
                  shipmentItem,
                  recipient.id,
                  quantity,
                  isProof
                );
                this.setState({ editableRow: false });
              }}
            />
          </TableRowColumn>
        </TableRow>
      );
    }

    const filteredOptions = reorderList.filter(({ orderItemType }) =>
      [PRODUCT_ORDER_ITEM_NAME, MISCELLANEOUS_COST].includes(
        _get(orderItemType, "name")
      )
    );

    const copyItems = filteredOptions.map((item, index) => {
      return (
        <CNGMenuItem
          style={MenuItemStyle}
          primaryText={this.formatItemLine(item)}
          key={index}
          onClick={() => onSelectedOption("copyTo", item, recipient)}
        />
      );
    });

    const moveItems = filteredOptions.map((item, index) => (
      <CNGMenuItem
        style={MenuItemStyle}
        primaryText={this.formatItemLine(item)}
        key={index}
        onClick={() =>
          onSelectedOption("moveTo", item, recipient, shipmentItem)
        }
      />
    ));

    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn highlight={true} customStyles={{ width: 210 }}>
          <Link
            to={`/companies/${companyId}?tab=recipients&query=${_get(
              recipient,
              "name"
            )}`}
            className="no-link-styles"
          >
            <span title={_get(recipient, "name")}>
              {_get(recipient, "name")}
            </span>
          </Link>
          <div className="table-subtext" title={_get(recipient, "companyName")}>
            {_get(recipient, "companyName")}
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={{ width: 300 }}>
          <div>
            <span className="subtext">
              <FormattedAddress user={recipient || {}} />
            </span>
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={_get(recipient, "phoneNumber")}
          customStyles={{ width: 150 }}
        >
          <FormattedPhone
            phoneNumber={_get(recipient, "phoneNumber")}
            countryCode={_get(
              recipient,
              "country.code",
              _get(recipient, "countryCode")
            )}
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <span title={_get(recipient, "emailAddress")}>
            {_get(recipient, "emailAddress")}
          </span>
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={{ width: 100, position: "relative" }}>
          {shipmentItem.shippedQuantity}
          {shipmentItem.isProof && (
            <span
              style={{ position: "absolute", top: 4, right: 4, color: "#1d9abb" }}
            >
              PP
            </span>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={{ width: 100 }}>
          {!isDisabled() &&
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
            >
              {!sameRecipient ? (
                <CNGMenuItem
                  primaryText="Copy to"
                  rightIcon={<ArrowDropRight />}
                  disabled={reorderList.length <= 0}
                  style={MenuItemStyle}
                  menuItems={copyItems}
                />
              ) : null}

              {!sameRecipient ? (
                <CNGMenuItem
                  primaryText="Move to"
                  rightIcon={<ArrowDropRight />}
                  disabled={reorderList.length <= 0}
                  style={MenuItemStyle}
                  menuItems={moveItems}
                />
              ) : null}
              <CNGMenuItem
                primaryText="Edit"
                style={MenuItemStyle}
                onClick={() =>
                  this.setState({ editableRow: index, option: "edit" })
                }
              />
              <CNGMenuItem
                primaryText="Delete"
                style={MenuItemStyle}
                onClick={() => onDeleteShipmentItem(shipmentItem)}
              />
            </IconMenu>
          }
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "RECIPIENT",
        customStyles: {
          width: 210,
        },
      },
      {
        columnContent: "ADDRESS",
        customStyles: {
          width: 300,
        },
      },
      {
        columnContent: "PHONE",
        customStyles: {
          width: 150,
        },
      },
      "EMAIL",
      {
        columnContent: this.props.sameRecipient ? "" : "QTY TO SHIP",
        customStyles: {
          width: 100,
        },
      },
      {
        columnContent: "",
        customStyles: {
          width: 100,
        },
      },
    ];
    const { shipmentItems } = this.props;

    if (!shipmentItems) return <div />;

    return (
      <CNGTable
        className="inventory-table"
        tableHeader={header}
        displayRowCheckbox={false}
      >
        {shipmentItems.map((row, index) => {
          return this._renderRow(row, index);
        })}
      </CNGTable>
    );
  }
}

const MenuItemStyle = {
  color: "#747D86",
  fontSize: 14,
};
