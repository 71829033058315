import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CarrierAssignmentTab from "./CarrierAssignmentTab";
import {
  fetchCompanyShippingCarrierAssignments,
  patchCompanyShippingCarrierAssignment,
  deleteCompanyShippingCarrierAssignment,
} from "../../../../../actions/shipmentActions";

function mapStateToProps(state) {
  const {
    isCarrierAssignmentsLoading,
    companyShippingCarrierAssignments,
  } = state.shipment;
  return {
    isInternal: state.auth.isInternal,
    isLoading: isCarrierAssignmentsLoading,
    carrierAssignments: companyShippingCarrierAssignments || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCompanyShippingCarrierAssignment: bindActionCreators(
      deleteCompanyShippingCarrierAssignment,
      dispatch
    ),
    patchCompanyShippingCarrierAssignment: bindActionCreators(
      patchCompanyShippingCarrierAssignment,
      dispatch
    ),
    fetchCompanyShippingCarrierAssignments: bindActionCreators(
      fetchCompanyShippingCarrierAssignments,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CarrierAssignmentTab
);
