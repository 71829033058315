import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchShippingMethodsRequest = () =>
  makeCommonAction(types.FETCH_SHIPPING_METHODS_REQUEST);
const fetchShippingMethodsSuccess = payload =>
  makeCommonAction(types.FETCH_SHIPPING_METHODS_SUCCESS, payload);
const fetchShippingMethodsError = error =>
  makeCommonAction(types.FETCH_SHIPPING_METHODS_ERROR, error, true);

const fetchShippingCarriersRequest = () =>
  makeCommonAction(types.FETCH_SHIPPING_CARRIERS_REQUEST);
const fetchShippingCarriersSuccess = payload =>
  makeCommonAction(types.FETCH_SHIPPING_CARRIERS_SUCCESS, payload);
const fetchShippingCarriersError = error =>
  makeCommonAction(types.FETCH_SHIPPING_CARRIERS_ERROR, error, true);

const fetchShipmentsRequest = () =>
  makeCommonAction(types.FETCH_SHIPMENTS_REQUEST);
const fetchShipmentsSuccess = payload =>
  makeCommonAction(types.FETCH_SHIPMENTS_SUCCESS, payload);
const fetchShipmentsError = error =>
  makeCommonAction(types.FETCH_SHIPMENTS_ERROR, error, true);

const fetchShipmentRequest = () =>
  makeCommonAction(types.FETCH_SHIPMENT_REQUEST);
const fetchShipmentSuccess = payload =>
  makeCommonAction(types.FETCH_SHIPMENT_SUCCESS, payload);
const fetchShipmentError = error =>
  makeCommonAction(types.FETCH_SHIPMENT_ERROR, error, true);

const updateShipmentItemRequest = () =>
  makeCommonAction(types.UPDATE_SHIPMENT_ITEM_REQUEST);
const updateShipmentItemSuccess = payload =>
  makeCommonAction(types.UPDATE_SHIPMENT_ITEM_SUCCESS, payload);
const updateShipmentItemError = error =>
  makeCommonAction(types.UPDATE_SHIPMENT_ITEM_ERROR, error, true);

const fetchCompanyShippigCarrierAssignmentRequest = () =>
  makeCommonAction(types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST);
const fetchCompanyShippigCarrierAssignmentSuccess = payload =>
  makeCommonAction(
    types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS,
    payload
  );
const fetchCompanyShippigCarrierAssignmentError = error =>
  makeCommonAction(
    types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR,
    error,
    true
  );

const createShipmentItemRequest = () =>
  makeCommonAction(types.CREATE_SHIPMENT_ITEM_REQUEST);
const createShipmentItemSuccess = payload =>
  makeCommonAction(types.CREATE_SHIPMENT_ITEM_SUCCESS, payload);
const createShipmentItemError = error =>
  makeCommonAction(types.CREATE_SHIPMENT_ITEM_ERROR, error, true);

const updateShipmentRequest = () =>
  makeCommonAction(types.UPDATE_SHIPMENT_REQUEST);
const updateShipmentSuccess = payload =>
  makeCommonAction(types.UPDATE_SHIPMENT_SUCCESS, payload);
const updateShipmentError = error =>
  makeCommonAction(types.UPDATE_SHIPMENT_ERROR, error, true);

const deleteShippingCarrierAssignmentRequest = () =>
  makeCommonAction(types.DELETE_SHIPPING_CARRIER_ASSIGNMENT_REQUEST);
const deleteShippingCarrierAssignmentSuccess = payload =>
  makeCommonAction(types.DELETE_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS, payload);
const deleteShippingCarrierAssignmentError = error =>
  makeCommonAction(types.DELETE_SHIPPING_CARRIER_ASSIGNMENT_ERROR, error, true);

const createCompanyShippingCarrierAssignmentRequest = () =>
  makeCommonAction(types.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST);
const createCompanyShippingCarrierAssignmentSuccess = payload =>
  makeCommonAction(
    types.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS,
    payload
  );
const createCompanyShippingCarrierAssignmentError = error =>
  makeCommonAction(
    types.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR,
    error,
    true
  );

const patchShippingCarrierAssignmentRequest = () =>
  makeCommonAction(types.PATCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST);
const patchShippingCarrierAssignmentSuccess = payload =>
  makeCommonAction(types.PATCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS, payload);
const patchShippingCarrierAssignmentError = error =>
  makeCommonAction(types.PATCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR, error, true);

const fetchCompanyShippigCarrierAssignmentsRequest = () =>
  makeCommonAction(types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_REQUEST);
const fetchCompanyShippigCarrierAssignmentsSuccess = payload =>
  makeCommonAction(
    types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_SUCCESS,
    payload
  );
const fetchCompanyShippigCarrierAssignmentsError = error =>
  makeCommonAction(
    types.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_ERROR,
    error,
    true
  );

export function searchShipments(query, options) {
  options = {
    sort: "shipments;created_at",
    include: "[order,items.orderItem.orderItemProduct.product]",
    ...options,
  };
  const url = RequestService.buildSearchUrl(
    "shipments",
    options.include,
    query,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchShipmentsRequest,
    successFunc: fetchShipmentsSuccess,
    errorFunc: fetchShipmentsError,
    error_message: "Error searching inventory shipments",
  });
}

export function searchShipmentsByCompany(company_id, query, options) {
  options = {
    sort: " ",
    include: "[order,items.orderItem.orderItemProduct.product]",
    ...options,
  };
  const url = RequestService.buildSearchUrl(
    `companies/${company_id}/shipments`,
    options.include,
    query,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchShipmentsRequest,
    successFunc: fetchShipmentsSuccess,
    errorFunc: fetchShipmentsError,
    error_message: "Error searching inventory shipments",
  });
}

export function searchInventoryShipments(company_id, query, options = {}) {
  const url = RequestService.buildSearchUrl(
    `companies/${company_id}/inventory_items/shipments`,
    options.include,
    query,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchShipmentsRequest,
    successFunc: fetchShipmentsSuccess,
    errorFunc: fetchShipmentsError,
    error_message: "Error searching inventory shipments",
  });
}

export function fetchShipments(orderId, options = {}) {
  const include =
    "[recipient(notDeleted).[country,company],items(orderById,notDeleted).recipient(notDeleted).[country,company],shippingMethod(notDeleted).shippingCarrier(notDeleted),shipmentAccount(notDeleted).shippingCarrier(notDeleted)]";

  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `orders/${orderId}/shipments`,
      include,
      options
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchShipmentsRequest,
    successFunc: fetchShipmentsSuccess,
    errorFunc: fetchShipmentsError,
    error_message: "Error fetching shipments",
  });
}

export function fetchShipment(id) {
  return makeActionCreator({
    endpoint: `/shipments/${id}/?include=[items.[recipient]]`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchShipmentRequest,
    successFunc: fetchShipmentSuccess,
    errorFunc: fetchShipmentError,
    error_message: "Error fetching shipment",
  });
}

export function deleteShipmentItem(id) {
  return makeActionCreator({
    endpoint: `/shipmentitems/${id}`,
    fetchOptions: {method: "DELETE"},
    requestFunc: createShipmentItemRequest,
    successFunc: createShipmentItemSuccess,
    errorFunc: createShipmentItemError,
    error_message: "Error updating Shipment Item",
  });
}

export function updateShipmentItem(id, shipmentItemData, afterSave) {
  return makeActionCreator({
    endpoint: `/shipmentitems/${id}`,
    fetchOptions: {body: shipmentItemData, method: "PUT"},
    requestFunc: updateShipmentItemRequest,
    successFunc: payload => {
      afterSave && afterSave();
      return updateShipmentItemSuccess(payload);
    },
    errorFunc: updateShipmentItemError,
    error_message: "Error updating Shipment Item",
    successMessage: "Shipment item updated successfully",
  });
}

export function createShipmentItem(shipmentItemData) {
  return makeActionCreator({
    endpoint: "/shipmentitems",
    fetchOptions: {body: shipmentItemData, method: "POST"},
    requestFunc: createShipmentItemRequest,
    successFunc: createShipmentItemSuccess,
    errorFunc: createShipmentItemError,
    error_message: "Error creating Shipment Item",
  });
}

export function updateShipment(id, shipmentData, callback) {
  return makeActionCreator({
    endpoint: `/shipments/${id}`,
    fetchOptions: {body: shipmentData, method: "PUT"},
    requestFunc: updateShipmentRequest,
    successFunc: payload => {
      callback && callback();
      return updateShipmentSuccess(payload);
    },
    errorFunc: updateShipmentError,
    error_message: "Error updating Shipment Item",
    successMessage: "Shipment has been updated",
  });
}

export function createCompanyShippingCarrierAssignment(
  companyId,
  payload,
  callback
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/company_shipping_carrier_assignments?include=shippingCarrier`,
    fetchOptions: {body: payload, method: "POST"},
    requestFunc: createCompanyShippingCarrierAssignmentRequest,
    successFunc: data => {
      callback && callback();
      return createCompanyShippingCarrierAssignmentSuccess(data);
    },
    errorFunc: createCompanyShippingCarrierAssignmentError,
    error_message: "Error creating shipping carrier assignment",
    successMessage: "Shipping carrier assignment has been created",
  });
}

export function fetchCompanyShippingCarrierAssignment(companyId, id) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/company_shipping_carrier_assignments/${id}?include=shippingCarrier`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchCompanyShippigCarrierAssignmentRequest,
    successFunc: fetchCompanyShippigCarrierAssignmentSuccess,
    errorFunc: fetchCompanyShippigCarrierAssignmentError,
    error_message: "Error fetching shipping carrier assignment",
  });
}

export function deleteCompanyShippingCarrierAssignment(companyId, id) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/company_shipping_carrier_assignments/${id}`,
    fetchOptions: {method: "DELETE"},
    requestFunc: deleteShippingCarrierAssignmentRequest,
    successFunc: () => {
      return deleteShippingCarrierAssignmentSuccess({
        id,
      });
    },
    errorFunc: deleteShippingCarrierAssignmentError,
    error_message: "Error updating Shipment Item",
    successMessage: "Shipping carrier assignment has been deleted",
  });
}

export function patchCompanyShippingCarrierAssignment(
  companyId,
  id,
  payload,
  callback
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/company_shipping_carrier_assignments/${id}`,
    fetchOptions: {body: payload, method: "PATCH"},
    requestFunc: patchShippingCarrierAssignmentRequest,
    successFunc: carrierAssignment => {
      callback && callback();
      return patchShippingCarrierAssignmentSuccess(carrierAssignment);
    },
    errorFunc: patchShippingCarrierAssignmentError,
    error_message: "Error updating Shipment Item",
    successMessage: "Shipping carrier assignment has been updated",
  });
}

export function fetchCompanyShippingCarrierAssignments(options) {
  return makeActionCreator({
    endpoint: `/company_shipping_carrier_assignments/?filter=${
      options.filter
    }&include=shippingCarrier`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchCompanyShippigCarrierAssignmentsRequest,
    successFunc: fetchCompanyShippigCarrierAssignmentsSuccess,
    errorFunc: fetchCompanyShippigCarrierAssignmentsError,
    error_message: "Error fetching shipping accounts",
  });
}

export function fetchShippingCarriers(options = {}) {
  const filter = options.filter ? `filter=${options.filter}` : "";
  return makeActionCreator({
    endpoint: `/shipping_carriers/?${filter}`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchShippingCarriersRequest,
    successFunc: fetchShippingCarriersSuccess,
    errorFunc: fetchShippingCarriersError,
    error_message: "Error fetching shipping carriers",
  });
}

export function fetchShippingMethods(options = {}) {
  const filter = options.filter ? `filter=${options.filter}` : "";
  return makeActionCreator({
    endpoint: `/shipping_methods/?${filter}`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchShippingMethodsRequest,
    successFunc: fetchShippingMethodsSuccess,
    errorFunc: fetchShippingMethodsError,
    error_message: "Error fetching shipping methods",
  });
}
