import React, {Component} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import Divider from "material-ui/Divider";
import {Link} from "react-router-dom";
import {getArtTitle} from "./BrandIndexTable";

import {tableRowStyle} from "../../../../styles/components/tableStyles";
import ArtRevisionDetails from "./../../Arts/shared/ArtRevisionDetails";
import CNGToolTip from "../../../shared/CNGToolTip";

const cardColumns = {
  padding: 24,
  color: "#747D86",
  fontSize: 14,
  lineHeight: "20px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  cursor: "default",
};

const highlightColumn = {
  ...cardColumns,
  color: "#0C5271",
  fontWeight: "bold",
  lineHeight: "24px",
  cursor: "pointer",
};

const cardStyle = {
  ...tableRowStyle,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 0,
  minHeight: 80,
  height: "auto",
  margin: "8px 0",
  flexWrap: "wrap",
};

export default class ArtRow extends Component {
  static propTypes = {
    art: PropTypes.object,
    renderHeader: PropTypes.func,
  };

  state = {
    isOpen: false,
  };

  handleToggleCard = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  renderColumns = (revision, primaryColumn = true, hasVersions = false) => {
    const {isOpen} = this.state;
    const {brandId, id} = this.props.art;
    const imageTooltip = {
      width: 250,
      border: "1px solid #BDC6CF",
    };

    const {name, adornmentTypeName} = revision;
    return (
      <React.Fragment>
        <div
          style={{
            ...highlightColumn,
            width: "22%",
          }}
        >
          <CNGToolTip
            parentRef={`art${lodash.get(revision, "id")}`}
            style={imageTooltip}
            element={revision}
          >
            <Link
              to={`/brands/${brandId}/arts/${id}/revisions/${revision.id}`}
              className={`no-link-styles ${
                revision.active ? "" : "inactive-art"
              }`}
              title={getArtTitle(revision)}
            >
              {name}
            </Link>
          </CNGToolTip>
        </div>
        <div
          title={adornmentTypeName}
          style={{
            ...cardColumns,
            width: "18%",
          }}
        >
          {adornmentTypeName}
        </div>

        <div
          style={{
            ...cardColumns,
            width: "calc(60% - 100px)",
          }}
        >
          <ArtRevisionDetails artRevision={revision} />
        </div>

        <div
          style={{
            ...cardColumns,
            width: 100,
            textAlign: "right",
          }}
        >
          {primaryColumn &&
            hasVersions && (
            <FontIcon
              className="material-icons"
              style={{
                color: "black",
              }}
              onClick={this.handleToggleCard}
            >
              {isOpen ? "unfold_more" : "unfold_less"}
            </FontIcon>
          )}
        </div>

        {primaryColumn && (
          <Divider
            className={"ordershipment_card_divider"}
            style={{
              width: "calc(100% - 48px)",
              marginLeft: 24,
              visibility: isOpen ? "visible" : "hidden",
            }}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    const {isOpen} = this.state;
    const {art} = this.props;
    const {revisions} = art;

    const revision = lodash.first(revisions);
    const versions = lodash.filter(
      revisions,
      ({id}) => Number(id) !== Number(revision.id)
    );

    return (
      <div className="bg-white" style={{paddingBottom: isOpen ? 8 : 0}}>
        <div style={cardStyle} className="order-item-row">
          {this.renderColumns(revision, true, !lodash.isEmpty(versions))}
        </div>
        {isOpen ? (
          <div style={{margin: "0px 24px"}}>
            <p style={{margin: "12px 0px", fontWeight: "normal"}}>Versions</p>
            <table className="w-100">{this.props.renderHeader()}</table>
            {versions.map(version => (
              <div
                key={version.id}
                style={{
                  ...cardStyle,
                  background: "rgba(189, 198, 207, 0.07)",
                  margin: "8px 0",
                  minHeight: "none",
                }}
                className="order-item-row w-100"
              >
                {this.renderColumns(version, false)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
