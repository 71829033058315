import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SelectField from "material-ui/SelectField";
import FontIcon from "material-ui/FontIcon";
import Fuse from "fuse.js";

import MenuItem from "../../../../shared/CNGMenuItem";
import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";
import TextField from "../../../shared/DebouncedTextField";
import BottleCapacityDropdown from "./BottleCapacityDropdown";
import Product from "./Product";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 4,
  keys: ["itemNumber", "productName"],
};

class ChooseProduct extends Component {
  state = {
    search: "",
    selectedType: null,
    selectedCapacity: [null],
  };

  componentDidUpdate(prevProps){
    const {capacities} = this.props;
    if (prevProps.capacities !== capacities) {
      const items = capacities.map(({id})=> id);

      this.setState({
        selectedCapacity: items
      });
    }
  }

  componentDidMount() {
    const {
      item: {categoryId, product},
      fetchProducts,
      fetchBagCapacities,
    } = this.props;
    let filter = `active,eq,true|product_category_id,eq,${categoryId}`;
    if (product && product.bagCapacity) {
      filter += `|bagType.id,eq,${product.bagCapacity.bagTypeId}`;
      fetchBagCapacities({
        sort: "capacity_number",
        sortDirection: "ASC",
        filter: `bag_type_id,eq,${product.bagCapacity.bagTypeId}`,
      });
      this.setState({
        selectedType: `${product.bagCapacity.bagTypeId}`,
      });
    }
    fetchProducts(this.getFetchProductsOptions({filter}));
  }

  renderOptions = options => {
    return [
      <MenuItem key={0} value={null} primaryText="All" />,
      ...options.map(option => (
        <MenuItem key={option.id} value={option.id} primaryText={option.name} />
      )),
    ];
  };

  getFetchProductsOptions = opts => ({
    include: "[productImages,bagCapacity]",
    sort: "item_number",
    sortDirection: "ASC",
    delay: 300,
    ...opts,
  });

  handleOnChange = name => (event, index, value) => {
    const {category, fetchBagCapacities, fetchProducts, onChange} = this.props;
    const {[name]: currentValue} = this.state;
    let productFilter = `active,eq,true|product_category_id,eq,${category.id}`;
    const newState = {[name]: value, search: ""};
    if (name === "selectedType") {
      if (currentValue === value) return;
      newState.selectedCapacity = [null];
      let filter;
      if (value) {
        filter = `bag_type_id,eq,${value}`;
        productFilter += `|bagType.id,eq,${value}`;
      }
      fetchBagCapacities({
        filter,
        sort: "capacity_number",
        sortDirection: "ASC",
      });
    }
    if (name === "selectedCapacity") {
      const allIndex = value.findIndex(value => !value);
      if (
        value.length === 0 ||
        (value.length > 0 && allIndex === value.length - 1)
      ) {
        const {selectedType} = this.state;
        newState.selectedCapacity = [null];
        productFilter += `|bagType.id,eq,${selectedType}`;
      } else {
        if (allIndex >= 0) {
          value = [...value.slice(0, allIndex), ...value.slice(allIndex + 1)];
        }
        newState.selectedCapacity = value;
        productFilter += `|bagCapacity.id,in,(${value.join(";")})`;
      }
    }
    onChange("product")({target: {}}, null);

    fetchProducts(this.getFetchProductsOptions({filter: productFilter}));
    this.setState(newState);
  };

  handleSearchChange = (_, search) => this.setState({search});

  getProducts = () => {
    const {products} = this.props;
    if (!products) return [];
    const {search} = this.state;
    if (!search) return products;
    const fuse = new Fuse(products, fuseOptions);
    return fuse.search(search);
  };

  render() {
    const {
      item,
      onChange,
      pageStyle,
      category,
      types,
      capacities,
      isLoading,
    } = this.props;
    const {selectedType, selectedCapacity, search} = this.state;
    const products = this.getProducts();
    return (
      <Fragment>
        <Typography variant="h1" style={pageStyle.title}>
          Choose a Product
        </Typography>
        <div className="flex">
          {category &&
            category.hasBagFilters && (
            <Fragment>
              <SelectField
                name="bagType"
                floatingLabelFixed
                floatingLabelText="Type of Bag"
                onChange={this.handleOnChange("selectedType")}
                value={selectedType}
                iconStyle={pageStyle.dropdownIcon}
                menuStyle={{borderRadius: "0px !important"}}
              >
                {this.renderOptions(types)}
              </SelectField>
              <BottleCapacityDropdown
                name="bagCapacity"
                disabled={selectedType === null}
                floatingLabelText="Bottle Capacity"
                onChange={this.handleOnChange("selectedCapacity")}
                selectedCapacity={selectedCapacity}
                values={capacities}
                style={{marginLeft: 16}}
                iconStyle={pageStyle.dropdownIcon}
                menuStyle={{borderRadius: "20px !important"}}
              >
              </BottleCapacityDropdown>
            </Fragment>
          )}
          <div className="position-relative">
            <TextField
              floatingLabelFixed
              floatingLabelText="Search"
              initialValue={search}
              onChange={this.handleSearchChange}
              style={{marginLeft: 16}}
            />
            <FontIcon className="material-icons right-position-absolute">
              search
            </FontIcon>
          </div>
        </div>
        <div
          className={classNames("item-list", {
            "item-list-with-filter": category.hasBagFilters,
          })}
        >
          {products.map(product => (
            <Product
              key={product.id}
              product={product}
              selectedItem={item}
              isLoading={isLoading}
              pageStyle={pageStyle}
              onChange={onChange("product")}
            />
          ))}
          {!isLoading &&
            products.length === 0 && (
            <Typography variant="h2" style={{marginLeft: 16}}>
              {search
                ? "There are no products that match the search criteria."
                : "There are no products in this category yet."}
            </Typography>
          )}
          {isLoading &&
            products.length === 0 &&
            [0, 1, 2, 3, 4, 5, 6, 7].map(_ => (
              <div key={_} className="item loading">
                <div className="item-container">
                  <div className="item-image">
                    <CNGImage style={pageStyle.image} />
                  </div>
                </div>
                <Typography variant="body" style={pageStyle.name}>
                  itemNumber
                </Typography>
                <Typography variant="h5" style={pageStyle.name}>
                  productName
                </Typography>
              </div>
            ))}
        </div>
      </Fragment>
    );
  }
}

ChooseProduct.propTypes = {
  item: PropTypes.object,
  category: PropTypes.object,
  onChange: PropTypes.func,
  fetchProducts: PropTypes.func,
  fetchBagCapacities: PropTypes.func,
  products: PropTypes.array,
  types: PropTypes.array,
  capacities: PropTypes.array,
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
};

export default ChooseProduct;
