import React, {Component} from "react";
import PropTypes from "prop-types";

const styles = {
  color: "#929DA8",
  fontSize: 12,
  fontWeight: "bold",
  lineHeight: "16px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
};

export default class SectionHeading extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginRight: PropTypes.number,
    marginLeft: PropTypes.number,
    textTransform: PropTypes.string,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.string,
    fontColor: PropTypes.string,
  };

  render() {
    const {
      text,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      textTransform,
      fontSize,
      fontWeight,
      fontColor,
    } = this.props;
    return (
      <p
        style={{
          ...styles,
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
          textTransform: textTransform || styles.textTransform,
          fontSize: fontSize || styles.fontSize,
          fontWeight: fontWeight || styles.fontWeight,
          color: fontColor || styles.color,
        }}
      >
        {text}
      </p>
    );
  }
}
