import React, {Component} from "react";
import PropTypes from "prop-types";

import {LayoutPropTypes} from "./LayoutProps";

export default class Layout extends Component {
  static propTypes = {
    ...LayoutPropTypes,
    className: PropTypes.string,
  };

  render() {
    const {
      margin,
      marginHorizontal,
      marginVertical,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      padding,
      paddingHorizontal,
      paddingVertical,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
      display,
      flex,
      flexDirection,
      justifyContent,
      alignItems,
      width,
      maxWidth,
      minWidth,
      height,
      maxHeight,
      minHeight,
      className,
      style,
      children,
    } = this.props;
    return (
      <div
        className={className}
        style={{
          margin,
          marginTop: marginVertical ? marginVertical : marginTop,
          marginRight: marginHorizontal ? marginHorizontal : marginRight,
          marginLeft: marginHorizontal ? marginHorizontal : marginLeft,
          marginBottom: marginVertical ? marginVertical : marginBottom,
          padding,
          paddingTop: paddingVertical ? paddingVertical : paddingTop,
          paddingRight: paddingHorizontal ? paddingHorizontal : paddingRight,
          paddingLeft: paddingHorizontal ? paddingHorizontal : paddingLeft,
          paddingBottom: paddingVertical ? paddingVertical : paddingBottom,
          display,
          flex,
          flexDirection,
          justifyContent,
          alignItems,
          width,
          maxWidth,
          minWidth,
          height,
          maxHeight,
          minHeight,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
}
