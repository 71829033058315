import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import AddressBookTab from "../../pages/Companies/Components/AddressBook/AddressBookTab";
import {
  fetchWalletMembers,
  createWalletMember,
  archiveCompanyContact,
} from "../../../actions/companyActions";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWalletMembers: bindActionCreators(fetchWalletMembers, dispatch),
    archiveCompanyContact: bindActionCreators(archiveCompanyContact, dispatch),
    createWalletMember: bindActionCreators(createWalletMember, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressBookTab)
);
