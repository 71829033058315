import queryString, {stringify} from "query-string";
import AuthService from "../services/AuthService";
const authService = new AuthService();

import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import {objectToQueryString} from "../utils/object";
import RequestService from "../services/RequestService";
import {push} from "react-router-redux";

const fetchOrdersRequest = () => makeCommonAction(types.FETCH_ORDERS_REQUEST);
const fetchOrdersSuccess = payload =>
  makeCommonAction(types.FETCH_ORDERS_SUCCESS, payload);
const fetchOrdersError = error =>
  makeCommonAction(types.FETCH_ORDERS_ERROR, error, true);

const fetchDSODOrdersRequest = () =>
  makeCommonAction(types.FETCH_DSOD_ORDERS_REQUEST);
const fetchDSODOrdersSuccess = payload =>
  makeCommonAction(types.FETCH_DSOD_ORDERS_SUCCESS, payload);
const fetchDSODOrdersError = error =>
  makeCommonAction(types.FETCH_DSOD_ORDERS_ERROR, error, true);

const fetchOpenOrdersRequest = () =>
  makeCommonAction(types.FETCH_OPEN_ORDERS_REQUEST);
const fetchOpenOrdersSuccess = payload =>
  makeCommonAction(types.FETCH_OPEN_ORDERS_SUCCESS, payload);
const fetchOpenOrdersError = error =>
  makeCommonAction(types.FETCH_OPEN_ORDERS_ERROR, error, true);

const fetchUserOpenOrdersRequest = () =>
  makeCommonAction(types.FETCH_USER_OPEN_ORDERS_REQUEST);
const fetchUserOpenOrdersSuccess = payload =>
  makeCommonAction(types.FETCH_USER_OPEN_ORDERS_SUCCESS, payload);
const fetchUserOpenOrdersError = error =>
  makeCommonAction(types.FETCH_USER_OPEN_ORDERS_ERROR, error, true);

const fetchOrderRequest = () => makeCommonAction(types.FETCH_ORDER_REQUEST);
const fetchOrderSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_SUCCESS, payload);
const fetchOrderError = error =>
  makeCommonAction(types.FETCH_ORDER_ERROR, error, true);

const fetchOrderItemsRequest = () =>
  makeCommonAction(types.FETCH_ORDER_ITEMS_REQUEST);
const fetchOrderItemsSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_ITEMS_SUCCESS, payload);
const fetchOrderItemsError = error =>
  makeCommonAction(types.FETCH_ORDER_ITEMS_ERROR, error, true);

const fetchOrderItemRequest = () =>
  makeCommonAction(types.FETCH_ORDER_ITEM_REQUEST);
const fetchOrderItemSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_ITEM_SUCCESS, payload);
const fetchOrderItemError = error =>
  makeCommonAction(types.FETCH_ORDER_ITEM_ERROR, error, true);

const updateOrderRequest = () => makeCommonAction(types.UPDATE_ORDER_REQUEST);
const updateOrderSuccess = payload =>
  makeCommonAction(types.UPDATE_ORDER_SUCCESS, payload);
const updateOrderError = error =>
  makeCommonAction(types.UPDATE_ORDER_ERROR, error, true);

const updateOrderItemRequest = () =>
  makeCommonAction(types.UPDATE_ORDER_ITEM_REQUEST);
const updateOrderItemSuccess = payload =>
  makeCommonAction(types.UPDATE_ORDER_ITEM_SUCCESS, payload);
const updateOrderItemError = error =>
  makeCommonAction(types.UPDATE_ORDER_ITEM_ERROR, error, true);

const createOrderItemRequest = () =>
  makeCommonAction(types.CREATE_ORDER_ITEM_REQUEST);
const createOrderItemSuccess = payload =>
  makeCommonAction(types.CREATE_ORDER_ITEM_SUCCESS, payload);
const createOrderItemError = error =>
  makeCommonAction(types.CREATE_ORDER_ITEM_ERROR, error, true);

const fetchOrderStatusRequest = () =>
  makeCommonAction(types.FETCH_ORDER_STATUS_REQUEST);
const fetchOrderStatusSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_STATUS_SUCCESS, payload);
const fetchOrderStatusError = error =>
  makeCommonAction(types.FETCH_ORDER_STATUS_REQUEST, error, true);

const createOrderRequest = () => makeCommonAction(types.CREATE_ORDER_REQUEST);
const createOrderSuccess = payload =>
  makeCommonAction(types.CREATE_ORDER_SUCCESS, payload);
const createOrderError = error =>
  makeCommonAction(types.CREATE_ORDER_ERROR, error, true);

const archiveOrderItemRequest = () =>
  makeCommonAction(types.DELETE_ORDER_ITEM_REQUEST);
const archiveOrderItemSuccess = payload =>
  makeCommonAction(types.DELETE_ORDER_ITEM_SUCCESS, payload);
const archiveOrderItemError = error =>
  makeCommonAction(types.DELETE_ORDER_ITEM_ERROR, error, true);

const archiveOrderRequest = () => makeCommonAction(types.ARCHIVE_ORDER_REQUEST);
const archiveOrderSuccess = payload =>
  makeCommonAction(types.ARCHIVE_ORDER_SUCCESS, payload);
const archiveOrderError = error =>
  makeCommonAction(types.ARCHIVE_ORDER_ERROR, error, true);

export function createReorderItem(companyId, items) {
  return async dispatch => {
    const openOrders = await fetchOpenOrders(companyId)(dispatch);
    const openOrder = openOrders.data.find(
      openOrder =>
        Number(openOrder.companyId) === Number(companyId) &&
        openOrder.isDsod === false
    );

    if (!openOrder) {
      await createOrder(
        companyId,
        {
          is_open: true,
          is_dsod: false,
          submitted_on: new Date(),
          order_source: "reorder",
          shipment: {
            is_open: true,
          },
        },
        {historicalIds: items.join(",")}, () =>{
          dispatch(
            push({
              pathname: `/companies/${companyId}/reorders`,
              state: {
                reorderItems: items,
              },
            })
          );},
      )(dispatch);
    }else{
      await updateOrder(openOrder.id, {}, {historicalIds: items.join(",")}, () => {
        dispatch(
          push({
            pathname: `/companies/${companyId}/reorders`,
            state: {
              reorderItems: items,
            },
          })
        );},
      )(dispatch);
    }

  };
}

export function fetchOrdersByCompany(
  company_id,
  include = "[items.inventoryHistories.inventoryItem.order,shipments.[items,shipmentAccount],status]",
  options = {}
) {
  const url = RequestService.buildIndexUrl(
    `ordersbycompany/${company_id}`,
    include,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrdersRequest,
    successFunc: fetchOrdersSuccess,
    errorFunc: fetchOrdersError,
    error_message: "Error fetching orders",
  });
}

export function searchOrdersByCompany(
  company_id,
  query,
  include = "[items,shipments.[items,shipmentAccount],status]",
  options = {}
) {
  const url = RequestService.buildIndexUrl(
    `ordersbycompany/${company_id}/search?search_term=${query}`,
    include,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrdersRequest,
    successFunc: fetchOrdersSuccess,
    errorFunc: fetchOrdersError,
    error_message: "Error fetching orders",
  });
}

export function searchOrders(query, include, options = {}) {
  const url = RequestService.buildIndexUrl(
    `orders/search?search_term=${query}`,
    include,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrdersRequest,
    successFunc: fetchOrdersSuccess,
    errorFunc: fetchOrdersError,
    error_message: "Error fetching orders",
  });
}

export function searchDSODOrders(query, include, options = {}) {
  const url = RequestService.buildIndexUrl(
    `orders/dsod/search?search_term=${query}`,
    include,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchDSODOrdersRequest,
    successFunc: fetchDSODOrdersSuccess,
    errorFunc: fetchDSODOrdersError,
    error_message: "Error fetching orders",
  });
}

export function fetchOrder(id) {
  return makeActionCreator({
    endpoint: `/orders/${id}/?include=[paymentTerm,billingCompanyContact.[country],items(notDeleted).[artThreadColorAssignment.artRevision.artSource.brand,orderItemProduct(notDeleted).[orderItemProductAdornmentLocationArtAssignments.[art.artSource,productAdornmentLocation.adornmentLocation],product,fabricColor.[fabric,color]]],status,createdUser]`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrderRequest,
    successFunc: fetchOrderSuccess,
    errorFunc: fetchOrderError,
    error_message: "Error fetching order",
  });
}

export function fetchOrderItems(orderId, options = {}) {
  const url = RequestService.buildIndexUrl(
    `orders/${orderId}/orderitems`,
    "[product.productImages,orderItemType,orderItemProduct.[orderItemProductAdornmentLocationArtAssignments.[art,productAdornmentLocation.adornmentLocation],art.artSource.brand]" +
      ",fabricColor.[fabric,color],leatherColor.color,inventoryHistories.inventoryItem.order]",
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrderItemsRequest,
    successFunc: fetchOrderItemsSuccess,
    errorFunc: fetchOrderItemsError,
    error_message: "Error fetching order items",
  });
}

export function fetchOrderItem(
  id,
  include = "[artThreadColorAssignment.artRevision.artSource]"
) {
  return makeActionCreator({
    endpoint: `/orderitems/${id}/?include=${include}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrderItemRequest,
    successFunc: fetchOrderItemSuccess,
    errorFunc: fetchOrderItemError,
    error_message: "Error fetching order item",
  });
}

export function fetchOrderItemByProduct(id) {
  return makeActionCreator({
    endpoint: `/orderitems/?include=[art,fabricColor,order.[shipments,company]]&filter=product_id,eq,${id}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrderItemRequest,
    successFunc: fetchOrderItemSuccess,
    errorFunc: fetchOrderItemError,
    error_message: "Error fetching order item",
  });
}

export function updateOrder(id, orderData, extraParams, callback, errorCallback) {
  return makeActionCreator({
    endpoint: `/orders/${id}?include=[quote,paymentTerm,status,createdUser,billingCompanyContact.[country],items]&${stringify(
      extraParams
    )}`,
    fetchOptions: {
      body: orderData,
      method: "PUT",
    },
    requestFunc: updateOrderRequest,
    successFunc: () => {
      callback && callback();
      return updateOrderSuccess(id);
    },
    errorFunc: error => {
      errorCallback && errorCallback(error);
      return updateOrderError(error);
    },
    error_message: "Error updating Order",
  });
}

export function createOrderItem(companyId, orderItemData, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/order_items`,
    fetchOptions: {
      body: orderItemData,
      method: "POST",
    },
    requestFunc: createOrderItemRequest,
    successFunc: () => {
      callback && callback();
      return createOrderItemSuccess();
    },
    errorFunc: createOrderItemError,
    successMessage: "Order Item saved successfully",
    error_message: "Error creating Order Item",
  });
}

export function updateOrderItem(id, orderItemData, callback) {
  return makeActionCreator({
    endpoint: `/orderitems/${id}`,
    fetchOptions: {
      body: orderItemData,
      method: "PUT",
    },
    requestFunc: updateOrderItemRequest,
    successFunc: () => {
      callback && callback();
      return updateOrderItemSuccess();
    },
    errorFunc: updateOrderItemError,
    successMessage: "Order Item updated successfully",
    error_message: "Error updating Order Item",
  });
}

export function updateOpenOrder(id, orderData) {
  return makeActionCreator({
    endpoint: `/orders/${id}?include=[status,createdUser,billingCompanyContact.[country],items.orderItemType,paymentTerm,quote]`,
    fetchOptions: {
      body: orderData,
      method: "PUT",
    },
    requestFunc: updateOrderRequest,
    successFunc: updateOrderSuccess,
    errorFunc: updateOrderError,
    error_message: "Error updating Order",
  });
}

export function fetchOrderStatus() {
  return makeActionCreator({
    endpoint: "/orderstatuses?sort=status_step",
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOrderStatusRequest,
    successFunc: fetchOrderStatusSuccess,
    errorFunc: fetchOrderStatusError,
    error_message: "Error fetching order status",
  });
}

export function fetchCurrentOpenOrders() {
  return makeActionCreator({
    endpoint: "/orders/open?include=[company,createdUser.country,quote]",
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUserOpenOrdersRequest,
    successFunc: fetchUserOpenOrdersSuccess,
    errorFunc: fetchUserOpenOrdersError,
    error_message: "Error fetching current open orders status",
  });
}

export function createOrder(companyId, orderData, extraParams = {}, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/orders?${stringify(extraParams)}`,
    fetchOptions: {
      body: orderData,
      method: "POST",
    },
    requestFunc: createOrderRequest,
    errorFunc: createOrderError,
    successFunc: () => {
      callback && callback();
      return createOrderSuccess();
    },
    error_message: "Error creating Order",
  });
}

export function fetchOpenOrders(companyId, params) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/orders/open?${objectToQueryString(
      params
    )}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchOpenOrdersRequest,
    successFunc: fetchOpenOrdersSuccess,
    errorFunc: fetchOpenOrdersError,
    error_message: "Error fetching open orders",
  });
}

export function archiveOrderItem(id) {
  return makeActionCreator({
    endpoint: `/orderitems/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: archiveOrderItemRequest,
    successFunc: archiveOrderItemSuccess,
    errorFunc: archiveOrderItemError,
    error_message: "Error deleteing order item",
  });
}

export function archiveOrder(id, callback) {
  return makeActionCreator({
    endpoint: `/orders/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: archiveOrderRequest,
    successFunc: () => {
      callback && callback();
      return archiveOrderSuccess(id);
    },
    errorFunc: archiveOrderError,
    error_message: "Error deleteing order item",
    successMessage: "Order has been archived successfully",
  });
}

export function revertOrder(id, callback) {
  return makeActionCreator({
    endpoint: `/orders/${id}/revert`,
    fetchOptions: {
      method: "PUT",
    },
    requestFunc: updateOrderRequest,
    successFunc: () => {
      callback && callback();
      return updateOrderSuccess(id);
    },
    errorFunc: updateOrderError,
    successMessage: "Order has been reverted successfully",
    error_message: "Error updating Order",
  });
}

export function approveOrder(id, callback) {
  return makeActionCreator({
    endpoint: `/orders/${id}/approve`,
    fetchOptions: {
      method: "PUT",
    },
    requestFunc: updateOrderRequest,
    successFunc: () => {
      callback && callback();
      return updateOrderSuccess(id);
    },
    errorFunc: updateOrderError,
    successMessage: "Order has been approved successfully",
    error_message: "Error updating Order",
  });
}

export function printOrderDocuments(companyId, orderId, options = {}) {
  const token = authService.getToken();
  const baseUrl = `${process.env.API_DOMAIN}:${process.env.API_PORT}`;
  let url = `${baseUrl}/companies/${companyId}/orders/${orderId}/print?${queryString.stringify(
    options
  )}`;

  const headers = {
    Accept: "application/pdf",
    Authorization: `JWT ${token}`,
    "content-type": "application/pdf",
  };

  return fetch(url, {
    headers,
    method: "GET",
  })
    .then(response => {
      return (response.blob && response.blob()) || response;
    })
    .then(file => {
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    });
}

export const fetchOrdersUnreadCounts = counts => dispatch =>
  dispatch({type: types.FETCH_ORDERS_UNREAD_COUNTS, payload: counts});
