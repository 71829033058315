import {Deserializer as JSONAPIDeserializer} from "jsonapi-serializer";
import changeCaseKeys from "change-case-keys";
import _ from "lodash";

import AuthService from "../services/AuthService";
const authenticationService = new AuthService();

const deserializer = new JSONAPIDeserializer({keyForAttribute: "camelCase"})
  .deserialize;

export default function fetchAPI(options, dispatch) {
  const {
    types,
    url,
    payload = {},
    headers = {},
    method,
    body,
    delay = 0,
  } = options;

  const defaultHeaders = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/json",
  };

  const authenticationHeader = authenticationService.getToken() ? {
    Authorization: "JWT " + authenticationService.getToken(),
  } : {};

  const hasValidActionTypes =
    !Array.isArray(types) ||
    types.length !== 3 ||
    !types.every(type => typeof type === "string");

  if (hasValidActionTypes) {
    throw new Error("Expected an array of three string types.");
  }

  const [requestType, successType, failureType] = types;

  const hooks = {};

  function handleResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      hooks[successType] && hooks[successType](response.headers);
      return response;
    }
    return response.json().then(payload => {
      return new Promise((resolve, reject) => {
        reject(payload);
      });
    });
  }

  dispatch({
    type: requestType,
    payload,
  });

  function formatPayload(response) {
    if (response.status === 204) return "";
    return response.json().then(async payload => {
      const {meta} = payload;
      return {meta, payload: await deserializer(payload)};
    });
  }

  function formatBody(body) {
    if (body instanceof FormData) return body;
    return JSON.stringify(changeCaseKeys(_.clone(body), "underscored"));
  }

  if (options && options.contentType === false) {
    delete defaultHeaders["Content-Type"];
  }

  return Promise.all([
    fetch(`${process.env.API_DOMAIN}:${process.env.API_PORT}` + url, {
      headers: {...defaultHeaders, ...headers, ...authenticationHeader},
      method,
      body: formatBody(body),
    }),
    new Promise(resolve => setTimeout(resolve, delay)),
  ])
    .then(([response]) => handleResponse(response))
    .then(formatPayload)
    .then(({payload, meta}) => {
      dispatch({
        type: successType,
        payload,
        meta,
      });
      return payload;
    })
    .catch(error => {
      let errorText = _.get(error, "message", error);
      dispatch({
        error: errorText,
        type: failureType,
      });
    });
}
