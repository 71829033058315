import React, {Component} from "react";
import PropTypes from "prop-types";

import SelectField from "material-ui/SelectField";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import SectionHeading from "../../../shared/SectionHeading";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import StateSelect from "../../../shared/StateSelect";

import {
  formStyles,
  SelectFieldIconStyle,
} from "../../../../styles/components/formStyles";

import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";
import {isCountryUSAOrCanada} from "../../../../utils/countryRegionsHelper";
import {USA_COUNTRY_ID} from "../../../../constants/country";
import PhoneNumberInput from "../../../shared/PhoneNumberInput";

export default class CompanyForm extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    countries: PropTypes.array,
    states: PropTypes.array,
    paymentTerms: PropTypes.array.isRequired,
    businesses: PropTypes.array.isRequired,
    discounts: PropTypes.array.isRequired,

    state: PropTypes.string,
    country_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payment_term_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    business_category_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    discount_category_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    resale_number: PropTypes.string,
    company_name: PropTypes.string,

    user_last_name: PropTypes.string,
    user_first_name: PropTypes.string,
    phone_number: PropTypes.string,
    email_address: PropTypes.string,
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    city: PropTypes.string,
    zip_code: PropTypes.string,

    company: PropTypes.object,
    isEdit: PropTypes.bool,
    primary_user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,
    errors: PropTypes.object,
    submitted: PropTypes.bool,
    users: PropTypes.array,
    send_primary_user_notification: PropTypes.bool,
    userAssociations: PropTypes.array,
  };
  updateValidations = {
    company_name: {
      format: "flexibleName",
      length: [1, 60],
      nullable: false,
    },
    resale_number: {
      format: "resaleNumber",
      nullable: true,
    },
    payment_term_id: {
      format: "integer",
    },
    business_category_id: {
      format: "integer",
    },
    discount_category_id: {
      format: "integer",
    },
  };
  createValidations = {
    ...this.updateValidations,
    user_first_name: {
      format: "name",
      length: [2, 30],
    },
    user_last_name: {
      format: "name",
      length: [2, 30],
    },
    phone_number: {
      format: "any",
      length: [10],
    },
    email_address: {
      format: "email",
    },
    address_1: {
      format: "any",
      length: [1, 255],
    },
    address_2: {
      format: "any",
      length: [1, 255],
      nullable: true,
    },
    city: {
      format: "name",
      length: [2, 30],
    },
    state: {
      format: "any",
    },
    zip_code: {
      format: "any",
    },
    country_id: {
      format: "integer",
    },
  };
  validations = this.props.isEdit
    ? this.updateValidations
    : this.createValidations;
  state = {
    errors: {},
    submitted: false,
  };

  componentWillReceiveProps(nextProps) {
    const {submitted} = this.state;
    this.setState(
      {
        submitted: nextProps.submitted,
      },
      () => {
        if (nextProps.submitted && !submitted) {
          const errors = this._validate();
          const valid = Object.keys(errors).length === 0;
          if (valid) {
            this.props.submitSuccess();
          } else {
            this.props.submitError();
          }
        }
      }
    );
  }

  isSelectedUSA() {
    const {country_id} = this.props;
    return Number(country_id) === USA_COUNTRY_ID;
  }

  _validate() {
    const {country_id} = this.props;
    if (this.isSelectedUSA()) {
      this.createValidations["zip_code"] = {
        format: "integer",
        shouldMatch: value => {
          return value && value.length === 5;
        },
        shouldMatchMessage: "invalid zipcode should be 5 characters long",
      };
      this.createValidations["state"] = {
        format: "any",
      };
    } else {
      this.createValidations["zip_code"] = {
        format: "any",
        nullable: true,
      };
      this.createValidations["state"] = {
        format: "any",
        nullable: true,
      };
    }
    if (isCountryUSAOrCanada(country_id)) {
      this.createValidations["phone_number"] = {
        format: "any",
        length: [10],
      };
    } else {
      this.createValidations["phone_number"] = {
        format: "any",
      };
    }
    const errors = validateForm(this);
    this.setState({errors});

    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  _renderPrimaryContact() {
    const {countries, country_id} = this.props;
    const selectedCountry = countries.find(
      country => Number(country.id) === country_id
    );
    const countryCode = selectedCountry ? selectedCountry.code : "US";
    return (
      <div>
        <SectionHeading text={"PRIMARY USER"} marginBottom={9} marginTop={40} />
        <div className="usercreate_row">
          <ValidableTextField
            name="user_first_name"
            label="First Name"
            value={this.props.user_first_name}
            errorText={this.state.errors.user_first_name}
            handleChange={(e, value) =>
              this.props.handleChange(e, null, value, "user_first_name")
            }
            validate={() => this._validateDebounced()}
            style={{
              ...formStyles.TwoTextFieldStyles,
              marginRight: 48,
            }}
            inputStyle={formStyles.TwoTextInputStyles}
          />
          <ValidableTextField
            name="user_last_name"
            label="Last Name"
            value={this.props.user_last_name}
            errorText={this.state.errors.user_last_name}
            handleChange={(e, value) =>
              this.props.handleChange(e, null, value, "user_last_name")
            }
            validate={() => this._validateDebounced()}
            style={{
              ...formStyles.TwoTextFieldStyles,
              marginRight: 48,
            }}
            inputStyle={formStyles.TwoTextInputStyles}
          />
          <ValidableTextField
            name="email_address"
            label="Email"
            value={this.props.email_address}
            errorText={this.state.errors.email_address}
            handleChange={(e, value) => this.props.handleChange(e, value)}
            validate={() => this._validateDebounced()}
            style={formStyles.TwoTextFieldStyles}
            inputStyle={formStyles.TwoTextInputStyles}
          />
        </div>
        <div className="usercreate_row">
          <ValidableSelect
            name="country_id"
            label="Country"
            value={this.props.country_id && this.props.country_id.toString()}
            errorText={this.state.errors.country_id}
            handleChange={(e, index, value) => {
              this.props.handleChange(e, index, Number(value), "country_id");
              this.props.handleChange(e, null, null, "state");
            }}
            options={countries && this._getCountrySelectOptions()}
            style={{
              ...formStyles.HalfFieldStyles,
              marginRight: 32,
            }}
            inputStyle={formStyles.HalfFieldStyles}
            iconStyle={formStyles.CountryIconStyles}
          />
          <PhoneNumberInput
            name="phone_number"
            label="Phone"
            value={this.props.phone_number}
            countryCode={countryCode}
            errorText={this.state.errors.phone_number}
            style={formStyles.PhoneNumberTextFieldStyles}
            inputStyle={formStyles.TwoTextFieldStyles}
            handleChange={(e, value) =>
              this.props.handleChange(
                e,
                null,
                value.replace(/\D/g, ""),
                "phone_number"
              )
            }
            validate={() => this._validateDebounced()}
          />
        </div>
        <div className="usercreate_row">
          <ValidableTextField
            name="address_1"
            label="Address 1"
            value={this.props.address_1}
            errorText={this.state.errors.address_1}
            handleChange={(e, value) => this.props.handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
          <ValidableTextField
            name="address_2"
            label="Address 2"
            value={this.props.address_2}
            errorText={this.state.errors.address_2}
            handleChange={(e, value) => this.props.handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
        </div>
        {this._renderLocationRow(countryCode)}
      </div>
    );
  }

  _getCountrySelectOptions() {
    const {countries} = this.props;
    return countries.reduce((countryOptions, country) => {
      const countryElement = (
        <CNGMenuItem
          key={country.id}
          value={country.id}
          primaryText={country.name}
        />
      );
      if (["USA", "US"].includes(country.code)) {
        return [
          countryElement,
          <Divider key={"usa_divider"} />,
          ...countryOptions,
        ];
      }
      countryOptions.push(countryElement);
      return countryOptions;
    }, []);
  }

  _renderLocationRow(countryCode) {
    return (
      <div className="usercreate_row">
        <ValidableTextField
          name="city"
          label="City"
          value={this.props.city}
          errorText={this.state.errors.city}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
          style={formStyles.CityTextFieldStyles}
          inputStyle={formStyles.HalfFieldStyles}
        />
        <StateSelect
          countryCode={countryCode}
          value={this.props.state}
          errorText={this.state.errors.state}
          handleChange={this.props.handleChange}
          style={formStyles.halfSelectStyles}
          inputStyle={formStyles.halfSelectInputStyles}
          floatingLabelStyle={formStyles.halfSelectFloatingLabelStyles}
          menuStyle={formStyles.halfSelectMenuInputStyles}
          listStyle={formStyles.halfSelectMenuListStyles}
          iconStyle={{
            ...formStyles.StateIconStyles,
            right: -5,
          }}
        />
        <ValidableTextField
          name="zip_code"
          label="Zip Code"
          value={this.props.zip_code}
          errorText={this.state.errors.zip_code}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
          style={formStyles.PostCodeStyles}
          inputStyle={formStyles.PostCodeInputStyles}
        />
      </div>
    );
  }
  _renderSelectPrimaryContact() {
    const {primary_user_id, company} = this.props;
    if (!company) return;
    const userIds = company.userAssociations.map(({userId}) => Number(userId));
    const users = company.users.filter(({id}) => userIds.includes(Number(id)));

    return (
      <div>
        <SectionHeading
          text={"PRIMARY COMPANY USER"}
          marginBottom={9}
          marginTop={40}
        />
        <SelectField
          floatingLabelText="Name"
          value={String(primary_user_id)}
          name="primary_user_id"
          onChange={(event, index, value) =>
            this.props.handleChange(event, index, value, "primary_user_id")
          }
          style={formStyles.TwoTextFieldStyles}
          inputStyle={formStyles.inputStyle}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          iconStyle={{
            ...SelectFieldIconStyle,
            right: -12,
          }}
          underlineStyle={formStyles.underlineStyle}
        >
          {users &&
            users.map(({id, name}) => {
              return <CNGMenuItem key={id} value={id} primaryText={name} />;
            })}
        </SelectField>
      </div>
    );
  }
  _renderCompanyFields() {
    return (
      <div className="usercreate_card_body">
        <SectionHeading text={"GENERAL INFO"} marginBottom={0} />
        <div className="usercreate_row">
          <ValidableTextField
            name="company_name"
            label="Company Name"
            value={this.props.company_name}
            errorText={this.state.errors.company_name}
            handleChange={(e, value) => this.props.handleChange(e, value)}
            validate={() => this._validateDebounced()}
            style={formStyles.DoubleTextFieldStyles}
            inputStyle={formStyles.DoubleTextInputStyles}
            autoFocus={true}
          />
        </div>
        <div className="usercreate_row">
          <ValidableTextField
            name="resale_number"
            label="CA Resale Number"
            value={this.props.resale_number}
            errorText={this.state.errors.resale_number}
            handleChange={(e, value) => this.props.handleChange(e, value)}
            validate={() => this._validateDebounced()}
            style={{
              ...formStyles.HalfFieldStyles,
              marginRight: 32,
            }}
            inputStyle={formStyles.HalfTextInputStyles}
          />
          <ValidableSelect
            name="payment_term_id"
            label="Payment Terms"
            value={
              this.props.payment_term_id &&
              this.props.payment_term_id.toString()
            }
            errorText={this.state.errors.payment_term_id}
            handleChange={(e, index, value) =>
              this.props.handleChange(
                e,
                index,
                Number(value),
                "payment_term_id"
              )
            }
            options={
              this.props.paymentTerms &&
              this.props.paymentTerms.map(paymentTerm => {
                return (
                  <CNGMenuItem
                    key={paymentTerm.id}
                    value={paymentTerm.id}
                    primaryText={paymentTerm.name}
                  />
                );
              })
            }
            style={{
              ...formStyles.HalfFieldStyles,
              marginRight: 32,
            }}
            inputStyle={formStyles.HalfTextInputStyles}
            iconStyle={formStyles.HalfIconStyles}
            menuItemStyle={formStyles.wrappedMenuItemStyle}
          />
          <ValidableSelect
            name="business_category_id"
            label="Category"
            value={
              this.props.business_category_id &&
              this.props.business_category_id.toString()
            }
            errorText={this.state.errors.business_category_id}
            handleChange={(e, index, value) =>
              this.props.handleChange(
                e,
                index,
                Number(value),
                "business_category_id"
              )
            }
            options={
              this.props.businesses &&
              this.props.businesses.map(business => {
                return (
                  <CNGMenuItem
                    key={business.id}
                    value={business.id}
                    primaryText={business.name}
                  />
                );
              })
            }
            style={{
              ...formStyles.HalfFieldStyles,
              marginRight: 32,
            }}
            inputStyle={formStyles.HalfTextInputStyles}
            iconStyle={formStyles.HalfIconStyles}
          />
          <ValidableSelect
            name="discount_category_id"
            label="Discounts"
            value={
              this.props.discount_category_id &&
              this.props.discount_category_id.toString()
            }
            errorText={this.state.errors.discount_category_id}
            handleChange={(e, index, value) =>
              this.props.handleChange(
                e,
                index,
                Number(value),
                "discount_category_id"
              )
            }
            options={
              this.props.discounts &&
              this.props.discounts.map(discounted => {
                return (
                  <CNGMenuItem
                    key={discounted.id}
                    value={discounted.id}
                    primaryText={discounted.name}
                  />
                );
              })
            }
            style={{
              ...formStyles.HalfFieldStyles,
              marginRight: 32,
            }}
            inputStyle={formStyles.HalfTextInputStyles}
            iconStyle={formStyles.HalfIconStyles}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="user-form">
        {this._renderCompanyFields()}

        <div className="usercreate_card_body">
          {this.props.isEdit
            ? this._renderSelectPrimaryContact()
            : this._renderPrimaryContact()}
          <Checkbox
            label="Communication Preference"
            checked={this.props.send_primary_user_notification || false}
            style={{marginTop: 30}}
            onClick={e => {
              this.props.handleChange(
                e,
                null,
                !this.props.send_primary_user_notification,
                "send_primary_user_notification"
              );
            }}
          />
        </div>
      </div>
    );
  }
}
