import React, {Component} from "react";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";

class MenuHeaderOption extends Component {
  render() {
    const {materialIcon, style, icon, disabled, isActive, onClick} = this.props;
    return (
      <div
        className={`header_nav_link ${disabled ? "disabled" : ""}`}
        style={style}
        onClick={onClick}
      >
        <IconButton
          disabled={disabled}
          style={{width: 48, height: 48, padding: 12}}
        >
          {materialIcon ? (
            <FontIcon
              className="material-icons-outlined header_nav_icon"
              color="white"
            >
              {icon}
            </FontIcon>
          ) : (
            <img
              src={`/assets/imgs/icons/${icon}.svg`}
              className="header_nav_icon"
            />
          )}
          <div
            className={
              "header_menu_pointer_arrow " + (isActive ? "active " : "")
            }
          />
        </IconButton>
      </div>
    );
  }
}

MenuHeaderOption.propTypes = {
  materialIcon: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuHeaderOption;
