import colors from "./colors";
const {primary, gray} = colors;

export default {
  h1: {
    color: primary.light,
    fontSize: 36,
    fontWeight: 300,
    lineHeight: "44px",
  },
  h2: {
    color: primary.light,
    fontSize: 30,
    fontWeight: 300,
    lineHeight: "32px",
  },
  h3: {
    color: primary.light,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "32px",
  },
  h4: {
    color: gray.main,
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "28px",
  },
  h5: {
    color: gray.main,
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "24px",
  },
  subtitle: {
    color: gray.dark,
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "16px",
  },
  subtitleBold: {
    color: gray.dark,
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "16px",
  },
  body: {
    color: gray.dark,
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "20px",
  },
  bodyMedium: {
    color: gray.dark,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "20px",
  },
  bodyBold: {
    color: gray.dark,
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "20px",
  },
  link: {
    color: primary.light,
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "24px",
    cursor: "pointer",
  },
};
