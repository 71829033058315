import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "react-router-dom";

import FormattedPhone from "../../../shared/FormattedPhone";
import FormattedAddress from "../../../shared/FormattedAddress";
import SectionHeading from "../../../shared/SectionHeading";
import ChillButton from "../../../shared/ChillButton";

import Dotdotdot from "react-dotdotdot";

export default class CompanyDetailDetails extends Component {
  static propTypes = {
    countries: PropTypes.array.isRequired,
    company: PropTypes.object,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  _getObjById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == id) {
        return arr[i];
      }
    }
  }
  _renderBrands() {
    const {company} = this.props;
    if (!company) return null;
    const brands = (company.brands || []).reduce((brandList, brand, index) => {
      brandList += index > 0 ? `, ${brand.name}` : brand.name;
      return brandList;
    }, "");
    return (
      <Dotdotdot
        className="company_bot_brands"
        clamp={3}
        splitOnChars={[".", "-", "–", "—", " ", ","]}
      >
        <p className="subtext">{brands}</p>
      </Dotdotdot>
    );
  }

  render() {
    const {company, countries, isLoading} = this.props;

    const {users, userAssociations} = company || {};
    const primaryAssociation = (userAssociations || []).find(
      ({isPrimary}) => isPrimary
    );
    let primaryUser;

    if (primaryAssociation) {
      primaryUser = (users || []).find(
        ({id}) => Number(id) === Number(primaryAssociation.userId)
      );
    }

    const primaryContactInfo = primaryUser && primaryUser.recipient;
    let country;
    if (primaryContactInfo) {
      country = countries.find(
        country => Number(country.id) === primaryUser.recipient.countryId
      );
    }
    return (
      <div className="company_bot_info">
        <div className="company_bot_left">
          <SectionHeading
            text="Primary user"
            marginBottom={9}
            textTransform="none"
            fontSize={16}
            fontColor="#4A5158"
            fontWeight="normal"
          />
          <div className="company_details">
            <div className="company_details_section">
              <div
                className={classNames(
                  {loading: isLoading},
                  {"w-90": isLoading}
                )}
              >
                <span className="detail-heading">Name</span>
                <span className="subtext">
                  {(primaryContactInfo && primaryContactInfo.name) ||
                    "No default wallet user"}
                </span>
              </div>
              <div
                className={classNames(
                  {loading: isLoading},
                  {"w-90": isLoading}
                )}
              >
                <span className="detail-heading">Phone</span>
                <span className="subtext">
                  {primaryContactInfo ? (
                    <FormattedPhone
                      phoneNumber={
                        primaryContactInfo && primaryContactInfo.phoneNumber
                      }
                      countryCode={country && country.code}
                    />
                  ) : (
                    "N/A"
                  )}
                </span>
              </div>
              <div
                className={classNames(
                  {loading: isLoading},
                  {"w-90": isLoading}
                )}
              >
                <span className="detail-heading">Email</span>
                <span className="subtext">
                  {(primaryContactInfo && primaryContactInfo.emailAddress) ||
                    "N/A"}
                </span>
              </div>
            </div>

            <div
              className={classNames(
                "company_details_section",
                {
                  loading: isLoading,
                },
                {"margin-right-5": isLoading}
              )}
            >
              <div className="row flex-no-wrap">
                <p className="detail-heading">Address</p>
                <div className="detail_addressBlock">
                  {primaryContactInfo ? (
                    <FormattedAddress
                      user={primaryContactInfo}
                      countries={countries}
                      multilines
                    />
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company_bot_right">
          <SectionHeading
            text="Brands"
            marginBottom={9}
            textTransform="none"
            fontSize={16}
            fontColor="#4A5158"
            fontWeight="normal"
          />
          <span
            style={{
              display: "inline-block",
            }}
            className={classNames({loading: isLoading})}
          >
            {this._renderBrands()}
          </span>
          <Link
            to={`/companies/${company && company.id}/brands`}
            className="reset-link-styles"
          >
            <ChillButton
              name={this.props.isInternal ? "Manage Brands" : "View All Brands"}
              height={24}
              width={120}
              className={classNames({loading: isLoading})}
            />
          </Link>
        </div>
      </div>
    );
  }
}
