import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "../../../shared/ChillButton";
import PageTitle from "../../../shared/PageTitle";
import OrderInfo from "./OrderInfo";
import Dialog from "material-ui/Dialog";
import OrderStatus from "./OrderStatus";
import OrderPrint from "./OrderPrint";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import lodash from "lodash";
import {Link} from "react-router-dom";
import {orderCanBeApproved} from "../../../../utils/orderSatusMachine";

const buttonContainerStyle = {
  position: "absolute",
  right: 0,
  top: 0,
};

class OrderDetailHeader extends Component {
  state = {
    openStatusModal: false,
    openPrintModal: false,
    confirmOpen: false,
    confirmApproveOpen: false,
    openRollbackModal: false,
  };

  handleOpen = () => {
    this.setState({openStatusModal: true});
  };

  handleClose = event => {
    if (event) event.preventDefault();
    this.setState({openStatusModal: false});
  };

  handleApproveChanges = () => {
    this.setState({confirmApproveOpen: true});
  };

  handlePrintOpen = () => {
    this.setState({openPrintModal: true});
  };

  handlePrintClose = event => {
    if (event) event.preventDefault();
    this.setState({openPrintModal: false});
  };

  _handleCancel = event => {
    event.preventDefault();
    const {onToggleReorder} = this.props;
    onToggleReorder();
  };

  _handlePrintActive = isActive => {
    this.setState({isPrintActive: isActive});
  };

  _handleRollbackModal = isActive => {
    this.setState({openRollbackModal: isActive});
  };

  _rollbackModal = () => {
    const {onUpdateOrderStatus} = this.props;
    const {openRollbackModal} = this.state;
    return (
      <ConfirmationModal
        title={"Are You Sure?"}
        content={"Changes to the completed order will alter history."}
        confirmText="Yes"
        cancelText="No"
        open={openRollbackModal}
        onConfirm={() =>
          onUpdateOrderStatus(1, true) && this._handleRollbackModal(false)
        }
        onDismiss={() => this._handleRollbackModal(false)}
      />
    );
  };

  _renderReorder = () => {
    const {onPrintOpen, isInternal} = this.props;
    const billingReportButton = isInternal ? (
      <ChillButton
        className="pageIndexHeader_btn"
        name="BILLING REPORT"
        height={40}
        width={160}
        widthIcon={20}
        icon="print-white"
        onClick={onPrintOpen}
      />
    ) : null;

    if (this.props.isDSOD) {
      return (
        <div
          className="row middle-xs button-container non-printable"
          style={{
            alignSelf: "flex-start",
          }}
        >
          {billingReportButton}
        </div>
      );
    }

    const {
      onToggleReorder,
      isReordering,
      reorderItems,
      onReorderItems,
    } = this.props;

    return (
      <div
        className="row button-container non-printable"
        style={buttonContainerStyle}
      >
        {isReordering ? (
          <div className="row middle-xs">
            <Link to={""} className="text-cancel" onClick={this._handleCancel}>
              Cancel
            </Link>
            <ChillButton
              className="pageIndexHeader_btn"
              name="ADD TO RE-ORDER"
              height={40}
              isDisabled={reorderItems.length === 0}
              width={195}
              widthIcon={20}
              icon="orderfilled-white"
              onClick={onReorderItems}
            />
          </div>
        ) : (
          <div className="row middle-xs">
            {billingReportButton}
            <ChillButton
              className="pageIndexHeader_btn"
              name="RE-ORDER"
              height={40}
              width={135}
              widthIcon={20}
              icon="orderfilled-white"
              onClick={onToggleReorder}
            />
            {isInternal && (
              <ChillButton
                className="pageIndexHeader_btn"
                name="ROLLBACK"
                height={40}
                width={135}
                widthIcon={20}
                icon="replacefile-white"
                onClick={() => this._handleRollbackModal(true)}
              />
            )}
            {this._rollbackModal()}
          </div>
        )}
      </div>
    );
  };

  handleGoToEditPage = () => {
    const {history, isDSOD, companyId, orderId} = this.props;
    const order_path = isDSOD ? "dsod" : "orders";
    history.push(`/companies/${companyId}/${order_path}/${orderId}/edit`);
  };

  _renderApproveChanges = () => {
    const {orderRequiresApproval, order, shipments} = this.props;
    if (!orderRequiresApproval) return null;
    const isDisabled = !orderCanBeApproved(order, shipments);
    return (
      <ChillButton
        isDisabled={isDisabled}
        className="pageIndexHeader_btn"
        name="APPROVE ORDER"
        height={40}
        width={147}
        margin
        icon=""
        onClick={this.handleApproveChanges}
      />
    );
  };

  _renderButtons = () => {
    const {
      isInternal,
      orderStatus,
      order,
      onUpdateOrderStatus,
      isDSOD,
    } = this.props;
    const {openStatusModal, openPrintModal} = this.state;

    return (
      <div
        className="row button-container non-printable"
        style={buttonContainerStyle}
      >
        {isInternal && (
          <ChillButton
            className="btn--formCardHeader"
            name={order.isDsod ? "REVERT DSOD" : "REVERT ORDER"}
            height={40}
            width={150}
            secondary={true}
            onClick={() => {
              this.setState({confirmOpen: true});
            }}
          />
        )}

        {!isInternal ? null : (
          <ChillButton
            className="btn--formCardHeader"
            name="EDIT"
            height={40}
            width={100}
            icon="edit"
            secondary={true}
            onClick={this.handleGoToEditPage}
          />
        )}

        {!isInternal ? null : (
          <ChillButton
            className="pageIndexHeader_btn"
            name="UPDATE STATUS"
            height={40}
            width={147}
            margin
            icon=""
            onClick={this.handleOpen}
          />
        )}

        {this._renderApproveChanges()}

        {!isInternal ? null : (
          <ChillButton
            className="pageIndexHeader_btn"
            name="PRINT"
            height={40}
            width={107}
            widthIcon={20}
            icon="print-white"
            onClick={this.handlePrintOpen}
          />
        )}

        <Dialog
          modal={true}
          open={openStatusModal}
          contentStyle={{width: 545}}
          overlayClassName={"dialog-overlay"}
        >
          <OrderStatus
            orderStatus={orderStatus}
            onClose={this.handleClose}
            order={order}
            onUpdateOrderStatus={onUpdateOrderStatus}
            isDSOD={isDSOD}
          />
        </Dialog>

        <Dialog
          modal={true}
          open={openPrintModal}
          contentStyle={{width: 545}}
          overlayClassName={"dialog-overlay"}
        >
          <OrderPrint
            orderStatus={orderStatus}
            onClose={this.handlePrintClose}
            isInternal={isInternal}
            order={order}
          />
        </Dialog>
      </div>
    );
  };

  _renderEditableName = () => {
    const {
      titleIcon,
      order,
      isInternal,
      shipments,
      isDSOD,
      company,
    } = this.props;

    return (
      <div className="start-xs middle-xs">
        {this.isFullyShipped() ? null : (
          <div className="row start-xs middle-xs padding-bottom-8">
            <span className="subtext">Order status:&nbsp;</span>
            <span className="subtext font-weight-bold">
              {lodash.get(order, "status.name")}
            </span>
          </div>
        )}
        {titleIcon ? (
          <img
            src={`/assets/imgs/icons/${titleIcon}.svg`}
            className="pageIndexHeader_icon"
          />
        ) : null}

        {!isDSOD && (
          <PageTitle
            text={lodash.get(order, "jobName") || ""}
            className="non-printable"
          />
        )}

        <OrderInfo
          order={order}
          company={company}
          isInternal={isInternal}
          shipments={shipments}
          isDSOD={isDSOD}
          isFullyShipped={this.isFullyShipped}
        />
      </div>
    );
  };

  isFullyShipped = () => {
    if (!this.props.order.status) return;
    return this.props.order.status.isClosed;
  };

  render() {
    const {containerClass, order} = this.props;
    const {confirmOpen, confirmApproveOpen} = this.state;

    return (
      <div
        className={`pageIndexHeader row between-xs middle-xs ${containerClass}`}
        style={{marginBottom: 0, position: "relative", width: "100%"}}
      >
        {this._renderEditableName()}
        {this.isFullyShipped() ? this._renderReorder() : this._renderButtons()}

        <ConfirmationModal
          title="Order will be reverted to the order cart. Do you want to continue?"
          confirmText="Yes"
          cancelText="No"
          open={confirmOpen}
          onConfirm={() => {
            this.setState({confirmOpen: false}, () => {
              if (typeof this.props.revertOrder === "function") {
                this.props.revertOrder(order.id, () => {
                  if (order.isDsod) {
                    this.props.history.push({
                      pathname: `/companies/${order.companyId}/dsod`,
                    });
                  } else {
                    this.props.history.push({
                      pathname: `/companies/${order.companyId}/reorders`,
                    });
                  }
                });
              }
            });
          }}
          onDismiss={() => {
            this.setState({confirmOpen: false});
          }}
          height={250}
        />

        <ConfirmationModal
          title="Order will be approved. Do you want to continue?"
          confirmText="Yes"
          cancelText="No"
          open={confirmApproveOpen}
          onConfirm={() => {
            this.setState(
              {
                confirmApproveOpen: false,
              },
              () => {
                this.props.approveOrder(order.id);
              }
            );
          }}
          onDismiss={() => {
            this.setState({confirmApproveOpen: false});
          }}
          height={250}
        />
      </div>
    );
  }
}

OrderDetailHeader.propTypes = {
  revertOrder: PropTypes.func.isRequired,
  onUpdateOrderStatus: PropTypes.func.isRequired,
  onReorderItems: PropTypes.func,
  onToggleReorder: PropTypes.func,
  containerClass: PropTypes.string,
  titleIcon: PropTypes.string,
  isInternal: PropTypes.bool,
  isReordering: PropTypes.bool,
  order: PropTypes.object,
  orderStatus: PropTypes.array,
  reorderItems: PropTypes.array,
  shipments: PropTypes.array,
  history: PropTypes.object,
  isDSOD: PropTypes.bool,
  companyId: PropTypes.string,
  company: PropTypes.object,
  orderId: PropTypes.string,
  onPrintOpen: PropTypes.func,
  orderRequiresApproval: PropTypes.bool,
  approveOrder: PropTypes.func,
};

export default OrderDetailHeader;
