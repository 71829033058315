import decode from "jwt-decode";
import changeCaseKeys from "change-case-keys";
import lodash from "lodash";

export default class AuthService {
  async login(email_address, password) {
    const response = await this.fetchWithAuth("/login", {
      method: "POST",
      body: {
        email_address,
        password,
      },
    });

    this.setToken(response.body.token);

    return response;
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      return decode(token).exp < Date.now() / 1000 ? true : false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    localStorage.setItem("id_token", idToken);
  }

  getToken() {
    return localStorage.getItem("id_token");
  }

  setSelectedCompany(company_id, name, customer_message) {
    localStorage.setItem("selected_company_id", company_id);
    localStorage.setItem("selected_company_name", name);
    if (customer_message) {
      localStorage.setItem(
        "selected_company_customer_message",
        customer_message
      );
    }
  }

  getSelectedCompany() {
    return localStorage.getItem("selected_company_id");
  }

  getSelectedCompanyName() {
    return localStorage.getItem("selected_company_name");
  }

  getSelectedCompanyCustomerMessage() {
    return localStorage.getItem("selected_company_customer_message");
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("selected_company_id");
    localStorage.removeItem("selected_company_name");
    localStorage.removeItem("selected_company_customer_message");
  }

  fetchWithAuth(url, options) {
    //body keys must change to underscore
    if (options && options.body) options.body = this._formatBody(options);

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (options && options.contentType === false) {
      delete headers["Content-Type"];
    }

    if (this.loggedIn()) {
      headers["Authorization"] = "JWT " + this.getToken();
    }

    return fetch(`${process.env.API_DOMAIN}:${process.env.API_PORT}` + url, {
      headers,
      ...options,
    })
      .then(response => {
        if (response.status === 204) return response;
        return response
          .json()
          .then(data => ({body: data, status: response.status}));
      })
      .then(this._checkStatus);
  }

  _formatBody(options) {
    if (options.body instanceof FormData) return options.body;
    return JSON.stringify(changeCaseKeys(options.body, "underscored"));
  }

  async _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(lodash.get(response, "body.message"));
      error.response = response;
      throw error;
    }
  }
}
