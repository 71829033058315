import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import QuoteLayout from "./QuoteLayout";
import {closeNotification} from "../../../../actions/notificationActions";

function mapStateToProps(state) {
  const {notification} = state;
  return {notification};
}

function mapDispatchToProps(dispatch) {
  return {
    closeNotification: bindActionCreators(closeNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteLayout)
);
