import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function digitalizationTypePriceReducer(
  state = initialState.digitalizationTypePrices,
  {type, payload}
) {
  switch (type) {
  case actions.FETCH_DIGITALIZATION_TYPE_PRICES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_DIGITALIZATION_TYPE_PRICES_SUCCESS:
    return {
      ...state,
      digitalizationTypePrices: payload,
      isLoading: false,
    };
  case actions.FETCH_DIGITALIZATION_TYPE_PRICES_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  default:
    return state;
  }
}
