import React, {Fragment, Component} from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";

import {formatAddress} from "../../../../../utils/addressFormatter";
import withStyles from "../../../shared/WithStyles";
import Typography from "../../../shared/Typography";
import RaisedButton from "../../../shared/RaisedButton";
import AttributeLine from "../../../../shared/AttributeLine";
import ContentLoader from "../../../../shared/ContentLoader";
import QuoteItem from "./QuoteItem";
import QuoteItemEditModal from "./QuoteItemEditModal";
import QuoteFooter from "../QuoteFooter";
import {UPLOAD_ART, PLACE_ORDER, SEND_QUOTE_REQUEST} from "../../../../../constants/quote";
import {isArray} from "util";

class QuoteSummary extends Component {
  state = {
    priceTableOpenId: null,
    currentEditItemIndex: null,
    openEditModal: null,
  };

  componentDidMount() {
    const {items, fetchProducts, fetchFabrics, fetchColors} = this.props;
    if (items.length === 1 && items[0].artOption === "no-art") {
      this.setState({priceTableOpenId: items[0].id});
    }
    fetchProducts();
    fetchFabrics();
    fetchColors();
  }

  handlePriceTableOpen = itemId => {
    this.setState({priceTableOpenId: itemId});
  };

  handleItemChange = index => item => {
    const {onUpdateItems, items} = this.props;
    items[index] = item;

    if (items[index].arts) {
      items[index].artOption = UPLOAD_ART;
    }

    this.handleDismissEditModal();
    onUpdateItems(items);
  };

  handleOpenEditModal = index => () => {
    this.setState({currentEditItemIndex: index, openEditModal: true});
  };

  handleDismissEditModal = () => {
    this.setState({currentEditItemIndex: null, openEditModal: false});
  };



  hasValidQty = () => {
    const {items, onUpdateItems, accessoryCategoryId} = this.props;

    items.forEach(({quantity, artOption, categoryId}, index) => {
      const hasNoArt = artOption === "no-art";
      const isAccessory = categoryId === accessoryCategoryId;
      
      items[index].errorMessages =
        hasNoArt && isAccessory && (isNaN(Number(quantity)) || Number(quantity) <= 0)
          ? ["required qty"]
          : undefined;
    });

    const itemsWithArt = items.filter(item => item.artOption !== "no-art");
    
    if (itemsWithArt.length >= 1 && items.length > 1) {
      items.map( item => {
        item.errorMessages && item.errorMessages.map(error => {
          error === "required qty" && (item.errorMessages = undefined);
        });
      });
    }

    // update state items attribute in order to show the message
    onUpdateItems(items);
    // prevent submit
    return !items.some(({errorMessages}) => isArray(errorMessages));
  };

  get pageStyle() {
    const {styles: {colors}} = this.props;
    return {
      wrapper: {
        paddingRight: 41,
      },
      contactField: {
        whiteSpace: "nowrap",
        marginRight: 16,
        width: undefined,
        minWidth: 261,
      },
      submitButton: {},
      artFilename: {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artIcon: {
        padding: 4,
        color: colors.primary.light,
      },
      submitButtonLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      footer: {
        paddingTop: 30,
        paddingBottom: 30,
      },
      input: {
        marginRight: 24,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artFile: {
        marginTop: "auto",
        marginBottom: 8,
        width: 238,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artImage: {
        width: 32,
        height: 32,
        objectFit: "contain",
      },
      artFileName: {
        padding: "0 8px",
        whiteSpace: "nowrap",
        maxWidth: 230,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      deleteIcon: {
        fontSize: 26,
        marginRight: 8,
        color: colors.primary.light,
      },
      separator: {
        marginLeft: "10px",
        marginRight: "10px",
        fontWeight: "bold",
      },
      item: {
        minWidth: 1116,
        borderRadius: 4,
        backgroundColor: colors.white,
      },
      image: {
        width: 144,
        height: 144,
        objectFit: "contain",
      },
      removeButton: {
        cursor: "pointer",
        color: "#0C5271",
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: 14,
      },
      closeIcon: {
        color: colors.primary.light,
        fontSize: 16,
      },
      addMore: {
        backgroundColor: `${colors.white}80`,
      },
      addMoreIcon: {
        color: colors.secondary.light,
        fontSize: 26,
        marginRight: 8,
      },
      smallInput: {
        width: 80,
      },
      archiveIcon: {
        padding: 4,
        color: colors.primary.light,
      },
    };
  }

  render() {
    const {
      isAuthenticated,
      isKnownCompany,
      name,
      lastName,
      companyName,
      emailAddress,
      address1,
      address2,
      city,
      state,
      zipCode,
      countryId,
      countries,
      items,
      arts,
      companyArts,
      onAddItem,
      onRemoveItem,
      onSubmitQuote,
      itemPrices,
      isLoadingPrices,
      isLoading,
      fetchItemPriceTable,
      onUpdateItemQuantityValue,
      onOpenArchiveModal,
      styles: {colors},
      fetchProducts,
      fetchFabrics,
      fetchColors,
      fetchAdornmentLocationsByProduct,
      fabrics,
      products,
      colors: colorsState,
      categories,
      locations,
      onCreateArt,
      onDeleteArt,
      openNotification,
      handleSetStateQuote,
    } = this.props;
    const {priceTableOpenId, openEditModal, currentEditItemIndex} = this.state;

    const {address2: formatAddress2, stateCode, countryCode} = formatAddress(
      {address1, address2, city, state, zipCode, countryId},
      countries
    );
    const address = `${address1}${formatAddress2}, ${city}, ${stateCode}, ${zipCode}, ${countryCode}`;

    const currentEditItem =
      currentEditItemIndex !== null ? items[currentEditItemIndex] : {};

    const totalArts = items.reduce((total, item) => total + (item.arts ? item.arts.length : 0), 0);

    const matchingArt  = items.length > 0 && companyArts.length > 0 && items.every((item) =>
      item.arts.every((art) =>
        companyArts.some((companyArt) => companyArt.id === art.art)
      )
    );
    return (
      <ContentLoader isLoading={isLoading} zIndex={2}>
        <div className="flex" style={this.pageStyle.wrapper}>
          <div className="flex flex-column margin-auto">
            <Typography variant="h1" className="margin-bottom-30">
              Request a Quote
            </Typography>
            <div className="flex">
              <AttributeLine
                labelText={isKnownCompany ? "Company" : "Name"}
                text={isKnownCompany ? companyName : `${name} ${lastName}`}
                style={this.pageStyle.contactField}
              />
              <AttributeLine
                labelText="Email"
                text={emailAddress}
                style={this.pageStyle.contactField}
              />
              {!isKnownCompany && (
                <AttributeLine
                  labelText="Address"
                  text={address}
                  style={this.pageStyle.contactField}
                />
              )}
            </div>
            <div
              className="flex flex-column padding-top-40"
              style={{minWidth: 1116}}
            >
              {items.map((item, index) => (
                <QuoteItem
                  key={item.id}
                  {...item}
                  companyArts={companyArts}
                  quoteArts={arts}
                  itemPrices={itemPrices}
                  priceTableOpenId={priceTableOpenId}
                  onPriceTableOpen={this.handlePriceTableOpen}
                  onEditItem={this.handleOpenEditModal(index)}
                  onRemoveItem={onRemoveItem(item.id)}
                  fetchItemPriceTable={fetchItemPriceTable}
                  onUpdateItemQuantityValue={onUpdateItemQuantityValue(index)}
                  pageStyle={this.pageStyle}
                  isLoadingPrices={isLoadingPrices}
                  hideRemove={items.length === 1}
                />
              ))}
              <QuoteItemEditModal
                item={currentEditItem}
                handleSetStateQuote={handleSetStateQuote}
                itemIndex={currentEditItemIndex}
                items={items}
                quoteArts={arts}
                companyArts={companyArts}
                open={openEditModal}
                categories={categories}
                products={products}
                fabrics={fabrics}
                colors={colorsState}
                locations={locations}
                fetchProducts={fetchProducts}
                fetchFabrics={fetchFabrics}
                fetchColors={fetchColors}
                fetchAdornmentLocationsByProduct={fetchAdornmentLocationsByProduct}
                onEditItem={this.handleItemChange(currentEditItemIndex)}
                onUpdateItemQuantityValue={onUpdateItemQuantityValue()}
                totalArts={totalArts}
                onClose={this.handleDismissEditModal}
                pageStyle={this.pageStyle}
                onCreateArt={onCreateArt}
                onDeleteArt={onDeleteArt}
                openNotification={openNotification}
              />
            </div>
            <div
              className="small-header flex flex-center justify-content-center w-100 uppercase pointer margin-top-8 padding-20"
              style={this.pageStyle.addMore}
              onClick={onAddItem}
            >
              <FontIcon
                className="material-icons"
                style={this.pageStyle.addMoreIcon}
              >
                add_circle_outline
              </FontIcon>
              <span style={{color: colors.secondary.light}}>
                Add another item to quote
              </span>
            </div>

            <footer
              style={this.pageStyle.footer}
              className="flex justify-content-between align-items-center"
            >
              <Typography
                variant="bodyBold"
                className="flex flex-center justify-content-center pointer"
                color="primary.light"
                onClick={onOpenArchiveModal}
              >
                <FontIcon
                  className="material-icons"
                  style={this.pageStyle.archiveIcon}
                >
                  archive
                </FontIcon>
                Quit This Quote
              </Typography>
              <RaisedButton
                large
                label={
                  <Fragment>
                    <FontIcon
                      className="material-icons"
                      style={{color: "white", padding: 4, fontSize: 20}}
                    >
                      check
                    </FontIcon>
                    {isAuthenticated && totalArts === 0 || matchingArt ? PLACE_ORDER : SEND_QUOTE_REQUEST}
                  </Fragment>
                }
                style={this.pageStyle.submitButton}
                labelStyle={this.pageStyle.submitButtonLabel}
                onClick={() =>  this.hasValidQty() &&  onSubmitQuote()}
              />
            </footer>
            <QuoteFooter />
          </div>
        </div>
      </ContentLoader>
    );
  }
}

QuoteSummary.propTypes = {
  isAuthenticated: PropTypes.bool,
  itemPrices: PropTypes.array,
  isKnownCompany: PropTypes.bool,
  name: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
  emailAddress: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countries: PropTypes.array,
  items: PropTypes.array,
  arts: PropTypes.array,
  companyArts: PropTypes.array,
  onAddItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onSubmitQuote: PropTypes.func,
  onUpdateItems: PropTypes.func,
  fetchItemPriceTable: PropTypes.func,
  onUpdateItemQuantityValue: PropTypes.func,
  styles: PropTypes.object,
  isLoadingPrices: PropTypes.bool,
  isLoading: PropTypes.bool,
  onOpenArchiveModal: PropTypes.func,
  fabrics: PropTypes.array,
  products: PropTypes.array,
  categories: PropTypes.array,
  locations: PropTypes.array,
  colors: PropTypes.array,
  fetchProducts: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fetchAdornmentLocationsByProduct: PropTypes.func,
  onCreateArt: PropTypes.func,
  onDeleteArt: PropTypes.func,
  openNotification: PropTypes.func,
  accessoryCategoryId: PropTypes.string,
  handleSetStateQuote: PropTypes.func,
};

export default withStyles(QuoteSummary);