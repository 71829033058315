import React from "react";
import PropTypes from "prop-types";

import {
  CompactTable,
  CompactTableRow,
  CompactTableRowColumn,
} from "../../shared/CompactTable";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

const getQuantityText = (min, max, pricesCount) => {
  if (min) {
    if (max > 999999999) {
      return `${min}+`;
    }
    return `${min}-${max}`;
  }
  if (pricesCount === 1) {
    return `${max}+`;
  }
  return `${max}`;
};

const QuoteItemPricingTable = ({prices, simple, tableStyle, isLoading}) => {
  const headers = simple
    ? ["QTY", "NET UNIT COST"]
    : ["QTY", "UNIT COST", "ADORN. COST", "NET UNIT COST"];
  return (
    <CompactTable headers={headers} style={tableStyle} isLoading={isLoading}>
      {prices.map((price, index) => (
        <CompactTableRow key={index}>
          <CompactTableRowColumn>
            {getQuantityText(
              price.quantityMin,
              price.quantityMax,
              prices.length
            )}
          </CompactTableRowColumn>
          {!simple && (
            <CompactTableRowColumn>
              {getCurrencyFormattedNumber(
                price.unitCostValue - price.quantityDiscountValue
              )}
            </CompactTableRowColumn>
          )}
          {!simple && (
            <CompactTableRowColumn>
              {getCurrencyFormattedNumber(price.unitAdornmentCostValue)}
            </CompactTableRowColumn>
          )}
          <CompactTableRowColumn>
            {getCurrencyFormattedNumber(price.netUnitCost)}
          </CompactTableRowColumn>
        </CompactTableRow>
      ))}
      {!isLoading &&
        prices.length === 0 && (
        <CompactTableRow>
          <CompactTableRowColumn tdProps={{colSpan: simple ? 2 : 4}}>
              No prices found
          </CompactTableRowColumn>
        </CompactTableRow>
      )}
      {isLoading &&
        prices.length === 0 &&
        [0, 1, 2, 3, 4].map(_ => (
          <CompactTableRow key={_}>
            <CompactTableRowColumn>{_}</CompactTableRowColumn>
          </CompactTableRow>
        ))}
    </CompactTable>
  );
};

QuoteItemPricingTable.propTypes = {
  prices: PropTypes.array,
  simple: PropTypes.bool,
  tableStyle: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default QuoteItemPricingTable;
