import React from "react";
import ReactGA from "react-ga";
import {renderRoutes} from "react-router-config";
import {Colors} from "../styles/theme/Colors";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import themeV2 from "../styles/v2/theme";
import urlPaths from "../constants/urlPaths";

import Dashboard from "./containers/Dashboard";

import CompaniesIndex from "./containers/Companies/CompaniesIndex";
import CompaniesCreate from "./containers/Companies/CompaniesCreate";
import CompaniesEdit from "./containers/Companies/CompaniesEdit";
import CompaniesDetail from "./containers/Companies/CompaniesDetail";

import BrandIndex from "./containers/Brands/BrandIndex";
import BrandCreate from "./containers/Brands/BrandCreate";
import BrandDetail from "./containers/Brands/BrandDetail";

import ArtCreate from "./containers/Arts/ArtCreate";
import ArtEdit from "./containers/Arts/ArtEdit";
import ArtDetail from "./containers/Arts/ArtDetail";

import DSODCart from "./containers/DSODcart";
import DSODDetail from "./containers/DSOD/DSODDetail";
import DSODEdit from "./containers/DSOD/DSODEdit";
import ReorderCreate from "./containers/Reorders/ReorderCreate";
import OrderCartNew from "./pages/Reorders/OrderCartNew";

import OrderDetail from "./containers/Orders/OrderDetail";
import OrderEdit from "./containers/Orders/OrderEdit";
import OrderPrint from "./pages/Orders/PrintPage";

import Users from "./containers/Users/UsersIndex";
import UserCreate from "./containers/Users/UserCreate";
import UserEdit from "./containers/Users/UserEdit";
import UserDetail from "./containers/Users/UserDetail";

import ContactCreate from "./containers/Contacts/ContactCreate";
import ContactEdit from "./containers/Contacts/ContactEdit";
import ContactDetail from "./containers/Contacts/ContactDetail";

import CarrierAssignmentCreate from "./pages/CarrierAssignments/CarrierAssignmentCreate";
import CarrierAssignmentEdit from "./pages/CarrierAssignments/CarrierAssignmentEdit";

import Login from "./containers/Login";
import Forgot from "./containers/Forgot";
import Reset from "./containers/Reset";
import CompanySelect from "./containers/CompanySelect";

import ProductDetail from "./containers/Products/ProductDetail";
import QuoteInfo from "./v2/pages/quotes/QuoteInfo";
import Products from "./v2/pages/products/Products";
import QuoteComplete from "./v2/pages/quotes/QuoteComplete";
import QuoteSummary from "./v2/pages/quotes/QuoteSummary";
import QuoteDetail from "./v2/pages/quotes/QuoteDetail";
import QuoteIndex from "./v2/pages/quotes/QuoteIndex";
import ChatCenter from "./v2/pages/ChatCenter";

import StyleGuides from "./v2/pages/StyleGuides";
import requireAuthentication from "./requireAuthentication";
const routers = [
  {
    path: "/users",
    component: requireAuthentication(Users),
    exact: true,
  },
  {
    path: "/",
    component: requireAuthentication(Dashboard),
    exact: true,
  },
  {
    path: "/companies",
    component: requireAuthentication(CompaniesIndex),
    exact: true,
  },
  {
    path: "/companies/create",
    component: requireAuthentication(CompaniesCreate),
    exact: true,
  },
  {
    path: "/companies/edit/:id",
    component: requireAuthentication(CompaniesEdit),
    exact: true,
  },
  {
    path: "/companies/:id",
    component: requireAuthentication(CompaniesDetail),
    exact: true,
  },
  {
    path: "/companies/:id/brands",
    component: requireAuthentication(BrandIndex),
    exact: true,
  },
  {
    path: "/companies/:id/brands",
    component: requireAuthentication(BrandIndex),
    exact: true,
  },
  {
    path: "/companies/:company_id/brands/create",
    component: requireAuthentication(BrandCreate),
    exact: true,
  },
  {
    path: "/companies/:company_id/brands/:id",
    component: requireAuthentication(BrandDetail),
    exact: true,
  },
  {
    path: "/companies/:company_id/users/create",
    component: requireAuthentication(UserCreate, true, true),
    exact: true,
  },
  {
    path: "/companies/:company_id/users/:id",
    component: requireAuthentication(UserDetail, true, false, {
      useCompanyBreadcrumbs: true,
    }),
    exact: true,
  },
  {
    path: "/companies/:company_id/users/:id/edit",
    component: requireAuthentication(UserEdit, true, false, {
      useCompanyBreadcrumbs: true,
    }),
    exact: true,
  },
  {
    path: "/brands/:id/arts/create",
    component: requireAuthentication(ArtCreate),
    exact: true,
  },
  {
    path: "/brands/:brand_id/arts/:id/edit",
    component: requireAuthentication(ArtEdit),
    exact: true,
  },
  {
    path: "/brands/:brand_id/arts/:id",
    component: requireAuthentication(ArtDetail),
    exact: true,
  },
  {
    path: "/brands/:brand_id/arts/:id/revisions/:revision_id",
    component: requireAuthentication(ArtDetail),
    exact: true,
  },
  {
    path: "/companies/:company_id/products/:id",
    component: requireAuthentication(ProductDetail),
    exact: true,
  },

  {
    path: "/companies/:company_id/orders/:order_id/products/:id",
    component: requireAuthentication(ProductDetail, true, false, {
      disableTabs: true,
    }),
  },
  {
    path: "/dsod/new",
    component: requireAuthentication(DSODCart),
    exact: true,
  },

  {
    path: "/orders/new",
    component: requireAuthentication(OrderCartNew),
    exact: true,
  },

  {
    path: "/companies/:company_id/dsod",
    component: requireAuthentication(DSODCart),
    exact: true,
  },

  {
    path: "/companies/:company_id/dsod/:id",
    component: requireAuthentication(DSODDetail),
    exact: true,
  },
  {
    path: "/companies/:company_id/reorders",
    component: requireAuthentication(ReorderCreate),
    exact: true,
  },
  {
    path: "/companyselect",
    component: requireAuthentication(CompanySelect, false),
    exact: true,
  },
  {
    path: "/companies/:company_id/orders/:id",
    component: requireAuthentication(OrderDetail),
    exact: true,
  },
  {
    path: "/companies/:company_id/orders/:id/print",
    component: requireAuthentication(OrderPrint, false, false, {
      printMode: true,
    }),
    exact: true,
  },
  {
    path: "/companies/:company_id/orders/:id/edit",
    component: requireAuthentication(OrderEdit),
    exact: true,
  },
  {
    path: "/companies/:company_id/dsod/:id/edit",
    component: requireAuthentication(DSODEdit),
    exact: true,
  },

  {
    path: "/users/create",
    component: requireAuthentication(UserCreate, true, true),
    exact: true,
  },

  {
    path: "/users/:id/edit",
    component: requireAuthentication(UserEdit, true, true),
    exact: true,
  },

  {
    path: "/users/:id",
    component: requireAuthentication(UserDetail, true, true),
  },

  {
    path: "/companies/:company_id/contacts/create",
    component: requireAuthentication(ContactCreate, true, false),
    exact: true,
  },

  {
    path: "/companies/:company_id/carrier_assignment/create",
    component: requireAuthentication(CarrierAssignmentCreate, true, false),
    exact: true,
  },

  {
    path: "/companies/:company_id/carrier_assignment/:id",
    component: requireAuthentication(CarrierAssignmentEdit, true, false),
    exact: true,
  },

  {
    path: "/companies/:company_id/contacts/:id",
    component: requireAuthentication(ContactDetail, true, false),
    exact: true,
  },

  {
    path: "/companies/:company_id/contacts/:id/edit",
    component: requireAuthentication(ContactEdit, true, false),
    exact: true,
  },

  {
    path: "/login",
    component: Login,
    exact: true,
  },

  {
    path: "/forgot",
    component: Forgot,
    exact: true,
  },

  {
    path: "/reset",
    component: Reset,
    exact: true,
  },
];

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: Colors.primaryColor,
    accent1Color: Colors.accentColor,
    textColor: "#747D86",
  },
  textField: {
    focusColor: "#34B8DD",
    textColor: "#4A5158",
  },
  checkbox: {
    checkedColor: "#0c5371",
    boxColor: "#bdc6cf",
  },
  menuItem: {
    selectedTextColor: "rgba(0, 0, 0, 0.88)",
  },
  datePicker: {
    selectColor: Colors.primaryColor,
    headerColor: Colors.primaryColor,
  },
});

const routersV2 = [
  {
    path: "/styleguide",
    component: StyleGuides,
    exact: true,
  },
  {
    path: urlPaths.quoteInfo,
    component: QuoteInfo,
    exact: true,
  },
  {
    path: urlPaths.quoteSummary,
    component: QuoteSummary,
    exact: true,
  },
  {
    path: urlPaths.quoteComplete,
    component: QuoteComplete,
    exact: true,
  },
  {
    path: urlPaths.quoteDetail,
    component: requireAuthentication(QuoteDetail, true, false),
    exact: true,
  },
  {
    path: urlPaths.products,
    component: requireAuthentication(Products),
    exact: true,
  },
  {
    path: urlPaths.quoteIndex,
    component: requireAuthentication(QuoteIndex),
    exact: true,
  },
  {
    path: urlPaths.chats,
    component: requireAuthentication(ChatCenter),
    exact: true,
  },
];

export default class Routes extends React.Component {
  componentDidUpdate() {
    this.trackPageview();
  }

  componentDidMount() {
    this.trackPageview();
  }

  trackPageview() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider muiTheme={themeV2}>
          {renderRoutes(routersV2)}
        </MuiThemeProvider>
        <MuiThemeProvider muiTheme={muiTheme}>
          {renderRoutes(routers)}
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}
