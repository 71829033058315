import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../actions/authActions";
import LoginPage from "../pages/LoginPage";

class Login extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      this.props.history.push("/companyselect");
    }
  }

  render() {
    return (
      <LoginPage
        login={(email, password) => this.props.login(email, password)}
        loading={this.props.loading}
        error_message={this.props.error_message}
      />
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error_message: PropTypes.string,
  token: PropTypes.string,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    error_message: state.auth.error_message,
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(actions.login(email, password)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
