import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _reject from "lodash/reject";
import FilePreview from "react-preview-file";
import { FileIcon } from "@drawbotics/file-icons";
import Dialog from "material-ui/Dialog";
import FontIcon from "material-ui/FontIcon";

import CNGMenuItem from "../../../../shared/CNGMenuItem";
import ValidableSelect from "../../../../shared/ValidableSelect";
import ChillButton from "../../../../shared/ChillButton";
import Typography from "../../../shared/Typography";
import QuoteArtModal from "../QuoteArtModal";
import {
  VALID_IMAGE_FORMATS,
  PREVIEWABLE_IMAGE_FORMATS,
} from "../../../../../constants/art";

class QuoteItemEditModal extends Component {
  state = {
    artModalOpen: false,
  };

  inputField = null;
  componentDidMount() {
    const {
      item
    } = this.props;
    const { categoryId, product, fabric, color } = item;  

    this.setState({
      categoryId: categoryId,
      product: product,
      fabric: fabric,
      color: color,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      item,
      fetchProducts,
      fetchFabrics,
      fetchColors,
      fetchAdornmentLocationsByProduct,
    } = this.props;

    if (prevProps.item && item && prevProps.item.id !== item.id && item.id) {
      this.setState({ ...item, mockups: _get(item, "mockups", []) });
      const { categoryId, product, fabric } = item;
      fetchProducts({
        include: "productImages",
        filter: `product_category_id,eq,${categoryId}`,
      });
      fetchFabrics({
        filter: `productFabricPricing.product_id,eq,${product.id}`,
      });
      if (fabric) {
        const filters = [
          ["fabricAvailableColor.active", "eq", true],
          ["fabricAvailableColor.fabric_id", "eq", fabric.id]
        ];
        fetchColors({
          filter: filters.join("|"),
          sort: "name",
          sortDirection: "asc",
        });
      }
      fetchAdornmentLocationsByProduct(product.id, {
        sort: "index",
        sortDirection: "ASC",
      });
    }
  }

  getOptions(arrayName, idName = "id", name = "name") {
    const options = this.props[arrayName];
    return options.map(option => (
      <CNGMenuItem
        key={option[idName]}
        value={option[idName]}
        primaryText={typeof name === "string" ? option[name] : name(option)}
      />
    ));
  }

  getCategoriesOptions = () => this.getOptions("categories");

  getProductOptions = () => {
    return this.getOptions(
      "products",
      "id",
      product => `${product.itemNumber} - ${product.productName}`
    );
  };

  getFabricsOptions = () => this.getOptions("fabrics");

  getColorsOptions = () => this.getOptions("colors");

  handleChangeCategory = (event, index, value) => {
    const { fetchProducts } = this.props;
    this.setState({
      categoryId: value,
      product: null,
      fabric: null,
      color: null,
    });
    fetchProducts({
      include: "productImages",
      filter: `product_category_id,eq,${value}`,
    });
  };

  handleChangeProduct = (event, index, value) => {
    const {
      products,
      fetchFabrics,
      fetchAdornmentLocationsByProduct,
    } = this.props;
    this.setState({ product: products[index], fabric: null, color: null });
    fetchFabrics({
      filter: `productFabricPricing.product_id,eq,${value}`,
    });
    fetchAdornmentLocationsByProduct(value, {
      sort: "index",
      sortDirection: "ASC",
    });
  };

  handleChangeFabric = (event, index, value) => {
    const { fabrics, fetchColors } = this.props;
    this.setState({ fabric: fabrics[index], color: null });
    const filters = [
      ["fabricAvailableColor.active", "eq", true],
      ["fabricAvailableColor.fabric_id", "eq", value]
    ];
    fetchColors({
      filter: filters.join("|"),
      sort: "name",
      sortDirection: "asc",
    });
  };

  handleChangeColor = (event, index) => {
    const { colors } = this.props;
    this.setState({ color: colors[index] });
  };

  handleChangeArtLocation = (artIndex, name) => (event, index, value) => {
    const { locations } = this.props;
    this.setState(({ arts }) => {
      const modifiedArt = { ...arts[artIndex] };
      if (name === "location") {
        modifiedArt.adornmentLocation = locations.find(
          location => location.id === value
        );
      }
      if (name === "art") {
        modifiedArt.art = value;
      }
      return {
        arts: [
          ...arts.slice(0, artIndex),
          modifiedArt,
          ...arts.slice(artIndex + 1),
        ],
      };
    });
  };

  handleRemoveArtLocation = artIndex => () => {
    this.setState(({ arts }) => ({
      arts: [...arts.slice(0, artIndex), ...arts.slice(artIndex + 1)],
    }));
  };

  handleAddArtLocation = () => {
    this.setState(({ arts }) => ({
      arts: [
        ...arts,
        {
          art: null,
          adornmentLocation: null,
        },
      ],
    }));
  };

  handleAddMockup = event => {
    this.inputField.click(event);
  };

  handleFileChange = async inputFile => {
    const { currentTarget: { files } } = inputFile;
    const { openNotification } = this.props;
    const file = files[0];
    if (!file) return;
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Uploaded file is not a valid input image. Only png, jpg and jpg"
      );
    } else {
      await this.setState(({ mockups }) => ({
        mockups: [...(mockups || []), file],
      }));
    }
  };

  handleDeleteMockUp = index => () => {
    this.setState(({ mockups }) => ({
      mockups: [...mockups.slice(0, index), ...mockups.slice(index + 1)],
    }));
  };

  handleCloseArtModal = () => {
    this.setState({ artModalOpen: false });
  };

  handleOpenArtModal = () => {
    this.setState({ artModalOpen: true });
  };

  hasFabrics = () => {
    const { fabrics } = this.props;
    const { product } = this.state;
    return !(product && fabrics.length === 1 && fabrics[0].name === "None");
  };

  hasLocations = () => {
    const { locations } = this.props;
    return locations.length > 0;
  };

  getArtsOptions = () => {
    const { quoteArts, companyArts } = this.props;
    return [
      ...(companyArts
        ? companyArts.map(art => (
          <CNGMenuItem key={art.id} value={art.id} primaryText={art.name} />
        ))
        : []),
      ...(quoteArts
        ? quoteArts.map(art => (
          <CNGMenuItem
            key={art.uuid}
            value={art.uuid}
            primaryText={art.filename}
          />
        ))
        : []),
    ];
  };

  getLocationsOptions = (arts, currentArtIndex) => {
    const { locations } = this.props;
    const selectedIds = arts.map(art => _get(art, "adornmentLocation.id"));
    const currentArt = arts[currentArtIndex];
    const selectedId = _get(currentArt, "adornmentLocation.id");
    return _reject(locations, ({ id }) => {
      return selectedIds.includes(id) && selectedId !== id;
    }).map(location => (
      <CNGMenuItem
        key={location.id}
        value={location.id}
        primaryText={location.location}
      />
    ));
  };

  renderArtList = () => {
    const { quoteArts, companyArts, pageStyle } = this.props;
    const { artOption, product, arts } = this.state;
    if (!this.hasFabrics()) return;
    if (!this.hasLocations()) return;
    return (
      arts &&
      arts.map((itemArt, index) => {
        let art = quoteArts.find(({ uuid }) => itemArt.art === uuid);
        if (!art) {
          art = companyArts.find(({ id }) => itemArt.art === id);
        }
        return (
          <div key={index} className="flex flex-center justify-content-center">
            {art ? (
              <div
                className="flex justify-content-between align-items-center z-index-3"
                style={{
                  ...pageStyle.input,
                  ...pageStyle.artFile,
                  ...pageStyle.grayBackground,
                  width: 260,
                }}
              >
                <div className="flex align-items-center" style={{ padding: 8 }}>
                  {artOption === "upload-art" && (
                    <img src={art.imageUrl} style={pageStyle.artImage} />
                  )}
                  <Typography
                    variant="bodyBold"
                    color="secondary.light"
                    className="version--2 secondary-link-style"
                    style={pageStyle.artFileName}
                  >
                    {art.name || art.filename}
                  </Typography>
                </div>
              </div>
            ) : (
              <ValidableSelect
                disabled={!product}
                name="art"
                label={`Art ${index + 1}`}
                errorText={null}
                value={
                  itemArt.adornmentLocation && itemArt.adornmentLocation.id
                }
                style={{ ...pageStyle.input, width: 260 }}
                floatingLabelFixed={true}
                inputStyle={{ width: "100%" }}
                dropDownMenuProps={{
                  style: {
                    width: "100%",
                  },
                  autoWidth: true,
                }}
                handleChange={this.handleChangeArtLocation(index, "art")}
                options={this.getArtsOptions()}
                hintText="Select"
              />
            )}
            <ValidableSelect
              disabled={!product}
              name="location"
              label={`Art ${index + 1} location`}
              errorText={null}
              value={itemArt.adornmentLocation && itemArt.adornmentLocation.id}
              style={{ width: 230 }}
              floatingLabelFixed={true}
              inputStyle={{ width: "100%" }}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
                autoWidth: true,
              }}
              handleChange={this.handleChangeArtLocation(index, "location")}
              options={this.getLocationsOptions(arts, index)}
              hintText="Select"
            />
            <FontIcon
              className="pointer material-icons"
              style={pageStyle.deleteIcon}
              onClick={this.handleRemoveArtLocation(index)}
            >
              delete
            </FontIcon>
          </div>
        );
      })
    );
  };

  renderMockups = () => {
    const { pageStyle } = this.props;
    const { mockups } = this.state;
    return (
      mockups &&
      mockups.map((mockup, i) => (
        <div
          key={i}
          className="flex justify-content-between align-items-center z-index-3"
          style={{
            ...pageStyle.input,
            ...pageStyle.grayBackground,
            margin: 12,
            marginTop: 16,
            marginBottom: 8,
            width: 260,
          }}
        >
          <div
            className="flex align-items-center"
            style={{ ...pageStyle.artFile, padding: 8 }}
          >
            {mockup.imageUrl ? (
              <img src={mockup.imageUrl} style={pageStyle.artImage} />
            ) : (
              <FilePreview file={mockup}>
                {preview => {
                  return PREVIEWABLE_IMAGE_FORMATS.includes(mockup.type) ? (
                    <img src={preview} style={pageStyle.artImage} />
                  ) : (
                    <FileIcon
                      filename={mockup.name}
                      style={pageStyle.artImage}
                    />
                  );
                }}
              </FilePreview>
            )}
            <Typography
              variant="bodyBold"
              color="secondary.light"
              className="version--2 secondary-link-style"
              style={pageStyle.artFileName}
            >
              {mockup.imageUrl ? mockup.filename : mockup.name}
            </Typography>
          </div>

          <FontIcon
            className="material-icons"
            style={{
              color: "#1D9ABB",
              fontSize: 26,
              marginRight: 8,
            }}
            onClick={this.handleDeleteMockUp(i)}
          >
            delete
          </FontIcon>
        </div>
      ))
    );
  };

  renderMockupsSection = () => {
    const { showMockupsUpload } = this.props;
    if (!showMockupsUpload) return null;
    return (
      <React.Fragment>
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer margin-bottom-9 margin-top-24"
          color="primary.light"
        >
          Mock-Ups
        </Typography>
        <div className="flex flex-wrap justify-content-center">
          {this.renderMockups()}
        </div>
        <span
          className="small-header new-order--add-link flex flex-center uppercase pointer margin-top-8 padding-left-24 z-index-3"
          style={{ color: "#1D9ABB" }}
          onClick={this.handleAddMockup}
        >
          <FontIcon
            className="material-icons"
            style={{
              color: "#1D9ABB",
              fontSize: 26,
              marginRight: 8,
            }}
          >
            add_circle_outline
          </FontIcon>
          <span>Add mockup</span>
        </span>
        <input
          type="file"
          onChange={this.handleFileChange}
          className="hidden-safe"
          style={{ display: "none" }}
          name="mockupFile"
          tabIndex={-1}
          ref={input => (this.inputField = input)}
        />
      </React.Fragment>
    );
  };

  handleCreate = () => {
    const { onEditItem } = this.props;
    const {arts} = this.state;
    if(arts.length > 0) {
      onEditItem({ ...this.state, quantity: 0, unitCost: 0 });

    }else{
      onEditItem({ ...this.state });
    }
  };

  isSaveButtonDisabled = () => {
    const { product, fabric, color, arts } = this.state;
    const artsOk =
      arts && arts.length > 0
        ? !arts.every(({ art, adornmentLocation }) => art && adornmentLocation)
        : false;
    return !product || !fabric || !color || artsOk;
  };

  render() {
    const {
      open,
      item,
      items,
      quoteArts,
      companyArts,
      locations,
      pageStyle,
      onClose,
      onCreateArt,
      onDeleteArt,
      openNotification,
    } = this.props;
    const { categoryId, product, fabric, color, arts, artModalOpen } = this.state;
    return (
      <Dialog
        modal={false}
        open={open}
        contentStyle={{ maxWidth: 640 }}
        bodyClassName="quote-item-edit-modal-content"
      >
        <span style={{ position: "absolute", right: 10, top: 10 }}>
          <FontIcon
            className="material-icons pointer"
            onClick={onClose}
            style={{
              fontSize: 16,
              borderRadius: 9,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            close
          </FontIcon>
        </span>
        <Typography variant="h3" style={{ textAlign: "center" }}>
          Edit Product
        </Typography>

        <div className="flex justify-content-center">
          <ValidableSelect
            name="categoryId"
            label="Product Category"
            errorText={null}
            value={categoryId}
            style={{ width: 260, marginRight: 24 }}
            inputStyle={{ width: "100%" }}
            floatingLabelFixed={true}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            handleChange={this.handleChangeCategory}
            options={this.getCategoriesOptions()}
            hintText="Select"
          />
          <ValidableSelect
            name="product"
            label="Select Product"
            errorText={null}
            value={product && product.id}
            style={{ width: 260 }}
            floatingLabelFixed={true}
            inputStyle={{ width: "100%" }}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
              autoWidth: true,
            }}
            handleChange={this.handleChangeProduct}
            options={this.getProductOptions()}
            hintText="Select"
          />
        </div>

        {this.hasFabrics() && (
          <div className="flex justify-content-center">
            <ValidableSelect
              disabled={!product}
              name="fabric"
              label="Fabric"
              errorText={null}
              value={fabric && fabric.id}
              style={{ width: 260, marginRight: 24 }}
              inputStyle={{ width: "100%" }}
              floatingLabelFixed={true}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
              }}
              handleChange={this.handleChangeFabric}
              options={this.getFabricsOptions()}
              hintText="Select"
            />
            <ValidableSelect
              disabled={!fabric}
              name="color"
              label="Fabric Color"
              errorText={null}
              value={color && color.id}
              style={{ width: 260 }}
              floatingLabelFixed={true}
              inputStyle={{ width: "100%" }}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
                autoWidth: true,
              }}
              handleChange={this.handleChangeColor}
              options={this.getColorsOptions()}
              hintText="Select"
            />
          </div>
        )}
        {this.renderArtList()}
        <div className="flex">
          <span
            className="small-header new-order--add-link flex flex-center uppercase pointer margin-right-20 z-index-3"
            style={{
              marginTop: 24,
              marginLeft: 18,
              color: "#1D9ABB",
              width: 256,
            }}
            onClick={this.handleOpenArtModal}
          >
            <FontIcon
              className="material-icons"
              style={{
                color: "#1D9ABB",
                fontSize: 26,
                marginRight: 8,
              }}
            >
              add_circle_outline
            </FontIcon>
            <span>Add art</span>
          </span>
          {locations &&
            arts &&
            arts.length < locations.length && (
            <span
              className="small-header new-order--add-link flex flex-center uppercase pointer padding-left-8 z-index-3"
              style={{
                marginTop: 24,
                color: "#1D9ABB",
              }}
              onClick={this.handleAddArtLocation}
            >
              <FontIcon
                className="material-icons"
                style={{
                  color: "#1D9ABB",
                  fontSize: 26,
                  marginRight: 8,
                }}
              >
                  add_circle_outline
              </FontIcon>
              <span>Add another art location</span>
            </span>
          )}
        </div>
        {this.renderMockupsSection()}
        <ChillButton
          isDisabled={this.isSaveButtonDisabled()}
          onClick={this.handleCreate}
          name="Save"
          useFontIcon={true}
          icon="check"
          fontIconStyles={{
            color: "#FFFFFF",
            fontSize: 18,
            marginRight: 4,
          }}
          className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
          height={40}
          marginTop={40}
          marginBottom={40}
        />
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer uppercase margin-bottom-24"
          color="primary.light"
          onClick={onClose}
        >
          Cancel
        </Typography>
        <QuoteArtModal
          open={artModalOpen}
          arts={quoteArts}
          companyArts={companyArts}
          item={item}
          items={items}
          pageStyle={pageStyle}
          onCreateArt={onCreateArt}
          onDeleteArt={onDeleteArt}
          onClose={this.handleCloseArtModal}
          openNotification={openNotification}
        />
      </Dialog>
    );
  }
}

QuoteItemEditModal.propTypes = {
  open: PropTypes.bool,
  showMockupsUpload: PropTypes.bool,
  item: PropTypes.object,
  items: PropTypes.array,
  categories: PropTypes.array,
  products: PropTypes.array,
  fabrics: PropTypes.array,
  colors: PropTypes.array,
  locations: PropTypes.array,
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
  quoteArts: PropTypes.array,
  companyArts: PropTypes.array,
  fetchProducts: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fetchAdornmentLocationsByProduct: PropTypes.func,
  onEditItem: PropTypes.func,
  onClose: PropTypes.func,
  onCreateArt: PropTypes.func,
  onDeleteArt: PropTypes.func,
  openNotification: PropTypes.func,
  totalArts: PropTypes.number,
  onUpdateItemQuantityValue: PropTypes.func,
  handleSetStateQuote: PropTypes.func,
};

export default QuoteItemEditModal;
