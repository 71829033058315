import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import lodash from "lodash";
import Typography from "../../../shared/Typography";
import ProductImage from "../../../../pages/Products/Components/ProductImage";
import { Link } from "react-router-dom";
import CNGToolTip from "../../../../shared/CNGToolTip";


class ProductDisabled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipActive: false,
      tooltipId: null,
      tooltipMessage: "",
    };
  }

  render() {
    const { orderItemProduct, selectedItems, getSubTitleText } = this.props;
    const {
      product,
      fabricColor,
      leatherColor,
      orderItemProductAdornmentLocationArtAssignments,
      orderItem,
      company,
    } = orderItemProduct;

    const orderItemImage =
      lodash.get(orderItemProductAdornmentLocationArtAssignments, "[0]") || {};
    const url = orderItemImage.filename
      ? orderItemImage.imageUrl
      : lodash.get(orderItem, "product.productImages[0].imageUrl");

    return (
      <div
        className={classNames("product-item", {
          selected: selectedItems.includes(orderItemProduct.id),
        })}
        key={orderItemProduct.id}
      >
        <div className="product-container">
          <div
            className="product-image pointer"
            style={{ opacity: 0.4, cursor: "not-allowed" }}
            title={"Product archived"}
          >
            <ProductImage
              src={url}
              emptyStyle={{
                maxHeight: "inherit",
                width: "100%",
                margin: 0,
              }}
              style={{
                maxHeight: "inherit",
                objectFit: "contain",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div className="product-info">
          <Typography
            variant="subtitle"
            className={"flex"}
            id={product.id}
          >
            Item #{lodash.get(product, "itemNumber")}
            <div className="flex" title="Product archived">
              <CNGToolTip
                parentRef={`product-${product.id}`}
                element={{ text: "Contact CNG to resurrect" }}
              >
                <img
                  src={"/assets/imgs/icons/warning.svg"}
                  style={{cursor:"help", marginLeft:"4px", marginBottom:"2px"}}
                  className="btn-icon-small"
                />
              </CNGToolTip>
            </div>
          </Typography>
          <div
            className="reset-link-styles product-info--name"
            title={"Product archived"}
          >
            <Typography
              variant="h5"
              style={{lineHeight: "16px"}}
              className="overflow-ellipsis"
            >
              {lodash.get(product, "productName")}
            </Typography>
          </div>
          <Typography variant="body" className="overflow-ellipsis">
            {fabricColor && getSubTitleText(fabricColor, leatherColor)}
          </Typography>
          <Typography variant="bodyBold" className="overflow-ellipsis">
            Last order:
            <Link
              to={`/companies/${lodash.get(company, "id")}/orders/${lodash.get(
                orderItem,
                "order.id"
              )}`}
              style={{marginLeft: 4}}
              className="reset-link-styles"
            >
              {lodash.get(orderItem, "order.cngReferenceNumber")}
            </Link>
          </Typography>
        </div>
      </div>
    );
  }
}

ProductDisabled.propTypes = {
  selectedItems: PropTypes.array,
  getSubTitleText: PropTypes.func,
  orderItemProduct: PropTypes.object,
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
};

export default ProductDisabled;
