import React, {Component} from "react";
import PropTypes from "prop-types";

import Margin from "./Layout/Spacings/Margin";

export default class Divider extends Component {
  static propTypes = {
    height: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const {height, className} = this.props;
    return (
      <Margin
        className={`divider ${className ? className : ""}`}
        style={{
          height,
        }}
        {...this.props}
      />
    );
  }
}
