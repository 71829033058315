import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import Divider from "../../shared/Divider";

import UserDetailCardHeader from "./components/UserDetailCardHeader";
import UserDetailInfo from "./components/UserDetailInfo";
import UserDetailTabs from "./components/UserDetailTabs";

export default class UserDetailPage extends Component {
  static propTypes = {
    userDetail: PropTypes.object,
    archiveUser: PropTypes.func,
    userId: PropTypes.string,
    getUser: PropTypes.func,
    getCompany: PropTypes.func,
    createUserNote: PropTypes.func,
    deleteUserNote: PropTypes.func,
    companies: PropTypes.array,
    updateUserWithContact: PropTypes.func,
    navigateTo: PropTypes.func,
    fetchUser: PropTypes.func,
    isInternal: PropTypes.bool,
    company: PropTypes.object,
    loggedInUserId: PropTypes.number,
    isLoading: PropTypes.bool,
    useCompanyBreadcrumbs: PropTypes.bool,
    userNoteRequestLoading: PropTypes.bool,
  };

  async archiveUser(userId) {
    const {archiveUser, navigateTo} = this.props;
    await archiveUser(userId);
    navigateTo("/users");
  }

  async createUserNote(newNote) {
    const {createUserNote} = this.props;
    createUserNote(newNote);
  }

  async deleteUserNote(noteId) {
    const {deleteUserNote} = this.props;
    deleteUserNote(noteId);
  }

  render() {
    const {
      userDetail,
      isInternal,
      company,
      loggedInUserId,
      isLoading,
      useCompanyBreadcrumbs,
      userNoteRequestLoading,
    } = this.props;

    if (!userDetail) {
      return null;
    }
    const recipient = _get(userDetail, "recipient", {});
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={userDetail ? userDetail.name : ""}
      >
        {useCompanyBreadcrumbs || !isInternal ? (
          <BreadCrumbs
            rootName={isInternal ? "Companies" : "Company"}
            rootUrl={"companies"}
            disableRootLink={!isInternal}
            middle={company && company.name}
            middleLink={company && `${company.id}`}
            pageName={userDetail ? userDetail.name || "" : ""}
          />
        ) : (
          <BreadCrumbs
            rootName={"Users"}
            pageName={userDetail ? userDetail.name || "" : ""}
          />
        )}
        <Card>
          <div>
            <UserDetailCardHeader
              companyId={company && company.id}
              isInternal={isInternal}
              userDetail={userDetail}
              useCompanyBreadcrumbs={useCompanyBreadcrumbs}
              archiveUser={userId => this.archiveUser(userId)}
            />
            <Divider marginTop={12} marginBottom={31} height={2} />
            <UserDetailInfo
              userDetail={recipient}
              titleFontSize={16}
              titleFontWeight="normal"
              titleTextTransform="none"
              titleFontColor="#4A5158"
            />{" "}
            {isInternal ? (
              <UserDetailTabs
                notes={userDetail.notes}
                userDetail={userDetail}
                userNoteRequestLoading={userNoteRequestLoading}
                deleteUserNote={id => this.deleteUserNote(id)}
                createUserNote={note => this.createUserNote(note)}
                loggedInUserId={loggedInUserId}
              />
            ) : (
              ""
            )}
          </div>
        </Card>
      </PageLayout>
    );
  }
}
