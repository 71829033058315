import React, {Component} from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import ChillButton from "../../../shared/ChillButton";
import OrderForm from "./OrderForm";
import {formStyles} from "../../../../styles/components/formStyles";
import lodash from "lodash";
import {Link} from "react-router-dom";

const buttonContainerStyle = {
  position: "absolute",
  right: 0,
  top: 0,
};

class OrderEditHeader extends Component {
  _handleCancel = event => {
    event.preventDefault();
    const {onToggleReorder} = this.props;
    onToggleReorder && onToggleReorder();
  };

  _renderButtons = () => {
    const {isInternal, order, isDSOD} = this.props;
    const order_path = isDSOD ? "dsod" : "orders";
    const cancelLink = `/companies/${lodash.get(
      order,
      "companyId"
    )}/${order_path}/${lodash.get(order, "id")}`;

    return (
      <div
        className="row button-container non-printable"
        style={buttonContainerStyle}
      >
        {!isInternal ? null : (
          <div className="row middle-xs">
            <Link to={cancelLink} className="text-cancel">
              Cancel
            </Link>
            <ChillButton
              className="btn--formCardHeader"
              name="SAVE"
              height={32}
              width={84}
              icon="check"
              onClick={this.props.onUpdateOrder}
            />
          </div>
        )}
      </div>
    );
  };

  _renderJobName = (isDSOD, orderData, onOrderChange) => {
    if (isDSOD) return;

    return (
      <TextField
        autoFocus
        type="text"
        name="job_name"
        value={orderData.job_name || ""}
        onChange={onOrderChange}
        hintText="Enter the current job name here"
        style={{
          color: "#747D86",
          width: "50%",
        }}
        inputStyle={{
          width: "100%",
          fontSize: 32,
        }}
        floatingLabelStyle={formStyles.TextFieldLabelStyles}
        underlineStyle={formStyles.underlineStyle}
      />
    );
  };

  _renderEditableName = () => {
    const {
      isDSOD,
      company,
      order,
      isInternal,
      shipments,
      orderData,
      shipmentData,
      companyShippingCarrierAssignments,
      shippingMethods,
      onOrderChange,
      onShipmentChange,
      billingCompanyContacts,
      paymentTerms,
    } = this.props;

    const isMultiple = shipments && shipments[0].isMultiple;

    return (
      <div className="start-xs middle-xs">
        {this.isFullyShipped() ? null : (
          <div className="row start-xs middle-xs">
            Order status:
            <span className="subtext font-weight-bold margin-left-10">
              {lodash.get(order, "status.name")}
            </span>
          </div>
        )}
        {this._renderJobName(isDSOD, orderData, onOrderChange)}
        <OrderForm
          inputCostEditable={!isMultiple}
          isDSOD={isDSOD}
          order={order}
          isInternal={isInternal}
          shipments={shipments}
          shippingMethods={shippingMethods}
          orderData={orderData}
          shipmentData={shipmentData}
          companyShippingCarrierAssignments={companyShippingCarrierAssignments}
          onOrderChange={onOrderChange}
          onShipmentChange={onShipmentChange}
          billingCompanyContacts={billingCompanyContacts}
          showInputCost={!isMultiple}
          company={company}
          paymentTerms={paymentTerms}
        />
      </div>
    );
  };

  isFullyShipped = () => {
    if (!this.props.order.status) return;
    return this.props.order.status.isClosed;
  };

  render() {
    const {containerClass} = this.props;

    return (
      <div
        className={`pageIndexHeader row between-xs middle-xs ${containerClass}`}
        style={{
          marginBottom: 0,
          position: "relative",
          width: "100%",
        }}
      >
        {this._renderEditableName()}
        {this._renderButtons()}
      </div>
    );
  }
}

OrderEditHeader.propTypes = {
  onOrderChange: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  containerClass: PropTypes.string,
  titleIcon: PropTypes.string,
  onToggleReorder: PropTypes.func,
  isInternal: PropTypes.bool,
  isDSOD: PropTypes.bool,
  isReordering: PropTypes.bool,
  company: PropTypes.object,
  order: PropTypes.object,
  orderData: PropTypes.object,
  shipmentData: PropTypes.object,
  orderStatus: PropTypes.array,
  shipments: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingMethods: PropTypes.array,
  history: PropTypes.object,
  billingCompanyContacts: PropTypes.array,
  paymentTerms: PropTypes.array,
};

export default OrderEditHeader;
