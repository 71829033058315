const validationFormats = {
  name: {
    regex: /^[a-z ,.'-]+$/gi,
    message: "must be a valid name",
  },
  flexibleName: {
    regex: /^.+$/gi,
    message: "must be a valid company name",
  },
  email: {
    regex: /^(.*)@(.*)\.(.*)$/gi,
    message: "must be a valid email",
  },
  phone: {
    regex: /^[0-9]{10}$/gi,
    message: "must be a valid phone",
  },
  zipcode: {
    regex: /^[0-9]{5}$/gi,
    message: "must be a valid zipcode",
  },
  any: {
    regex: /^[a-zA-Z0-9\s,.'-]*/gi,
    message: "is invalid",
  },
  integer: {
    regex: /^\d+$/gi,
    message: "must be an integer",
  },
  decimal: {
    regex: /^\d+(\.\d{1,2})?$/gi,
    message: "must be a decimal (2 digits of precision)",
  },
  resaleNumber: {
    regex: /^[a-zA-Z0-9-]*$/gi,
    message: "must be a valid resale number",
  },
};

const lengthConfigs = length => ({
  1: {
    regex: `^.{${length[0]},${length[0]}}$`,
    message: `must be ${length[0]} characters long`,
  },
  2: {
    regex: `^.{${length[0]},${length[1]}}$`,
    message: `must be between ${length[0]} and ${length[1]} characters`,
  },
});

export function validateForm(form, formData, isSubmitted = false) {
  if (!(form && form.validations)) {
    return {};
  }

  return Object.entries(form.validations).reduce(
    (
      errors,
      [attribute, {format, length, nullable, shouldMatch, shouldMatchMessage}]
    ) => {
      const isNullable = typeof nullable === "function" ? nullable() : nullable;
      const submitted = form.state.submitted || isSubmitted;

      const {regex, message} = validationFormats[format];
      const propValue =
        (formData && formData[attribute]) ||
        form.props[attribute] ||
        form.state[attribute];

      const propValueStr =
        typeof propValue !== "undefined" ? String(propValue) : "";

      const matchesValue =
        typeof shouldMatch !== "function" ? true : shouldMatch(propValue);

      const lengthConfig = length && lengthConfigs(length)[length.length];

      const lengthMismatch =
        length && !propValueStr.match(RegExp(lengthConfig.regex, "gi"));
      const lengthMismatchError = lengthMismatch
        ? lengthConfig.message
        : undefined;

      const formatMismatchError = !propValueStr.match(regex)
        ? message
        : undefined;
      const nonNullableError =
        submitted && !isNullable && !propValue ? "can't be blank" : undefined;
      const valueMismatch = !matchesValue ? shouldMatchMessage : undefined;
      const errorMessage =
        nonNullableError ||
        (propValue &&
          (formatMismatchError || lengthMismatchError || valueMismatch));

      return Object.assign(
        errors,
        errorMessage
          ? {
            [attribute]: errorMessage,
          }
          : undefined
      );
    },
    {}
  );
}
