import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function businessReducer(
  state = initialState.businesses,
  {type, payload}
) {
  switch (type) {
  case actions.FETCH_BUSINESSES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_BUSINESSES_SUCCESS:
    return {
      ...state,
      businesses: payload,
      isLoading: false,
    };
  case actions.FETCH_BUSINESSES_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  default:
    return state;
  }
}
