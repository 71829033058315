import React, {Component} from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import CircularProgress from "./CircularProgress";
import * as PropTypes from "prop-types";

export default class ContentLoader extends Component {
  state = {
    isLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    const {isLoading} = nextProps;
    if (this._ismounted) {
      this.setState({isLoading});
    }
  }
  componentDidMount() {
    this._ismounted = true;
  }
  componentWillUnmount() {
    this._ismounted = false;
  }

  _renderLoader() {
    const {customContainerStyles, loaderPops, zIndex} = this.props;
    const nextContainerStyles = {
      background: "#fff",
      pointerEvents: "none",
      zIndex,
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      ...customContainerStyles,
    };
    return (
      <div
        key="circular_progress"
        style={nextContainerStyles}
        className="flex flex-column align-items-center"
      >
        <CircularProgress
          {...(loaderPops ? loaderPops : {})}
          className="margin-auto"
        />
      </div>
    );
  }
  render() {
    const {isLoading} = this.state;
    const {children} = this.props;

    return (
      <ReactCSSTransitionGroup
        className="content-loader-wrapper"
        transitionName="loader"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        component="div"
        aria-busy={isLoading}
      >
        <div>
          {isLoading ? this._renderLoader() : null}
          {children}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

ContentLoader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  customContainerStyles: PropTypes.object,
  loaderPops: PropTypes.object,
  zIndex: PropTypes.number,
};

ContentLoader.defaultProps = {
  zIndex: 999,
};
