import React, {Component} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../styles/components/formStyles";
import ValidableTextField from "./ValidableTextField";

const customNoteLabelStyle = {
  ...TextFieldSmallLabelStyles,
  color: "#929da8",
  fontSize: 12,
  fontWeight: "bold !important",
  lineHeight: "16px",
  textTransform: "uppercase",
};

export default class NotesForm extends Component {
  static propTypes = {
    classContainer: PropTypes.string,
    order: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onSaveDraft: PropTypes.func,
    inputStyle: PropTypes.object,
    isInternal: PropTypes.bool,
  };

  state = {
    internalEditActive: false,
    userEditActive: false,
  };

  toggleInternalEditNote = () => {
    if (this.isReadable()) return;
    this.setState({internalEditActive: !this.state.internalEditActive});
  };

  toggleUserEditNote = () => {
    const {isInternal} = this.props;
    if (this.isReadable()) return;
    if (isInternal) return;
    this.setState({userEditActive: !this.state.userEditActive});
  };

  isReadable = () => {
    const {onChange} = this.props;
    return !onChange;
  };

  handleSaveChanges = () => {
    const {onSaveDraft} = this.props;
    onSaveDraft && onSaveDraft();
    this.toggleInternalEditNote();
  };

  handleSaveUserChanges = () => {
    const {onSaveDraft} = this.props;
    onSaveDraft && onSaveDraft();
    this.toggleUserEditNote();
  };

  getEditableContainer = (noteTitle, name, value, handleSaveChanges) => {
    const {onChange, inputStyle = {}} = this.props;
    return (
      <div className="editable">
        <div className="section-header">{noteTitle}</div>
        <ValidableTextField
          type="text"
          name={name}
          multiLine={true}
          className={"order-note"}
          label=""
          rowsMax={2}
          value={value || ""}
          onChange={(event, value) => onChange(event, value, name)}
          style={{
            ...formStyles.HalfTextInputStyles,
            width: "100%",
            ...inputStyle,
          }}
          floatingLabelStyle={customNoteLabelStyle}
          underlineStyle={formStyles.underlineStyle}
          inputStyle={{}}
          textareaStyle={{
            marginTop: 0,
            fontSize: 12,
            lineHeight: "16px",
            color: "#747D86",
          }}
          onBlur={handleSaveChanges}
          autoFocus={true}
        />
      </div>
    );
  };

  getDetailContainer = (noteTitle, value, handleClickEvent) => {
    return (
      <div onClick={handleClickEvent} className="details">
        <div className="section-header">{noteTitle}</div>
        <p style={{whiteSpace: "pre-line"}}>{value}</p>
      </div>
    );
  };

  render() {
    const {classContainer, order, isInternal} = this.props;
    const {userEditActive, internalEditActive} = this.state;
    const isReadable = this.isReadable();

    return (
      <div className={`${classContainer} order-note-container`}>
        <div className="note-title">Notes</div>
        <div
          style={{
            display: "flex",
          }}
        >
          {isInternal ? (
            <div
              className={classNames("order-note order-note--first", {
                "order-note--disabled": this.isReadable(),
                "order-note--editing": internalEditActive,
              })}
            >
              {internalEditActive
                ? this.getEditableContainer(
                  "Internal note",
                  "internalNotes",
                  order && order.internalNotes,
                  this.handleSaveChanges
                )
                : this.getDetailContainer(
                  "Internal note",
                  order.internalNotes,
                  this.toggleInternalEditNote
                )}
            </div>
          ) : null}

          <div
            className={classNames("order-note", {
              "order-note--disabled": isReadable || isInternal,
              "order-note--editing": userEditActive,
            })}
          >
            {userEditActive
              ? this.getEditableContainer(
                "Note from client",
                "clientNotes",
                order && order.clientNotes,
                this.handleSaveUserChanges
              )
              : this.getDetailContainer(
                "Note from client",
                order.clientNotes,
                this.toggleUserEditNote
              )}
          </div>
        </div>
      </div>
    );
  }
}
