import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import lodash from "lodash";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import FormattedPhone from "../../../../shared/FormattedPhone";
import ContentLoader from "../../../../shared/ContentLoader";
import FormattedAddress from "../../../../shared/FormattedAddress";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import SelectField from "material-ui/SelectField";
import {
  TextFieldSmallLabelStyles,
  formStyles,
} from "../../../../../styles/components/formStyles";

import IconMenu from "material-ui/IconMenu";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";

class AddressBookTable extends Component {
  static propTypes = {
    contacts: PropTypes.array,
    company: PropTypes.object,
    currentPage: PropTypes.number,
    searching: PropTypes.bool.isRequired,
    archiveCompanyContact: PropTypes.func,
    createWalletMember: PropTypes.func,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    history: PropTypes.object,
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  state = {
    newDefaultWalletMember: null,
    openArchiveModal: false,
    selectedRecipient: null,
  };

  confirmDefaultWallet = recipient => {
    this.setState({openArchiveModal: true, selectedRecipient: recipient});
  };

  _renderRow = (recipient, index) => {
    const {company} = this.props;
    const contactLink = `/companies/${lodash.get(
      company,
      "id"
    )}/contacts/${lodash.get(recipient, "id")}`;
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn highlight={true}>
          <Link to={contactLink} title={recipient.name}>
            {recipient.name}
          </Link>
          <div
            className="table-subtext"
            title={lodash.get(recipient, "companyName")}
          >
            {lodash.get(recipient, "companyName")}
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn title={recipient.emailAddress}>
          {recipient.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <FormattedPhone
            phoneNumber={recipient.phoneNumber}
            countryCode={lodash.get(recipient, "country.code")}
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            whiteSpace: "nowrap",
          }}
        >
          <FormattedAddress user={recipient} />
        </CNGTableRowColumn>

        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          {!recipient.userId ? (
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <MoreVertIcon color="#0C5371" />
                </IconButton>
              }
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              targetOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <CNGMenuItem
                primaryText="Archive"
                onClick={() => {
                  if (recipient.defaultWalletMember) {
                    return this.confirmDefaultWallet(recipient);
                  }
                  this.props.archiveCompanyContact(recipient);
                }}
              />
              {this.props.isInternal ? (
                <CNGMenuItem
                  primaryText="Add as User"
                  onClick={() => {
                    this.props.history.push(
                      `/companies/${
                        this.props.companyId
                      }/users/create?recipientId=${recipient.id}`
                    );
                  }}
                />
              ) : null}
            </IconMenu>
          ) : null}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  _hasContact(contacts) {
    return (
      lodash.get(contacts, "length", 0) < 1 || lodash.get(contacts, "results")
    );
  }

  _renderEmptyState = () => {
    const {isLoading, searching} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <div
          className="flex flex-column align-items-center"
          style={{
            minHeight: "82px",
          }}
        >
          <h2 className="userindex_noUsers">
            No contacts {searching ? "found" : "yet"}.
          </h2>
        </div>
      </ContentLoader>
    );
  };

  render() {
    const header = [
      "NAME & COMPANY",
      "E-MAIL",
      "PHONE",
      "ADDRESS",
      {
        customStyles: {
          width: 100,
        },
      },
    ];

    const {contacts, isLoading, company} = this.props;
    const {selectedRecipient} = this.state;
    if (this._hasContact(contacts)) {
      return this._renderEmptyState();
    }
    if (!Array.isArray(contacts)) {
      return (
        <CNGTable
          isLoading={isLoading}
          tableHeader={header}
          displayRowCheckbox={false}
        >
          {this._renderRow(contacts)}
        </CNGTable>
      );
    }
    return (
      <React.Fragment>
        <CNGTable
          className="address-book-table"
          tableHeader={header}
          displayRowCheckbox={false}
          isLoading={isLoading}
        >
          {contacts && contacts.map(this._renderRow)}
        </CNGTable>
        <ConfirmationModal
          title="Company must have at least one wallet member."
          open={this.state && this.state.openArchiveModal}
          content={
            <div className={"flex flex-column align-items-center"}>
              Please select a new default wallet member contact.
              <SelectField
                floatingLabelText="Company contacts"
                floatingLabelStyle={TextFieldSmallLabelStyles}
                value={this.state.newDefaultWalletMember}
                onChange={(_, __, value) =>
                  this.setState({newDefaultWalletMember: value})
                }
                style={{
                  width: 200,
                }}
                iconStyle={{
                  right: "-16px",
                }}
                menuStyle={formStyles.selectMenuStyle}
                underlineStyle={formStyles.selectUnderlineStyle}
              >
                {lodash
                  .defaultTo(lodash.get(company, "contacts"), [])
                  .filter(
                    contact =>
                      Number(selectedRecipient && selectedRecipient.id) !==
                      Number(contact.id)
                  )
                  .map(contact => (
                    <CNGMenuItem
                      key={`company_association_${contact.id}`}
                      value={contact.id}
                      primaryText={contact.name}
                    />
                  ))}
              </SelectField>
            </div>
          }
          confirmText="Save"
          cancelText="Cancel"
          disabledConfirm={!this.state.newDefaultWalletMember}
          onConfirm={function() {
            const {
              createWalletMember,
              archiveCompanyContact,
              company,
            } = this.props;
            const {newDefaultWalletMember, selectedRecipient} = this.state;
            if (newDefaultWalletMember) {
              createWalletMember(
                company.id,
                {
                  isWalletDefaultMember: true,
                  isWalletMember: true,
                  company_contact_id: newDefaultWalletMember,
                },
                function() {
                  archiveCompanyContact(selectedRecipient);
                }
              );
            } else {
              archiveCompanyContact(selectedRecipient);
            }
            this.setState({openArchiveModal: false});
          }}
          onDismiss={function() {
            this.setState({openArchiveModal: false});
          }}
        />
      </React.Fragment>
    );
  }
}

export default AddressBookTable;
