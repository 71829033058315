import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchDiscountedRequest = () =>
  makeCommonAction(types.FETCH_DISCOUNTED_REQUEST);
const fetchDiscountedSuccess = payload =>
  makeCommonAction(types.FETCH_DISCOUNTED_SUCCESS, payload);
const fetchDiscountedError = error =>
  makeCommonAction(types.FETCH_DISCOUNTED_ERROR, error, true);

export function fetchDiscounted() {
  return makeActionCreator({
    endpoint: "/discountcategories",
    fetchOptions: {method: "GET"},
    requestFunc: fetchDiscountedRequest,
    successFunc: fetchDiscountedSuccess,
    errorFunc: fetchDiscountedError,
    error_message: "Error fetching discounted cateogories",
  });
}
