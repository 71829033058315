import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import NumberFormat from "react-number-format";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";

import {
  formStyles,
  SelectFieldIconStyle,
  TextFieldSmallLabelStyles,
} from "../../../../styles/components/formStyles";
import {formatAddress} from "../../../../utils/addressFormatter";

import CNGMenuItem from "../../../shared/CNGMenuItem";
import AttributeLine from "../../../shared/AttributeLine";
import {SHIPPING_ACCOUNT_NONE} from "../../../../constants/order";

const formInputStyle = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 23,
};

class OrderShipmentForm extends Component {

  state={
    errors: {}
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.companyShippingCarrierAssignments ===
      this.props.companyShippingCarrierAssignments
    ) {
      this.setDefaultShippingCarrier();
    }
    if (prevProps.errors !== this.props.errors) {
      this._handleError();
    }
  }

  getShipmentMethodsBySelectedAccount = () => {
    const {shippingMethods, shipmentData, onShipmentChange} = this.props;
    if (!shippingMethods) return [];

    const filteredShipments = shippingMethods.filter(
      shippingMethod =>
        Number(shippingMethod.shippingCarrierId) ===
        Number(shipmentData.shipping_carrier_id)
    );
    if (filteredShipments.length === 1 && !shipmentData.shipping_method_id) {
      onShipmentChange(
        null,
        Number(filteredShipments[0].id),
        "shipping_method_id"
      );
    }
    return filteredShipments;
  };

  renderShipmentOptionsBySelectedAccount = shipmentMethods => {
    return shipmentMethods.map(shippingMethod => {
      return (
        <CNGMenuItem
          key={shippingMethod.id}
          value={shippingMethod.id + ""}
          primaryText={shippingMethod.name}
        />
      );
    });
  };

  renderPaymentTermOptions = () => {
    const {paymentTerms} = this.props;
    if (!paymentTerms) return [];
    return paymentTerms.map(paymentTerm => (
      <CNGMenuItem
        key={paymentTerm.id}
        value={Number(paymentTerm.id)}
        primaryText={paymentTerm.name}
      />
    ));
  };

  renderPaymentTermField = () => {
    const {isInternal, orderData, onOrderChange, onSaveDraft, isDisabled} = this.props;
    if (!isInternal) {
      return (
        <AttributeLine
          labelText="Payment Terms"
          text={_get(orderData, "paymentTerm.name", "")}
          style={{
            ...formInputStyle,
            marginTop: 6,
          }}
        />
      );
    }
    return (
      <SelectField
        floatingLabelText="Payment Terms"
        name="paymentTermId"
        value={_get(orderData, "paymentTermId", "")}
        iconStyle={{
          ...SelectFieldIconStyle,
          right: 60,
          width: 40,
        }}
        onChange={(e, index, value) =>
          onOrderChange(null, value, "paymentTermId", onSaveDraft)
        }
        style={{...formInputStyle, width: 180}}
        inputStyle={{
          ...formStyles.HalfTextInputStyles,
          height: 68,
          width: 180,
        }}
        labelStyle={{
          ...formStyles.TextFieldLabelStyles,
          color: "#4A5158",
        }}
        underlineStyle={{...formStyles.underlineStyle, width: 180}}
        floatingLabelStyle={formStyles.TextFieldLabelStyles}
        menuStyle={formStyles.tqSelectMenuInputStyles}
        listStyle={formStyles.tqSelectMenuListStyles}
        disabled={isDisabled()}
      >
        {this.renderPaymentTermOptions()}
      </SelectField>
    );
  };

  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(event, value, "shipment_cost");
    }
  };

  handleChangeAccount = (event, index, value) => {
    const {
      onShipmentChange,
      companyShippingCarrierAssignments: shipmentAccounts,
      onShippingAccountNone,
      clearError,
    } = this.props;

    const shipmentAccount = shipmentAccounts.find(
      shipmentAccount => Number(shipmentAccount.id) === Number(value)
    );

    if (!shipmentAccount) return;
    if (shipmentAccount.accountNumber === SHIPPING_ACCOUNT_NONE) {
      clearError("orderQuantity");
      const continueChange = onShippingAccountNone(shipmentAccount);
      if (!continueChange) {
        return;
      }
    }
    if (!shipmentAccount.shippingCarrier) {
      return onShipmentChange(null, {
        shipping_carrier_name: "",
        shipping_carrier_id: null,
        shipment_account_number: shipmentAccount.accountNumber,
        shipment_account_id: Number(shipmentAccount.id),
        shipping_method_id: null,
      });
    }
    onShipmentChange(null, {
      shipping_carrier_name: shipmentAccount.shippingCarrier.name,
      shipping_carrier_id: shipmentAccount.shippingCarrier.id,
      shipment_account_number: shipmentAccount.accountNumber,
      shipment_account_id: Number(shipmentAccount.id),
      shipping_method_id: null,
    });
  };

  _handleError = () => {
    const {errors} = this.props;
    this.setState({errors});
  }

  setDefaultShippingCarrier = () => {
    const {
      companyShippingCarrierAssignments,
      shipmentData: {shipment_account_id},
    } = this.props;

    if (!shipment_account_id && companyShippingCarrierAssignments) {
      const defaultCarrierAssignment = companyShippingCarrierAssignments.find(
        ({isDefault}) => isDefault
      );
      if (defaultCarrierAssignment) {
        this.handleChangeAccount(
          null,
          defaultCarrierAssignment.id,
          defaultCarrierAssignment.id
        );
      }
    }
  };

  getCompanyShippingCarrierAssignments = () => {
    const {companyShippingCarrierAssignments, isDSOD} = this.props;
    if (!companyShippingCarrierAssignments) return [];
    return companyShippingCarrierAssignments.filter(
      companyShippingCarrierAssignment => {
        const isShippingAccountNone =
          companyShippingCarrierAssignment.accountNumber ===
          SHIPPING_ACCOUNT_NONE;
        return !isDSOD || !isShippingAccountNone;
      }
    );
  };

  renderShipment = () => {
    const {
      onShipmentChange,
      shipmentData,
      showInputCost,
      orderData,
      onOrderChange,
      onSaveDraft,
      billingCompanyContacts,
      renderBillTo,
      isDSOD,
      isInternal,
      isDisabled,
      clearError,
    } = this.props;
    const companyShippingCarrierAssignments = this.getCompanyShippingCarrierAssignments();

    const shipmentAccountNumber = _get(
      shipmentData,
      "shipment_account_number",
      ""
    );
    const showPaymentTerms = !isDSOD || !shipmentAccountNumber;
    const isShippingAccountNone =
      _get(shipmentData, "shipment_account_number") === SHIPPING_ACCOUNT_NONE;

    const shipmentMethods = this.getShipmentMethodsBySelectedAccount();
    const requiresRecipient = _get(orderData, "quote.requiresRecipient");

    return (
      <div
        className="flex align-items-center"
        style={{display: !!shipmentData}}
      >
        {renderBillTo && (
          <SelectField
            errorText={this.state.errors.billingCompanyContactId}
            floatingLabelText="Bill To *"
            floatingLabelStyle={formStyles.TextFieldLabelStyles}
            name="billingCompanyContactId"
            value={orderData && orderData.billingCompanyContactId + ""}
            onChange={(_, __, value) => {
              onOrderChange(
                null,
                value,
                "billingCompanyContactId",
                onSaveDraft
              );
            }}
            ref={el => (this.contactSelectRef = el)}
            style={{
              ...formInputStyle,
            }}
            inputStyle={{
              ...formStyles.HalfTextInputStyles,
              height: 68,
            }}
            iconStyle={{
              ...formStyles.HalfIconStyles,
              ...SelectFieldIconStyle,
              marginRight: 50,
            }}
            menuStyle={{
              ...formStyles.MenuStyle,
              width: 250,
            }}
            underlineStyle={formStyles.underlineStyle}
            labelStyle={{
              ...formStyles.TextFieldLabelStyles,
              color: "#4A5158",
            }}
            disabled={isDisabled()}
          >
            {billingCompanyContacts.length > 0 ? (
              billingCompanyContacts.map(companyContact => {
                const formattedAddress = formatAddress(companyContact);
                return (
                  <CNGMenuItem
                    key={companyContact.id}
                    value={companyContact.id + ""}
                    primaryText={companyContact.name}
                    title={formattedAddress && formattedAddress.title}
                  />
                );
              })
            ) : (
              <CNGMenuItem primaryText="No Users in Company Wallet" disabled />
            )}
          </SelectField>
        )}
        <SelectField
          errorText={this.state.errors.shipment_account_id}
          floatingLabelText="Shipping Account *"
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          name="shipment_account_id"
          value={shipmentData && Number(shipmentData.shipment_account_id)}
          onChange={this.handleChangeAccount}
          style={{
            ...formInputStyle,
            width: isShippingAccountNone ? 250 : formInputStyle.width,
          }}
          inputStyle={{
            ...formStyles.HalfTextInputStyles,
            height: 68,
            width: isShippingAccountNone
              ? 260
              : formStyles.HalfTextInputStyles.width,
          }}
          underlineStyle={formStyles.underlineStyle}
          iconStyle={SelectFieldIconStyle}
          autoWidth={true}
          labelStyle={{
            ...formStyles.TextFieldLabelStyles,
            color: "#4A5158",
          }}
          disabled={isDisabled()}
        >
          {companyShippingCarrierAssignments.map(
            companyShippingCarrierAssignment => {
              return (
                <CNGMenuItem
                  key={Number(companyShippingCarrierAssignment.id)}
                  value={Number(companyShippingCarrierAssignment.id)}
                  primaryText={companyShippingCarrierAssignment.name}
                />
              );
            }
          )}
        </SelectField>

        {!isShippingAccountNone && (
          <AttributeLine
            name="shipping_carrier_name"
            labelText="Shipping Company"
            text={_get(shipmentData, "shipping_carrier_name", "")}
            style={{
              ...formInputStyle,
              width: 150,
              marginTop: 6,
            }}
          />
        )}

        {!isShippingAccountNone &&
          (shipmentMethods.length === 1 ? (
            <AttributeLine
              name="shipping_method_id"
              labelText="Shipping Method *"
              text={shipmentMethods[0].name}
              style={{
                ...formInputStyle,
                width: 150,
                marginTop: 6,
              }}
            />
          ) : (
            <SelectField
              errorText={this.state.errors.shipping_method_id}
              disabled={
                (isDisabled()) ||
                !(shipmentData && shipmentData.shipment_account_id)
              }
              floatingLabelText={"Shipping Method *"}
              name="shipping_method_id"
              value={_get(shipmentData, "shipping_method_id", "") + ""}
              iconStyle={SelectFieldIconStyle}
              onChange={(_e, _index, value) => {
                onShipmentChange(null, Number(value), "shipping_method_id");
                clearError("shipping_method_id");
              }}
              style={{...formInputStyle, width: 180}}
              inputStyle={{
                ...formStyles.HalfTextInputStyles,
                height: 68,
                width: 180,
              }}
              labelStyle={{
                ...formStyles.TextFieldLabelStyles,
                color: "#4A5158",
              }}
              underlineStyle={{...formStyles.underlineStyle, width: 180}}
              floatingLabelStyle={formStyles.TextFieldLabelStyles}
            >
              {this.renderShipmentOptionsBySelectedAccount(shipmentMethods)}
            </SelectField>
          ))}

        {!isShippingAccountNone && (
          <AttributeLine
            name="shipment_account_number"
            labelText="Account Number"
            text={shipmentAccountNumber}
            style={{
              ...formInputStyle,
              marginTop: 6,
            }}
          />
        )}

        {showInputCost ? (
          <NumberFormat
            thousandSeparator={true}
            prefix={"$"}
            customInput={TextField}
            floatingLabelText="Shipping Cost"
            type="text"
            name="shipment_cost"
            value={
              _get(shipmentData, "shipment_cost", "") ||
              _get(shipmentData, "shipmentCost", "")
            }
            onValueChange={(values, event) => {
              return this.handleChangeWithMaxLength(
                event,
                values.value,
                onShipmentChange,
                20
              );
            }}
            disabled={
              (!isInternal && requiresRecipient) ||
              (this.props.inputCostEditable ? false : true)
            }
            style={formStyles.SmallInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
          />
        ) : null}

        {showPaymentTerms && this.renderPaymentTermField()}
      </div>
    );
  };

  render() {
    return this.renderShipment();
  }
}

OrderShipmentForm.defaultProps = {
  clearError: () => {}
};

OrderShipmentForm.propTypes = {
  onShipmentChange: PropTypes.func,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingMethods: PropTypes.array,
  paymentTerms: PropTypes.array,
  shipment: PropTypes.object,
  shipmentData: PropTypes.object,
  showInputCost: PropTypes.bool,
  company: PropTypes.object,
  inputCostEditable: PropTypes.bool,
  isDSOD: PropTypes.bool,
  orderData: PropTypes.object,
  onOrderChange: PropTypes.func,
  onSaveDraft: PropTypes.func,
  billingCompanyContacts: PropTypes.array,
  renderBillTo: PropTypes.bool,
  isInternal: PropTypes.bool,
  isDisabled: PropTypes.func,
  onShippingAccountNone: PropTypes.func,
  errors: PropTypes.object,
  clearError: PropTypes.func,
};

export default OrderShipmentForm;
