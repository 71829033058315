import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {parse, stringify} from "query-string";

/**
 * A public higher-order component to include pagination feature
 */

function withPagination(WrappedComponent) {
  class Pagination extends Component {
    static propTypes = {
      location: PropTypes.object,
      history: PropTypes.object,
    };

    componentWillMount() {
      this._initParams();
    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this._initParams();
      }
    }

    state = {
      searchTerm: "",
      page: 1,
    };

    _initParams() {
      const {location: {search}} = this.props;
      const params = parse(search);
      const {query = "", page = 1} = params;
      this.setState({searchTerm: query, page: Number(page)});
    }

    setSearchTerm = searchTerm => {
      this.addQueryParam({query: searchTerm, page: 1});
      this.setState({searchTerm, page: 1});
    };

    setPage = page => {
      this.addQueryParam({page});
      this.setState({page});
    };

    addQueryParam = params => {
      const {location, history} = this.props;
      const search = stringify(
        Object.assign({}, parse(location.search), params)
      );

      history.replace({search});
      this.setState(params);
    };

    render() {
      return (
        <WrappedComponent
          setSearchTerm={this.setSearchTerm}
          setPage={this.setPage}
          searchTerm={this.state.searchTerm}
          page={this.state.page}
          addQueryParam={this.addQueryParam}
          {...this.props}
        />
      );
    }
  }
  return withRouter(Pagination);
}
export default withPagination;
