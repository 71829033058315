import React, {Component} from "react";
import PropTypes from "prop-types";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";

import ChillButton from "../../../../shared/ChillButton";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import SearchInput from "../../../../shared/SearchInput";
import Pagination from "../../../../shared/Pagination";
import InventoryTable from "./InventoryTable";
import lodash from "lodash";

export default class InventoryTab extends Component {
  static propTypes = {
    searchInventory: PropTypes.func,
    inventoryItems: PropTypes.array,
    inventoryItemsCount: PropTypes.number,
    company: PropTypes.object,
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    addDSODItems: PropTypes.func,
    isLoading: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    setPage: PropTypes.func,
    searchTerm: PropTypes.string,
    page: PropTypes.number,
    selectedTab: PropTypes.string,
  };
  state = {
    currentPage: 1,
    selectedItems: [],
    openOrderModal: false,
  };

  searchInventory() {
    const {searchInventory, page, searchTerm} = this.props;
    searchInventory(null, searchTerm, {
      filter: "used_quantity,lt,quantity",
      page,
    });
  }

  componentDidMount() {
    this.searchInventory();
  }

  componentDidUpdate(prevProps) {
    const isActiveTab = this.props.selectedTab !== prevProps.selectedTab;

    if (
      this.props.selectedTab === "inventory" &&
      (isActiveTab || hasQueryParamsChanged(this.props, prevProps))
    ) {
      this.searchInventory();
    }
  }

  handleSelectPage = options => {
    const {setPage} = this.props;
    setPage(options.page);

    this.setState({
      currentPage: options.page,
    });
  };

  handleCreateDSOD = async () => {
    const {
      addDSODItems,
      companyId,
      isInternal,
      currentUserId,
      fetchOpenOrders,
    } = this.props;
    const {selectedItems} = this.state;
    if (!isInternal) {
      const allOpen = await fetchOpenOrders(companyId);
      const openOrders = allOpen.data.filter(order => order.isDsod);
      if (
        openOrders.length > 0 &&
        openOrders[0].createdUserId !== currentUserId
      ) {
        this.setState({openOrderModal: true});
        return;
      }
    }
    addDSODItems(companyId, selectedItems);
  };

  handleChangeSelectedItems(selectedItems) {
    this.setState({
      selectedItems,
    });
  }

  _handleSearchInventory(searchTerm) {
    const {setSearchTerm} = this.props;
    setSearchTerm(searchTerm);
  }

  _handleSearchInventoryDebounced = lodash.debounce(
    this._handleSearchInventory,
    300
  );

  _renderPagination() {
    const {inventoryItemsCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={options => this.handleSelectPage(options)}
          count={inventoryItemsCount}
          currentPage={this.props.page - 1}
        />
      </div>
    );
  }

  _renderInventoryTable() {
    const {
      inventoryItems,
      inventoryItemsCount,
      companyId,
      isLoading,
      searchTerm,
    } = this.props;
    return (
      <div className="tab-container">
        <InventoryTable
          isLoading={isLoading}
          companyId={companyId}
          inventoryItems={inventoryItems}
          inventoryItemsCount={inventoryItemsCount}
          selectedItems={this.state.selectedItems}
          onSelectedItemsChange={selectedItems =>
            this.handleChangeSelectedItems(selectedItems)
          }
          searching={!!searchTerm}
        />{" "}
        {inventoryItemsCount > 7 && inventoryItems && inventoryItems.length
          ? this._renderPagination()
          : null}
      </div>
    );
  }

  render() {
    const {isLoading, searchTerm, company} = this.props;
    const {selectedItems, openOrderModal} = this.state;
    return (
      <div
        className={
          this.props.inventoryItemsCount <= 10
            ? "tab-container"
            : "tab-container tab-container__pagination"
        }
      >
        <div className="tab_top">
          <div className="flex">
            <SearchInput
              search={(event, value) =>
                this._handleSearchInventoryDebounced(value)
              }
              initialValue={searchTerm}
            />
            <ChillButton
              name={"ADD TO DROP SHIP CART"}
              height={40}
              width={200}
              marginLeft={16}
              icon={"DSOD-white"}
              onClick={this.handleCreateDSOD}
              isDisabled={selectedItems.length === 0 || isLoading}
            />
          </div>
        </div>
        {this._renderInventoryTable()}
        <ConfirmationModal
          open={openOrderModal}
          height={400}
          title="OOPS - we’re sorry!!"
          content={
            <React.Fragment>
              <span>
                Another {company && company.name} user has your DSOD cart open.
                We can open only one cart for you at a time. Please be patient
                and try again later.
              </span>
              <br />
              <br />
              <span>Thanks for your understanding!</span>
              <br />
              <br />
              <span>Team chill-n-go</span>
            </React.Fragment>
          }
          confirmText="OK"
          cancelText=" "
          onConfirm={() => this.setState({openOrderModal: false})}
          onDismiss={() => this.setState({openOrderModal: false})}
        />
      </div>
    );
  }
}
