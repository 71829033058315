// RaiseButton is not really open to changes
import lodash from "lodash";
import React, {cloneElement} from "react";
import PropTypes from "prop-types";
import MUFlatButton from "material-ui/FlatButton";
import withStyles from "./WithStyles";

const borderRadius = 22;
const getMediumStyle = (props, buttonStyle) => {
  const {medium, labelStyle, style} = props;
  if (!medium) return buttonStyle;

  return {
    labelStyle: {
      ...lodash.defaultTo(labelStyle, {}),
      textTransform: "uppercase",
    },
    style: {
      ...lodash.defaultTo(style, {}),
      height: 32,
      minWidth: 130,
    },
  };
};

const getLargeStyle = (props, buttonStyle) => {
  const {large, labelStyle, style} = props;
  if (!large) return buttonStyle;

  return {
    labelStyle: {
      ...lodash.defaultTo(labelStyle, {}),
      textTransform: "uppercase",
    },
    style: {
      ...lodash.defaultTo(style, {}),
      height: 40,
      minWidth: 136,
    },
  };
};

const FlatButton = props => {
  const {
    icon,
    className,
    styles,
    style,
    labelStyle,
    large, // eslint-disable-line no-unused-vars
    medium, // eslint-disable-line no-unused-vars
    ...additionalProps
  } = props;

  let buttonStyle = {
    style,
    labelStyle,
  };

  buttonStyle = getMediumStyle(props, buttonStyle);
  buttonStyle = getLargeStyle(props, buttonStyle);

  return (
    <MUFlatButton
      {...additionalProps}
      labelStyle={{
        color: styles.colors.primary.light,
        ...buttonStyle.labelStyle,
      }}
      style={{...buttonStyle.style, borderRadius}}
      icon={
        icon &&
        cloneElement(icon, {
          style: styles.icon,
        })
      }
      className={[
        className,
        (additionalProps.secondary && "secondary") || "primary",
      ].join(" ")}
    />
  );
};

FlatButton.propTypes = {
  icon: PropTypes.node,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(FlatButton);
