import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FontIcon from "material-ui/FontIcon";
import Dialog from "material-ui/Dialog";

import { formatAddress } from "../../../../../utils/addressFormatter";
import { getCurrencyFormattedNumber } from "../../../../../utils/numberFormat";
import { status as statusData } from "../../../../../constants/quote";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import PageLayout from "../../../../shared/Layout/Layouts/PageLayout";
import AttributeLine from "../../../../shared/AttributeLine";
import withStyles from "../../../shared/WithStyles";
import Typography from "../../../shared/Typography";
import RaisedButton from "../../../shared/RaisedButton";
import ChatSystem from "../../../shared/ChatSystem";
import QuoteItemDetail from "./QuoteItemDetail";
import QuoteItemEditModal from "./QuoteItemEditModal";
import QuoteDigitizedArtForm from "./QuoteDigitizedArtForm";
import AssignCompanyModal from "./AssignCompanyModal";
import PhoneNumberInput from "../../../../shared/PhoneNumberInput";
import ContentLoader from "../../../../shared/ContentLoader";

const quoteIncludeOption =
  "[originalQuote.country,company.brands,country,contact.country]";

class QuoteDetail extends Component {
  state = {
    openRevertModal: false,
    openArchiveModal: false,
    openEditModal: false,
    openArtModal: false,
    openAssignCompanyModal: false,
    currentEditItemIndex: null,
    currentArtUUID: null,
    disabled: false,
    showOriginalQuote: false,
    quote: {},
    quoteLoaded: false,
  };

  form = null;
  chat = null;

  componentDidMount() {
    const {
      quoteId,
      fetchQuote,
      fetchCountries,
      fetchCategories,
      fetchAdornmentTypes,
      fetchDigitalizationTypePrices,
    } = this.props;
    fetchCountries();
    fetchQuote(quoteId, { include: quoteIncludeOption });
    fetchCategories();
    fetchAdornmentTypes();
    fetchDigitalizationTypePrices({
      sort: JSON.stringify([
        {
          field: "adornment_type",
          direction: "ASC",
        },
        {
          field: "complexity_level",
          direction: "ASC",
        },
      ]),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      quote,
      fetchArtRevisionsByCompany,
      clearArtRevisions,
      isLoading,
    } = this.props;
    if (isLoading !== prevProps.isLoading && !isLoading) {
      this.setState({ quote, quoteLoaded: true });
      if (quote.companyId) {
        fetchArtRevisionsByCompany(quote.companyId, {
          include: "[artSource.brand,adornmentType]",
        });
      } else {
        clearArtRevisions();
      }
    }
  }

  handleAssignQuoteToCompany = async () => {
    const {
      quoteId,
      assignQuoteToKnownCompany,
      openNotification,
    } = this.props;
    try {
      const result = await assignQuoteToKnownCompany(quoteId, {
        include: quoteIncludeOption,
      });

      if (result) {
        openNotification("Company assigned to Quote successfully");
      }
    } catch (error) {
      openNotification("Failed to assign quote to company", "error");
    }



  };


  handleCreateCompany = async () => {
    const { history, quote, quoteId } = this.props;

    history.push("/companies/create", { ...quote, quoteId });
  };

  handleQuoteChange = name => (event, value) => {
    this.setState(({ quote }) => ({ quote: { ...quote, [name]: value } }));
  };

  handleArtChange = (artUuid, name) => (event, value) => {
    this.setState(({ quote: { arts, ...quote } }) => {
      const artIndex = arts.findIndex(art => art.uuid === artUuid);
      return {
        quote: {
          ...quote,
          arts: [
            ...arts.slice(0, artIndex),
            {
              ...arts[artIndex],
              [name]: value,
            },
            ...arts.slice(artIndex + 1),
          ],
        },
      };
    });
  };

  handleItemChange = index => item => {
    this.setState(({ quote: { items, ...quote } }) => {
      item.artOption =
        _get(item, "arts", []).length > 0 ? "upload-art" : "no-art";
      return {
        quote: {
          ...quote,
          items: [...items.slice(0, index), item, ...items.slice(index + 1)],
        },
        openEditModal: false,
        currentEditItemIndex: null,
      };
    }, this.handleSaveQuote);
  };

  handleSaveQuote = async () => {
    const {
      openNotification,
      updateQuote,
      quoteId,
      quote: propsQuote,
    } = this.props;
    const { quote: quoteData } = this.state;
    if (_isEqual(quoteData, propsQuote)) return;
    const {
      originalQuote, // eslint-disable-line no-unused-vars
      ...quote
    } = quoteData;
    const result = await updateQuote(quoteId, _cloneDeep(quote), {
      include: quoteIncludeOption,
    });
    if (result) openNotification("Quote information updated successfully");
  };

  openArchiveModal = () => this.setState({ openArchiveModal: true });

  handleDismissArchiveModal = () => this.setState({ openArchiveModal: false });

  handleConfirmArchiveModal = async () => {
    const { deleteQuote, quoteId, history } = this.props;
    await deleteQuote(quoteId);
    history.replace("/");
  };

  handleRevertQuote = () => this.setState({ openRevertModal: true });

  handleDismissRevertModal = () => this.setState({ openRevertModal: false });

  handleConfirmRevertModal = async () => {
    const { updateQuote, quoteId } = this.props;
    await updateQuote(quoteId, { status: "new" }, { include: quoteIncludeOption });
    this.handleDismissRevertModal();
  };

  handleOpenEditModal = index => () => {
    this.setState({ currentEditItemIndex: index, openEditModal: true });
  };

  handleDismissEditModal = () => {
    this.setState({ currentEditItemIndex: null, openEditModal: false });
  };

  handleAddDigitizedArt = artUuid => () => {
    this.setState({ currentArtUUID: artUuid, openArtModal: true });
  };

  handleArtUnlink = (artUuid, itemId, index) => () => {
    this.setState(({ quote: { items, arts, ...quote } }) => {
      const newUuid = uuidv4();
      const art = arts && arts.find(art => art.uuid === artUuid);
      const itemIndex = items && items.findIndex(item => item.id === itemId);
      const item = items[itemIndex];
      return {
        quote: {
          ...quote,
          arts: [
            ...arts,
            {
              ..._cloneDeep(art),
              uuid: newUuid,
              digitization: art.digitization
                ? {
                  key: "none",
                  price: 0,
                  adornmentType: "No - chill-n-go has art",
                  adornmentTypeCode: "none",
                }
                : undefined,
              name: art.name ? `${art.name} 2` : undefined,
              copiedFrom: artUuid,
            },
          ],
          items: [
            ...items.slice(0, itemIndex),
            {
              ...item,
              arts: [
                ...item.arts.slice(0, index),
                {
                  ...item.arts[index],
                  art: newUuid,
                },
                ...item.arts.slice(index + 1),
              ],
            },
            ...items.slice(itemIndex + 1),
          ],
        },
      };
    }, this.handleSaveQuote);
  };

  handleDismissArtModal = () => {
    this.setState({ currentArtUUID: null, openArtModal: false });
  };

  handleEditArt = async art => {
    const { openNotification, updateQuote, quoteId } = this.props;
    if (!art.uuid) return;
    const arts = this.arts;
    const artIndex = arts.findIndex(({ uuid }) => uuid === art.uuid);
    if (artIndex < 0) return;
    const newArts = [
      ...arts.slice(0, artIndex),
      { ...art },
      ...arts.slice(artIndex + 1),
    ];
    this.setState(({ quote }) => ({
      quote: {
        ...quote,
        arts: newArts,
      },
      currentArtUUID: null,
      openArtModal: false,
    }));
    await updateQuote(quoteId, { arts: newArts }, { include: quoteIncludeOption });
    openNotification("Quote arts updated successfully");
  };

  handleCreateArt = (filename, file) => {
    const uuid = uuidv4();
    this.setState(({ quote: { arts, ...quote } }) => ({
      quote: {
        ...quote,
        arts: [...(arts || []), { uuid, filename, file }],
      },
    }));
  };

  handleDeleteArt = uuid => () => {
    this.setState(({ quote: { arts, ...quote } }) => {
      const index = arts.findIndex(art => art.uuid === uuid);
      return {
        quote: {
          ...quote,
          arts: [...arts.slice(0, index), ...arts.slice(index + 1)],
        },
      };
    });
  };

  getSubmitQuoteApprovalLabel = () => {
    const { quote: { noAdornment, noNewArt } } = this.props;
    if (noNewArt && !noAdornment) {
      return "Request Art Approval";
    }
    return "Submit Quote For Approval";
  };

  handleSubmitQuoteApproval = async () => {
    const { openNotification, updateQuote, quoteId, quote } = this.props;
    this.form.submit();
    if (this.form.isFormValid()) {
      let quoteStatus = _get(quote, "status");
      if (
        quoteStatus === "sent_for_art_approval" &&
        !quote.sendArtApprovalEmail
      ) {
        quoteStatus = "processing";
      }
      const status = _get(
        statusData,
        `${quoteStatus}.nextStatus${quote.noAdornment ? "NoAdornment" : ""}`
      );
      if (!status) return;
      const payload = { status };
      if (quoteStatus === "processing") payload.sendArtApprovalEmail = true;
      const result = await updateQuote(quoteId, payload, {
        include: quoteIncludeOption,
      });
      if (result && this.chat) {
        this.chat.handleSendMessage();
      }
      openNotification("Quote submitted for approval");
    }
  };

  handleCreateOrder = async () => {
    const { openNotification, updateQuote, quoteId, history } = this.props;
    const quote = await updateQuote(
      quoteId,
      { createOrder: true },
      { include: quoteIncludeOption }
    );
    if (!quote) return;
    if (quote.orderId) {
      history.push(`/companies/${quote.companyId}/reorders`, {
        fromQuote: true,
        quoteId,
      });
    }
    openNotification("Created open cart from quote");
  };

  handleError = () => {
    this.setState({ disabled: !this.form.isFormValid() });
  };

  hanldeViewOriginalQuote = () => {
    this.setState({ showOriginalQuote: true });
  };

  handleCompanyAssignment = async companyId => {
    const { quoteId, openNotification, updateQuote } = this.props;
    if (!companyId) return;
    await updateQuote(quoteId, { companyId }, { include: quoteIncludeOption });
    openNotification("Quote submitted for approval");
  };

  renderCompanyName = () => {
    const { quote: { originalQuote, company } } = this.props;
    const { showOriginalQuote, quote: { companyName } } = this.state;
    if (showOriginalQuote) {
      return (
        <AttributeLine
          labelText="Company Name"
          text={originalQuote.companyName}
          title={originalQuote.companyName}
          style={this.pageStyle.input}
        />
      );
    }
    if (!company) {
      return (
        <TextValidator
          name="companyName"
          floatingLabelText="Company Name"
          value={companyName}
          title={companyName}
          validators={["required"]}
          errorMessages={["this field is required"]}
          validatorListener={this.handleError}
          onChange={this.handleQuoteChange("companyName")}
          onBlur={this.handleSaveQuote}
        />
      );
    }
    if (!company) {
      return (
        <AttributeLine
          labelText="Company Name"
          text={companyName}
          title={companyName}
          style={this.pageStyle.input}
        />
      );
    }
    return (
      <Typography
        variant="h3"
        className="flex flex-center"
        title={company.name}
      >
        {company.name}
      </Typography>
    );
  };

  renderName = () => {
    const { quote: { originalQuote, status, contact }, isInternal } = this.props;
    const {
      showOriginalQuote,
      quote: { isKnownCompany, name, lastName },
    } = this.state;
    if (isKnownCompany) {
      if (!contact) return null;
      return (
        <AttributeLine
          labelText="Name"
          text={contact.name}
          style={{
            ...this.pageStyle.input,
            marginBottom: 4,
          }}
        />
      );
    }
    if (showOriginalQuote) {
      return (
        <AttributeLine
          labelText="Name"
          text={`${originalQuote.name} ${originalQuote.lastName}`}
          style={this.pageStyle.input}
        />
      );
    }
    if (isInternal && status === "new") {
      return (
        <Fragment>
          <TextValidator
            name="name"
            floatingLabelText="Name"
            value={name}
            validators={["required"]}
            errorMessages={["this field is required"]}
            validatorListener={this.handleError}
            onChange={this.handleQuoteChange("name")}
            onBlur={this.handleSaveQuote}
            style={{ marginRight: 24, width: 128 }}
          />
          <TextValidator
            name="lastName"
            floatingLabelText="Last Name"
            value={lastName}
            validators={["required"]}
            errorMessages={["this field is required"]}
            validatorListener={this.handleError}
            onChange={this.handleQuoteChange("lastName")}
            onBlur={this.handleSaveQuote}
            style={{ marginRight: 24, width: 128 }}
          />
        </Fragment>
      );
    }
    return (
      <AttributeLine
        labelText="Name"
        text={`${name} ${lastName}`}
        style={{ ...this.pageStyle.input, marginBottom: 4 }}
      />
    );
  };

  renderEmail = () => {
    const {
      quote: { originalQuote, company, isKnownCompany, contact },
    } = this.props;
    const { showOriginalQuote, quote: { emailAddress } } = this.state;
    if (isKnownCompany && contact) {
      return (
        <AttributeLine
          labelText="Email"
          text={contact.emailAddress}
          style={{ marginRight: 24, marginBottom: 4, whiteSpace: "nowrap" }}
        />
      );
    }
    if (showOriginalQuote) {
      return (
        <AttributeLine
          labelText="Email"
          text={originalQuote.emailAddress}
          style={{ marginRight: 24, whiteSpace: "nowrap" }}
        />
      );
    }
    if (!company) {
      return (
        <TextValidator
          name="email"
          floatingLabelText="Email"
          value={emailAddress}
          validators={["required", "isEmail"]}
          errorMessages={[
            "this field is required",
            "email address is not valid",
          ]}
          validatorListener={this.handleError}
          onChange={this.handleQuoteChange("emailAddress")}
          onBlur={this.handleSaveQuote}
          style={{ marginRight: 24 }}
        />
      );
    }
    return (
      <AttributeLine
        labelText="Email"
        text={emailAddress}
        style={{ marginRight: 12, whiteSpace: "nowrap" }}
      />
    );
  };

  renderPhoneNumber = () => {
    const { quote: { originalQuote } } = this.props;
    const {
      showOriginalQuote,
      quote: { phoneNumber, country, isKnownCompany, contact },
    } = this.state;
    if (isKnownCompany) {
      if (!contact) return null;
      return (
        <PhoneNumberInput
          component={TextValidator}
          style={{ marginRight: 24, whiteSpace: "nowrap" }}
          name="phoneNumber"
          floatingLabelText="Phone"
          value={contact.phoneNumber}
          countryCode={_get(contact, "country.code", "")}
          onChange={this.onChangePhoneNumber}
          autoComplete="off"
          margin="normal"
          className="margin-right-20"
        />
      );
    }
    if (showOriginalQuote) {
      return (
        <PhoneNumberInput
          component={TextValidator}
          style={{ marginRight: 24, whiteSpace: "nowrap" }}
          name="phoneNumber"
          floatingLabelText="Phone"
          value={originalQuote.phoneNumber}
          countryCode={_get(originalQuote, "country.code", "")}
          onChange={this.onChangePhoneNumber}
          autoComplete="off"
          margin="normal"
          className="margin-right-20"
        />
      );
    }
    return (
      <PhoneNumberInput
        component={TextValidator}
        style={{ marginRight: 24, whiteSpace: "nowrap" }}
        name="phoneNumber"
        floatingLabelText="Phone"
        value={phoneNumber}
        countryCode={_get(country, "code", "")}
        onChange={this.onChangePhoneNumber}
        autoComplete="off"
        margin="normal"
        className="margin-right-20"
      />
    );
  };

  renderAddress = () => {
    const { quote, countries } = this.props;
    const { quote: { isKnownCompany, contact } } = this.state;
    if (isKnownCompany) {
      if (!contact) return null;
      const {
        address1,
        address2: formatAddress2,
        city,
        stateCode,
        zipCode,
        countryCode,
      } = formatAddress(contact, countries);
      const address = `${address1}${formatAddress2}, ${city}, ${stateCode}, ${zipCode}, ${countryCode}`;
      return (
        <AttributeLine
          labelText="Address"
          text={address}
          style={{
            marginRight: 24,
            marginBottom: 4,
            height: undefined,
            minHeight: 27,
          }}
        />
      );
    }
    const {
      address1,
      address2: formatAddress2,
      city,
      stateCode,
      zipCode,
      countryCode,
    } = formatAddress(quote, countries);
    const address = `${address1}${formatAddress2}, ${city}, ${stateCode}, ${zipCode}, ${countryCode}`;
    return (
      <AttributeLine
        labelText="Address"
        text={address}
        style={{
          marginRight: 24,
          marginBottom: 4,
          height: undefined,
          minHeight: 27,
        }}
      />
    );
  };

  renderSubmitForApproval = () => {
    const { isLoading, quote } = this.props;
    const { disabled } = this.state;
    let status = _get(quote, "status");
    if (!status) return;
    if (
      ["sent_for_approval", "art_approved", "turned_to_order"].includes(status)
    )
      return;
    if (status === "sent_for_art_approval" && quote.sendArtApprovalEmail)
      return;
    if (status === "sent_for_art_approval" && !quote.sendArtApprovalEmail) {
      status = "processing";
    }
    const label = statusData[status].nextActionLabel;
    let isDisabled = isLoading || disabled;
    if (
      !(quote.noAdornment || quote.noNewArt) &&
      quote.status === "processing"
    ) {
      const usedArts = quote.arts.filter(art =>
        quote.items.find(item =>
          item.arts.find(itemArt => itemArt.art === art.uuid)
        )
      );
      isDisabled = isDisabled || usedArts.some(art => !art.digitizedFile);
    }
    return (
      <RaisedButton
        large
        disabled={isDisabled}
        label={
          <React.Fragment>
            <FontIcon
              className="material-icons"
              style={{ color: "white", padding: 4, fontSize: 20 }}
            >
              check
            </FontIcon>
            {label}
          </React.Fragment>
        }
        labelStyle={this.pageStyle.submitButtonLabel}
        className="margin-bottom-16"
        onClick={this.handleSubmitQuoteApproval}
      />
    );
  };
  getTotalDigitization = quote => {
    if (!quote) return 0;
    const { arts } = quote;
    if (!arts) return 0;
    return arts.reduce((total, { digitization }) => {
      if (!_get(digitization, "price")) return total;
      return total + digitization.price;
    }, 0);
  };

  getOrderTotal = () => {
    const { quote } = this.props;
    if (!quote) return { digitization: 0, total: 0, items: 0 };
    const { items } = quote;
    const digitization = this.getTotalDigitization(quote);
    if (!items) return { digitization, total: digitization, items: 0 };
    const itemsCost = items.reduce(
      (total, { unitCost, quantity }) =>
        total + (unitCost || 0) * (quantity || 0),
      0
    );
    return {
      digitization,
      items: itemsCost,
      total: itemsCost + digitization,
    };
  };

  artDigitizationRender = items => {
    if (!items) return;
    const arts = {};
    return items.map(item => {
      return item.arts.map(art => {
        const existed = arts[art.art];
        if (!existed) {
          arts[art.art] = true;
        }
        return !existed;
      });
    });
  };

  isCreateOrderDisabled = () => {
    const { isLoading, quote } = this.props;
    if (isLoading) return true;
    const usedArts = quote.arts.filter(art =>
      quote.items.find(item =>
        item.arts.find(itemArt => itemArt.art === art.uuid)
      )
    );
    return usedArts && usedArts.some(art => !art.brand);
  };

  get items() {
    const { quote: { originalQuote } } = this.props;
    const { showOriginalQuote, quote: { items } } = this.state;
    if (showOriginalQuote) {
      return originalQuote.items;
    }
    return items;
  }

  get arts() {
    const { quote: { originalQuote } } = this.props;
    const { showOriginalQuote, quote: { arts } } = this.state;
    if (showOriginalQuote) {
      return originalQuote.arts;
    }
    return arts;
  }

  get pageStyle() {
    const { styles: { colors } } = this.props;
    return {
      headerIcon: {
        fontSize: 32,
        color: colors.primary.light,
      },
      artFilename: {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artIcon: {
        padding: 4,
        color: colors.primary.light,
      },
      submitButtonLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      archiveIcon: {
        padding: 4,
        color: colors.primary.light,
      },
      input: {
        marginRight: 24,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      grayBackground: {
        backgroundColor: `${colors.gray.border}12`,
      },
      lightGrayBackground: {
        backgroundColor: `${colors.gray.border}1A`,
      },
      artFile: {
        marginTop: "auto",
        marginBottom: 8,
        width: 238,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artImage: {
        width: 32,
        height: 32,
        objectFit: "contain",
      },
      artFileName: {
        padding: "0 8px",
        whiteSpace: "nowrap",
        maxWidth: 230,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      deleteIcon: {
        fontSize: 26,
        marginRight: 8,
        color: colors.primary.light,
      },
      separator: {
        marginLeft: "10px",
        marginRight: "10px",
        fontWeight: "bold",
      },
      statusStyle: {
        paddingTop: 7,
        paddingRight: 16,
        paddingBottom: 6,
        paddingLeft: 16,
      },
    };
  }

  render() {
    const {
      isLoading,
      isLoadingPrices,
      quote,
      companyArts,
      itemPrices,
      categories,
      products,
      fabrics,
      colors,
      locations,
      adornmentTypes,
      digitalizationTypePrices,
      companies,
      isInternal,
      fetchProducts,
      fetchFabrics,
      fetchColors,
      fetchAdornmentLocationsByProduct,
      fetchItemPriceTable,
      openNotification,
      searchCompanies,
      fetchCompanies,
    } = this.props;
    const {
      quoteLoaded,
      showOriginalQuote,
      disabled,
      openRevertModal,
      openArchiveModal,
      openEditModal,
      openArtModal,
      openAssignCompanyModal,
      currentEditItemIndex,
      currentArtUUID,
      quote: { status, noAdornment, noNewArt },
    } = this.state;
    const statusValue = statusData[status] || {};
    const items = this.items;
    const arts = this.arts;
    const currentEditItem =
      currentEditItemIndex !== null ? items[currentEditItemIndex] : {};
    const currentArt =
      currentArtUUID !== null
        ? arts.find(({ uuid }) => uuid === currentArtUUID)
        : {};
    const artDigitizationRenderConfig = this.artDigitizationRender(items);
    const total = this.getOrderTotal();
    return (
      <PageLayout loaderEnable isLoading={isLoading}>
        <ContentLoader isLoading={!quoteLoaded && isLoading} zIndex={2}>
          <div className="breadcrumbs row middle-xs between-xs non-printable">
            <div className="row middle-xs start-xs">
              {showOriginalQuote ? (
                <span
                  className="pointer reset-link-styles small-header"
                  onClick={() => this.setState({ showOriginalQuote: false })}
                >
                  Back to Quote
                </span>
              ) : (
                <Link to="/" className="reset-link-styles small-header">
                  Back to Dashboard
                </Link>
              )}
            </div>
          </div>
          <div className="flex margin-bottom-30 justify-content-between">
            <Typography variant="h2" className="flex flex-center">
              <FontIcon
                className="material-icons margin-right-4"
                style={this.pageStyle.headerIcon}
              >
                local_atm
              </FontIcon>
              Quote Request
            </Typography>
            {showOriginalQuote ? (
              <Typography
                variant="bodyBold"
                className="flex flex-center"
                color="white"
                style={{ padding: 10, backgroundColor: "#747D86" }}
              >
                Initial Quote
              </Typography>
            ) : (
              <Typography
                variant="bodyBold"
                className="flex flex-center"
                color="white"
                style={{
                  ...this.pageStyle.statusStyle,
                  backgroundColor: statusValue.color,
                  position: "relative",
                }}
              >
                <FontIcon
                  className="material-icons"
                  style={{ color: "white", padding: 4, fontSize: 20 }}
                >
                  {statusValue.icon}
                </FontIcon>
                {(noAdornment || noNewArt) && statusValue.descriptionNoAdornment
                  ? statusValue.descriptionNoAdornment
                  : statusValue.description}
                {quote.furtherProofRequested && (
                  <Typography
                    variant="body"
                    style={{
                      position: "absolute",
                      top: "120%",
                      left: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Further proof requested
                  </Typography>
                )}
              </Typography>
            )}
          </div>
          <ValidatorForm
            ref={node => {
              if (node) this.form = node;
            }}
            onSubmit={() => { }}
            onError={this.handleError}
          >
            <div className="flex align-items-end margin-bottom-16">
              {this.renderCompanyName()}
              {status === "art_approved" &&
                !quote.company && (
                <RaisedButton
                  large
                  secondary
                  disabled={disabled}
                  label="Assign Quote To Company"
                  labelStyle={this.pageStyle.submitButtonLabel}
                  className="margin-bottom-8"
                  onClick={this.handleAssignQuoteToCompany}
                />
              )}
              {quote.requiresRecipient && (
                <Typography
                  className="margin-left-8"
                  variant="body"
                  style={{ whiteSpace: "nowrap", alignSelf: "center" }}
                >
                  Requires Recipient
                </Typography>
              )}
            </div>
            {status === "art_approved" &&
              !quote.company && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "8px",
                  marginLeft: "292px",
                  width: "fit-content",
                }}
              >
                {status === "art_approved" &&
                    !quote.company && (
                  <Typography
                    variant="body"
                    style={{
                      marginBottom: "8px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                        Or
                  </Typography>
                )}
                {status === "art_approved" &&
                    !quote.company && (
                  <a
                    href="#"
                    style={{
                      textDecoration: "none",
                      color: "rgb(12, 83, 113)",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                    onClick={this.handleCreateCompany}
                  >
                        Create the company
                  </a>
                )}
              </div>
            )}
            <div className="flex align-items-end margin-bottom-40 version--2">
              {this.renderName()}
              {this.renderEmail()}
              {this.renderPhoneNumber()}
              {this.renderAddress()}
            </div>
            <div className="margin-bottom-30">
              {items &&
                items.map((item, index) => (
                  <QuoteItemDetail
                    quoteId={quote.id}
                    quoteStatus={quote.status}
                    quoteArts={arts}
                    companyArts={companyArts}
                    quoteItems={items}
                    orderId={quote.orderId}
                    showBrand={
                      quote.status === "art_approved" && Boolean(quote.company)
                    }
                    brands={quote.company && quote.company.brands}
                    companyId={quote.companyId}
                    artDigitizationRenderConfig={
                      artDigitizationRenderConfig[index]
                    }
                    disableFields={showOriginalQuote}
                    key={item.id}
                    {...item}
                    isLoading={isLoading}
                    onEditItem={this.handleOpenEditModal(index)}
                    onAddDigitizedArt={this.handleAddDigitizedArt}
                    onArtChange={this.handleArtChange}
                    onFieldBlur={this.handleSaveQuote}
                    pageStyle={this.pageStyle}
                    validatorListener={this.handleError}
                    itemPrices={itemPrices}
                    isLoadingPrices={isLoadingPrices}
                    fetchItemPriceTable={fetchItemPriceTable}
                    openNotification={openNotification}
                    isInternal={isInternal}
                    onUnlinkArt={this.handleArtUnlink}
                  />
                ))}
            </div>
          </ValidatorForm>
          {isInternal ? null : (
            <div className="flex" style={{ width: "45%", alignSelf: "flex-end" }}>
              {total.digitization > 0 && (
                <div className="flex-one">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    One-time Set-up Costs
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{ margintop: 4, marginBottom: 8 }}
                  >
                    {getCurrencyFormattedNumber(total.digitization)}
                  </Typography>
                </div>
              )}

              {total.digitization > 0 && (
                <div className="flex-one">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Product Costs
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{ margintop: 4, marginBottom: 8 }}
                  >
                    {getCurrencyFormattedNumber(total.items)}
                  </Typography>
                </div>
              )}

              {total.total > 0 && (
                <div className="flex-one" style={{ marginLeft: 32 }}>
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Order Total before S/H
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{ margintop: 4, marginBottom: 8 }}
                  >
                    {getCurrencyFormattedNumber(total.total)}
                  </Typography>
                </div>
              )}
            </div>
          )}
          {!showOriginalQuote && (
            <footer
              style={this.pageStyle.footer}
              className="flex flex-column justify-content-center align-items-end"
            >
              {isInternal && (
                <Fragment>
                  {this.renderSubmitForApproval()}
                  {quote &&
                    quote.status === "art_approved" &&
                    quote.companyId &&
                    !quote.orderId && (
                    <RaisedButton
                      large
                      disabled={this.isCreateOrderDisabled()}
                      label={
                        <React.Fragment>
                          <FontIcon
                            className="material-icons-outlined"
                            style={{ color: "white", padding: 4, fontSize: 20 }}
                          >
                              shopping_cart
                          </FontIcon>
                            Create Order
                        </React.Fragment>
                      }
                      labelStyle={this.pageStyle.submitButtonLabel}
                      className="margin-bottom-16"
                      onClick={this.handleCreateOrder}
                    />
                  )}
                </Fragment>
              )}
              <div
                className={classNames("flex flex-center w-100", {
                  "justify-content-between": status !== "new",
                  "justify-content-end": status === "new",
                })}
              >
                <div>
                  {status === "new" ? null : (
                    <Typography
                      variant="bodyBold"
                      className="flex flex-center justify-content-center pointer"
                      color="primary.light"
                      onClick={this.hanldeViewOriginalQuote}
                    >
                      <FontIcon
                        className="material-icons"
                        style={this.pageStyle.archiveIcon}
                      >
                        list
                      </FontIcon>
                      View Original Client Quote Request
                    </Typography>
                  )}
                  {status === "sent_for_approval" ? (
                    <Typography
                      variant="bodyBold"
                      className="flex flex-center pointer"
                      color="primary.light"
                      style={{ marginTop: 16 }}
                      onClick={this.handleRevertQuote}
                    >
                      <FontIcon
                        className="material-icons"
                        style={this.pageStyle.archiveIcon}
                      >
                        undo
                      </FontIcon>
                      Revert Quote
                    </Typography>
                  ) : null}
                </div>
                <Typography
                  variant="bodyBold"
                  className="flex flex-center justify-content-center pointer"
                  color="primary.light"
                  onClick={this.openArchiveModal}
                >
                  <FontIcon
                    className="material-icons"
                    style={this.pageStyle.archiveIcon}
                  >
                    archive
                  </FontIcon>
                  Archive This Quote
                </Typography>
              </div>
              {quote &&
                (quote.isApproved ||
                  (quote.status !== "new" && (noAdornment || noNewArt))) ? (
                  <ChatSystem
                    setRef={ref => (this.chat = ref)}
                    type="quote"
                    typeData={quote}
                    typeIdName="uuid"
                    name={
                      isInternal ? undefined : quote.name || quote.companyName
                    }
                    userId={_get(quote, "contact.userId", undefined)}
                  />
                ) : null}
            </footer>
          )}

          <QuoteItemEditModal
            item={currentEditItem}
            items={items}
            quoteArts={arts}
            companyArts={companyArts}
            open={openEditModal}
            categories={categories}
            products={products}
            fabrics={fabrics}
            colors={colors}
            locations={locations}
            showMockupsUpload={
              !["new", "sent_for_approval"].includes(status) ||
              noAdornment ||
              noNewArt
            }
            fetchProducts={fetchProducts}
            fetchFabrics={fetchFabrics}
            fetchColors={fetchColors}
            fetchAdornmentLocationsByProduct={fetchAdornmentLocationsByProduct}
            onEditItem={this.handleItemChange(currentEditItemIndex)}
            onClose={this.handleDismissEditModal}
            pageStyle={this.pageStyle}
            onCreateArt={this.handleCreateArt}
            onDeleteArt={this.handleDeleteArt}
            openNotification={openNotification}
          />

          <Dialog
            modal={false}
            open={openArtModal}
            contentStyle={{ maxWidth: 760 }}
            bodyClassName="flex flex-column"
          >
            <span style={{ position: "absolute", right: 10, top: 10 }}>
              <FontIcon
                className="material-icons pointer"
                onClick={this.handleDismissArtModal}
                style={{
                  color: "#1D9ABB",
                  fontSize: 16,
                  borderRadius: 9,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                close
              </FontIcon>
            </span>
            {currentArtUUID && (
              <QuoteDigitizedArtForm
                art={currentArt}
                adornmentTypes={adornmentTypes}
                onEditArt={this.handleEditArt}
                onClose={this.handleDismissArtModal}
                openNotification={openNotification}
                digitalizationTypePrices={digitalizationTypePrices}
              />
            )}
          </Dialog>

          <AssignCompanyModal
            open={openAssignCompanyModal}
            initialCompanySearch={quote.companyName}
            companies={companies}
            pageStyle={this.pageStyle}
            fetchCompanies={fetchCompanies}
            searchCompanies={searchCompanies}
            onAssignToCompany={this.handleCompanyAssignment}
            onClose={this.handleDismissCompanyModal}
          />

          <ConfirmationModal
            title={
              "This action will archive this quote, do you want to continue?"
            }
            confirmText="Yes"
            cancelText="No"
            open={openArchiveModal}
            onConfirm={this.handleConfirmArchiveModal}
            onDismiss={this.handleDismissArchiveModal}
            height={250}
          />

          <ConfirmationModal
            title={
              "Reverting the status of this quote to New will allow editing and re-sending.  Continue?"
            }
            confirmText="Yes"
            cancelText="No"
            open={openRevertModal}
            onConfirm={this.handleConfirmRevertModal}
            onDismiss={this.handleDismissRevertModal}
            height={250}
          />
        </ContentLoader>
      </PageLayout>
    );
  }
}

QuoteDetail.propTypes = {
  quoteId: PropTypes.string,
  quote: PropTypes.object,
  companyArts: PropTypes.array,
  itemPrices: PropTypes.array,
  categories: PropTypes.array,
  products: PropTypes.array,
  fabrics: PropTypes.array,
  colors: PropTypes.array,
  locations: PropTypes.array,
  adornmentTypes: PropTypes.array,
  countries: PropTypes.array,
  companies: PropTypes.array,
  digitalizationTypePrices: PropTypes.array,
  fetchCountries: PropTypes.func,
  fetchQuote: PropTypes.func,
  fetchCategories: PropTypes.func,
  fetchProducts: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fetchAdornmentLocationsByProduct: PropTypes.func,
  fetchAdornmentTypes: PropTypes.func,
  updateQuote: PropTypes.func,
  deleteQuote: PropTypes.func,
  openNotification: PropTypes.func,
  fetchItemPriceTable: PropTypes.func,
  fetchCompanies: PropTypes.func,
  searchCompanies: PropTypes.func,
  assignQuoteToKnownCompany: PropTypes.func,
  fetchArtRevisionsByCompany: PropTypes.func,
  fetchDigitalizationTypePrices: PropTypes.func,
  clearArtRevisions: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingPrices: PropTypes.bool,
  isInternal: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(QuoteDetail);
