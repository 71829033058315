import React, {Component} from "react";
import PropTypes from "prop-types";
import lodashObject from "lodash/object";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import FormCardHeader from "../../shared/FormCardHeader";
import Divider from "../../shared/Divider";
import ContentLoader from "../../shared/ContentLoader";

import ArtForm from "./components/ArtForm";
import ArtTabs from "./components/ArtTabs";

export default class ArtCreatePage extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func,
    onCreateArt: PropTypes.func.isRequired,
    onSelectPage: PropTypes.func.isRequired,
    brand: PropTypes.object,
    adornmentTypes: PropTypes.array,
    filename: PropTypes.string,
    name: PropTypes.string,
    adornment_type_id: PropTypes.number,
    file: PropTypes.object,
    currentPage: PropTypes.number,
    details: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    selectedArt: PropTypes.object,
  };

  state = {
    submitted: false,
  };

  _getSelectedAdornmentType = () => {
    const {adornment_type_id, adornmentTypes = []} = this.props;

    return adornmentTypes.find(
      adornmentType => adornmentType.id == adornment_type_id
    );
  };

  render() {
    const {
      isLoading,
      onChange,
      onDetailChange,
      onFileChange,
      onCreateArt,
      brand,
      adornmentTypes,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={lodashObject.get(brand, "company.name")}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={"Companies"}
          middle={lodashObject.get(brand, "company.name")}
          middleLink={lodashObject.get(brand, "company.id")}
          middletwo={lodashObject.get(brand, "name")}
          middletwoLink={`brands/${lodashObject.get(brand, "id")}`}
          pageName="Create New"
        />
        <Card>
          <ContentLoader
            isLoading={isLoading}
            customContainerStyles={{width: "100%", height: 550}}
          >
            <div className={isLoading ? ".hidden" : ""}>
              <FormCardHeader
                title="New Art"
                cancelLink={`/companies/${lodashObject.get(
                  brand,
                  "companyId"
                )}/brands/${lodashObject.get(brand, "id")}`}
                buttonText="SAVE"
                buttonIcon="check"
                buttonClick={() => this.setState({submitted: true})}
                buttonHeight={32}
                buttonWidth={84}
              />
              <Divider marginBottom={24} marginTop={24} />
              <ArtForm
                onChange={onChange}
                onDetailChange={onDetailChange}
                onFileChange={onFileChange}
                adornmentTypes={adornmentTypes}
                submitSuccess={onCreateArt}
                submitError={() => this.setState({submitted: false})}
                submitted={this.state.submitted}
                {...this.props}
              />
            </div>
          </ContentLoader>
          <ArtTabs revisions={[]} brand={brand} isLoading={isLoading} />{" "}
        </Card>
      </PageLayout>
    );
  }
}
