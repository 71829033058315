import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function quoteReducer(
  state = initialState.quotes,
  {type, payload, meta}
) {
  switch (type) {
  case actions.CREATE_QUOTE_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_QUOTE_SUCCESS:
    return {
      ...state,
      quote: payload,
      isLoading: false,
    };
  case actions.FETCH_QUOTE_REQUEST:
    return {
      ...state,
      isLoadingQuote: true,
    };
  case actions.FETCH_QUOTE_SUCCESS:
    return {
      ...state,
      quote: payload,
      isLoadingQuote: false,
    };
  case actions.FETCH_QUOTES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_QUOTES_SUCCESS:
    return {
      ...state,
      quotes: payload,
      quotesCount: meta ? meta.count : payload.length,
      isLoading: false,
    };
  case actions.FETCH_QUOTES_UNREAD_COUNTS:
    return {
      ...state,
      unreadCounts: payload,
    };
  case actions.UPDATE_QUOTE_REQUEST:
    return {
      ...state,
      isLoadingQuote: true,
    };
  case actions.UPDATE_QUOTE_SUCCESS:
    return {
      ...state,
      isLoadingQuote: false,
      quote: payload,
    };
  case actions.DELETE_QUOTE_REQUEST:
    return {
      ...state,
      isLoadingQuote: true,
    };
  case actions.DELETE_QUOTE_SUCCESS:
    return {
      ...state,
      isLoadingQuote: false,
      quote: null,
    };
  case actions.FETCH_QUOTE_ITEM_PRICES_REQUEST:
    return {
      ...state,
      isLoadingPrices: true,
    };
  case actions.FETCH_QUOTE_ITEM_PRICES_SUCCESS:
    return {
      ...state,
      itemPrices: payload,
      isLoadingPrices: false,
    };

  case actions.UPLOAD_ART_FILE_REQUEST:
    return {
      ...state,
      isLoadingArtFile: true,
    };
  case actions.UPLOAD_ART_FILE_SUCCESS:
    return {
      ...state,
      isLoadingArtFile: false,
    };
  default:
    return state;
  }
}
