import React, {Component} from "react";
import PropTypes from "prop-types";

import CarrierAssignmentTable from "./CarrierAssignmentTable";
import ChillButton from "../../../../shared/ChillButton";

const customTabContainerStyle = {
  paddingTop: 9,
};

export default class CarrierAssignmentTab extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    carrierAssignments: PropTypes.array,
    companyId: PropTypes.number,
    history: PropTypes.object,
    fetchCompanyShippingCarrierAssignments: PropTypes.func,
    patchCompanyShippingCarrierAssignment: PropTypes.func,
    deleteCompanyShippingCarrierAssignment: PropTypes.func,
  };

  componentDidMount() {
    this.fetchCompanyShippingCarrierAssignments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchCompanyShippingCarrierAssignments();
    }
  }

  fetchCompanyShippingCarrierAssignments() {
    const {fetchCompanyShippingCarrierAssignments, companyId} = this.props;
    if (!companyId) return;
    fetchCompanyShippingCarrierAssignments({
      filter: `company_id,eq,${companyId}`,
    });
  }

  render() {
    const {
      isLoading,
      isInternal,
      carrierAssignments,
      patchCompanyShippingCarrierAssignment,
      deleteCompanyShippingCarrierAssignment,
      companyId,
      history,
    } = this.props;

    return (
      <div className="tab-container" style={customTabContainerStyle}>
        {isInternal ? (
          <div className="dashboard_top" style={{marginBottom: 0}}>
            <ChillButton
              className="add-wallet-button"
              name={"ADD SHIPPING CARRIER"}
              height={40}
              width={207}
              marginTop={17}
              marginBottom={6}
              marginLeft={16}
              icon={"DSOD-white"}
              onClick={() => {
                history.push({
                  pathname: `/companies/${companyId}/carrier_assignment/create`,
                });
              }}
            />
          </div>
        ) : null}
        <CarrierAssignmentTable
          isLoading={isLoading}
          isInternal={isInternal}
          carrierAssignments={carrierAssignments}
          patchCompanyShippingCarrierAssignment={
            patchCompanyShippingCarrierAssignment
          }
          deleteCompanyShippingCarrierAssignment={
            deleteCompanyShippingCarrierAssignment
          }
          history={history}
        />
      </div>
    );
  }
}
