import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _isEqual from "lodash/isEqual";
import {getDate} from "../../../utils/dates";
import OrderEditPage from "../../pages/Orders/OrderEditPage";
import {openNotification} from "../../../actions/notificationActions";
import {
  fetchCompany,
  fetchWalletMembers,
} from "../../../actions/companyActions";
import {
  fetchShipments,
  updateShipmentItem,
  updateShipment,
  fetchCompanyShippingCarrierAssignments,
  fetchShippingCarriers,
  fetchShippingMethods,
} from "../../../actions/shipmentActions";
import {fetchCountries} from "../../../actions/geoActions";
import {
  fetchOrder,
  fetchOrderItems,
  updateOrder,
  fetchOrderStatus,
} from "../../../actions/orderActions";
import {fetchPaymentTerms} from "../../../actions/paymentTermsActions";

class OrderEdit extends Component {
  state = {
    isReordering: false,
    reorderItems: [],
    orderData: {},
    shipmentData: {},
  };

  componentWillReceiveProps(nextProps) {
    this._setInitialState(nextProps);
  }

  componentWillMount() {
    this._fetchData();
  }

  componentDidUpdate() {
    const {
      order,
      match: {params: {company_id: companyId, id: orderId}},
      history,
    } = this.props;
    if (order && order.isDsod) {
      history.replace(`/companies/${companyId}/dsod/${orderId}/edit`);
    }
  }

  getUsersWithWalletEnable = company => {
    if (!company) return;
    const {walletMembers} = this.props;
    if (!walletMembers) return [];
    return walletMembers.filter(({isWalletMember}) => {
      return isWalletMember;
    });
  };

  _setInitialState = nextProps => {
    const {order, shipments, shippingMethods, fetchShippingMethods} = nextProps;
    if (!order) return;

    if (this.props.order !== order) {
      const {
        companyId,
        cngReferenceNumber,
        isDsod,
        orderStatusId,
        purchaseOrderDate,
        submittedOn,
        estimatedShipOn,
        purchaseOrderNumber,
        jobName,
        billingCompanyContactId,
        inHandDate,
        clientNotes,
        internalNotes,
      } = order;

      this.setState({
        orderData: {
          clientNotes,
          internalNotes,
          company_id: companyId,
          cng_reference_number: cngReferenceNumber,
          is_dsod: isDsod,
          order_status_id: orderStatusId,
          purchase_order_number: purchaseOrderNumber,
          submitted_on: submittedOn && getDate(submittedOn),
          in_hand_date: inHandDate && getDate(inHandDate),
          estimated_ship_on: estimatedShipOn && getDate(estimatedShipOn),
          job_name: jobName,
          billing_company_contact_id: billingCompanyContactId,
          purchase_order_date: purchaseOrderDate && getDate(purchaseOrderDate),
          paymentTermId: order.paymentTermId,
          paymentTerm: order.paymentTerm,
        },
      });
    }

    if (!shipments || (shipments && shipments.length <= 0)) return;
    const shipment = shipments[0];
    const shippingCarrier = _get(
      shipment,
      "shippingMethod.shippingCarrier",
      {}
    );
    const shippingCarrierId = _get(
      shipment,
      "shipmentAccount.shippingCarrierId",
      null
    );
    const accountNumber = _get(shipment, "shipmentAccount.accountNumber", "");
    const {
      id: shipmentId,
      trackingNumber,
      shippingMethodId,
      shipmentAccountId,
      actualShipDate,
      shipmentCost,
    } = shipment;

    this.setState({
      shipmentData: {
        tracking_number: trackingNumber,
        shipping_method_id: shippingMethodId,
        shipment_account_id: shipmentAccountId,
        shipping_carrier_id: shippingCarrierId,
        shipment_account_number: accountNumber,
        shipping_carrier_name: shippingCarrier && shippingCarrier.name,
        shipment_cost: shipmentCost,
        actual_ship_date: actualShipDate,
        id: shipmentId,
      },
    });

    if (!shippingMethods) {
      fetchShippingMethods();
    }
    if (_isEqual(this.props, nextProps)) return;
    this._fetchShipmentAccounts();
  };

  _fetchShippingMethods = async companyShippingCarrierAssignments => {
    const {fetchShippingCarriers} = this.props;
    const carrierIds = companyShippingCarrierAssignments.map(
      assigment => assigment.shippingCarrierId
    );
    await fetchShippingCarriers({
      filter: `id,in,(${carrierIds.join(",")})`,
    });
  };

  async _fetchShipmentAccounts() {
    const {
      fetchCompanyShippingCarrierAssignments,
      isCarrierAssignmentsLoading,
      match: {params: {company_id: companyId}},
    } = this.props;

    if (isCarrierAssignmentsLoading) return;
    await fetchCompanyShippingCarrierAssignments({
      filter: `company_id,eq,${companyId}`,
    });
  }

  _fetchOrderItems = () => {
    const {fetchOrderItems, match: {params: {id: orderId}}} = this.props;
    fetchOrderItems(orderId, {
      sort: JSON.stringify([
        {
          field: "orderItemType.index",
          direction: "ASC",
          relationship: "orderItemType",
        },
        {
          field: "product.item_number",
          direction: "ASC",
          relationship: "product",
        },
        {
          field: "id",
          direction: "ASC",
        },
      ]),
    });
  };

  _fetchData = () => {
    const {
      fetchCompany,
      fetchOrder,
      fetchOrderStatus,
      fetchShipments,
      fetchCountries,
      fetchWalletMembers,
      fetchPaymentTerms,
      match: {params: {company_id: companyId, id: orderId}},
    } = this.props;

    fetchCountries();
    fetchWalletMembers(companyId);
    fetchCompany(companyId);
    fetchOrder(orderId);
    this._fetchOrderItems();
    fetchShipments(orderId, {
      orderBy: "id",
      orderDirection: "DESC",
    });
    fetchOrderStatus();
    fetchPaymentTerms();
  };

  handleUpdateOrder = async () => {
    const {updateOrder, order, openNotification, history} = this.props;
    const {orderData, shipmentData} = this.state;

    try {
      await updateOrder(
        order.id,
        {
          ...orderData,
          shipment: _omit(shipmentData, ["id"]),
        },
        {},
        () => {
          history.push(`/companies/${order.companyId}/orders/${order.id}`);
          openNotification("Order updated successfully");
        }
      );
    } catch (error) {
      if (error.name === "Invariant Violation") return;
      openNotification("Error Updating Order");
    }
  };

  handleUpdateOrderStatus = async statusId => {
    const {updateOrder, order, openNotification} = this.props;
    await updateOrder(order.id, {orderStatusId: statusId});
    openNotification("Order changes successfully saved");
  };

  handleToggleReorder = () => {
    const {isReordering} = this.state;
    this.setState({isReordering: !isReordering});
  };

  handleToggleReorderItem = orderItem => {
    const {reorderItems} = this.state;
    const hasReorderItem = reorderItems.find(item => item.id === orderItem.id);
    if (hasReorderItem) {
      return this.setState({
        reorderItems: reorderItems.filter(item => item.id !== orderItem.id),
      });
    }

    this.setState({reorderItems: [...reorderItems, orderItem]});
  };

  handleUpdateShipmentItem = async (itemId, data) => {
    const {
      updateShipmentItem,
      openNotification,
      fetchShipments,
      match: {params: {id: orderId}},
    } = this.props;

    await updateShipmentItem(itemId, data);
    await fetchShipments(orderId);
    openNotification("Shipment Item has been updated");
  };

  handleOrderChange = (event, value, formName) => {
    this.handleChange(event, value, formName, "orderData");
  };

  handleShipmentChange = (event, value, formName) => {
    if (typeof value === "object") {
      return this.setState({
        shipmentData: {
          ...this.state.shipmentData,
          ...value,
        },
      });
    }
    this.handleChange(event, value, formName, "shipmentData");
  };

  handleChange = (event, value, formName, stateName) => {
    const name = event ? event.target.name : formName;

    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [name]: value,
      },
    });
  };

  render() {
    return (
      <OrderEditPage
        onUpdateOrderName={this.handleUpdateOrderName}
        onUpdateOrderStatus={this.handleUpdateOrderStatus}
        onToggleReorder={this.handleToggleReorder}
        onToggleReorderItem={this.handleToggleReorderItem}
        onUpdateShipmentTrackingNumber={this.handleUpdateShipmentTrackingNumber}
        onOrderChange={this.handleOrderChange}
        onShipmentChange={this.handleShipmentChange}
        onUpdateOrder={this.handleUpdateOrder}
        fetchShippingMethodsByCarrier={this.fetchShippingMethodsByCarrier}
        onUpdateShipmentItem={this.handleUpdateShipmentItem}
        billingCompanyContacts={this.getUsersWithWalletEnable(
          this.props.company
        )}
        onUpdateOrderItem={this._fetchOrderItems}
        {...this.props}
        {...this.state}
      />
    );
  }
}

OrderEdit.propTypes = {
  isCarrierAssignmentsLoading: PropTypes.bool,
  openNotification: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchShipments: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrderItems: PropTypes.func.isRequired,
  fetchOrderStatus: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func,
  fetchWalletMembers: PropTypes.func,
  fetchCompanyShippingCarrierAssignments: PropTypes.func,
  fetchShippingCarriers: PropTypes.func,
  fetchShippingMethods: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  updateShipmentItem: PropTypes.func,
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  order: PropTypes.object,
  shipments: PropTypes.array,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  shippingMethods: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  walletMembers: PropTypes.array,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    companies: {company, walletMembers},
    orders: {order, isLoading: isOrderLoading, orderItems, orderStatus},
    auth: {isInternal, id},
    shipment: {
      shipments,
      companyShippingCarrierAssignments,
      isCarrierAssignmentsLoading,
      shippingMethods,
      isLoading: isShipmentLoading,
    },
    paymentTerms: {paymentTerms},
    geo: {states, countries, isLoading: isGeolocationLoading},
  } = state;

  return {
    isCarrierAssignmentsLoading,
    walletMembers,
    company,
    order,
    isInternal,
    currentUserId: id,
    isLoading: isOrderLoading && isShipmentLoading && isGeolocationLoading,
    orderItems,
    orderStatus,
    shipments,
    states: states,
    countries: countries,
    companyShippingCarrierAssignments,
    shippingMethods,
    paymentTerms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchShippingCarriers: bindActionCreators(fetchShippingCarriers, dispatch),
    fetchShippingMethods: bindActionCreators(fetchShippingMethods, dispatch),
    fetchCompanyShippingCarrierAssignments: bindActionCreators(
      fetchCompanyShippingCarrierAssignments,
      dispatch
    ),
    fetchWalletMembers: bindActionCreators(fetchWalletMembers, dispatch),
    fetchPaymentTerms: bindActionCreators(fetchPaymentTerms, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    updateShipment: bindActionCreators(updateShipment, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderEdit)
);
