import React, {Component} from "react";
import PropTypes from "prop-types";

import DatePicker from "material-ui/DatePicker";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";

import {formStyles} from "../../styles/components/formStyles";
import {getFormattedDate} from "../../utils/dates";
import moment from "moment";

const style = {
  outer: {
    position: "relative",
  },
  datePicker: {
    position: "absolute",
  },
  input: {
    userSelect: "none",
    pointerEvents: "none",
    inner: {
      color: "transparent",
    },
  },
  iconButton: {
    position: "absolute",
    top: "52%",
    right: -8,
    width: 26,
    height: 26,
    padding: 6,
  },
  icon: {
    width: 14,
    height: 14,
  },
};

export default class CNGDatePicker extends Component {
  constructor() {
    super();
    this.datePickerRef = null;
    this.inputRef = null;
  }

  _handleKeyPress(event) {
    if (event.key === "Enter") {
      setTimeout(() => {
        this.datePickerRef.openDialog();
      }, 200);
      event.target.blur();
      event.preventDefault();
    }
  }

  _handleDatePickerChange(event, value) {
    this.inputRef.focus();
    this.props.onChange(event, value);
  }

  _formatDate(date) {
    return getFormattedDate(date);
  }

  _clear = () => {
    this.props.onChange(null, null);
  };

  _renderDatePicker() {
    const {
      datePickerStyle,
      datePickerInputStyle,
      value,
      showClear, // eslint-disable-line no-unused-vars
      ...pickerProps
    } = this.props;

    return (
      <DatePicker
        {...pickerProps}
        value={
          value &&
          moment(value, "YYYY-MM-DD")
            .utc(false)
            .toDate()
        }
        ref={el => (this.datePickerRef = el)}
        floatingLabelText=" "
        type="text"
        tabIndex="-1"
        formatDate={date => this._formatDate(date)}
        onChange={(event, value) => this._handleDatePickerChange(event, value)}
        style={{
          ...datePickerStyle,
          ...style.datePicker,
        }}
        inputStyle={datePickerInputStyle}
        underlineStyle={{display: "none"}}
        autoOk={true}
      />
    );
  }

  _renderInput() {
    const {value, disabled} = this.props;
    const textFieldValue = !value || typeof value === "undefined" ? "" : value;
    return (
      <TextField
        floatingLabelText={this.props.floatingLabelText}
        name={`${this.props.name}-focus-handler`}
        type="text"
        value={textFieldValue}
        ref={el => (this.inputRef = el)}
        autoFocus={this.props.autoFocus}
        onKeyPress={event => this._handleKeyPress(event)}
        className="datepicker-focus"
        style={{
          ...this.props.textFieldStyle,
          ...style.input,
        }}
        inputStyle={style.input.inner}
        underlineStyle={this.props.underlineStyle}
        floatingLabelStyle={formStyles.TextFieldLabelStyles}
        disabled={disabled}
      />
    );
  }

  _renderClearIcon() {
    const {showClear, value} = this.props;
    if (!showClear || !value) return;
    return (
      <IconButton
        style={style.iconButton}
        iconStyle={style.icon}
        onClick={this._clear}
      >
        <img src={"/assets/imgs/icons/trash2.svg"} className="btn-icon-size" />
      </IconButton>
    );
  }

  _handlerClearDate() {
    const {clearDate} = this.props;
    if (clearDate) {
      this._clear();
    }
  }

  render() {
    return (
      <div
        className="CNG-DatePicker"
        style={{
          ...style.outer,
          ...this.props.style,
        }}
      >
        {this._renderDatePicker()}
        {this._renderInput()}
        {this._renderClearIcon()}
        {this.props.clearDate && this._handlerClearDate()}
      </div>
    );
  }
}

CNGDatePicker.propTypes = {
  style: PropTypes.object,
  datePickerStyle: PropTypes.object,
  datePickerInputStyle: PropTypes.object,
  onChange: PropTypes.func,
  floatingLabelText: PropTypes.string,
  name: PropTypes.string,
  textFieldStyle: PropTypes.object,
  underlineStyle: PropTypes.object,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  showClear: PropTypes.bool,
  disabled: PropTypes.bool,
  clearDate: PropTypes.bool,
};

CNGDatePicker.defaultProps = {
  showClear: false,
  clearDate: false,
};
