import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import DashboardPage from "../pages/DashboardPage";
import {searchCompanies, fetchCompanies} from "../../actions/companyActions";
import withPagination from "../modules/withPagination";
import {ORDER_COMPLETED_ID, REQUEST_FULFILLED_ID} from "../../constants/order";
import {searchUsers, searchUsersByCompany} from "../../actions/userActions";
import {searchArts} from "../../actions/artActions";
import {searchQuotes} from "../../actions/v2/quoteActions";
import {fetchSettings} from "../../actions/v2/settingsAction";
import {searchOrders, searchDSODOrders} from "../../actions/orderActions";
import {
  searchShipments,
  searchShipmentsByCompany,
} from "../../actions/shipmentActions";
import {fetchPriceTableLinks} from "../../actions/v2/priceLinkActions";

const resultsLimit = 20;

class Dashboard extends Component {
  state = {
    searchTerm: "",
    resultsLimitQuotes: resultsLimit,
    resultsLimitCompanies: resultsLimit,
    resultsLimitUsers: resultsLimit,
    resultsLimitArts: resultsLimit,
    resultsLimitOrders: resultsLimit,
    resultsLimitDSODs: resultsLimit,
    resultsLimitShipments: resultsLimit,
  };

  componentDidMount() {
    this._initQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.setState({
        resultsLimitQuotes: resultsLimit,
        resultsLimitCompanies: resultsLimit,
        resultsLimitUsers: resultsLimit,
        resultsLimitArts: resultsLimit,
        resultsLimitOrders: resultsLimit,
        resultsLimitDSODs: resultsLimit,
        resultsLimitShipments: resultsLimit,
      });
      this._initQuery();
    }
  }

  searchHandler = (event, searchTerm) => {
    this.props.setSearchTerm(searchTerm);
  };

  _initQuery = () => {
    const {
      searchTerm,
      fetchCompanies,
      fetchPriceTableLinks,
      fetchSettings,
    } = this.props;
    fetchPriceTableLinks();
    fetchSettings();
    this.loadQuotes();
    this.loadOrders();
    this.loadDSODs();
    if (searchTerm === "") {
      fetchCompanies({
        sort: "name",
        sortDirection: "ASC",
        page: {
          size: 0,
        },
      });
      return;
    }
    this.setState({searchTerm});
    this.loadCompanies();
    this.loadUsers();
    this.loadArts();
    this.loadShipments();
  };

  loadCompanies = async () => {
    const {searchTerm, searchCompanies} = this.props;
    const {resultsLimitCompanies: perPage} = this.state;
    searchCompanies(searchTerm, {page: 1, perPage});
  };

  loadUsers = async () => {
    const {
      searchTerm,
      searchUsers,
      searchUsersByCompany,
      isInternal,
      selectedCompanyId,
    } = this.props;
    const {resultsLimitUsers: perPage} = this.state;
    if (isInternal) {
      searchUsers(searchTerm, {page: 1, perPage});
    } else {
      searchUsersByCompany(selectedCompanyId, searchTerm, {
        page: 1,
        perPage,
        include: "country",
      });
    }
  };

  loadArts = async () => {
    const {searchTerm, searchArts, isInternal, selectedCompanyId} = this.props;
    const {resultsLimitArts: perPage} = this.state;

    if (isInternal) {
      searchArts(searchTerm, {page: 1, perPage});
    } else {
      searchArts(searchTerm, {
        company_id: selectedCompanyId,
        page: 1,
        perPage,
      });
    }
  };

  loadQuotes = () => {
    const {searchTerm, searchQuotes} = this.props;
    const {resultsLimitQuotes} = this.state;

    searchQuotes(searchTerm, {
      page: 1,
      perPage: searchTerm ? resultsLimitQuotes : 0,
    });
  };

  loadOrders = () => {
    const {
      searchTerm,
      isInternal,
      selectedCompanyId,
      searchOrders,
    } = this.props;
    const {resultsLimitOrders} = this.state;

    const orderFilters = [];
    if (!searchTerm) {
      orderFilters.push(`status;code,neq,__${ORDER_COMPLETED_ID}__`);
    }
    if (!isInternal) {
      orderFilters.push(`company_id,eq,${selectedCompanyId}`);
    }
    searchOrders(searchTerm, "[status,items,company]", {
      filter: orderFilters.join("|"),
      orderBy: "orders;estimated_ship_on",
      orderDirection: "ASC",
      page: 1,
      perPage: searchTerm ? resultsLimitOrders : 0,
    });
  };

  loadDSODs = () => {
    const {
      searchTerm,
      isInternal,
      selectedCompanyId,
      searchDSODOrders,
    } = this.props;
    const {resultsLimitDSODs} = this.state;

    const dsodFilters = [];
    if (!searchTerm) {
      dsodFilters.push(`status;code,neq,__${REQUEST_FULFILLED_ID}__`);
    }
    if (!isInternal) {
      dsodFilters.push(`company_id,eq,${selectedCompanyId}`);
    }
    searchDSODOrders(searchTerm, "[status,items.product,company]", {
      filter: dsodFilters.join("|"),
      orderBy: "orders;created_at",
      page: 1,
      perPage: searchTerm ? resultsLimitDSODs : 0,
    });
  };

  loadShipments = async () => {
    const {
      searchTerm,
      isInternal,
      selectedCompanyId,
      searchShipments,
      searchShipmentsByCompany,
    } = this.props;
    const {resultsLimitShipments: perPage} = this.state;
    const options = {page: 1, perPage};
    if (isInternal) {
      searchShipments(searchTerm, options);
    } else {
      searchShipmentsByCompany(selectedCompanyId, searchTerm, options);
    }
  };

  loadMore = (stateName, callback) => async additional => {
    await this.setState(({[stateName]: limit}) => ({
      [stateName]: limit + additional,
    }));
    callback();
  };

  render() {
    const {searchHandler} = this;
    const {
      settings,
      companies,
      companiesCount,
      users,
      userCount,
      art,
      artCount,
      quotes,
      quotesCount,
      quotesUnreadCounts,
      orders,
      ordersUnreadCounts,
      openOrders,
      DSODOrders,
      ordersCount,
      DSODOrdersCount,
      isInternal,
      isLoading,
      searchQuotes,
      searchOrders,
      searchDSODOrders,
      currentUserName,
      selectedCompany,
      companyIsLoading,
      userIsLoading,
      artIsLoading,
      orderIsLoading,
      dsodIsLoading,
      shipments,
      shipmentsCount,
      selectedCompanyId,
      shipmentIsLoading,
      priceTableLink,
    } = this.props;
    const {
      resultsLimitCompanies,
      resultsLimitUsers,
      resultsLimitArts,
      resultsLimitQuotes,
      resultsLimitOrders,
      resultsLimitDSODs,
      resultsLimitShipments,
    } = this.state;
    const loadMoreCompanies = this.loadMore(
      "resultsLimitCompanies",
      this.loadCompanies
    );
    const loadMoreUsers = this.loadMore("resultsLimitUsers", this.loadUsers);
    const loadMoreArts = this.loadMore("resultsLimitArts", this.loadArts);
    const loadMoreQuotes = this.loadMore("resultsLimitQuotes", this.loadQuotes);
    const loadMoreOrders = this.loadMore("resultsLimitOrders", this.loadOrders);
    const loadMoreDSODs = this.loadMore("resultsLimitDSODs", this.loadDSODs);
    const loadMoreShipments = this.loadMore(
      "resultsLimitShipments",
      this.loadShipments
    );

    const searchResults = {
      companies,
      users,
      art,
      quotes,
      orders,
      openOrders,
      shipments,
      DSODOrders,
    };

    return (
      <DashboardPage
        settings={settings}
        onSearch={searchHandler}
        searchResults={searchResults}
        searchTerm={this.props.searchTerm}
        isInternal={isInternal}
        isLoading={isLoading}
        orderIsLoading={orderIsLoading}
        userIsLoading={userIsLoading}
        artIsLoading={artIsLoading}
        dsodIsLoading={dsodIsLoading}
        companyIsLoading={companyIsLoading}
        shipmentIsLoading={shipmentIsLoading}
        searchQuotes={searchQuotes}
        quotesCount={quotesCount}
        quotesUnreadCounts={quotesUnreadCounts}
        ordersUnreadCounts={ordersUnreadCounts}
        searchOrders={searchOrders}
        ordersCount={ordersCount}
        searchDSODOrders={searchDSODOrders}
        DSODOrdersCount={DSODOrdersCount}
        shipmentsCount={shipmentsCount}
        artCount={artCount}
        userCount={userCount}
        companiesCount={companiesCount}
        currentUserName={currentUserName}
        selectedCompany={selectedCompany}
        resultsLimit={resultsLimit}
        resultsLimitCompanies={resultsLimitCompanies}
        loadMoreCompanies={loadMoreCompanies}
        resultsLimitUsers={resultsLimitUsers}
        loadMoreUsers={loadMoreUsers}
        resultsLimitArts={resultsLimitArts}
        loadMoreArts={loadMoreArts}
        resultsLimitQuotes={resultsLimitQuotes}
        loadMoreQuotes={loadMoreQuotes}
        resultsLimitOrders={resultsLimitOrders}
        loadMoreOrders={loadMoreOrders}
        resultsLimitDSODs={resultsLimitDSODs}
        loadMoreDSODs={loadMoreDSODs}
        resultsLimitShipments={resultsLimitShipments}
        loadMoreShipments={loadMoreShipments}
        selectedCompanyId={selectedCompanyId}
        priceTableLink={priceTableLink}
      />
    );
  }
}

Dashboard.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
  searchCompanies: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  searchArts: PropTypes.func.isRequired,
  searchQuotes: PropTypes.func.isRequired,
  searchOrders: PropTypes.func.isRequired,
  searchDSODOrders: PropTypes.func.isRequired,
  searchUsersByCompany: PropTypes.func.isRequired,
  searchShipmentsByCompany: PropTypes.func.isRequired,
  searchShipments: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  companiesCount: PropTypes.number,
  users: PropTypes.array.isRequired,
  userCount: PropTypes.number,
  art: PropTypes.array.isRequired,
  artCount: PropTypes.number,
  quotes: PropTypes.array.isRequired,
  quotesCount: PropTypes.number,
  quotesUnreadCounts: PropTypes.object,
  ordersUnreadCounts: PropTypes.object,
  orders: PropTypes.array.isRequired,
  openOrders: PropTypes.array.isRequired,
  shipments: PropTypes.array,
  shipmentsCount: PropTypes.number,
  ordersCount: PropTypes.number,
  DSODOrders: PropTypes.array.isRequired,
  DSODOrdersCount: PropTypes.number,
  isInternal: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.object,
  selectedCompanyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUserName: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  orderIsLoading: PropTypes.bool,
  userIsLoading: PropTypes.bool,
  artIsLoading: PropTypes.bool,
  dsodIsLoading: PropTypes.bool,
  companyIsLoading: PropTypes.bool,
  shipmentIsLoading: PropTypes.bool,
  quotesIsLoading: PropTypes.bool,
  priceTableLink: PropTypes.string,
  fetchPriceTableLinks: PropTypes.func,
  settings: PropTypes.shape({}),
  fetchSettings: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    companies,
    auth: {
      isInternal,
      selectedCompany: selectedCompanyId,
      selectedCompanyName: selectedCompanyName,
      user: currentUserName,
      selectedCompanyCustomerMessage,
    },
    users,
    art,
    quotes,
    orders,
    shipment: {shipments, shipmentsCount, isLoading: shipmentIsLoading},
    priceTableLinks: {links},
    settings,
  } = state;

  const selectedCompany = {
    id: selectedCompanyId,
    name: selectedCompanyName,
    customer_message: selectedCompanyCustomerMessage,
  };

  const priceTableLink = links && links.length > 0 ? links[0].link : null;

  return {
    settings,
    shipments,
    shipmentsCount,
    companies: companies.companies,
    companiesCount: companies.companiesCount,
    users: users.users,
    userCount: users.userCount,
    art: art.artList,
    artCount: art.artCount,
    quotes: quotes.quotes,
    quotesCount: quotes.quotesCount,
    quotesUnreadCounts: quotes.unreadCounts,
    ordersUnreadCounts: orders.unreadCounts,
    orders: orders.orders,
    openOrders: orders.currentOpenOrders,
    DSODOrders: orders.DSODOrders,
    ordersCount: orders.ordersCount,
    DSODOrdersCount: orders.DSODOrdersCount,
    selectedCompany,
    selectedCompanyId,
    quotesIsLoading: quotes.isLoading,
    orderIsLoading: orders.isLoading,
    userIsLoading: users.isLoading,
    artIsLoading: art.isLoading,
    shipmentIsLoading: shipmentIsLoading,
    companyIsLoading: companies.isLoading,
    dsodIsLoading: orders.dsodIsLoading,
    isLoading:
      orders.isLoading ||
      users.isLoading ||
      art.isLoading ||
      companies.isLoading ||
      quotes.isLoading,
    isInternal,
    currentUserName: currentUserName.split(" ")[0],
    priceTableLink,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    searchCompanies: bindActionCreators(searchCompanies, dispatch),
    searchUsersByCompany: bindActionCreators(searchUsersByCompany, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    searchArts: bindActionCreators(searchArts, dispatch),
    searchQuotes: bindActionCreators(searchQuotes, dispatch),
    searchOrders: bindActionCreators(searchOrders, dispatch),
    searchDSODOrders: bindActionCreators(searchDSODOrders, dispatch),
    fetchSettings: bindActionCreators(fetchSettings, dispatch),
    searchShipmentsByCompany: bindActionCreators(
      searchShipmentsByCompany,
      dispatch
    ),
    searchShipments: bindActionCreators(searchShipments, dispatch),
    fetchPriceTableLinks: bindActionCreators(fetchPriceTableLinks, dispatch),
  };
}

export default withPagination(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
