import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ChillButton from "./ChillButton";
import Dialog from "material-ui/Dialog";
import FileDrop from "react-file-drop";
import {VALID_IMAGE_FORMATS} from "../../constants/art";

export default class FileUploaderDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onFileChange: PropTypes.func,
    onClose: PropTypes.func,
    onUpload: PropTypes.func,
    saveText: PropTypes.string,
    title: PropTypes.string,
  };

  state = {
    file: null,
    validationMessage: "",
  };

  openFileBrowserHandler = () => {
    this.inputField.click();
  };

  handleDropFile = (files, event) => {
    event.preventDefault();
    this._validateFile(files[0]);
  };

  handleUploadFile = target => {
    const {currentTarget: {files}} = target;
    this._validateFile(files[0]);
  };

  handleRemoveFile = event => {
    event.preventDefault();
    this.setState({file: null});
  };

  _validateFile = file => {
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      this.setState({
        validationMessage:
          "Uploaded file is not a valid input image. Only png, jpg and jpg",
      });
    }
    this.setState({
      file,
    });
  };

  _renderUploaderStep = () => {
    return (
      <div className="flex flex-center flex-column">
        <div
          style={dropFileStyle}
          className="flex flex-center justify-content-center"
        >
          <FileDrop onDrop={this.handleDropFile}>Drop file here</FileDrop>
        </div>

        <input
          type="file"
          onChange={this.handleUploadFile}
          className="hidden"
          name="file"
          ref={input => (this.inputField = input)}
        />

        <ChillButton
          name={"MANUALLY UPLOAD FILE"}
          icon={"upload-white"}
          width={220}
          widthIcon={20}
          height={32}
          marginTop={15}
          secondary={true}
          onClick={this.openFileBrowserHandler}
        />
      </div>
    );
  };

  _isPDFFormat = () => {
    const {file} = this.state;
    return file && file.type === "application/pdf";
  };

  _renderPreviewStep = () => {
    const {file} = this.state;
    return (
      <div style={previewStyle} className="flex flex-center">
        <div style={{flex: 8, paddingLeft: 20}}>
          {this._isPDFFormat() && (
            <img
              src={"/assets/imgs/icons/pdf.svg"}
              className="btn-icon-size"
              style={{
                width: 20,
                height: 20,
              }}
            />
          )}
          <span style={filenameStyle}>{file.name}</span>
        </div>

        <div style={{flex: 1}}>
          <Link to={""} onClick={this.handleRemoveFile}>
            <img
              src={"/assets/imgs/icons/trash.svg"}
              className="btn-icon-size"
              style={{
                width: 20,
                height: 20,
              }}
            />
          </Link>
        </div>
      </div>
    );
  };

  _handleUpload = () => {
    const {file} = this.state;
    const {onUpload} = this.props;

    if (!file) return;
    onUpload(file);
  };

  render() {
    const {open, onClose, saveText, title} = this.props;
    const {file} = this.state;

    return (
      <Dialog
        modal={true}
        open={open}
        contentStyle={{width: 545}}
        overlayClassName={"dialog-overlay"}
      >
        <div className="flex flex-center flex-column">
          <h4 className="page-title" style={{fontSize: 24, padding: "18px 0"}}>
            {title}
          </h4>
          {!file ? this._renderUploaderStep() : this._renderPreviewStep()}
          <ChillButton
            className="btn--formCardHeader"
            name={saveText}
            height={40}
            width={337}
            marginTop={32}
            icon={""}
            isDisabled={!file}
            onClick={this._handleUpload}
          />
          <Link
            to={""}
            onClick={onClose}
            style={{marginTop: 24}}
            className="text-cancel"
          >
            Cancel
          </Link>
        </div>
      </Dialog>
    );
  }
}

const dropFileStyle = {
  height: 64,
  width: 360,
  border: "1px dashed #BDC6CF",
  borderRadius: 3,
  backgroundColor: "#F4F8F9",
};

const previewStyle = {
  height: 64,
  width: 360,
  borderRadius: 3,
  backgroundColor: "#F4F8F9",
};

const filenameStyle = {
  color: "#4A4A4A",
  fontSize: 16,
  whiteSpace: "nowrap",
  width: 240,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
};
