import React, {Component} from "react";
import PropTypes from "prop-types";

export default class CompanyLink extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    index: PropTypes.number.isRequired,
    len: PropTypes.number.isRequired,
    className: PropTypes.string,
    marginRight: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    id: PropTypes.string,
    objectId: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    let {text} = this.props;

    const {
      index,
      len,
      className,
      marginRight,
      onMouseLeave,
      onMouseEnter,
      objectId,
      id,
      title,
    } = this.props;

    if (typeof text === "function") {
      text = text();
    }

    return (
      <span
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-id={objectId}
        id={id}
        style={{marginRight: marginRight ? marginRight : 3}}
        title={typeof text !== "string" ? title : text}
      >
        {text}
        {len > 0 && index != len - 1 ? "," : null}
      </span>
    );
  }
}
