import React from "react";
import PropTypes from "prop-types";

export const Logo = props => {
  return (
    <img
      src={"/assets/imgs/Logo_white@3x.png"}
      className={`${props.className}`}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};
