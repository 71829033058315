import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import OrderDetailPage from "../../pages/Orders/OrderDetailPage";
import {openNotification} from "../../../actions/notificationActions";
import {fetchCompany} from "../../../actions/companyActions";
import {
  fetchShipments,
  updateShipmentItem,
  updateShipment,
} from "../../../actions/shipmentActions";
import {searchInventoryItems} from "../../../actions/inventoryActions";
import {fetchCountries} from "../../../actions/geoActions";
import {
  fetchOrder,
  fetchOrderItems,
  updateOrder,
  fetchOrderStatus,
  fetchOpenOrders,
  createOrder,
  revertOrder,
  approveOrder,
} from "../../../actions/orderActions";

class OrderDetail extends Component {
  state = {
    isReordering: false,
    reorderItems: [],
    openOrderModal: false,
  };

  componentWillMount() {
    this._fetchOrderData();
  }

  componentDidUpdate() {
    this._redirectToDSOD();
  }

  _redirectToDSOD() {
    const {
      order,
      match: {params: {company_id: companyId, id: orderId}},
      history,
    } = this.props;
    if (order.id && order.isDsod) {
      history.replace(`/companies/${companyId}/dsod/${orderId}`);
    }
  }

  _fetchOrderData = () => {
    const {
      fetchCompany,
      fetchOrder,
      fetchOrderItems,
      fetchOrderStatus,
      fetchShipments,
      fetchCountries,
      fetchOpenOrders,
      searchInventoryItems,
      selectedCompany,
      isInternal,
      history,
      match: {params: {company_id: companyId, id: orderId}},
    } = this.props;

    if (!isInternal && Number(companyId) !== Number(selectedCompany)) {
      return history.push("/");
    }

    fetchCountries();
    fetchCompany(companyId);
    fetchOrder(orderId);
    fetchOrderItems(orderId, {
      sort: JSON.stringify([
        {
          field: "orderItemType.index",
          direction: "ASC",
          relationship: "orderItemType",
        },
        {
          field: "product.item_number",
          direction: "ASC",
          relationship: "product",
        },
        {
          field: "id",
          direction: "ASC",
        },
      ]),
    });
    fetchShipments(orderId, {
      orderBy: "id",
      orderDirection: "DESC",
    });
    fetchOrderStatus();
    fetchOpenOrders(companyId);
    searchInventoryItems(companyId, null, {
      filter: `inventory_items;order_id,eq,${orderId}`,
      include:
        "inventoryHistories.orderItem.[shipmentItems.[recipient,shipment.shippingMethod.shippingCarrier],order]",
      without_stock: true,
    });
  };

  handleUpdateShipmentItem = async (itemId, data) => {
    const {
      updateShipmentItem,
      openNotification,
      fetchShipments,
      fetchOrder,
      match: {params: {id: orderId}},
    } = this.props;

    await updateShipmentItem(itemId, data);
    fetchShipments(orderId);
    fetchOrder(orderId);
    openNotification("Shipment Item has been updated");
  };

  handleUpdateShipment = async (itemId, data) => {
    const {
      updateShipment,
      fetchShipments,
      fetchOrder,
      match: {params: {id: orderId}},
    } = this.props;

    await updateShipment(itemId, data, () => {
      fetchOrder(orderId);
      fetchShipments(orderId);
    });
  };

  handleUpdateOrderStatus = async (statusId, rollback) => {
    const {updateOrder, order, openNotification, fetchOrder} = this.props;
    const updateData = {orderStatusId: statusId};
    const rollbackData = {orderStatusId: statusId, is_rollback: true};
    const data = !rollback ? updateData : rollbackData;

    await updateOrder(order.id, data, null, () => {
      fetchOrder(order.id);
      openNotification("Order changes successfully saved");
    });
  };

  handleToggleReorder = () => {
    const {isReordering} = this.state;
    this.setState({
      isReordering: !isReordering,
    });
  };

  handleToggleReorderItem = orderItem => {
    const {reorderItems} = this.state;
    const hasReorderItem = reorderItems.find(item => item.id === orderItem.id);
    if (hasReorderItem) {
      return this.setState({
        reorderItems: reorderItems.filter(item => item.id !== orderItem.id),
      });
    }

    this.setState({
      reorderItems: [...reorderItems, orderItem],
    });
  };

  handleReorderItems = async () => {
    const {
      history,
      order,
      createOrder,
      updateOrder,
      isInternal,
      currentUserId,
      match: {params: {company_id: companyId}},
      fetchOpenOrders,
    } = this.props;
    const {reorderItems} = this.state;
    const {data: openOrders} = await fetchOpenOrders(companyId);

    const openOrder = openOrders.find(
      openOrder =>
        Number(openOrder.companyId) === Number(order.companyId) &&
        openOrder.orderSource === "reorder"
    );

    if (!openOrder) {
      await createOrder(
        order.companyId,
        {
          is_open: true,
          is_dsod: false,
          submitted_on: new Date(),
          order_source: "reorder",
          shipment: {
            is_open: true,
          },
        },
        {historicalIds: reorderItems.map(item => item.id).join(",")},
        () => {
          history.push({
            pathname: `/companies/${order.companyId}/reorders`,
            state: {
              reorderItems,
            },
          });
        }
      );
    } else if (isInternal || openOrder.createdUserId === currentUserId) {
      await updateOrder(
        openOrder.id,
        {},
        {historicalIds: reorderItems.map(item => item.id).join(",")},
        () => {
          history.push({
            pathname: `/companies/${order.companyId}/reorders`,
            state: {
              reorderItems,
            },
          });
        }
      );
    } else {
      return this.setState({openOrderModal: true});
    }
  };

  handleHideOpenOrderModal = () => this.setState({openOrderModal: false});

  handleApproveOrder = orderId => {
    const {approveOrder} = this.props;
    approveOrder(orderId, this._fetchOrderData);
  };

  render() {
    return (
      <OrderDetailPage
        onUpdateOrderStatus={this.handleUpdateOrderStatus}
        onToggleReorder={this.handleToggleReorder}
        onToggleReorderItem={this.handleToggleReorderItem}
        onUpdateShipmentItem={this.handleUpdateShipmentItem}
        onReorderItems={this.handleReorderItems}
        {...this.props}
        {...this.state}
        approveOrder={this.handleApproveOrder}
        updateShipment={this.handleUpdateShipment}
        onHideOpenOrderModal={this.handleHideOpenOrderModal}
      />
    );
  }
}

OrderDetail.propTypes = {
  openNotification: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchShipments: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrderItems: PropTypes.func.isRequired,
  fetchOrderStatus: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func,
  fetchOrders: PropTypes.func,
  fetchOpenOrders: PropTypes.func,
  searchInventoryItems: PropTypes.func,
  updateShipmentItem: PropTypes.func,
  updateShipment: PropTypes.func,
  updateOrder: PropTypes.func,
  createOrder: PropTypes.func,
  selectedCompany: PropTypes.string,
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  history: PropTypes.object,
  currentUserId: PropTypes.number,
  approveOrder: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    companies: {company},
    orders: {
      order,
      isLoading,
      isLoadingOrderItems,
      orderItems,
      orderStatus,
      openOrders,
    },
    auth: {
      id,
      isInternal,
      selectedCompany: selectedCompanyId,
      selectedCompanyName: selectedCompanyName,
    },
    shipment: {shipments},
    geo: {countries},
    inventory: {inventoryItems},
  } = state;

  return {
    selectedCompanyName,
    selectedCompanyId,
    company,
    order,
    openOrders,
    isInternal,
    isLoading,
    isLoadingOrderItems,
    orderItems,
    orderStatus,
    shipments,
    countries,
    inventoryItems,
    currentUserId: id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchOpenOrders: bindActionCreators(fetchOpenOrders, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    updateShipment: bindActionCreators(updateShipment, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    createOrder: bindActionCreators(createOrder, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    revertOrder: bindActionCreators(revertOrder, dispatch),
    approveOrder: bindActionCreators(approveOrder, dispatch),
    searchInventoryItems: bindActionCreators(searchInventoryItems, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetail)
);
