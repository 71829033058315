import * as actions from "../constants/actionTypes";
import initialState from "./initialState";
import {getFormattedIndexResponse} from "../utils/indexResponseFormat";

export default function orderReducer(
  state = initialState.orders,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_OPEN_ORDERS_REQUEST:
    return {
      ...state,
      openOrders: [],
      openOrdersCount: 0,
      isOpenOrderLoading: true,
    };
  case actions.FETCH_OPEN_ORDERS_SUCCESS:
    return {
      ...state,
      openOrders: getFormattedIndexResponse(payload),
      openOrdersCount: payload.total,
      isOpenOrderLoading: false,
    };
  case actions.FETCH_OPEN_ORDERS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isOpenOrderLoading: false,
    };

  case actions.ARCHIVE_ORDER_SUCCESS:
    return {
      ...state,
      openOrders: state.openOrders.filter(order => {
        return Number(payload) !== Number(order.id);
      }),
      isLoading: false,
    };
  case actions.FETCH_DSOD_ORDERS_REQUEST:
    return {
      ...state,
      dsodIsLoading: true,
    };
  case actions.FETCH_DSOD_ORDERS_SUCCESS:
    return {
      ...state,
      DSODOrders: getFormattedIndexResponse(payload),
      DSODOrdersCount: meta.count,
      dsodIsLoading: false,
    };
  case actions.FETCH_DSOD_ORDERS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      dsodIsLoading: false,
    };
  case actions.FETCH_USER_OPEN_ORDERS_REQUEST:
    return {
      ...state,
      currentOpenOrders: [],
      isOpenOrderLoading: true,
    };
  case actions.FETCH_USER_OPEN_ORDERS_SUCCESS:
    return {
      ...state,
      currentOpenOrders: getFormattedIndexResponse(payload),
      isOpenOrderLoading: false,
    };
  case actions.FETCH_USER_OPEN_ORDERS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isOpenOrderLoading: false,
    };
  case actions.FETCH_ORDERS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ORDERS_SUCCESS:
    return {
      ...state,
      orders: getFormattedIndexResponse(payload),
      ordersCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_ORDERS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_ORDER_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ORDER_SUCCESS:
    return {
      ...state,
      order: payload,
      isLoading: false,
    };
  case actions.FETCH_ORDER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_ORDER_ITEMS_REQUEST:
    return {
      ...state,
      isLoadingOrderItems: true,
    };
  case actions.FETCH_ORDER_ITEMS_SUCCESS:
    return {
      ...state,
      orderItems: payload,
      isLoadingOrderItems: false,
    };
  case actions.FETCH_ORDER_ITEMS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoadingOrderItems: false,
    };
  case actions.FETCH_ORDER_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ORDER_ITEM_SUCCESS:
    return {
      ...state,
      orderItem: payload,
      isLoading: false,
    };
  case actions.FETCH_ORDER_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_ORDER_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_ORDER_SUCCESS:
    return {
      ...state,
      order: {
        ...state.order,
        ...payload,
      },
      isLoading: false,
    };
  case actions.UPDATE_ORDER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.FETCH_ORDER_STATUS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ORDER_STATUS_SUCCESS:
    return {
      ...state,
      orderStatus: payload,
      isLoading: false,
    };
  case actions.FETCH_ORDER_STATUS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.CREATE_ORDER_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_ORDER_SUCCESS:
    return {
      ...state,
      orders: [...state.orders, payload],
      isLoading: false,
    };
  case actions.CREATE_ORDER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.UPDATE_ORDER_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };

  case actions.UPDATE_ORDER_ITEM_SUCCESS:
    return {
      ...state,
      orderItem: payload,
      isLoading: false,
    };
  case actions.UPDATE_ORDER_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.CREATE_ORDER_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_ORDER_ITEM_SUCCESS:
    return {
      ...state,
      orderItem: payload,
      isLoading: false,
    };
  case actions.CREATE_ORDER_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_ORDERS_UNREAD_COUNTS:
    return {
      ...state,
      unreadCounts: payload,
    };
  default:
    return state;
  }
}
