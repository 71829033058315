import React from "react";
import PropTypes from "prop-types";
import Typography from "../../shared/Typography";
import withStyles from "../../shared/WithStyles";

const HidePricingTableButton = ({onHidePricingTable}) => {
  return (
    <Typography
      variant="bodyBold"
      color="primary.light"
      className="flex pointer"
      style={{width: "fit-content"}}
      onClick={onHidePricingTable}
    >
      HIDE
      <img
        src={"/assets/imgs/icons/ExpandLessBlue.svg"}
        style={{marginLeft: 8}}
      />
    </Typography>
  );
};

HidePricingTableButton.propTypes = {
  onHidePricingTable: PropTypes.func,
  style: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(HidePricingTableButton);
