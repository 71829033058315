import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DiscountItemCard from "./DiscountItemCard";
import {openNotification} from "../../../../../actions/notificationActions";
import {archiveOrderItem} from "../../../../../actions/orderActions";

function mapStateToProps(state) {
  const {orders: {isLoading}} = state;

  return {
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    archiveOrderItem: bindActionCreators(archiveOrderItem, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiscountItemCard)
);
