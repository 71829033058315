function createBlobFromBase64(base64, type) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type});
}

function downloadBlob(blob, filename) {
  const objectURL = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = objectURL;
  a.setAttribute("download", filename);
  if (typeof a.download === "undefined") {
    a.setAttribute("target", "_blank");
  }
  a.click();
}

export async function artFileDownload(quoteId, artUuid, filename) {
  let response = await fetch(
    `${process.env.API_DOMAIN}:${
      process.env.API_PORT
    }/quotes/downloadart?quoteId=${quoteId}&art=${artUuid}`
  );
  const file = await response.json();
  const blob = createBlobFromBase64(file.base64, file.ContentType);
  downloadBlob(blob, filename);
}
