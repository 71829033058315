import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "material-ui/CircularProgress";
import AuthFooter from "../shared/Footer/AuthFooter";
import ValidableTextField from "../shared/ValidableTextField";
import { debounce } from "lodash";
import { validateForm } from "../../utils/formValidations";

export default class ForgotPage extends Component {
  state = {
    email: "",
    showAlerts: false,
    errors: {},
    submitted: false,
  };

  validations = {
    email: {
      format: "email",
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.success_message || nextProps.error_message) {
      this.setState({ showAlerts: true });
    }
  }

  componentDidMount() {
    window.closeSplash();
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    if (!valid) return;
    const { sendForgotEmail } = this.props;
    const { email } = this.state;
    sendForgotEmail(email);
  }

  _validate() {
    const errors = validateForm(this);
    this.setState({ errors });
    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  handleChange(key, value) {
    this.setState({ [key]: value });
  }

  _getSendButtonDisabeldState() {
    const { loading } = this.props;
    const { errors } = this.state;
    if (loading) {
      return true;
    }

    const { email } = this.state;
    if (!email.length) {
      return true;
    }
    return Object.keys(errors).length > 0 ? true : false;
  }

  render() {
    const { email, errors } = this.state;
    return (
      <div className="bg-white auth-content">
        <div className="app-body auth-page-body">
          <div className="auth-page_top">
            <img
              className="auth-page_top_img"
              src="./assets/imgs/Icon@2x.png"
            />
          </div>

          <div className="auth-page_mid flex flex-column align-items-center">
            <img
              className="auth-page_chillLogo auth-page_chillLogo--forgot"
              src="./assets/imgs/ChillNGo.svg"
            />

            <div className="auth-page_info" style={{ height: 66 }}>
              <h3 className="auth-page_info_heading">Forgot your password?</h3>
              <p className="auth-page_info_text">
                Please enter your email address and click the send button.
              </p>
            </div>

            <div className="auth-page_input--forgot">
              <ValidableTextField
                style={{
                  marginRight: 0,
                  width: 358,
                }}
                name="email"
                label="Email"
                value={email}
                errorText={errors.email}
                handleChange={(_, value) => this.handleChange("email", value)}
                validate={() => this._validateDebounced()}
              />
            </div>

            <div className="auth-page-btn-container flex flex-column align-items-center">
              <button
                className="chill-button btn-1 btn-icon auth-page_btn auth-forgot_btn"
                onClick={() => this.triggerValidations()}
                disabled={this._getSendButtonDisabeldState()}
              >
                {this.props.loading && (
                  <div className="auth-button-progress-container">
                    <CircularProgress
                      size={24}
                      thickness={4}
                      color="rgba(255, 255, 255, 0.5)"
                    />
                  </div>
                )}
                <img
                  src="../assets/imgs/icons/sendmail.svg"
                  className="auth-page_btn-icon "
                />
                SEND
              </button>
              {this.state.showAlerts &&
                (this.props.success_message ? (
                  <div className="success-text auth-page_error">
                    {this.props.success_message}
                  </div>
                ) : (
                  <div className="error-text auth-page_error">
                    {this.props.error_message}
                  </div>
                ))}
            </div>

            <p>
              Back to Log in by clicking {" "}
              <Link to="/login" className="link-text">
                here
              </Link>
            </p>
          </div>
        </div>
        <AuthFooter />
      </div>
    );
  }
}

ForgotPage.propTypes = {
  loading: PropTypes.bool,
  sendForgotEmail: PropTypes.func.isRequired,
  error_message: PropTypes.string,
  success_message: PropTypes.string,
};
