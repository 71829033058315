import * as actions from "../constants/actionTypes";
import initialState from "./initialState";
import {cloneDeep} from "lodash";

export default function userReducer(
  state = initialState.users,
  {type, payload, meta}
) {
  switch (type) {
  case actions.VALIDATE_USER_EMAIL_COMPANY_REQUEST:
    return {
      ...state,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.VALIDATE_USER_EMAIL_COMPANY_SUCCESS:
    return {
      ...state,
      userEmailValidationByCompany: true,
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.VALIDATE_USER_EMAIL_COMPANY_ERROR:
    return {
      ...state,
      userEmailValidationByCompany: false,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_USERS_REQUEST:
    return {
      ...state,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.FETCH_USERS_SUCCESS:
    return {
      ...state,
      users: payload,
      userCount: meta.count,
      currentPage: meta.currentPage,
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.FETCH_USERS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_USER_REQUEST:
    return {
      ...state,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.FETCH_USER_SUCCESS:
    return {
      ...state,
      userDetail: payload,
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.FETCH_USER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.ARCHIVE_USER_REQUEST:
    return {
      ...state,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.ARCHIVE_USER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.ARCHIVE_USER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_USER_REQUEST:
    return {
      ...state,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.CREATE_USER_SUCCESS:
    return {
      ...state,
      users: [...state.users, payload],
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.CREATE_USER_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_USER_REQUEST:
    return {
      ...state,
      isUpdating: true,
      isLoading: true,
      error_message: null,
      error: null,
    };
  case actions.UPDATE_USER_SUCCESS:
    return {
      ...state,
      userDetail: payload,
      isUpdating: false,
      isLoading: false,
      error_message: null,
      error: null,
    };
  case actions.UPDATE_USER_ERROR:
    return {
      ...state,
      isLoading: false,
      isUpdating: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_USER_NOTE_REQUEST:
    return {
      ...state,
      userNoteRequestLoading: true,
      error_message: null,
      error: null,
    };
  case actions.CREATE_USER_NOTE_SUCCESS:
    return {
      ...state,
      userNoteRequestLoading: false,
      error_message: null,
      userDetail: {
        ...cloneDeep(state.userDetail),
        notes: [...cloneDeep(state.userDetail.notes), payload],
      },
      error: null,
    };
  case actions.CREATE_USER_NOTE_ERROR:
    return {
      ...state,
      userNoteRequestLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.DELETE_USER_NOTE_REQUEST:
    return {
      ...state,
      userNoteRequestLoading: true,
      error_message: null,
      error: null,
    };
  case actions.DELETE_USER_NOTE_SUCCESS:
    return {
      ...state,
      userNoteRequestLoading: false,
      error_message: null,
      error: null,
      userDetail: {
        ...cloneDeep(state.userDetail),
        notes:
            payload && "id" in payload
              ? [
                ...cloneDeep(state.userDetail.notes).filter(
                  userNote => Number(userNote.id) !== Number(payload.id)
                ),
              ]
              : [...cloneDeep(state.userDetail.notes)],
      },
    };
  case actions.DELETE_USER_NOTE_ERROR:
    return {
      ...state,
      userNoteRequestLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
