import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchAdornmentTypesRequest = () =>
  makeCommonAction(types.FETCH_ADORNMENT_TYPE_REQUEST);
const fetchAdornmentTypesSuccess = payload =>
  makeCommonAction(types.FETCH_ADORNMENT_TYPE_SUCCESS, payload);
const fetchAdornmentTypesError = error =>
  makeCommonAction(types.FETCH_ADORNMENT_TYPE_ERROR, error, true);

export function fetchAdornmentTypes() {
  return makeActionCreator({
    endpoint: "/adornmenttypes?include=[adornmentApplicabilities]",
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchAdornmentTypesRequest,
    successFunc: fetchAdornmentTypesSuccess,
    errorFunc: fetchAdornmentTypesError,
    error_message: "Error fetching Adornment types",
  });
}
