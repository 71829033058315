export const status = {
  new: {
    color: "#D0021B",
    name: "New",
    nameNoAdornment: "New",
    description: "New Quote",
    icon: "warning",
    nextActionLabel: "Send For Approval",
    nextStatus: "sent_for_approval",
    nextStatusNoAdornment: "sent_for_approval",
  },
  sent_for_approval: {
    color: "#F5A623",
    name: "Est. Sent For Approval",
    nameNoAdornment: "Sent For Approval",
    eu: {
      name: "Est. Pending Approval",
      nameNoAdornment: "Pending Approval",
    },
    description: "Quote Est. Sent For Approval",
    descriptionNoAdornment: "Sent For Approval",
    icon: "check",
    nextActionLabel: "Approve Quote",
    nextActionLabelNoAdornment: "Place Order",
    nextStatus: "processing",
    nextStatusNoAdornment: "art_approved",
  },
  processing: {
    color: "#417505",
    name: "Processing",
    description: "Quote Processing",
    icon: "autorenew",
    nextActionLabel: "Send For Art Approval",
    nextStatus: "sent_for_art_approval",
  },
  sent_for_art_approval: {
    color: "#FF9F00",
    name: "Sent For Art Approval",
    eu: {
      name: "Pending Art Approval",
    },
    description: "Quote Sent For Art Approval",
    icon: "check",
    nextActionLabel: "Place Order",
    nextStatus: "art_approved",
  },
  art_approved: {
    color: "#66BF00",
    name: "Art Approved",
    nameNoAdornment: "Approved",
    description: "Art Approved",
    descriptionNoAdornment: "Approved",
    icon: "assignment_turned_in",
  },
  turned_to_order: {
    color: "#34B8DD",
    name: "Turned To Order",
    nameNoAdornment: "Turned To Order",
    description: "Quote Turned To Order",
    icon: "assignment_turned_in",
  },
};
export const NO_ART = "no-art";
export const UPLOAD_ART = "upload-art";
export const PLACE_ORDER = "Proceed To Final Order";
export const SEND_QUOTE_REQUEST = "Send Quote Request";
export const PRICING_TABLE_INFO = "Open Price Table to view full discount range.\nUse Qty Calc to determine any specific quantity total.";
export const QUANTITY_ITEM_INFO = "A quantity is not required at this stage.\nUse this as a calculator to quickly see an order total at any quantity contemplated.";