import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QuoteStorageService from "../../../../../services/QuoteStorageService";
import QuoteComplete from "./QuoteComplete";
import {fetchQuote} from "../../../../../actions/v2/quoteActions";
import {openNotification} from "../../../../../actions/notificationActions";
import {logout} from "../../../../../actions/authActions";

function mapStateToProps(state) {
  const {quotes: {quote}, auth: {isAuthenticated, user, isInternal}} = state;

  return {
    quote,
    isAuthenticated,
    user,
    isInternal,
    store: new QuoteStorageService(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuote: bindActionCreators(fetchQuote, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    logout: bindActionCreators(logout, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteComplete)
);
