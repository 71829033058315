import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";

import ChillButton from "../../../shared/ChillButton";
import Typography from "../Typography";

class CreateBrand extends Component {
  state = {
    name: "",
  };

  handleChange = (event, value) => {
    this.setState({name: value});
  };

  handleCreate = async () => {
    const {companyId, createBrand, onBrandCreated} = this.props;
    const {name} = this.state;
    if (!name || !companyId) return;
    const brand = await createBrand({name, companyId});
    this.setState({name: ""});
    onBrandCreated(brand);
  };

  render() {
    const {onClose} = this.props;
    const {name} = this.state;
    return (
      <Fragment>
        <Typography variant="h3" style={{textAlign: "center"}}>
          New Brand to Assign Art
        </Typography>
        <TextField
          floatingLabelText="Brand Name"
          value={name}
          onChange={this.handleChange}
        />
        <ChillButton
          isDisabled={!name}
          onClick={this.handleCreate}
          name="Create new brand"
          fontIconStyles={{
            color: "#FFFFFF",
            fontSize: 18,
            marginRight: 4,
          }}
          className="chill-button btn-1 flex w-100 chill-button--large uppercase"
          height={40}
          marginTop={40}
          marginBottom={20}
        />
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer uppercase margin-bottom-24"
          color="primary.light"
          onClick={onClose}
        >
          Cancel
        </Typography>
      </Fragment>
    );
  }
}

CreateBrand.propTypes = {
  companyId: PropTypes.number,
  createBrand: PropTypes.func,
  onBrandCreated: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateBrand;
