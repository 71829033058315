import lodash from "lodash";

export const objectToQueryString = object => {
  const results = [];
  lodash.forOwn(object, (value, key) => {
    if (Array.isArray(value)) {
      lodash.forOwn(value, value => {
        results.push(`${key}=${value}`);
      });
    } else {
      results.push(`${key}=${value}`);
    }
  });
  return results.join("&");
};
