import React, { Component } from "react";
import PropTypes from "prop-types";
import _defaultTo from "lodash/defaultTo";
import _get from "lodash/get";

import ChillButton from "../../../shared/ChillButton";
import NotesForm from "../../../shared/NotesForm";
import OrderSummary from "../../../../utils/orderSummary";
import { getCurrencyFormattedNumber } from "../../../../utils/numberFormat";
import {ORDER_BUTTON_INTERNAL, ORDER_BUTTON_EXTERNAL} from "../../../../constants/order";
import OrderItemCardFactory from "./OrderItemCardFactory";

export default class ReorderItems extends Component {
  static propTypes = {
    fetchCompanyContacts: PropTypes.func.isRequired,
    reorderItems: PropTypes.array,
    onUpdated: PropTypes.func,
    onFailed: PropTypes.func,
    hasInvalidMiscellaneous: PropTypes.func,
    sameRecipient: PropTypes.bool,
    shipments: PropTypes.array,
    fetchOpenOrders: PropTypes.func,
    order: PropTypes.object,
    fetchShipments: PropTypes.func,
    company: PropTypes.object,
    companyContacts: PropTypes.array,
    isOrderValid: PropTypes.bool,
    onSubmitOrder: PropTypes.func,
    orderData: PropTypes.object,
    onOrderChange: PropTypes.func,
    onSaveDraft: PropTypes.func,
    isInternal: PropTypes.bool,
    isDisabled: PropTypes.func,
    isConfirmed: PropTypes.bool,
    confirmOrderModal: PropTypes.func,
    buttonIsDisabled: PropTypes.bool,
    errors: PropTypes.object,
    validateFields: PropTypes.func,
    clearError: PropTypes.func,
  };

  state = {
    activeOption: null,
    selectedOrderItem: null,
    selectedRecipient: null,
    shipmentItemSource: null,
  };

  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(event, value);
    }
  };

  handleSelectOption = (
    activeOption,
    selectedOrderItem,
    selectedRecipient,
    shipmentItemSource
  ) => {
    this.setState({
      activeOption,
      selectedRecipient,
      selectedOrderItem,
      shipmentItemSource,
    });
  };

  cleanSelectedAction = () => {
    this.setState({
      activeOption: null,
      selectedOrderItem: null,
      selectedRecipient: null,
      shipmentItemSource: null,
    });
  };

  handleRefreshItems = async () => {
    const {
      fetchCompanyContacts,
      fetchOpenOrders,
      company,
      updateOrderState,
    } = this.props;

    await fetchCompanyContacts(company.id);
    const openOrders = await fetchOpenOrders(company.id);
    updateOrderState(openOrders);
  };

  renderSummary = () => {
    const { reorderItems } = this.props;
    if (!reorderItems) return;
    const orderSummary = new OrderSummary(reorderItems);
    return (
      <div
        className="flex justify-content-end"
        style={{
          height: 61,
          marginTop: 32,
        }}
      >
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total products</div>
          <div style={SummaryStyle.data}>{orderSummary.totalItems}</div>
        </div>
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total shipped</div>
          <div style={SummaryStyle.data}>{orderSummary.totalShipped}</div>
        </div>
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total to inventory</div>
          <div style={SummaryStyle.data}>{orderSummary.totalToInventory}</div>
        </div>
        <div style={SummaryStyle.separator} />
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total unit cost</div>
          <div style={SummaryStyle.data}>
            {getCurrencyFormattedNumber(orderSummary.totalUnitCost)}
          </div>
        </div>
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total discount</div>
          <div style={SummaryStyle.data}>
            ({getCurrencyFormattedNumber(orderSummary.totalDiscount)})
          </div>
        </div>
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Total adorn. cost</div>
          <div style={SummaryStyle.data}>
            {getCurrencyFormattedNumber(orderSummary.totalAdornmentCost)}
          </div>
        </div>
        <div className="margin-left-20">
          <div style={SummaryStyle.header}>Order total</div>
          <div style={SummaryStyle.data}>
            {getCurrencyFormattedNumber(orderSummary.totalOrder)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      selectedOrderItem,
      selectedRecipient,
      activeOption,
      shipmentItemSource,
    } = this.state;
    const {
      isOrderValid,
      reorderItems,
      sameRecipient,
      companyContacts,
      company,
      onSubmitOrder,
      shipments,
      onOrderChange,
      onSaveDraft,
      orderData,
      isInternal,
      isDisabled,
      isConfirmed,
      hasInvalidMiscellaneous,
      confirmOrderModal,
      order,
      validateFields,
      errors,
      clearError,
    } = this.props;

    const sortOrderItems = _defaultTo(reorderItems, []).sort(
      (actual, next) => actual.id - next.id
    );

    return (
      <div>
        {sortOrderItems.map(reorderItem =>
          OrderItemCardFactory.build(_get(reorderItem, "orderItemType.name"), {
            order,
            reorderItem: reorderItem,
            hasInvalidMiscellaneous,
            reorderItems: reorderItems,
            key: reorderItem.id,
            disableRecipient: sameRecipient,
            onItemUpdated: this.handleRefreshItems,
            onItemArchived: this.handleRefreshItems,
            onShipmentUpdated: this.handleRefreshItems,
            companyId: company && Number(company.id),
            companyContacts: companyContacts,
            onSelectedOption: this.handleSelectOption,
            selectedRecipient: selectedRecipient,
            selectedOrderItem: selectedOrderItem,
            activeOption: activeOption,
            shipmentItemSource: shipmentItemSource,
            cleanSelectedAction: this.cleanSelectedAction,
            shipments: shipments,
            isInternal: isInternal,
            isDisabled: isDisabled,
            errors: errors,
            clearError: clearError,
          })
        )}
        {this.renderSummary()}
        {
          isInternal && isConfirmed &&
          <div className="flex justify-content-end">
            <ChillButton
              className="pageIndexHeader_btn"
              name="CREATE ORDER"
              height={40}
              width={160}
              icon="check"
              marginTop={24}
              isDisabled={!isOrderValid}
              onClick={onSubmitOrder}
            />
          </div>
        }
        {!isConfirmed &&
          <div className="flex justify-content-end">
            <ChillButton
              className="pageIndexHeader_btn"
              name={isInternal ? ORDER_BUTTON_INTERNAL : ORDER_BUTTON_EXTERNAL}
              height={40}
              width={160}
              icon="check"
              marginTop={24}
              onClick={() => {
                validateFields(true) && confirmOrderModal();
              }
              }
            />
          </div>
        }

        <NotesForm
          order={orderData}
          onChange={onOrderChange}
          onSaveDraft={onSaveDraft}
          isInternal={isInternal}
        />
      </div>
    );
  }
}

const SummaryStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
    marginBottom: 12,
  },

  data: {
    color: "#0C5371",
    fontSize: 24,
  },

  separator: {
    borderLeft: "1px #BDC6CF solid",
    marginLeft: 20,
    marginTop: 5,
  },
};
