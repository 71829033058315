import * as types from "../constants/actionTypes";
import RequestService from "../services/RequestService";

import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchBrandsRequest = () => makeCommonAction(types.FETCH_BRANDS_REQUEST);
const fetchBrandsSuccess = payload =>
  makeCommonAction(types.FETCH_BRANDS_SUCCESS, payload);
const fetchBrandsError = error =>
  makeCommonAction(types.FETCH_BRANDS_ERROR, error, true);

const fetchBrandRequest = () => makeCommonAction(types.FETCH_BRAND_REQUEST);
const fetchBrandSuccess = payload =>
  makeCommonAction(types.FETCH_BRAND_SUCCESS, payload);
const fetchBrandError = error =>
  makeCommonAction(types.FETCH_BRAND_ERROR, error, true);

const createBrandRequest = () => makeCommonAction(types.CREATE_BRAND_REQUEST);
const createBrandSuccess = payload =>
  makeCommonAction(types.CREATE_BRAND_SUCCESS, payload);
const createBrandError = error =>
  makeCommonAction(types.CREATE_BRAND_ERROR, error, true);

const updateBrandRequest = () => makeCommonAction(types.UPDATE_BRAND_REQUEST);
const updateBrandSuccess = payload =>
  makeCommonAction(types.UPDATE_BRAND_SUCCESS, payload);
const updateBrandError = error =>
  makeCommonAction(types.UPDATE_BRAND_ERROR, error, true);

const deleteBrandRequest = () => makeCommonAction(types.DELETE_BRAND_REQUEST);
const deleteBrandSuccess = payload =>
  makeCommonAction(types.DELETE_BRAND_SUCCESS, payload);
const deleteBrandError = error =>
  makeCommonAction(types.DELETE_BRAND_ERROR, error, true);

function _buildFetchUrl(opts) {
  const fetchOptions = {
    page: (opts && opts.page) || 1,
    perPage: 7,
    orderDirection: (opts && opts.orderDirection) || "asc",
    orderBy: (opts && opts.orderBy) || "name",
    filter: (opts && opts.filter) || "",
    include: (opts && opts.include) || "",
  };
  const url = `/companies/${opts.id}/brands?sort=${
    fetchOptions.orderBy
  }&page[number]=${fetchOptions.page}&page[size]=${
    fetchOptions.perPage
  }&meta=&sortDirection=${fetchOptions.orderDirection}${
    fetchOptions.filter
  }&include=${fetchOptions.include}`;
  return url;
}

export function fetchBrands(opts) {
  return makeActionCreator({
    endpoint: _buildFetchUrl(opts),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchBrandsRequest,
    successFunc: fetchBrandsSuccess,
    errorFunc: fetchBrandsError,
    afterReqFunc: result =>
      (result.meta.currentPage = (opts && opts.page) || 1),
    error_message: "Error fetching brands",
  });
}

export function searchBrands(id, query, options) {
  const searchOptions = options || {
    orderBy: "first_name",
    orderDirection: "ASC",
  };
  const url = RequestService.buildSearchUrl(
    `companies/${id}/brands`,
    searchOptions.include || "[arts.revisions]",
    query,
    searchOptions
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchBrandsRequest,
    successFunc: fetchBrandsSuccess,
    errorFunc: fetchBrandsError,
    error_message: "Error fetching brands",
  });
}

export function fetchBrand(id, options = {}) {
  const include =
    options.include === "" ? "" : "[arts.revisions.adornmentType,company]";
  return makeActionCreator({
    endpoint: `/brands/${id}?include=${include}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchBrandRequest,
    successFunc: fetchBrandSuccess,
    errorFunc: fetchBrandError,
    error_message: "Error fetching brand",
  });
}

export function createBrand(brand) {
  return makeActionCreator({
    endpoint: "/brands/",
    fetchOptions: {
      body: brand,
      method: "POST",
    },
    requestFunc: createBrandRequest,
    successFunc: createBrandSuccess,
    errorFunc: createBrandError,
    error_message: "Error creating Brand",
    successMessage: "Brand created successfully",
  });
}

export function updateBrand(id, artData) {
  return makeActionCreator({
    endpoint: `/brands/${id}?include=arts`,

    fetchOptions: {
      body: artData,
      method: "PUT",
    },
    requestFunc: updateBrandRequest,
    successFunc: updateBrandSuccess,
    errorFunc: updateBrandError,
    error_message: "Error updating Brand",
    successMessage: "Brand updated successfully",
  });
}

export function deleteBrand(id) {
  return makeActionCreator({
    endpoint: `/brands/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteBrandRequest,
    successFunc: deleteBrandSuccess,
    errorFunc: deleteBrandError,
    error_message: "Error deleting brand",
    successMessage: "Brand removed successfully",
  });
}
