import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";
import Pagination from "../../../../shared/Pagination";
import ChillButton from "../../../../shared/ChillButton";
import WalletTable from "./WalletTable";
import SelectField from "material-ui/SelectField";
import FlatButton from "material-ui/FlatButton";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";

const blankStyle = {
  margin: "16px 0",
};

export default class WalletTab extends Component {
  static propTypes = {
    selectedTab: PropTypes.string,
    setPage: PropTypes.func,
    page: PropTypes.number,
    company: PropTypes.object,
    isLoading: PropTypes.bool,
    fetchWalletMembers: PropTypes.func,
    fetchCompanyContacts: PropTypes.func,
    updateWalletMember: PropTypes.func.isRequired,
    createWalletMember: PropTypes.func.isRequired,
    walletMembers: PropTypes.array,
    walletMemberCount: PropTypes.number,
    companyId: PropTypes.any,
    companyContacts: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.fetchWalletMembers();
    this.state = {
      addingUser: false,
      companyContactId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedTab !== "wallet" &&
      this.props.selectedTab === "wallet"
    ) {
      const options = {
        perPage: 0,
      };
      this.props.fetchCompanyContacts(options);
    }
    if (
      hasQueryParamsChanged(this.props, prevProps) &&
      this.props.selectedTab === "wallet"
    ) {
      this.fetchWalletMembers();
    }
  }

  fetchWalletMembers = () => {
    const {
      fetchCompanyContacts,
      fetchWalletMembers,
      companyId,
      page,
    } = this.props;

    fetchWalletMembers(companyId, {
      page: page || 1,
    });
    const options = {
      perPage: 0,
    };
    fetchCompanyContacts(options);
  };

  handleAddUserClick() {
    this.setState({addingUser: true});
  }

  async handleCreateWalletClick() {
    const {companyContactId} = this.state;
    const {createWalletMember, companyId} = this.props;
    if (companyContactId) {
      const params = {
        companyId,
        companyContactId: companyContactId,
        isWalletMember: true,
        isWalletDefaultMember: false,
      };
      await createWalletMember(companyId, params, () => {
        this.fetchWalletMembers(companyId);
      });
      this.setState({addingUser: false, companyContactId: null});
    }
  }

  handlecompanyContactIdSelected(value) {
    if (value) {
      this.setState({companyContactId: value});
    }
  }

  _renderAddButton() {
    return (
      <ChillButton
        className="add-wallet-button"
        name={"ADD TO WALLET"}
        height={40}
        width={157}
        marginTop={17}
        marginBottom={6}
        marginLeft={16}
        icon={"adduser"}
        onClick={() => this.handleAddUserClick()}
      />
    );
  }
  _getCompanyNonWalletUsersDataSource() {
    if (!this.props.company) return [];
    const {walletMembers, companyContacts: contacts} = this.props;

    if (!contacts || !walletMembers) return [];
    const contactsWithUsers = contacts;
    const contactIds = walletMembers.map(member => member.companyContactId);

    const filteredCompanyContact = contactsWithUsers.filter(({id}) => {
      return !contactIds.includes(Number(id));
    });

    return lodash.sortBy(
      filteredCompanyContact.map(({id, name}) => {
        return {text: name, value: parseInt(id)};
      }),
      ["text"]
    );
  }

  handleSelectPage = ({page}) => {
    this.props.setPage(page);
  };

  _renderPagination() {
    const {walletMemberCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={options => this.handleSelectPage(options)}
          count={walletMemberCount}
          currentPage={this.props.page - 1}
        />
      </div>
    );
  }

  _renderUserFields(nonWalletUsers) {
    return (
      <div className="flex flex-center">
        <SelectField
          inputStyle={formStyles.inputStyle}
          floatingLabelText="Add Wallet Member"
          value={this.state.companyContactId}
          onChange={(_, __, value) =>
            this.handlecompanyContactIdSelected(value)
          }
          autoWidth={true}
          style={{
            marginRight: "24px",
            width: 303,
          }}
          iconStyle={{
            right: "-16px",
            ...SelectFieldIconStyle,
          }}
          hintText="Select Recipient"
          menuStyle={formStyles.selectMenuStyle}
          underlineStyle={formStyles.underlineStyle}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
        >
          {nonWalletUsers.map(({value, text}) => (
            <CNGMenuItem
              key={`company_association_${value}`}
              value={value}
              primaryText={text}
            />
          ))}
        </SelectField>
        <ChillButton
          name=""
          isDisabled={!this.state.companyContactId}
          height={40}
          width={40}
          marginLeft={16}
          marginRightIcon={0}
          icon={"check"}
          widthIcon={15}
          onClick={() => this.handleCreateWalletClick()}
        />
        <FlatButton
          label="CANCEL"
          labelPosition="before"
          primary={true}
          labelStyle={{
            fontWeight: "bold",
          }}
          onClick={() =>
            this.setState({addingUser: false, companyContactId: null})
          }
        />
      </div>
    );
  }
  _renderNoUserAvailableToAdd() {
    return (
      <div style={blankStyle}>
        <p>All users associated to this company are wallet users</p>
      </div>
    );
  }
  _canShowUserFields(nonWalletUsers) {
    return nonWalletUsers.length > 0;
  }

  render() {
    const {
      company,
      updateWalletMember,
      isLoading,
      walletMembers,
      walletMemberCount,
    } = this.props;
    const {addingUser} = this.state;
    const nonWalletUsers = this._getCompanyNonWalletUsersDataSource();

    return (
      <div className="tab-container" style={{paddingTop: 0}}>
        {this._canShowUserFields(nonWalletUsers) ? (
          <div className="dashboard_top" style={{marginBottom: 0}}>
            {addingUser
              ? this._renderUserFields(nonWalletUsers)
              : this._renderAddButton()}
          </div>
        ) : (
          this._renderNoUserAvailableToAdd()
        )}
        <WalletTable
          isLoading={isLoading}
          walletMembers={walletMembers}
          updateWalletMember={updateWalletMember}
          fetchWalletMembers={this.fetchWalletMembers}
          company={company}
        />

        {walletMembers && walletMemberCount > 7
          ? this._renderPagination()
          : null}
      </div>
    );
  }
}
