import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import {Link} from "react-router-dom";
import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";

import FormattedPhone from "../../../shared/FormattedPhone";
import FormattedAddress from "../../../shared/FormattedAddress";
import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import CNGTableRow from "../../../shared/CNGTable/CNGTableRow";
import CNGTableRowColumn from "../../../shared/CNGTable/CNGTableRowColumn";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
  thFirstLinkStyle,
  tableRowStyle,
} from "../../../../styles/components/tableStyles";

export default class UserIndexTable extends Component {
  static propTypes = {
    users: PropTypes.array,
    fetchUsers: PropTypes.func,
    fetchUsersByCompany: PropTypes.func,
    sortRowsBy: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  _renderUserCompanies(user) {
    const {isInternal, companies} = user;
    if (isInternal) {
      return "chill-n-go";
    }
    const hasCompanies = Array.isArray(companies);
    if (hasCompanies && companies.length > 1) {
      const companyNames = companies.map(company => company.name);
      return (
        <p title={companyNames.join(", ")} className="subtext">
          Multiple Companies
        </p>
      );
    } else if (hasCompanies && companies.length === 1) {
      const company = companies[0];
      return (
        <Link
          key={`company_${company.id}`}
          to={`/companies/${company.id}`}
          className="no-link-styles"
          title={company.name}
        >
          {company.name}
        </Link>
      );
    } else {
      return "No Company";
    }
  }

  _renderRow(user, index) {
    if (!user) {
      return null;
    }
    const recipient = lodash.get(user, "recipient");

    return (
      <CNGTableRow key={index} styles={tableRowStyle}>
        <CNGTableRowColumn highlight={true}>
          <Link to={`/users/${user && user.id}`} className="no-link-styles">
            {user && user.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn highlight={true}>
          {this._renderUserCompanies(user)}
        </CNGTableRowColumn>

        <CNGTableRowColumn title={user.emailAddress}>
          {user.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          {user ? (
            <FormattedPhone
              phoneNumber={lodash.get(recipient, "phoneNumber")}
              countryCode={lodash.get(recipient, "country.code")}
            />
          ) : (
            "No contact"
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 320,
          }}
        >
          <FormattedAddress
            user={lodash.get(user, "recipient") || {}}
            multilines
          />
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderUserIndexTableHeader() {
    const {sortRowsBy} = this.props;
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          <TableRowColumn style={thFirstLinkStyle} className="CNG-th pointer">
            <div onClick={() => sortRowsBy("fullname")}>
              NAME
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            COMPANY
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            <div onClick={() => sortRowsBy("email_address")}>
              E-MAIL
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            <div onClick={() => sortRowsBy("phone_number")}>
              PHONE
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 320,
            }}
            className="CNG-th"
          >
            <div onClick={() => sortRowsBy("address")}>
              ADDRESS
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
        </TableRow>
      </TableHeader>
    );
  }

  render() {
    const {users, isLoading} = this.props;
    const tableRows = Array.isArray(users)
      ? users.reduce((userRows, user, index) => {
        const userRow = this._renderRow(user, index);
        if (userRow) {
          userRows.push(userRow);
        }
        return userRows;
      }, [])
      : null;
    return (
      <CNGTableCustom
        tableStyle={tableStyle}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {this._renderUserIndexTableHeader()}
        <TableBody displayRowCheckbox={false}>{tableRows}</TableBody>
      </CNGTableCustom>
    );
  }
}
