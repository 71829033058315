import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {fetchBrand} from "../../../actions/brandActions";
import ArtDetailPage from "../../pages/Arts/ArtDetailPage";
import {
  fetchArtRevisions,
  fetchArt,
  deleteArtRevision,
  updateArtRevisionActive,
} from "../../../actions/artActions";
import {openNotification} from "../../../actions/notificationActions";

class ArtDetail extends Component {
  static propTypes = {
    openNotification: PropTypes.func.isRequired,
    fetchArtRevisions: PropTypes.func.isRequired,
    fetchArt: PropTypes.func.isRequired,
    fetchBrand: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    brand: PropTypes.object,
    artRevisions: PropTypes.array,
    artCount: PropTypes.number,
    isLoading: PropTypes.bool,
    selectedArt: PropTypes.object,
    isInternal: PropTypes.bool,
  };

  state = {
    active: false,
    filename: null,
    name: null,
    adornment_type_id: null,
    file: null,
    currentPage: 1,
    details: {},
  };

  handleSelectPage = options => {
    const {fetchArtRevisions} = this.props;
    const {match: {id}} = this.props;
    this.setState({currentPage: options.page});
    fetchArtRevisions(id, {
      ...options,
      orderBy: "-created_at",
      sortDirection: "desc",
    });
  };

  componentWillMount() {
    const {match} = this.props;
    const {brand_id: brandId, id, revision_id: revisionId} = match.params;
    this.props.fetchBrand(brandId);
    this.props.fetchArt(id, revisionId);
    this.props.fetchArtRevisions(id);
  }

  render() {
    return (
      <ArtDetailPage
        onSelectPage={this.handleSelectPage}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    auth: {isInternal},
    brands: {brand},
    art: {isLoading, art, artRevisions, artRevisionsCount},
  } = state;

  return {
    isLoading,
    isInternal,
    artRevisions,
    artRevisionsCount,
    selectedArt: art,
    brand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBrand: bindActionCreators(fetchBrand, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchArt: bindActionCreators(fetchArt, dispatch),
    fetchArtRevisions: bindActionCreators(fetchArtRevisions, dispatch),
    deleteArtRevision: bindActionCreators(deleteArtRevision, dispatch),
    updateArtRevisionActive: bindActionCreators(
      updateArtRevisionActive,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtDetail)
);
