import React, {Component} from "react";
import PropTypes from "prop-types";
import {Tabs, Tab} from "material-ui/Tabs";

import AssociationsTab from "./Associations/AssociationsTab";
import OrderHistoryTab from "./OrderHistory/OrderHistoryTab";
import InventoryTab from "./Inventory/InventoryTab";
import UsersTab from "./Users/UsersTab";
import WalletTab from "../../../containers/Companies/WalletTab";
import AddressBookTab from "../../../containers/Companies/AddressBookTab";
import CompanyNotesTab from "./CompanyNotes/CompanyNotesTab";
import CarrierAssignmentTab from "./CarrierAssignment";
import QuotesTab from "./Quotes/QuotesTab";

const tabWidth = 129;
const inkBarStyle = {
  backgroundColor: "#34b8dd",
  height: 2,
  width: tabWidth,
  padding: "0 14px",
};

const inkBarContainerStyle = {
  width: "102%",
  paddingLeft: 30,
};
const tabItemContainerStyle = {
  background: "transparent",
  width: "100%",
  padding: "0 5px",
  borderBottom: "1px solid #BDC6CF",
  justifyContent: "space-around",
};
const tabButtonStyle = {
  color: "rgba(12, 83, 113, 0.5)",
  width: tabWidth,
  fontSize: 12,
  fontWeight: "bold",
  transition: "600ms color linear",
};
const selectedTabStyle = {
  ...tabButtonStyle,
  color: "#0C5371",
};

class CompanyTabs extends Component {
  componentDidMount() {
    document.getElementsByClassName("PageContainer")[0].scrollTop = 0;
  }

  _getTabButtonStyle(tabName) {
    const {selectedTab} = this.props;
    return selectedTab === tabName ? selectedTabStyle : tabButtonStyle;
  }

  _getTabs() {
    const {isInternal} = this.props;
    const baseTabs = ["orders", "inventory", "users", "wallet", "recipients"];
    if (isInternal) {
      return ["associations", ...baseTabs, "notes"];
    }
    return baseTabs;
  }

  handleSelectTab = value => {
    this.props.updateQueryParams("tab", value);
  };

  render() {
    const {
      isInternal,
      company,
      companies,
      createCompanyNote,
      deleteCompanyNote,
      companyContacts,
      users,
      orders,
      ordersCount,
      inventoryItems,
      inventoryItemsCount,
      quotes,
      quotesCount,
      associatedCompanies,
      createCompanyCompanyAssociation,
      deleteCompanyCompanyAssociation,
      searchOrders,
      searchInventory,
      searchUsers,
      searchContacts,
      updateCompany,
      updateUserWithCompanies,
      companyContactsCount,
      fetchCompanyContacts,
      userCount,
      searchAssociatedCompanies,
      addDSODItems,
      isLoadingAssociatedCompanies,
      isLoadingOrderHistory,
      isLoadinginventory,
      isLoadingQuotes,
      isLoadingUsers,
      isCompanyContactsLoading,
      isNoteRequestLoading,
      selectedTab,
      setSearchTerm,
      searchTerm,
      setPage,
      addQueryParam,
      page,
      selectedOrderType,
      selectedQuoteType,
      location,
      history,
      archiveUserCompany,
      companyId,
      createReorderItem,
      currentUserId,
      fetchOpenOrders,
      searchQuotes,
    } = this.props;

    return (
      <Tabs
        inkBarContainerStyle={
          isInternal
            ? {
              padding: "0 14px",
              width: "101%",
            }
            : inkBarContainerStyle
        }
        tabItemContainerStyle={tabItemContainerStyle}
        inkBarStyle={inkBarStyle}
        value={selectedTab}
        onChange={this.handleSelectTab}
        style={{}}
        className="company-tabs"
      >
        {isInternal ? (
          <Tab
            id="notes-tab"
            value="notes"
            label="Notes"
            style={tabButtonStyle}
            buttonStyle={this._getTabButtonStyle("notes")}
          >
            <CompanyNotesTab
              isLoading={isNoteRequestLoading}
              company={company}
              userDetail={company}
              notes={company && company.notes}
              deleteCompanyNote={deleteCompanyNote}
              createCompanyNote={createCompanyNote}
            />
          </Tab>
        ) : null}
        <Tab
          id="order-history-tab"
          value="orders"
          label="Order History"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("orders")}
        >
          {(selectedTab === "orders" || !selectedTab) && (
            <OrderHistoryTab
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              isLoading={isLoadingOrderHistory}
              orders={orders}
              ordersCount={ordersCount}
              company={company}
              searchOrders={searchOrders}
              setPage={setPage}
              page={page}
              isInternal={isInternal}
              location={location}
              history={history}
              addQueryParam={addQueryParam}
              selectedOrderType={selectedOrderType}
              selectedTab={selectedTab}
              createReorderItem={createReorderItem}
              fetchOpenOrders={fetchOpenOrders}
              currentUserId={currentUserId}
            />
          )}
        </Tab>
        <Tab
          id="inventory-tab"
          value="inventory"
          label="Inventory"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("inventory")}
        >
          {selectedTab === "inventory" && (
            <InventoryTab
              page={page}
              location={location}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              isLoading={isLoadinginventory}
              orders={orders}
              inventoryItems={inventoryItems}
              inventoryItemsCount={inventoryItemsCount}
              searchInventory={searchInventory}
              company={company}
              companyId={company && company.id}
              addDSODItems={addDSODItems}
              selectedTab={selectedTab}
              setPage={setPage}
              isInternal={isInternal}
              fetchOpenOrders={fetchOpenOrders}
              currentUserId={currentUserId}
            />
          )}
        </Tab>
        {isInternal ? null : (
          <Tab
            id="quotes-tab"
            value="quotes"
            label="Quotes"
            style={tabButtonStyle}
            buttonStyle={this._getTabButtonStyle("quotes")}
          >
            {selectedTab === "quotes" ? (
              <QuotesTab
                page={page}
                history={history}
                location={location}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                currentUserId={currentUserId}
                selectedTab={selectedTab}
                setPage={setPage}
                isInternal={isInternal}
                addQueryParam={addQueryParam}
                isLoading={isLoadingQuotes}
                quotes={quotes}
                quotesCount={quotesCount}
                searchQuotes={searchQuotes}
                selectedQuoteType={selectedQuoteType}
              />
            ) : null}
          </Tab>
        )}
        <Tab
          id="users-tab"
          value="users"
          label="Users"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("users")}
        >
          {selectedTab === "users" ? (
            <UsersTab
              page={page}
              selectedTab={selectedTab}
              location={location}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              isLoading={isLoadingUsers}
              companyId={companyId}
              company={company}
              setPage={setPage}
              isInternal={isInternal}
              updateCompany={updateCompany}
              users={users}
              userCount={userCount}
              searchUsers={searchUsers}
              currentUserId={currentUserId}
              archiveUserCompany={archiveUserCompany}
            />
          ) : (
            <div />
          )}
        </Tab>
        <Tab
          id="wallet-tab"
          value="wallet"
          label="Wallet"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("wallet")}
        >
          {selectedTab === "wallet" && (
            <WalletTab
              setPage={setPage}
              page={page}
              companyId={companyId}
              companyContacts={companyContacts}
              isLoading={isLoadingUsers}
              company={company}
              fetchCompanyContacts={fetchCompanyContacts}
              updateUserWithCompanies={updateUserWithCompanies}
              selectedTab={selectedTab}
            />
          )}
        </Tab>
        <Tab
          id="address-book-tab"
          value="recipients"
          label="Address Book"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("recipients")}
        >
          {selectedTab === "recipients" && (
            <AddressBookTab
              isInternal={isInternal}
              companyId={companyId}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              isLoading={isCompanyContactsLoading}
              searchContacts={searchContacts}
              companyContacts={companyContacts}
              companyContactsCount={companyContactsCount}
              company={company}
              page={page}
              fetchCompanyContacts={fetchCompanyContacts}
              selectedTab={selectedTab}
              history={history}
            />
          )}
        </Tab>

        {isInternal ? (
          <Tab
            id="associations-tab"
            value="associations"
            label="Associations"
            style={tabButtonStyle}
            buttonStyle={this._getTabButtonStyle("associations")}
          >
            <AssociationsTab
              location={location}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              companies={companies}
              orders={orders}
              company={company}
              isLoading={isLoadingAssociatedCompanies}
              associatedCompanies={associatedCompanies}
              createCompanyCompanyAssociation={createCompanyCompanyAssociation}
              deleteCompanyCompanyAssociation={id =>
                deleteCompanyCompanyAssociation(id)
              }
              searchAssociatedCompanies={searchAssociatedCompanies}
            />
          </Tab>
        ) : null}

        <Tab
          id="carrier-assignment-tab"
          value="carriers"
          label="Shipping Carriers"
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("carriers")}
        >
          {selectedTab === "carriers" && (
            <CarrierAssignmentTab companyId={companyId} history={history} />
          )}
        </Tab>
      </Tabs>
    );
  }
}

CompanyTabs.propTypes = {
  companyId: PropTypes.any,
  updateCompany: PropTypes.func,
  addDSODItems: PropTypes.func,
  company: PropTypes.object,
  companies: PropTypes.array,
  inventoryItems: PropTypes.array,
  inventoryItemsCount: PropTypes.number,
  associatedCompanies: PropTypes.array.isRequired,
  createCompanyCompanyAssociation: PropTypes.func.isRequired,
  deleteCompanyCompanyAssociation: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  ordersCount: PropTypes.number,
  quotes: PropTypes.array.isRequired,
  quotesCount: PropTypes.number,
  searchOrders: PropTypes.func.isRequired,
  searchInventory: PropTypes.func.isRequired,
  isInternal: PropTypes.bool,
  currentUserId: PropTypes.number,
  location: PropTypes.object,
  history: PropTypes.object,
  users: PropTypes.array,
  userCount: PropTypes.number,
  deleteCompanyNote: PropTypes.func,
  createCompanyNote: PropTypes.func,
  searchUsers: PropTypes.func,
  searchContacts: PropTypes.func,
  updateUserWithCompanies: PropTypes.func.isRequired,
  fetchCompanyContacts: PropTypes.func.isRequired,
  companyContacts: PropTypes.array,
  companyContactsCount: PropTypes.number,
  searchAssociatedCompanies: PropTypes.func.isRequired,
  isLoadingAssociatedCompanies: PropTypes.bool,
  isLoadingOrderHistory: PropTypes.bool,
  isLoadinginventory: PropTypes.bool,
  isLoadingQuotes: PropTypes.bool,
  isLoadingUsers: PropTypes.bool,
  isCompanyContactsLoading: PropTypes.bool,
  isNoteRequestLoading: PropTypes.bool,
  selectedTab: PropTypes.string,
  updateQueryParams: PropTypes.func,
  page: PropTypes.number,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  setPage: PropTypes.func,
  addQueryParam: PropTypes.func,
  selectedOrderType: PropTypes.string,
  selectedQuoteType: PropTypes.string,
  archiveUserCompany: PropTypes.func,
  createReorderItem: PropTypes.func,
  fetchOpenOrders: PropTypes.func,
  searchQuotes: PropTypes.func,
};

export default CompanyTabs;
