import * as actions from "../constants/actionTypes";
import initialState from "./initialState";
import {cloneDeep} from "lodash";

export default function companyReducer(
  state = initialState.companies,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_DSOD_COMPANIES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_DSOD_COMPANIES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      dsodCompanies: payload,
    };
  case actions.FETCH_DSOD_COMPANIES_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_COMPANIES_WITH_DSOD_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_COMPANIES_WITH_DSOD_SUCCESS:
    return {
      ...state,
      isLoading: false,
      companiesWithDSOD: payload,
      hasCompaniesWithoutDSODAndInventoryItems:
          meta.hasCompaniesWithoutDSODAndInventoryItems,
    };
  case actions.FETCH_COMPANIES_WITH_DSOD_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_COMPANIES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_COMPANIES_SUCCESS:
    return {
      ...state,
      companies: payload,
      companiesCount: meta.count,
      currentPage: meta.currentPage,
      isLoading: false,
    };
  case actions.FETCH_COMPANIES_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_COMPANY_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_COMPANY_SUCCESS:
    return {
      ...state,
      company: payload,
      isLoading: false,
    };
  case actions.FETCH_COMPANY_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_COMPANY_REQUEST:
    return {
      ...state,
      company: null,
      isLoading: true,
      error: null,
      error_message: "",
    };
  case actions.CREATE_COMPANY_SUCCESS:
    return {
      ...state,
      companies: [...state.companies, payload],
      company: payload,
      isLoading: false,
    };
  case actions.CREATE_COMPANY_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.UPDATE_COMPANY_REQUEST:
    return {
      ...state,
      isLoading: true,
      error: null,
      error_message: "",
    };
  case actions.UPDATE_COMPANY_SUCCESS:
    return {
      ...state,
      isLoading: false,
      company: payload,
    };
  case actions.UPDATE_COMPANY_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.FETCH_COMPANY_CONTACTS_REQUEST:
    return {
      ...state,
      isCompanyContactsLoading: true,
    };
  case actions.FETCH_COMPANY_CONTACTS_SUCCESS:
    return {
      ...state,
      companyContacts: payload,
      companyContactsCount: meta.count,
      isCompanyContactsLoading: false,
    };
  case actions.FETCH_COMPANY_CONTACTS_ERROR:
    return {
      ...state,
      isCompanyContactsLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.FETCH_COMPANY_CONTACT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_COMPANY_CONTACT_SUCCESS:
    return {
      ...state,
      companyContact: payload,
      isLoading: false,
    };
  case actions.FETCH_COMPANY_CONTACT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.CREATE_COMPANY_CONTACT_REQUEST:
    return {
      ...state,
      isLoadingCompanyContactRequest: true,
      error: null,
      error_message: "",
    };
  case actions.CREATE_COMPANY_CONTACT_SUCCESS:
    return {
      ...state,
      isLoadingCompanyContactRequest: false,
    };
  case actions.CREATE_COMPANY_CONTACT_ERROR:
    return {
      ...state,
      isLoadingCompanyContactRequest: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.UPDATE_COMPANY_CONTACT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_COMPANY_CONTACT_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case actions.UPDATE_COMPANY_CONTACT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_ASSOCIATED_COMPANIES_REQUEST:
    return {
      ...state,
      isLoadingAssociatedCompanies: true,
    };
  case actions.FETCH_ASSOCIATED_COMPANIES_SUCCESS:
    return {
      ...state,
      associatedCompanies: payload,
      isLoadingAssociatedCompanies: false,
    };
  case actions.FETCH_ASSOCIATED_COMPANIES_ERROR:
    return {
      ...state,
      isLoadingAssociatedCompanies: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.CREATE_COMPANY_COMPANY_REQUEST:
    return {
      ...state,
      isLoadingAssociatedCompanies: true,
    };
  case actions.CREATE_COMPANY_COMPANY_SUCCESS:
    return {
      ...state,
      associatedCompanies: [...state.associatedCompanies, payload],
      isLoadingAssociatedCompanies: false,
    };
  case actions.CREATE_COMPANY_COMPANY_ERROR:
    return {
      ...state,
      isLoadingAssociatedCompanies: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.DELETE_COMPANY_COMPANY_REQUEST:
    return {
      ...state,
      isLoadingAssociatedCompanies: true,
    };
  case actions.DELETE_COMPANY_COMPANY_SUCCESS:
    return {
      ...state,
      associatedCompanies: [...state.associatedCompanies],
      isLoadingAssociatedCompanies: false,
    };
  case actions.DELETE_COMPANY_COMPANY_ERROR:
    return {
      ...state,
      isLoadingAssociatedCompanies: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.CREATE_COMPANY_NOTE_REQUEST:
    return {
      ...state,
      error_message: null,
      error: null,
      isNoteRequestLoading: true,
    };
  case actions.CREATE_COMPANY_NOTE_SUCCESS:
    return {
      ...state,
      isNoteRequestLoading: false,
      company: {
        ...cloneDeep(state.company),
        notes: [...cloneDeep(state.company.notes), payload],
      },
    };
  case actions.CREATE_COMPANY_NOTE_ERROR:
    return {
      ...state,
      isNoteRequestLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.ARCHIVE_USER_COMPANY_REQUEST:
    return {
      ...state,
      isUserCompanyIsLoading: true,
    };
  case actions.ARCHIVE_USER_COMPANY_SUCCESS:
    return {
      ...state,
      isUserCompanyIsLoading: false,
    };
  case actions.ARCHIVE_USER_COMPANY_ERROR:
    return {
      ...state,
      isUserCompanyIsLoading: false,
    };
  case actions.DELETE_COMPANY_NOTE_REQUEST:
    return {
      ...state,
      isNoteRequestLoading: true,
      error_message: null,
      error: null,
    };
  case actions.DELETE_COMPANY_NOTE_SUCCESS:
    return {
      ...state,
      isNoteRequestLoading: false,
      company: {
        ...cloneDeep(state.company),
        notes:
            payload && "id" in payload
              ? [
                ...cloneDeep(state.company.notes).filter(
                  companyNote => Number(companyNote.id) !== Number(payload.id)
                ),
              ]
              : [...cloneDeep(state.company.notes)],
      },
    };
  case actions.DELETE_COMPANY_NOTE_ERROR:
    return {
      ...state,
      isNoteRequestLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.LOGOUT_SUCCESS:
    return {
      ...initialState.companies,
    };
  case actions.FETCH_WALLET_MEMBERS_REQUEST:
    return {
      ...state,
      isLoadingWalletMembers: true,
    };
  case actions.FETCH_WALLET_MEMBERS_SUCCESS:
    return {
      ...state,
      walletMembers: payload,
      walletMemberCount: meta.count,
      isLoadingWalletMembers: false,
    };
  case actions.FETCH_WALLET_MEMBERS_ERROR:
    return {
      ...state,
      isLoadingWalletMembers: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.UPDATE_WALLET_MEMBER_REQUEST:
    return {
      ...state,
      isLoadingWalletMembers: true,
      error: null,
      error_message: "",
    };
  case actions.UPDATE_WALLET_MEMBER_SUCCESS:
    return {
      ...state,
      isLoadingWalletMembers: false,
    };
  case actions.UPDATE_WALLET_MEMBER_ERROR:
    return {
      ...state,
      isLoadingWalletMembers: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_WALLET_MEMBER_REQUEST:
    return {
      ...state,
      isLoadingWalletMembers: true,
      error: null,
      error_message: "",
    };
  case actions.CREATE_WALLET_MEMBER_SUCCESS:
    return {
      ...state,
      isLoadingWalletMembers: false,
    };
  case actions.CREATE_WALLET_MEMBER_ERROR:
    return {
      ...state,
      isLoadingWalletMembers: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
