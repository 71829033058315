import React from "react";
import ReorderItemCard from "../../../containers/Reorders/components/ReorderItemCard";
import {
  PRODUCT_ORDER_ITEM_NAME,
  LABOR_ORDER_ITEM_NAME,
  SETUP_ORDER_ITEM_NAME,
  MISCELLANEOUS_COST,
  DISCOUNT_ORDER_ITEM_NAME,
} from "../../../../constants/orderItemType";
import LaborItemCard from "./LaborItemCard";
import DiscountItemCard from "./DiscountItemCard";
import MiscellaneousItemCard from "./MiscellaneousItemCard";
import SetUpCostItemCard from "./SetUpCostItemCard";

export default class OrderItemCardFactory {
  static build(orderItemType = "Product", props) {
    switch (orderItemType) {
    case PRODUCT_ORDER_ITEM_NAME:
      return <ReorderItemCard {...props} />;
    case LABOR_ORDER_ITEM_NAME:
      return <LaborItemCard {...props} />;
    case SETUP_ORDER_ITEM_NAME:
      return <SetUpCostItemCard {...props} />;
    case MISCELLANEOUS_COST:
      return <MiscellaneousItemCard {...props} />;
    case DISCOUNT_ORDER_ITEM_NAME:
      return <DiscountItemCard {...props} />;
    default:
      return undefined;
    }
  }
}
