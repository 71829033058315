import React, {Component} from "react";
import PropTypes from "prop-types";
import Pagination from "../../shared/Pagination";
import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BrandDetailHeader from "./Components/BrandDetailHeader";
import ArtList from "./Components/ArtList";
import BreadCrumbs from "../../shared/BreadCrumbs";

export default class BrandDetailPage extends Component {
  static propTypes = {
    art: PropTypes.array,
    artCount: PropTypes.number,
    brand: PropTypes.object,
    brandId: PropTypes.string.isRequired,
    fetchBrand: PropTypes.func.isRequired,
    company: PropTypes.object,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    onUpdateBrandName: PropTypes.func.isRequired,
    onDeleteBrand: PropTypes.func.isRequired,
    onSelectPage: PropTypes.func,
    currentPage: PropTypes.number,
    isLoadingArt: PropTypes.bool,
  };

  _renderPaginiation() {
    const {onSelectPage} = this.props;
    return (
      <div className="userindex_pagination">
        <Pagination
          getData={onSelectPage}
          count={this.props.artCount}
          currentPage={this.props.currentPage}
        />
      </div>
    );
  }

  render() {
    const {
      brand,
      company,
      isInternal,
      onUpdateBrandName,
      onDeleteBrand,
      art,
      artCount,
      isLoading,
      isLoadingArt,
    } = this.props;

    return (
      <PageLayout
        subHeaderText={company ? company.name : null}
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && `${company.id}`}
          middletwo="Brands"
          middletwoLink={company && "brands"}
          pageName={(brand && brand.name) || ""}
        />
        <BrandDetailHeader
          brand={brand}
          isInternal={isInternal}
          onUpdateBrandName={onUpdateBrandName}
          onDeleteBrand={onDeleteBrand}
        />
        <ArtList art={art} artCount={artCount} isLoading={isLoadingArt} />{" "}
        {art && artCount > 7 ? this._renderPaginiation() : null}
      </PageLayout>
    );
  }
}
