import React from "react";
import PropTypes from "prop-types";
import {TableRow} from "material-ui/Table";

const CNGTableRow = props => {
  const rowStyles = {
    height: 66,
    borderRadius: 4,
    backgroundColor: "rgba(189, 198, 207, 0.07)",
    ...props.styles,
  };
  if (props.isSlimRow) {
    rowStyles.height = "50px";
  }
  return (
    <TableRow
      displayBorder={false}
      style={rowStyles}
      selectable={true}
      className={props.className}
      hoverable={true}
      id={props.id}
    >
      {props.children}
    </TableRow>
  );
};

CNGTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  className: PropTypes.string,
  isSlimRow: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CNGTableRow;
