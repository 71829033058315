import fetchAPI from "../../utils/fetchAPI";
import RequestService from "../../services/RequestService";
import {
  FETCH_PRODUCT_CATEGORIES_REQUEST,
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  FETCH_PRODUCT_CATEGORIES_ERROR,
} from "../../constants/actionTypes";

export const fetchProductCategories = options => {
  const {delay, ...opts} = options || {};
  return async dispatch => {
    await fetchAPI(
      {
        url: RequestService.buildIndexUrl("product_categories", null, opts),
        method: "GET",
        types: [
          FETCH_PRODUCT_CATEGORIES_REQUEST,
          FETCH_PRODUCT_CATEGORIES_SUCCESS,
          FETCH_PRODUCT_CATEGORIES_ERROR,
        ],
        delay,
      },
      dispatch
    );
  };
};
