import React, {Component} from "react";

import Column from "../Components/Column";
import {LayoutPropTypes} from "../Base/LayoutProps";

export default class PageContainer extends Component {
  static propTypes = {
    ...LayoutPropTypes,
  };
  render() {
    return (
      <Column
        {...this.props}
        className="PageContainer"
        height={"100%"}
        style={{
          background: "#f4f8f9",
          paddingTop: 72,
          overflowY: "auto",
        }}
      />
    );
  }
}
