import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function shipmentReducer(
  state = initialState.shipment,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_SHIPMENTS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_SHIPMENTS_SUCCESS:
    return {
      ...state,
      shipments: payload,
      shipmentsCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_SHIPMENTS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_SHIPMENT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_SHIPMENT_SUCCESS:
    return {
      ...state,
      shipment: payload,
      isLoading: false,
    };
  case actions.FETCH_SHIPMENT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_SHIPMENT_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_SHIPMENT_ITEM_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case actions.CREATE_SHIPMENT_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_SHIPMENT_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_SHIPMENT_ITEM_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case actions.UPDATE_SHIPMENT_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_SHIPMENT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_SHIPMENT_SUCCESS:
    return {
      ...state,
      shipment: {...state.shipment, ...payload},
      isLoading: false,
    };
  case actions.UPDATE_SHIPMENT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST:
    return {
      ...state,
      isCarrierAssignmentsLoading: true,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS:
    return {
      ...state,
      companyShippingCarrierAssignment: payload,
      isCarrierAssignmentsLoading: false,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      companyShippingCarrierAssignment: null,
      isCarrierAssignmentsLoading: false,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_REQUEST:
    return {
      ...state,
      isCarrierAssignmentsLoading: true,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_SUCCESS:
    return {
      ...state,
      companyShippingCarrierAssignments: payload,
      isCarrierAssignmentsLoading: false,
    };
  case actions.FETCH_COMPANY_SHIPPING_CARRIER_ASSIGNMENTS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isCarrierAssignmentsLoading: false,
    };
  case actions.FETCH_SHIPPING_CARRIERS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_SHIPPING_CARRIERS_SUCCESS:
    return {
      ...state,
      shippingCarriers: payload,
      isLoading: false,
    };
  case actions.FETCH_SHIPPING_CARRIERS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_SHIPPING_METHODS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_SHIPPING_METHODS_SUCCESS:
    return {
      ...state,
      shippingMethods: payload,
      isLoading: false,
    };
  case actions.FETCH_SHIPPING_METHODS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };

  case actions.PATCH_SHIPPING_CARRIER_ASSIGNMENT_REQUEST:
    return {
      ...state,
      isCarrierAssignmentsLoading: true,
    };
  case actions.PATCH_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS:
    return {
      ...state,
      companyShippingCarrierAssignments: state.companyShippingCarrierAssignments.map(
        carrierAssignment => {
          const carrierAssignmentData = {...carrierAssignment};
          if (payload.isDefault) carrierAssignmentData.isDefault = false;
          if (carrierAssignment.id === payload.id)
            return {...carrierAssignment, ...payload};
          return carrierAssignmentData;
        }
      ),
      isCarrierAssignmentsLoading: false,
    };
  case actions.PATCH_SHIPPING_CARRIER_ASSIGNMENT_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isCarrierAssignmentsLoading: false,
    };

  case actions.DELETE_SHIPPING_CARRIER_ASSIGNMENT_REQUEST:
    return {
      ...state,
      isCarrierAssignmentsLoading: true,
    };
  case actions.DELETE_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS:
    return {
      ...state,
      companyShippingCarrierAssignments: state.companyShippingCarrierAssignments.filter(
        ({id}) => Number(id) !== Number(payload.id)
      ),
      isCarrierAssignmentsLoading: false,
    };
  case actions.DELETE_SHIPPING_CARRIER_ASSIGNMENT_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isCarrierAssignmentsLoading: false,
    };

  case actions.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_REQUEST:
    return {
      ...state,
      isCarrierAssignmentsLoading: true,
    };
  case actions.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_SUCCESS:
    return {
      ...state,
      companyShippingCarrierAssignments: [
        ...state.companyShippingCarrierAssignments,
        payload,
      ],
      isCarrierAssignmentsLoading: false,
    };
  case actions.CREATE_COMPANY_SHIPPING_CARRIER_ASSIGNMENT_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isCarrierAssignmentsLoading: false,
    };
  default:
    return state;
  }
}
