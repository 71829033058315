import {stringify} from "query-string";

export default class RequestService {
  static buildIndexUrl(base, include, opts = {}) {
    const {
      page = null,
      perPage = 0,
      filter,
      sort,
      orderBy,
      sortDirection,
      orderDirection,
      include: includeFromParams,
      ...extraParams
    } = opts;
    include = include || includeFromParams;

    return `/${base}${base.includes("?") ? "&" : "?"}sort=${sort ||
      orderBy ||
      "updated_at"}&sortDirection=${sortDirection ||
      orderDirection ||
      "DESC"}&page[number]=${page}&page[size]=${perPage}${
      include ? "&include=" + include : ""
    }${filter ? "&filter=" + filter : ""}&${stringify(extraParams)}`;
  }

  static buildSearchUrl(base, include, query, opts) {
    var searchQuery = query ? `${new URLSearchParams({search_term: query})}` : ""; 
    return `${RequestService.buildIndexUrl(base + "/search", include, opts)}${
      searchQuery}`;
  }
}
