import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DSODEditPage from "../../pages/DSOD/DSODEditPage";
import {openNotification} from "../../../actions/notificationActions";
import {fetchCompany} from "../../../actions/companyActions";

import {
  fetchShipments,
  updateShipmentItem,
  fetchCompanyShippingCarrierAssignments,
  fetchShippingCarriers,
  fetchShippingMethods,
} from "../../../actions/shipmentActions";
import {
  fetchOrder,
  fetchOrderItems,
  updateOrder,
  fetchOrderStatus,
} from "../../../actions/orderActions";
import {fetchPaymentTerms} from "../../../actions/paymentTermsActions";

class OrderEdit extends Component {
  state = {
    orderData: {},
    shipmentData: {},
  };

  componentWillReceiveProps(nextProps) {
    this._setInitialState(nextProps);
  }

  componentDidUpdate() {
    const {
      order,
      match: {params: {company_id: companyId, id: orderId}},
      history,
    } = this.props;
    if (order && !order.isDsod) {
      history.replace(`/companies/${companyId}/orders/${orderId}/edit`);
    }
  }

  componentWillMount() {
    this._fetchData();
  }

  _setInitialState = nextProps => {
    const {
      order,
      shipments,
      shippingMethods,
      fetchShippingMethods,
      companyShippingCarrierAssignments,
    } = nextProps;

    if (!order) return;

    const {
      companyId,
      cngReferenceNumber,
      isDsod,
      orderStatusId,
      purchaseOrderDate,
      submittedOn,
      estimatedShipOn,
      purchaseOrderNumber,
      jobName,
      internalNotes,
      inHandDate,
      clientNotes,
    } = order;

    this.setState({
      orderData: {
        clientNotes,
        internalNotes,
        company_id: companyId,
        cng_reference_number: cngReferenceNumber,
        is_dsod: isDsod,
        order_status_id: orderStatusId,
        purchase_order_number: purchaseOrderNumber,
        submitted_on: submittedOn && new Date(submittedOn),
        in_hand_date: inHandDate && new Date(inHandDate),
        estimated_ship_on: estimatedShipOn && new Date(estimatedShipOn),
        job_name: jobName,
        purchase_order_date: purchaseOrderDate && new Date(purchaseOrderDate),
      },
    });

    if (!shipments || (shipments && shipments.length <= 0)) return;
    const shipment = shipments[0];
    const {
      id: shipmentId,
      trackingNumber,
      shippingMethodId,
      shipmentAccountId,
      actualShipDate,
      shipmentAccount: {accountNumber, shippingCarrierId},
      shippingMethod,
    } = shipment;

    const shippingCarrier = shippingMethod
      ? shippingMethod.shippingCarrier
      : undefined;

    this.setState({
      shipmentData: {
        tracking_number: trackingNumber,
        shipping_method_id: shippingMethodId,
        shipment_account_id: shipmentAccountId,
        shipping_carrier_id: shippingCarrierId,
        shipment_account_number: accountNumber,
        shipping_carrier_name: shippingCarrier && shippingCarrier.name,
        actual_ship_date: actualShipDate,
        id: shipmentId,
      },
    });

    if (!shippingMethods) {
      fetchShippingMethods();
    }

    if (!companyShippingCarrierAssignments) {
      this._fetchShipmentAccounts();
    }
  };

  _fetchShippingMethods = async companyShippingCarrierAssignments => {
    const {fetchShippingCarriers} = this.props;
    const carrierIds = companyShippingCarrierAssignments.map(
      assigment => assigment.shippingCarrierId
    );
    await fetchShippingCarriers({
      filter: `id,in,(${carrierIds.join(",")})`,
    });
  };

  async _fetchShipmentAccounts() {
    const {order, fetchCompanyShippingCarrierAssignments} = this.props;

    await fetchCompanyShippingCarrierAssignments({
      filter: `company_id,eq,${order.companyId}`,
    });
  }

  _fetchData = () => {
    const {
      fetchCompany,
      fetchOrder,
      fetchOrderItems,
      fetchOrderStatus,
      fetchShipments,
      fetchPaymentTerms,
      match: {params: {company_id: companyId, id: orderId}},
    } = this.props;

    fetchCompany(companyId);
    fetchOrder(orderId);
    fetchOrderItems(orderId);
    fetchShipments(orderId);
    fetchOrderStatus();
    fetchPaymentTerms();
  };

  handleUpdateOrder = async () => {
    const {updateOrder, order, openNotification, history} = this.props;
    const {orderData, shipmentData} = this.state;

    try {
      await updateOrder(order.id, {
        ...orderData,
        shipment: shipmentData,
      });
      history.push(`/companies/${order.companyId}/dsod/${order.id}`);
      openNotification("DSOD Order updated successfully");
    } catch (error) {
      openNotification("Error Updating DSOD Order");
    }
  };

  handleUpdateOrderStatus = async statusId => {
    const {updateOrder, order, openNotification} = this.props;
    const data = {orderStatusId: statusId};

    await updateOrder(order.id, data);
    openNotification("Order changes successfully saved");
  };

  handleOrderChange = (event, value, formName) => {
    this.handleChange(event, value, formName, "orderData");
  };

  handleShipmentChange = (event, value, formName) => {
    if (typeof value === "object") {
      return this.setState({
        shipmentData: {
          ...this.state.shipmentData,
          ...value,
        },
      });
    }
    this.handleChange(event, value, formName, "shipmentData");
  };

  handleChange = (event, value, formName, stateName) => {
    const name = event ? event.target.name : formName;

    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [name]: value,
      },
    });
  };

  render() {
    return (
      <DSODEditPage
        onToggleReorder={this.handleToggleReorder}
        onToggleReorderItem={this.handleToggleReorderItem}
        onUpdateShipmentTrackingNumber={this.handleUpdateShipmentTrackingNumber}
        onOrderChange={this.handleOrderChange}
        onShipmentChange={this.handleShipmentChange}
        onUpdateOrder={this.handleUpdateOrder}
        fetchShippingMethodsByCarrier={this.fetchShippingMethodsByCarrier}
        onUpdateShipmentTrackingItemNumber={
          this.handleUpdateShipmentTrackingItemNumber
        }
        {...this.props}
        {...this.state}
      />
    );
  }
}

OrderEdit.propTypes = {
  openNotification: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchShipments: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrderItems: PropTypes.func.isRequired,
  fetchOrderStatus: PropTypes.func.isRequired,
  fetchCompanyShippingCarrierAssignments: PropTypes.func,
  fetchShippingCarriers: PropTypes.func,
  fetchShippingMethods: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  shippingMethods: PropTypes.array,
  shipments: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    companies: {company},
    orders: {order, isLoading: isOrderLoading, orderItems, orderStatus},
    auth: {isInternal},
    shipment: {
      shipments,
      companyShippingCarrierAssignments,
      shippingMethods,
      isLoading: isShipmentLoading,
    },
    paymentTerms: {paymentTerms},
  } = state;

  return {
    company,
    order,
    isInternal,
    isLoading: isOrderLoading && isShipmentLoading,
    orderItems,
    orderStatus,
    shipments,
    companyShippingCarrierAssignments,
    shippingMethods,
    paymentTerms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    fetchShippingCarriers: bindActionCreators(fetchShippingCarriers, dispatch),
    fetchShippingMethods: bindActionCreators(fetchShippingMethods, dispatch),
    fetchCompanyShippingCarrierAssignments: bindActionCreators(
      fetchCompanyShippingCarrierAssignments,
      dispatch
    ),
    fetchPaymentTerms: bindActionCreators(fetchPaymentTerms, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderEdit)
);
