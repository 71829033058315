import React, {Component} from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import classNames from "classnames";

import Pagination from "../../shared/Pagination";
import {hasQueryParamsChanged} from "../../../utils/indexHelper";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../../shared/PageComponents/PageIndexHeader";
import BrandIndexTable from "./Components/BrandIndexTable";
import BreadCrumbs from "../../shared/BreadCrumbs";

export default class BrandIndexPage extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    searchBrands: PropTypes.func.isRequired,
    fetchBrands: PropTypes.func.isRequired,
    brandsCount: PropTypes.number,
    company: PropTypes.object,
    isInternal: PropTypes.bool,
    isLoading: PropTypes.bool,
    setSort: PropTypes.func,
    setPage: PropTypes.func,
    setSearchTerm: PropTypes.func,
    sortDirection: PropTypes.string,
    sortBy: PropTypes.string,
    searchTerm: PropTypes.string,
    page: PropTypes.number,
    match: PropTypes.object,
  };

  toggleOrderDir() {
    this.props.setSort("name");
  }

  constructor(props) {
    super(props);
    this._searchBrands();
  }

  componentDidUpdate(prevProps) {
    if (hasQueryParamsChanged(this.props, prevProps)) {
      this._searchBrands();
    }
  }

  _searchBrands = () => {
    const {
      fetchBrands,
      searchBrands,
      searchTerm,
      sortBy,
      sortDirection,
      page,
      match: {params: {id: companyId}},
    } = this.props;

    const options = {
      orderBy: sortBy,
      orderDirection: sortDirection,
      page: page,
      perPage: 7,
      include: "arts.revisions",
    };

    if (searchTerm === "") {
      fetchBrands({
        id: companyId,
        ...options,
      });
      return;
    }
    searchBrands(companyId, searchTerm, options);
  };

  searchBrands = (e, value) => {
    this.props.setSearchTerm(value);
  };

  _onPageChangeHandler(options) {
    const {setPage} = this.props;
    setPage(options.page);
  }

  _renderPaginiation() {
    const {brandsCount, page} = this.props;

    return (
      <div className="userindex_pagination">
        <Pagination
          getData={options => this._onPageChangeHandler(options)}
          count={brandsCount}
          currentPage={page - 1}
          perPage={7}
        />
      </div>
    );
  }

  _renderPage = () => {
    const {brands, brandsCount, company, isLoading, searchTerm} = this.props;

    return (
      <div>
        <PageIndexHeader
          containerClass="userindex_top"
          title="Brands"
          searchFunc={debounce(this.searchBrands, 300, {
            leading: false,
            trailing: true,
          })}
          disableSearch={brands && brands.length < 1}
          createPage={`/companies/${company && company.id}/brands/create`}
          noButton={!this.props.isInternal}
          buttonIcon="add2-white"
          buttonText="ADD BRAND"
          searchTerm={searchTerm}
        />

        <h2
          className={classNames("userindex_noUsers", {
            hidden: isLoading || brandsCount > 0,
          })}
        >
          No brands yet.
        </h2>
        <div>
          <BrandIndexTable
            brands={brands}
            brandsCount={brandsCount}
            company={company}
            fetchBrands={this.props.fetchBrands}
            orderDir={this.props.sortDirection}
            isLoading={isLoading}
            toggleOrderDir={() => this.toggleOrderDir()}
          />
          {brandsCount && brandsCount > 7 ? this._renderPaginiation() : null}
        </div>
      </div>
    );
  };

  render() {
    const {company, isLoading, isInternal} = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company ? company.name : null}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          pageName="Brands"
        />
        {this._renderPage()}
      </PageLayout>
    );
  }
}
