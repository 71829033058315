import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import NumberFormat from "react-number-format";
import FontIcon from "material-ui/FontIcon";
import TextField from "material-ui/TextField";

import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";
import QuoteItemPricingTable from "../QuoteItemPricingTable";
import PricingTableButton from "../PricingTableButton";
import HidePricingTableButton from "../HidePricingTableButton";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import {QUANTITY_ITEM_INFO} from "../../../../../constants/quote";
import ImagesModal from "../ImagesModal";

class QuoteItem extends Component {
  state = {
    pricingTableOpen: false,
    openArchiveModal: false,
    openMockupsModal: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.priceTableOpenId &&
      this.props.priceTableOpenId &&
      this.props.priceTableOpenId !== prevProps.priceTableOpenId &&
      this.props.priceTableOpenId !== this.props.id
    ) {
      this.setState({pricingTableOpen: false});
    }
    if (
      !prevProps.priceTableOpenId &&
      this.props.priceTableOpenId &&
      this.props.priceTableOpenId === this.props.id
    ) {
      this.handleOpenPricingTable();
    }

    if (prevProps.product !== this.props.product){
      this.updateItemQuantityValue(this.props.quantity);
    }
  }

  handleOpenPricingTable = () => {
    const {
      id,
      quoteArts,
      companyArts,
      fetchItemPriceTable,
      onPriceTableOpen,
    } = this.props;
    fetchItemPriceTable(this.props, quoteArts, companyArts);
    onPriceTableOpen(id);
    this.setState({pricingTableOpen: true});
  };

  handleClosePricingTable = () => {
    const {onPriceTableOpen} = this.props;
    onPriceTableOpen(null);
    this.setState({pricingTableOpen: false});
  };

  handleShowMockupsModal = () => this.setState({openMockupsModal: true});

  handleHideMockupsModal = () => this.setState({openMockupsModal: false});

  handleChange = event => {
    this.updateItemQuantityValue(event.target.value);
  };

  updateItemQuantityValue = _debounce(
    qty => {
      const {onUpdateItemQuantityValue} = this.props;
      onUpdateItemQuantityValue(qty);
    },
    750,
    {
      leading: false,
      trailing: true,
    }
  );

  getArtNameFrom = uuid => {
    const {quoteArts, companyArts} = this.props;
    let art;
    art = companyArts.find(art => art.id === uuid);
    if (art) {
      return art.name;
    }
    art = quoteArts.find(art => art.uuid === uuid);
    return art && art.filename;
  };

  canShowPricingTable = () => {
    const {artOption, arts, companyArts} = this.props;
    if (artOption === "no-art") return true;
    return arts.every(art => {
      return companyArts && companyArts.find(({id}) => id === art.art);
    });
  };

  render() {
    const {
      itemPrices,
      quantity,
      unitCost,
      product,
      fabric,
      color,
      arts,
      artOption,
      onEditItem,
      onRemoveItem,
      pageStyle,
      isLoadingPrices,
      hideRemove,
      errorMessages,
    } = this.props;
    const {pricingTableOpen, openMockupsModal} = this.state;

    return (
      <div className="flex padding-32 margin-bottom-16" style={pageStyle.item}>
        <Fragment>
          <ImagesModal
            open={openMockupsModal}
            images={
              [
                {
                  imageUrl: _get(
                    product,
                    "productImages[0].imageUrlThumbnail",
                    _get(product, "imageUrl")
                  ),
                },
              ]
            }
            onClose={this.handleHideMockupsModal}
          />
        </Fragment>
        <div className="initial">
          <CNGImage
            src={_get(
              product,
              "productImages[0].imageUrlThumbnail",
              product.imageUrl
            )}
            placeHolderStyle={pageStyle.image}
            style={pageStyle.image}
          />
          <div className="pointer flex" style={{justifyContent: "center", padding: 10}} onClick={this.handleShowMockupsModal}>
            <FontIcon
              className="material-icons"
              style={{
                color: "#497E95",
                fontSize: 20,
              }}
            >
                  search
            </FontIcon>
            <div style={{color: "#497E95"}}>
              Expand
            </div>
          </div>
        </div>
        <div className="flex flex-column flex-one padding-left-24">
          <Typography variant="subtitle">Item #{product.itemNumber}</Typography>
          <div className="flex justify-content-between">
            <div className="flex">
              <Typography variant="h3">{product.productName}</Typography>
              <span
                className="pointer"
                style={{
                  alignSelf: "center",
                  padding: 4,
                }}
                onClick={onEditItem}
              >
                <FontIcon
                  className="material-icons"
                  style={{
                    color: "#497E95",
                    fontSize: 20,
                  }}
                >
                  edit
                </FontIcon>
              </span>
            </div>
            {this.canShowPricingTable() ? (
              pricingTableOpen ? null : (
                <PricingTableButton
                  onOpenPricingTable={this.handleOpenPricingTable}
                />
              )
            ) : null}
            {hideRemove ? null : (
              <span
                className="flex flex-center"
                style={pageStyle.removeButton}
                onClick={onRemoveItem}
              >
                Remove
                <FontIcon
                  className="material-icons"
                  style={pageStyle.closeIcon}
                >
                  close
                </FontIcon>
              </span>
            )}
          </div>
          <Typography variant="h5">Product Details</Typography>
          <div className="flex margin-bottom-16">
            <Typography variant="bodyMedium" className="margin-right-4">
              Fabric:
            </Typography>
            <Typography variant="body">{fabric.name}</Typography>
            <Typography
              variant="bodyMedium"
              className="margin-left-8 margin-right-4"
            >
              Color:
            </Typography>
            <Typography variant="body">{color.name}</Typography>
          </div>
          <div className="flex flex-column margin-bottom-16">
            {arts.map((art, index) => (
              <div key={index} className="flex">
                <Typography variant="bodyMedium" className="margin-right-4">
                  Art File {index + 1}:
                </Typography>
                <Typography
                  variant="body"
                  style={{
                    whiteSpace: "nowrap",
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {this.getArtNameFrom(art.art)}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  className="margin-left-8 margin-right-4"
                >
                  Location:
                </Typography>
                <Typography variant="body">
                  {art.adornmentLocation && art.adornmentLocation.location}
                </Typography>
              </div>
            ))}
          </div>
          {this.canShowPricingTable() && (
            <Fragment>
              {pricingTableOpen ? (
                <Fragment>
                  <div className="flex justify-content-end">
                    <HidePricingTableButton
                      onHidePricingTable={this.handleClosePricingTable}
                    />
                  </div>
                  <QuoteItemPricingTable
                    prices={itemPrices}
                    isLoading={isLoadingPrices}
                    simple={artOption === "no-art"}
                  />
                </Fragment>
              ) : null}
              <div className="flex justify-content-between align-items-end">
                <div className="flex">
                  <NumberFormat
                    customInput={TextField}
                    allowNegative={false}
                    decimalSeparator={null}
                    errorText={errorMessages}
                    style={pageStyle.smallInput}
                    name="itemQty"
                    floatingLabelText="Qty Calc"
                    value={quantity}
                    onChange={this.handleChange}
                    autoComplete="off"
                    margin="normal"
                  />
                  <div
                    className="flex align-items-center"
                    style={{marginLeft: 8, marginTop: 8}}
                    title={QUANTITY_ITEM_INFO}
                  >
                    <FontIcon className="material-icons" color={"#0c5371"}>
                      info
                    </FontIcon>
                  </div>
                </div>
                <div className="flex flex-column align-items-end">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Total
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                  >
                    {getCurrencyFormattedNumber(unitCost * quantity || 0)}
                  </Typography>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

QuoteItem.propTypes = {
  id: PropTypes.string,
  itemPrices: PropTypes.array,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  product: PropTypes.object,
  fabric: PropTypes.object,
  color: PropTypes.object,
  arts: PropTypes.array,
  quoteArts: PropTypes.array,
  companyArts: PropTypes.array,
  artOption: PropTypes.string,
  pageStyle: PropTypes.object,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  fetchItemPriceTable: PropTypes.func,
  onUpdateItemQuantityValue: PropTypes.func,
  isLoadingPrices: PropTypes.bool,
  priceTableOpenId: PropTypes.string,
  onPriceTableOpen: PropTypes.func,
  hideRemove: PropTypes.bool,
  errorMessages: PropTypes.array,
};

export default QuoteItem;
