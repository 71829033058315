import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function settingReducer(
  state = initialState.settings,
  {type, payload = []}
) {
  switch (type) {
  case actions.FETCH_SETTINGS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_SETTINGS_SUCCESS:
    return {
      ...state,
      ...payload.reduce(
        (result, {name, value}) => ({
          ...result,
          [name]: value,
        }),
        {}
      ),
      isLoading: false,
    };
  case actions.FETCH_SETTINGS_FAILURE:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  default:
    return state;
  }
}
