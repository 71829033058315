import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import lodash from "lodash";

import {CNGTable, CNGTableRowColumn} from "../../../../shared/CNGTable";
import {TableRow} from "material-ui/Table";
import Checkbox from "material-ui/Checkbox";
import OrderItemArtDetails from "../../../../shared/OrderItemArtDetails";
import ContentLoader from "../../../../shared/ContentLoader";
import CNGToolTip from "../../../../shared/CNGToolTip";

const styles = {
  rowStyles: {
    height: 66,
    borderRadius: 4,
    backgroundColor: "#fafbfb",
  },
};

export default class InventoryTable extends Component {
  static propTypes = {
    selectedItems: PropTypes.array,
    inventoryItems: PropTypes.array.isRequired,
    inventoryItemsCount: PropTypes.number.isRequired,
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onSelectedItemsChange: PropTypes.func.isRequired,
    searching: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  state = {
    selectedRows: [],
    activeOrderItem: null,
  };

  inventoryItemsChanged(compareInventoryItems) {
    const currentInventoryItemIds = this.props.inventoryItems.map(({id}) => id);
    const compareInventoryItemsIds = compareInventoryItems.map(({id}) => id);
    const difference = lodash.difference(
      currentInventoryItemIds,
      compareInventoryItemsIds
    );
    return difference.length > 0;
  }

  _getWarningIcon = () => {
    return (
      <img
        style={{
          height: 18,
          width: 18,
          marginLeft: 6,
        }}
        src="/assets/imgs/icons/warning.svg"
      />
    );
  };

  _renderRow = inventoryItem => {
    const fabricColor = lodash.get(inventoryItem, "orderItem.fabricColor");
    const balance = inventoryItem.quantity - inventoryItem.usedQuantity;
    const {companyId, selectedItems} = this.props;
    let icon;

    if (inventoryItem.balance <= 2) {
      icon = this._getWarningIcon();
    }

    const assignment = lodash.get(
      inventoryItem,
      "orderItem.orderItemProduct.orderItemProductAdornmentLocationArtAssignments[0]"
    );
    const fabricName = fabricColor && fabricColor.fabric.name;
    const colorName = fabricColor && fabricColor.color.name;
    const brandName = lodash.get(
      inventoryItem,
      "orderItem.orderItemProduct.art.artSource.brand.name"
    );

    return (
      <TableRow
        key={inventoryItem.id}
        displayBorder={false}
        style={styles.rowStyles}
      >
        <CNGTableRowColumn
          customStyles={{
            width: 50,
            cursor:"default",
          }}
        >

          <Checkbox
            style={{
              width: "unset",
            }}
            checked={!!selectedItems.find(item => item.id === inventoryItem.id)}
            onCheck={() => {
              this.handleToggleInventoryItem(inventoryItem);
            }}
          />
          
        </CNGTableRowColumn>
        <CNGTableRowColumn highlight={true}>
          <Link
            to={`/companies/${companyId}/products/${lodash.get(
              inventoryItem,
              "orderItem.orderItemProduct.id"
            )}`}
            className="reset-link-styles"
            title={lodash.get(inventoryItem, "orderItem.product.productName")}
          >
            <div className="overflow-ellipsis">
              <CNGToolTip
                parentRef={`assignment-${assignment && assignment.id}`}
                style={{width: 250}}
                element={assignment}
              >
                {lodash.get(inventoryItem, "orderItem.product.productName")}
              </CNGToolTip>
            </div>
          </Link>
          {icon}
          <div className="table-subtext">
            Item #{lodash.get(inventoryItem, "orderItem.product.itemNumber")}
          </div>
          <div className="table-subtext">
            CNG #{" "}
            {lodash.get(inventoryItem, "orderItem.order.cngReferenceNumber")}
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn title={fabricName}>{fabricName}</CNGTableRowColumn>
        <CNGTableRowColumn title={colorName}>{colorName}</CNGTableRowColumn>
        <CNGTableRowColumn title={brandName}>{brandName}</CNGTableRowColumn>
        <CNGTableRowColumn highlight={true}>
          <OrderItemArtDetails orderItem={inventoryItem.orderItem}>
            <Link
              to={`/brands/${lodash.get(
                inventoryItem,
                "orderItem.orderItemProduct.art.artSource.brand.id"
              )}/arts/${lodash.get(
                inventoryItem,
                "orderItem.orderItemProduct.art.artSource.id"
              )}/revisions/${lodash.get(
                inventoryItem,
                "orderItem.orderItemProduct.art.id"
              )}`}
            >
              {lodash.get(inventoryItem, "orderItem.orderItemProduct.art.name")}
            </Link>
          </OrderItemArtDetails>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{balance}</CNGTableRowColumn>
      </TableRow>
    );
  };

  handleToggleInventoryItem = inventoryItem => {
    const {selectedItems, onSelectedItemsChange} = this.props;
    const hasInventoryItem = selectedItems.find(
      item => item.id === inventoryItem.id
    );

    if (hasInventoryItem) {
      return onSelectedItemsChange(
        selectedItems.filter(item => item.id !== inventoryItem.id)
      );
    }

    onSelectedItemsChange([...selectedItems, inventoryItem]);
  };

  renderEmptyState() {
    const {isLoading, searching} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <div
          className="flex flex-column align-items-center"
          style={{
            minHeight: "82px",
          }}
        >
          <h2 className="userindex_noUsers">
            No inventory {searching ? "found" : "yet"}.
          </h2>
        </div>
      </ContentLoader>
    );
  }

  headers = () => {
    return [
      {
        customStyles: {
          width: 50,
        },
        columnContent: "",
      },
      "ITEM NAME & ID",
      "FABRIC",
      "COLOR",
      "BRAND",
      "ART FILE",
      "BALANCE",
    ];
  };

  render() {
    const {inventoryItems, isLoading} = this.props;
    if (!inventoryItems || inventoryItems.length < 1) {
      return this.renderEmptyState();
    }

    return (
      <div>
        <CNGTable
          displayRowCheckbox={false}
          isLoading={isLoading}
          tableHeader={this.headers()}
          className="CNG-table CNG-table--removeHeaderHeight inventory-table"
        >
          {inventoryItems.map(this._renderRow)}
        </CNGTable>
      </div>
    );
  }
}
