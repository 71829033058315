import React, {Component} from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import {getFormattedDate} from "../../../utils/dates";
import Pagination from "../../shared/Pagination";
import {status as statusData} from "../../../constants/quote";

class QuotesTable extends Component {
  state = {
    currentPage: 1,
  };

  _renderPagination = () => {
    const {quotesCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={this.handleSelectPage}
          count={quotesCount}
          currentPage={this.state.currentPage - 1}
        />
      </div>
    );
  };

  handleSelectPage = options => {
    const {searchQuotes, searchTerm} = this.props;
    this.setState({currentPage: options.page});
    searchQuotes(searchTerm, {
      ...options,
      page: options.page,
    });
  };

  _getQuoteStatus = quote => {
    const {isInternal} = this.props;
    const {status, noAdornment, noNewArt} = quote;
    const statusValue = statusData[status];
    if (!isInternal && statusValue.eu) {
      return noAdornment || noNewArt
        ? statusValue.eu.nameNoAdornment
        : statusValue.eu.name;
    }
    if (noAdornment || noNewArt) return statusValue.nameNoAdornment;
    return statusValue.name;
  };

  _renderRow = quote => {
    const {unreadMessagesCounts} = this.props;
    const {id, uuid, companyName, items, createdAt, status} = quote;
    const unreadMessagesCount = unreadMessagesCounts
      ? unreadMessagesCounts[uuid]
      : 0;
    const productNames =
      items && items.map(({product}) => product.productName).join(", ");
    const statusValue = statusData[status];
    return (
      <CNGTableRow
        key={id}
        styles={{
          marginTop: 8,
        }}
      >
        <CNGTableRowColumn highlight>
          <Link
            to={`/quotes/info/${id}`}
            className="no-link-styles"
            title={companyName}
          >
            {companyName}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn title={productNames}>{productNames}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {createdAt && getFormattedDate(moment(createdAt))}
        </CNGTableRowColumn>
        <CNGTableRowColumn title={status} customStyles={{position: "relative"}}>
          <span className="flex flex-center" style={{color: statusValue.color}}>
            <FontIcon
              className="material-icons"
              style={{color: statusValue.color, padding: 4}}
            >
              {statusValue.icon}
            </FontIcon>
            {this._getQuoteStatus(quote)}
          </span>
          {unreadMessagesCount > 0 && (
            <span className="badge" style={{top: 0, right: 0}}>
              {unreadMessagesCount}
            </span>
          )}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  render() {
    const header = ["Company", "Product", "Date", "Status"];
    const {quotes, quotesCount, isLoading} = this.props;

    return (
      <div>
        <div
          style={{
            minHeight: quotesCount > 10 ? "560px" : null,
          }}
          className={quotesCount === 0 ? "hidden" : ""}
        >
          <CNGTable
            tableHeader={header}
            displayRowCheckbox={false}
            isLoading={isLoading}
          >
            {quotes.map(this._renderRow)}
          </CNGTable>
        </div>
        {quotesCount > 7 && !isLoading ? this._renderPagination() : null}

        {quotesCount === 0 ? (
          <h2 className="userindex_noUsers">
            There are no quotes in process at this time.
          </h2>
        ) : null}
      </div>
    );
  }
}

QuotesTable.propTypes = {
  unreadMessagesCounts: PropTypes.object,
  quotes: PropTypes.array,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  quotesCount: PropTypes.number,
  searchTerm: PropTypes.string,
  searchQuotes: PropTypes.func,
  minHeight: PropTypes.string,
  selectedCompanyId: PropTypes.any,
};

export default QuotesTable;
