import * as types from "../constants/actionTypes";

export function openNotification(message, duration) {
  return dispatch => {
    dispatch({
      type: types.OPEN_NOTIFICATION,
      notification: {
        message: message,
        show: true,
        duration,
      },
    });
  };
}

export function closeNotification() {
  return dispatch => {
    dispatch({type: types.CLOSE_NOTIFICATION});
  };
}
