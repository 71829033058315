import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import _pickBy from "lodash/pickBy";
import _pick from "lodash/pick";
import {push as navigateTo} from "react-router-redux";
import ReorderCreatePage from "../../pages/Reorders/ReorderCreatePage";
import { getDate } from "../../../utils/dates";
import { openNotification } from "../../../actions/notificationActions";
import {
  fetchShipments,
  updateShipmentItem,
  fetchCompanyShippingCarrierAssignments,
  fetchShippingCarriers,
  fetchShippingMethods,
  updateShipment,
  deleteShipmentItem,
} from "../../../actions/shipmentActions";
import { fetchCountries } from "../../../actions/geoActions";
import {
  fetchCompanyContacts,
  fetchCompany,
  fetchWalletMembers,
  createWalletMember,
} from "../../../actions/companyActions";
import {
  fetchOrder,
  fetchOrderItems,
  updateOrder,
  fetchOrderStatus,
  fetchOpenOrders,
  archiveOrder,
} from "../../../actions/orderActions";
import {
  fetchUsersByCompany,
  updateUserWithCompanies,
} from "../../../actions/userActions";
import { fetchPaymentTerms } from "../../../actions/paymentTermsActions";

class ReorderCreate extends Component {
  state = {
    submitted: null,
    isLoading: true,
    isReordering: false,
    sameRecipient: false,
    reorderItems: null,
    orderData: {},
    shipmentData: {},
  };

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      this._fetchData();
    }
  }

  _fetchShippingMethods = async companyShippingCarrierAssignments => {
    const { fetchShippingCarriers } = this.props;
    const carrierIds = companyShippingCarrierAssignments.map(
      ({ shippingCarrierId }) => shippingCarrierId
    );
    await fetchShippingCarriers({
      filter: `id,in,(${carrierIds.join(",")})`,
    });
  };

  async _fetchShipmentAccounts() {
    const {
      match: { params: { company_id: companyId } },
      fetchCompanyShippingCarrierAssignments,
    } = this.props;

    return await fetchCompanyShippingCarrierAssignments({
      filter: `company_id,eq,${companyId}`,
    });
  }

  _fetchData = async () => {
    const {
      fetchCompany,
      fetchCompanyContacts,
      fetchOrderStatus,
      fetchCountries,
      fetchOpenOrders,
      fetchShippingMethods,
      fetchWalletMembers,
      fetchPaymentTerms,
      match: { params: { company_id: companyId } },
    } = this.props;

    this.setState({ isLoading: true });
    fetchCountries();
    await fetchShippingMethods();
    const company = await fetchCompany(companyId);
    fetchWalletMembers(companyId, {perPage: null});
    fetchCompanyContacts(companyId);
    const openOrders = await fetchOpenOrders(companyId);

    fetchOrderStatus();
    await this._setInitialState(openOrders, company);
    this.setState({ isLoading: false });
    fetchPaymentTerms();
  };

  _setInitialState = async (openOrders, company) => {
    if (!openOrders) return;
    const { fetchShipments } = this.props;

    const order = await this.updateOrderState(openOrders);
    if (!order) {
      return this.setState({ isLoading: false });
    }
    const shipments = await fetchShipments(order.id);

    const sameRecipient =
      shipments && shipments.data.length > 0 && !!shipments.data[0].recipientId;

    let initialShipmentData = {};
    const billingCompanyContact = this.getDefaultBillingContact(company);
    const companyShippingCarrierAssignments = await this._fetchShipmentAccounts();
    let shipment = {};

    if (shipments) {
      shipment = shipments.data[0];
      if (billingCompanyContact) {
        let billingCompanyContactId =
          billingCompanyContact && billingCompanyContact.id;
        if (shipment.billingCompanyContactId) {
          billingCompanyContactId = shipment.billingCompanyContactId;
        }
        initialShipmentData["billing_company_contact_id"] =
          billingCompanyContactId + "";

        if (shipment.shippingMethodId) {
          initialShipmentData["shipping_method_id"] =
            shipment.shippingMethodId + "";
        }
      }
    }

    if (
      companyShippingCarrierAssignments &&
      companyShippingCarrierAssignments.data.length > 0 &&
      shipment
    ) {
      const shipmentAccount = companyShippingCarrierAssignments.data.find(
        assignment =>
          Number(assignment.id) === Number(shipment.shipmentAccountId)
      );

      if (shipmentAccount) {
        initialShipmentData = {
          ...initialShipmentData,
          shipment_account_id: shipmentAccount.id,
          shipping_carrier_id:
            shipmentAccount.shippingCarrier &&
            shipmentAccount.shippingCarrier.id,
          shipping_carrier_name:
            shipmentAccount.shippingCarrier &&
            shipmentAccount.shippingCarrier.name,
          shipment_account_number: shipmentAccount.accountNumber,
          shipping_method_id: shipment.shippingMethodId,
        };
      }
    }

    this.setState({
      sameRecipient,
      shipmentData: initialShipmentData,
      initialShipmentData,
      isLoading: false,
    });
  };

  handleSubmitReorder = async () => {
    const {
      updateOrder,
      openNotification,
      history,
      location,
      isInternal,
    } = this.props;
    const { order, orderData, shipmentData } = this.state;
    const attributes = [
      "jobName",
      "inHandDate",
      "purchaseOrderNumber",
      "purchaseOrderDate",
      "estimatedShipOn",
      "sendNotification",
      "sendCopyInvoice",
    ];
    const requiresRecipient = _get(order, "quote.requiresRecipient");
    this.setState({ submitted: true });
    const isCreated = await updateOrder(order.id, {
      ..._pickBy(orderData, (value, key) => attributes.includes(key)),
      isOpen: null,
      shipment: shipmentData,
      fromQuote: _get(location, "state.fromQuote", false),
    });
    if (isCreated) {
      if (isInternal && !requiresRecipient) {
        window.open(
          `/companies/${order.companyId}/orders/${order.id
          }/print?orderInformation=true`,
          "_blank"
        );
      }

      const path =
        isInternal && requiresRecipient ? "reorders" : `orders/${order.id}`;
      history.push({
        pathname: `/companies/${order.companyId}/${path}`,
        state: {
          reorderCreated: true,
        },
      });

      return openNotification("Order updated successfully");
    }
    this.setState({ submitted: false });
  };

  updateOrderState = async openOrders => {
    if (!openOrders) return;
    const { fetchShipments } = this.props;
    const { match: { params: { company_id: companyId } } } = this.props;
    const order = openOrders.data.find(
      order => !order.isDsod && Number(order.companyId) === Number(companyId)
    );

    if (!order) return;
    const shipments = await fetchShipments(order.id);

    const sameRecipient =
      shipments && shipments.data.length > 0 && !!shipments.data[0].recipientId;

    this.setState({
      sameRecipient,
      order,
      orderData: {
        ...this.state.orderData,
        ...this.updateOrderData(order),
      },
    });
    return order;
  };

  updateOrderData(order) {
    const { inHandDate, estimatedShipOn, purchaseOrderDate } = order;
    return {
      ...this.state.orderData,
      ...order,
      inHandDate: inHandDate && getDate(inHandDate),
      purchaseOrderDate: purchaseOrderDate && getDate(purchaseOrderDate),
      estimatedShipOn: estimatedShipOn && getDate(estimatedShipOn),
    };
  }

  handleSaveDraft = async () => {
    const { orderData, shipmentData, order, initialShipmentData } = this.state;
    const { updateOrder, openNotification, fetchShipments } = this.props;
    const dataAttributes = this.pickOrderAttributes(orderData);
    if (!initialShipmentData) return;
    const { inHandDate, estimatedShipOn, purchaseOrderDate } = order;
    const prevOrder = {
      ...order,
      inHandDate: inHandDate && getDate(inHandDate),
      purchaseOrderDate: purchaseOrderDate && getDate(purchaseOrderDate),
      estimatedShipOn: estimatedShipOn && getDate(estimatedShipOn),
    };

    const shipmentChanged =
      shipmentData.shipment_account_id !==
      initialShipmentData.shipment_account_id ||
      shipmentData.shipping_method_id !==
      initialShipmentData.shipping_method_id;

    if (
      !_isEqual(dataAttributes, this.pickOrderAttributes(prevOrder)) ||
      shipmentChanged
    ) {
      await updateOrder(order.id, {
        ...dataAttributes,
        shipment: shipmentData,
      }, {}, () => {
        openNotification("Order changes successfully saved");
      }, ({ error }) => {
        this.errorConfirm(orderData, error);
      });
      await fetchShipments(order.id);
      this.setState({
        initialShipmentData: shipmentData,
        order: {
          ...order,
          ...dataAttributes,
        },
      });
    }
  };

  errorConfirm = async (orderData, error) => {
    const {fetchOrder} = this.props;
    const { body: { type } } = error.response;
    const {id} = orderData;

    const {isConfirmed} = await fetchOrder(id);

    if (type === "ConfirmOrderError") {
      this.setState({
        orderData: {
          ...orderData,
          isConfirmed: isConfirmed,
        },
      });
    }
  }

  pickOrderAttributes(order) {
    return _pick(order, [
      "jobName",
      "inHandDate",
      "purchaseOrderNumber",
      "purchaseOrderDate",
      "estimatedShipOn",
      "billingCompanyContactId",
      "clientNotes",
      "internalNotes",
      "createdUserId",
      "sendCopyInvoice",
      "paymentTermId",
      "sewOutRequested",
      "preProductionRequested",
      "isConfirmed",
    ]);
  }

  disableExternal = (isConfirmed, isInternal) => {
    if (isConfirmed && !isInternal) {
      return true;
    }

    if (!isConfirmed && !isInternal) {
      return false;
    }
  }

  disableInternal = (isConfirmed, isInternal) => {
    if (!isConfirmed && isInternal) {
      return true;
    }

    if (isConfirmed && isInternal) {
      return false;
    }
  }

  handleDisableConfirmed = () => {
    const { isInternal } = this.props;
    const { orderData } = this.state;
    const { isConfirmed } = orderData;

    if (isInternal) {
      return this.disableInternal(isConfirmed, isInternal);
    } else {
      return this.disableExternal(isConfirmed, isInternal);
    }
  };

  handleUpdateOrderStatus = async statusId => {
    const { updateOrder, openNotification } = this.props;
    const { order } = this.state;
    await updateOrder(order.id, { orderStatusId: statusId }, {}, () => {
      openNotification("Order changes successfully saved");
    });
  };

  handleToggleReorder = () => {
    const { isReordering } = this.state;
    this.setState({
      isReordering: !isReordering,
    });
  };

  handleToggleReorderItem = orderItem => {
    const { reorderItems } = this.state;
    const isSelectedOrderItem = item => item.id === orderItem.id;
    const hasReorderItem = reorderItems.find(isSelectedOrderItem);
    if (hasReorderItem) {
      return this.setState({
        reorderItems: reorderItems.filter(isSelectedOrderItem),
      });
    }

    this.setState({
      reorderItems: [...reorderItems, orderItem],
    });
  };

  handleUpdateShipmentRecipient = async recipientId => {
    const {
      openNotification,
      fetchShipments,
      fetchOpenOrders,
      fetchCompanyContacts,
      updateShipment,
      shipments,
      company,
    } = this.props;

    const { order } = this.state;

    const shipment = shipments[0];

    await fetchCompanyContacts(company.id);
    await updateShipment(shipment.id, {
      recipientId,
      isMultiple: recipientId === null,
    });
    const openOrders = await fetchOpenOrders(company.id);
    await fetchShipments(order.id);
    this.updateOrderState(openOrders);

    openNotification("Order has been updated");
  };

  handleOrderChange = (event, value, formName, callback) => {
    this.handleChange(event, value, formName, "orderData", callback);
  };

  handleShipmentChange = (event, value, formName, callback) => {
    if (typeof value === "object") {
      return this.setState(
        {
          shipmentData: {
            ...this.state.shipmentData,
            ...value,
          },
        },
        () => {
          if (callback) callback();
        }
      );
    }
    this.handleChange(event, value, formName, "shipmentData", callback);
  };

  handleChange = (event, value, formName, stateName, callback) => {
    const name = event ? event.target.name : formName;

    this.setState(
      {
        [stateName]: {
          ...this.state[stateName],
          [name]: value,
        },
      },
      () => {
        if (callback) callback();
      }
    );
  };

  handleToggleSameRecipient = () => {
    this.setState({
      sameRecipient: !this.state.sameRecipient,
    });
  };

  getDefaultBillingContact = company => {
    if (!company) return;
    const userWithWalletEnable = this.getUsersWithWalletEnable(company);
    const { company: currentCompany } = this.props;
    if (!currentCompany) {
      return null;
    }

    const { userAssociations } = currentCompany;

    return userWithWalletEnable.find(user => {
      const userAssociation = userAssociations.find(
        userAssociation => Number(userAssociation.userId) === Number(user.id)
      );

      return userAssociation.isWalletDefaultMember;
    });
  };

  getUsersWithWalletEnable = company => {
    if (!company) return;
    const { users, userAssociations } = company;
    if (!users) return [];
    return users.filter(user => {
      const userAssociation = userAssociations.find(
        userAssociation => Number(userAssociation.userId) === Number(user.id)
      );

      return userAssociation && userAssociation.isWalletMember;
    });
  };

  render() {
    const {
      userCompanies,
      match: { params: { company_id: companyId } },
      currentUserId,
      walletMembers,
    } = this.props;
    const selectedCompany = Array.isArray(userCompanies)
      ? userCompanies.find(company => Number(company.id) === Number(companyId))
      : null;
    return (
      <ReorderCreatePage
        isDisabled={this.handleDisableConfirmed}
        currentUserId={currentUserId}
        walletMembers={walletMembers}
        selectedCompany={selectedCompany}
        onUpdateOrderName={this.handleUpdateOrderName}
        onUpdateOrderStatus={this.handleUpdateOrderStatus}
        onToggleReorder={this.handleToggleReorder}
        onToggleReorderItem={this.handleToggleReorderItem}
        onUpdateShipmentTrackingNumber={this.handleUpdateShipmentTrackingNumber}
        onOrderChange={this.handleOrderChange}
        onShipmentChange={this.handleShipmentChange}
        onUpdateOrder={this.handleUpdateOrder}
        onToggleSameRecipient={this.handleToggleSameRecipient}
        onSubmitReorder={this.handleSubmitReorder}
        fetchShippingMethodsByCarrier={this.fetchShippingMethodsByCarrier}
        billingCompanyContacts={this.getUsersWithWalletEnable(
          this.props.company
        )}
        onUpdateShipmentRecipient={this.handleUpdateShipmentRecipient}
        updateUserWithCompanies={this.updateUserWithCompanies}
        onSaveDraft={this.handleSaveDraft}
        updateOrderState={this.updateOrderState}
        submitted={this.state.submitted}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    companies: { company, companyContacts, walletMembers },
    orders: { orderItems, orderStatus, openOrders },
    auth: { isInternal, userCompanies, id: currentUserId },
    shipment: {
      shipments,
      companyShippingCarrierAssignments,
      shippingMethods,
      isCarrierAssignmentsLoading,
    },
    paymentTerms: { paymentTerms },
    geo: { countries },
    users: { users },
  } = state;

  return {
    isCarrierAssignmentsLoading,
    currentUserId,
    walletMembers,
    userCompanies,
    company,
    companyContacts,
    openOrders,
    isInternal,
    orderItems,
    orderStatus,
    shipments,
    countries: countries,
    companyShippingCarrierAssignments,
    shippingMethods,
    users,
    paymentTerms,
  };
}

ReorderCreate.propTypes = {
  openNotification: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  fetchOpenOrders: PropTypes.func,
  fetchCompanyContacts: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchShipments: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrderItems: PropTypes.func.isRequired,
  fetchOrderStatus: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func,
  fetchCompanyShippingCarrierAssignments: PropTypes.func,
  fetchShippingCarriers: PropTypes.func,
  fetchShippingMethods: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  updateShipmentItem: PropTypes.func,
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  openOrders: PropTypes.array,
  shipments: PropTypes.array,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  shippingMethods: PropTypes.array,
  companyShippingCarrierAssignments: PropTypes.array,
  sameRecipient: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  updateUserWithCompanies: PropTypes.func,
  userCompanies: PropTypes.array,
  walletMembers: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchCompanyContacts: bindActionCreators(fetchCompanyContacts, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchShippingCarriers: bindActionCreators(fetchShippingCarriers, dispatch),
    fetchShippingMethods: bindActionCreators(fetchShippingMethods, dispatch),
    fetchCompanyShippingCarrierAssignments: bindActionCreators(
      fetchCompanyShippingCarrierAssignments,
      dispatch
    ),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    updateShipment: bindActionCreators(updateShipment, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchOpenOrders: bindActionCreators(fetchOpenOrders, dispatch),
    fetchUsersByCompany: bindActionCreators(fetchUsersByCompany, dispatch),
    fetchWalletMembers: bindActionCreators(fetchWalletMembers, dispatch),
    fetchPaymentTerms: bindActionCreators(fetchPaymentTerms, dispatch),
    createWalletMember: bindActionCreators(createWalletMember, dispatch),
    archiveOrder: bindActionCreators(archiveOrder, dispatch),
    updateUserWithCompanies: bindActionCreators(
      updateUserWithCompanies,
      dispatch
    ),
    deleteShipmentItem: bindActionCreators(deleteShipmentItem, dispatch),
    navigateTo: bindActionCreators(navigateTo, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReorderCreate)
);
