import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {parse} from "query-string";
import classNames from "classnames";
import _get from "lodash/get";
import FontIcon from "material-ui/FontIcon";

import {formatAddress} from "../../../../../utils/addressFormatter";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import {status as statusData} from "../../../../../constants/quote";
import withStyles from "../../../shared/WithStyles";
import Typography from "../../../shared/Typography";
import RaisedButton from "../../../shared/RaisedButton";
import QuoteLayout from "../../QuoteLayout";
import QuoteItemDetail from "./QuoteItemDetail";
import ChatSystem from "../../../shared/ChatSystem";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import QuoteFooter from "../QuoteFooter";
import Proofs from "./Proofs";

class QuoteSummary extends Component {
  state = {
    openArchiveModal: false,
    sewOutRequested: false,
    preProductionRequested: false,
    priceTableOpenId: null,
    openCheckRecipientModal: false,
  };

  chat = null;

  async componentDidMount() {
    const {
      store,
      history,
      location: {search},
      fetchQuote,
      checkAuth,
      fetchArtRevisionsByCompany,
    } = this.props;
    await checkAuth();
    const {isAuthenticated, isInternal} = this.props;
    const {quote: uuid} = parse(search);
    await fetchQuote(uuid, {include: "country"}, true);
    const {quote} = this.props;
    if (!quote) {
      window.assignLocation("http://www.chill-n-go.com/");
    }
    if (isAuthenticated && isInternal) {
      history.replace(`/quotes/info/${quote.id}`);
    }
    if (quote.companyId && isAuthenticated) {
      fetchArtRevisionsByCompany(quote.companyId, {
        include: "[artSource.brand,adornmentType]",
      });
    }
    if (quote.companyId && quote.contactId && !isAuthenticated) {
      history.replace("/login");
    }
    const {items} = quote;
    if (!quote.isApproved && items.length === 1) {
      this.setState({priceTableOpenId: items[0].id});
    }
    if (
      ["new", "processing", "art_approved", "turned_to_order"].includes(
        quote.status
      )
    ) {
      store.storeQuoteId(quote.id);
      history.replace("/quotes/complete");
    }
  }

  handleOpenArchiveModal = () => this.setState({openArchiveModal: true});

  handleDismissArchiveModal = () => this.setState({openArchiveModal: false});

  handleConfirmArchiveModal = async () => {
    const {deleteQuote, quote} = this.props;
    await deleteQuote(quote.id);
    window.assignLocation("http://www.chill-n-go.com/");
  };

  handleDismissCheckRecipientModal = () =>
    this.setState({openCheckRecipientModal: false});

  handleConfirmArchiveModal = async () => {
    this.setState({openCheckRecipientModal: false});
    await this.doAproveQuote();
  };

  handlePriceTableOpen = itemId => {
    this.setState({priceTableOpenId: itemId});
  };

  handleRemoveItem = itemIndex => async () => {
    const {quote, updateQuote, deleteQuote} = this.props;
    if (quote.items.length === 1) {
      await deleteQuote(quote.id);
      return;
    }
    const items = [
      ...quote.items.slice(0, itemIndex),
      ...quote.items.slice(itemIndex + 1),
    ];
    await updateQuote(quote.uuid, {items}, {include: "country"}, true);
  };

  handleUpdateItemData = itemIndex => async (name, value) => {
    const {quote, updateQuote} = this.props;
    const {uuid, items} = quote;
    const item = items[itemIndex];
    const newItems = [
      ...items.slice(0, itemIndex),
      {
        ...item,
        [name]: value,
      },
      ...items.slice(itemIndex + 1),
    ];
    await updateQuote(uuid, {items: newItems}, {include: "country"}, true);
  };

  handleUpdateItemQuantityValue = itemIndex => async value => {
    const {quote, companyArts, fetchItemPrice, updateQuote} = this.props;
    const {items, arts} = quote;
    const item = items[itemIndex];
    let netUnitCost;
    if (value) {
      const itemPrice = await fetchItemPrice(item, arts, companyArts, value);
      netUnitCost = itemPrice.netUnitCost;
    } else {
      netUnitCost = value;
    }
    const newItems = [
      ...quote.items.slice(0, itemIndex),
      {
        ...item,
        quantity: value,
        unitCost: netUnitCost,
      },
      ...quote.items.slice(itemIndex + 1),
    ];
    await updateQuote(
      quote.uuid,
      {items: newItems},
      {include: "country"},
      true
    );
  };

  handleApproveQuote = async () => {
    const {quote, updateQuote, store, history} = this.props;
    const {sewOutRequested, preProductionRequested} = this.state;
    const statusConfig = _get(statusData, quote.status);
    if (!statusConfig) return;
    const status =
      quote.noAdornment || quote.noNewArt
        ? statusConfig.nextStatusNoAdornment
        : statusConfig.nextStatus;
    if (!status) return;
    const result = await updateQuote(
      quote.uuid,
      {status, sewOutRequested, preProductionRequested},
      {include: "country"},
      true
    );
    if (result && this.chat) {
      this.chat.handleSendMessage();
    }
    store.storeQuoteId(quote.id);
    history.replace("/quotes/complete");
  };

  optionRequestHandler = option => () => {
    switch (option) {
    case "requestPreProductionProof":
      this.setState(({preProductionRequested}) => ({
        preProductionRequested: !preProductionRequested,
      }));
      break;
    case "requestSewOut":
      this.setState(({sewOutRequested}) => ({
        sewOutRequested: !sewOutRequested,
      }));
      break;
    default:
      break;
    }
  };

  renderContactInfo = () => {
    const {quote} = this.props;
    const {
      address1,
      address2: formatAddress2,
      city,
      stateCode,
      zipCode,
      countryCode,
    } = formatAddress(quote, []);
    const address = `${address1}${formatAddress2}, ${city}, ${stateCode}, ${zipCode}, ${countryCode}`;
    return (
      <div className="flex justify-content-between">
        {quote &&
          !quote.isKnownCompany && (
          <div className="flex flex-column">
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              color="gray.title"
            >
                Company Contact
            </Typography>
            <Typography variant="body" className="margin-top-8">
              {quote && quote.name} {quote && quote.lastName}
            </Typography>
          </div>
        )}
        <div className="flex flex-column">
          <Typography
            variant="subtitleBold"
            className="text-uppercase"
            color="gray.title"
          >
            Email
          </Typography>
          <Typography variant="body" className="margin-top-8">
            {quote && quote.emailAddress}
          </Typography>
        </div>
        {quote &&
          !quote.isKnownCompany && (
          <div className="flex flex-column">
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              color="gray.title"
            >
                Address
            </Typography>
            <Typography variant="body" className="margin-top-8">
              {address}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  renderApproveButton = () => {
    const {quote} = this.props;
    if (!_get(quote, "status")) return;
    if (
      ["new", "processing", "art_approved", "turned_to_order"].includes(
        quote.status
      )
    )
      return;
    return (
      <RaisedButton
        large
        disabled={this.isApproveDisabled()}
        onClick={this.handleApproveQuote}
        label={
          <Fragment>
            <FontIcon
              className="material-icons"
              style={{color: "white", padding: 4, fontSize: 20}}
            >
              check
            </FontIcon>
            {this.getApproveButtonText()}
          </Fragment>
        }
        style={this.pageStyle.submitButton}
        labelStyle={this.pageStyle.submitButtonLabel}
      />
    );
  };

  getGreetingText = () => {
    const {quote} = this.props;
    if (!quote) return;
    const {noAdornment, isApproved, noNewArt} = quote;
    if (noAdornment) {
      return "Thanks for your patience. The pricing table avails the full quantity/discount matrix. Entering the desired quantity shows your total order cost before taxes (if applicable) and shipping. Simply approve the quote and we’ll move forward.";
    }
    if (isApproved) {
      return `We’re almost there! Please find below the final elements of your order: a printout of your ${
        noNewArt ? "" : "digitized"
      } art, a digital mock-up, a quantity/price table (including  ${
        noNewArt ? "" : "final"
      } embroidery costs), and approval options. Setting your desired quantity yields item and order total costs (${
        noNewArt ? "" : "includes digitization/set-up, "
      }excludes S/H and taxes if applicable).`;
    }
    return (
      <Fragment>
        Please find below your product and art details.
        Click the Price Table to see the quantity discount structure.
        Use Budget calculator or input any quantity to see respective cost.
        Final quantity will be set later.
      </Fragment>
    );
  };

  getTotalDigitization = quote => {
    if (!quote) return 0;
    const {arts, items} = quote;
    const usedArts = arts.filter(art =>
      items.find(item => item.arts.find(itemArt => itemArt.art === art.uuid))
    );
    if (!usedArts) return 0;
    return usedArts.reduce((total, {digitization}) => {
      if (!_get(digitization, "price")) return total;
      return total + digitization.price;
    }, 0);
  };

  getOrderTotal = () => {
    const {quote} = this.props;
    if (!quote) return {digitization: 0, total: 0, items: 0};
    const {items} = quote;
    const digitization = this.getTotalDigitization(quote);
    if (!items) return {digitization, total: digitization, items: 0};
    const itemsCost = items.reduce(
      (total, {unitCost, quantity}) =>
        total + (unitCost || 0) * (quantity || 0),
      0
    );
    return {
      digitization,
      items: itemsCost,
      total: itemsCost + digitization,
    };
  };

  getApproveButtonText = () => {
    const {quote} = this.props;
    if (!_get(quote, "status")) return "";
    const statusConfig = _get(statusData, quote.status);
    if (!statusConfig) return;
    return quote.noAdornment || quote.noNewArt
      ? statusConfig.nextActionLabelNoAdornment
      : statusConfig.nextActionLabel;
  };

  isApproveDisabled = () => {
    const {quote} = this.props;
    if (!quote) return true;
    if (!quote.isApproved && !(quote.noAdornment || quote.noNewArt))
      return false;
    return quote.items.some(item => !item.quantity);
  };

  canPlaceOrder = () => {
    const {quote} = this.props;
    if (!quote || quote.status === "new") return false;
    return quote.isApproved || quote.noAdornment || quote.noNewArt;
  };

  artDigitizationRender = items => {
    if (!items) return;
    const arts = {};
    return items.map(item => {
      return item.arts.map(art => {
        const existed = arts[art.art];
        if (!existed) {
          arts[art.art] = true;
        }
        return !existed;
      });
    });
  };

  get pageStyle() {
    const {styles: {colors}} = this.props;
    return {
      headerLogo: {
        width: 150,
        marginTop: 7,
      },
      artFilename: {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artFileName: {
        padding: "0 8px",
        whiteSpace: "nowrap",
        maxWidth: 230,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      artFile: {
        marginTop: "auto",
        marginBottom: 8,
        width: 238,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      imageContainer: {
        marginRight: 24,
      },
      image: {
        minWidth: 144,
        maxWidth: 144,
        height: 144,
        objectFit: "contain",
        borderColor: colors.gray.border,
        borderWidth: 1,
      },
      removeButton: {
        marginLeft: 16,
        cursor: "pointer",
        color: "#0C5271",
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: 14,
      },
      closeIcon: {
        color: colors.primary.light,
        fontSize: 14,
      },
      input: {
        minWidth: 238,
      },
      smallInput: {
        width: 80,
      },
      footer: {
        paddingTop: 30,
        paddingBottom: 30,
      },
      artIcon: {
        padding: 4,
        color: colors.primary.light,
      },
      separator: {
        marginLeft: "10px",
        marginRight: "10px",
        fontWeight: "bold",
      },
      submitButton: {
        marginLeft: 16,
      },
      submitButtonLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      artImageContainer: {
        width: 144,
        height: 185,
        borderColor: colors.gray.border,
        borderWidth: 1,
        borderStyle: "solid",
      },
      artImage: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      archiveIcon: {
        padding: 4,
        color: colors.primary.light,
      },
    };
  }

  render() {
    const {
      quote,
      itemPrices,
      companyArts,
      isAuthenticated,
      user,
      isInternal,
      isLoadingPrices,
      fetchItemPriceTable,
      logout,
    } = this.props;
    const {
      openArchiveModal,
      sewOutRequested,
      preProductionRequested,
      priceTableOpenId,
    } = this.state;
    const artDigitizationRenderConfig = this.artDigitizationRender(
      quote && quote.items
    );
    const total = this.getOrderTotal();
    return (
      <QuoteLayout
        showMainHeader={isAuthenticated}
        user={user}
        isInternal={isInternal}
        quote={quote}
        showSidebar={false}
        subHeaderText={
          isAuthenticated ? null : (
            <img
              src="/assets/imgs/ChillNGo-white.png"
              style={this.pageStyle.headerLogo}
            />
          )
        }
        withTopMargin={false}
        logout={logout}
      >
        <div className="flex">
          <div
            className="flex flex-column margin-auto"
            style={{maxWidth: 1116, minWidth: 1116}}
          >
            <Typography variant="h1" style={{margin: "32px 0 24px"}}>
              {quote && quote.companyName}
            </Typography>
            <Typography
              variant="h5"
              style={{marginTop: 8, marginBottom: 24, fontStyle: "italic"}}
            >
              Hi {quote && quote.name}:<br />
              {this.getGreetingText()}
            </Typography>
            {quote &&
              quote.items.map((item, index) => (
                <QuoteItemDetail
                  detailOnly
                  disabled={quote && quote.status === "processing"}
                  key={item.id}
                  {...item}
                  quoteArts={quote.arts}
                  companyArts={companyArts}
                  quoteId={quote.id}
                  artDigitizationRenderConfig={
                    artDigitizationRenderConfig[index]
                  }
                  itemPrices={itemPrices}
                  isLoadingPrices={isLoadingPrices}
                  priceTableOpenId={priceTableOpenId}
                  onPriceTableOpen={this.handlePriceTableOpen}
                  fetchItemPriceTable={fetchItemPriceTable}
                  onRemoveItem={this.handleRemoveItem(index)}
                  onUpdateItemQuantityValue={this.handleUpdateItemQuantityValue(
                    index
                  )}
                  onUpdateItemData={this.handleUpdateItemData(index)}
                  pageStyle={this.pageStyle}
                  itemsCount={quote.items.length}
                  quoteStatus={quote.status}
                  hideRemove={quote.items.length === 1}
                />
              ))}
            <div className="flex" style={{width: "45%", alignSelf: "flex-end"}}>
              {total.digitization > 0 && (
                <div className="flex-one">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    One-time Set-up Costs
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{margintop: 4, marginBottom: 8}}
                  >
                    {getCurrencyFormattedNumber(total.digitization)}
                  </Typography>
                </div>
              )}

              {total.items > 0 && (
                <div className="flex-one">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Product Costs
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{margintop: 4, marginBottom: 8}}
                  >
                    {getCurrencyFormattedNumber(total.items)}
                  </Typography>
                </div>
              )}

              {total.total > 0 && (
                <div
                  className={classNames("flex flex-column flex-one", {
                    "align-items-end": !(
                      total.items > 0 && total.digitization > 0
                    ),
                  })}
                  style={{marginLeft: 32}}
                >
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Order Total before S/H
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{margintop: 4, marginBottom: 8}}
                  >
                    {getCurrencyFormattedNumber(total.total)}
                  </Typography>
                </div>
              )}
            </div>
            <footer
              style={this.pageStyle.footer}
              className={classNames("flex align-items-center", {
                "justify-content-end": quote && quote.status === "art_approved",
                "justify-content-between":
                  quote && quote.status !== "art_approved",
              })}
            >
              {quote &&
                !["art_approved", "turned_to_order"].includes(quote.status) && (
                <Typography
                  variant="bodyBold"
                  className="flex flex-center justify-content-center pointer"
                  color="primary.light"
                  onClick={this.handleOpenArchiveModal}
                >
                  <FontIcon
                    className="material-icons"
                    style={this.pageStyle.archiveIcon}
                  >
                      archive
                  </FontIcon>
                    Quit This Quote
                </Typography>
              )}
              <div className="flex flex-column">
                <div>
                  {this.canPlaceOrder() &&
                  !["processing", "art_approved", "turned_to_order"].includes(
                    quote.status
                  ) ? (
                      <Proofs
                        noSewOut={quote.noAdornment}
                        requestSewOut={sewOutRequested}
                        requestPreProductionProof={preProductionRequested}
                        optionRequestHandler={this.optionRequestHandler}
                      />
                    ) : null}
                  {this.renderApproveButton()}
                </div>
              </div>
            </footer>

            {this.canPlaceOrder() ? (
              <ChatSystem
                setRef={ref => (this.chat = ref)}
                type="quote"
                typeData={quote}
                typeIdName="uuid"
                name={quote.name || quote.companyName}
              />
            ) : null}
            <QuoteFooter />
          </div>
        </div>
        <ConfirmationModal
          title="Do you want to quit this Quote?"
          content={
            <Fragment>
              Oh no!! Sorry to see you go!
              <br />
              If it’s something we did, please reach out.
            </Fragment>
          }
          confirmText="Yes"
          cancelText="No"
          open={openArchiveModal}
          onConfirm={this.handleConfirmArchiveModal}
          onDismiss={this.handleDismissArchiveModal}
        />
      </QuoteLayout>
    );
  }
}

QuoteSummary.propTypes = {
  quote: PropTypes.object,
  store: PropTypes.object,
  itemPrices: PropTypes.array,
  companyArts: PropTypes.array,
  isLoadingPrices: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.string,
  isInternal: PropTypes.bool,
  fetchQuote: PropTypes.func,
  fetchItemPriceTable: PropTypes.func,
  fetchItemPrice: PropTypes.func,
  updateQuote: PropTypes.func,
  deleteQuote: PropTypes.func,
  checkAuth: PropTypes.func.isRequired,
  fetchArtRevisionsByCompany: PropTypes.func.isRequired,
  logout: PropTypes.func,
  location: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(QuoteSummary);
