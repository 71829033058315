import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchColorsRequest = () => makeCommonAction(types.FETCH_COLORS_REQUEST);
const fetchColorsSuccess = payload =>
  makeCommonAction(types.FETCH_COLORS_SUCCESS, payload);
const fetchColorsError = error =>
  makeCommonAction(types.FETCH_COLORS_ERROR, error, true);

const fetchLeatherColorsRequest = () =>
  makeCommonAction(types.FETCH_LEATHER_COLORS_REQUEST);
const fetchLeatherColorsSuccess = payload =>
  makeCommonAction(types.FETCH_LEATHER_COLORS_SUCCESS, payload);
const fetchLeatherColorsError = error =>
  makeCommonAction(types.FETCH_LEATHER_COLORS_ERROR, error, true);

export function fetchColors(options) {
  const {delay, ...opts} = options || {};
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl("colors", null, opts),
    fetchOptions: {method: "GET"},
    requestFunc: fetchColorsRequest,
    successFunc: fetchColorsSuccess,
    errorFunc: fetchColorsError,
    error_message: "Error fetching Colors",
    delay,
  });
}

export function fetchLeatherColors(options) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl("colors/leather", null, options),
    fetchOptions: {method: "GET"},
    requestFunc: fetchLeatherColorsRequest,
    successFunc: fetchLeatherColorsSuccess,
    errorFunc: fetchLeatherColorsError,
    error_message: "Error fetching Colors",
  });
}
