import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Margin extends Component {
  static propTypes = {
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginRight: PropTypes.number,
    marginLeft: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const {
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      className,
    } = this.props;
    return (
      <div
        className={className}
        style={{
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
        }}
      />
    );
  }
}
