export const TextFieldStyles = {
  width: 358,
  color: "#747D86",
  fontSize: 16,
};

export const TextInputStyles = {
  width: 358,
  color: "#4A5158",
  fontSize: 16,
};

export const TextFieldLabelStyles = {
  color: "#747D86",
  fontWeight: "normal",
};

export const TextFieldSmallLabelStyles = {
  color: "#747D86",
  fontSize: 16,
};

export const SelectFieldIconStyle = {
  fill: "#929DA8",
};

export const formStyles = {
  TextFieldLabelStyles: {
    color: "#747D86",
    fontSize: 16,
    fontWeight: "normal",
  },
  TextFieldStyles: {
    width: 300,
    color: "#747D86",
    marginRight: 32,
  },
  TextInputStyles: {
    width: 300,
    fontSize: 16,
    fontWeight: "normal",
  },
  HalfFieldStyles: {
    width: 160,
    fontSize: 16,
  },
  TinyInputStyles: {
    width: 80,
    fontSize: 16,
  },
  SmallInputStyles: {
    width: 120,
    fontSize: 16,
  },
  HalfTextInputStyles: {
    width: 150,
    fontSize: 16,
  },
  TwoTextFieldStyles: {
    width: 200,
    color: "#747D86",
    marginRight: 32,
  },
  TwoTextInputStyles: {
    width: 200,
    fontSize: 16,
  },
  DoubleTextFieldStyles: {
    width: 330,
    color: "#747D86",
    marginRight: 32,
  },
  DoubleTextInputStyles: {
    width: 330,
    fontSize: 16,
  },
  SelectMenuItemStyles: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tqSelectMenuInputStyles: {
    width: 250,
    overflowX: "hidden",
  },
  tqSelectMenuListStyles: {
    width: 250,
    maxWidth: 250,
  },
  tqTextInputStyles: {
    width: 260,
  },
  tinySelectStyles: {
    width: 30,
  },
  halfSelectStyles: {
    width: 190,
    color: "#747D86",
    marginRight: 32,
  },
  halfSelectInputStyles: {
    width: 190,
    fontSize: 16,
  },
  halfSelectMenuInputStyles: {
    width: 205,
    overflowX: "hidden",
  },
  halfSelectMenuListStyles: {
    width: 190,
    maxWidth: 190,
  },
  halfSelectFloatingLabelStyles: {
    width: 180,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  SelectMenuInputStyles: {
    width: 130,
  },
  quarterSelectStyles: {
    width: 80,
    color: "#747D86",
    marginRight: 32,
  },
  quarterSelectInputStyles: {
    width: 80,
    fontSize: 16,
  },
  quarterSelectMenuInputStyles: {
    width: 100,
  },
  quarterSelectMenuListStyles: {
    width: 85,
    maxWidth: 85,
  },
  quarterSelectFloatingLabelStyles: {
    width: 80,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  PostCodeStyles: {
    width: 136,
    color: "#747D86",
    marginRight: 32,
  },
  PostCodeInputStyles: {
    width: 136,
    fontSize: 16,
  },
  underlineStyle: {
    borderColor: "#747D86",
  },
  noUnderlineStyle: {
    border: "none",
  },
  underlineDisabledStyle: {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  PhoneNumberTextFieldStyles: {
    width: 200,
    color: "#4A5158",
    marginRight: 32,
  },
  CityTextFieldStyles: {
    width: 160,
    color: "#4A5158",
    marginRight: 32,
    display: "block",
  },
  CountryIconStyles: {
    right: 20,
    color: "#747D86",
    padding: 0,
  },
  HalfTextInputIconStyles: {
    right: 30,
    padding: 0,
  },
  StateIconStyles: {
    right: 105,
    color: "#747D86",
    padding: 0,
  },
  HalfIconStyles: {
    right: 30,
  },
  MenuStyle: {
    width: 200,
  },
  WideMenuStyle: {
    width: 320,
  },
  wrappedMenuItemStyle: {
    whiteSpace: "normal",
    lineHeight: "1.33em",
    display: "flex",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4,
    maxHeight: "280px",
  },
  inputStyle: {
    fontWeight: "normal",
    color: "#4A5158",
    fontSize: 16,
  },
  phoneInputWithPrefixWrapperStyle: {
    display: "flex",
    alignItems: "baseline",
  },
  phoneInputPrefixStyle: {
    padding: "0 8px",
    color: "#4A5158",
    fontSize: 16,
    width: 50,
    textAlign: "right",
  },
};
