import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _get from "lodash/get";
import Divider from "material-ui/Divider";
import FontIcon from "material-ui/FontIcon";
import Dialog from "material-ui/Dialog";

import ArtsTable from "../../../../pages/DashboardPage/ArtsTable";
import CNGToolTip from "../../../../shared/CNGToolTip";
import Card from "../../../../shared/Card";
import Typography from "../../../shared/Typography";
import QuoteItemPricingTable from "../QuoteItemPricingTable";
import QuoteItemArtsTable from "../QuoteItemArtsTable";
import PricingTableButton from "../PricingTableButton";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";

class QuoteItemDetail extends Component {
  state = {
    pricingTableOpen: false,
  };

  handleOpenPricingTable = () => {
    const {quoteArts, companyArts, fetchItemPriceTable} = this.props;
    fetchItemPriceTable(this.props, quoteArts, companyArts);
    this.setState({pricingTableOpen: true});
  };

  handleClosePricingTable = () => this.setState({pricingTableOpen: false});

  shouldShowPricingTable = () => {
    const {arts, quoteArts, companyArts} = this.props;
    if (!arts || arts.length < 1) return true;
    return arts.every(itemArt => {
      let art = quoteArts.find(art => art.uuid === itemArt.art);
      if (art) return _get(art, "details.stitchCount", art.stitchCount);
      art = companyArts && companyArts.find(({id}) => id === itemArt.art);
      return art;
    });
  };

  renderFabricColor = noQty => {
    const {fabric, color, quantity} = this.props;
    return (
      <div className="flex margin-bottom-16">
        <div className="flex flex-column">
          <Typography
            variant="subtitleBold"
            className="text-uppercase"
            color="gray.light"
          >
            Fabric
          </Typography>
          <Typography variant="body" className="margin-top-8">
            {fabric && fabric.name}
          </Typography>
        </div>
        <div className="flex flex-column margin-left-40">
          <Typography
            variant="subtitleBold"
            className="text-uppercase"
            color="gray.light"
          >
            Color
          </Typography>
          <Typography variant="body" className="margin-top-8">
            {color && color.name}
          </Typography>
        </div>
        {!noQty &&
          quantity > 0 && (
          <div className="flex flex-column margin-left-40">
            <Typography
              variant="subtitleBold"
              className="text-uppercase"
              color="gray.light"
            >
                Quantity
            </Typography>
            <Typography variant="body" className="margin-top-8">
              {quantity}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  renderArts = () => {
    const {
      pageStyle,
      onArtChange,
      onFieldBlur,
      validatorListener,
      disableFields,
      quoteStatus,
      quoteId,
      quoteArts,
      companyArts,
      showBrand,
      brands,
      companyId,
      isInternal,
      artDigitizationRenderConfig,
      onAddDigitizedArt,
      onUnlinkArt,
      id,
    } = this.props;
    const newArts = this.newItemArts;
    const hasNewArts = newArts && newArts.length > 0;
    const existingArts = this.existingItemArts;
    const hasExistingArts = existingArts && existingArts.length > 0;
    return (
      <React.Fragment>
        {hasNewArts &&
          hasExistingArts && (
          <Typography variant="subtitle">New Art</Typography>
        )}
        {hasNewArts && (
          <QuoteItemArtsTable
            arts={newArts}
            quoteId={quoteId}
            quoteArts={quoteArts}
            companyArts={companyArts}
            quoteStatus={quoteStatus}
            onArtChange={onArtChange}
            onFieldBlur={onFieldBlur}
            pageStyle={pageStyle}
            validatorListener={validatorListener}
            disableFields={disableFields}
            artDigitizationRenderConfig={artDigitizationRenderConfig}
            onAddDigitizedArt={onAddDigitizedArt}
            showBrand={showBrand}
            brands={brands}
            companyId={companyId}
            isInternal={isInternal}
            onUnlinkArt={onUnlinkArt}
            itemId={id}
          />
        )}
        {hasNewArts &&
          hasExistingArts && (
          <Typography variant="subtitle">Company Art</Typography>
        )}
        {hasExistingArts && <ArtsTable showArtLocation arts={existingArts} />}
      </React.Fragment>
    );
  };

  get newItemArts() {
    const {arts, quoteArts} = this.props;
    const newArts = arts
      .map((art, artIndex) => ({...art, artIndex}))
      .filter(art => quoteArts.find(quoteArt => quoteArt.uuid === art.art));
    return newArts;
  }

  get existingItemArts() {
    const {arts, companyArts, pageStyle} = this.props;
    const existingArts = arts.reduce((revisions, art) => {
      const revision = companyArts.find(
        companyArt => companyArt.id === art.art
      );
      if (revision) {
        revisions.push({
          ...revision.artSource,
          revisions: [revision],
          adornmentLocation: art.adornmentLocation,
          digitizedArtPreview: (
            <div className="flex flex-center">
              <CNGToolTip
                parentRef={`digitized-art-${art.art}`}
                style={{
                  width: 250,
                  border: "1px solid #BDC6CF",
                }}
                element={{imageUrl: revision.imageUrl}}
                position="top"
              >
                <div
                  className="flex justify-content-between align-items-center z-index-3"
                  style={{
                    ...pageStyle.artFile,
                  }}
                >
                  <div
                    className="flex align-items-center"
                    style={{overflow: "hidden"}}
                  >
                    <img src={revision.imageUrl} style={pageStyle.artImage} />
                    <Typography
                      component="span"
                      variant="bodyBold"
                      color="secondary.light"
                      className="version--2 secondary-link-style"
                      style={pageStyle.artFileName}
                    >
                      {revision.name}
                    </Typography>
                  </div>
                </div>
              </CNGToolTip>
            </div>
          ),
        });
      }
      return revisions;
    }, []);
    return existingArts;
  }

  render() {
    const {
      itemPrices,
      product,
      unitCost,
      quantity,
      onEditItem,
      disableFields,
      isLoadingPrices,
      orderId,
      isInternal,
    } = this.props;
    const {pricingTableOpen} = this.state;
    return (
      <Card className="flex flex-column justify-content-between padding-32 margin-bottom-16">
        <Typography variant="subtitle">Item #{product.itemNumber}</Typography>
        <div className="flex justify-content-between">
          <div className="flex">
            <Typography variant="h3">{product.productName}</Typography>
            {!orderId &&
              isInternal && (
              <span
                className={classNames({pointer: !disableFields})}
                style={{
                  alignSelf: "center",
                  padding: 4,
                  paddingLeft: 12,
                }}
                onClick={disableFields ? () => {} : onEditItem}
              >
                <FontIcon
                  className="material-icons"
                  style={{
                    color: disableFields ? "#BDC6CF" : "#497E95",
                    fontSize: 20,
                  }}
                >
                    edit
                </FontIcon>
              </span>
            )}
          </div>
          {this.shouldShowPricingTable() && (
            <PricingTableButton
              onOpenPricingTable={this.handleOpenPricingTable}
            />
          )}
        </div>
        <Divider style={{marginTop: 20, marginBottom: 16}} />
        {this.renderFabricColor()}
        {this.renderArts()}
        {quantity > 0 && (
          <div className="flex flex-column align-items-end margin-top-8 margin-left-8">
            <Typography variant="subtitleBold" className="text-uppercase">
              Total
            </Typography>
            <Typography
              variant="h4"
              className="text-uppercase"
              color="primary.light"
              style={{margintop: 4, marginBottom: 8}}
            >
              {getCurrencyFormattedNumber(unitCost * quantity || 0)}
            </Typography>
          </div>
        )}
        <Dialog
          modal={false}
          open={pricingTableOpen}
          contentStyle={{maxWidth: 640}}
        >
          <span style={{position: "absolute", right: 10, top: 10}}>
            <FontIcon
              className="material-icons pointer"
              onClick={this.handleClosePricingTable}
              style={{
                color: "#1D9ABB",
                fontSize: 16,
                borderRadius: 9,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              close
            </FontIcon>
          </span>
          <Typography variant="subtitle">Item #{product.itemNumber}</Typography>
          <Typography variant="h3" style={{marginTop: 5, marginBottom: 13}}>
            {product.productName}
          </Typography>
          {this.renderFabricColor(true)}
          <QuoteItemPricingTable
            showDetails
            detailOnly
            prices={itemPrices}
            isLoading={isLoadingPrices}
            tableStyle={{marginTop: 24}}
          />
        </Dialog>
      </Card>
    );
  }
}

QuoteItemDetail.propTypes = {
  id: PropTypes.string,
  quoteStatus: PropTypes.string,
  quoteId: PropTypes.string,
  orderId: PropTypes.string,
  itemPrices: PropTypes.array,
  product: PropTypes.object,
  fabric: PropTypes.object,
  color: PropTypes.object,
  arts: PropTypes.array,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
  onEditItem: PropTypes.func,
  onArtChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  validatorListener: PropTypes.func,
  disableFields: PropTypes.bool,
  fetchItemPriceTable: PropTypes.func,
  isLoadingPrices: PropTypes.bool,
  isInternal: PropTypes.bool,
  showBrand: PropTypes.bool,
  brands: PropTypes.array,
  companyId: PropTypes.number,
  quoteArts: PropTypes.array,
  companyArts: PropTypes.array,
  artDigitizationRenderConfig: PropTypes.array,
  onAddDigitizedArt: PropTypes.func,
  onUnlinkArt: PropTypes.func,
};

export default QuoteItemDetail;
