import _get from "lodash/get";
import serializePayloadData from "../../utils/serializePayloadData";
import fetchAPI from "../../utils/fetchAPI";
import RequestService from "../../services/RequestService";
import {dispatchMessage} from "../commonActions";
import {
  CREATE_QUOTE_REQUEST,
  CREATE_QUOTE_SUCCESS,
  FETCH_QUOTE_REQUEST,
  FETCH_QUOTE_SUCCESS,
  FETCH_QUOTES_REQUEST,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_UNREAD_COUNTS,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
  DELETE_QUOTE_REQUEST,
  DELETE_QUOTE_SUCCESS,
  FETCH_QUOTE_ITEM_PRICES_REQUEST,
  FETCH_QUOTE_ITEM_PRICES_SUCCESS,
  FETCH_QUOTE_ITEM_PRICE_REQUEST,
  FETCH_QUOTE_ITEM_PRICE_SUCCESS,
  UPLOAD_ART_FILE_REQUEST,
  UPLOAD_ART_FILE_SUCCESS,
  OPEN_ERROR_MESSAGE,
} from "../../constants/actionTypes";

export const createQuote = payload => {
  let hasFiles = false;
  payload.arts.forEach((art, index) => {
    if (art.file && art.file.name) {
      hasFiles = true;
      payload[`arts_art_${index}_file`] = art.file;
    }
  });

  payload = serializePayloadData(payload);

  if (hasFiles) {
    const formData = new FormData();
    Object.keys(payload)
      .filter(key => !["arts", "items"].includes(key))
      .forEach(key => {
        formData.append(key, payload[key]);
      });
    formData.append("arts", JSON.stringify(payload.arts));
    formData.append("items", JSON.stringify(payload.items));
    payload = formData;
  }

  return async dispatch => {
    const quote = await fetchAPI(
      {
        url: "/quotes",
        method: "POST",
        body: payload,
        contentType: !hasFiles,
        types: [CREATE_QUOTE_REQUEST, CREATE_QUOTE_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );
    if (quote) {
      if (Object.keys(quote).includes("orderSource")) {
        dispatchMessage(dispatch, "Order created successfully");
      }else{
        dispatchMessage(dispatch, "Quote created successfully");
      }
      return quote;
    }
  };
};

export const fetchQuote = (id, options, byUUID = false) => {
  return async dispatch => {
    const quote = await fetchAPI(
      {
        url: RequestService.buildIndexUrl(
          byUUID ? `quotes/uuid/${id}` : `quotes/${id}`,
          null,
          options
        ),
        method: "GET",
        types: [FETCH_QUOTE_REQUEST, FETCH_QUOTE_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );
    return quote;
  };
};

export const fetchQuotes = options => {
  return async dispatch => {
    const quotes = await fetchAPI(
      {
        url: RequestService.buildIndexUrl("quotes", null, options),
        method: "GET",
        types: [FETCH_QUOTES_REQUEST, FETCH_QUOTES_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );
    return quotes;
  };
};

export const searchQuotes = (search, options) => {
  return async dispatch => {
    const quotes = await fetchAPI(
      {
        url: RequestService.buildIndexUrl(
          `quotes/search?search_term=${search}`,
          null,
          options
        ),
        method: "GET",
        types: [FETCH_QUOTES_REQUEST, FETCH_QUOTES_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );
    return quotes;
  };
};

export const updateQuote = (id, payload, options, byUUID = false) => {
  let hasFiles = false;
  payload.arts &&
    payload.arts.forEach((art, index) => {
      if (art.file && art.file.name) {
        hasFiles = true;
        payload[`arts_art_${index}_file`] = art.file;
      }
      if (art.digitizedFile && art.digitizedFile.name) {
        hasFiles = true;
        payload[`arts_digitized_${index}_file`] = art.digitizedFile;
      }
    });
  payload.items &&
    payload.items.forEach((item, itemIndex) => {
      item.mockups &&
        item.mockups.forEach((mockup, index) => {
          if (mockup && mockup.name) {
            hasFiles = true;
            payload[`items_${itemIndex}_mockup_${index}_file`] = mockup;
          }
        });
    });
  payload = serializePayloadData(payload);

  if (hasFiles) {
    const formData = new FormData();
    Object.keys(payload)
      .filter(key => !["arts", "items"].includes(key))
      .forEach(key => {
        if (payload[key]) formData.append(key, payload[key]);
      });
    formData.append("arts", JSON.stringify(payload.arts));
    if (payload.items) {
      formData.append("items", JSON.stringify(payload.items));
    }
    payload = formData;
  }

  return async dispatch => {
    const quotes = await fetchAPI(
      {
        url: RequestService.buildIndexUrl(
          byUUID ? `quotes/uuid/${id}` : `quotes/${id}`,
          null,
          options
        ),
        method: "PUT",
        body: payload,
        contentType: !hasFiles,
        types: [UPDATE_QUOTE_REQUEST, UPDATE_QUOTE_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );

    return quotes;
  };
};

export const deleteQuote = id => {
  return async dispatch => {
    const quotes = await fetchAPI(
      {
        url: `/quotes/${id}`,
        method: "DELETE",
        types: [DELETE_QUOTE_REQUEST, DELETE_QUOTE_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );

    return quotes;
  };
};

const setUpNewArtInParams = (artParams, art, index) => {
  artParams.push(`art${index + 1}[uuid]=${art.uuid}`);
  const stitchCount = _get(art, "details.stitchCount", art.stitchCount);
  artParams.push(`art${index + 1}[stitch_count]=${stitchCount}`);
  if (art.internalIdentifier) {
    artParams.push(
      `art${index + 1}[internal_identifier]=${art.internalIdentifier}`
    );
  } else if (art.isLargeFormat) {
    artParams.push(
      `art${index + 1}[internal_identifier]=embroidery_large_format`
    );
  }
};

const setUpArtsInParams = (artParams, item, quoteArts) => {
  let companyArtCount = 0;
  item.arts.forEach(({art: artUuid}, index) => {
    let art = quoteArts.find(art => art.uuid === artUuid);
    if (art) {
      setUpNewArtInParams(artParams, art, index);
    } else {
      artParams.push(`arts[${companyArtCount}]=${artUuid}`);
      companyArtCount++;
    }
  });
};

export const fetchQuoteItemPriceTable = (item, quoteArts, companyArts) => {
  return async dispatch => {
    const artParams = [];
    if (_get(item, "arts.length", 0) > 0) {
      setUpArtsInParams(artParams, item, quoteArts, companyArts);
    }
    const prices = await fetchAPI(
      {
        url: `/calculateunitprices?product_id=${item.product.id}&fabric_id=${
          item.fabric.id
        }&${artParams.join("&")}`,
        method: "GET",
        types: [
          FETCH_QUOTE_ITEM_PRICES_REQUEST,
          FETCH_QUOTE_ITEM_PRICES_SUCCESS,
          OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );
    return prices;
  };
};

export const fetchQuoteItemPrice = (item, quoteArts, companyArts, quantity) => {
  return async dispatch => {
    const artParams = [];
    if (_get(item, "arts.length", 0) > 0) {
      setUpArtsInParams(artParams, item, quoteArts, companyArts);
    }
    const prices = await fetchAPI(
      {
        url: `/calculateunitprice?product_id=${item.product.id}&fabric_id=${
          item.fabric.id
        }&quantity=${quantity}&${artParams.join("&")}`,
        method: "GET",
        types: [
          FETCH_QUOTE_ITEM_PRICE_REQUEST,
          FETCH_QUOTE_ITEM_PRICE_SUCCESS,
          OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );
    return prices;
  };
};

export const assignQuoteToKnownCompany = (quoteId, options) => {
  return async dispatch => {
    const quote = await fetchAPI(
      {
        url: RequestService.buildIndexUrl(
          "quotes/assigncompany",
          null,
          options
        ),
        method: "POST",
        body: {quoteId},
        types: [UPDATE_QUOTE_REQUEST, UPDATE_QUOTE_SUCCESS, OPEN_ERROR_MESSAGE],
      },
      dispatch
    );
    return quote;
  };
};

export const uploadArtFile = (file, art_id) => {
  return async dispatch => {
    const formData = new FormData();
    formData.set("file", file);
    formData.append("art_id", art_id);
    const result = await fetchAPI(
      {
        url: "/quotes/newartfile",
        method: "POST",
        body: formData,
        contentType: false,
        types: [
          UPLOAD_ART_FILE_REQUEST,
          UPLOAD_ART_FILE_SUCCESS,
          OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );

    if (result) {
      dispatchMessage(dispatch, "Art file has been created successfully");
    }
    return result;
  };
};

export const fetchQuotesUnreadCounts = counts => dispatch =>
  dispatch({type: FETCH_QUOTES_UNREAD_COUNTS, payload: counts});
