import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import IconMenu from "material-ui/IconMenu";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import lodash from "lodash";

class CarrierAssignmentTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    carrierAssignments: PropTypes.array,
    patchCompanyShippingCarrierAssignment: PropTypes.func,
    deleteCompanyShippingCarrierAssignment: PropTypes.func,
  };

  _renderRow(carrierAssignment, index) {
    const shippingCarrierName = lodash.get(
      carrierAssignment,
      "shippingCarrier.name"
    );
    const name = lodash.get(carrierAssignment, "name");
    const accountNumber = lodash.get(carrierAssignment, "accountNumber");
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          title={shippingCarrierName}
          customStyles={{
            width: "25%",
            fontSize: 12,
          }}
        >
          {shippingCarrierName}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={name}
          customStyles={{
            width: "24%",
          }}
        >
          {name}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={accountNumber}
          customStyles={{
            width: "24%",
          }}
        >
          {accountNumber}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "18%",
          }}
        >
          {lodash.get(carrierAssignment, "isDefault") ? "Yes" : ""}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 80,
            textOverflow: "unset",
          }}
        >
          {this._renderRowOptions(carrierAssignment)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderRowOptions(carrierAssignment) {
    const {isInternal} = this.props;
    if (!isInternal && lodash.get(carrierAssignment, "isDefault")) return;

    return (
      <IconMenu
        iconButtonElement={
          <IconButton>
            <MoreVertIcon color="#0C5371" />
          </IconButton>
        }
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        targetOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {!lodash.get(carrierAssignment, "isDefault") ? (
          <CNGMenuItem
            primaryText="Make default"
            onClick={() => {
              this.handleMakeDefault(carrierAssignment);
            }}
          />
        ) : null}
        {isInternal ? (
          <CNGMenuItem
            primaryText="Archive"
            onClick={() => {
              this.handleArchive(carrierAssignment);
            }}
          />
        ) : null}
        {isInternal ? (
          <CNGMenuItem
            primaryText="Edit"
            onClick={() => {
              this.handleEdit(carrierAssignment);
            }}
          />
        ) : null}
      </IconMenu>
    );
  }

  handleEdit = carrierAssignment => {
    const {history} = this.props;
    history.push(
      `/companies/${carrierAssignment.companyId}/carrier_assignment/${
        carrierAssignment.id
      }`
    );
  };

  handleArchive = carrierAssignment => {
    const {deleteCompanyShippingCarrierAssignment} = this.props;
    deleteCompanyShippingCarrierAssignment(
      carrierAssignment.companyId,
      carrierAssignment.id
    );
  };

  handleMakeDefault = carrierAssignment => {
    const {patchCompanyShippingCarrierAssignment} = this.props;
    patchCompanyShippingCarrierAssignment(
      carrierAssignment.companyId,
      carrierAssignment.id,
      {
        isDefault: true,
      }
    );
  };

  _renderEmptyState() {
    return (
      <div
        className="flex flex-column align-items-center"
        style={{
          minHeight: "82px",
        }}
      >
        <h2 className="userindex_noUsers">No carrier assignment yet.</h2>
      </div>
    );
  }

  render() {
    const header = [
      {
        columnContent: "CARRIER NAME",
        customStyles: {
          width: "25%",
        },
      },
      {
        columnContent: "ACCOUNT OWNER",
        customStyles: {
          width: "24%",
        },
      },
      {
        columnContent: "ACCOUNT NUMBER",
        customStyles: {
          width: "24%",
        },
      },
      {
        columnContent: "DEFAULT",
        customStyles: {
          width: "18%",
        },
      },
      {
        columnContent: "",
        customStyles: {
          width: 80,
        },
      },
    ];

    const {carrierAssignments, isLoading} = this.props;

    if (!carrierAssignments.length && !isLoading) {
      return this._renderEmptyState();
    }

    return (
      <React.Fragment>
        <CNGTable
          className="carrier-assingment-table"
          tableHeader={header}
          displayRowCheckbox={false}
          isLoading={isLoading}
        >
          {carrierAssignments &&
            lodash.orderBy(carrierAssignments, ["id"]).map((row, index) => {
              return this._renderRow(row, index);
            })}
        </CNGTable>
      </React.Fragment>
    );
  }
}

export default CarrierAssignmentTable;
