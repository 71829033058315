import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import FormattedPhone from "../../shared/FormattedPhone";
import {getFormattedDateAndTime} from "../../../utils/dates";

moment.relativeTimeThreshold("h", 24 * 26);

class OpenCartsTable extends Component {
  static propTypes = {
    orders: PropTypes.array,
    isLoading: PropTypes.bool,
    ordersCount: PropTypes.number,
    minHeight: PropTypes.string,
  };

  _renderRow = (order, index) => {
    let {isDsod, company, createdUser, createdAt} = order;
    if (createdAt) createdAt = new Date(createdAt);
    return (
      <CNGTableRow
        key={index}
        styles={{
          marginTop: 8,
        }}
      >
        <CNGTableRowColumn
          highlight
          customStyles={{
            width: "9%",
          }}
        >
          {company && (
            <Link
              to={`/companies/${order.companyId}/${
                isDsod ? "dsod" : "reorders"
              }`}
              className="no-link-styles"
              title={company.name}
            >
              {isDsod ? "DSOD" : "Order"}
            </Link>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          highlight
          customStyles={{
            width: "14%",
          }}
        >
          {company && (
            <Link
              to={`/companies/${order.companyId}`}
              className="no-link-styles"
              title={company.name}
            >
              {company.name}
            </Link>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "14%",
          }}
        >
          {createdUser && createdUser.name}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "15%",
          }}
        >
          {createdUser && createdUser.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "14%",
          }}
        >
          {createdUser && (
            <FormattedPhone
              phoneNumber={createdUser.phoneNumber}
              countryCode={createdUser.country && createdUser.country.code}
            />
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "20%",
          }}
        >
          {getFormattedDateAndTime(createdAt)}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "14%",
          }}
        >
          {moment(createdAt).fromNow()}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };
  render() {
    const header = [
      {
        columnContent: "Cart",
        customStyles: {
          width: "9%",
        },
      },
      {
        columnContent: "Company",
        customStyles: {
          width: "14%",
        },
      },
      {
        columnContent: "User",
        customStyles: {
          width: "14%",
        },
      },
      {
        columnContent: "E-mail",
        customStyles: {
          width: "15%",
        },
      },
      {
        columnContent: "Phone",
        customStyles: {
          width: "14%",
        },
      },
      {
        columnContent: "Open Since",
        customStyles: {
          width: "20%",
        },
      },
      {
        columnContent: "Duration",
        customStyles: {
          width: "14%",
        },
      },
    ];
    const {orders, ordersCount, isLoading} = this.props;

    return (
      <div>
        <div
          style={{
            minHeight: ordersCount > 10 ? "560px" : null,
          }}
          className={ordersCount === 0 ? "hidden" : ""}
        >
          <CNGTable
            tableHeader={header}
            displayRowCheckbox={false}
            isLoading={isLoading}
          >
            {orders.map(this._renderRow)}
          </CNGTable>
        </div>

        {ordersCount === 0 ? (
          <h2 className="userindex_noUsers">
            There are no order in process at this time.
          </h2>
        ) : null}
      </div>
    );
  }
}

export default OpenCartsTable;
