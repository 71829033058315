import React, {Component} from "react";
import PropTypes from "prop-types";

import Note from "./Note";
import AddNoteButton from "./AddNoteButton";
import AddNoteForm from "./AddNoteForm";
import {orderBy} from "lodash";
import ContentLoader from "../ContentLoader";

export default class Notes extends Component {
  static propTypes = {
    createNote: PropTypes.func.isRequired,
    userDetail: PropTypes.object,
    company: PropTypes.object,
    notes: PropTypes.array,
    deleteNote: PropTypes.func.isRequired,
    loggedInUserId: PropTypes.number,
    isLoading: PropTypes.bool,
  };
  state = {
    editingNewNote: false,
    noteDeletionRequested: false,
    newNoteRequested: false,
    newNote: "",
  };

  componentWillReceiveProps() {
    this._evaluateLoadingState(...arguments);
  }

  _evaluateLoadingState(nextProps) {
    const {isLoading: nextIsLoading} = nextProps;
    const {isLoading} = this.props;
    const {newNoteRequested, noteDeletionRequested} = this.state;
    const loadingCompleted = isLoading && !nextIsLoading;
    if (newNoteRequested && loadingCompleted) {
      this.setState({
        editingNewNote: false,
        newNote: "",
        newNoteRequested: false,
      });
    } else if (noteDeletionRequested && loadingCompleted) {
      this.setState({noteDeletionRequested: false});
    }
  }

  handleChange(e, value) {
    let name = e.target.name;

    this.setState({[name]: value});
  }
  createNote() {
    this.setState({editingNewNote: true});
  }
  closeNewNote() {
    this.setState({editingNewNote: false, newNote: ""});
  }
  addNewNote() {
    const {company, createNote} = this.props;
    const newNote = company
      ? this._getCompanyNewNote()
      : this._getUserNewNote();
    createNote(newNote);
    this.setState({newNoteRequested: true});
  }

  _getCompanyNewNote() {
    const {userDetail} = this.props;
    return {company_id: userDetail.id, content: this.state.newNote};
  }

  _getUserNewNote() {
    const {userDetail, loggedInUserId} = this.props;
    return {
      user_id: userDetail.id,
      content: this.state.newNote,
      created_user_id: loggedInUserId,
    };
  }

  _deleteNoteHandler(noteId) {
    const {deleteNote} = this.props;
    this.setState(
      {
        noteDeletionRequested: noteId,
      },
      () => deleteNote(noteId)
    );
  }

  _getContentLoaderStyles() {
    return {
      background: "#fbfbfb",
      marginRight: "16px",
      marginBottom: "16px",
      border: "1px solid rgba(189, 198, 207, 0.07)",
    };
  }

  render() {
    const {notes, userDetail, isLoading} = this.props;
    const {
      newNoteRequested,
      noteDeletionRequested,
      editingNewNote,
    } = this.state;
    return (
      <div
        className="flex flex-row flex-wrap"
        style={{
          minHeight: "222px",
        }}
      >
        <ContentLoader
          isLoading={isLoading && newNoteRequested}
          customContainerStyles={this._getContentLoaderStyles()}
        >
          {this.state.editingNewNote ? (
            <AddNoteForm
              handleChange={(e, value) => this.handleChange(e, value)}
              addNewNote={() => this.addNewNote()}
              closeNewNote={() => this.closeNewNote()}
              newNote={this.state.newNote}
            />
          ) : (
            <AddNoteButton
              disabled={isLoading && noteDeletionRequested}
              createNote={() => this.createNote()}
            />
          )}
        </ContentLoader>
        {notes &&
          orderBy(notes, "createdAt", "desc").map(note => {
            return (
              <ContentLoader
                key={`note_loader_${note.id}`}
                isLoading={
                  isLoading &&
                  noteDeletionRequested &&
                  noteDeletionRequested === note.id
                }
                customContainerStyles={this._getContentLoaderStyles()}
              >
                <Note
                  disabled={editingNewNote}
                  key={note.id}
                  note={note}
                  user={userDetail}
                  deleteNote={id => this._deleteNoteHandler(id)}
                />
              </ContentLoader>
            );
          })}
      </div>
    );
  }
}
