import React, {Component} from "react";
import PropTypes from "prop-types";

export default class NoteButton extends Component {
  static propTypes = {
    createNote: PropTypes.func,
    disabled: PropTypes.bool,
  };
  render() {
    const {disabled} = this.props;
    return (
      <div
        className={`note note-button ${
          disabled ? "disabled-button disabled-link" : ""
        }`}
        onClick={() => this.props.createNote()}
      >
        <img src="/assets/imgs/icons/add.svg" className="note-add-btn" />
        <div className="note-btn-text">NEW NOTE</div>
      </div>
    );
  }
}
