import React from "react";

import TextField from "material-ui/TextField";

import {formStyles} from "../../styles/components/formStyles";
import EditInputAttribute from "./EditInputAttribute";
import NumberFormat from "react-number-format";

const customerFieldStyles = {
  color: "#747D86",
};
const customerInputStyles = {
  width: "100%",
  fontSize: 32,
};

export default class EditInputAttributeWithoutIcon extends EditInputAttribute {
  _renderEditingCustomerMessage() {
    const {
      containerStyle,
      textStyle,
      fieldStyle,
      notFocus,
      withCurrencyFormat,
    } = this.props;

    return (
      <div className="row between-xs w-100">
        <form
          style={{width: "90%", ...containerStyle}}
          onSubmit={this.saveHandler}
        >
          {withCurrencyFormat ? (
            <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              customInput={TextField}
              type="text"
              name="customer_message"
              value={this.state.text}
              onValueChange={(values, event) => {
                this._updateInputHandler(event, values.value);
              }}
              style={{...customerFieldStyles, width: "100%", ...fieldStyle}}
              inputStyle={{...customerInputStyles, ...textStyle}}
              floatingLabelStyle={formStyles.TextFieldLabelStyles}
              underlineStyle={formStyles.underlineStyle}
              autoFocus={notFocus ? null : true}
            />
          ) : (
            <TextField
              type="text"
              name="customer_message"
              value={this.state.text}
              onChange={this._updateInputHandler}
              style={{...customerFieldStyles, width: "100%", ...fieldStyle}}
              inputStyle={{...customerInputStyles, ...textStyle}}
              floatingLabelStyle={formStyles.TextFieldLabelStyles}
              underlineStyle={formStyles.underlineStyle}
              autoFocus={notFocus ? null : true}
            />
          )}
        </form>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editMode !== this.props.editMode) {
      this.setState({editMode: this.props.editMode});
    }
  }

  render() {
    const {editMode} = this.state;
    const {width} = this.props;
    return (
      <div style={{width}}>
        {editMode
          ? this._renderEditingCustomerMessage()
          : this._renderMessageEditButton()}
      </div>
    );
  }
}
