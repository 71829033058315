import typography from "./typography";
import colors from "./colors";
export default {
  typography,
  colors,
  spacing: {},
  icon: {
    marginBottom: 4,
    marginLeft: 15,
  },
  dropdownIcon: {
    fill: "#929DA8",
  },
  underlineDisabledStyle: {
    borderBottomWidth: 1,
  },
  tab: {
    fontSize: 12,
    fontWeight: "bold",
    transition: "600ms color linear",
  },
};
