import React, {Component} from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import classNames from "classnames";

import Pagination from "../../shared/Pagination";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../../shared/PageComponents/PageIndexHeader";
import UserIndexTable from "./components/UserIndexTable";
import {hasQueryParamsChanged} from "../../../utils/indexHelper";

export default class UsersIndexPage extends Component {
  static propTypes = {
    setSearchTerm: PropTypes.func,
    searchTerm: PropTypes.string,
    users: PropTypes.array,
    fetchUser: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchUsersByCompany: PropTypes.func,
    searchUsers: PropTypes.func.isRequired,
    userCount: PropTypes.number,
    currentPage: PropTypes.number,
    isLoading: PropTypes.bool,
    page: PropTypes.number,
    setSort: PropTypes.func,
    setPage: PropTypes.func,
    sortDirection: PropTypes.string,
    sortBy: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this._searchUser();
  }

  componentDidUpdate(prevProps) {
    if (hasQueryParamsChanged(this.props, prevProps)) {
      this._searchUser();
    }
  }

  _searchUser() {
    const {
      fetchUsers,
      searchUsers,
      searchTerm,
      sortBy,
      sortDirection,
      page,
    } = this.props;

    const orderBy = this._getOrderBy(sortBy || "fullname", sortDirection);

    const options = {
      orderBy,
      orderDirection: sortDirection,
      page: page,
      perPage: 7,
    };

    if (searchTerm === "") {
      fetchUsers(options);
      return;
    }

    searchUsers(searchTerm, options);
  }

  _sortRowsBy(sortAttribute) {
    this.props.setSort(sortAttribute);
  }

  _getOrderBy(sortAttribute, direction) {
    switch (sortAttribute) {
    case "fullname":
      return JSON.stringify([
        {
          field: "first_name",
          direction,
        },
      ]);
    case "address":
      return JSON.stringify([
        {
          field: "address_1",
          direction,
        },
        {
          field: "address_2",
          direction,
        },
        {
          field: "zip_code",
          direction,
        },
        {
          field: "state.name",
          direction,
          relationship: "state",
        },
        {
          field: "country.name",
          direction,
          relationship: "country",
        },
      ]);
    default:
      return sortAttribute;
    }
  }

  searchUsers(e, value) {
    this.props.setSearchTerm(value);
  }

  _onPageChangeHandler(options) {
    const {setPage} = this.props;
    setPage(options.page);
  }

  _renderPagination() {
    return (
      <div className="userindex_pagination">
        <Pagination
          getData={options => this._onPageChangeHandler(options)}
          count={this.props.userCount}
          currentPage={this.props.page - 1}
          perPage={7}
        />
      </div>
    );
  }

  render() {
    const {
      users,
      fetchUsers,
      fetchUsersByCompany,
      isLoading,
      searchTerm,
      userCount,
    } = this.props;
    const nonContent = (users && users.length < 1) || !Array.isArray(users);

    return (
      <PageLayout isLoading={userCount && isLoading} loaderEnable={true}>
        <PageIndexHeader
          containerClass="userindex_top"
          title="Users"
          titleIcon="users"
          searchFunc={debounce(this.searchUsers.bind(this), 300, {
            leading: false,
            trailing: true,
          })}
          disableSearch={users && users.length < 1 && !searchTerm}
          createPage="/users/create"
          buttonIcon="adduser"
          buttonText="CREATE USER"
          searchTerm={searchTerm}
        />{" "}
        <h2
          className={classNames("userindex_noUsers", {
            hidden: userCount > 0,
          })}
        >
          Sorry, no results found for {`"${searchTerm}"`}
        </h2>
        <div className={classNames({hidden: nonContent})}>
          <UserIndexTable
            users={users}
            isLoading={isLoading}
            fetchUsers={fetchUsers}
            fetchUsersByCompany={fetchUsersByCompany}
            sortRowsBy={sortAttribute => this._sortRowsBy(sortAttribute)}
          />{" "}
          {this._renderPagination()}
        </div>
      </PageLayout>
    );
  }
}
