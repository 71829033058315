import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontIcon from "material-ui/FontIcon";
import SelectField from "material-ui/SelectField";
import MenuItem from "../../../../shared/CNGMenuItem";
import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";
import ColorSwatch from "./ColorSwatch";

class ChooseFabriColor extends Component {
  async componentDidMount() {
    const {
      item: {product: {id}, fabric, color},
      onChange,
      fetchFabrics,
      fetchColors,
    } = this.props;
    const {data: fabrics} = await fetchFabrics({
      filter: `productFabricPricing.product_id,eq,${id}`,
      delay: 300,
    });
    if (!fabric && fabrics.length) {
      onChange("fabric")({target: {}}, fabrics[0]);
      const filter = `fabricAvailableColor.active,eq,true|fabricAvailableColor.fabric_id,eq,${
        fabrics[0].id
      }`;
      const {data: colors} = await fetchColors({
        filter,
        sort: "name",
        sortDirection: "asc",
        delay: 300,
        include: "[fabricAvailableColor]",
      });
      if (!color && colors.length === 1) {
        onChange("color")({target: {}}, colors[0]);
      }
    }
  }

  renderOptions = options => {
    return options.map(option => (
      <MenuItem key={option.id} value={option.id} primaryText={option.name} />
    ));
  };

  handleFabricChange = (event, index, value) => {
    const {fabrics, onChange, fetchColors} = this.props;
    const fabric = fabrics[index];
    onChange("fabric")({target: {}}, fabric);
    onChange("color")({target: {}}, null);
    const filters = [
      ["fabricAvailableColor.active","eq",true],
    ];
    if (value) {
      filters.push(["fabricAvailableColor.fabric_id","eq",value]);
    }
    fetchColors({
      filter: filters.join("|"),
      sort: "name",
      sortDirection: "asc",
      delay: 300,
      include: "[fabricAvailableColor]",
    });
  };

  render() {
    const {
      item,
      onChange,
      pageStyle,
      fabrics,
      colors,
      isLoadingFabrics,
      isLoadingColors,
    } = this.props;
    const isLoading = isLoadingFabrics || isLoadingColors;
    return (
      <Fragment>
        <Typography variant="h1" style={pageStyle.title}>
          Choose Fabric and Color
        </Typography>
        <SelectField
          floatingLabelFixed
          floatingLabelText="Fabric"
          onChange={this.handleFabricChange}
          value={item.fabric && item.fabric.id}
          iconStyle={pageStyle.dropdownIcon}
          menuStyle={{borderRadius: "0px !important"}}
          className={classNames({loading: isLoadingFabrics})}
        >
          {this.renderOptions(fabrics)}
        </SelectField>
        <br />
        <FontIcon
          className="material-icons"
          title="Choose any color: color choice does not affect product cost."
          style={{
            color: "#0c5371",
            fontSize: 18,
          }}
        >
          help
        </FontIcon>
        <div className="colors-list">
          {colors.map(color => (
            <ColorSwatch
              key={color.id}
              color={color}
              isLoading={isLoading}
              onChange={onChange("color")}
              item={item}
              pageStyle={pageStyle}
            />
          ))}
          {!isLoading &&
            colors.length === 0 && (
            <Typography variant="h2" style={{marginLeft: 16}}>
                There are no colors for this fabric and product combination yet.
            </Typography>
          )}
          {isLoading &&
            colors.length === 0 &&
            [0, 1, 2, 3, 4, 5, 6, 7].map(_ => (
              <div key={_} className="item color loading">
                <div className="item-container">
                  <div className="item-image color">
                    <CNGImage style={pageStyle.image} />
                  </div>
                </div>
                <Typography variant="h5" style={pageStyle.name}>
                  {_}
                </Typography>
              </div>
            ))}
        </div>
      </Fragment>
    );
  }
}

ChooseFabriColor.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fabrics: PropTypes.array,
  colors: PropTypes.array,
  isLoadingFabrics: PropTypes.bool,
  isLoadingColors: PropTypes.bool,
  pageStyle: PropTypes.object,
};

export default ChooseFabriColor;
