import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import AuthFooter from "../shared/Footer/AuthFooter";
import CircularProgress from "material-ui/CircularProgress";
import {
  TextFieldStyles,
  TextInputStyles,
  TextFieldLabelStyles,
  formStyles,
} from "../../styles/components/formStyles";

export default class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    showAlerts: false,
  };

  handleChange(target) {
    this.setState({
      [target.name]: target.value,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error_message) {
      this.setState({showAlerts: true});
    }
  }

  componentDidMount() {
    window.closeSplash();
  }

  _handleSubmitOnEnterKey(event) {
    if (event.key === "Enter") {
      this._authenticateUser();
    }
  }

  _authenticateUser() {
    const {email, password} = this.state;
    this.props.login(email, password);
  }

  render() {
    return (
      <div className="bg-white auth-content">
        <div className="app-body auth-page-body bg-white">
          <div className="auth-page_top">
            <img
              className="auth-page_top_img"
              src="./assets/imgs/Icon@2x.png"
            />
          </div>

          <div className="auth-page_mid">
            <img
              className="auth-page_chillLogo"
              src="./assets/imgs/ChillNGo.svg"
            />

            <div className="auth-page_input">
              <TextField
                floatingLabelText="Email"
                type="text"
                name="email"
                onChange={({target}) => this.handleChange(target)}
                style={TextFieldStyles}
                inputStyle={TextInputStyles}
                floatingLabelStyle={TextFieldLabelStyles}
                underlineStyle={formStyles.underlineStyle}
              />
            </div>

            <TextField
              floatingLabelText="Password"
              type="password"
              name="password"
              style={TextFieldStyles}
              inputStyle={TextInputStyles}
              floatingLabelStyle={TextFieldLabelStyles}
              underlineStyle={formStyles.underlineStyle}
              onChange={({target}) => this.handleChange(target)}
              onKeyPress={event => this._handleSubmitOnEnterKey(event)}
            />
            <div className="auth-page-btn-container flex flex-column align-items-center">
              <button
                disabled={this.props.loading}
                className="chill-button btn-1 auth-page_btn"
                onClick={() => this._authenticateUser()}
              >
                {this.props.loading ? (
                  <div>
                    <div className="auth-button-progress-container">
                      <CircularProgress
                        size={24}
                        thickness={4}
                        color="rgba(255, 255, 255, 0.5)"
                      />
                    </div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  <div className="auth-page_btn-icon-label-wrapper">
                    <img
                      src="../assets/imgs/icons/login.svg"
                      className="auth-page_btn-icon mr-1"
                    />
                    <span>LOG IN</span>
                  </div>
                )}
              </button>
              {this.state.showAlerts && (
                <div className="error-text auth-page_error">
                  {this.props.error_message}
                </div>
              )}
            </div>

            <div className="auth-page_forgot">
              <Link to="/forgot" className="link-text">
                Forgot your Password?
              </Link>
            </div>
          </div>
        </div>
        <AuthFooter />
      </div>
    );
  }
}

LoginPage.propTypes = {
  loading: PropTypes.bool,
  login: PropTypes.func.isRequired,
  error_message: PropTypes.string,
};
