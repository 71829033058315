import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {
  updateUserCompanyAssociation,
  resetWelcomeEmail,
} from "../../../../../actions/userActions";
import {fetchCompany} from "../../../../../actions/companyActions";
import IconMenu from "material-ui/IconMenu";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import IconButton from "material-ui/IconButton";
import lodash from "lodash";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import FormattedPhone from "../../../../shared/FormattedPhone";
import ContentLoader from "../../../../shared/ContentLoader";
import FormattedAddress from "../../../../shared/FormattedAddress";

import {Link} from "react-router-dom";

class UsersTable extends Component {
  static propTypes = {
    archiveUserCompany: PropTypes.func,
    users: PropTypes.array,
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    company: PropTypes.object,
    isInternal: PropTypes.bool,
    searching: PropTypes.bool.isRequired,
    currentUserId: PropTypes.number,
    isLoading: PropTypes.bool,
    isUserLoading: PropTypes.bool,
    updateUserCompanyAssociation: PropTypes.func,
    updateCompany: PropTypes.func,
    searchUsers: PropTypes.func,
    fetchCompany: PropTypes.func,
    resetWelcomeEmail: PropTypes.func,
  };

  state = {
    openSendConfirmation: false,
    selectedId: null,
  };

  _renderRow(data, index, usersRoutePath) {
    if (!data) return;

    const recipient = lodash.get(data, "recipient", {}) || {};
    return (
      <CNGTableRow key={index} id={`user-${data.id}`}>
        <CNGTableRowColumn highlight={true}>
          <Link
            to={`${usersRoutePath}${data.id}`}
            className="reset-link-styles"
          >
            {data.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn title={data.emailAddress}>
          {data.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <FormattedPhone
            phoneNumber={recipient.phoneNumber}
            countryCode={
              recipient.country ? recipient.country.code : recipient.countryCode
            }
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            whiteSpace: "nowrap",
            width: 210,
          }}
        >
          <FormattedAddress user={recipient} />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            whiteSpace: "nowrap",
            width: 120,
          }}
        >
          {this._isPrimary(data) ? <span>Yes</span> : ""}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          {this._renderActions(data)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _isPrimary({companyAssociations}) {
    if (!companyAssociations) return;
    const {companyId} = this.props;
    return companyAssociations.find(({companyId: company, isPrimary}) => {
      return isPrimary && Number(company) === Number(companyId);
    });
  }

  _renderActions = user => {
    const isPrimary = this._isPrimary(user);
    const {
      isInternal,
      company,
      updateCompany,
      companyId,
      currentUserId,
    } = this.props;
    const sendPrimaryUserNotification = _get(
      company,
      "sendPrimaryUserNotification"
    );
    const notificationAction = sendPrimaryUserNotification
      ? "Inactivate"
      : "Activate";

    const options = [];
    if (!isPrimary) {
      options.push(
        <React.Fragment key="internal">
          {isInternal && (
            <CNGMenuItem
              primaryText="Make primary"
              onClick={() => this.handleMakePrimaryUser(user.id)}
            />
          )}
          <CNGMenuItem
            primaryText="Archive"
            onClick={() => this.handleArchiveCompanyUser(user)}
          />
        </React.Fragment>
      );
    }

    if (isInternal) {
      options.push(
        <CNGMenuItem
          key="welcome-email"
          primaryText="Send welcome email"
          onClick={() =>
            this.setState({openSendConfirmation: true, selectedId: user.id})
          }
        />
      );
    }

    if (isPrimary && !isInternal && currentUserId === Number(user.id)) {
      options.push(
        <CNGMenuItem
          key="notification"
          primaryText={`${notificationAction} email notification`}
          onClick={() => {
            updateCompany({
              id: companyId,
              sendPrimaryUserNotification: !sendPrimaryUserNotification,
            });
          }}
        />
      );
    }
    if (options.length === 0) return null;
    return (
      <IconMenu
        iconButtonElement={
          <IconButton>
            <MoreVertIcon color="#0C5371" />
          </IconButton>
        }
      >
        {options}
      </IconMenu>
    );
  };

  handleArchiveCompanyUser = user => {
    const {companyId, archiveUserCompany, searchUsers} = this.props;

    archiveUserCompany(companyId, user.id, () => {
      searchUsers();
    });
  };

  handleMakePrimaryUser = userId => {
    const {
      companyId,
      updateUserCompanyAssociation,
      fetchCompany,
      searchUsers,
    } = this.props;
    updateUserCompanyAssociation(companyId, userId, {isPrimary: true}, () => {
      searchUsers();
      fetchCompany(companyId);
    });
  };

  emptyResults() {
    const {users} = this.props;
    return (users && users.length < 1) || (users && users.results);
  }

  _renderEmptyState() {
    const {isLoading, searching} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <div
          className="flex flex-column align-items-center"
          style={{
            minHeight: "82px",
          }}
        >
          <h2 className="userindex_noUsers">
            No users {searching ? "found" : "yet"}.
          </h2>
        </div>
      </ContentLoader>
    );
  }

  render() {
    const header = [
      "NAME",
      "E-MAIL",
      "PHONE",
      "ADDRESS",
      {
        columnContent: "IS PRIMARY",
        customStyles: {
          width: 120,
        },
      },
      {
        customStyles: {
          width: 100,
        },
      },
    ];
    const {users, companyId, isLoading} = this.props;
    if (this.emptyResults()) {
      return this._renderEmptyState();
    }
    const usersRoutePath = `/companies/${companyId}/users/`;
    return (
      <CNGTable
        className="users-table"
        tableHeader={header}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {users &&
          users.map((row, index) =>
            this._renderRow(row, index, usersRoutePath)
          )}

        <ConfirmationModal
          title={
            "This action will reset user password, do you want to continue?"
          }
          confirmText="Yes"
          cancelText="No"
          open={this.state.openSendConfirmation}
          onConfirm={() => {
            this.props.resetWelcomeEmail(this.state.selectedId);
            this.setState({
              selectedId: null,
              openSendConfirmation: false,
            });
          }}
          onDismiss={() => {
            this.setState({openSendConfirmation: false});
          }}
          height={250}
        />
      </CNGTable>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserLoading: state.users.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    resetWelcomeEmail: bindActionCreators(resetWelcomeEmail, dispatch),
    updateUserCompanyAssociation: bindActionCreators(
      updateUserCompanyAssociation,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
