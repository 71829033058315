import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import ContactForm from "./Components/ContactForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";

export default class ContactCreatePage extends Component {
  static propTypes = {
    router: PropTypes.object,
    createCompanyContact: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    company: PropTypes.object,
    isLoading: PropTypes.bool,
  };
  state = {
    companyName: "",
    name: "",
    phoneNumber: "",
    emailAddress: "",
    address1: "",
    address2: "",
    city: "",
    state: null,
    zipCode: null,
    countryId: null,
    submitted: false,
  };

  companyUrl = () =>
    this.props.company && `/companies/${this.props.company.id}?tab=recipients`;

  componentWillReceiveProps() {
    this._evaluatePageState(...arguments);
    this._setDefaultCountry(...arguments);
  }

  _setDefaultCountry(nextProps) {
    const {countries} = nextProps;
    const {countryId: currentCountryId} = this.state;
    if (currentCountryId) {
      return;
    }
    const defaultCountry =
      countries.length > 0
        ? countries.find(country => ["USA", "US"].includes(country.code))
        : null;
    if (defaultCountry) {
      this.setState({
        countryId: Number(defaultCountry.id),
      });
    }
  }

  async _evaluatePageState(nextProps) {
    const {error: nextError, isLoading: nextIsLoading} = nextProps;
    const {isLoading, navigateTo} = this.props;
    if (!nextError && !nextIsLoading && isLoading) {
      navigateTo(this.companyUrl() || "/");
    }
  }

  async createContact() {
    const {createCompanyContact, company} = this.props;
    const {address1, address2, ...contactProps} = this.state;
    delete contactProps.submitted;
    const contact = {
      company_id: company.id,
      address_1: address1,
      address_2: address2,
      ...contactProps,
    };
    createCompanyContact(contact);
  }

  handleChange(e, index, value, formName) {
    const name = formName || e.target.name;
    if (!value && formName !== "state") {
      value = index;
    }
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {company, countries, isLoading} = this.props;
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
        subHeaderText={company ? company.name : null}
      >
        <BreadCrumbs
          rootName="Companies"
          middle={company && company.name}
          middleLink={company && company.id}
          middletwo="Address Book"
          middletwoLink="#contacts"
          pageName="Add Recipient"
        />
        <Card>
          <div>
            <FormCardHeader
              title="Make New Recipient"
              cancelLink={this.companyUrl() || "/"}
              buttonText="CREATE"
              buttonIcon="check"
              buttonClick={() => this.setState({submitted: true})}
            />
            <Divider marginBottom={24} marginTop={24} />
            <ContactForm
              handleChange={(e, index, value, formName) =>
                this.handleChange(e, index, value, formName)
              }
              submitSuccess={() => this.createContact()}
              submitError={() => this.setState({submitted: false})}
              countries={countries}
              {...this.state}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
