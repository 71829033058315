import React, {Component} from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import Checkbox from "material-ui/Checkbox";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _defaultTo from "lodash/defaultTo";

import {
  formStyles,
  TextFieldSmallLabelStyles,
  SelectFieldIconStyle,
} from "../../../../styles/components/formStyles";

import SelectField from "material-ui/SelectField";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import TextField from "material-ui/TextField";

import ValidableTextField from "../../../shared/ValidableTextField";
import CNGDatePicker from "../../../shared/CNGDatePicker";
import OrderShipmentForm from "../../Orders/components/OrderShipmentForm";

import {validateForm} from "../../../../utils/formValidations";
import {getTodayDate} from "../../../../utils/dates";

const formInputStyle = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 23,
};

const smallInputStyles = {
  ...formStyles.HalfFieldStyles,
  marginRight: 23,
  width: 120,
};

const artForm = {
  marginTop: 23,
  marginBottom: 40,
};

const trackingInputStyle = {
  marginBottom: 12,
};

const TODAY = getTodayDate();

class ReorderForm extends Component {
  constructor(props) {
    super(props);
    this.validations = {
      purchaseOrderNumber: {
        format: "any",
      },
    };

    if (props.isInternal) {
      this.validations = {
        jobName: {
          format: "any",
        },
        purchaseOrderNumber: {
          format: "any",
        },
      };
    }
  }

  state = {
    errors: {},
    submitted: false,
    addedWalletUsers: [],
    renderBillTo: true,
  };

  componentDidUpdate(prevProps){
    if (prevProps.errors !== this.props.errors) {
      this._handleError();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {submitted} = this.state;
    this.setState(
      {
        submitted: nextProps.submitted,
      },
      () => {
        if (nextProps.submitted && !submitted) {
          this.triggerValidations();
        }
      }
    );
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    if (!valid) return submitError();
    submitSuccess();
  }
  
  _handleError = () => {
    const {errors} = this.props;
    this.setState({errors});
  }

  handleChangeWithMaxLength = (event, value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(event, value);
    }
  };

  _validate = () => {
    const errors = validateForm(this, this.props.orderData);
    this.setState({errors});

    return errors;
  };

  _validateDebounced = _debounce(this._validate, 300);

  _getCompanyNonWalletUsersDataSource() {
    if (!this.props.company) return [];
    const {company: {contacts}} = this.props;
    const walletMembers = this._totalBillingContacts();
    return (
      contacts &&
      contacts
        .filter(
          contact =>
            !walletMembers.find(({id}) => parseInt(id) === parseInt(contact.id))
        )
        .map(({id, name}) => {
          return {text: name, value: id};
        })
    );
  }

  async createWallet(companyContactId) {
    const {company, createWalletMember} = this.props;
    const {onShipmentChange, onSaveDraft} = this.props;
    const {contacts} = company;
    const newCompanyContact = contacts.find(
      ({id}) => Number(id) === Number(companyContactId)
    );

    if (companyContactId) {
      const data = {
        company_contact_id: parseInt(companyContactId),
        isWalletMember: true,
      };
      createWalletMember(company.id, data, () => {
        this.setState(
          {
            addedWalletUsers: [
              ...this.state.addedWalletUsers,
              newCompanyContact,
            ],
          },
          () => {
            onShipmentChange(
              null,
              companyContactId,
              "billing_company_contact_id",
              () => {
                onSaveDraft();
                this.setState(
                  {
                    renderBillTo: false,
                  },
                  () => {
                    this.setState({renderBillTo: true});
                  }
                );
              }
            );
          }
        );
      });
    }
  }

  _addUserToWalletAndChoose({value: companyContactId}, index) {
    if (index != -1 && companyContactId) {
      this.createWallet(companyContactId);
    }
  }

  _totalBillingContacts() {
    const {billingCompanyContacts} = this.props;
    const recipients = _defaultTo(billingCompanyContacts, []).map(
      ({companyContact}) => companyContact
    );
    return recipients
      .concat(this.state.addedWalletUsers)
      .sort((contact1, contact2) => contact1.name.localeCompare(contact2.name));
  }

  hasCopyToInvoiceActive = () => {
    const {orderData: {billingCompanyContactId, createdUserId}} = this.props;
    const billingCompanyContacts = this._totalBillingContacts();
    if (billingCompanyContactId) {
      const billingCompanyContact = billingCompanyContacts.find(
        contact => contact.id == billingCompanyContactId
      );
      if (
        billingCompanyContact &&
        billingCompanyContact.userId == createdUserId
      )
        return false;
    }

    return true;
  };

  render() {
    const {
      onOrderChange,
      onShipmentChange,
      onSaveDraft,
      orderData,
      order,
      shipmentData,
      shipments,
      shippingMethods,
      companyShippingCarrierAssignments,
      isInternal,
      isDisabled,
      company,
      paymentTerms,
      sameRecipient,
      onShippingAccountNone,
      onToggleProofCheckboxes,
      errors,
      clearError,
    } = this.props;

    const billingCompanyContacts = this._totalBillingContacts();
    const shipment =
      (shipments && shipments.length > 0 && shipments[0]) || null;
    const preProductionRequested = _get(orderData, "preProductionRequested");
    const sewOutRequested = _get(orderData, "sewOutRequested");

    return (
      <div className="order-form " style={artForm}>
        <div style={trackingInputStyle} className="row">
          <TextField
            floatingLabelText="CNG Ref #"
            type="text"
            value={(order && order.cngReferenceNumber) || ""}
            disabled={true}
            underlineShow={false}
            style={formStyles.SmallInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            onBlur={onSaveDraft}
          />{" "}
          {isInternal && (
            <ValidableTextField
              label="Job Name *"
              type="text"
              name="jobName"
              value={orderData.jobName || ""}
              errorText={this.state.errors.jobName}
              handleChange={(event, value) =>
                this.handleChangeWithMaxLength(event, value, onOrderChange, 20)
              }
              onBlur={onSaveDraft}
              style={formInputStyle}
              floatingLabelStyle={TextFieldSmallLabelStyles}
              underlineStyle={formStyles.underlineStyle}
              validate={() => this._validateDebounced()}
              autoFocus={true}
              disabled={isDisabled()}
            />
          )}
          <ValidableTextField
            label="Customer PO # *"
            type="text"
            name="purchaseOrderNumber"
            value={orderData.purchaseOrderNumber || ""}
            errorText={this.state.errors.purchaseOrderNumber}
            handleChange={(event, value) =>
              this.handleChangeWithMaxLength(event, value, onOrderChange, 17)
            }
            onBlur={onSaveDraft}
            style={formInputStyle}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            validate={() => this._validateDebounced()}
            autoFocus={!isInternal}
            disabled={isDisabled()}
          />
          <CNGDatePicker
            floatingLabelText="PO Date"
            name="PODate"
            value={orderData.purchaseOrderDate}
            onChange={(event, value) =>
              onOrderChange(event, value, "purchaseOrderDate", onSaveDraft)
            }
            style={{
              ...smallInputStyles,
              display: "inline-block",
              marginRight: 26,
            }}
            datePickerStyle={formStyles.SmallInputStyles}
            textFieldStyle={formStyles.SmallInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            disabled={isDisabled()}
          />{" "}
          <CNGDatePicker
            showClear
            floatingLabelText="In-Hand Date"
            name="inHandDate"
            value={orderData.inHandDate}
            onChange={(event, value) =>
              onOrderChange(event, value, "inHandDate", onSaveDraft)
            }
            style={{
              ...smallInputStyles,
              display: "inline-block",
              marginRight: 26,
            }}
            datePickerStyle={formStyles.SmallInputStyles}
            textFieldStyle={formStyles.SmallInputStyles}
            floatingLabelStyle={TextFieldSmallLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            disabled={isDisabled()}
          />{" "}
          {isInternal && (
            <CNGDatePicker
              floatingLabelText="Est. Ship Date *"
              name="estimatedShipOn"
              value={orderData.estimatedShipOn}
              onChange={(event, value) =>
                onOrderChange(event, value, "estimatedShipOn", onSaveDraft)
              }
              style={{
                ...smallInputStyles,
                display: "inline-block",
                marginRight: 26,
              }}
              datePickerStyle={formStyles.SmallInputStyles}
              textFieldStyle={formStyles.SmallInputStyles}
              floatingLabelStyle={TextFieldSmallLabelStyles}
              underlineStyle={formStyles.underlineStyle}
              minDate={TODAY}
              disabled={isDisabled()}
            />
          )}
          {isInternal && (
            <SelectField
              floatingLabelText="Requesting user*"
              floatingLabelStyle={formStyles.TextFieldLabelStyles}
              name="createdUserId"
              value={orderData && orderData.createdUserId + ""}
              onChange={(_, __, value) => {
                onOrderChange(null, value, "createdUserId", onSaveDraft);
              }}
              ref={el => (this.contactSelectRef = el)}
              style={{
                ...formInputStyle,
              }}
              inputStyle={{
                ...formStyles.HalfTextInputStyles,
                height: 68,
              }}
              iconStyle={{
                ...formStyles.HalfIconStyles,
                ...SelectFieldIconStyle,
                marginRight: 50,
              }}
              menuStyle={{
                ...formStyles.MenuStyle,
                width: 250,
              }}
              underlineStyle={formStyles.underlineStyle}
              labelStyle={{
                ...formStyles.TextFieldLabelStyles,
                color: "#4A5158",
              }}
              disabled={isDisabled()}
            >
              {((company && company.users) || []).map(companyContact => {
                return (
                  <CNGMenuItem
                    key={companyContact.id}
                    value={companyContact.id + ""}
                    primaryText={companyContact.name}
                  />
                );
              })}
            </SelectField>
          )}
        </div>
        <div className="section-header">Shipping Information</div>
        <OrderShipmentForm
          onShipmentChange={(event, value, formName) => {
            onShipmentChange(event, value, formName, onSaveDraft);
          }}
          companyShippingCarrierAssignments={companyShippingCarrierAssignments}
          shipmentData={shipmentData}
          shippingMethods={shippingMethods}
          shipment={shipment}
          showInputCost={false}
          company={company}
          onBlur={onSaveDraft}
          orderData={orderData}
          onOrderChange={onOrderChange}
          billingCompanyContacts={billingCompanyContacts}
          onSaveDraft={onSaveDraft}
          renderBillTo={this.state.renderBillTo}
          paymentTerms={paymentTerms}
          isInternal={isInternal}
          isDisabled={isDisabled}
          sameRecipient={sameRecipient}
          onShippingAccountNone={onShippingAccountNone}
          errors={errors}
          clearError={clearError}
        />
        <div className="flex justify-content-between">
          {this.hasCopyToInvoiceActive() && (
            <div className="flex align-items-center">
              <Checkbox
                label="Copy to Requesting User"
                style={{
                  display: "inline-block",
                  width: "auto",
                }}
                defaultChecked={orderData.sendCopyInvoice}
                labelStyle={{
                  width: "auto",
                }}
                onClick={event => {
                  onOrderChange(
                    null,
                    event.target.checked,
                    "sendCopyInvoice",
                    onSaveDraft
                  );
                }}
                disabled={isDisabled()}
              />
              <FontIcon
                className="material-icons"
                title="Checking this box insures that both the Billed-To entity and the Requesting User receive the invoice.  Caution: both emails will contain the payment link."
                style={{
                  fontSize: 18,
                  color: "#0C5271",
                  margin: "0 3px 0 5px",
                  fontWeight: 500,
                }}
              >
                info
              </FontIcon>
            </div>
          )}
          {order &&
            order.revertedAt && (
            <Checkbox
              label="Re-send Notification"
              style={{marginTop: 10}}
              onClick={event => {
                onOrderChange(null, event.target.checked, "sendNotification");
              }}
            />
          )}
          {isInternal ? (
            <div className="flex flex-one flex-center justify-content-around">
              <Checkbox
                disabled={isDisabled() || _get(orderData, "quote.preProductionRequested")}
                label="Pre-Production Proof"
                style={{
                  width: "auto",
                }}
                labelStyle={{
                  width: "auto",
                }}
                checked={preProductionRequested === true}
                onCheck={onToggleProofCheckboxes("preProductionRequested")}
              />
              <Checkbox
                disabled={isDisabled() || _get(orderData, "quote.sewOutRequested")}
                label="Sew-Out"
                style={{
                  width: "auto",
                }}
                labelStyle={{
                  width: "auto",
                }}
                checked={sewOutRequested === true}
                onCheck={onToggleProofCheckboxes("sewOutRequested")}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ReorderForm.propTypes = {
  isDisabled: PropTypes.func,
  onSaveDraft: PropTypes.func,
  order: PropTypes.object,
  isInternal: PropTypes.bool,
  billingCompanyContacts: PropTypes.array,
  onOrderChange: PropTypes.func,
  onShipmentChange: PropTypes.func,
  onFileChange: PropTypes.func,
  companyShippingCarrierAssignments: PropTypes.array,
  shippingMethods: PropTypes.array,
  shipments: PropTypes.array,
  orderData: PropTypes.object,
  shipmentData: PropTypes.object,
  submitSuccess: PropTypes.func,
  submitError: PropTypes.func,
  submitted: PropTypes.bool,
  company: PropTypes.object,
  createWalletMember: PropTypes.func,
  paymentTerms: PropTypes.array,
  sameRecipient: PropTypes.bool,
  onShippingAccountNone: PropTypes.func,
  onToggleProofCheckboxes: PropTypes.func,
  errors: PropTypes.object,
  clearError: PropTypes.func,
};

export default ReorderForm;
