import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "../../../shared/ChillButton";

export default class ContactDetailHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    companyContact: PropTypes.object,
    history: PropTypes.object,
    company: PropTypes.object,
  };

  handleGoToEditPage = () => {
    const {companyContact, company, history} = this.props;
    if (!companyContact || !companyContact) return;

    history.push(`/companies/${company.id}/contacts/${companyContact.id}/edit`);
  };

  renderButtons = () => {
    return (
      <ChillButton
        className="btn--formCardHeader"
        name="EDIT"
        height={32}
        width={137}
        icon="edit"
        secondary={true}
        onClick={this.handleGoToEditPage}
      />
    );
  };

  render() {
    return (
      <div className="row middle-xs between-xs">
        <h1>Recipient</h1>
        <div className="row middle-xs">{this.renderButtons()}</div>
      </div>
    );
  }
}
