import React, {Component} from "react";
import PropTypes from "prop-types";

const titleStyle = {
  color: "#1D9ABB",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "32px 0",
  textTransform: "uppercase",
};

const contentStyle = {
  display: "flex",
  padding: "0 24px",
  cursor: "pointer",
};

const imgStyle = {
  marginRight: "8px",
};

const separatorStyle = {
  flex: 1,
  borderBottom: "1px solid #bdc6cf",
  height: 0,
};

export default class LineHeader extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool,
  };

  render() {
    const {icon, title, onClick, disabled} = this.props;
    const style = Object.assign({}, titleStyle, this.props.style);
    return (
      <h5 style={style}>
        <span style={separatorStyle} />
        <span
          style={contentStyle}
          onClick={onClick}
          className={disabled ? "disabled-button" : null}
        >
          {this.props.icon ? (
            <img src={`/assets/imgs/icons/${icon}.svg`} style={imgStyle} />
          ) : null}
          {title}
        </span>
        <span style={separatorStyle} />
      </h5>
    );
  }
}
