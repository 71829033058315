import React, {Component} from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import SelectField from "material-ui/SelectField";

import SearchInput from "../../../../shared/SearchInput";
import ChillButton from "../../../../shared/ChillButton";
import Pagination from "../../../../shared/Pagination";
import QuotesTable from "./QuotesTable";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import {
  formStyles,
  SelectFieldIconStyle,
} from "../../../../../styles/components/formStyles";

class QuotesTab extends Component {
  state = {
    currentPage: {
      all: 1,
      historical: 1,
      active: 1,
    },
    selectedQuoteType: "active",
  };

  componentDidMount() {
    this.props.searchQuotes();
  }

  componentDidUpdate(prevProps) {
    if (this._hasParamsChanged(prevProps)) {
      this.props.searchQuotes();
      this.setState({selectedQuoteType: this.props.selectedQuoteType});
    }
  }

  _hasParamsChanged = prevProps => {
    const {selectedTab, selectedQuoteType} = this.props;
    const isActiveTab = selectedTab !== prevProps.selectedTab;

    const isSelectedTypeChanged =
      selectedQuoteType !== prevProps.selectedQuoteType;

    return (
      selectedTab === "quotes" &&
      (isActiveTab ||
        hasQueryParamsChanged(this.props, prevProps) ||
        isSelectedTypeChanged)
    );
  };

  handleSelectPage = options => {
    const {selectedQuoteType} = this.state;
    const {setPage} = this.props;

    setPage(options.page);
    this.setState({
      currentPage: {
        ...this.state.currentPage,
        [selectedQuoteType]: options.page,
      },
    });
  };

  handleCreateQuote = () => {
    const {history} = this.props;
    history.push("/quotes/new/info?knownUser=true");
  };

  _handleSearchOrders(nextSearchTerm) {
    const {searchTerm, setSearchTerm} = this.props;
    if (nextSearchTerm != searchTerm) {
      this.setState({currentPage: 1});
    }
    setSearchTerm(nextSearchTerm);
  }

  _handleSearchOrdersDebounced = _debounce(this._handleSearchOrders, 300);

  _handleTypeDropdownChange = value => {
    this.props.addQueryParam({selectedQuoteType: value});
  };

  _renderPagination() {
    const {quotesCount, page} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={options => this.handleSelectPage(options)}
          count={quotesCount}
          currentPage={(page || 1) - 1}
        />
      </div>
    );
  }

  _renderOrderTable() {
    const {quotes, quotesCount, isLoading, searchTerm, isInternal} = this.props;
    const {selectedQuoteType} = this.state;
    return (
      <div
        className={
          quotesCount <= 7
            ? "tab-container"
            : "tab-container tab-container__pagination"
        }
      >
        <QuotesTable
          quotes={quotes}
          quotesCount={quotesCount}
          isLoading={isLoading}
          searching={!!searchTerm || selectedQuoteType !== "active"}
          isInternal={isInternal}
        />{" "}
        {quotesCount > 7 && quotes && quotes.length
          ? this._renderPagination()
          : null}
      </div>
    );
  }

  _renderTypeDropdown() {
    const {selectedQuoteType} = this.props;

    return (
      <SelectField
        value={selectedQuoteType}
        onChange={(_, __, value) => this._handleTypeDropdownChange(value)}
        style={{
          ...formStyles.HalfFieldStyles,
          width: "auto",
          minWidth: "220px",
          marginLeft: "24px",
        }}
        iconStyle={{
          right: "-16px",
          ...SelectFieldIconStyle,
        }}
        menuStyle={formStyles.selectMenuStyle}
        underlineStyle={formStyles.underlineStyle}
        inputStyle={formStyles.inputStyle}
        floatingLabelStyle={formStyles.TextFieldLabelStyles}
      >
        <CNGMenuItem value="active" primaryText="Active Quotes" />
        <CNGMenuItem value="turned" primaryText="Turned to Orders" />
        <CNGMenuItem value="historical" primaryText="Historical Quotes" />
      </SelectField>
    );
  }

  render() {
    const {isInternal} = this.props;
    return (
      <div className="tab-container">
        <div className="tab_top">
          <div className="flex w-100">
            <SearchInput
              initialValue={this.props.searchTerm}
              search={(_, value) => this._handleSearchOrdersDebounced(value)}
            />{" "}
            {isInternal && this._renderTypeDropdown()}
            <div className="flex align-items-center w-100 flex-direction-reverse ">
              <ChillButton
                name={"Create Quote"}
                height={32}
                width={160}
                icon={"check"}
                onClick={this.handleCreateQuote}
                className="text-uppercase"
              />
            </div>
          </div>
        </div>
        {this._renderOrderTable()}
      </div>
    );
  }
}

QuotesTab.propTypes = {
  searchQuotes: PropTypes.func,
  quotes: PropTypes.array,
  quotesCount: PropTypes.number,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  setSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  setPage: PropTypes.func,
  page: PropTypes.number,
  addQueryParam: PropTypes.func,
  selectedQuoteType: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  selectedTab: PropTypes.string,
  createQuote: PropTypes.func,
  currentUserId: PropTypes.number,
};

export default QuotesTab;
