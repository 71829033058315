import React, {Component} from "react";
import PropTypes from "prop-types";

export default class PageTitle extends Component {
  static propTypes = {
    text: PropTypes.node,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginRight: PropTypes.number,
    marginLeft: PropTypes.number,
    styles: PropTypes.object,
    className: PropTypes.string,
  };

  render() {
    const {
      styles,
      text,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      className,
    } = this.props;

    return (
      <h1
        style={{
          ...pageTitleStyles,
          ...styles,
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
        }}
        className={className}
      >
        {text}
      </h1>
    );
  }
}

const pageTitleStyles = {
  color: "#0c5371",
  fontSize: 30,
  fontWeight: 300,
  lineHeight: "32px",
  margin: 0,
};
