import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import ListComma from "../../shared/ListComma";

class CompaniesTable extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
  };

  _renderBrandNames(company) {
    if (!company.brands) return;

    return company.brands.map((brand, index) => {
      return (
        <ListComma
          key={index}
          index={index}
          len={company.brands.length}
          text={brand.name}
        />
      );
    });
  }

  _renderRow(company, index) {
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn highlight={true} customStyles={{width: 250}}>
          <Link to={`/companies/${company.id}`} className="no-link-styles">
            {company.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{this._renderBrandNames(company)}</CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "Company Name",
        customStyles: {
          width: 250,
        },
      },
      {
        columnContent: "Brands",
      },
    ];
    const {companies, isLoading} = this.props;

    return (
      <CNGTable
        tableHeader={header}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {companies.map((company, index) => {
          return this._renderRow(company, index);
        })}
      </CNGTable>
    );
  }
}

export default CompaniesTable;
