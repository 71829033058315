import React, {Component} from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import UserForm from "./components/UserForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";
import {cloneDeep} from "lodash";
import {updatedDiff} from "deep-object-diff";
import serializePayloadData from "../../../utils/serializePayloadData";

const USER_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: null,
  countryId: null,
  companies: [],
  addingCompany: false,
  isInternal: false,
  submitted: false,
  password: "",
  companiesWallets: [],
  requestUserType: false,
  recipientId: "new",
};

export default class UserCreatePage extends Component {
  static propTypes = {
    contactsWithoutUser: PropTypes.array,
    router: PropTypes.object,
    countries: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    createUserWithCompanies: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    company: PropTypes.object,
    match: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    errorMessage: PropTypes.string,
    openNotification: PropTypes.func.isRequired,
    isInternal: PropTypes.bool,
    location: PropTypes.object,
  };
  state = cloneDeep(USER_INITIAL_STATE);

  _getRedirectUrl() {
    const {
      match: {params: {company_id: companyId}},
      router: {previousSearch},
    } = this.props;
    return companyId
      ? `/companies/${companyId}${previousSearch || ""}`
      : "/users";
  }

  componentWillMount() {
    const {match: {params: {company_id}}, location: {search}} = this.props;
    const recipientId = queryString.parse(search).recipientId;
    if (company_id) {
      this.setState({
        companies: [...this.state.companies, company_id],
        recipientId,
      });
    }
  }

  componentWillReceiveProps() {
    this._evaluatePageState(...arguments);
    this._setDefaultCountry(...arguments);
  }

  _setDefaultCountry(nextProps) {
    const {countries} = nextProps;
    const {countryId: currentCountryId} = this.state;
    if (currentCountryId) {
      return;
    }
    const defaultCountry =
      countries.length > 0
        ? countries.find(country => ["USA", "US"].includes(country.code))
        : null;
    if (defaultCountry) {
      this.setState({
        countryId: Number(defaultCountry.id),
      });
    }
  }

  async _evaluatePageState(nextProps) {
    const {
      error: nextError,
      errorMessage: nextErrorMessage,
      isLoading: nextIsLoading,
    } = nextProps;
    const {isLoading, navigateTo, openNotification} = this.props;
    if (!nextError && !nextIsLoading && isLoading) {
      navigateTo(this._getRedirectUrl());
    }

    if (nextError && nextErrorMessage && nextErrorMessage.length > 0) {
      if (
        nextError.response &&
        "bodyUsed" in nextError.response &&
        nextError.response.bodyUsed
      ) {
        return;
      }
      const errorResponse = await nextError.response.json();
      const isUniqueViolation =
        errorResponse &&
        errorResponse.type &&
        errorResponse.type === "UniqueViolation";
      const errorMessage = isUniqueViolation
        ? "There is already a user with the provided email address"
        : nextErrorMessage;
      openNotification(errorMessage, 6000);
      this.setState({submitted: false});
    }
  }

  async createUser() {
    const user = cloneDeep(this.state);
    if (!this._userTypeIsDefined(user)) {
      return;
    }
    delete user.requestUserType;
    const formattedPayload = serializePayloadData(user);
    this.props.createUserWithCompanies(formattedPayload);
  }

  _userTypeIsDefined(user) {
    const {isInternal, companies} = user;
    if (!isInternal && companies.length == 0) {
      this.setState({requestUserType: true, submitted: false});
      return false;
    }
    return true;
  }

  _renderGeneralErrors() {
    const {generalErrors} = this.state;
    if (!generalErrors.length) {
      return null;
    }
    return generalErrors.map((error, index) => (
      <div
        className="error-container flex flex-column"
        key={`general_error_${index}`}
      >
        <div className="error-message">{error.message}</div>
      </div>
    ));
  }

  toggleIsInternal() {
    this.setState({
      isInternal: !this.state.isInternal,
    });
  }

  toggleCompanyWallet(id) {
    let {companiesWallets} = this.state;
    companiesWallets = companiesWallets.includes(id)
      ? companiesWallets.filter(el => el != id)
      : [...companiesWallets, id];

    this.setState({companiesWallets});
  }

  handleChange(e, index, value, formName) {
    const {isLoading} = this.props;
    if (isLoading) {
      return;
    }
    const name = formName || e.target.name;
    if (!value && formName !== "state") {
      value = index;
    }
    this.setState({
      [name]: value,
    });
  }
  handleChangeCompany(e, index, value) {
    if (this.state.companies.indexOf(value) > -1) {
      return;
    }
    this.setState({
      companies: [...this.state.companies, value],
      addingCompany: false,
    });
  }
  handleDeleteCompany(id) {
    const companies = this.state.companies;
    const index = companies.indexOf(id);
    companies.splice(index, 1);
    this.setState({companies: companies});
  }

  _getUserTypeRequestDialogButtons() {
    return [
      <FlatButton
        label="Continue"
        primary={true}
        key="confirmed"
        onClick={() => this.setState({requestUserType: false})}
      />,
    ];
  }

  _evaluateSaveButtonDisabledState() {
    const diffResult = updatedDiff(this.state, USER_INITIAL_STATE);
    return !(Object.keys(diffResult).length > 0);
  }

  setContactInformation = user => {
    this.setState(user);
  };

  render() {
    const {
      match: {params: {company_id}},
      company,
      isLoading,
      isInternal,
      contactsWithoutUser,
    } = this.props;

    return (
      <PageLayout isLoading={isLoading} loaderEnable={true} smallTop={true}>
        {company_id ? (
          <BreadCrumbs
            rootName={(company && "Companies") || "Users"}
            middle={company && company.name}
            middleLink={company && company.id}
            pageName="Create New"
          />
        ) : (
          <BreadCrumbs rootName={"Users"} pageName="Create New" />
        )}
        <Card>
          <div>
            <FormCardHeader
              title="Create New User"
              cancelLink={this._getRedirectUrl()}
              buttonText="CREATE USER"
              buttonIcon="check"
              noButton={this._evaluateSaveButtonDisabledState()}
              buttonClick={() => this.setState({submitted: true})}
            />
            <Divider marginBottom={24} marginTop={24} />
            <UserForm
              contactsWithoutUser={contactsWithoutUser}
              allowPasswordAssignment={isInternal}
              handleDeleteCompany={id => this.handleDeleteCompany(id)}
              handleChangeCompany={(e, index, value) =>
                this.handleChangeCompany(e, index, value)
              }
              handleChange={(e, index, value, formName) =>
                this.handleChange(e, index, value, formName)
              }
              toggleIsInternal={() => this.toggleIsInternal()}
              toggleCompanyWallet={id => this.toggleCompanyWallet(id)}
              submitSuccess={() => this.createUser()}
              submitError={() => this.setState({submitted: false})}
              allCompanies={this.props.companies}
              countries={this.props.countries}
              companyId={company_id}
              setContactInformation={this.setContactInformation}
              {...this.state}
            />
          </div>
        </Card>
        <Dialog
          contentStyle={{
            maxWidth: "426px",
          }}
          actions={this._getUserTypeRequestDialogButtons()}
          modal={false}
          open={this.state.requestUserType}
          onRequestClose={() => this.setState({requestUserType: false})}
        >
          <p>
            The user type is not defined, select if the user is an internal user
            otherwise assign the user to a company.
          </p>
        </Dialog>
      </PageLayout>
    );
  }
}
