import React, {Component} from "react";
import PropTypes from "prop-types";
import Paper from "material-ui/Paper";

import PageLayout from "../../../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../../../shared/PageComponents/PageIndexHeader";
import Typography from "../../shared/Typography";
import ChatSystem from "../../shared/ChatSystem";
import ChatList from "./ChatList";

class ChatCenter extends Component {
  timeout = null;

  state = {
    channels: [],
    activeUserId: null,
    activeUserName: null,
    isLoading: false,
  };

  componentWillMount() {
    this.setState({isLoading: true});
  }

  async componentDidMount() {
    const {messenger} = this.props;
    if (!messenger.sb.currentUser) await messenger.init();
    this.loadChannels();
    messenger.addOnMessageReceivedHandler(
      "general",
      this.handleMessageReceived
    );
  }

  componentWillUnmount() {
    const {messenger} = this.props;
    if (this.timeout) clearTimeout(this.timeout);
    messenger.removeOnMessageReceivedHandler("general");
  }

  loadChannels = async () => {
    const {messenger, fetchChatsUnreadCounts} = this.props;
    const channels = await messenger.getChannelsList();
    let chatUnreadCounts = 0;
    const generalChannels = channels.filter(channel => {
      const data = JSON.parse(channel.data);
      const isGeneral = data.type === "general";
      if (isGeneral) chatUnreadCounts += channel.unreadMessageCount;
      return isGeneral;
    });
    fetchChatsUnreadCounts(chatUnreadCounts);
    this.setState({channels: generalChannels, isLoading: false});
  };

  handleMessageReceived = () => this.loadChannels();

  handleSelectUser = (id, name) => {
    this.setState({activeUserId: id, activeUserName: name});
    this.timeout = setTimeout(() => {
      this.loadChannels();
    }, 5000);
  };

  renderCurrentActive = () => {
    const {activeUserId, activeUserName} = this.state;
    if (!activeUserId) {
      return (
        <Typography
          variant="h4"
          className="flex flex-column flex-center"
          color="secondary.light"
        >
          Select a chat to continue a conversation
        </Typography>
      );
    }
    return (
      <ChatSystem
        largeVersion
        type="general"
        typeData={{id: activeUserId}}
        typeIdName="id"
        customTitle={
          <div
            className="w-100"
            style={{
              padding: 16,
              zIndex: 1,
              boxShadow: "rgba(0, 0, 0, 0.12) 4px 1px 6px",
            }}
          >
            <Typography variant="h5" color="secondary.light">
              {activeUserName}
            </Typography>
          </div>
        }
      />
    );
  };

  render() {
    const {channels, isLoading} = this.state;
    return (
      <PageLayout loaderEnable isLoading={isLoading}>
        <PageIndexHeader noButton noSearch disableSearch title="Chats" />{" "}
        <Paper className="flex" style={{height: 638}}>
          <div
            style={{
              width: "30%",
              zIndex: 2,
              boxShadow: "rgba(0, 0, 0, 0.12) 1px 0px 6px",
            }}
          >
            <ChatList
              isLoading={isLoading}
              channels={channels}
              onChannelSelect={this.handleSelectUser}
            />
          </div>
          <div
            className="flex flex-center justify-content-center"
            style={{width: "70%"}}
          >
            {this.renderCurrentActive()}
          </div>
        </Paper>
      </PageLayout>
    );
  }
}

ChatCenter.propTypes = {
  isLoading: PropTypes.bool,
  messenger: PropTypes.object,
  fetchChatsUnreadCounts: PropTypes.func,
};

export default ChatCenter;
