import {
  FETCH_STATES_REQUEST,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_ERROR,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
} from "../constants/actionTypes";
import initialState from "./initialState";

export default function geoReducer(state = initialState.geo, {type, payload}) {
  switch (type) {
  case FETCH_STATES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_STATES_SUCCESS:
    return {
      ...state,
      states: payload,
      isLoading: false,
    };
  case FETCH_STATES_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.errorMessage,
    };

  case FETCH_COUNTRIES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_COUNTRIES_SUCCESS:
    return {
      ...state,
      countries: payload,
      isLoading: false,
    };
  case FETCH_COUNTRIES_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.errorMessage,
    };

  default:
    return state;
  }
}
