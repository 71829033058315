import React, {Component} from "react";
import PropTypes from "prop-types";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";

import {Link} from "react-router-dom";

import PageTitle from "../../../shared/PageTitle";
import SectionHeading from "../../../shared/SectionHeading";
import ChillButton from "../../../shared/ChillButton";
import classNames from "classnames";

class CompanyDetailDetails extends Component {
  state = {
    id: null,
    company_name: "",
    payment_terms_id: null,
    resale_number: null,
    primary_contact_id: null,
    company_id: null,
    customer_message: "",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this._setCompanyState(_cloneDeep(this.props.company));
    }
  }

  _setCompanyState(companyData) {
    const contacts = companyData.contacts;
    let primaryContactId;
    if (Array.isArray(contacts) && contacts.length) {
      const primaryContact = contacts.find(contact => contact.is_primary);
      primaryContactId = _get(primaryContact, "id");
    }
    this.setState({
      id: companyData.id,
      company_name: companyData.name,
      payment_terms_id: companyData.paymentTermsId,
      resale_number: companyData.resaleNumber,
      primary_contact_id: primaryContactId,
      customer_message: companyData.customerMessage,
    });
  }

  updateCompany = async () => {
    const {match} = this.props;
    const {id} = match.params;
    this.props.updateCompany({..._cloneDeep(this.state), id});
  };

  updateMessage(msg) {
    this.setState({customer_message: msg});
  }

  _renderEditButton() {
    return (
      <Link
        to={`/companies/edit/${this.props.companyId}`}
        className={classNames("reset-link-styles", {
          loading: this.props.isLoading,
        })}
      >
        <ChillButton
          name={"EDIT INFO"}
          height={32}
          width={110}
          icon={"edit"}
          secondary={true}
        />
      </Link>
    );
  }

  render() {
    const {company, isLoading} = this.props;
    const {paymentTerm, discountCategory, businessCategory} = company || {};

    return (
      <div>
        <div className={classNames("row between-xs")}>
          <span
            className={classNames({loading: isLoading})}
            style={{
              display: "inline-block",
              minWidth: "40%",
            }}
          >
            <PageTitle text={company ? company.name : ""} marginBottom={16} />
          </span>{" "}
          {this.props.isInternal ? this._renderEditButton() : null}
        </div>

        <div>
          <div className={classNames("company_top_info", {loading: isLoading})} style={{ justifyContent: "space-between", padding: "5px 0px" }}>
            <div className="company_top_info_section">
              <SectionHeading text={"CA RESALE NUMBER"} />
              <span className="subtext no-wrap">
                {company && company.resaleNumber}
              </span>
            </div>
            <div className="company_top_info_section">
              <SectionHeading text={"PAYMENT TERMS"} />
              <span className="subtext  no-wrap">
                {paymentTerm && paymentTerm.name}
              </span>
            </div>
            <div className="company_top_info_section">
              <SectionHeading text={"CATEGORY"} />
              <span className="subtext  no-wrap">
                {businessCategory && businessCategory.name}
              </span>
            </div>
            <div className="company_top_info_section">
              <SectionHeading text={"DISCOUNT"} />
              <span className="subtext  no-wrap">
                {discountCategory && discountCategory.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompanyDetailDetails.propTypes = {
  companyId: PropTypes.string,
  paymentTerms: PropTypes.array.isRequired,
  company: PropTypes.object,
  updateCompany: PropTypes.func,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  fetchCompany: PropTypes.func,
};

export default CompanyDetailDetails;
