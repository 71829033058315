import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function brandReducer(
  state = initialState.brands,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_BRANDS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_BRANDS_SUCCESS:
    return {
      ...state,
      brands: payload,
      brandsCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_BRANDS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_BRAND_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_BRAND_SUCCESS:
    return {
      ...state,
      brand: payload,
      isLoading: false,
    };
  case actions.FETCH_BRAND_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.CREATE_BRAND_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_BRAND_SUCCESS:
    return {
      ...state,
      brands: [...state.brands, payload],
      isLoading: false,
    };
  case actions.CREATE_BRAND_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.UPDATE_BRAND_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_BRAND_SUCCESS:
    return {
      ...state,
      brand: payload,
      isLoading: false,
    };
  case actions.UPDATE_BRAND_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.DELETE_BRAND_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.DELETE_BRAND_SUCCESS:
    return {
      ...state,
      brand: null,
      isLoading: false,
    };
  case actions.DELETE_BRAND_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
