import React, {Component} from "react";
import PropTypes from "prop-types";

import CNGCard from "../../../../shared/CNGCard";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import lodash from "lodash";

const closeIconStyle = {
  color: "#0C5371",
  fontWeight: "300",
  marginLeft: 4,
};

const BeforeCardTitle = {
  color: "#747D86",
  fontSize: 12,
  display: "block",
  lineHeight: 2,
};

const RemoveStyle = {
  cursor: "pointer",
  color: "#0C5271",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 14,
};

const ItemStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
  },
};

export default class LaborItemCard extends Component {
  static propTypes = {
    reorderItem: PropTypes.object,
    isDisabled: PropTypes.func,
  };

  handleArchiveOrderItem = async () => {
    const {
      archiveOrderItem,
      reorderItem,
      openNotification,
      onItemArchived,
    } = this.props;

    try {
      await archiveOrderItem(reorderItem.id);
      openNotification("Order item was archived successfully");
      if (onItemArchived) onItemArchived();
    } catch (error) {
      openNotification("Error archiving Order item");
    }
  };

  _topRightContent = () => {
    const removeOption = (
      <span
        style={RemoveStyle}
        key="remove"
        className="labor--remove"
        onClick={this.handleArchiveOrderItem}
      >
        Remove
        <span key="remove-icon" style={closeIconStyle}>
          X
        </span>
      </span>
    );
    return [removeOption];
  };

  renderItem = () => {
    const {reorderItem} = this.props;

    return (
      <div
        className="flex justify-content-between"
        style={{
          width: "75%",
          marginBottom: 26,
        }}
      >
        <div>
          <div style={ItemStyle.header}>Price</div>
          <div
            style={{
              color: "#747D86",
              fontSize: 14,
            }}
            className="labor--unit-cost"
          >
            {getCurrencyFormattedNumber(
              lodash.get(reorderItem, "unitCostValue")
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {isDisabled} = this.props;
    const title = (
      <div>
        <span style={BeforeCardTitle} />
        <span className="labor--title">Labor</span>
      </div>
    );

    return (
      <CNGCard
        title={title}
        topRightContent={!isDisabled() && this._topRightContent()}
        style={{
          paddingBottom: 1,
          paddingTop: 49,
          height: 190,
          fontWeight: "normal",
        }}
      >
        <div>{this.renderItem()}</div>
      </CNGCard>
    );
  }
}
