import React from "react";
import PropTypes from "prop-types";

import ArtHistoryList from "./ArtHistoryList";

const ArtsTab = ({revisions, brand, isLoading}) => {
  return (
    <div className="tab-container">
      <ArtHistoryList
        revisions={revisions}
        brand={brand}
        isLoading={isLoading}
      />
    </div>
  );
};

ArtsTab.propTypes = {
  revisions: PropTypes.array,
  brand: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ArtsTab;
