import React, {Component} from "react";

import Column from "../Components/Column";
import {LayoutPropTypes} from "../Base/LayoutProps";

export default class BodyContainer extends Component {
  static propTypes = {
    ...LayoutPropTypes,
  };
  render() {
    return (
      <Column
        {...this.props}
        className="BodyContainer"
        flex={"1 0 auto"}
        style={{background: "#f4f8f9"}}
      />
    );
  }
}
