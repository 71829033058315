import React, {Component} from "react";
import PropTypes from "prop-types";
import changeCaseKeys from "change-case-keys";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {cloneDeep} from "lodash";

import ArtCreatePage from "../../pages/Arts/ArtCreatePage";
import {createArt, fetchArtsByBrand} from "../../../actions/artActions";
import {fetchBrand} from "../../../actions/brandActions";
import {openNotification} from "../../../actions/notificationActions";
import {fetchAdornmentTypes} from "../../../actions/adornmentTypeActions";
import {VALID_IMAGE_FORMATS} from "../../../constants/art";

class ArtCreate extends Component {
  static propTypes = {
    openNotification: PropTypes.func.isRequired,
    fetchBrand: PropTypes.func.isRequired,
    fetchAdornmentTypes: PropTypes.func.isRequired,
    fetchArtsByBrand: PropTypes.func.isRequired,
    createArt: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    brand: PropTypes.object,
    adornmentTypes: PropTypes.array,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
  };

  state = {
    filename: null,
    name: null,
    adornment_type_id: null,
    file: null,
    currentPage: 1,
    description: null,
    details: {},
  };

  componentWillReceiveProps(nextProps) {
    const {location: {state}} = nextProps;
    const selectedArt = state && state.selectedArt;
    this._evaluateSelectedArt(selectedArt);
  }

  componentWillMount() {
    const {
      match,
      fetchBrand,
      fetchArtsByBrand,
      fetchAdornmentTypes,
    } = this.props;
    const {id} = match.params;
    fetchBrand(id);
    fetchArtsByBrand(id, {
      orderBy: "created_at",
      sortDirection: "desc",
    });

    fetchAdornmentTypes();
  }

  _evaluateSelectedArt(selectedArt) {
    if (selectedArt && selectedArt.id) {
      const art = Array.isArray(selectedArt.revisions)
        ? cloneDeep(selectedArt.revisions[0])
        : null;
      if (art) {
        const {
          id,
          filename,
          name,
          adornmentTypeId,
          adornmentDetails,
          thumbnail,
        } = art;

        this.setState({
          revision_id: id,
          filename,
          name,
          thumbnail,
          adornment_type_id: adornmentTypeId,
          details: changeCaseKeys(adornmentDetails, "underscored"),
          selectedArt: selectedArt,
        });
      }
    }
  }

  handleSelectPage = options => {
    const {fetchArtsByBrand, brand} = this.props;
    this.setState({currentPage: options.page});
    fetchArtsByBrand(brand.id, {
      ...options,
      orderBy: "created_at",
      sortDirection: "desc",
    });
  };

  handleDetailChange = (error, index, value, formName) => {
    if (!value) {
      value = index;
    }
    const name = formName ? formName : error.target.name;

    this.setState({
      details: {
        ...this.state.details,
        [name]: value,
      },
    });
  };

  handleChange = (error, index, value, formName) => {
    if (!value) {
      value = index;
    }
    let name;
    if (!formName) {
      name = error.target.name;
    } else {
      name = formName;
    }

    if (name === "adornment_type_id") {
      this.setState({details: {}});
    }

    this.setState({[name]: value});
  };

  handleFileChange = inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Uploaded file is not a valid input image. Only png, jpg and jpg"
      );
    } else {
      this.setState({file: file, filename: file.name});
    }
  };

  handleCreateArt = async () => {
    const {openNotification} = this.props;
    try {
      const {brand, history} = this.props;
      await this.props.createArt({
        brand_id: brand.id,
        ...this.state,
      });
      openNotification("Art created successfully");
      history.push(`/companies/${brand.companyId}/brands/${brand.id}`);
    } catch (error) {
      openNotification("Error Creating Art");
    }
  };

  render() {
    return (
      <ArtCreatePage
        onCreateArt={this.handleCreateArt}
        onChange={this.handleChange}
        onDetailChange={this.handleDetailChange}
        onFileChange={this.handleFileChange}
        onSelectPage={this.handleSelectPage}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    brands: {brand},
    art: {isLoading},
    adornmentTypes: {adornmentTypes},
  } = state;

  return {
    brand,
    isLoading,
    adornmentTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArtsByBrand: bindActionCreators(fetchArtsByBrand, dispatch),
    fetchBrand: bindActionCreators(fetchBrand, dispatch),
    fetchAdornmentTypes: bindActionCreators(fetchAdornmentTypes, dispatch),
    createArt: bindActionCreators(createArt, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtCreate)
);
