import React, {Component} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import ValidableSelect from "../../../../shared/ValidableSelect";
import ValidableTextField from "../../../../shared/ValidableTextField";
import ChillButton from "../../../../shared/ChillButton";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";
import CircularProgress from "material-ui/CircularProgress";
const iconStyle = {
  right: "-16px",
  ...SelectFieldIconStyle,
};

export default class LaborForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    companyId: PropTypes.number,
    itemTypeOptions: PropTypes.array,
    onChangeOrderItemType: PropTypes.func,
    onClose: PropTypes.func,
    onCreated: PropTypes.func,
    createOrderItem: PropTypes.func,
    selectedItemTypeId: PropTypes.number,
    fabrics: PropTypes.array,
    products: PropTypes.array,
    colors: PropTypes.array,
    adornmentLocations: PropTypes.array,
    artRevisions: PropTypes.array,
  };

  state = {
    unitCost: null,
  };

  handleChange = name => (event, index, value) => {
    value = lodash.get(event, "target.value") || value;
    this.setState({
      [name]: value,
    });
  };

  handleChangeArt = (name, artIndex) => (event, index, value) => {
    value = event.target.value || value;

    this.setState({
      arts: this.state.arts.map((art, index) => {
        if (index === artIndex) return {...art, [name]: value};
        return art;
      }),
    });
  };

  handleCreate = () => {
    const {
      createOrderItem,
      companyId,
      onClose,
      onCreated,
      selectedItemTypeId,
    } = this.props;
    createOrderItem(
      companyId,
      {
        ...this.state,
        orderItemTypeId: selectedItemTypeId,
      },
      () => {
        onClose();
        onCreated();
      }
    );
  };

  isValidForm = () => {
    const attributes = ["unitCost"];
    return attributes.every(attribute => !!this.state[attribute]);
  };

  render() {
    const {
      itemTypeOptions,
      selectedItemTypeId,
      onChangeOrderItemType,
    } = this.props;

    const {unitCost} = this.state;

    return (
      <React.Fragment>
        <div className="flex">
          <ValidableSelect
            name="orderItemTypeId"
            label="Item"
            errorText={null}
            value={selectedItemTypeId || ""}
            handleChange={onChangeOrderItemType}
            underlineStyle={formStyles.underlineStyle}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            style={{width: 260, marginRight: 24}}
            iconStyle={{...iconStyle, color: "#4A5158"}}
            inputStyle={formStyles.inputStyle}
            floatingLabelFixed={true}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            options={itemTypeOptions}
            hintText="Select"
          />
        </div>

        <div className="flex">
          <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            prefix={"$"}
            customInput={ValidableTextField}
            name="unitCost"
            label="Price"
            type="text"
            value={unitCost || ""}
            onValueChange={({floatValue}) => {
              return this.handleChange("unitCost")(null, null, floatValue);
            }}
            style={{width: 260, marginRight: 24}}
            inputStyle={{
              ...formStyles.TextInputStyles,
              width: 260,
              color: "#747D86",
            }}
            underlineStyle={formStyles.underlineStyle}
          />
        </div>

        {this.props.isLoading ? (
          <div
            style={{
              margin: " 25px 0",
              textAlign: "center",
            }}
          >
            <div className="auth-button-progress-container">
              <CircularProgress
                size={24}
                thickness={4}
                color="rgba(255, 255, 255, 0.5)"
              />
            </div>
            <span>Loading...</span>
          </div>
        ) : (
          <ChillButton
            onClick={this.handleCreate}
            name="Save"
            useFontIcon={true}
            icon="check"
            fontIconStyles={{
              color: "#FFFFFF",
              fontSize: 18,
              marginRight: 4,
            }}
            isDisabled={!this.isValidForm()}
            className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
            height={41}
            marginTop={37}
            marginBottom={36}
          />
        )}
      </React.Fragment>
    );
  }
}
