import React, {Component} from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";

import PageLayout from "../shared/Layout/Layouts/PageLayout";
import SearchInput from "../shared/SearchInput";
import CNGCard from "../shared/CNGCard";
import PageTitle from "../shared/PageTitle";
import ChillButton from "../shared/ChillButton";

import ArtsTable from "./DashboardPage/ArtsTable";
import CompaniesTable from "./DashboardPage/CompaniesTable";
import DSODRequestTable from "./DashboardPage/DSODRequestTable";
import QuotesTable from "./DashboardPage/QuotesTable";
import OrdersTable from "./DashboardPage/OrdersTable";
import OpenCartsTable from "./DashboardPage/OpenCartsTable";
import UsersTable from "./DashboardPage/UsersTable";
import ShipmentTable from "./DashboardPage/ShipmentTable";

class DashboardPage extends Component {
  _renderTop() {
    return (
      <div className="dashboard_top">
        <div className="dashboard_top_left">
          <img
            src={"/assets/imgs/icons/dashboard.svg"}
            className="dashboard_top_icon"
          />
          <PageTitle text={"Dashboard"} />
        </div>
        <div>
          <SearchInput
            search={this.props.onSearch}
            initialValue={this.props.searchTerm}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }

  _dsodResults() {
    const {
      searchResults: {companies, DSODOrders},
      isInternal,
      dsodIsLoading,
      loadMoreDSODs,
      DSODOrdersCount,
      resultsLimitDSODs,
      resultsLimit,
    } = this.props;
    let DSODsLeftCount = this.getRemainingCount(
      DSODOrdersCount,
      resultsLimitDSODs,
      resultsLimit
    );
    return DSODOrders && DSODOrders.length > 0 ? (
      <>
        <DSODRequestTable
          orders={DSODOrders}
          companies={companies}
          isInternal={isInternal}
          isLoading={dsodIsLoading}
          minHeight="0"
        />\
        {DSODsLeftCount ? (
          <ChillButton
            secondary
            useFontIcon
            icon="refresh"
            name="Load More"
            height={32}
            width={110}
            onClick={() => loadMoreDSODs(DSODsLeftCount)}
            fontIconStyles={{color: "white"}}
            customStyles={{alignSelf: "center"}}
          />
        ) : null}
      </>
    ) : (
      <div />
    );
  }

  _shipmentsResults() {
    const {
      searchResults: {shipments},
      isInternal,
      selectedCompany,
      loadMoreShipments,
      shipmentsCount,
      resultsLimitShipments,
      resultsLimit,
      shipmentIsLoading,
    } = this.props;
    let shipmentsLeftCount = this.getRemainingCount(
      shipmentsCount,
      resultsLimitShipments,
      resultsLimit
    );

    return _get(shipments, "length", 0) ? (
      <>
        <ShipmentTable
          shipments={shipments}
          isInternal={isInternal}
          isLoading={shipmentIsLoading}
          selectedCompany={selectedCompany}
          minHeight="0"
        />
        {shipmentsLeftCount ? (
          <ChillButton
            secondary
            useFontIcon
            icon="refresh"
            name="Load More"
            height={32}
            width={110}
            onClick={() => loadMoreShipments(shipmentsLeftCount)}
            fontIconStyles={{color: "white"}}
            customStyles={{alignSelf: "center"}}
          />
        ) : null}
      </>
    ) : (
      <div />
    );
  }

  _quotesResults = () => {
    const {
      searchResults: {quotes},
      searchQuotes,
      searchTerm,
      quotesIsLoading,
      loadMoreQuotes,
      quotesCount,
      quotesUnreadCounts,
      resultsLimitQuotes,
      resultsLimit,
      isInternal,
    } = this.props;
    let quotesLeftCount = this.getRemainingCount(
      quotesCount,
      resultsLimitQuotes,
      resultsLimit
    );

    return (
      <>
        <QuotesTable
          minHeight="0"
          quotes={quotes}
          searchQuotes={searchQuotes}
          searchTerm={searchTerm}
          isLoading={quotesIsLoading}
          unreadMessagesCounts={quotesUnreadCounts}
          isInternal={isInternal}
        />
        {quotesLeftCount ? (
          <ChillButton
            secondary
            useFontIcon
            icon="refresh"
            name="Load More"
            height={32}
            width={110}
            onClick={() => loadMoreQuotes(quotesLeftCount)}
            fontIconStyles={{color: "white"}}
            customStyles={{alignSelf: "center"}}
          />
        ) : null}
      </>
    );
  };

  _ordersResults = () => {
    const {
      searchResults: {companies, orders},
      searchOrders,
      searchTerm,
      isInternal,
      orderIsLoading,
      loadMoreOrders,
      ordersCount,
      resultsLimitOrders,
      resultsLimit,
      ordersUnreadCounts,
    } = this.props;
    let ordersLeftCount = this.getRemainingCount(
      ordersCount,
      resultsLimitOrders,
      resultsLimit
    );

    return (
      <>
        <OrdersTable
          minHeight="0"
          orders={orders}
          companies={companies}
          isInternal={isInternal}
          searchOrders={searchOrders}
          searchTerm={searchTerm}
          isLoading={orderIsLoading}
          unreadMessagesCounts={ordersUnreadCounts}
        />
        {ordersLeftCount ? (
          <ChillButton
            secondary
            useFontIcon
            icon="refresh"
            name="Load More"
            height={32}
            width={110}
            onClick={() => loadMoreOrders(ordersLeftCount)}
            fontIconStyles={{color: "white"}}
            customStyles={{alignSelf: "center"}}
          />
        ) : null}
      </>
    );
  };

  getRemainingCount = (count, limit, pageSize) => {
    let remainingCount = count > limit ? count - limit : 0;
    if (remainingCount > pageSize) remainingCount = pageSize;
    return remainingCount;
  };

  _searchResults() {
    const {
      searchTerm,
      isLoading,
      ordersCount,
      resultsLimitOrders,
      DSODOrdersCount,
      resultsLimitDSODs,
      shipmentsCount,
      resultsLimitShipments,
      quotesCount,
      resultsLimitQuotes,
    } = this.props;

    if (isLoading === false && !this._hasQueryResults()) {
      return (
        <h2 className="userindex_noUsers">
          Sorry, no results found for &quot;{searchTerm}&quot;
        </h2>
      );
    }

    const companyCards = this._companiesContent();
    const userCards = this._usersContent();
    const artCards = this._artsContent();
    return (
      <div className={!this._hasSearchTerm() ? "hidden" : null}>
        {companyCards}
        {userCards}
        {artCards}

        <CNGCard
          title="Quotes"
          resultsCount={quotesCount}
          resultsLimit={resultsLimitQuotes}
          style={{display: "flex", flexDirection: "column"}}
        >
          {this._quotesResults()}
        </CNGCard>

        <CNGCard
          title="Orders"
          resultsCount={ordersCount}
          resultsLimit={resultsLimitOrders}
          style={{display: "flex", flexDirection: "column"}}
        >
          {this._ordersResults()}
        </CNGCard>

        <CNGCard
          title="DSOD Requests"
          resultsCount={DSODOrdersCount}
          resultsLimit={resultsLimitDSODs}
          style={{display: "flex", flexDirection: "column"}}
        >
          {this._dsodResults()}
        </CNGCard>

        <CNGCard
          title="Shipments"
          resultsCount={shipmentsCount}
          resultsLimit={resultsLimitShipments}
          style={{display: "flex", flexDirection: "column"}}
        >
          {this._shipmentsResults()}
        </CNGCard>
      </div>
    );
  }

  _companiesContent() {
    const {
      searchResults: {companies},
      isInternal,
      companiesCount,
      resultsLimit,
      loadMoreCompanies,
      resultsLimitCompanies,
    } = this.props;
    let companiesLeftCount = this.getRemainingCount(
      companiesCount,
      resultsLimitCompanies,
      resultsLimit
    );
    return isInternal ? (
      <CNGCard
        title="Companies"
        headerIcon="company"
        resultsCount={companiesCount}
        resultsLimit={resultsLimitCompanies}
        style={{display: "flex", flexDirection: "column"}}
      >
        {companies.length > 0 ? (
          <>
            <CompaniesTable
              companies={companies}
              isLoading={this.props.companyIsLoading}
            />
            {companiesLeftCount ? (
              <ChillButton
                secondary
                useFontIcon
                icon="refresh"
                name="Load More"
                height={32}
                width={110}
                onClick={() => loadMoreCompanies(companiesLeftCount)}
                fontIconStyles={{color: "white"}}
                customStyles={{alignSelf: "center"}}
              />
            ) : null}
          </>
        ) : (
          <div />
        )}
      </CNGCard>
    ) : null;
  }

  _usersContent() {
    const {
      searchResults: {users},
      isInternal,
      selectedCompany,
      userIsLoading,
      loadMoreUsers,
      resultsLimitUsers,
      userCount,
      resultsLimit,
    } = this.props;
    let usersLeftCount = this.getRemainingCount(
      userCount,
      resultsLimitUsers,
      resultsLimit
    );
    return (
      <CNGCard
        title="Users"
        headerIcon="users"
        resultsCount={userCount}
        resultsLimit={resultsLimitUsers}
        style={{display: "flex", flexDirection: "column"}}
      >
        {users.length > 0 ? (
          <>
            <UsersTable
              users={users}
              isInternal={isInternal}
              selectedCompany={selectedCompany}
              isLoading={userIsLoading}
            />
            {users.length > 0 && usersLeftCount ? (
              <ChillButton
                secondary
                useFontIcon
                icon="refresh"
                name="Load More"
                height={32}
                width={110}
                onClick={() => loadMoreUsers(usersLeftCount)}
                fontIconStyles={{color: "white"}}
                customStyles={{alignSelf: "center"}}
              />
            ) : null}
          </>
        ) : (
          <div />
        )}
      </CNGCard>
    );
  }

  _artsContent() {
    const {
      searchResults: {art},
      isInternal,
      artIsLoading,
      loadMoreArts,
      resultsLimitArts,
      artCount,
      resultsLimit,
    } = this.props;
    let artsLeftCount = this.getRemainingCount(
      artCount,
      resultsLimitArts,
      resultsLimit
    );
    return (
      <CNGCard
        title="Art"
        resultsCount={artCount}
        resultsLimit={resultsLimitArts}
        style={{display: "flex", flexDirection: "column"}}
      >
        {art.length > 0 ? (
          <>
            <ArtsTable
              isInternal={isInternal}
              arts={art}
              isLoading={artIsLoading}
            />
            {artsLeftCount ? (
              <ChillButton
                secondary
                useFontIcon
                icon="refresh"
                name="Load More"
                height={32}
                width={110}
                onClick={() => loadMoreArts(artsLeftCount)}
                fontIconStyles={{color: "white"}}
                customStyles={{alignSelf: "center"}}
              />
            ) : null}
          </>
        ) : (
          <div />
        )}
      </CNGCard>
    );
  }

  _renderWelcomeMessage() {
    const {
      isInternal,
      currentUserName,
      selectedCompany,
      priceTableLink,
      settings,
    } = this.props;
    if (isInternal || !selectedCompany) return null;

    return (
      <CNGCard
        title={`Hi ${currentUserName}`}
        topRightContent={
          <div className="flex flex-center">
            See price tables &nbsp;
            <a className="flex" href={priceTableLink} target="_blank">
              <FontIcon
                className="material-icons-outlined chill-icon"
                style={{fontSize: 18}}
              >
                open_in_new
              </FontIcon>
            </a>
          </div>
        }
      >
        <p className="dashboard_card_message">
          {_get(settings, "DASHBOARD_MESSAGE")}
        </p>
      </CNGCard>
    );
  }

  _dashboardContent() {
    const {
      searchResults: {quotes, orders, openOrders, DSODOrders, companies},
      isInternal,
      searchQuotes,
      searchOrders,
      searchDSODOrders,
      ordersCount,
      DSODOrdersCount,
      searchTerm,
      quotesIsLoading,
      quotesUnreadCounts,
      ordersUnreadCounts,
      orderIsLoading,
      dsodIsLoading,
      selectedCompanyId,
    } = this.props;
    return (
      <div className={this._hasSearchTerm() ? "hidden" : null}>
        {this._renderWelcomeMessage()}
        <CNGCard title={"DSOD Requests"}>
          <DSODRequestTable
            orders={DSODOrders}
            ordersCount={DSODOrdersCount}
            companies={companies}
            searchOrders={searchDSODOrders}
            isInternal={isInternal}
            searchTerm={searchTerm}
            isLoading={dsodIsLoading}
            minHeight="0"
          />
        </CNGCard>
        <CNGCard
          title="Quotes"
          topRightContent={
            <Link
              to="/quotes"
              className="pointer"
              style={{
                fontSize: 14,
                height: 18,
                color: "#0C5271",
                fontWeight: "bold",
                lineHeight: "24px",
              }}
              title="View All"
            >
              View all
            </Link>
          }
        >
          <QuotesTable
            quotes={quotes}
            searchQuotes={searchQuotes}
            searchTerm={searchTerm}
            isLoading={quotesIsLoading}
            unreadMessagesCounts={quotesUnreadCounts}
            isInternal={isInternal}
          />
        </CNGCard>
        <CNGCard title={"Orders"}>
          <OrdersTable
            showTotal
            orders={orders}
            ordersCount={ordersCount}
            companies={companies}
            isInternal={isInternal}
            searchOrders={searchOrders}
            searchTerm={searchTerm}
            isLoading={orderIsLoading}
            selectedCompanyId={selectedCompanyId}
            unreadMessagesCounts={ordersUnreadCounts}
          />
        </CNGCard>
        {isInternal && (
          <CNGCard title="Open Carts">
            <OpenCartsTable orders={openOrders} isLoading={orderIsLoading} />
          </CNGCard>
        )}
      </div>
    );
  }

  _hasQueryResults() {
    const {searchResults} = this.props;
    return Object.values(searchResults).some(
      results => results && results.length > 0
    );
  }

  _hasSearchTerm() {
    const {searchTerm} = this.props;
    return !!searchTerm && searchTerm.trim() !== "";
  }

  _renderContent = () => {
    return (
      <React.Fragment>
        {this._searchResults()}
        {this._dashboardContent()}
      </React.Fragment>
    );
  };

  render() {
    const {selectedCompany} = this.props;
    return (
      <PageLayout
        isLoading={this.props.isLoading}
        loaderEnable={true}
        subHeaderText={selectedCompany ? selectedCompany.name : ""}
      >
        {this._renderTop()}
        {this._renderContent()}
      </PageLayout>
    );
  }
}

DashboardPage.propTypes = {
  onSearch: PropTypes.func.isRequired,
  resultsLimitCompanies: PropTypes.number,
  loadMoreCompanies: PropTypes.func,
  resultsLimitUsers: PropTypes.number,
  loadMoreUsers: PropTypes.func,
  resultsLimitArts: PropTypes.number,
  loadMoreArts: PropTypes.func,
  searchOrders: PropTypes.func,
  resultsLimitOrders: PropTypes.number,
  loadMoreOrders: PropTypes.func,
  searchDSODOrders: PropTypes.func,
  resultsLimitDSODs: PropTypes.number,
  loadMoreDSODs: PropTypes.func,
  resultsLimitShipments: PropTypes.number,
  loadMoreShipments: PropTypes.func,
  searchResults: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  isInternal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  ordersCount: PropTypes.number,
  DSODOrdersCount: PropTypes.number,
  shipmentsCount: PropTypes.number,
  artCount: PropTypes.number,
  userCount: PropTypes.number,
  companiesCount: PropTypes.number,
  currentUserName: PropTypes.string,
  selectedCompany: PropTypes.object,
  history: PropTypes.object,
  orderIsLoading: PropTypes.bool,
  userIsLoading: PropTypes.bool,
  artIsLoading: PropTypes.bool,
  companyIsLoading: PropTypes.bool,
  dsodIsLoading: PropTypes.bool,
  shipmentIsLoading: PropTypes.bool,
  resultsLimit: PropTypes.number,
  selectedCompanyId: PropTypes.number,
  searchQuotes: PropTypes.func,
  quotesCount: PropTypes.number,
  quotesUnreadCounts: PropTypes.object,
  quotesIsLoading: PropTypes.bool,
  resultsLimitQuotes: PropTypes.number,
  loadMoreQuotes: PropTypes.func,
  priceTableLink: PropTypes.string,
  ordersUnreadCounts: PropTypes.object,
  settings: PropTypes.shape({}),
};

export default DashboardPage;
