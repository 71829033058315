import React, {Component} from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";
import ContentLoader from "../../../../shared/ContentLoader";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import {getFormattedDate} from "../../../../../utils/dates";
import {status as statusData} from "../../../../../constants/quote";

class QuotesTable extends Component {
  state = {
    currentPage: 1,
  };

  _getQuoteStatus = quote => {
    const {isInternal} = this.props;
    const {status, noAdornment, noNewArt} = quote;
    const statusValue = statusData[status];
    if (!isInternal && statusValue.eu) {
      return noAdornment || noNewArt
        ? statusValue.eu.nameNoAdornment
        : statusValue.eu.name;
    }
    if (noAdornment || noNewArt) return statusValue.nameNoAdornment;
    return statusValue.name;
  };

  _renderRow = quote => {
    const {id, companyName, items, createdAt, status} = quote;
    const productNames =
      items && items.map(({product}) => product.productName).join(", ");
    const statusValue = statusData[status];
    return (
      <CNGTableRow
        key={id}
        styles={{
          marginTop: 8,
        }}
      >
        <CNGTableRowColumn highlight>
          <Link
            to={`/quotes/info/${id}`}
            className="no-link-styles"
            title={companyName}
          >
            {companyName}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn title={productNames}>{productNames}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {createdAt && getFormattedDate(moment(createdAt))}
        </CNGTableRowColumn>
        <CNGTableRowColumn title={status} customStyles={{position: "relative"}}>
          <span className="flex flex-center" style={{color: statusValue.color}}>
            <FontIcon
              className="material-icons"
              style={{color: statusValue.color, padding: 4}}
            >
              {statusValue.icon}
            </FontIcon>
            {this._getQuoteStatus(quote)}
          </span>
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  render() {
    const header = ["Company", "Product", "Date", "Status"];
    const {quotes, quotesCount, searching, isLoading} = this.props;
    if (!quotesCount) {
      return (
        <ContentLoader isLoading={isLoading}>
          <div
            className="flex flex-column align-items-center"
            style={{
              minHeight: "82px",
            }}
          >
            <h2 className="userindex_noUsers">
              No quotes {searching ? "found" : "yet"}.
            </h2>
          </div>
        </ContentLoader>
      );
    }

    return (
      <ContentLoader isLoading={isLoading}>
        <CNGTable
          tableHeader={header}
          displayRowCheckbox={false}
          isLoading={isLoading}
        >
          {quotes.map(this._renderRow)}
        </CNGTable>
      </ContentLoader>
    );
  }
}

QuotesTable.propTypes = {
  quotes: PropTypes.array,
  quotesCount: PropTypes.number,
  searching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
};

export default QuotesTable;
