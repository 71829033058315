import React, {Component} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import SelectField from "material-ui/SelectField";
import CNGMenuItem from "../../../shared/CNGMenuItem";

import {
  formStyles,
  SelectFieldIconStyle,
} from "../../../../styles/components/formStyles";

export default class CompanySelect extends Component {
  static propTypes = {
    handleChangeCompany: PropTypes.func.isRequired,
    handleDeleteCompany: PropTypes.func,
    companies: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    trashCan: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    updateRef: PropTypes.func,
  };

  _renderTrashCan() {
    return (
      <img
        onClick={() => this.props.handleDeleteCompany(this.props.id)}
        className="company_field_delete"
        src="/assets/imgs/icons/trash.svg"
      />
    );
  }
  render() {
    const {
      name,
      id,
      companies,
      handleChangeCompany,
      disabled,
      trashCan,
      updateRef,
    } = this.props;
    return (
      <div className="company_field">
        <SelectField
          floatingLabelText="Company"
          floatingLabelStyle={{
            ...formStyles.TextFieldLabelStyles,
            ...CompanyTextFloatingStyles,
          }}
          name={name}
          value={id}
          disabled={disabled}
          onChange={(e, index, value, name) =>
            handleChangeCompany(e, index, value, name)
          }
          style={CompanySelectStyles}
          inputStyle={{...formStyles.inputStyle, ...CompanyTextInputStyles}}
          iconStyle={{...SelectFieldIconStyle, ...CompanyIconStyles}}
          underlineStyle={formStyles.underlineStyle}
          ref={el => updateRef && updateRef(ReactDOM.findDOMNode(el))} // eslint-disable-line react/no-find-dom-node
        >
          {companies &&
            companies.map(company => {
              return (
                <CNGMenuItem
                  key={company.id}
                  value={company.id}
                  title={company.name}
                  primaryText={company.name}
                  className="company-select-menu-item"
                  innerDivStyle={CompanyMenuItemInnerDivStyle}
                  style={CompanyMenuItemStyle}
                />
              );
            })}
        </SelectField>
        {trashCan ? this._renderTrashCan() : null}
      </div>
    );
  }
}

const CompanySelectStyles = {
  height: 56,
  width: 300,
  bottom: 34,
  color: "#747D86",
  marginRight: 32,
};
const CompanyTextInputStyles = {
  height: 56,
  width: 300,
  fontSize: 16,
  marginTop: 0,
};

const CompanyTextFloatingStyles = {
  top: 20,
};
const CompanyIconStyles = {
  right: -15,
  padding: 0,
};

const CompanyMenuItemInnerDivStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const CompanyMenuItemStyle = {
  width: 300,
};
