import React, {Component} from "react";
import PropTypes from "prop-types";

import ProductImage from "./ProductImage";
import ArtAssignmentsModal from "./ArtAssignmentsModal";

import lodash from "lodash";

const productDetailsStyles = {
  marginLeft: 64,
};

const productRowStyle = {
  marginBottom: 44,
};

const productImagesStyles = {
  width: 246,
  marginRight: 65,
  marginLeft: 46,
};

const artImageStyles = {
  width: 188,
};

export default class ProductDetails extends Component {
  static propTypes = {
    inventoryItem: PropTypes.object,
    orderItemProduct: PropTypes.object,
    uploadArtAssignmentImage: PropTypes.func,
    isLoadingArtAssignment: PropTypes.bool,
    isInternal: PropTypes.bool,
  };

  state = {
    activeAssignmentIndex: 0,
    defaultAssignmentIndex: 0,
    openArtsModal: false,
  };

  render() {
    const {
      uploadArtAssignmentImage,
      orderItemProduct,
      isLoadingArtAssignment,
      isInternal,
    } = this.props;
    if (!orderItemProduct) return <div />;
    const {
      orderItemProduct: {
        fabricColor: {color: {name: colorName}, fabric: {name: fabricName}},
        leatherColor,
      },
    } = this.props;
    let assignments =
      orderItemProduct.orderItemProductAdornmentLocationArtAssignments;
    assignments = lodash.sortBy(assignments, ["id"]);

    const {activeAssignmentIndex} = this.state;

    const productImageUrl = lodash.get(
      assignments,
      `[${activeAssignmentIndex}].imageUrl`
    );

    const url =
      productImageUrl ||
      lodash.get(
        orderItemProduct,
        "orderItem.product.productImages[0].imageUrl"
      );

    const leatherColorName = lodash.get(leatherColor, "color.name");
    return (
      <div className="row" style={productRowStyle}>
        {this.state.openArtsModal ? (
          <ArtAssignmentsModal
            uploadArtAssignmentImage={uploadArtAssignmentImage}
            isLoadingArtAssignment={isLoadingArtAssignment}
            isInternal={isInternal}
            open={this.state.openArtsModal}
            assignments={assignments}
            activeAssignmentIndex={activeAssignmentIndex}
            onClose={() => this.setState({openArtsModal: false})}
          />
        ) : null}
        <div className="product-detail--image">
          <ProductImage src={url} style={productImagesStyles} />
        </div>

        <div style={productDetailsStyles} className="product-details--info">
          <h4>Product Details</h4>

          <div className="row">
            <div className="detail-table_col">
              <p className="product-detail-attribute">
                <b>Fabric:</b>
                &nbsp;{fabricName}&nbsp;&nbsp;
                <b>Color:</b>
                &nbsp;{colorName}&nbsp;&nbsp;
                {leatherColorName && <b>Trim:</b>}
                &nbsp;{leatherColorName}
              </p>
              <p className="product-detail-attribute">
                {assignments &&
                  assignments.map(assigment => {
                    const {art} = assigment;
                    return (
                      <>
                        <span key={assigment.id}>
                          <b>Art File:</b>
                          &nbsp;{art.name}&nbsp;&nbsp;
                          <b>Location:</b>
                          &nbsp;{lodash.get(
                            assigment,
                            "adornmentLocation.location",
                            "not applicable"
                          )}
                        </span>
                        <br />
                      </>
                    );
                  })}
              </p>

              <div className="product-detail--art-image">
                {assignments &&
                  assignments.map((assigment, index) => (
                    <img
                      key={assigment.art.id}
                      style={artImageStyles}
                      src={assigment.art.imageUrl}
                      onClick={() =>
                        this.setState({
                          openArtsModal: true,
                          defaultAssignmentIndex: index,
                        })
                      }
                      onMouseOver={() =>
                        this.setState({activeAssignmentIndex: index})
                      }
                      onMouseOut={() =>
                        this.setState({
                          activeAssignmentIndex: this.state
                            .defaultAssignmentIndex,
                        })
                      }
                      className={
                        activeAssignmentIndex === index ? "active" : ""
                      }
                      onError={e => {
                        e.target.src = "/assets/imgs/image_not_found.png";
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
