export const tableStyle = {
  backgroundColor: "transparent",
};
export const tableHeaderStyle = {
  borderWidth: "0",
  height: 16,
  lineHeight: "16px",
};
export const tableHeaderRowStyle = {
  height: 16,
};
export const thStyle = {
  height: 16,
  fontWeight: "bold",
  color: "#929DA8",
  fontSize: 12,
  lineHeight: "16px",
  verticalAlign: "bottom",
};

export const thLinkStyle = {
  ...thStyle,
  color: "#1D9ABB",
};
export const thFirstLinkStyle = {
  ...thLinkStyle,
  paddingLeft: 33,
};
export const tableRowStyle = {
  height: 80,
  backgroundColor: "white",
  borderRadius: 4,
};
