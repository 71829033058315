import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _pick from "lodash/pick";
import NumberFormat from "react-number-format";
import CNGMenuItem from "../../../../shared/CNGMenuItem";

import ValidableSelect from "../../../../shared/ValidableSelect";
import ValidableTextField from "../../../../shared/ValidableTextField";
import ChillButton from "../../../../shared/ChillButton";
import {
  EMBROIDERY_COST,
  ETCHING_COST,
  ADORNMENT_TYPES,
} from "../../../../../constants/orderItemType";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";
import CircularProgress from "material-ui/CircularProgress";
const iconStyle = {
  right: "-16px",
  ...SelectFieldIconStyle,
};

export default class SetUpCostForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    companyId: PropTypes.number,
    itemTypeOptions: PropTypes.array,
    onChangeOrderItemType: PropTypes.func,
    onClose: PropTypes.func,
    onCreated: PropTypes.func,
    createOrderItem: PropTypes.func,
    selectedItemTypeId: PropTypes.number,
    digitalizationTypePrices: PropTypes.array,
    fetchDigitalizationTypePrices: PropTypes.func,
  };

  state = {
    unitCost: null,
    digitalizationTypePriceId: null,
    adornmentTypeId: EMBROIDERY_COST,
    adornmentTypes: ADORNMENT_TYPES,
  };

  componentDidMount() {
    this.props.fetchDigitalizationTypePrices();
  }

  handleChange = name => (event, index, value) => {
    value = _get(event, "target.value") || value;
    const newState = {
      unitCost: null,
      digitalizationTypePriceId: null,
      [name]: value,
    };
    if (name === "adornmentTypeId") {
      const options = _get(this.props, "digitalizationTypePrices", []).filter(
        typePrice => typePrice.adornmentType === value
      );
      if (options.length === 1) {
        newState.digitalizationTypePriceId = options[0].id.toString();
      }
    }
    this.setState(newState);
  };

  handleCreate = () => {
    const {
      createOrderItem,
      companyId,
      onClose,
      onCreated,
      selectedItemTypeId,
    } = this.props;
    createOrderItem(
      companyId,
      {
        ..._pick(this.state, [
          "unitCost",
          "digitalizationTypePriceId",
          "adornmentTypeId",
          "orderItemTypeId",
        ]),
        orderItemTypeId: selectedItemTypeId,
      },
      () => {
        onClose();
        onCreated();
      }
    );
  };

  isValidForm = () => {
    const {adornmentTypeId} = this.state;
    let attributes = ["adornmentTypeId", "digitalizationTypePriceId"];
    if (![EMBROIDERY_COST, ETCHING_COST].includes(adornmentTypeId)) {
      attributes = ["adornmentTypeId", "unitCost"];
    }
    return attributes.every(attribute => !!this.state[attribute]);
  };

  getDigitalizationOptions = () => {
    const {adornmentTypeId} = this.state;
    return _get(this.props, "digitalizationTypePrices", [])
      .filter(typePrice => typePrice.adornmentType === adornmentTypeId)
      .map(({id, code}) => (
        <CNGMenuItem key={id} value={id.toString()} primaryText={code} />
      ));
  };

  getAdornmentTypesOptions = () => {
    return _get(this.state, "adornmentTypes", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  render() {
    const {
      itemTypeOptions,
      selectedItemTypeId,
      onChangeOrderItemType,
    } = this.props;

    const {adornmentTypeId, digitalizationTypePriceId, unitCost} = this.state;

    return (
      <React.Fragment>
        <div className="flex">
          <ValidableSelect
            name="orderItemTypeId"
            label="Item"
            errorText={null}
            value={selectedItemTypeId || ""}
            handleChange={onChangeOrderItemType}
            underlineStyle={formStyles.underlineStyle}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            style={{width: 260, marginRight: 24}}
            iconStyle={iconStyle}
            inputStyle={{width: "100%"}}
            floatingLabelFixed={true}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            options={itemTypeOptions}
            hintText="Select"
          />

          <ValidableSelect
            name="adornmentTypeId"
            label="Adornment Type"
            errorText={null}
            value={adornmentTypeId}
            handleChange={this.handleChange("adornmentTypeId")}
            underlineStyle={formStyles.underlineStyle}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            style={{width: 260, marginRight: 24}}
            iconStyle={iconStyle}
            inputStyle={{width: "100%"}}
            floatingLabelFixed={true}
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            options={this.getAdornmentTypesOptions()}
            hintText="Select"
          />
        </div>

        <div className="flex">
          {[EMBROIDERY_COST, ETCHING_COST].includes(adornmentTypeId) ? (
            <ValidableSelect
              name="digitalizationTypePriceId"
              label="Complexity"
              errorText={null}
              value={digitalizationTypePriceId}
              handleChange={this.handleChange("digitalizationTypePriceId")}
              underlineStyle={formStyles.underlineStyle}
              underlineDisabledStyle={formStyles.underlineDisabledStyle}
              style={{width: 260, marginRight: 24}}
              iconStyle={iconStyle}
              inputStyle={{width: "100%"}}
              floatingLabelFixed={true}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
              }}
              options={this.getDigitalizationOptions()}
              hintText="Select"
            />
          ) : (
            <NumberFormat
              thousandSeparator={true}
              allowNegative={false}
              prefix={"$"}
              customInput={ValidableTextField}
              name="unitCost"
              label="Price"
              type="text"
              value={unitCost || ""}
              onValueChange={({floatValue}) => {
                return this.handleChange("unitCost")(null, null, floatValue);
              }}
              style={{width: 260, marginRight: 24}}
              inputStyle={{...formStyles.TextInputStyles, width: 260}}
              underlineStyle={formStyles.underlineStyle}
            />
          )}
        </div>

        {this.props.isLoading ? (
          <div
            style={{
              margin: " 25px 0",
              textAlign: "center",
            }}
          >
            <div className="auth-button-progress-container">
              <CircularProgress
                size={24}
                thickness={4}
                color="rgba(255, 255, 255, 0.5)"
              />
            </div>
            <span>Loading...</span>
          </div>
        ) : (
          <ChillButton
            onClick={this.handleCreate}
            name="Save"
            useFontIcon={true}
            icon="check"
            fontIconStyles={{
              color: "#FFFFFF",
              fontSize: 18,
              marginRight: 4,
            }}
            isDisabled={!this.isValidForm()}
            className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
            height={41}
            marginTop={37}
            marginBottom={36}
          />
        )}
      </React.Fragment>
    );
  }
}
