import fetchAPI from "../../utils/fetchAPI";
import {dispatchMessage} from "../commonActions";
import {
  UPDATE_ART_ASSIGNMENT_REQUEST,
  UPDATE_ART_ASSIGNMENT_SUCCESS,
  UPDATE_ORDER_ITEM_PRODUCT_ART_ASSIGNMENTS,
  OPEN_ERROR_MESSAGE,
} from "../../constants/actionTypes";

export const uploadArtAssignmentImage = (
  orderItemProductId,
  artAssignmentId,
  payload
) => {
  return async dispatch => {
    const formData = new FormData();
    formData.append("file", payload);
    const artAssignment = await fetchAPI(
      {
        url: `/order_item_products/${orderItemProductId}/art_assignments/${artAssignmentId}/upload_image`,
        method: "PATCH",
        body: formData,
        contentType: false,
        types: [
          UPDATE_ART_ASSIGNMENT_REQUEST,
          UPDATE_ART_ASSIGNMENT_SUCCESS,
          OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );
    if (artAssignment) {
      dispatchMessage(dispatch, "Product art image uploaded successfully");
      dispatch({
        type: UPDATE_ORDER_ITEM_PRODUCT_ART_ASSIGNMENTS,
        payload: artAssignment,
      });
      return artAssignment;
    }
  };
};
