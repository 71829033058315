export const PRODUCT_ORDER_ITEM_NAME = "Product";
export const LABOR_ORDER_ITEM_NAME = "Labor";
export const SETUP_ORDER_ITEM_NAME = "Set Up Cost";
export const MISCELLANEOUS_COST = "Miscellaneous";
export const DISCOUNT_ORDER_ITEM_NAME = "Discount";
export const SEW_OUT = "Sew-Out";

export const EMBROIDERY_COST = "embroidery";
export const ETCHING_COST = "etching";

export const PRODUCT_ORDER_ITEM_ID = 1;
export const LABOR_ORDER_ITEM_ID = 2;
export const SETUP_ORDER_ITEM_ID = 3;
export const ADORNMENT_TYPES = [
  {
    id: EMBROIDERY_COST,
    name: "Embroidery",
    title: "Difficulty",
  },
  {
    id: ETCHING_COST,
    name: "Laser Etching",
  },
];
