import React, {Component} from "react";
import PropTypes from "prop-types";

import CNGCard from "../../../shared/CNGCard";
import RecipientForm from "../../../containers/DSOD/RecipientFormContainer";
import SectionHeading from "../../../shared/SectionHeading";
import FormattedPhone from "../../../shared/FormattedPhone";
import FormattedAddress from "../../../shared/FormattedAddress";
import ToggleIcon from "../../../shared/ToggleIcon";
import RecipientProductsTable from "./RecipientProductsTable";
import {Link} from "react-router-dom";

const quantityStyle = {
  height: 12,
  lineHeight: "14px",
  fontSize: 12,
  marginBottom: 8,
};

const removeStyle = {
  color: "white",
  fontWeight: "bold",
  marginLeft: 6,
  borderRadius: 3,
  textAlign: "center",
  background: "#1D9ABB",
  lineHeight: "15px",
  height: 16,
  width: 16,
  cursor: "pointer",
  fontSize: 12,
};

export default class RecipientCard extends Component {
  static propTypes = {
    onUpdateDsodRecipient: PropTypes.func,
    companyId: PropTypes.number,
    companies: PropTypes.array.isRequired,
    companyContacts: PropTypes.array,
    activeContacts: PropTypes.array,
    recipient: PropTypes.object,
    dsodItems: PropTypes.array,
    inventoryItems: PropTypes.array,
    fetchInventoryItems: PropTypes.func,
    updateDsodItem: PropTypes.func,
    inventoryItemsCount: PropTypes.number,
    openNotification: PropTypes.func,
    deleteDsodItem: PropTypes.func,
    createDsodItem: PropTypes.func,
    fullDsodItems: PropTypes.array,
    onFetchDsodItems: PropTypes.func,
  };

  state = {
    editting: false,
    toggle: true,
  };

  _renderContactSummary() {
    const {
      companyName,
      emailAddress,
      phoneNumber,
      country,
      countryCode,
    } = this.props.recipient;

    return (
      <div className="company_top_info">
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"COMPANY"} />
          <span className="subtext">{companyName}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"ADDRESS"} />
          <FormattedAddress user={this.props.recipient || {}} />
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"EMAIL"} />
          <span className="subtext no-wrap">{emailAddress}</span>
        </div>
        <div className="company_top_info_section flex-one">
          <SectionHeading fontSize={10} text={"PHONE"} />
          <span className="subtext no-wrap">
            <FormattedPhone
              phoneNumber={phoneNumber}
              countryCode={country ? country.code : countryCode}
            />
          </span>
        </div>
      </div>
    );
  }
  _renderRecipientForm() {
    const {
      companyId,
      companies,
      companyContacts,
      onUpdateDsodRecipient,
      dsodItems = [],
      recipient,
    } = this.props;

    return (
      <RecipientForm
        hideIsProof
        companyId={Number(companyId)}
        companies={companies}
        companyContacts={companyContacts}
        onSaveRecipient={recipient => {
          onUpdateDsodRecipient(recipient, dsodItems);
          this.setState({editting: false});
        }}
        clearForm={true}
        onClose={() => {
          if (recipient) {
            this.setState({editting: false});
          }
        }}
        initialState={recipient}
      />
    );
  }

  _totalDsodItems() {
    const {dsodItems} = this.props;
    return (
      (dsodItems &&
        dsodItems.reduce(
          (total, dsodItem) => total + dsodItem.orderQuantity,
          0
        )) ||
      0
    );
  }

  _isEmptyRecipient = () => {
    const {dsodItems} = this.props;
    return dsodItems && dsodItems.length > 0 && dsodItems[0].recipientId;
  };

  render() {
    const {toggle, editting} = this.state;
    const {
      recipient,
      dsodItems,
      fetchInventoryItems,
      inventoryItems,
      inventoryItemsCount,
      companyId,
      updateDsodItem,
      deleteDsodItem,
      openNotification,
      activeContacts,
      createDsodItem,
      fullDsodItems,
      onFetchDsodItems,
    } = this.props;

    const total = this._totalDsodItems();
    const toggleStyle = {
      height: 90,
    };

    return (
      <CNGCard
        title={(recipient && recipient.name) || "Recipient"}
        style={!toggle ? toggleStyle : {}}
        actionIcon="edit-blue"
        showActionIcon={!editting && this.state.toggle}
        showDivider={this.state.toggle}
        onTitleClick={() => this.setState({editting: true})}
        topRightContent={
          <div className="flex">
            <div>
              <div style={quantityStyle}>Quantity</div>
              <div>{total > 0 ? total : "-"}</div>
            </div>
            <ToggleIcon
              onChange={() => {
                this.setState({toggle: !this.state.toggle});
              }}
              className="margin-left-40"
            />

            {this._isEmptyRecipient() ? (
              <Link
                to={""}
                onClick={event => {
                  event.preventDefault();
                  const {id} = dsodItems[0];
                  deleteDsodItem(companyId, id, onFetchDsodItems);
                }}
                style={removeStyle}
              >
                x
              </Link>
            ) : null}
          </div>
        }
      >
        {this.state.toggle ? (
          <div>
            {!recipient || editting
              ? this._renderRecipientForm()
              : this._renderContactSummary()}
            <RecipientProductsTable
              onFetchDsodItems={onFetchDsodItems}
              openNotification={openNotification}
              fetchInventoryItems={fetchInventoryItems}
              inventoryItems={inventoryItems}
              inventoryItemsCount={inventoryItemsCount}
              dsodItems={dsodItems}
              fullDsodItems={fullDsodItems}
              companyId={companyId}
              updateDsodItem={updateDsodItem}
              onUpdatedDsodItem={() => openNotification("DSOD cart updated")}
              deleteDsodItem={deleteDsodItem}
              activeContacts={activeContacts}
              createDsodItem={createDsodItem}
              canAddItems={!!recipient}
            />
          </div>
        ) : (
          <div />
        )}
      </CNGCard>
    );
  }
}
