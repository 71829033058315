import React, {Component} from "react";
import PropTypes from "prop-types";
import SelectField from "material-ui/SelectField";

import ReactDOM from "react-dom";
import {
  formStyles,
  SelectFieldIconStyle,
  TextFieldSmallLabelStyles,
} from "../../styles/components/formStyles";

export default class ValidableSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    menuStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    inputProps: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    floatingLabelStyle: PropTypes.object,
  };
  state = {
    value: null,
  };
  buttonEl = null;

  _handleSelectRefUpdated = (proxyElement, tabIndex) => {
    if (!proxyElement) return;
    const element = ReactDOM.findDOMNode(proxyElement); // eslint-disable-line react/no-find-dom-node
    this.buttonEl = element.querySelector("button");
    if (!tabIndex) return;
    this.buttonEl.tabIndex = tabIndex;
  };

  _handleChange(e, index, value) {
    this.props.handleChange(e, index, value);
    setTimeout(() => this.buttonEl && this.buttonEl.focus(), 600);
  }

  render() {
    const {
      HalfFieldStyles,
      MenuStyle: selectMenuStyle,
      underlineStyle: selectUnderlineStyle,
    } = formStyles;
    const {
      value,
      iconStyle,
      label: floatingLabelText,
      options,
      labelStyle,
      style = HalfFieldStyles,
      inputStyle = HalfFieldStyles,
      menuStyle = selectMenuStyle,
      underlineStyle = selectUnderlineStyle,
      floatingLabelStyle = TextFieldSmallLabelStyles,
      tabIndex,
      ...selectProps
    } = this.props;
    delete selectProps.handleChange;
    const labelStyleColor = selectProps.disabled ? "#0000004d" : "#4A5158";

    return (
      <SelectField
        {...selectProps}
        {...{
          style,
          menuStyle,
          underlineStyle,
          floatingLabelText,
        }}
        floatingLabelStyle={{
          ...formStyles.TextFieldLabelStyles,
          ...floatingLabelStyle,
        }}
        labelStyle={{
          ...formStyles.TextFieldLabelStyles,
          ...labelStyle,
          color: labelStyleColor,
        }}
        inputStyle={{...formStyles.inputStyle, ...inputStyle}}
        iconStyle={{...SelectFieldIconStyle, ...iconStyle, fill: "#929DA8"}}
        value={value !== null ? (value || "").toString() : value}
        onChange={(e, index, value) => this._handleChange(e, index, value)}
        ref={el => this._handleSelectRefUpdated(el, tabIndex)}
        underlineStyle={formStyles.underlineStyle}
      >
        {options}
      </SelectField>
    );
  }
}
