import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";
import FormattedAddress from "../../../shared/FormattedAddress";
import FormattedPhone from "../../../shared/FormattedPhone";
import TrackingNumber from "../../../shared/TrackingNumber";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

class DSODTable extends Component {
  _renderRow(DSODOrderItem, index, inventoryHistory) {
    const {countries, companyId} = this.props;
    if (!DSODOrderItem) return;
    const {order, shipmentItems} = DSODOrderItem;
    if (!shipmentItems) return;
    const {recipient, trackingNumber, shipmentCost, shipment} =
      shipmentItems[0] || {};

    const {cngReferenceNumber, id: orderId} = order || {};

    const {name, phoneNumber, emailAddress, companyName, country, countryCode} =
      recipient || {};
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          customStyles={{
            width: 170,
          }}
          highlight={true}
        >
          <Link
            to={`/companies/${companyId}/contacts/${recipient && recipient.id}`}
            className="no-link-styles"
            title={name}
          >
            {name}
            <div className="table-subtext">{companyName}</div>
            <div className="table-subtext">{cngReferenceNumber}</div>
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <FormattedAddress user={recipient || {}} countries={countries} />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 130,
          }}
        >
          <FormattedPhone
            phoneNumber={phoneNumber}
            countryCode={country ? country.code : countryCode}
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 175,
          }}
        >
          {emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 80,
          }}
        >
          {inventoryHistory.outQuantity || "-"}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 165,
            fontWeight: "normal",
          }}
        >
          <TrackingNumber shipment={shipment}>{trackingNumber}</TrackingNumber>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(shipmentCost)}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 70,
            textAlign: "right",
            cursor: "pointer",
          }}
        >
          <Link
            to={`/companies/${companyId}/dsod/${orderId}`}
            className="no-link-styles non-printable"
            title={name}
          >
            <FontIcon
              className="material-icons"
              style={{
                fontSize: 16,
                color: "#497E95",
              }}
            >
              arrow_forward_ios
            </FontIcon>
          </Link>
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "RECIPIENT",
        customStyles: {
          width: 170,
        },
      },
      "ADDRESS",
      {
        columnContent: "PHONE",
        customStyles: {
          width: 130,
        },
      },
      {
        columnContent: "EMAIL",
        customStyles: {
          width: 175,
        },
      },
      {
        columnContent: "QUANTITY",
        customStyles: {
          width: 80,
        },
      },
      {
        columnContent: "TRACKING #",
        customStyles: {
          width: 165,
        },
      },
      {
        columnContent: "SHIPMENT COST",
        customStyles: {
          width: 100,
        },
      },
      {
        columnContent: "",
        customStyles: {
          width: 70,
        },
      },
    ];

    const {inventoryItem} = this.props;
    const inventoryHistories = _get(
      inventoryItem,
      "inventoryHistories",
      []
    ).filter(inventoryHistory => inventoryHistory.sourceType === "dsod");

    return (
      <CNGTable
        tableHeader={header}
        displayRowCheckbox={false}
        className="dsod-table"
      >
        {inventoryHistories.map(inventoryHistory => {
          const DSODOrderItem = _get(inventoryHistory, "orderItem");
          return this._renderRow(
            DSODOrderItem,
            DSODOrderItem.id,
            inventoryHistory
          );
        })}
      </CNGTable>
    );
  }
}

DSODTable.propTypes = {
  inventoryItem: PropTypes.object,
  countries: PropTypes.array,
  companyId: PropTypes.number,
};

export default DSODTable;
