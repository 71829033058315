import React, {Component} from "react";
import PropTypes from "prop-types";
import MaterialUITextField from "material-ui/TextField";
import phoneNumberExamples from "libphonenumber-js/examples.mobile.json";
import {getExampleNumber, isSupportedCountry} from "libphonenumber-js";
import InputMask from "react-input-mask";

import {formStyles} from "../../styles/components/formStyles";
import {
  isCountryUSAOrCanadaByCode,
  getValidCountryCodeForHistoricalOrders,
} from "../../utils/countryRegionsHelper";
import ValidableTextField from "./ValidableTextField";

const InternationalPhoneNumberInput = ({
  component: TextField,
  value,
  style,
  inputStyle,
  tabIndex,
  countryCallingCode,
  countryCode, // eslint-disable-line no-unused-vars
  ...props
}) => {
  const prefixLength = formStyles.phoneInputPrefixStyle.width;
  const fieldStyle = {
    ...style,
    width:
      (style && style.width ? style.width : formStyles.TextFieldStyles.width) -
      prefixLength,
  };
  const innerInputStyle = {
    ...inputStyle,
    width:
      (inputStyle && inputStyle.width
        ? inputStyle.width
        : formStyles.TextInputStyles.width) - prefixLength,
  };

  return (
    <div style={formStyles.phoneInputWithPrefixWrapperStyle}>
      <span style={formStyles.phoneInputPrefixStyle}>
        +{countryCallingCode}
      </span>
      <TextField
        {...props}
        tabIndex={tabIndex}
        value={value}
        inputStyle={innerInputStyle}
        style={fieldStyle}
      />
    </div>
  );
};

InternationalPhoneNumberInput.propTypes = {
  ...MaterialUITextField.propTypes,
  countryCode: PropTypes.string.isRequired,
  countryCallingCode: PropTypes.string.isRequired,
  onCountryCodeChange: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

InternationalPhoneNumberInput.defaultProps = {
  component: ValidableTextField,
};

class PhoneNumberInput extends Component {
  state = {
    mask: "",
    countryCallingCode: "",
  };

  componentDidMount() {
    const {mask, countryCallingCode} = this.getMaskForCountryCode(
      this.props.countryCode,
      this.props.onCountryCodeChange
    );
    this.setState({mask, countryCallingCode});
  }

  componentDidUpdate(prevProps) {
    if (this.props.countryCode !== prevProps.countryCode) {
      const {mask, countryCallingCode} = this.getMaskForCountryCode(
        this.props.countryCode,
        this.props.onCountryCodeChange
      );
      this.setState({mask, countryCallingCode});
    }
  }

  getMaskForCountryCode = (countryCode, onCountryCodeChange) => {
    const isSupported = isSupportedCountry(countryCode);
    const phoneNumberExample = getExampleNumber(
      isSupported ? countryCode : "US",
      phoneNumberExamples
    );
    const template = phoneNumberExample.formatNational();
    const countryCallingCode = phoneNumberExample.countryCallingCode;
    const mask = template.replace(/\d/g, "9");
    if (onCountryCodeChange) {
      onCountryCodeChange(
        countryCode,
        template.replace(/\D/g, "").length,
        template,
        mask
      );
    }
    return {
      mask,
      countryCallingCode,
    };
  };

  render() {
    const {
      component: TextField,
      value,
      countryCode,
      onCountryCodeChange, // eslint-disable-line no-unused-vars
      style,
      inputStyle,
      tabIndex,
      ...props
    } = this.props;
    const {mask, countryCallingCode} = this.state;
    const parsedCountryCode = getValidCountryCodeForHistoricalOrders(
      countryCode
    );

    if (isCountryUSAOrCanadaByCode(parsedCountryCode)) {
      return (
        <TextField
          {...props}
          value={value}
          inputStyle={inputStyle}
          style={style}
        >
          <InputMask
            value={value || ""}
            mask={mask}
            maskChar={null}
            tabIndex={tabIndex}
            style={style}
          />
        </TextField>
      );
    }
    return (
      <InternationalPhoneNumberInput
        {...this.props}
        countryCode={countryCode}
        countryCallingCode={countryCallingCode}
      />
    );
  }
}

PhoneNumberInput.propTypes = {
  ...MaterialUITextField.propTypes,
  countryCode: PropTypes.string.isRequired,
  onCountryCodeChange: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

PhoneNumberInput.defaultProps = {
  component: ValidableTextField,
};

export default PhoneNumberInput;
