import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../../shared/BreadCrumbs";
import Card from "../../../shared/Card";
import ShippingCarrierForm from "../CarrierAssignmentCreate/ShippingCarrierForm";
import Divider from "../../../shared/Divider";
import FormCardHeader from "../../../shared/FormCardHeader";

export default class ShippingCarrierCreate extends Component {
  static propTypes = {
    shippingCarrier: PropTypes.object,
    navigateTo: PropTypes.func,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    fetchShippingCarriers: PropTypes.func,
    fetchCompany: PropTypes.func,
    patchCompanyShippingCarrierAssignment: PropTypes.func,
    fetchCompanyShippingCarrierAssignment: PropTypes.func,
    shippingCarriers: PropTypes.array,
    companyShippingCarrierAssignment: PropTypes.object,
    company: PropTypes.object,
  };

  state = {
    submitted: false,
    isCreating: false,
  };

  componentDidMount() {
    window.closeSplash();
    const {match: {params: {company_id: companyId, id}}} = this.props;
    this.props.fetchCompany(companyId);
    this.props.fetchCompanyShippingCarrierAssignment(companyId, id);
    this.props.fetchShippingCarriers();
  }

  companyUrl = () => {
    const {match: {params: {company_id: companyId}}} = this.props;
    return `/companies/${companyId}?tab=carriers`;
  };

  patchCompanyShippingCarrierAssignment = params => {
    const {
      navigateTo,
      match: {params: {company_id: companyId, id}},
    } = this.props;
    this.setState({isCreating: true});
    this.props.patchCompanyShippingCarrierAssignment(
      companyId,
      id,
      params,
      () => {
        navigateTo(this.companyUrl() || "/");
      }
    );
  };

  render() {
    const {
      company,
      isLoading,
      shippingCarriers,
      companyShippingCarrierAssignment,
    } = this.props;
    const {submitted, isCreating} = this.state;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
        subHeaderText={company ? company.name : null}
      >
        <BreadCrumbs
          rootName="Companies"
          middle={company && company.name}
          middleLink={company && `${company.id}`}
          pageName="Edit Shipping Carrier"
        />
        <Card>
          <div>
            <FormCardHeader
              title="Edit Shipping Carrier"
              cancelLink={this.companyUrl() || "/"}
              buttonText="Update"
              buttonIcon="check"
              noButton={isCreating}
              buttonClick={() =>
                this.setState({submitted: true}, () => {
                  this.setState({submitted: false});
                })
              }
            />
            <Divider marginBottom={24} marginTop={24} />
            <ShippingCarrierForm
              companyShippingCarrierAssignment={
                companyShippingCarrierAssignment
              }
              submitted={submitted}
              shippingCarriers={shippingCarriers}
              submitSuccess={this.patchCompanyShippingCarrierAssignment}
              submitError={() => {}}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
