import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withPagination from "../../modules/withPagination";
import withSorting from "../../modules/withSorting";
import * as actions from "../../../actions/brandActions";
import {fetchCompany} from "../../../actions/companyActions";

import BrandIndexPage from "../../pages/Brands/BrandIndexPage";

class BrandIndex extends Component {
  componentWillMount() {
    this.props.fetchCompany(this.props.match.params.id);
  }
  render() {
    return <BrandIndexPage {...this.props} />;
  }
}

BrandIndex.propTypes = {
  brands: PropTypes.array,
  company: PropTypes.object,
  fetchBrands: PropTypes.func,
  fetchCompany: PropTypes.func,
  searchBrands: PropTypes.func,
  match: PropTypes.object,
  isInternal: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    brands: state.brands.brands,
    company: state.companies.company,
    brandsCount: state.brands.brandsCount,
    isLoading: state.brands.isLoading,
    isInternal: state.auth.isInternal,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBrands: bindActionCreators(actions.fetchBrands, dispatch),
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    searchBrands: bindActionCreators(actions.searchBrands, dispatch),
  };
}

export default withSorting(
  withPagination(connect(mapStateToProps, mapDispatchToProps)(BrandIndex))
);
