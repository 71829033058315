import React, {Component} from "react";
import PropTypes from "prop-types";

import {getCountryRegionsFromCountryCode} from "../../utils/countryRegionsHelper";
import {formStyles} from "../../styles/components/formStyles";
import ValidableSelect from "./ValidableSelect";
import CNGMenuItem from "./CNGMenuItem";

class StateSelect extends Component {
  state = {
    regions: [],
  };

  componentDidMount() {
    const regions = getCountryRegionsFromCountryCode(this.props.countryCode);
    this.setState({regions});
  }

  componentDidUpdate(prevProps) {
    if (this.props.countryCode !== prevProps.countryCode) {
      const regions = getCountryRegionsFromCountryCode(this.props.countryCode);
      if (regions.length === 1) {
        this.props.handleChange({}, 0, regions[0].name, this.props.name);
      }
      this.setState({regions});
    }
  }

  renderRegions = () => {
    const {countryCode} = this.props;
    const {regions} = this.state;
    const regionsMenuItems = regions.map(region => {
      let title;
      if (["US", "USA"].includes(countryCode)) {
        title = region.shortCode;
      } else {
        title = region.name;
        if (region.shortCode) {
          title;
        }
      }
      return (
        <CNGMenuItem
          key={region.name}
          value={region.name}
          primaryText={title}
          title={["US", "USA"].includes(countryCode) ? region.name : title}
          innerDivStyle={formStyles.SelectMenuItemStyles}
        />
      );
    });
    if (!["US", "USA"].includes(countryCode) && regionsMenuItems.length !== 1) {
      regionsMenuItems.unshift(
        <CNGMenuItem key="no-region" value="" primaryText="No state/region" />
      );
    }
    return regionsMenuItems;
  };

  handleChange = (e, index, value) => {
    const {name, handleChange} = this.props;
    handleChange(e, index, value, name);
  };

  render() {
    const {countryCode, ...props} = this.props; // eslint-disable-line no-unused-vars
    return (
      <ValidableSelect
        {...props}
        floatingLabelFixed
        handleChange={this.handleChange}
        options={this.renderRegions()}
      />
    );
  }
}

StateSelect.propTypes = {
  ...ValidableSelect.propTypes,
  countryCode: PropTypes.string,
};

StateSelect.defaultProps = {
  name: "state",
  label: "State/Region/Province",
  countryCode: "US",
  style: formStyles.halfSelectStyles,
  inputStyle: formStyles.halfSelectInputStyles,
  iconStyle: formStyles.StateIconStyles,
};

export default StateSelect;
