import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ChillButton from "../../../shared/ChillButton";
import PageTitle from "../../../shared/PageTitle";
import EditInputAttribute from "../../../shared/EditInputAttribute";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

export default class BrandDetailHeader extends Component {
  static propTypes = {
    containerClass: PropTypes.string,
    titleIcon: PropTypes.string,
    onUpdateBrandName: PropTypes.func.isRequired,
    isInternal: PropTypes.bool,
    brand: PropTypes.object,
    onDeleteBrand: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleConfirmation = () => {
    this.handleClose();
    this.props.onDeleteBrand();
  };

  actions = () => {
    return [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.handleClose}
        key={1}
      />,
      <FlatButton
        label="Confirm"
        primary={true}
        onClick={this.handleConfirmation}
        key={2}
      />,
    ];
  };

  _renderButtons = () => {
    const {brand, isInternal} = this.props;

    return (
      <div className="row">
        {!isInternal ? null : (
          <ChillButton
            className="pageIndexHeader_btn"
            name="ARCHIVE BRAND"
            height={32}
            width={153}
            margin
            secondary={true}
            icon="archive"
            onClick={this.handleOpen}
          />
        )}

        {!isInternal ? null : (
          <Link
            to={`/brands/${brand && brand.id}/arts/create`}
            className="reset-link-styles"
          >
            <ChillButton
              className="pageIndexHeader_btn"
              name="ADD ART"
              height={32}
              width={102}
              icon="add2-white"
            />
          </Link>
        )}
      </div>
    );
  };

  _renderEditableName = () => {
    const {titleIcon, brand, isInternal, onUpdateBrandName} = this.props;

    return (
      <div className="row start-xs middle-xs">
        {titleIcon ? (
          <img
            src={`/assets/imgs/icons/${titleIcon}.svg`}
            className="pageIndexHeader_icon"
          />
        ) : null}
        {isInternal ? (
          <EditInputAttribute
            initialValue={brand && brand.name}
            onSave={onUpdateBrandName}
            width={550}
          />
        ) : (
          <PageTitle text={(brand && brand.name) || ""} />
        )}
      </div>
    );
  };

  render() {
    const {containerClass} = this.props;

    return (
      <div
        className={`pageIndexHeader row between-xs middle-xs ${containerClass}`}
        style={{marginBottom: 45}}
      >
        <Dialog
          title="Archive Brand"
          modal={true}
          open={this.state.open}
          actions={this.actions()}
          overlayClassName={"dialog-overlay"}
        >
          Do you want to archive this Brand?
        </Dialog>
        {this._renderEditableName()}
        {this._renderButtons()}
      </div>
    );
  }
}
