import React, {Component} from "react";
import PropTypes from "prop-types";

import Divider from "../Divider";

export default class Footer extends Component {
  static propTypes = {
    styles: PropTypes.string,
  };

  render() {
    let styles = this.props.styles ? this.props.styles : "";
    const currentYear = new Date().getFullYear();

    return (
      <footer className={`footer ${styles} non-printable`}>
        <Divider />
        <p className="small-text">Copyright 2017 - {currentYear} by chill-n-go, Inc</p>
      </footer>
    );
  }
}
