import React from "react";

import ValidableTextField from "../../../shared/ValidableTextField";
import {formStyles} from "../../../../styles/components/formStyles";

import LeatherForm from "./LeatherForm";

export default class LeatherAndDyeForm extends LeatherForm {
  validations = {
    dye_color: {format: "name", length: [2, 18]},
    height: {format: "decimal", length: [1, 10]},
    width: {format: "decimal", length: [1, 10]},
  };

  render() {
    const {onDetailChange, details: {height, width, dye_color}} = this.props;

    return (
      <div
        className="flex flex-wrap"
        style={{
          width: 710,
        }}
      >
        <ValidableTextField
          tabIndex={4}
          floatingLabelText="Dye Color"
          name="dye_color"
          label="Dye Color"
          value={dye_color}
          maxLength={this.validations.dye_color.length[1]}
          errorText={this.state.errors.dye_color}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          tabIndex={5}
          name="width"
          label="Width Size (inch)"
          value={width}
          maxLength={this.validations.width.length[1]}
          errorText={this.state.errors.width}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          tabIndex={6}
          name="height"
          label="Height Size (inch)"
          value={height}
          maxLength={this.validations.height.length[1]}
          errorText={this.state.errors.height}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
      </div>
    );
  }
}

const detailsInputStyle = {
  ...formStyles.HalfFieldStyles,
  marginRight: 16,
};
