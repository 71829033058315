import React, {Component} from "react";
import PropTypes from "prop-types";

import Layout from "../Base/Layout";
import {LayoutPropTypes} from "../Base/LayoutProps";

export default class ContentContainer extends Component {
  static propTypes = {
    ...LayoutPropTypes,
    subheader: PropTypes.bool,
    smallTop: PropTypes.bool,
    hasSubheader: PropTypes.bool,
  };
  render() {
    const {smallTop, hasSubheader} = this.props;
    return (
      <Layout
        {...this.props}
        className="ContentContainer"
        maxWidth={1116}
        minWidth={1116}
        marginTop={hasSubheader ? 40 : 0}
        marginHorizontal={"auto"}
        paddingTop={smallTop ? 20 : 48}
      />
    );
  }
}
