import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { parse } from "query-string";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _find from "lodash/find";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Typography from "../../../shared/Typography";
import QuoteLayout from "../../QuoteLayout";
import withStyles from "../../../shared/WithStyles";
import RaisedButton from "../../../shared/RaisedButton";
import FlatButton from "../../../shared/FlatButton";
import ContactInformation from "./ContactInformation";
import QuoteSidebar from "./QuoteSidebar";
import ChooseCategory from "./ChooseCategory";
import ChooseProduct from "./ChooseProduct";
import ChooseFabricColor from "./ChooseFabricColor";
import AddArt from "./AddArt";
import QuoteSummary from "./QuoteSummaryInfo";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import { USA_COUNTRY_ID } from "../../../../../constants/country";

class QuoteInfo extends Component {
  state = {
    quote: {
      companyName: "",
      name: "",
      lastName: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      countryId: USA_COUNTRY_ID,
      emailAddress: "",
      phoneNumber: "",
      items: [],
      arts: [],
    },
    currentItem: {},
    openArchiveModal: false,
  };

  async componentDidMount() {
    this.props.checkAuth();
    this.props.fetchCountries();
    this.props.fetchCategories({ sort: "id", sortDirection: "ASC", delay: 300 });
    this.props.fetchBagTypes();
    this.fetchArtInCompany();
    this.setState(await this.initialState());
  }

  async componentDidUpdate(prevProps) {
    const { location: { search }, quote, history, store, companyId } = this.props;
    if (
      prevProps.location.search !== search ||
      prevProps.companyId !== companyId
    ) {
      this.setState(await this.initialState());
      this.fetchArtInCompany();
    }
    if (prevProps.quote !== quote && quote) {
      store.deleteQuote();
      if (Object.keys(quote).includes("orderSource")) {
        history.replace(`/companies/${quote.companyId}/reorders`);
      } else {
        store.storeQuoteId(quote.id);
        history.replace("/quotes/complete");
      }

    }
  }

  initialState = async () => {
    const {
      location: { search },
      history,
      store,
      user,
      email,
      companyId,
      companyName: companyNameFromProps,
    } = this.props;
    const { companyName = "", knownCompany = false, knownUser = false, userEmail = "" } = parse(
      search
    );
    const isKnownUser = knownUser === "true";
    const isKnownCompany = knownCompany === "true";
    let quote = await store.loadQuote();
    if (isKnownUser) {
      if (user && companyId) {
        if (!quote) {
          const userNames = user.split(" ");
          quote = {
            isKnownCompany: true,
            companyName: companyNameFromProps,
            emailAddress: email,
            companyId,
            name: userNames[0],
            lastName: _get(userNames, "[1]"),
            items: [],
            arts: [],
          };
        }
      } else if (!quote || !quote.companyName) {
        history.replace("/quotes/new/info");
        return {};
      }
      const currentSection =
      quote.items && quote.items.length > 0 ? "summary" : "category";
      return {
        disabled: true,
        quote,
        currentItem: { id: "new" },
        isKnownUser,
        currentSection,
        showQuoteDetails: false,
      };
    } else if (user && companyId) {
      history.replace("/quotes/new/info?knownUser=true");
      return {};
    }
    let quoteData = {
      name: "",
      lastName: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      countryId: USA_COUNTRY_ID,
      emailAddress: email ? email : userEmail,
      phoneNumber: "",
      items: [],
      arts: [],
      companyName,
      isKnownCompany,
    };
    if (quote && isKnownCompany === quote.isKnownCompany) {
      quoteData = { ...quoteData, ...quote, isKnownCompany };
    }
    if (companyName && quote && companyName !== quote.companyName) {
      quoteData.companyName = companyName;
    }
    return {
      quote: quoteData,
      disabled: false,
      currentItem: { id: "new" },
      isKnownUser: false,
      showQuoteDetails: false,
    };
  };

  fetchArtInCompany = () => {
    const { companyId, fetchArtRevisionsByCompany } = this.props;
    if (companyId) {
      fetchArtRevisionsByCompany(companyId, {
        sort: "name",
        sortDirection: "ASC",
        filter: "active,eq,true",
      });
    }
  };

  handleError = () => {
    this.setState({ disabled: !this.form.isFormValid() });
  };

  handleChange = name => (event, value) => {
    const data = event.target.value || value;
    this.setState(({ quote }) => ({
      disabled: false,
      quote: {
        ...quote,
        [name]: data,
      },
    }));
  };

  handleItemChange = name => (event, value) => {
    this.setState(({ currentItem }) => ({
      currentItem: {
        ...currentItem,
        [name]: event.target.value || value,
      },
    }));
  };

  handleItemArtChange = (index, name) => (event, value) => {
    this.setState(({ currentItem }) => ({
      currentItem: {
        ...currentItem,
        arts: [
          ...currentItem.arts.slice(0, index),
          {
            ...currentItem.arts[index],
            [name]: event.target.value || value,
          },
          ...currentItem.arts.slice(index + 1),
        ],
      },
    }));
  };

  handleAddItemArt = () => {
    this.setState(({ currentItem }) => ({
      currentItem: {
        ...currentItem,
        arts: [...currentItem.arts, {}],
      },
    }));
  };

  handleRemoveItemArt = index => () => {
    this.setState(({ currentItem }) => ({
      currentItem: {
        ...currentItem,
        arts: [
          ...currentItem.arts.slice(0, index),
          ...currentItem.arts.slice(index + 1),
        ],
      },
    }));
  };

  handleCreateArt = async (filename, file) => {
    const { currentItem } = this.state;
    const { uploadArtFile } = this.props;
    const uuid = uuidv4();
    const data = await uploadArtFile(file, uuid);
    const newArt = {
      filename: data.name,
      uuid,
      ...data,
    };
    this.setState(({ quote: { arts, ...quote } }) => ({
      quote: {
        ...quote,
        arts: [...(arts || []), newArt],
      },
    }));
    this.handleItemArtChange(currentItem.arts.length - 1, "art")(
      { target: {} },
      uuid
    );
  };

  handleDeleteArt = uuid => () => {
    this.setState(({ quote: { arts, ...quote } }) => {
      const index = arts.findIndex(art => art.uuid === uuid);
      return {
        quote: {
          ...quote,
          arts: [...arts.slice(0, index), ...arts.slice(index + 1)],
        },
      };
    });
  };

  handleSubmit = async () => {
    const { history, store } = this.props;
    const { quote, isKnownUser } = this.state;
    await store.storeQuote(_cloneDeep(quote));
    if (!isKnownUser) {
      history.push(`/quotes/new/info?knownUser=true&companyName=${quote.companyName}&userEmail=${quote.emailAddress}`);
    }
  };

  handlePrevious = () => {
    const { history } = this.props;
    const { currentSection, quote: { items } } = this.state;
    switch (currentSection) {
    case "category":
      if (items && items.length > 0) {
        return this.setState({ currentSection: "summary" });
      }
      return history.goBack();
    case "product":
      return this.setState(({ currentItem }) => ({
        currentItem: {
          ...currentItem,
          product: null,
        },
        currentSection: "category",
      }));
    case "fabric-color":
      return this.setState(({ currentItem }) => ({
        currentItem: {
          ...currentItem,
          fabric: null,
          color: null,
        },
        currentSection: "product",
      }));
    case "art":
      return this.setState(({ currentItem }) => ({
        currentItem: {
          ...currentItem,
          filename: "",
          adornmentLocation: null,
        },
        currentSection: "fabric-color",
      }));
    default:
      return;
    }
  };

  moveCurrentItemToItemList = async (fabricNone, colorNone) => {
    const { store } = this.props;
    const { quote: { items, ...quote }, currentItem } = this.state;
    let newItems = [...items];
    if (fabricNone) {
      currentItem.fabric = fabricNone;
      currentItem.color = colorNone;
      currentItem.artOption = "no-art";
      currentItem.arts = [];
    }
    if (currentItem.id === "new") {
      newItems.push({ ...currentItem, id: uuidv4() });
    } else {
      const index = items.findIndex(item => item.id === currentItem.id);
      if (index >= 0) {
        newItems = [
          ...items.slice(0, index),
          currentItem,
          ...items.slice(index + 1),
        ];
      }
    }
    const newState = {
      currentSection: "summary",
      currentItem: { id: "new" },
      quote: {
        ...quote,
        items: [...newItems],
      },
    };
    await store.storeQuote(
      _cloneDeep({
        ...quote,
        items: newState.quote.items,
      })
    );
    this.setState(newState);
  };

  handleContinue = async () => {
    const {
      companyArts,
      fetchFabrics,
      fetchColors,
      fetchAdornmentLocationsByProduct,
    } = this.props;
    const {
      isKnownUser,
      currentItem: { categoryId, product, color },
      currentSection,
      quote: { arts},
    } = this.state;
    if (!isKnownUser) {
      return this.form.submit();
    }
    if (categoryId && currentSection === "category") {
      return this.setState({ currentSection: "product" });
    }
    if (product && product.id && currentSection === "product") {
      const { data: fabrics } = await fetchFabrics({
        filter: `productFabricPricing.product_id,eq,${product.id}`,
      });
      if (fabrics.length === 1 && fabrics[0].name === "None") {
        const filters = [
          ["fabricAvailableColor.active", "eq", true],
          ["fabricAvailableColor.fabric_id", "eq", fabrics[0].id],
        ];
        const { data: colors } = await fetchColors({
          filter: filters.join("|"),
          sort: "name",
          sortDirection: "asc",
        });
        return this.moveCurrentItemToItemList(fabrics[0], colors[0]);
      }
      return this.setState({ currentSection: "fabric-color" });
    }
    if (color && currentSection === "fabric-color") {
      let firstArt;
      if (companyArts && companyArts.length > 0) {
        firstArt = companyArts[0].id;
      }
      if (!firstArt && arts && arts.length > 0) {
        firstArt = arts[0].uuid;
      }
      this.setState(({ currentItem }) => ({
        currentSection: "art",
        currentItem: {
          ...currentItem,
          artOption: "upload-art",
          arts: [{ art: firstArt }],
        },
      }));
      return fetchAdornmentLocationsByProduct(product.id, {
        sort: "index",
        sortDirection: "ASC",
      });
    }
    if (currentSection === "art") {
      return this.moveCurrentItemToItemList();
    }

  };

  handleToggleQuoteDetails = () => {
    this.setState(({ showQuoteDetails }) => ({
      showQuoteDetails: !showQuoteDetails,
    }));
  };

  handleAddItem = () => {
    this.setState({ currentSection: "category" });
  };

  handleEditItem = id => () => {
    const { quote: { items } } = this.state;
    const item = items.find(item => item.id === id);
    this.setState({
      currentItem: item,
      currentSection: "product",
    });
  };

  handleUpdateItems = items => {
    this.setState({ items });
  };

  handleRemoveItem = id => () => {
    const { store } = this.props;
    const { quote: { items, ...quote } } = this.state;
    const index = items.findIndex(item => item.id === id);
    const newItems = [...items.slice(0, index), ...items.slice(index + 1)];

    store.storeQuote(
      _cloneDeep({
        ...quote,
        items: newItems,
      })
    );
    this.setState({ quote: { ...quote, items: newItems } });
  };

  handleSubmitQuote = () => {
    const { companyId, createQuote } = this.props;
    const { quote } = this.state;
    var quoteData = _cloneDeep(quote);

    quoteData.items.map(item => {
      const { quantity } = item;
      item.quantity = quantity ? quantity : 0;
    }
    );

    if (companyId) {
      quoteData.companyId = companyId;
    }
    createQuote(quoteData);
  };

  handleUpdateItemQuantityValue = itemIndex => async value => {
    const { fetchItemPrice, companyArts } = this.props;
    const { quote: { items, arts } } = this.state;
    const item = items[itemIndex];
    let netUnitCost;
    if (value) {
      const itemPrice = await fetchItemPrice(item, arts, companyArts, value);
      netUnitCost = itemPrice.netUnitCost;
    } else {
      netUnitCost = value;
    }
    this.handleSetStateQuote(itemIndex, item, value, netUnitCost );
  };

  handleSetStateQuote = (itemIndex,item, value, netUnitCost ) => {
    this.setState(({ quote: { items, ...quote } }) => ({
      quote: {
        ...quote,
        items: [
          ...items.slice(0, itemIndex),
          {
            ...item,
            quantity: value,
            unitCost: netUnitCost,
          },
          ...items.slice(itemIndex + 1),
        ],
      },
    }));
  }

  handleOpenArchiveModal = () => this.setState({ openArchiveModal: true });

  handleDismissArchiveModal = () => this.setState({ openArchiveModal: false });

  handleConfirmArchiveModal = async () => {
    const { store, isAuthenticated } = this.props;
    store.deleteQuote();
    const url = isAuthenticated ? "/companies" : "https://www.chill-n-go.com/playbookcng.html";
    window.assignLocation(url);
  };

  renderCurrent = category => {
    const {
      countries,
      categories,
      email,
      products,
      fabrics,
      colors,
      types,
      capacities,
      locations,
      isAuthenticated,
      isLoadingCategories,
      isLoadingProducts,
      isLoadingFabrics,
      isLoadingColors,
      isLoadingPrices,
      isLoading,
      isLoadingArtFile,
      fetchProducts,
      fetchFabrics,
      fetchColors,
      fetchBagTypes,
      fetchBagCapacities,
      itemPrices,
      fetchItemPriceTable,
      openNotification,
      companyArts,
      styles: { underlineDisabledStyle },
      fetchAdornmentLocationsByProduct,
      userEmailValidationByCompany,
      fetchUserEmailValidationByCompany,
      history,
      companyName: companyNameFromProps,
      location: { search }
    } = this.props;
    const { userEmail } = parse(
      search
    );
    const {
      quote: {
        companyName,
        name,
        lastName,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
        countryId,
        items,
        isKnownCompany,
        arts,
      },
      isKnownUser,
      currentItem,
      currentSection,
    } = this.state;
    const accessoryCategoryId = _find(categories, ["name", "Accessories"]);

    if (isKnownUser) {
      switch (currentSection) {
      case "category":
        return (
          <ChooseCategory
            history={history}
            categories={categories}
            userEmail={email ? email : userEmail}
            companyName={companyNameFromProps ? companyNameFromProps : companyName}
            isKnownCompany={isKnownCompany}
            isKnownUser={isKnownUser}
            isLoading={isLoadingCategories}
            item={currentItem}
            onChange={this.handleItemChange}
            fetchUserEmailValidationByCompany={fetchUserEmailValidationByCompany}
            userEmailValidationByCompany={userEmailValidationByCompany}
            pageStyle={this.pageStyle}
          />
        );
      case "product":
        return (
          <ChooseProduct
            item={currentItem}
            category={category}
            products={products}
            types={types}
            capacities={capacities}
            isLoading={isLoadingProducts}
            fetchProducts={fetchProducts}
            fetchBagTypes={fetchBagTypes}
            fetchBagCapacities={fetchBagCapacities}
            onChange={this.handleItemChange}
            pageStyle={this.pageStyle}
          />
        );
      case "fabric-color":
        return (
          <ChooseFabricColor
            fabrics={fabrics}
            colors={colors}
            item={currentItem}
            isLoadingFabrics={isLoadingFabrics}
            isLoadingColors={isLoadingColors}
            onChange={this.handleItemChange}
            fetchFabrics={fetchFabrics}
            fetchColors={fetchColors}
            pageStyle={this.pageStyle}
          />
        );
      case "art":
        return (
          <AddArt
            item={currentItem}
            items={items || []}
            arts={arts || []}
            companyArts={companyArts || []}
            locations={locations}
            onChange={this.handleItemChange}
            onChangeItemArt={this.handleItemArtChange}
            onAddItemArt={this.handleAddItemArt}
            onRemoveItemArt={this.handleRemoveItemArt}
            onCreateArt={this.handleCreateArt}
            onDeleteArt={this.handleDeleteArt}
            openNotification={openNotification}
            pageStyle={this.pageStyle}
            isLoadingArt={isLoadingArtFile}
          />
        );
      case "summary":
        return (
          <QuoteSummary
            handleSetStateQuote={this.handleSetStateQuote}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            isKnownCompany={isKnownCompany}
            name={name}
            lastName={lastName}
            companyName={companyNameFromProps ? companyNameFromProps : companyName}
            emailAddress={email ? email : emailAddress}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zipCode={zipCode}
            countryId={countryId}
            countries={countries}
            items={items}
            arts={arts}
            companyArts={companyArts || []}
            onAddItem={this.handleAddItem}
            onEditItem={this.handleEditItem}
            onRemoveItem={this.handleRemoveItem}
            onSubmitQuote={this.handleSubmitQuote}
            onUpdateItems={this.handleUpdateItems}
            onCreateArt={this.handleCreateArt}
            onDeleteArt={this.handleDeleteArt}
            openNotification={openNotification}
            itemPrices={itemPrices}
            fetchItemPriceTable={fetchItemPriceTable}
            onUpdateItemQuantityValue={this.handleUpdateItemQuantityValue}
            pageStyle={this.pageStyle}
            isLoadingPrices={isLoadingPrices}
            onOpenArchiveModal={this.handleOpenArchiveModal}
            fetchProducts={fetchProducts}
            fetchFabrics={fetchFabrics}
            fetchColors={fetchColors}
            fetchAdornmentLocationsByProduct={fetchAdornmentLocationsByProduct}
            fabrics={fabrics}
            products={products}
            categories={categories}
            locations={locations}
            colors={colors}
            accessoryCategoryId={accessoryCategoryId ? accessoryCategoryId.id : 0}
          />
        );
      default:
        return;
      }
    }
    return (
      <Fragment>
        <Typography variant="h1" style={this.pageStyle.title}>
          {isKnownCompany ? "Thanks!" : "About your Company"}
        </Typography>

        {isKnownCompany && (
          <Typography variant="h5">
            We just need your email address for now. We&apos;ll confirm your
            Company information later in the process.
          </Typography>
        )}
        <ValidatorForm
          ref={node => {
            if (node) this.form = node;
          }}
          method="POST"
          onSubmit={this.handleSubmit}
          onError={this.handleError}
        >
          <div className="flex flex-wrap" style={this.pageStyle.form}>
            <TextValidator
              autoFocus
              disabled={isKnownCompany}
              style={this.pageStyle.input}
              name="companyName"
              floatingLabelText="Name of the Company"
              value={companyName}
              onChange={this.handleChange("companyName")}
              autoComplete="off"
              margin="normal"
              validators={["required"]}
              errorMessages={["this field is required"]}
              validatorListener={this.handleError}
              className="margin-right-20"
              underlineDisabledStyle={underlineDisabledStyle}
            />
            {isKnownCompany && (
              <TextValidator
                style={this.pageStyle.input}
                name="emailAddress"
                floatingLabelText="E-Mail"
                value={emailAddress}
                onChange={this.handleChange("emailAddress")}
                autoComplete="off"
                margin="normal"
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
                validatorListener={this.handleError}
              />
            )}
          </div>

          {!isKnownCompany && (
            <ContactInformation
              state={this.state.quote}
              countries={countries}
              onChange={this.handleChange}
              userEmail={userEmail}
              onError={this.handleError}
              pageStyle={this.pageStyle}
            />
          )}
        </ValidatorForm>
      </Fragment>
    );
  };

  isContinueButtonDisabled = () => {
    const { disabled, isKnownUser, currentSection, currentItem } = this.state;
    if (isKnownUser) {
      switch (currentSection) {
      case "category":
        return !currentItem.categoryId;
      case "product":
        return !currentItem.product;
      case "fabric-color":
        return !currentItem.color;
      case "art":
        switch (currentItem.artOption) {
        case "no-art":
          return false;
        case "upload-art":
          return currentItem.arts.some(art => {
            return !art.art || !art.adornmentLocation;
          });
        default:
          return true;
        }
      default:
        return true;
      }
    }
    return disabled;
  };

  getContinueButtonLabel = () => {
    const { currentSection } = this.state;
    switch (currentSection) {
    case "art":
      return "Add to Quote Request";
    default:
      return "Continue";
    }
  };

  renderSidebar = (
    category,
    product,
    fabric,
    color,
    items,
    showQuoteDetails
  ) => {
    if (this.state.currentSection === "summary") {
      return null;
    }
    return (
      <QuoteSidebar
        showQuoteDetails={showQuoteDetails}
        items={items}
        category={category}
        product={product}
        fabric={fabric}
        color={color}
      />
    );
  };

  renderSummary = () => {
    const { isAuthenticated, companyName } = this.props;
    const { currentSection } = this.state;
    if (isAuthenticated) {
      return companyName;
    }
    if (currentSection === "summary") {
      return (
        <img
          src="/assets/imgs/ChillNGo-white.png"
          style={this.pageStyle.headerLogo}
        />
      );
    }
  };

  get pageStyle() {
    const { isAuthenticated, styles: { colors } } = this.props;
    const { currentSection } = this.state;
    return {
      container: {
        overflow: "auto",
        paddingTop: 80,
        paddingLeft: 41,
        height: `calc(100vh - ${isAuthenticated ? (currentSection === "summary" ? 112 : 196) : 84
        }px)`,
      },
      footer: {
        height: 84,
        backgroundColor: colors.gray.lighter,
      },
      title: {
        marginBottom: 9,
      },
      previousButton: {
        marginRight: 40,
      },
      submitButton: {
        marginRight: 40,
      },
      submitButtonLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      input: {
        minWidth: 238,
      },
      form: {
        marginTop: 18,
      },
      companyContacts: {
        marginTop: 38,
      },
      companyName: {
        marginBottom: 8,
        minHeight: 28,
      },
      name: {
        textAlign: "center",
      },
      image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      headerLogo: {
        width: 150,
        marginTop: 7,
      },
    };
  }

  isLoading = () => {
    const {
      isLoadingCategories,
      isLoadingProducts,
      isLoadingFabrics,
      isLoadingColors,
    } = this.props;
    return (
      isLoadingCategories ||
      isLoadingProducts ||
      isLoadingFabrics ||
      isLoadingColors
    );
  };

  render() {
    const { categories, isAuthenticated, user, isInternal, logout, companyName } = this.props;
    const {
      isKnownUser,
      currentSection,
      openArchiveModal,
      quote,
      currentItem: { categoryId, product, fabric, color },
      showQuoteDetails,
    } = this.state;
    const { items } = quote;
    const category = categories.find(category => categoryId === category.id);
    return (
      <QuoteLayout
        showMainHeader={isAuthenticated}
        user={user}
        isInternal={isInternal}
        isLoading={this.isLoading()}
        showSidebar={currentSection !== "summary"}
        quote={quote}
        companyName={companyName}
        sidebarComponent={this.renderSidebar(
          category,
          product,
          fabric,
          color,
          items,
          showQuoteDetails
        )}
        subHeaderText={this.renderSummary()}
        withTopMargin={false}
        onToggleQuoteDetails={this.handleToggleQuoteDetails}
        logout={logout}
      >
        <div style={this.pageStyle.container}>
          {this.renderCurrent(category, product)}
        </div>
        {currentSection !== "summary" && (
          <footer
            style={this.pageStyle.footer}
            className="flex justify-content-end align-items-center"
          >
            {isKnownUser && (
              <FlatButton
                large
                label="Previous"
                style={this.pageStyle.previousButton}
                onClick={this.handlePrevious}
              />
            )}
            <RaisedButton
              large
              disabled={this.isContinueButtonDisabled()}
              label={this.getContinueButtonLabel()}
              style={this.pageStyle.submitButton}
              labelStyle={this.pageStyle.submitButtonLabel}
              onClick={this.handleContinue}
            />
          </footer>
        )}
        <ConfirmationModal
          title="Do you want to quit this Quote?"
          content={
            <Fragment>
              Oh no!! Sorry to see you go!
              <br />
              If it’s something we did, please reach out.
            </Fragment>
          }
          confirmText="Yes"
          cancelText="No"
          open={openArchiveModal}
          onConfirm={this.handleConfirmArchiveModal}
          onDismiss={this.handleDismissArchiveModal}
        />
      </QuoteLayout>
    );
  }
}

QuoteInfo.propTypes = {
  store: PropTypes.object,
  styles: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  quote: PropTypes.object,
  fetchCountries: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fetchBagTypes: PropTypes.func,
  fetchBagCapacities: PropTypes.func,
  fetchAdornmentLocationsByProduct: PropTypes.func,
  openNotification: PropTypes.func.isRequired,
  checkAuth: PropTypes.func.isRequired,
  fetchArtRevisionsByCompany: PropTypes.func.isRequired,
  createQuote: PropTypes.func.isRequired,
  countries: PropTypes.array,
  categories: PropTypes.array,
  products: PropTypes.array,
  fabrics: PropTypes.array,
  colors: PropTypes.array,
  types: PropTypes.array,
  capacities: PropTypes.array,
  locations: PropTypes.array,
  companyArts: PropTypes.array,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  isLoadingCategories: PropTypes.bool,
  isLoadingProducts: PropTypes.bool,
  isLoadingFabrics: PropTypes.bool,
  isLoadingColors: PropTypes.bool,
  itemPrices: PropTypes.array,
  fetchItemPriceTable: PropTypes.func,
  fetchItemPrice: PropTypes.func,
  isLoadingPrices: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.string,
  isInternal: PropTypes.bool,
  logout: PropTypes.func,
  uploadArtFile: PropTypes.func,
  isLoadingArtFile: PropTypes.bool,
  errorMessages: PropTypes.array,
  userEmail: PropTypes.string,
  userEmailValidationByCompany: PropTypes.bool,
  fetchUserEmailValidationByCompany: PropTypes.func,
  email: PropTypes.string,
};

export default withStyles(QuoteInfo);
