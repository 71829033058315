import React from "react";
import PropTypes from "prop-types";

import Typography from "../../../shared/Typography";
import CheckboxWithTooltip from "../../../shared/CheckboxWithTooltip";

const Proofs = ({
  noSewOut,
  requestSewOut,
  requestPreProductionProof,
  optionRequestHandler,
}) => (
  <div className="margin-bottom-16 margin-top-24">
    <Typography variant="bodyBold" style={{marginBottom: 16}}>
      Place Order with Further Proof
    </Typography>
    <CheckboxWithTooltip
      label="Pre-Production Proof"
      title="Cost: S/H is invoiced.  Normally required on new orders of 24 or more units.  An approved proof counts as '1' of your order total - we will make the balance.  Rejected proofs will be returned at our expense.  A new/corrected proof will be made and sent by next working day."
      checked={requestPreProductionProof}
      onCheck={optionRequestHandler("requestPreProductionProof")}
    />
    {noSewOut ? null : (
      <CheckboxWithTooltip
        label="Sew-Out"
        title="Cost: S/H is invoiced - there is no cost for the sew-out.  You may expect same-day or next-working-day shipment."
        checked={requestSewOut}
        onCheck={optionRequestHandler("requestSewOut")}
      />
    )}
  </div>
);

Proofs.propTypes = {
  noSewOut: PropTypes.bool,
  requestSewOut: PropTypes.bool,
  requestPreProductionProof: PropTypes.bool,
  optionRequestHandler: PropTypes.func.isRequired,
};

export default Proofs;
