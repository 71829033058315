import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchEmbroideryDetailsRequest = () =>
  makeCommonAction(types.FETCH_EMBROIDERY_DETAILS_REQUEST);
const fetchEmbroideryDetailsSuccess = payload =>
  makeCommonAction(types.FETCH_EMBROIDERY_DETAILS_SUCCESS, payload);
const fetchEmbroideryDetailsError = error =>
  makeCommonAction(types.FETCH_EMBROIDERY_DETAILS_ERROR, error, true);

const createEmbroideryDetailsRequest = () =>
  makeCommonAction(types.CREATE_EMBROIDERY_DETAILS_REQUEST);
const createEmbroideryDetailsSuccess = payload =>
  makeCommonAction(types.CREATE_EMBROIDERY_DETAILS_SUCCESS, payload);
const createEmbroideryDetailsError = error =>
  makeCommonAction(types.CREATE_EMBROIDERY_DETAILS_ERROR, error, true);

export function fetchEmbroideryDetails() {
  return makeActionCreator({
    endpoint: "/embroiderydetails",
    fetchOptions: {method: "GET"},
    requestFunc: fetchEmbroideryDetailsRequest,
    successFunc: fetchEmbroideryDetailsSuccess,
    errorFunc: fetchEmbroideryDetailsError,
    error_message: "Error fetching embroidery details",
  });
}

export function createEmbroideryDetail(details) {
  return makeActionCreator({
    endpoint: "/embroiderydetails/",
    fetchOptions: {body: details, method: "POST"},
    requestFunc: createEmbroideryDetailsRequest,
    successFunc: createEmbroideryDetailsSuccess,
    errorFunc: createEmbroideryDetailsError,
    error_message: "Error creating embroidery details",
  });
}
