import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import NumberFormat from "react-number-format";
import Dialog from "material-ui/Dialog";
import Divider from "material-ui/Divider";
import FontIcon from "material-ui/FontIcon";
import TextField from "material-ui/TextField";

import { getCurrencyFormattedNumber } from "../../../../../utils/numberFormat";
import ArtsTable from "../../../../pages/DashboardPage/ArtsTable";
import Card from "../../../../shared/Card";
import CNGImage from "../../../../shared/CNGImage";
import Typography from "../../../shared/Typography";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import QuoteItemPricingTable from "../QuoteItemPricingTable";
import QuoteItemArtsTable from "../QuoteItemArtsTable";
import PricingTableButton from "../PricingTableButton";
import HidePricingTableButton from "../HidePricingTableButton";
import ImagesModal from "../ImagesModal";
import DigitizedArtsPreview from "./DigitizedArtsPreview";
import CNGToolTip from "../../../../shared/CNGToolTip";

class QuoteItemDetail extends Component {
  state = {
    pricingTableOpen: false,
    openArchiveModal: false,
    openMockupsModal: false,
    openDigitizedArtModal: false,
    budget: 0,
    residualBudget: getCurrencyFormattedNumber(0),
  };

  componentDidUpdate(prevProps, prevState) {
    const { id, priceTableOpenId, companyArts } = this.props;
    if (
      prevProps.priceTableOpenId &&
      priceTableOpenId &&
      priceTableOpenId !== prevProps.priceTableOpenId &&
      priceTableOpenId !== id
    ) {
      this.setState({ pricingTableOpen: false });
    }
    if (
      !prevProps.priceTableOpenId &&
      priceTableOpenId &&
      priceTableOpenId === id
    ) {
      this.handleOpenPricingTable();
    }
    if (
      priceTableOpenId === id &&
      prevProps.companyArts &&
      companyArts &&
      prevProps.companyArts.length !== companyArts.length
    ) {
      this.handleOpenPricingTable();
    }

    if (this.state.budget !== prevState.budget) {
      const { fetchItemPriceTable,  quoteArts, companyArts } = this.props;
      fetchItemPriceTable(this.props, quoteArts, companyArts);
      this.handleQty();
    }

    if (this.props.quantity !== prevProps.quantity) {
      this.handleBudget();
    }
  }

  handleOpenArchiveModal = () => this.setState({ openArchiveModal: true });

  handleDismissArchiveModal = () => this.setState({ openArchiveModal: false });

  handleOpenPricingTable = () => {
    const {
      id,
      quoteArts,
      companyArts,
      fetchItemPriceTable,
      onPriceTableOpen,
    } = this.props;
    fetchItemPriceTable(this.props, quoteArts, companyArts);
    onPriceTableOpen(id);
    this.setState({ pricingTableOpen: true });
  };

  handleClosePricingTable = () => {
    const { onPriceTableOpen } = this.props;
    onPriceTableOpen(null);
    this.setState({ pricingTableOpen: false });
  };


  handleBudgetChange = event => {
    this.setState({ budget: event.target.value });
  };

  handleChange = event => {
    return this.updateItemQuantityValue(event.target.value);
  };

  updateItemQuantityValue = _debounce(
    qty => {
      const { onUpdateItemQuantityValue } = this.props;
      return onUpdateItemQuantityValue(qty);
    },
    700,
    {
      leading: false,
      trailing: true,
    }
  );

  handleOptionRequest = name => () => {
    const { onUpdateItemData, [name]: prop } = this.props;
    onUpdateItemData(name, !prop);
  };

  foundQty = (qty = 1, selectedItem) => {
    const { 
      itemPrices,
      fetchItemPriceTable,
      quoteArts,
      companyArts
    } = this.props;
    const { budget } = this.state;

    if (itemPrices.length === 0) {
      fetchItemPriceTable(this.props, quoteArts, companyArts);
    }

    const item = itemPrices.find( item => ( qty >= item.quantityMin && qty <= item.quantityMax));
    const quantity = Math.floor(parseFloat(budget) / _get(item, "netUnitCost"));

    if (item === selectedItem) {
      return quantity;
    }
    return this.foundQty(quantity, item);
  }

  handleQty = () => {
    const { quantity  } = this.props;
    const qty = this.foundQty();
    quantity === qty && this.handleBudget();
    const event = { target: {value: qty}};
    this.handleChange(event);
  }

  handleBudget = () => {
    const { unitCost, quantity } = this.props;
    const { budget } = this.state;

    const quantityCost = (unitCost * quantity);
    let residual = getCurrencyFormattedNumber( quantityCost > 0 ? (budget -  quantityCost) : 0 );
    residual = getCurrencyFormattedNumber( budget > 0 ? residual : 0 );

    this.setState({ residualBudget: residual });
  }

  renderFabricColor = () => {
    const { fabric, color } = this.props;
    return (
      <div className="flex margin-bottom-16">
        <div className="flex flex-column">
          <Typography
            variant="subtitleBold"
            className="text-uppercase"
            color="gray.light"
          >
            Fabric
          </Typography>
          <Typography variant="body" className="margin-top-8">
            {fabric && fabric.name}
          </Typography>
        </div>
        <div className="flex flex-column margin-left-40">
          <Typography
            variant="subtitleBold"
            className="text-uppercase"
            color="gray.light"
          >
            Color
          </Typography>
          <Typography variant="body" className="margin-top-8">
            {color && color.name}
          </Typography>
        </div>
      </div>
    );
  };

  renderArts = () => {
    const {
      pageStyle,
      quoteStatus,
      quoteId,
      quoteArts,
      artDigitizationRenderConfig,
    } = this.props;
    const newArts = this.newItemArts;
    const existingArts = this.existingItemArts;
    return (
      <Fragment>
        {newArts &&
          newArts.length > 0 &&
          existingArts &&
          existingArts.length > 0 && (
          <Typography variant="subtitle">New Art</Typography>
        )}
        {newArts &&
          newArts.length > 0 && (
          <QuoteItemArtsTable
            detailOnly
            quoteStatus={quoteStatus}
            quoteId={quoteId}
            arts={newArts}
            quoteArts={quoteArts}
            pageStyle={pageStyle}
            artDigitizationRenderConfig={artDigitizationRenderConfig}
            isInternal={false}
          />
        )}
        {newArts &&
          newArts.length > 0 &&
          existingArts &&
          existingArts.length > 0 && (
          <Typography variant="subtitle">Company Art</Typography>
        )}
        {existingArts &&
          existingArts.length > 0 && (
          <ArtsTable showArtLocation arts={existingArts} />
        )}
      </Fragment>
    );
  };

  getFirstDigitizedArt = () => {
    const { arts, companyArts, quoteArts } = this.props;
    if (arts.length < 1) return null;
    let art;
    const firstArt = arts[0];
    if (quoteArts.length > 0) {
      art = quoteArts.find(art => art.uuid === firstArt.art);
    }
    if (art) return { imageUrl: art.digitizedImageUrl };
    if (companyArts && companyArts.length > 0) {
      art = companyArts.find(art => art.id === firstArt.art);
    }
    return art;
  };

  getDigitizedArts = () => {
    const { arts, companyArts, quoteArts } = this.props;
    if (!arts || arts.length < 1) return [];
    return arts.map(art => {
      let digitizedArt = {};
      if (quoteArts && quoteArts.length > 0) {
        const quoteArt = quoteArts.find(quoteArt => quoteArt.uuid === art.art);
        if (quoteArt) {
          digitizedArt.name = `${quoteArt.name} (${art.adornmentLocation.location
          })`;
          digitizedArt.imageUrl = quoteArt.digitizedImageUrl;
        }
      }
      if (companyArts && companyArts.length > 0) {
        const companyArt = companyArts.find(
          companyArt => companyArt.id === art.art
        );
        if (companyArt) {
          digitizedArt.name = `${companyArt.name} (${art.adornmentLocation.location
          })`;
          digitizedArt.imageUrl = companyArt.imageUrl;
        }
      }
      return digitizedArt;
    });
  };

  getDeleteModalTitle = () => {
    const { itemsCount } = this.props;
    if (itemsCount < 2) {
      return "This action will delete this item and the quote, are you sure you wish to continue? It cannot be accessed in the future.";
    }
    return "This action will delete this item, do you want to continue?";
  };

  handleRequestPreProductionProof = () => {
    const { requestPreProductionProof, onUpdateItemData } = this.props;
    onUpdateItemData("requestPreProductionProof", !requestPreProductionProof);
  };

  handleShowMockupsModal = () => this.setState({ openMockupsModal: true });

  handleHideMockupsModal = () => this.setState({ openMockupsModal: false });

  handleExpandDigitizedArt = () => this.setState({ openDigitizedArtModal: true });

  handleHideDigitizedArt = () => this.setState({ openDigitizedArtModal: false });

  get newItemArts() {
    const { arts, quoteArts } = this.props;
    const newArts = arts
      .map((art, artIndex) => ({ ...art, artIndex }))
      .filter(art => {
        return quoteArts.find(quoteArt => quoteArt.uuid === art.art);
      });
    return newArts;
  }

  get existingItemArts() {
    const { arts, companyArts, pageStyle } = this.props;
    const existingArts = arts.reduce((revisions, art) => {
      const revision = companyArts
        ? companyArts.find(companyArt => companyArt.id === art.art)
        : null;
      if (revision) {
        revisions.push({
          ...revision.artSource,
          revisions: [revision],
          adornmentLocation: art.adornmentLocation,
          digitizedArtPreview: (
            <div className="flex flex-center">
              <CNGToolTip
                parentRef={`digitized-art-${art.art}`}
                style={{
                  width: 250,
                  border: "1px solid #BDC6CF",
                }}
                element={{ imageUrl: revision.imageUrl }}
                position="top"
              >
                <div
                  className="flex justify-content-between align-items-center z-index-3"
                  style={pageStyle.artFile}
                >
                  <div
                    className="flex align-items-center"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src={revision.imageUrl}
                      style={{
                        width: 32,
                        height: 32,
                        objectFit: "contain",
                      }}
                    />
                    <Typography
                      component="span"
                      variant="bodyBold"
                      color="secondary.light"
                      className="version--2 secondary-link-style"
                      style={pageStyle.artFileName}
                    >
                      {revision.name}
                    </Typography>
                  </div>
                </div>
              </CNGToolTip>
            </div>
          ),
        });
      }
      return revisions;
    }, []);
    return existingArts;
  }

  render() {
    const {
      itemPrices,
      quantity,
      unitCost,
      product,
      artOption,
      mockups,
      pageStyle,
      isLoadingPrices,
      disabled,
      onRemoveItem,
      quoteStatus,
      hideRemove,
    } = this.props;
    const {
      openArchiveModal,
      pricingTableOpen,
      openMockupsModal,
      openDigitizedArtModal,
      budget,
      residualBudget,
    } = this.state;
    const isQuoteApproved = !["new", "sent_for_approval"].includes(quoteStatus);
    const fabricColor = this.renderFabricColor();
    const digitizedArt = this.getFirstDigitizedArt();
    const productImage = _get(
      mockups,
      "[0].imageUrl",
      _get(product, "productImages[0].imageUrlThumbnail", product.imageUrl)
    );
    return (
      <Card className="flex flex-column padding-32 margin-bottom-16">
        <div className="flex">
          <div className="flex flex-column" style={pageStyle.imageContainer}>
            <Fragment>
              <ImagesModal
                open={openMockupsModal}
                images={
                  mockups ||
                  [
                    {
                      imageUrl: _get(
                        product,
                        "productImages[0].imageUrlThumbnail",
                        product.imageUrl
                      ),
                    },
                  ]
                }
                onClose={this.handleHideMockupsModal}
              />
            </Fragment>
            <div className="initial">
              <CNGImage
                src={productImage}
                placeHolderStyle={pageStyle.image}
                style={pageStyle.image}
              />
              <div className="pointer flex" style={{ justifyContent: "center", padding: 10 }} onClick={this.handleShowMockupsModal}>
                <FontIcon
                  className="material-icons"
                  style={{
                    color: "#497E95",
                    fontSize: 20,
                  }}
                >
                  search
                </FontIcon>
                <div style={{ color: "#497E95" }}>
                  Expand
                </div>
              </div>
            </div>

          </div>
          <div className="flex flex-column justify-content-between flex-one">
            <Typography variant="subtitle">
              Item #{product.itemNumber}
            </Typography>
            <div className="flex justify-content-between margin-bottom-16">
              <Typography variant="h3">{product.productName}</Typography>
              <div className="flex">
                {pricingTableOpen ? null : (
                  <PricingTableButton
                    onOpenPricingTable={this.handleOpenPricingTable}
                  />
                )}
                {!hideRemove && !isQuoteApproved ? (
                  <span
                    style={pageStyle.removeButton}
                    onClick={this.handleOpenArchiveModal}
                  >
                    <span>Remove</span>
                    <span
                      style={{
                        alignSelf: "center",
                        marginLeft: 4,
                      }}
                    >
                      <FontIcon
                        className="material-icons"
                        style={pageStyle.closeIcon}
                      >
                        close
                      </FontIcon>
                    </span>
                  </span>
                ) : null}
              </div>
            </div>
            {fabricColor}
            {this.renderArts()}
            {!isQuoteApproved && pricingTableOpen ? (
              <Fragment>
                <div className="flex justify-content-end">
                  <HidePricingTableButton
                    onHidePricingTable={this.handleClosePricingTable}
                  />
                </div>
                <QuoteItemPricingTable
                  showDetails
                  detailOnly
                  prices={itemPrices}
                  isLoading={isLoadingPrices}
                  tableStyle={{ marginTop: 24 }}
                />
              </Fragment>
            ) : null}
            {!isQuoteApproved && (
              <div className="flex justify-content-end align-items-end">
                {disabled ? (
                  <div className="flex flex-column align-items-end margin-left-8">
                    <Typography
                      variant="subtitleBold"
                      className="text-uppercase"
                    >
                      Quantity
                    </Typography>
                    <Typography
                      variant="h4"
                      className="text-uppercase"
                      color="primary.light"
                      style={{ margintop: 4, marginBottom: 8 }}
                    >
                      {quantity}
                    </Typography>
                  </div>
                ) : (
                  <div className="flex" style={{ justifyContent: "space-between", width: "100%" }}>
                    <div className="flex align-items-end">
                      <Typography
                        variant="h4"
                        className="text-uppercase"
                        color="primary.light"
                        style={{ margintop: 4, marginBottom: 8, paddingRight: 5}}
                      >
                        $
                      </Typography>
                      <NumberFormat
                        customInput={TextField}
                        allowNegative={false}
                        decimalSeparator={null}
                        style={pageStyle.smallInput}
                        name="itemBudget"
                        floatingLabelText="Budget"
                        value={budget}
                        onChange={this.handleBudgetChange}
                        autoComplete="off"
                        margin="normal"
                      />
                      <TextField
                        floatingLabelText="Balance"
                        type="text"
                        name="itemBalance"
                        onClick={null}
                        style={{padding: "0px  12px", width: "100px"}}
                        inputStyle={{
                          fontSize: "20px",
                          color: "rgb(12 83 113)",
                          pointerEvents: "none",
                        }}
                        value={residualBudget}
                        underlineShow={false}
                      />
                    </div>

                    <NumberFormat
                      customInput={TextField}
                      allowNegative={false}
                      decimalSeparator={null}
                      style={pageStyle.smallInput}
                      name="itemQty"
                      floatingLabelText="Quantity"
                      value={quantity}
                      onChange={this.handleChange}
                      autoComplete="off"
                      margin="normal"
                    />
                  </div>
                )}
                <div className="flex flex-column align-items-end margin-left-8">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Total
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{ margintop: 4, marginBottom: 8 }}
                  >
                    {getCurrencyFormattedNumber(unitCost * quantity || 0)}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
        {isQuoteApproved && (
          <Divider className="no-border" style={pageStyle.divider} />
        )}
        {isQuoteApproved && (
          <div className="flex">
            {artOption !== "no-art" &&
              digitizedArt && (
              <DigitizedArtsPreview
                digitizedArt={digitizedArt}
                onExpandDigitizedArt={this.handleExpandDigitizedArt}
                pageStyle={pageStyle}
              />
            )}
            <div className="flex flex-one flex-column justify-content-end padding-left-8 padding-left-16">
              <div className="flex justify-content-between align-items-end">
                <div className="flex align-items-baseline">
                  <NumberFormat
                    customInput={TextField}
                    allowNegative={false}
                    decimalSeparator={null}
                    style={pageStyle.smallInput}
                    name="itemQty"
                    floatingLabelText="Quantity"
                    value={quantity}
                    onChange={this.handleChange}
                    autoComplete="off"
                    margin="normal"
                  />
                </div>
                <div className="flex flex-column align-items-end margin-left-8">
                  <Typography variant="subtitleBold" className="text-uppercase">
                    Total
                  </Typography>
                  <Typography
                    variant="h4"
                    className="text-uppercase"
                    color="primary.light"
                    style={{ margintop: 4, marginBottom: 8 }}
                  >
                    {getCurrencyFormattedNumber(unitCost * quantity || 0)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
        <Dialog
          modal={false}
          open={isQuoteApproved && pricingTableOpen}
          contentStyle={{ maxWidth: 640 }}
        >
          <span style={{ position: "absolute", right: 10, top: 10 }}>
            <FontIcon
              className="material-icons pointer"
              onClick={this.handleClosePricingTable}
              style={{
                color: "#1D9ABB",
                fontSize: 16,
                borderRadius: 9,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              close
            </FontIcon>
          </span>
          <Typography variant="subtitle">Item #{product.itemNumber}</Typography>
          <Typography variant="h3" style={{ marginTop: 5, marginBottom: 13 }}>
            {product.productName}
          </Typography>
          {fabricColor}
          <QuoteItemPricingTable
            showDetails
            detailOnly
            prices={itemPrices}
            isLoading={isLoadingPrices}
            tableStyle={{ marginTop: 24 }}
          />
        </Dialog>
        {isQuoteApproved && (
          <Fragment>
            <ImagesModal
              open={openMockupsModal}
              images={
                mockups || [
                  {
                    imageUrl: _get(
                      product,
                      "productImages[0].imageUrlThumbnail",
                      product.imageUrl
                    ),
                  },
                ]
              }
              onClose={this.handleHideMockupsModal}
            />
            <ImagesModal
              open={openDigitizedArtModal}
              images={this.getDigitizedArts()}
              onClose={this.handleHideDigitizedArt}
            />
          </Fragment>
        )}

        <ConfirmationModal
          title={this.getDeleteModalTitle()}
          confirmText="Yes"
          cancelText="No"
          open={openArchiveModal}
          onConfirm={onRemoveItem}
          onDismiss={this.handleDismissArchiveModal}
          height={250}
        />
      </Card>
    );
  }
}

QuoteItemDetail.propTypes = {
  id: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemPrices: PropTypes.array,
  product: PropTypes.object,
  fabric: PropTypes.object,
  color: PropTypes.object,
  arts: PropTypes.array,
  quoteArts: PropTypes.array,
  companyArts: PropTypes.array,
  mockups: PropTypes.array,
  artOption: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingPrices: PropTypes.bool,
  disabled: PropTypes.bool,
  itemsCount: PropTypes.number,
  quoteStatus: PropTypes.string,
  quoteId: PropTypes.string,
  artDigitizationRenderConfig: PropTypes.array,
  requestSewOut: PropTypes.bool,
  requestPreProductionProof: PropTypes.bool,
  pageStyle: PropTypes.object,
  onRemoveItem: PropTypes.func,
  fetchItemPriceTable: PropTypes.func,
  onUpdateItemQuantityValue: PropTypes.func,
  onUpdateItemData: PropTypes.func,
  priceTableOpenId: PropTypes.string,
  onPriceTableOpen: PropTypes.func,
  hideRemove: PropTypes.bool,
};

export default QuoteItemDetail;
