import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";

import NewOrderItemModal from "./NewOrderItemModal";

import {fetchOrderItemTypes} from "../../../../../actions/orderItemTypeActions";
import {fetchProducts} from "../../../../../actions/productActions";
import {fetchFabrics} from "../../../../../actions/fabricActions";
import {
  fetchColors,
  fetchLeatherColors,
} from "../../../../../actions/colorActions";
import {fetchArtRevisionsByCompany} from "../../../../../actions/artActions";
import {createOrderItem} from "../../../../../actions/orderActions";
import {fetchAdornmentLocationsByProduct} from "../../../../../actions/adornmentLocationActions";
import {fetchDigitalizationTypePrices} from "../../../../../actions/digitalizacionTypePriceActions";

function mapStateToProps(state) {
  const {
    auth: {companies, isInternal},
    orderItemTypes: {orderItemTypes, isLoading: orderItemTypeIsLoading},
    products: {products},
    fabrics: {fabrics},
    colors: {colors, leatherColors},
    art: {artRevisions},
    adornmentLocations: {adornmentLocations},
    orders: {isLoading},
    adornmentTypes: {adornmentTypes},
    digitalizationTypePrices: {digitalizationTypePrices},
  } = state;

  return {
    companies,
    isInternal,
    orderItemTypes,
    products,
    fabrics,
    colors,
    leatherColors,
    isLoading,
    artRevisions,
    adornmentLocations,
    adornmentTypes,
    orderItemTypeIsLoading,
    digitalizationTypePrices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createOrderItem: bindActionCreators(createOrderItem, dispatch),
    fetchOrderItemTypes: bindActionCreators(fetchOrderItemTypes, dispatch),
    fetchProducts: bindActionCreators(fetchProducts, dispatch),
    fetchFabrics: bindActionCreators(fetchFabrics, dispatch),
    fetchColors: bindActionCreators(fetchColors, dispatch),
    fetchLeatherColors: bindActionCreators(fetchLeatherColors, dispatch),
    fetchDigitalizationTypePrices: bindActionCreators(
      fetchDigitalizationTypePrices,
      dispatch
    ),
    fetchAdornmentLocationsByProduct: bindActionCreators(
      fetchAdornmentLocationsByProduct,
      dispatch
    ),
    fetchArtRevisionsByCompany: bindActionCreators(
      fetchArtRevisionsByCompany,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewOrderItemModal)
);
