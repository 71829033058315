import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import {parse} from "query-string";
import {
  ORDER_COMPLETED_ID,
  REQUEST_FULFILLED_ID,
} from "../../../constants/order";
import CompanyTabs from "./Components/CompanyTabs";
import CompanyDetailsCompany from "./Components/CompanyDetailsCompany";
import CompanyDetailsContactBrands from "./Components/CompanyDetailsContactBrands";

class CompaniesDetailPage extends Component {
  searchAssociatedCompanies() {
    const {
      fetchAssociatedCompaniesByCompany,
      searchAssociatedCompaniesByCompany,
      searchTerm,
      tab,
      match: {params: {id: companyId}},
    } = this.props;

    if (!searchTerm || tab !== "associations") {
      fetchAssociatedCompaniesByCompany(companyId);
      return;
    }
    searchAssociatedCompaniesByCompany(companyId, searchTerm);
  }

  searchOrders = () => {
    const {
      searchTerm,
      page,
      tab,
      match: {params: {id: companyId}},
      location,
    } = this.props;

    const params = parse(location.search);
    const {selectedOrderType = "all"} = params;
    const filter = {
      all: "",
      active_orders: `status;code,neq,__${ORDER_COMPLETED_ID}__|is_dsod,eq,false`,
      historical_orders: `status;code,eq,__${ORDER_COMPLETED_ID}__|is_dsod,eq,false`,
      active_dsod: `status;code,neq,__${REQUEST_FULFILLED_ID}__|is_dsod,eq,true`,
      historical_dsod: `status;code,eq,__${REQUEST_FULFILLED_ID}__|is_dsod,eq,true`,
    }[selectedOrderType];

    const options = {
      filter,
      page: page || 1,
      perPage: 7,
      sort: "cng_reference_number",
    };

    if (!searchTerm || tab !== "orders") {
      return this.props.fetchOrdersByCompany(companyId, undefined, options);
    }
    this.props.searchOrdersByCompany(companyId, searchTerm, undefined, options);
  };

  searchInventory = () => {
    const {
      page,
      searchTerm,
      tab,
      match: {params: {id: companyId}},
    } = this.props;
    const inventoryItemsOptions = {
      include:
        "orderItem.[order,artThreadColorAssignment,orderItemProduct.[orderItemProductAdornmentLo" +
        "cationArtAssignments.[art,productAdornmentLocation.adornmentLocation],art.artSource.bra" +
        "nd],fabricColor.[fabric,color],product]",
      filter: "used_quantity,lt,quantity",
      page: page || 1,
      perPage: 7,
    };

    if (!searchTerm || tab !== "inventory") {
      return this.props.fetchInventoryItems(companyId, inventoryItemsOptions);
    }
    this.props.searchInventoryItems(
      companyId,
      searchTerm,
      inventoryItemsOptions
    );
  };

  searchQuotes = () => {
    const {searchTerm, page, tab, location} = this.props;

    const params = parse(location.search);
    const {selectedQuoteType = "active"} = params;

    const options = {
      customFilter: selectedQuoteType,
      page: page || 1,
      perPage: 7,
    };

    if (!searchTerm || tab !== "quotes") {
      return this.props.fetchQuotes(options);
    }
    this.props.searchQuotes(searchTerm, options);
  };

  searchUsers = () => {
    const {
      searchTerm,
      page,
      tab,
      match: {params: {id: companyId}},
    } = this.props;
    const include = "[state,country,recipient.[country],companyAssociations]";

    if (!searchTerm || tab !== "users") {
      return this.props.fetchUsersByCompany(companyId, {
        include,
        page: page || 1,
        perPage: 7,
        sort: "first_name",
        sortDirection: "asc",
      });
    }
    this.props.searchUsersByCompany(companyId, searchTerm, {
      include,
      page: page || 1,
      perPage: 7,
    });
  };

  searchContacts = (extraOptions = {}) => {
    const {searchTerm, page, match: {params: {id: companyId}}} = this.props;
    const include = "[country,defaultWalletMember]";

    const options = {
      include,
      page: page || 1,
      perPage: 7,
      sort: "name",
      sortDirection: "asc",
      ...extraOptions,
    };

    if (!searchTerm) {
      return this.props.fetchCompanyContacts(companyId, options);
    }

    this.props.searchCompanyContacts(companyId, searchTerm, options);
  };

  async createCompanyCompanyAssociation(data) {
    await this.props.createCompanyCompanyAssociation(data);
    await this.props.fetchAssociatedCompaniesByCompany(this.props.company.id);
  }

  async deleteCompanyCompanyAssociation(id) {
    await this.props.deleteCompanyCompanyAssociation(id);
    await this.props.fetchAssociatedCompaniesByCompany(this.props.company.id);
  }

  async createCompanyNote(obj) {
    this.props.createCompanyNote(obj);
  }

  async deleteCompanyNote(obj) {
    this.props.deleteCompanyNote(obj);
  }

  async updateUserWithCompanies(user) {
    await this.props.updateUserWithCompanies(user);
    this.props.fetchCompany(this.props.company.id);
  }

  addDSODItems = (companyId, inventoryItems) => {
    const {createDsodItemsFromInventory, history} = this.props;
    createDsodItemsFromInventory(
      companyId,
      null,
      inventoryItems.map(item => item.id),
      {},
      () => {
        history.push(`/companies/${companyId}/dsod`);
      }
    );
  };

  render() {
    const {
      match: {params: {id: companyId}},
      company,
      companies,
      paymentTerms,
      discounts,
      businesses,
      states,
      countries,
      isInternal,
      orders,
      ordersCount,
      inventoryItems,
      inventoryItemsCount,
      quotes,
      quotesCount,
      users,
      location,
      history,
      companyContacts,
      companyContactsCount,
      associatedCompanies,
      updateCompany,
      fetchCompany,
      userCount,
      isLoadingAssociatedCompanies,
      isLoadingOrderHistory,
      isLoadinginventory,
      isLoadingQuotes,
      isLoadingUsers,
      isLoadingCompany,
      isCompanyContactsLoading,
      isNoteRequestLoading,
      updateQueryParams,
      tab: selectedTab,
      setPage,
      setSearchTerm,
      searchTerm,
      page,
      isLoading,
      addQueryParam,
      archiveUserCompany,
      createReorderItem,
      match,
      currentUserId,
      fetchOpenOrders,
    } = this.props;

    const params = parse(location.search);
    const {selectedOrderType = "all", selectedQuoteType = "active"} = params;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company ? company.name : ""}
      >
        {isInternal ? (
          <BreadCrumbs
            rootName={"Companies"}
            rootUrl={"companies"}
            pageName={company ? company.name : ""}
          />
        ) : null}
        <Card>
          <CompanyDetailsCompany
            company={company}
            companyId={companyId}
            paymentTerms={paymentTerms}
            discounts={discounts}
            businesses={businesses}
            isInternal={isInternal}
            updateCompany={updateCompany}
            fetchCompany={fetchCompany}
            isLoading={isLoadingCompany}
            match={match}
          />
          <div
            className="divider"
            style={{
              marginBottom: 32,
            }}
          />
          <CompanyDetailsContactBrands
            company={company}
            states={states}
            countries={countries}
            isLoading={isLoadingCompany}
            isInternal={isInternal}
          />
          <CompanyTabs
            history={history}
            companyId={companyId}
            selectedOrderType={selectedOrderType}
            selectedQuoteType={selectedQuoteType}
            setPage={setPage}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            addQueryParam={addQueryParam}
            page={page}
            updateCompany={updateCompany}
            createReorderItem={createReorderItem}
            archiveUserCompany={archiveUserCompany}
            searchAssociatedCompanies={(event, value) =>
              this.searchAssociatedCompanies(event, value)
            }
            searchOrders={this.searchOrders}
            searchInventory={(event, value) =>
              this.searchInventory(event, value)
            }
            searchQuotes={this.searchQuotes}
            addDSODItems={this.addDSODItems}
            searchUsers={this.searchUsers}
            currentUserId={currentUserId}
            searchContacts={this.searchContacts}
            createCompanyCompanyAssociation={data =>
              this.createCompanyCompanyAssociation(data)
            }
            deleteCompanyCompanyAssociation={id =>
              this.deleteCompanyCompanyAssociation(id)
            }
            updateUserWithCompanies={user => this.updateUserWithCompanies(user)}
            company={company}
            associatedCompanies={associatedCompanies}
            orders={orders}
            ordersCount={ordersCount}
            inventoryItems={inventoryItems}
            inventoryItemsCount={inventoryItemsCount}
            quotes={quotes}
            quotesCount={quotesCount}
            users={users}
            userCount={userCount}
            location={location}
            isInternal={isInternal}
            companyContacts={companyContacts}
            companyContactsCount={companyContactsCount}
            companies={companies}
            createCompanyNote={companyNote =>
              this.createCompanyNote(companyNote)
            }
            deleteCompanyNote={companyNote =>
              this.deleteCompanyNote(companyNote)
            }
            fetchCompanyContacts={this.searchContacts}
            isLoadingAssociatedCompanies={isLoadingAssociatedCompanies}
            isLoadingOrderHistory={isLoadingOrderHistory}
            isLoadinginventory={isLoadinginventory}
            isLoadingQuotes={isLoadingQuotes}
            isLoadingUsers={isLoadingUsers}
            isCompanyContactsLoading={isCompanyContactsLoading}
            isNoteRequestLoading={isNoteRequestLoading}
            selectedTab={selectedTab}
            updateQueryParams={updateQueryParams}
            fetchOpenOrders={fetchOpenOrders}
          />
        </Card>
      </PageLayout>
    );
  }
}

CompaniesDetailPage.propTypes = {
  company: PropTypes.object,
  companies: PropTypes.array,
  updateCompany: PropTypes.func.isRequired,
  inventoryItems: PropTypes.array,
  inventoryItemsCount: PropTypes.number,
  fetchCompany: PropTypes.func,
  associatedCompanies: PropTypes.array.isRequired,
  fetchAssociatedCompaniesByCompany: PropTypes.func.isRequired,
  createCompanyCompanyAssociation: PropTypes.func.isRequired,
  deleteCompanyCompanyAssociation: PropTypes.func.isRequired,
  fetchOrdersByCompany: PropTypes.func.isRequired,
  fetchInventoryItems: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  discounts: PropTypes.array.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  businesses: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  ordersCount: PropTypes.number,
  quotes: PropTypes.array.isRequired,
  quotesCount: PropTypes.number,
  fabrics: PropTypes.array.isRequired,
  users: PropTypes.array,
  userCount: PropTypes.number,
  searchOrdersByCompany: PropTypes.func.isRequired,
  searchInventoryItems: PropTypes.func.isRequired,
  isInternal: PropTypes.bool,
  currentUserId: PropTypes.number,
  location: PropTypes.object,
  history: PropTypes.object,
  deleteCompanyNote: PropTypes.func,
  createCompanyNote: PropTypes.func,
  searchUsersByCompany: PropTypes.func,
  fetchUsersByCompany: PropTypes.func,
  companyContacts: PropTypes.array,
  companyContactsCount: PropTypes.number,
  fetchCompanyContacts: PropTypes.func,
  searchCompanyContacts: PropTypes.func,
  updateUserWithCompanies: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  searchAssociatedCompaniesByCompany: PropTypes.func,
  isLoadingAssociatedCompanies: PropTypes.bool,
  isLoadingOrderHistory: PropTypes.bool,
  isLoadinginventory: PropTypes.bool,
  isLoadingQuotes: PropTypes.bool,
  isLoadingUsers: PropTypes.bool,
  isCompanyContactsLoading: PropTypes.bool,
  isNoteRequestLoading: PropTypes.bool,
  tab: PropTypes.string,
  updateQueryParams: PropTypes.func,
  page: PropTypes.number,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  setPage: PropTypes.func,
  addQueryParam: PropTypes.func,
  match: PropTypes.object,
  isLoadingCompany: PropTypes.bool,
  createDsodItemsFromInventory: PropTypes.func,
  createReorderItem: PropTypes.func,
  archiveUserCompany: PropTypes.func,
  fetchOpenOrders: PropTypes.func,
  fetchQuotes: PropTypes.func,
  searchQuotes: PropTypes.func,
};

export default CompaniesDetailPage;
