import React, {Component, Fragment} from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";
import { Redirect } from "react-router-dom";

class ChooseCategory extends Component {
  state = {
    isLoadingUserValidation: true,
  };

  componentDidMount() {
    const {userEmail, companyName, fetchUserEmailValidationByCompany, isKnownCompany} = this.props;
    if(isKnownCompany === true){
      fetchUserEmailValidationByCompany(userEmail, companyName)
        .then(() => {
          this.setState({isLoadingUserValidation: false});
        });
    }else{
      this.setState({isLoadingUserValidation: false});
    }
  }

  render() {
    const {
      item,
      onChange,
      pageStyle,
      categories,
      isLoading,
      userEmailValidationByCompany,
      isKnownCompany,
      userEmail,
      companyName,
    } = this.props;
    const {isLoadingUserValidation} = this.state;

    if (isLoadingUserValidation) {
      return null;
    }

    if (userEmailValidationByCompany === false && isKnownCompany === true) {
      return <Redirect to={`/quotes/new/info?knownCompany=false&companyName=${companyName}&userEmail=${userEmail}`} />;
    }

    return (
      <Fragment>
        <Typography variant="h1" style={pageStyle.title}>
      Choose a Category
        </Typography>
        <div className="item-list">
          {categories.filter(category => category.thumbnail).map(category => (
            <div
              key={category.id}
              className={classNames("item", {
                pointer: !isLoading,
                loading: isLoading,
              })}
              onClick={() =>
                !isLoading && onChange("categoryId")({target: {}}, category.id)
              }
            >
              <div className="item-container">
                <div
                  className={classNames("item-image", {
                    selected: item && item.categoryId === category.id,
                  })}
                >
                  <CNGImage
                    src={_get(category, "imageUrlThumbnail", category.imageUrl)}
                    style={pageStyle.image}
                  />
                </div>
              </div>
              <Typography variant="h5">{_get(category, "name")}</Typography>
            </div>
          ))}
          {!isLoading &&
        categories.length === 0 && (
            <Typography variant="h2" style={{marginLeft: 16}}>
            There are no categories yet.
            </Typography>
          )}
          {isLoading &&
        categories.length === 0 &&
        [0, 1, 2, 3].map(_ => (
          <div key={_} className="item loading">
            <div className="item-container">
              <div className="item-image">
                <CNGImage style={pageStyle.image} />
              </div>
            </div>
            <Typography variant="h5">{_}</Typography>
          </div>
        ))}
        </div>
      </Fragment>
    );
  }
}


ChooseCategory.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  pageStyle: PropTypes.object,
  categories: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchUserEmailValidationByCompany: PropTypes.func,
  userEmailValidationByCompany: PropTypes.bool,
  companyName: PropTypes.string,
  userEmail: PropTypes.string,
  history: PropTypes.object,
  isKnownCompany: PropTypes.bool,
};

export default ChooseCategory;
