import React from "react";
import {Logo} from "./shared/Logo";
import Clipboard from "react-clipboard.js";

const browsers = [
  {
    imgUrl: "https://cdn.svgporn.com/logos/chrome.svg",
    url: "https://www.google.com/chrome/",
    name: "Chrome",
  },
  {
    imgUrl: "https://cdn.svgporn.com/logos/firefox.svg",
    url: "https://www.mozilla.org/en-US/firefox/new/",
    name: "Firefox",
  },
  {
    imgUrl: "https://cdn.svgporn.com/logos/safari.svg",
    url: "https://www.apple.com/safari/",
    name: "Safari",
  },
  {
    imgUrl: "https://cdn.svgporn.com/logos/microsoft-edge.svg",
    url: "https://www.microsoft.com/en-us/windows/microsoft-edge",
    name: "Edge",
  },
];

export default class App extends React.Component {
  state = {
    isCopied: false,
  };

  handleSuccess = () => {
    this.setState({isCopied: true});
  };

  render() {
    const {isCopied} = this.state;
    return (
      <div className="ie-wrapper">
        <div className="ie-container">
          <div className="ie-header">
            <Logo />
          </div>
          <div className="ie-description">
            <h4>
              Oops! Very sorry, but we&apos;re unable to support Micrsoft
              Internet Explorer (IE). We do support the browsers shown.
            </h4>
            <div className="browser">
              {browsers.map(browser => (
                <div key={browser.name}>
                  <a href={browser.url} target="_blank">
                    <img src={browser.imgUrl} alt="" />
                  </a>
                </div>
              ))}
            </div>
            <div>
              <h4 className="margin-bottom-9">
                Please click the &apos;Copy Link&apos; button; open a supported
                browser and paste the link. You&apos;re in!! Don&apos;t hesitate
                to contact us if you have a problem - we&apos;re at your
                service!!
              </h4>
              <Clipboard
                data-clipboard-text={window.location.href}
                className="chill-button chill-button--medium"
                onSuccess={this.handleSuccess}
              >
                COPY LINK
              </Clipboard>
              <div>{isCopied && "Copied!"}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
