export const inkBarStyle = {
  backgroundColor: "#34b8dd",
  height: 2,
  width: 148,
};
export const tabContainerStyle = {
  background: "transparent",
  marginTop: 38,
};
export const tabButtonStyle = {
  color: "#0C5371",
  width: 148,
  fontSize: 12,
  fontWeight: "bold",
};
