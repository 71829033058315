import ReactGA from "react-ga";
import * as types from "../constants/actionTypes";
import AuthService from "../services/AuthService";
import * as jwt from "jsonwebtoken";
import Messenger from "../services/MessagingService";
import { makeActionCreator, makeCommonAction } from "./commonActions";

const authRequestAction = () => {
  return {type: types.AUTH_REQUEST};
};

const authSuccessAction = (
  id,
  email,
  name,
  isInternal,
  companies,
  selectedCompany,
  selectedCompanyName,
  selectedCompanyCustomerMessage
) => {
  return {
    type: types.AUTH_SUCCESS,
    payload: {
      id,
      email,
      name,
      isInternal,
      companies,
      selectedCompany,
      selectedCompanyName,
      selectedCompanyCustomerMessage,
    },
    isAuthenticated: true,
  };
};

const authErrorAction = () => {
  return {type: types.AUTH_ERROR, isAuthenticated: false};
};

const logoutSuccessAction = () => {
  return {type: types.LOGOUT_SUCCESS, isLoggedOut: true};
};

const forgotRequestAction = () => {
  return {type: types.FORGOT_REQUEST};
};

const forgotSuccessAction = success_message => {
  return {
    type: types.FORGOT_SUCCESS,
    payload: {
      success_message,
    },
    error: false,
  };
};

const forgotErrorAction = error_message => {
  return {
    type: types.FORGOT_ERROR,
    payload: {
      error_message,
    },
    error: true,
  };
};

const loginRequestAction = () => {
  return {type: types.LOGIN_REQUEST};
};

const loginSuccessAction = (token, goto_order) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      token,
      goto_order,
    },
  };
};

const loginErrorAction = error_message => {
  return {
    type: types.LOGIN_ERROR,
    payload: {
      error_message,
    },
    error: true,
  };
};

const userEmailValidationRequestAction = () => {
  return { type: types.VALIDATE_USER_EMAIL_COMPANY_REQUEST};
};

const userEmailValidationSuccessAction = payload =>
  makeCommonAction(types.VALIDATE_USER_EMAIL_COMPANY_SUCCESS, payload);

const userEmailValidationErrorAction = (error_message) => {
  return {
    type: types.VALIDATE_USER_EMAIL_COMPANY_ERROR,
    payload: {
      error_message,
    },
    error: true,
  };
};

const resetRequestAction = () => {
  return {type: types.RESET_REQUEST};
};

const resetSuccessAction = success_message => {
  return {
    type: types.RESET_SUCCESS,
    payload: {
      success_message,
    },
    error: false,
  };
};

const resetErrorAction = error_message => {
  return {
    type: types.RESET_ERROR,
    payload: {
      error_message,
    },
    error: true,
  };
};

const selectCompanyRequestAction = () => {
  return {type: types.SELECT_COMPANY_REQUEST, error: false};
};

const selectCompanySuccessAction = (company_id, name, customerMessage) => {
  return {
    type: types.SELECT_COMPANY_SUCCESS,
    payload: {
      company_id,
      name,
      customerMessage,
    },
    error: false,
  };
};

const selectCompanyErrorAction = error_message => {
  return {
    type: types.SELECT_COMPANY_ERROR,
    payload: {
      error_message,
    },
    error: true,
  };
};

export function checkAuth() {
  return async dispatch => {
    try {
      dispatch(authRequestAction());
      const service = new AuthService();
      if (service.loggedIn()) {
        const {email, name, is_internal, companies, id} = jwt.verify(
          service.getToken(),
          process.env.JWT_SECRET
        );
        const selectedCompany = service.getSelectedCompany();
        const selectedCompanyName = service.getSelectedCompanyName();
        const selectedCompanyCustomerMessage = service.getSelectedCompanyCustomerMessage();
        dispatch(
          authSuccessAction(
            id,
            email,
            name,
            is_internal,
            companies,
            selectedCompany,
            selectedCompanyName,
            selectedCompanyCustomerMessage
          )
        );
      } else {
        dispatch(authErrorAction());
      }
    } catch (error) {
      dispatch(authErrorAction());
    }
  };
}

export function logout() {
  return async dispatch => {
    const service = new AuthService();
    await service.logout();
    if (Messenger.instance) await Messenger.instance.disconnect();
    dispatch(logoutSuccessAction());
  };
}

export function sendForgotEmail(email) {
  return async dispatch => {
    try {
      dispatch(forgotRequestAction());
      const service = new AuthService();
      const response = await service.fetchWithAuth("/forgotpassword", {
        method: "POST",
        body: {
          email_address: email,
        },
      });

      if (response.status == 200) {
        dispatch(
          forgotSuccessAction(
            `An email has been sent to ${email} with instructions to reset your password.`
          )
        );
      } else {
        dispatch(
          forgotErrorAction(
            `Unable to send password reset. Please verify the email address and try again (${email})`
          )
        );
      }
    } catch (error) {
      dispatch(
        forgotErrorAction(
          `Unable to send password reset. Please verify the email address and try again (${email})`
        )
      );
    }
  };
}

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch(loginRequestAction());
      const service = new AuthService();
      const {status, body, message} = await service.login(email, password);

      if (status == 200 && body.token) {
        dispatch(loginSuccessAction(body.token, body.goto_order));
      } else {
        dispatch(loginErrorAction(message));
      }
    } catch (error) {
      if (error.message == "Unauthorized") {
        dispatch(
          loginErrorAction(
            "Invalid login. Please try again or click \"Forgot your password?\" for help."
          )
        );
      } else {
        dispatch(
          loginErrorAction("Invalid email or password. Try again.")
        );
      }
    }
  };
}

export function fetchUserEmailValidationByCompany(userEmail, companyName) {
  return makeActionCreator({
    endpoint: `/useremailvalidation?userEmail=${userEmail}&companyName=${companyName}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: userEmailValidationRequestAction,
    successFunc: userEmailValidationSuccessAction,
    errorFunc: userEmailValidationErrorAction,
    error_message: "User doesn't exist in this company.",
  });
}

export function resetPassword(token, password) {
  return async dispatch => {
    try {
      dispatch(resetRequestAction());
      const service = new AuthService();
      const response = await service.fetchWithAuth("/resetpassword", {
        method: "POST",
        body: {
          token: token,
          password: password,
        },
      });

      if (response.status == 200) {
        dispatch(
          resetSuccessAction("Password reset successfully. Please log in.")
        );
      } else {
        dispatch(
          resetErrorAction(
            "Unable to reset password, please check your new password and try again"
          )
        );
      }
    } catch (error) {
      if (error.response) {
        const responseBody = await error.response.body;
        const errorMessage =
          typeof responseBody === "string"
            ? responseBody
            : "Unable to reset password, please check your new password and try again";
        dispatch(resetErrorAction(errorMessage));
        return;
      }
      dispatch(
        resetErrorAction(
          "Unable to reset password, please check your new password and try again"
        )
      );
    }
  };
}

export function setSelectedCompany({id: company_id, name, customerMessage}) {
  return async dispatch => {
    try {
      dispatch(selectCompanyRequestAction());
      const service = new AuthService();
      service.setSelectedCompany(company_id, name, customerMessage);
      dispatch(selectCompanySuccessAction(company_id, name, customerMessage));
      dispatch(checkAuth());
      ReactGA.set({userId: name, dimension1: name});
    } catch (error) {
      dispatch(selectCompanyErrorAction("Unable to select company"));
    }
  };
}
