import React from "react";
import PropTypes from "prop-types";
import Paper from "material-ui/Paper";
import WarningIcon from "material-ui/svg-icons/alert/warning";

const FlatText = ({children, style}) => (
  <Paper
    className="w-100 flex flex-center justify-content-center"
    style={{
      color: "#F44336",
      padding: 16,
      marginBottom: 24,
      fontStyle: "italic",
      ...style,
    }}
  >
    <WarningIcon
      color="#F44336"
      style={{marginRight: 8, minWidth: 32, height: 32}}
    />
    {children}
  </Paper>
);

FlatText.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any,
};

export default FlatText;
