import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchStatesRequest = () => makeCommonAction(types.FETCH_STATES_REQUEST);
const fetchStatesSuccess = payload =>
  makeCommonAction(types.FETCH_STATES_SUCCESS, payload);
const fetchStatesError = error =>
  makeCommonAction(types.FETCH_STATES_ERROR, error, true);

const fetchCountriesRequest = () =>
  makeCommonAction(types.FETCH_COUNTRIES_REQUEST);
const fetchCountriesSuccess = payload =>
  makeCommonAction(types.FETCH_COUNTRIES_SUCCESS, payload);
const fetchCountriesError = error =>
  makeCommonAction(types.FETCH_COUNTRIES_ERROR, error, true);

export function fetchStates() {
  return makeActionCreator({
    endpoint: "/states",
    fetchOptions: {method: "GET"},
    requestFunc: fetchStatesRequest,
    successFunc: fetchStatesSuccess,
    errorFunc: fetchStatesError,
    error_message: "Error fetching states",
  });
}

export function fetchCountries() {
  return makeActionCreator({
    endpoint: "/countries",
    fetchOptions: {method: "GET"},
    requestFunc: fetchCountriesRequest,
    successFunc: fetchCountriesSuccess,
    errorFunc: fetchCountriesError,
    error_message: "Error fetching countries",
  });
}
