import React, {Component} from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import CircularProgress from "../../../../shared/CircularProgress";
import ProductForm from "./ProductForm";
import LaborForm from "./LaborForm";
import SetUpCostForm from "./SetUpCostForm";
import MiscellaneousForm from "./MiscellaneousForm";
import DiscountForm from "./DiscountForm";
import SewOutForm from "./SewOutForm";
import Dialog from "material-ui/Dialog";
import {
  PRODUCT_ORDER_ITEM_NAME,
  LABOR_ORDER_ITEM_NAME,
  SETUP_ORDER_ITEM_NAME,
  DISCOUNT_ORDER_ITEM_NAME,
  MISCELLANEOUS_COST,
  SEW_OUT,
} from "../../../../../constants/orderItemType";

const newOrderItemStyles = {
  maxWidth: 640,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
};

const DEFAULT_ITEM_TYPE = 1;

export default class NewDSODModal extends Component {
  static propTypes = {
    onCreated: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    fetchOrderItemTypes: PropTypes.func,
    fetchProducts: PropTypes.func,
    fetchFabrics: PropTypes.func,
    fetchColors: PropTypes.func,
    fetchLeatherColors: PropTypes.func,
    fetchArtRevisionsByCompany: PropTypes.func,
    fetchAdornmentLocationsByProduct: PropTypes.func,
    fetchDigitalizationTypePrices: PropTypes.func,
    createOrderItem: PropTypes.func,
    products: PropTypes.array,
    adornmentTypes: PropTypes.array,
    orderItemTypes: PropTypes.array,
    fabrics: PropTypes.array,
    colors: PropTypes.array,
    leatherColors: PropTypes.array,
    artRevisions: PropTypes.array,
    adornmentLocations: PropTypes.array,
    digitalizationTypePrices: PropTypes.array,
    match: PropTypes.object,
    orderItemTypeIsLoading: PropTypes.bool,
  };

  state = {
    open: true,
    selectedItemType: DEFAULT_ITEM_TYPE,
  };

  componentDidMount() {
    this.setupComponent();
  }

  setupComponent = () => {
    const {fetchOrderItemTypes} = this.props;
    fetchOrderItemTypes();
  };

  handleChangeOrderItemType = (event, index, value) => {
    value = event.target.value || value;
    this.setState({
      selectedItemType: value,
    });
  };

  renderMiscellaneousForm = () => {
    const {selectedItemType} = this.state;
    const {
      onCreated,
      createOrderItem,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    return (
      <MiscellaneousForm
        createOrderItem={createOrderItem}
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        onCreated={onCreated}
        onClose={this.handleClose}
        selectedItemTypeId={selectedItemType}
      />
    );
  };

  renderSewOutForm = () => {
    const {selectedItemType} = this.state;
    const {
      onCreated,
      createOrderItem,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    const orderItemTypes = _get(this.props, "orderItemTypes", []);
    const miscellaneousType = orderItemTypes.find(
      ({name}) => name === "Miscellaneous"
    );
    return (
      <SewOutForm
        createOrderItem={createOrderItem}
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        onCreated={onCreated}
        onClose={this.handleClose}
        selectedItemTypeId={selectedItemType}
        orderItemTypeId={miscellaneousType.id}
      />
    );
  };

  renderDiscountForm = () => {
    const {selectedItemType} = this.state;
    const {
      products,
      fetchProducts,
      createOrderItem,
      onCreated,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    return (
      <DiscountForm
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        products={products}
        fetchProducts={fetchProducts}
        createOrderItem={createOrderItem}
        selectedItemTypeId={selectedItemType}
        onCreated={onCreated}
        onClose={this.handleClose}
      />
    );
  };

  renderSetUpCostForm = () => {
    const {selectedItemType} = this.state;
    const {
      digitalizationTypePrices,
      onCreated,
      fetchDigitalizationTypePrices,
      createOrderItem,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    return (
      <SetUpCostForm
        createOrderItem={createOrderItem}
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        onCreated={onCreated}
        onClose={this.handleClose}
        selectedItemTypeId={selectedItemType}
        digitalizationTypePrices={digitalizationTypePrices}
        fetchDigitalizationTypePrices={fetchDigitalizationTypePrices}
      />
    );
  };

  renderLaborForm = () => {
    const {selectedItemType} = this.state;
    const {
      products,
      fetchProducts,
      fabrics,
      colors,
      leatherColors,
      adornmentLocations,
      artRevisions,
      fetchFabrics,
      fetchColors,
      fetchLeatherColors,
      fetchArtRevisionsByCompany,
      fetchAdornmentLocationsByProduct,
      createOrderItem,
      onCreated,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    return (
      <LaborForm
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        products={products}
        adornmentLocations={adornmentLocations}
        fetchAdornmentLocationsByProduct={fetchAdornmentLocationsByProduct}
        fetchProducts={fetchProducts}
        fetchFabrics={fetchFabrics}
        fetchArtRevisionsByCompany={fetchArtRevisionsByCompany}
        createOrderItem={createOrderItem}
        fabrics={fabrics}
        fetchColors={fetchColors}
        colors={colors}
        fetchLeatherColors={fetchLeatherColors}
        leatherColors={leatherColors}
        artRevisions={artRevisions}
        selectedItemTypeId={selectedItemType}
        onCreated={onCreated}
        onClose={this.handleClose}
      />
    );
  };

  renderProductForm = () => {
    const {selectedItemType} = this.state;
    const {
      products,
      fetchProducts,
      fabrics,
      colors,
      leatherColors,
      adornmentLocations,
      artRevisions,
      fetchFabrics,
      fetchColors,
      fetchLeatherColors,
      fetchArtRevisionsByCompany,
      fetchAdornmentLocationsByProduct,
      createOrderItem,
      onCreated,
      isLoading,
      match: {params: {company_id: companyId}},
    } = this.props;
    return (
      <ProductForm
        onChangeOrderItemType={this.handleChangeOrderItemType}
        isLoading={isLoading}
        companyId={companyId}
        itemTypeOptions={this.getItemTypeOptions()}
        products={products}
        adornmentLocations={adornmentLocations}
        fetchAdornmentLocationsByProduct={fetchAdornmentLocationsByProduct}
        fetchProducts={fetchProducts}
        fetchFabrics={fetchFabrics}
        fetchArtRevisionsByCompany={fetchArtRevisionsByCompany}
        createOrderItem={createOrderItem}
        fabrics={fabrics}
        fetchColors={fetchColors}
        colors={colors}
        fetchLeatherColors={fetchLeatherColors}
        leatherColors={leatherColors}
        artRevisions={artRevisions}
        selectedItemTypeId={selectedItemType}
        onCreated={onCreated}
        onClose={this.handleClose}
      />
    );
  };

  renderSelectedForm = () => {
    const orderItemTypes = _get(this.props, "orderItemTypes", []);
    const miscellaneousType = orderItemTypes.find(
      ({name}) => name === "Miscellaneous"
    );
    const {selectedItemType} = this.state;
    let orderItemType = orderItemTypes.find(
      ({id}) => Number(id) === Number(selectedItemType)
    );
    if (
      miscellaneousType &&
      selectedItemType === `${miscellaneousType.id}-so`
    ) {
      orderItemType = {id: `${miscellaneousType.id}-so`, name: SEW_OUT};
    }
    if (!orderItemType) return;
    const forms = {
      [PRODUCT_ORDER_ITEM_NAME]: this.renderProductForm,
      [LABOR_ORDER_ITEM_NAME]: this.renderLaborForm,
      [SETUP_ORDER_ITEM_NAME]: this.renderSetUpCostForm,
      [MISCELLANEOUS_COST]: this.renderMiscellaneousForm,
      [DISCOUNT_ORDER_ITEM_NAME]: this.renderDiscountForm,
      [SEW_OUT]: this.renderSewOutForm,
    };
    const renderSelectedForm = forms[_get(orderItemType, "name")];
    return renderSelectedForm();
  };

  handleClose = () => {
    this.props.onClose();
    this.setState({open: false});
  };

  getItemTypeOptions = () => {
    const orderItemTypes = _get(this.props, "orderItemTypes", []);
    const miscellaneousType = orderItemTypes.find(
      ({name}) => name === "Miscellaneous"
    );
    return [
      orderItemTypes.map(({id, name}) => (
        <CNGMenuItem key={id} value={id} primaryText={name} />
      )),
      <CNGMenuItem
        key={`${miscellaneousType.id}-so`}
        value={`${miscellaneousType.id}-so`}
        primaryText="Sew-Out"
      />,
    ];
  };

  render() {
    const {orderItemTypeIsLoading: isLoading} = this.props;
    return (
      <Dialog
        modal={false}
        open={this.state.open}
        contentStyle={newOrderItemStyles}
        bodyStyle={{
          padding: "40px 48px",
        }}
        paperProps={{
          style: {borderRadius: 4},
        }}
        overlayClassName={"dialog-overlay"}
        className={"order-item-dialog"}
      >
        <img
          onClick={() => this.handleClose()}
          className="archiveModal_closeIcon"
          src="../../../../../assets/imgs/icons/close.svg"
        />
        <h3
          className="archiveModal_title"
          style={{
            marginBottom: 7,
          }}
        >
          Add an Order Item
        </h3>
        {isLoading ? (
          <div
            style={{height: 354}}
            className="page-loader flex justify-content-center align-items-center"
          >
            <CircularProgress className="margin-auto" />
          </div>
        ) : (
          this.renderSelectedForm()
        )}
        {!isLoading ? (
          <span
            onClick={() => this.handleClose()}
            className="archiveModal_cancel"
          >
            Cancel
          </span>
        ) : null}
      </Dialog>
    );
  }
}
