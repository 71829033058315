import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QuoteItemArtsTable from "./QuoteItemArtsTable";
import {fetchDigitalizationTypePrices} from "../../../../../actions/digitalizacionTypePriceActions";

function mapStateToProps(state) {
  const {digitalizationTypePrices} = state;

  return {
    digitalizationTypePrices: digitalizationTypePrices
      ? digitalizationTypePrices.digitalizationTypePrices
      : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDigitalizationTypePrices: bindActionCreators(
      fetchDigitalizationTypePrices,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteItemArtsTable)
);
