import {ADORNMENT_TYPES} from "../constants/orderItemType";

export function getDigitizationOptionsValues(digitalizationTypePrices) {
  const values = digitalizationTypePrices.map(digitizationTypePrice => {
    const adornmentTypeData = ADORNMENT_TYPES.find(
      type => type.id === digitizationTypePrice.adornmentType
    );
    const adornmentType = adornmentTypeData.title || adornmentTypeData.name;
    return {
      key: `${digitizationTypePrice.adornmentType}-${digitizationTypePrice.id}`,
      adornmentTypeCode: digitizationTypePrice.adornmentType,
      adornmentType,
      digitalizationTypePriceId: digitizationTypePrice.id,
      complexityLevel: digitizationTypePrice.complexityLevel,
      price: digitizationTypePrice.price / 100,
    };
  });
  values.push({
    key: "none",
    adornmentTypeCode: "none",
    adornmentType: "No - chill-n-go has art",
    price: 0,
  });
  return values;
}
