import {stringify} from "query-string";
import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const archiveUserCompanyRequest = () =>
  makeCommonAction(types.ARCHIVE_USER_COMPANY_REQUEST);
const archiveUserCompanySuccess = payload =>
  makeCommonAction(types.ARCHIVE_USER_COMPANY_SUCCESS, payload);
const archiveUserCompanyError = error =>
  makeCommonAction(types.ARCHIVE_USER_COMPANY_REQUEST, error, true);

const createWalletRequest = () =>
  makeCommonAction(types.CREATE_WALLET_MEMBER_REQUEST);
const createWalletSuccess = payload =>
  makeCommonAction(types.CREATE_WALLET_MEMBER_SUCCESS, payload);
const createWalletError = error =>
  makeCommonAction(types.CREATE_WALLET_MEMBER_ERROR, error, true);

const updateWalletRequest = () =>
  makeCommonAction(types.UPDATE_WALLET_MEMBER_REQUEST);
const updateWalletSuccess = payload =>
  makeCommonAction(types.UPDATE_WALLET_MEMBER_SUCCESS, payload);
const updateWalletError = error =>
  makeCommonAction(types.UPDATE_WALLET_MEMBER_ERROR, error, true);

const fetchCompaniesRequest = () =>
  makeCommonAction(types.FETCH_COMPANIES_REQUEST);
const fetchCompaniesSuccess = payload =>
  makeCommonAction(types.FETCH_COMPANIES_SUCCESS, payload);
const fetchCompaniesError = error =>
  makeCommonAction(types.FETCH_COMPANIES_ERROR, error, true);

const fetchWalletMembersRequest = () =>
  makeCommonAction(types.FETCH_WALLET_MEMBERS_REQUEST);
const fetchWalletMembersSuccess = payload =>
  makeCommonAction(types.FETCH_WALLET_MEMBERS_SUCCESS, payload);
const fetchWalletMembersError = error =>
  makeCommonAction(types.FETCH_COMPANIES_ERROR, error, true);

const fetchCompanyRequest = () => makeCommonAction(types.FETCH_COMPANY_REQUEST);
const fetchCompanySuccess = payload =>
  makeCommonAction(types.FETCH_COMPANY_SUCCESS, payload);
const fetchCompanyError = error =>
  makeCommonAction(types.FETCH_COMPANY_ERROR, error, true);

const createCompanyRequest = () =>
  makeCommonAction(types.CREATE_COMPANY_REQUEST);
const createCompanySuccess = payload =>
  makeCommonAction(types.CREATE_COMPANY_SUCCESS, payload);
const createCompanyError = error =>
  makeCommonAction(types.CREATE_COMPANY_ERROR, error, true);

const updateCompanyRequest = () =>
  makeCommonAction(types.UPDATE_COMPANY_REQUEST);
const updateCompanySuccess = payload =>
  makeCommonAction(types.UPDATE_COMPANY_SUCCESS, payload);
const updateCompanyError = error =>
  makeCommonAction(types.UPDATE_COMPANY_ERROR, error, true);

const fetchCompanyContactsRequest = () =>
  makeCommonAction(types.FETCH_COMPANY_CONTACTS_REQUEST);
const fetchCompanyContactsSuccess = payload =>
  makeCommonAction(types.FETCH_COMPANY_CONTACTS_SUCCESS, payload);
const fetchCompanyContactsError = error =>
  makeCommonAction(types.FETCH_COMPANY_CONTACTS_ERROR, error, true);

const fetchCompanyContactRequest = () =>
  makeCommonAction(types.FETCH_COMPANY_CONTACT_REQUEST);
const fetchCompanyContactSuccess = payload =>
  makeCommonAction(types.FETCH_COMPANY_CONTACT_SUCCESS, payload);
const fetchCompanyContactError = error =>
  makeCommonAction(types.FETCH_COMPANY_CONTACT_ERROR, error, true);

const createCompanyContactRequest = () =>
  makeCommonAction(types.CREATE_COMPANY_CONTACT_REQUEST);
const createCompanyContactSuccess = payload =>
  makeCommonAction(types.CREATE_COMPANY_CONTACT_SUCCESS, payload);
const createCompanyContactError = error =>
  makeCommonAction(types.CREATE_COMPANY_CONTACT_ERROR, error, true);

const archiveCompanyContactRequest = () =>
  makeCommonAction(types.ARCHIVE_COMPANY_CONTACT_REQUEST);
const archiveCompanyContactSuccess = payload =>
  makeCommonAction(types.ARCHIVE_COMPANY_CONTACT_SUCCESS, payload);
const archiveCompanyContactError = error =>
  makeCommonAction(types.ARCHIVE_COMPANY_CONTACT_ERROR, error, true);

const updateCompanyContactRequest = () =>
  makeCommonAction(types.UPDATE_COMPANY_CONTACT_REQUEST);
const updateCompanyContactSuccess = payload =>
  makeCommonAction(types.UPDATE_COMPANY_CONTACT_SUCCESS, payload);
const updateCompanyContactError = error =>
  makeCommonAction(types.UPDATE_COMPANY_CONTACT_ERROR, error, true);

const fetchAssociatedCompaniesRequest = () =>
  makeCommonAction(types.FETCH_ASSOCIATED_COMPANIES_REQUEST);
const fetchAssociatedCompaniesSuccess = payload =>
  makeCommonAction(types.FETCH_ASSOCIATED_COMPANIES_SUCCESS, payload);
const fetchAssociatedCompaniesError = error =>
  makeCommonAction(types.FETCH_ASSOCIATED_COMPANIES_ERROR, error, true);

const createCompanyCompanyAssociationRequest = () =>
  makeCommonAction(types.CREATE_COMPANY_COMPANY_REQUEST);
const createCompanyCompanyAssociationSuccess = payload =>
  makeCommonAction(types.CREATE_COMPANY_COMPANY_SUCCESS, payload);
const createCompanyCompanyAssociationError = error =>
  makeCommonAction(types.CREATE_COMPANY_COMPANY_ERROR, error, true);

const deleteCompanyCompanyAssociationRequest = () =>
  makeCommonAction(types.DELETE_COMPANY_COMPANY_REQUEST);
const deleteCompanyCompanyAssociationSuccess = payload =>
  makeCommonAction(types.DELETE_COMPANY_COMPANY_SUCCESS, payload);
const deleteCompanyCompanyAssociationError = error =>
  makeCommonAction(types.DELETE_COMPANY_COMPANY_ERROR, error, true);

const createCompanyNoteRequest = () =>
  makeCommonAction(types.CREATE_COMPANY_NOTE_REQUEST);
const createCompanyNoteSuccess = payload =>
  makeCommonAction(types.CREATE_COMPANY_NOTE_SUCCESS, payload);
const createCompanyNoteError = error =>
  makeCommonAction(types.CREATE_COMPANY_NOTE_ERROR, error, true);

const deleteCompanyNoteRequest = () =>
  makeCommonAction(types.DELETE_COMPANY_NOTE_REQUEST);
const deleteCompanyNoteSuccess = payload =>
  makeCommonAction(types.DELETE_COMPANY_NOTE_SUCCESS, payload);
const deleteCompanyNoteError = error =>
  makeCommonAction(types.DELETE_COMPANY_NOTE_ERROR, error, true);

export function fetchCompanies(options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      "companies",
      options.include || "[brands,activeInventoryItems]",
      options
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompaniesRequest,
    successFunc: fetchCompaniesSuccess,
    errorFunc: fetchCompaniesError,
    afterReqFunc: response =>
      (response.meta["currentPage"] = (options && options.page) || 1),
    error_message: "Unable to get companies.",
  });
}

export function searchCompanies(query, options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildSearchUrl(
      "companies",
      "brands",
      query,
      options
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompaniesRequest,
    successFunc: fetchCompaniesSuccess,
    errorFunc: fetchCompaniesError,
    afterReqFunc: response => (response.meta["currentPage"] = 1),
    error_message: "Unable to get companies.",
  });
}

export function fetchCompany(id, include) {
  const companyInclude =
    include ||
    "contacts.walletMembers,orders,brands,associatedCompanies,users.[country,recipient],userAssociations,notes.createdB" +
      "yUser,paymentTerm,businessCategory,discountCategory";

  return makeActionCreator({
    endpoint: `/companies/${id}/?include=[${companyInclude}]`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompanyRequest,
    successFunc: fetchCompanySuccess,
    errorFunc: fetchCompanyError,
    error_message: "Error fetching company",
  });
}

export function archiveUserCompany(companyId, userId, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/users/${userId}/association`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: archiveUserCompanyRequest,
    errorFunc: archiveUserCompanyError,
    error_message: "Error creating Company",
    successMessage: "User was removed successfully",
    successFunc: () => {
      callback && callback();
      return archiveUserCompanySuccess();
    },
  });
}

export function createCompany(company, options) {
  return makeActionCreator({
    endpoint: `/companies/?${options && stringify(options)}`,
    fetchOptions: {
      body: company,
      method: "POST",
    },
    requestFunc: createCompanyRequest,
    successFunc: createCompanySuccess,
    errorFunc: createCompanyError,
    error_message: "Error creating Company",
  });
}

export function updateCompany(company, include) {
  const id = company.id;
  delete company["id"];
  const companyInclude =
    include ||
    "contacts,orders,brands,associatedCompanies,users.recipient,userAssociations,notes.createdB" +
      "yUser,paymentTerm,businessCategory,discountCategory";

  return makeActionCreator({
    endpoint: `/companies/${id}?include=[${companyInclude}]`,
    fetchOptions: {
      body: company,
      method: "PUT",
    },
    requestFunc: updateCompanyRequest,
    successFunc: updateCompanySuccess,
    errorFunc: updateCompanyError,
    error_message: "Error updating company",
    successMessage: "Company updated successfully",
  });
}

export function fetchCompanyContacts(companyId, options = {}) {
  const url = RequestService.buildIndexUrl(
    `contactsbycompany/${companyId}`,
    options.include,
    options
  );

  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompanyContactsRequest,
    successFunc: fetchCompanyContactsSuccess,
    errorFunc: fetchCompanyContactsError,
    error_message: "Error fetching company contacts",
  });
}

export function searchCompanyContacts(companyId, query, options = {}) {
  const searchOptions = options || {
    orderBy: "first_name",
    orderDirection: "ASC",
  };
  const url = RequestService.buildSearchUrl(
    `contactsbycompany/${companyId}`,
    options.include,
    query,
    searchOptions
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompanyContactsRequest,
    successFunc: fetchCompanyContactsSuccess,
    errorFunc: fetchCompanyContactsError,
    error_message: "Error fetching company contacts",
  });
}

export function fetchCompanyContact(id) {
  return makeActionCreator({
    endpoint: `/companycontacts/${id}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchCompanyContactRequest,
    successFunc: fetchCompanyContactSuccess,
    errorFunc: fetchCompanyContactError,
    error_message: "Error fetching company contact",
  });
}

export function createCompanyContact(companyContact) {
  return makeActionCreator({
    endpoint: "/companycontacts/",
    fetchOptions: {
      body: companyContact,
      method: "POST",
    },
    requestFunc: createCompanyContactRequest,
    successFunc: createCompanyContactSuccess,
    errorFunc: createCompanyContactError,
    error_message: "Error creating contact",
    successMessage: "Recipient created successfully",
  });
}

export function archiveCompanyContact(companyId, companyContactId, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/company_contacts/${companyContactId}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: archiveCompanyContactRequest,
    errorFunc: archiveCompanyContactError,
    successFunc: () => {
      callback && callback();
      return archiveCompanyContactSuccess();
    },
    error_message: "Error updating user",
    successMessage: "Contact was archived successfully",
  });
}

export function updateCompanyContact(companyContact) {
  const {id, ...companyContactAttributes} = companyContact;
  return makeActionCreator({
    endpoint: `/companycontacts/${id}`,
    fetchOptions: {
      body: companyContactAttributes,
      method: "PUT",
    },
    requestFunc: updateCompanyContactRequest,
    successFunc: updateCompanyContactSuccess,
    errorFunc: updateCompanyContactError,
    error_message: "Error updating user",
    successMessage: "Recipient updated successfully",
  });
}

export function fetchAssociatedCompaniesByCompany(id) {
  return makeActionCreator({
    endpoint: `/associatedcompaniesbycompany/${id}?include=[lastUpdatedByUser,createdByUser,associatedCompany]`,
    fetchOptions: {
      method: "GET",
      sort: "name",
      sortDirection: "asc",
    },
    requestFunc: fetchAssociatedCompaniesRequest,
    successFunc: fetchAssociatedCompaniesSuccess,
    errorFunc: fetchAssociatedCompaniesError,
    error_message: "Error fetching associated companies",
  });
}

export function searchAssociatedCompaniesByCompany(id, query) {
  return makeActionCreator({
    endpoint: `/associatedcompaniesbycompany/${id}/search?search_term=${query}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchAssociatedCompaniesRequest,
    successFunc: fetchAssociatedCompaniesSuccess,
    errorFunc: fetchAssociatedCompaniesError,
    error_message: "Error fetching associated companies",
  });
}

export function createCompanyCompanyAssociation(data) {
  return makeActionCreator({
    endpoint: "/companycompanyassociations/",
    fetchOptions: {
      body: data,
      method: "POST",
    },
    requestFunc: createCompanyCompanyAssociationRequest,
    successFunc: createCompanyCompanyAssociationSuccess,
    errorFunc: createCompanyCompanyAssociationError,
    error_message: "Error creating company company association",
    successMessage: "Association created successfully",
  });
}
export function deleteCompanyCompanyAssociation(id) {
  return makeActionCreator({
    endpoint: `/companycompanyassociations/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteCompanyCompanyAssociationRequest,
    successFunc: deleteCompanyCompanyAssociationSuccess,
    errorFunc: deleteCompanyCompanyAssociationError,
    error_message: "Error deleting company company association",
  });
}

export function createCompanyNote(note) {
  return makeActionCreator({
    endpoint: "/companynotes/",
    fetchOptions: {
      body: note,
      method: "POST",
    },
    requestFunc: createCompanyNoteRequest,
    successFunc: createCompanyNoteSuccess,
    errorFunc: createCompanyNoteError,
    error_message: "Error deleting company note",
    successMessage: "Note created successfully",
  });
}

export function deleteCompanyNote(noteId) {
  return makeActionCreator({
    endpoint: `/companynotes/${Number(noteId)}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteCompanyNoteRequest,
    successFunc: deleteCompanyNoteSuccess,
    errorFunc: deleteCompanyNoteError,
    error_message: "Error deleting company note",
    successMessage: "Note deleted successfully",
  });
}

export function fetchWalletMembers(companyId, params = {}) {
  const options = {
    sort: "name",
    sortDirection: "asc",
    perPage: 7,
    page: 1,
    ...params,
  };

  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `companies/${companyId}/wallet_members`,
      options.include || "companyContact.[country]",
      options
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchWalletMembersRequest,
    successFunc: fetchWalletMembersSuccess,
    errorFunc: fetchWalletMembersError,
    error_message: "Error deleting company note",
  });
}

export function updateWalletMember(
  companyId,
  id,
  body,
  callback,
  successMessage = "Wallet member updated successfully"
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/wallet_members/${id}`,
    fetchOptions: {
      method: "PATCH",
      body,
    },
    requestFunc: updateWalletRequest,
    successFunc: () => {
      callback && callback();
      return updateWalletSuccess();
    },
    errorFunc: updateWalletError,
    error_message: "Error deleting wallet member",
    successMessage,
  });
}

export function createWalletMember(companyId, body, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/wallet_members`,
    fetchOptions: {
      method: "POST",
      body,
    },
    requestFunc: createWalletRequest,
    successFunc: () => {
      callback && callback();
      return createWalletSuccess();
    },
    errorFunc: createWalletError,
    error_message: "Error creating wallet member",
    successMessage: "Wallet member created successfully",
  });
}
