import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../actions/userActions";
import {fetchCountries} from "../../../actions/geoActions";
import {fetchCompanies, fetchCompany} from "../../../actions/companyActions";
import {push} from "react-router-redux";
import {openNotification} from "../../../actions/notificationActions";
import {bindActionCreators} from "redux";

import UserEditPage from "../../pages/Users/UserEditPage";

class UserEdit extends Component {
  componentWillMount() {
    const {
      fetchUser,
      fetchCountries,
      fetchCompanies,
      fetchCompany,
      match: {params},
    } = this.props;
    fetchUser(params.id);
    fetchCountries();
    fetchCompanies({sort: "name", sortDirection: "ASC"});
    if (params.company_id) {
      fetchCompany(params.company_id);
    }
  }
  render() {
    const {updateUserWithCompanies, match: {params}} = this.props;
    return (
      <UserEditPage
        updateUserWithCompanies={user => updateUserWithCompanies(user)}
        userId={params.id}
        {...this.props}
      />
    );
  }
}

UserEdit.propTypes = {
  openNotification: PropTypes.func.isRequired,
  userDetail: PropTypes.object,
  updateUserWithCompanies: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchCompanies: PropTypes.func,
  match: PropTypes.object,
  isInternal: PropTypes.bool,
  fetchCompany: PropTypes.func,
  company: PropTypes.object,
  errorMessage: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isUpdating: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    countries: state.geo.countries,
    companies: state.companies.companies,
    userDetail: state.users.userDetail,
    isLoading: state.users.isLoading,
    isInternal: state.auth.isInternal,
    company: state.companies.company,
    error: state.users.error,
    errorMessage: state.users.error_message,
    isUpdating: state.users.isUpdating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserWithCompanies: user =>
      dispatch(actions.updateUserWithCompanies(user)),
    fetchUser: id => dispatch(actions.fetchUser(id)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompanies: options => dispatch(fetchCompanies(options)),
    navigateTo: payload => dispatch(push(payload)),
    fetchCompany: id => dispatch(fetchCompany(id)),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserEdit)
);
