import React, {Component} from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Snackbar from "material-ui/Snackbar";

import {closeNotification} from "../../../../actions/notificationActions";

import PageContainer from "./PageContainer";
import BodyContainer from "./BodyContainer";
import ContentContainer from "./ContentContainer";
import SubHeader from "../../PageComponents/SubHeader";
import Footer from "../../Footer/Footer";
import AuthFooter from "../../Footer/AuthFooter";

class PageLayout extends Component {
  componentDidUpdate(nextProps) {
    if (this.props.isLoading !== nextProps.isLoading) {
      window.closeSplash();
    }
  }

  componentDidMount() {
    const pageContainer = document.getElementsByClassName("PageContainer")[0];
    if (pageContainer) pageContainer.scrollTop = 0;
  }

  render() {
    const {
      authFooter,
      footerStyles,
      subHeaderText,
      smallTop,
      children,
      notification,
      isLoading,
      closeNotification,
    } = this.props;

    return (
      <PageContainer>
        <Snackbar
          open={notification.show}
          message={notification.message}
          autoHideDuration={notification.duration || 4000}
          onRequestClose={closeNotification}
          className="alert-window non-printable"
        />
        <BodyContainer>
          {subHeaderText ? <SubHeader text={subHeaderText} /> : null}
          <ContentContainer
            subheader={subHeaderText ? true : false}
            smallTop={smallTop}
            hasSubheader={subHeaderText ? true : false}
          >
            <ReactCSSTransitionGroup
              transitionName="loader"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
              transitionEnter={false}
              transitionLeave={false}
              transitionAppear={true}
              transitionAppearTimeout={500}
              component="div"
              aria-busy={isLoading}
            >
              <div key="loader-children-container">{children}</div>
            </ReactCSSTransitionGroup>
          </ContentContainer>
        </BodyContainer>
        {authFooter ? (
          <AuthFooter styles={footerStyles} />
        ) : (
          <Footer styles={footerStyles} />
        )}
      </PageContainer>
    );
  }
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  authFooter: PropTypes.bool,
  footerStyles: PropTypes.object,
  subHeaderText: PropTypes.string,
  smallTop: PropTypes.bool,
  openNotification: PropTypes.func,
  closeNotification: PropTypes.func,
  notification: PropTypes.object,
  isLoading: PropTypes.bool,
  loaderEnable: PropTypes.bool,
};

PageLayout.defaultProps = {
  notification: {
    show: false,
    message: "",
  },
};

function mapStateToProps(state) {
  const {notification} = state;

  return {notification};
}

function mapDispatchToProps(dispatch) {
  return {
    closeNotification: bindActionCreators(closeNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageLayout)
);
