import React, {Component} from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";

import Pagination from "../../shared/Pagination";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../../shared/PageComponents/PageIndexHeader";
import CompanyIndexTable from "./Components/CompanyIndexTable";
import {hasQueryParamsChanged} from "../../../utils/indexHelper";

export default class CompaniesIndexPage extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    fetchCompany: PropTypes.func.isRequired,
    searchCompanies: PropTypes.func.isRequired,
    fetchCompanies: PropTypes.func.isRequired,
    companiesCount: PropTypes.number,
    currentPage: PropTypes.number,
    isLoading: PropTypes.bool,
    page: PropTypes.number,
    setSort: PropTypes.func,
    setPage: PropTypes.func,
    setSearchTerm: PropTypes.func,
    sortDirection: PropTypes.string,
    sortBy: PropTypes.string,
    searchTerm: PropTypes.string,
  };

  state = {
    search: "",
    sortDirection: "asc",
  };

  _sortRows() {
    const {sortBy, setSort} = this.props;
    const nextSortDirection =
      !sortBy || (typeof sortBy === "string" && !sortBy.length) ? "DESC" : null;
    setSort("name", nextSortDirection); ///it's the only attribute available
  }

  constructor(props) {
    super(props);
    this._searchCompanies();
  }

  componentDidUpdate(prevProps) {
    if (hasQueryParamsChanged(this.props, prevProps)) {
      this._searchCompanies();
    }
  }

  _searchCompanies() {
    const {
      fetchCompanies,
      searchCompanies,
      searchTerm,
      sortBy,
      sortDirection,
      page,
    } = this.props;

    const orderBy = sortBy || "name";

    if (searchTerm == "") {
      return fetchCompanies({
        orderBy,
        sortDirection,
        page,
        perPage: 7,
      });
    }
    searchCompanies(searchTerm, {
      orderBy,
      sortDirection,
      page,
    });
  }

  searchCompanies(e, value) {
    this.props.setSearchTerm(value);
  }

  _onPageChangeHandler(options) {
    const {setPage} = this.props;
    setPage(options.page);
  }

  _renderPaginiation() {
    return (
      <div className="userindex_pagination">
        <Pagination
          getData={options => this._onPageChangeHandler(options)}
          count={this.props.companiesCount}
          currentPage={this.props.page - 1}
          perPage={7}
        />
      </div>
    );
  }

  _hasCompanies() {
    const {companies} = this.props;
    return Array.isArray(companies) && companies.length > 0;
  }

  render() {
    const {companies, companiesCount, isLoading, searchTerm} = this.props;
    return (
      <PageLayout isLoading={companiesCount && isLoading} loaderEnable={true}>
        <PageIndexHeader
          containerClass="userindex_top"
          title="Companies"
          titleIcon="company"
          searchFunc={debounce(this.searchCompanies.bind(this), 300, {
            leading: false,
            trailing: true,
          })}
          createPage="/companies/create"
          searchTerm={searchTerm}
          buttonIcon="add2-white"
          buttonText="NEW COMPANY"
        />

        <h2
          className={`userindex_noUsers ${
            this._hasCompanies() ? "hidden" : ""
          }`}
        >
          Sorry No Companies
        </h2>
        <div className={this._hasCompanies() ? null : "hidden"}>
          <CompanyIndexTable
            companies={companies}
            isLoading={isLoading}
            sortColumns={() => this._sortRows()}
            fetchCompanies={this.props.fetchCompanies}
          />{" "}
          {companiesCount && companiesCount > 7
            ? this._renderPaginiation()
            : null}
        </div>
      </PageLayout>
    );
  }
}
