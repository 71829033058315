import React from "react";
import PropTypes from "prop-types";

import Notes from "../../../../shared/Notes/Notes";

const CompanyNotesTab = ({
  company,
  deleteCompanyNote,
  createCompanyNote,
  notes,
  isLoading,
}) => {
  return (
    <div className="tab-container">
      <Notes
        isLoading={isLoading}
        notes={company && notes}
        company={company}
        userDetail={company}
        deleteNote={deleteCompanyNote}
        createNote={createCompanyNote}
      />
    </div>
  );
};

CompanyNotesTab.propTypes = {
  company: PropTypes.object,
  notes: PropTypes.array,
  deleteCompanyNote: PropTypes.func,
  createCompanyNote: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CompanyNotesTab;
