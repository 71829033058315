import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../actions/userActions";
import {fetchCountries} from "../../../actions/geoActions";
import {fetchCompanies, fetchCompany} from "../../../actions/companyActions";
import {openNotification} from "../../../actions/notificationActions";
import {push} from "react-router-redux";
import {bindActionCreators} from "redux";

import UserCreatePage from "../../pages/Users/UserCreatePage";

class UserCreate extends Component {
  componentWillMount() {
    window.closeSplash();
    const {match: {params: {company_id}}} = this.props;
    this.props.fetchCountries();
    this.props.fetchCompanies({sort: "name", sortDirection: "ASC"});
    company_id && this.props.fetchCompany(company_id, "");
  }

  contactsWithoutUser() {
    const {company} = this.props;
    if (!company) return [];

    return company.contacts.filter(({userId}) => !userId);
  }

  render() {
    return (
      <UserCreatePage
        createUserWithCompanies={user =>
          this.props.createUserWithCompanies(user)
        }
        {...this.props}
        contactsWithoutUser={this.contactsWithoutUser()}
      />
    );
  }
}

UserCreate.propTypes = {
  openNotification: PropTypes.func.isRequired,
  createUserWithCompanies: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchCompanies: PropTypes.func,
  fetchCompany: PropTypes.func,
  match: PropTypes.object,
  company: PropTypes.object,
  errorMessage: PropTypes.string,
  isInternal: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoading: state.users.isLoading,
    error: state.users.error,
    errorMessage: state.users.error_message,
    countries: state.geo.countries,
    companies: state.companies.companies,
    company: state.companies.company,
    isInternal: state.auth.isInternal,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createUserWithCompanies: user =>
      dispatch(actions.createUserWithCompanies(user)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompanies: options => dispatch(fetchCompanies(options)),
    fetchCompany: id => dispatch(fetchCompany(id)),
    navigateTo: payload => dispatch(push(payload)),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserCreate)
);
