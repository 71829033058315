import React, {Component} from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import ChillButton from "../shared/ChillButton";

class ArchiveModal extends Component {
  static propTypes = {
    user: PropTypes.object,
    archiveUser: PropTypes.func,
  };
  state = {
    open: false,
  };

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {
    return (
      <div>
        <ChillButton
          className="userDetail_archiveBtn"
          name={"ARCHIVE"}
          height={32}
          width={110}
          icon={"archive"}
          secondary={true}
          label="Dialog"
          onClick={() => this.handleOpen()}
        />

        <Dialog
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          contentStyle={archiveModalStyles}
          contentClassName={"archiveModalStyles"}
          bodyStyle={{padding: 0}}
          overlayClassName={"dialog-overlay"}
        >
          <img
            onClick={() => this.handleClose()}
            className="archiveModal_closeIcon"
            src="../assets/imgs/icons/close.svg"
          />
          <h3 className="archiveModal_title">
            Are you sure you’d like to archive
          </h3>
          <h3 className="archiveModal_title">{this.props.user.name}</h3>
          <p className="archiveModal_text">
            {" "}
            This user will be removed from linked companies and searches.
          </p>
          <button
            onClick={() => this.props.archiveUser(this.props.user.id)}
            className="btn-1 archiveModal_btn"
          >
            Yes, Archive
          </button>
          <span
            onClick={() => this.handleClose()}
            className="archiveModal_cancel"
          >
            Cancel
          </span>
        </Dialog>
      </div>
    );
  }
}

const archiveModalStyles = {
  height: 328,
  width: 545,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
};

export default ArchiveModal;
