import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import {TableRow, TableRowColumn, TableHeader} from "material-ui/Table";

import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import ProductOrderItem from "./ProductOrderItem";
import LaborOrderItem from "./LaborOrderItem";
import MiscellaneousOrderItem from "./MiscellaneousOrderItem";
import DiscountOrderItem from "./DiscountOrderItem";

import {tableStyle, thStyle} from "../../../../styles/components/tableStyles";
import {
  PRODUCT_ORDER_ITEM_NAME,
  LABOR_ORDER_ITEM_NAME,
  SETUP_ORDER_ITEM_NAME,
  MISCELLANEOUS_COST,
  DISCOUNT_ORDER_ITEM_NAME,
} from "../../../../constants/orderItemType";

const helperStyle = {
  margin: "8px 0",
};

const titleStyle = {
  marginTop: 14,
};

class OrderItemsTable extends Component {
  getFirstColumnStyle = isReordering => {
    return {
      ...thStyle,
      width: 180,
      paddingRight: 0,
      paddingLeft: isReordering ? 64 : 32,
    };
  };

  _renderTableHeader() {
    const {isReordering, isEditingOrder} = this.props;
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
      >
        <TableRow
          displayBorder={false}
          style={{
            display: "block",
            height: 26,
          }}
        >
          <TableRowColumn
            style={this.getFirstColumnStyle(isReordering)}
            className="CNG-th pointer"
          >
            Item name & Number
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 90,
              paddingRight: 0,
            }}
            className="CNG-th pointer"
          >
            Art used
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 75,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            to ship
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 100,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            inventory
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 100,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            Qty. ordered
          </TableRowColumn>

          <TableRowColumn
            style={{
              ...thStyle,
              width: 100,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            Unit cost
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 85,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            Qty. Disc.
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 90,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            Adorn. Cost
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              width: 135,
              paddingRight: 0,
            }}
            className="CNG-th"
          >
            Net unit cost
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
            }}
            className="CNG-th"
          >
            Total
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...thStyle,
              padding: 0,
              maxWidth: 64,
            }}
            className="CNG-th"
          >
            {isEditingOrder ? "" : "Shipping"}
          </TableRowColumn>
        </TableRow>
      </TableHeader>
    );
  }

  renderItem = orderItem => {
    const {isEditingOrder} = this.props;
    switch (lodash.get(
      orderItem,
      "orderItemType.name",
      PRODUCT_ORDER_ITEM_NAME
    )) {
    case PRODUCT_ORDER_ITEM_NAME:
      return (
        <ProductOrderItem
          isEditingOrder={isEditingOrder}
          {...this.props}
          orderItem={orderItem}
          key={orderItem.id}
        />
      );
    case LABOR_ORDER_ITEM_NAME:
      return (
        <LaborOrderItem
          {...this.props}
          orderItem={orderItem}
          key={orderItem.id}
        />
      );
    case SETUP_ORDER_ITEM_NAME:
      return (
        <LaborOrderItem
          {...this.props}
          title="Set Up Cost"
          orderItem={orderItem}
          key={orderItem.id}
        />
      );
    case MISCELLANEOUS_COST:
      return (
        <MiscellaneousOrderItem
          {...this.props}
          orderItem={orderItem}
          key={orderItem.id}
        />
      );
    case DISCOUNT_ORDER_ITEM_NAME:
      return (
        <DiscountOrderItem
          {...this.props}
          orderItem={orderItem}
          key={orderItem.id}
        />
      );
    default:
      return undefined;
    }
  };

  render() {
    const {items, isReordering} = this.props;
    return (
      <div>
        <div className="section-header" style={titleStyle}>
          REORDER ITEMS
        </div>
        {isReordering && (
          <div style={helperStyle}>
            Select products to add them to your Re-Order.
          </div>
        )}
        <CNGTableCustom
          tableStyle={{
            ...tableStyle,
          }}
          displayRowCheckbox={false}
          sort={this._sortRows}
          className="CNG-table CNG-table--removeHeaderHeight "
        >
          {this._renderTableHeader()}
        </CNGTableCustom>
        {items && items.map(this.renderItem)}
      </div>
    );
  }
}

OrderItemsTable.propTypes = {
  companyId: PropTypes.string,
  items: PropTypes.array.isRequired,
  isReordering: PropTypes.bool,
  isInternal: PropTypes.bool,
  reorderItems: PropTypes.array,
  shipments: PropTypes.array,
  onToggleReorderItem: PropTypes.func,
  onUpdateShipmentItem: PropTypes.func,
  updateShipment: PropTypes.func,
  states: PropTypes.array,
  countries: PropTypes.array,
  inventoryItems: PropTypes.array,
  isPrintActive: PropTypes.any,
  isEditingOrder: PropTypes.bool,
};

export default OrderItemsTable;
