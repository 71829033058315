import React, {Component} from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import _get from "lodash/get";

import {
  formStyles,
  TextFieldSmallLabelStyles,
  SelectFieldIconStyle,
} from "../../../../styles/components/formStyles";

import CNGMenuItem from "../../../shared/CNGMenuItem";
import CNGDatePicker from "../../../shared/CNGDatePicker";
import ChillButton from "../../../shared/ChillButton";
import LineHeader from "../../../shared/LineHeader";
import NotesForm from "../../../shared/NotesForm";
import {getFormattedDate, getTodayDate} from "../../../../utils/dates";
import OrderShipmentForm from "../../Orders/components/OrderShipmentForm";
import RecipientCard from "./RecipientCard";
import lodash from "lodash";

const formInputStyle = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 23,
};

const HalfTextInputStylesWithGap = {
  ...formStyles.HalfTextInputStyles,
  marginRight: 32,
  width: 150,
};

const TODAY = getTodayDate();

class DSODCartForm extends Component {
  validations = {
    inHandDate: {
      format: "any",
      length: [2, 255],
      nullable: true,
    },
    estimatedShipDate: {
      format: this.props.isInternal ? "any" : undefined,
      length: this.props.isInternal ? [2, 255] : undefined,
      nullable: () => !this.props.isInternal,
    },
  };

  state = {
    errors: {},
    submitted: false,
  };

  componentWillReceiveProps = nextProps => {
    this._hasOrderChanged(nextProps);
  };

  _hasOrderChanged(nextProps) {
    const {dsodItems, order, shipmentData, orderData} = nextProps;

    if (
      this.props.dsodItems !== dsodItems ||
      this.props.order !== order ||
      shipmentData !== this.props.shipmentData ||
      this.props.orderData !== orderData
    ) {
      nextProps.onSetIsValid(this._isValidForm(nextProps));
    }
  }

  _emptyDsodItems = dsodItems => {
    return (
      dsodItems &&
      dsodItems.filter(
        dsodItem =>
          dsodItem.orderQuantity === 0 ||
          !dsodItem.recipientId ||
          !dsodItem.productId
      )
    );
  };

  _isValidForm = props => {
    const {
      orderData: {estimatedShipOn, createdUserId},
      shipmentData: {shipment_account_id, shipping_method_id},
      dsodItems,
      isInternal,
      currentUserId,
    } = props;

    if (currentUserId === createdUserId && isInternal) {
      return false;
    }

    if (dsodItems && !dsodItems.length) return false;

    const emptyDsodItems = this._emptyDsodItems(dsodItems);
    const isEstShipDateValid = isInternal ? estimatedShipOn !== null : true;
    return (
      isEstShipDateValid &&
      shipment_account_id !== null &&
      shipping_method_id !== null &&
      emptyDsodItems &&
      emptyDsodItems.length === 0
    );
  };

  _getRecipientfromDsodItems(dsodItems) {
    if (!dsodItems.length) return;
    const {companyContacts} = this.props;
    const dsodItem = dsodItems[0];

    return companyContacts.find(
      contact => Number(contact.id) === dsodItem.recipientId
    );
  }

  _renderShipDate = (isInternal, orderData, onOrderChange, onSaveDraft) => {
    return (
      isInternal && (
        <CNGDatePicker
          floatingLabelText="Est. Ship date*"
          name="estimatedShipDate"
          value={
            orderData.estimatedShipOn && new Date(orderData.estimatedShipOn)
          }
          onChange={(event, value) =>
            onOrderChange(event, value, "estimatedShipOn", onSaveDraft)
          }
          style={HalfTextInputStylesWithGap}
          datePickerStyle={formStyles.HalfTextInputStyles}
          textFieldStyle={formStyles.HalfTextInputStyles}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          minDate={TODAY}
        />
      )
    );
  };

  _renderDatesRow() {
    const today = getFormattedDate(new Date());
    const {
      company,
      orderData = {},
      onOrderChange,
      onSaveDraft,
      isInternal,
    } = this.props;

    return (
      <div className="margin-bottom-24 flex">
        <TextField
          disabled={true}
          defaultValue={today}
          floatingLabelText="Date of request"
          underlineShow={false}
          style={formStyles.SmallInputStyles}
        />
        <CNGDatePicker
          showClear
          floatingLabelText="In-Hand Date"
          name="inHandDate"
          value={orderData.inHandDate && new Date(orderData.inHandDate)}
          onChange={(event, value) =>
            onOrderChange(event, value, "inHandDate", onSaveDraft)
          }
          style={HalfTextInputStylesWithGap}
          datePickerStyle={formStyles.HalfTextInputStyles}
          textFieldStyle={formStyles.HalfTextInputStyles}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          autoFocus={true}
        />{" "}
        {this._renderShipDate(
          isInternal,
          orderData,
          onOrderChange,
          onSaveDraft
        )}
        {company &&
          isInternal && (
          <SelectField
            floatingLabelText="Requesting user*"
            floatingLabelStyle={formStyles.TextFieldLabelStyles}
            name="createdUserId"
            value={orderData && orderData.createdUserId + ""}
            onChange={(_, __, value) => {
              onOrderChange(null, value, "createdUserId", onSaveDraft);
            }}
            ref={el => (this.contactSelectRef = el)}
            style={{
              ...formInputStyle,
            }}
            inputStyle={{
              ...formStyles.HalfTextInputStyles,
              height: 68,
            }}
            iconStyle={{
              ...formStyles.HalfIconStyles,
              ...SelectFieldIconStyle,
              marginRight: 50,
            }}
            menuStyle={{
              ...formStyles.MenuStyle,
              width: 250,
            }}
            underlineStyle={formStyles.underlineStyle}
            labelStyle={{
              ...formStyles.TextFieldLabelStyles,
              color: "#4A5158",
            }}
          >
            {_get(company, "users").map(companyContact => {
              return (
                <CNGMenuItem
                  key={companyContact.id}
                  value={companyContact.id + ""}
                  primaryText={companyContact.name}
                />
              );
            })}
          </SelectField>
        )}
      </div>
    );
  }

  _renderShippingInformation() {
    const {
      onShipmentChange,
      shipmentData,
      shippingMethods,
      shipmentAccounts,
      orderData,
      onSaveDraft,
      company,
      paymentTerms,
      isInternal,
      onOrderChange,
      isDisabled,
    } = this.props;

    return (
      <div className="margin-bottom-40">
        <p>Shipment Information</p>
        <OrderShipmentForm
          isDSOD
          companyShippingCarrierAssignments={shipmentAccounts}
          shipmentData={shipmentData}
          shippingMethods={shippingMethods}
          onShipmentChange={(event, value, formName) => {
            onShipmentChange(
              event,
              value,
              formName,
              lodash.debounce(onSaveDraft, 300)
            );
          }}
          showInputCost={false}
          onSaveDraft={onSaveDraft}
          inputCostEditable
          company={company}
          orderData={orderData}
          onOrderChange={onOrderChange}
          paymentTerms={paymentTerms}
          isInternal={isInternal}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  _renderEmptyState = () => {
    const {companies, companyContacts} = this.props;

    return (
      <div className="recipientCards">
        <RecipientCard
          companyId={null}
          companies={companies}
          companyContacts={companyContacts}
        />
      </div>
    );
  };

  _reorderGroupRecipients = dsodItemsByrecipient => {
    const getCreatedAtCallback = recipient => {
      return new Date(recipient.createdAt).getTime();
    };

    return Object.keys(dsodItemsByrecipient).sort(
      (firstRecipientId, secondRecipientId) => {
        const firstMinCreatedAt = Math.min(
          dsodItemsByrecipient[firstRecipientId].map(getCreatedAtCallback)
        );
        const secondMinCreateAt = Math.min(
          dsodItemsByrecipient[secondRecipientId].map(getCreatedAtCallback)
        );

        return firstMinCreatedAt > secondMinCreateAt;
      }
    );
  };

  _isEmptyDSOD() {
    const {dsodItems} = this.props;
    return dsodItems && !dsodItems.length;
  }

  _canAddRecipient() {
    const {dsodItems = [], inventoryItems, companyContacts} = this.props;
    const dsodItemsByrecipient = lodash.groupBy(dsodItems, "recipientId");
    const activeRecipientIds = Object.keys(dsodItemsByrecipient);
    const filteredCompanyContacts = companyContacts.filter(
      contact => !activeRecipientIds.includes(`${contact.id}`)
    );
    const dsodItemsWithoutProducts = (dsodItems || []).filter(
      dsodItem => !dsodItem.recipientId && !dsodItem.productId
    );

    if (dsodItemsWithoutProducts.length !== 0) {
      return false;
    }

    return (
      dsodItems &&
      dsodItems.length >= 1 &&
      dsodItems[0].recipientId &&
      inventoryItems.length > 0 &&
      (filteredCompanyContacts && filteredCompanyContacts.length > 0)
    );
  }

  render() {
    const {
      onSubmitOrder,
      companyId,
      companies,
      companyContacts,
      onUpdateDsodRecipient,
      inventoryItems,
      inventoryItemsCount,
      fetchInventoryItems,
      updateDsodItem,
      createDsodItem,
      order,
      dsodItems = [],
      openNotification,
      deleteDsodItem,
      onFetchDsodItems,
      orderData,
      onOrderChange,
      onSaveDraft,
      isInternal,
    } = this.props;

    const dsodItemsByrecipient = lodash.groupBy(dsodItems, "recipientId");
    const activeRecipientIds = Object.keys(dsodItemsByrecipient);
    const filteredCompanyContacts = companyContacts.filter(
      contact => !activeRecipientIds.includes(`${contact.id}`)
    );
    const activeContacts = companyContacts.filter(contact =>
      activeRecipientIds.includes(`${contact.id}`)
    );
    const recipientIds = this._reorderGroupRecipients(dsodItemsByrecipient);
    return (
      <div className="dsod-cart-form">
        {this._renderDatesRow()}
        {this._renderShippingInformation()}

        <div className="recipientCards">
          {this._isEmptyDSOD()
            ? this._renderEmptyState()
            : recipientIds.map(recipientId => (
              <RecipientCard
                openNotification={openNotification}
                fetchInventoryItems={fetchInventoryItems}
                inventoryItems={inventoryItems}
                inventoryItemsCount={inventoryItemsCount}
                key={recipientId}
                recipient={this._getRecipientfromDsodItems(
                  dsodItemsByrecipient[recipientId]
                )}
                dsodItems={dsodItemsByrecipient[recipientId]}
                fullDsodItems={dsodItems}
                onFetchDsodItems={onFetchDsodItems}
                companyId={companyId}
                companies={companies}
                companyContacts={filteredCompanyContacts}
                activeContacts={activeContacts}
                onUpdateDsodRecipient={onUpdateDsodRecipient}
                updateDsodItem={updateDsodItem}
                deleteDsodItem={deleteDsodItem}
                createDsodItem={createDsodItem}
              />
            ))}

          {this._canAddRecipient() ? (
            <LineHeader
              title="Add recipient"
              icon="add"
              onClick={() => {
                createDsodItem(companyId, {order_id: order.id});
              }}
            />
          ) : null}
        </div>
        <div className="flex align-items-end flex-column">
          <ChillButton
            className="chill-button"
            icon="check"
            name="SUBMIT DSOD"
            small={false}
            width={137}
            height={40}
            isDisabled={!this.props.isValid}
            onClick={() => {
              this.props.isValid && onSubmitOrder();
            }}
          />
        </div>

        <NotesForm
          order={orderData}
          isInternal={isInternal}
          onChange={onOrderChange}
          onSaveDraft={onSaveDraft}
        />
      </div>
    );
  }
}

DSODCartForm.propTypes = {
  company: PropTypes.object,
  isValid: PropTypes.bool,
  onFetchDsodItems: PropTypes.func,
  onSetIsValid: PropTypes.func,
  onSubmitOrder: PropTypes.func,
  createDsodItem: PropTypes.func.isRequired,
  updateDsodItem: PropTypes.func.isRequired,
  onShipmentChange: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  onOrderChange: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onUpdateDsodRecipient: PropTypes.func,
  shipmentAccounts: PropTypes.array,
  shippingMethods: PropTypes.array,
  shipments: PropTypes.array,
  shipmentData: PropTypes.object,
  companyId: PropTypes.number,
  companyContacts: PropTypes.array,
  companies: PropTypes.array.isRequired,
  recipients: PropTypes.array.isRequired,
  errors: PropTypes.object,
  submitted: PropTypes.bool,
  orderData: PropTypes.object,
  order: PropTypes.object,
  dsodItems: PropTypes.array,
  inventoryItems: PropTypes.array,
  fetchInventoryItems: PropTypes.func,
  inventoryItemsCount: PropTypes.number,
  openNotification: PropTypes.func,
  deleteDsodItem: PropTypes.func,
  isInternal: PropTypes.bool,
  isDisabled: PropTypes.func,
  paymentTerms: PropTypes.array,
};

export default DSODCartForm;
