import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {fetchCountries} from "../../../../../actions/geoActions";
import {
  fetchQuote,
  updateQuote,
  deleteQuote,
  fetchQuoteItemPriceTable,
  assignQuoteToKnownCompany,
} from "../../../../../actions/v2/quoteActions";
import {fetchProductCategories} from "../../../../../actions/v2/productCategoriesActions";
import {fetchProducts} from "../../../../../actions/productActions";
import {fetchFabrics} from "../../../../../actions/fabricActions";
import {fetchColors} from "../../../../../actions/colorActions";
import {fetchAdornmentLocationsByProduct} from "../../../../../actions/adornmentLocationActions";
import {fetchAdornmentTypes} from "../../../../../actions/adornmentTypeActions";
import {openNotification} from "../../../../../actions/notificationActions";
import {
  fetchCompanies,
  searchCompanies,
} from "../../../../../actions/companyActions";
import {
  fetchArtRevisionsByCompany,
  clearArtRevisions,
} from "../../../../../actions/artActions";
import {fetchDigitalizationTypePrices} from "../../../../../actions/digitalizacionTypePriceActions";
import QuoteDetail from "./QuoteDetail";
import {fetchUserEmailValidationByCompany} from "../../../../../actions/authActions";

const QuoteDetailContainer = ({quote, history, isInternal, ...props}) => {
  if (!quote) return <span />;
  if (
    ["sent_for_approval", "sent_for_art_approval", "art_approved"].includes(
      quote.status
    ) &&
    !isInternal
  ) {
    history.push(`/quotes/summary?quote=${quote.uuid}`);
  }

  return (
    <QuoteDetail
      {...props}
      quote={quote}
      history={history}
      isInternal={isInternal}
    />
  );
};

QuoteDetailContainer.propTypes = {
  quote: PropTypes.object,
  history: PropTypes.object,
  isInternal: PropTypes.object,
};

function mapStateToProps(state, props) {
  const {match: {params}} = props;
  const {
    quotes,
    productCategories: {categories},
    products: {products},
    fabrics: {fabrics},
    colors: {colors},
    adornmentLocations: {adornmentLocations: locations},
    adornmentTypes: {adornmentTypes},
    geo,
    companies,
    art: {artRevisions},
    auth: {isInternal},
    digitalizationTypePrices: {digitalizationTypePrices},
  } = state;
  return {
    quoteId: params.id,
    quote: quotes.quote || {},
    itemPrices: quotes.itemPrices,
    isLoadingPrices: quotes.isLoadingPrices,
    categories,
    products,
    fabrics,
    colors,
    locations,
    adornmentTypes,
    countries: geo.countries,
    isLoading: quotes.isLoadingQuote,
    companies: companies.companies,
    companyArts: artRevisions,
    isInternal,
    digitalizationTypePrices: digitalizationTypePrices || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchQuote: bindActionCreators(fetchQuote, dispatch),
    fetchCategories: bindActionCreators(fetchProductCategories, dispatch),
    fetchProducts: bindActionCreators(fetchProducts, dispatch),
    fetchFabrics: bindActionCreators(fetchFabrics, dispatch),
    fetchColors: bindActionCreators(fetchColors, dispatch),
    fetchAdornmentLocationsByProduct: bindActionCreators(
      fetchAdornmentLocationsByProduct,
      dispatch
    ),
    fetchUserEmailValidationByCompany: bindActionCreators(fetchUserEmailValidationByCompany, dispatch),
    fetchAdornmentTypes: bindActionCreators(fetchAdornmentTypes, dispatch),
    updateQuote: bindActionCreators(updateQuote, dispatch),
    deleteQuote: bindActionCreators(deleteQuote, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchItemPriceTable: bindActionCreators(fetchQuoteItemPriceTable, dispatch),
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    searchCompanies: bindActionCreators(searchCompanies, dispatch),
    assignQuoteToKnownCompany: bindActionCreators(
      assignQuoteToKnownCompany,
      dispatch
    ),
    fetchArtRevisionsByCompany: bindActionCreators(
      fetchArtRevisionsByCompany,
      dispatch
    ),
    fetchDigitalizationTypePrices: bindActionCreators(
      fetchDigitalizationTypePrices,
      dispatch
    ),
    clearArtRevisions: bindActionCreators(clearArtRevisions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteDetailContainer)
);
