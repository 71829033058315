import SendBird from "sendbird";
import _get from "lodash/get";

class Messenger {
  channelList = [];
  currentChannel = null;
  listeners = {};

  constructor(user) {
    if (Messenger.instance) {
      return Messenger.instance;
    }
    Messenger.instance = this;
    this.cngUser = user;
    this.sb = new SendBird({appId: process.env.SENDBIRD_APP_ID});
    this.channelHandler = new this.sb.ChannelHandler();
    this.sb.addChannelHandler("CNG", this.channelHandler);
    this.channelHandler.onMessageReceived = (channel, message) => {
      this.handleMessageReceived(channel, message);
    };
    return this;
  }

  async init() {
    await this.connect();
    return await this.updateCurrentUserInfo();
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.sb.connect(`${this.cngUser.id}`, (sbUser, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(sbUser);
      });
    });
  }

  isCurrentUser(user) {
    return _get(this.sb, "currentUser.userId") == user.userId;
  }

  createMyChannelListQuery() {
    this.channelListQuery = this.sb.GroupChannel.createMyGroupChannelListQuery();
    this.channelListQuery.includeEmpty = true;
    this.channelListQuery.limit = 100;
  }

  getChannelsList() {
    return new Promise((resolve, reject) => {
      this.createMyChannelListQuery();
      if (this.channelListQuery.hasNext) {
        this.channelListQuery.next((channelList, error) => {
          if (error) {
            reject(error);
            return;
          }
          this.channelList = channelList;
          resolve(channelList);
        });
      }
    });
  }

  getTotalUnreadChannelCount() {
    return new Promise((resolve, reject) => {
      this.sb.getTotalUnreadChannelCount((count, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(count);
      });
    });
  }

  getTotalUnreadMessageCount() {
    return new Promise((resolve, reject) => {
      this.sb.getTotalUnreadMessageCount((count, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(count);
      });
    });
  }

  handleMessageReceived = (channel, message) => {
    Object.values(this.listeners).forEach(listener => {
      listener && listener(channel, message);
    });
  };

  addOnMessageReceivedHandler(identifier, handler) {
    this.listeners[identifier] = handler;
  }

  removeOnMessageReceivedHandler(uuid) {
    delete this.listeners[uuid];
  }

  createChannelFor(type, value, idName, additionalUser) {
    return new Promise((resolve, reject) => {
      const isDistinct = true;
      const id = _get(value, idName);
      const name = `${type}-${id}`;
      const userIds = [`${id}`, "internal"];
      if (additionalUser) userIds.push(`${additionalUser}`);
      const data = JSON.stringify({
        type,
        [`${type}-${idName}`]: id,
        userId: this.cngUser.id,
        userName: this.cngUser.name,
      });
      this.sb.GroupChannel.createChannelWithUserIds(
        userIds,
        isDistinct,
        name,
        "",
        data,
        type,
        (channel, error) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(channel);
        }
      );
    });
  }

  fetchPreviousMessages(channel, limit = 100) {
    return new Promise((resolve, reject) => {
      const prevMessageListQuery = channel.createPreviousMessageListQuery();
      prevMessageListQuery.limit = limit;
      prevMessageListQuery.reverse = false;

      prevMessageListQuery.load((messages, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(messages);
      });
    });
  }

  sendMessage(message, data, channel) {
    return new Promise((resolve, reject) => {
      const params = new this.sb.UserMessageParams();
      params.message = message;
      params.data = JSON.stringify(data);
      channel.sendUserMessage(params, (message, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(message);
      });
    });
  }

  sendFile(file, data, channel) {
    return new Promise((resolve, reject) => {
      const params = new this.sb.FileMessageParams();
      params.fileUrl = data.fileUrl;
      params.fileName = file.name;
      params.fileSize = file.size;
      params.mimeType = file.type;
      params.data = JSON.stringify(data);
      channel.sendFileMessage(params, (message, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(message);
      });
    });
  }

  updateCurrentUserInfo() {
    return new Promise((resolve, reject) => {
      this.sb.updateCurrentUserInfo(
        this.cngUser.name,
        "",
        (response, error) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(response);
        }
      );
    });
  }

  createNewUser(id, name) {
    return new Promise((resolve, reject) => {
      this.sb.connect(`${id}`, (sbUser, error) => {
        if (error) {
          reject(error);
          return;
        }
        this.sb.updateCurrentUserInfo(name, "", (response, error) => {
          if (error) {
            reject(error);
            return;
          }
          resolve();
        });
      });
    });
  }

  inviteWithUserIdsToChannel(channel, userIds) {
    return new Promise((resolve, reject) => {
      channel.inviteWithUserIds(userIds, (response, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response);
      });
    });
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      this.sb.disconnect((response, error) => {
        if (error) {
          reject(error);
          return;
        }
        this.channelList = [];
        this.currentChannel = null;
        this.listeners = {};
        Messenger.instance = null;
        resolve(response);
      });
    });
  }
}

export default Messenger;
