import React, {Component} from "react";
import PropTypes from "prop-types";
import ToolTip from "react-portal-tooltip";
import lodash from "lodash";

class CNGToolTip extends Component {
  state = {};

  showTooltip = event => {
    event.stopPropagation();
    const id = lodash.get(event, "target.parentNode.id");

    this.setState({
      isTooltipActive: true,
      tooltipId: id,
    });
  };

  hideTooltip = () => {
    this.setState({isTooltipActive: false});
  };

  render() {
    const {
      children,
      parentRef,
      style: customStyle,
      wrapperStyle,
      element,
      position,
    } = this.props;
    const style = {
      ...customStyle,
    };


    return (
      <span
        id={parentRef}
        style={wrapperStyle}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {children}
        <ToolTip
          active={this.state.isTooltipActive}
          parent={`#${parentRef}`}
          key="tooltip"
          tooltipTimeout={0}
          style={{
            style: {
              zIndex: 2010,
            },
            arrowStyle: {},
          }}
          position={position}
        >
          {(element && element.text) ? element.text :  <img
            src={element && element.imageUrl}
            onError={e => {
              e.target.src = "/assets/imgs/image_not_found.png";
            }}
            style={{
              ...style,
              margin: 32,
            }}
          />}
        </ToolTip>
      </span>
    );
  }
}

CNGToolTip.propTypes = {
  parentRef: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  imageUrl: PropTypes.string,
  element: PropTypes.object,
  position: PropTypes.string,
};

CNGToolTip.defaultProps = {
  position: "right",
};

export default CNGToolTip;
