import React, {Component} from "react";
import PropTypes from "prop-types";
import AutoComplete from "material-ui/AutoComplete";

import {formStyles} from "../../styles/components/formStyles";

export default class ValidableAutoComplete extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.func.isRequired,
    errorText: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    onNewRequest: PropTypes.func,
    onUpdateInput: PropTypes.func,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    textFieldStyle: PropTypes.object,
  };

  handleChangeWithMaxLength = (value, callback, maxLength) => {
    if (value.length <= maxLength) {
      callback(value);
    }
  };

  _getMatchingElementParent(element, parentClass, lastMatched) {
    if (!element) {
      return lastMatched;
    }
    const parent = element.parentElement;
    if (parent && parent.getAttribute("class") === parentClass) {
      return parent;
    }
    const nextLastMatched = parent ? parent : element;
    return this._getMatchingElementParent(parent, parentClass, nextLastMatched);
  }

  _onAutocompleteFocus = event => {
    const pageContainer = document.getElementsByClassName("PageContainer")[0];
    const targetElement = this._getMatchingElementParent(
      event.target,
      "dsod-recipient-form"
    );
    pageContainer.scrollTop = targetElement.offsetTop - 180;
  };

  render() {
    const {
      TextFieldStyles,
      TextInputStyles,
      TextFieldLabelStyles,
      underlineStyle: TextFieldUnderlineStyle,
    } = formStyles;

    const {
      value,
      label: floatingLabelText,
      onNewRequest,
      onUpdateInput,
      maxLength,
      validate,
      textFieldStyle = TextFieldStyles,
      inputStyle = TextInputStyles,
      floatingLabelStyle = TextFieldLabelStyles,
      underlineStyle = TextFieldUnderlineStyle,
      ...textFieldProps
    } = this.props;
    return (
      <AutoComplete
        ref={this.setAutocompleteRef}
        type="text"
        {...textFieldProps}
        {...{
          textFieldStyle,
          inputStyle,
          floatingLabelStyle,
          underlineStyle,
          floatingLabelText,
        }}
        menuProps={{
          menuItemStyle: formStyles.wrappedMenuItemStyle,
          desktop: true,
          initiallyKeyboardFocused: true,
        }}
        value={value || ""}
        onUpdateInput={value => {
          if (maxLength) {
            this.handleChangeWithMaxLength(value, onUpdateInput, maxLength);
          } else {
            onUpdateInput(value);
          }
        }}
        popoverProps={{
          autoCloseWhenOffScreen: true,
        }}
        onNewRequest={onNewRequest}
        onKeyUp={() => validate()}
        onFocus={this._onAutocompleteFocus}
        openOnFocus={true}
        targetOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        filter={AutoComplete.fuzzyFilter}
      />
    );
  }
}
