import React, {Component} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import {getFormattedDate} from "../../../utils/dates";
import FormattedPhone from "../../shared/FormattedPhone";
import FormattedAddress from "../../shared/FormattedAddress";
import ContentLoader from "../../shared/ContentLoader";

class ShipmentTable extends Component {
  static propTypes = {
    shipments: PropTypes.array.isRequired,
    isInternal: PropTypes.bool.isRequired,
    selectedCompany: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  getOrderItem = ({items, itemId}) => {
    const shipmentItem = items.find(({id}) => Number(id) === Number(itemId));
    return lodash.get(shipmentItem, "orderItem");
  };

  _renderRow(shipmentItem, index) {
    const {
      order,
      trackingNumber,
      actualShipDate,
      shippedQuantity,
    } = shipmentItem;

    const orderItem = this.getOrderItem(shipmentItem);

    return (
      <CNGTableRow key={shipmentItem.id || index}>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
          highlight={true}
        >
          <Link
            to={`/companies/${lodash.get(
              order,
              "companyId"
            )}/orders/${lodash.get(order, "id")}`}
            className="no-link-styles"
            title={lodash.get(order, "jobName")}
          >
            {lodash.get(order, "jobName")}
            {
              <div className="table-subtext">
                {lodash.get(order, "cngReferenceNumber")}
              </div>
            }
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 140,
          }}
        >
          {lodash.get(orderItem, "orderItemProduct.product.productName")}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 80,
          }}
        >
          {shippedQuantity}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 130,
          }}
        >
          {actualShipDate && getFormattedDate(new Date(actualShipDate))}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 165,
            fontWeight: "normal",
          }}
        >
          {trackingNumber}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "JOB INFO",
        customStyles: {
          width: 100,
        },
      },
      {
        columnContent: "ITEMS SHIPPED",
        customStyles: {
          width: 140,
        },
      },
      {
        columnContent: "QTY. SHIPPED",
        customStyles: {
          width: 80,
        },
      },
      {
        columnContent: "DATE SHIPPED",
        customStyles: {
          width: 130,
        },
      },
      {
        columnContent: "TRACKING #",
        customStyles: {
          width: 165,
        },
      },
    ];
    const {shipments, isLoading} = this.props;

    const shipmentByRecipient = lodash.groupBy(
      shipments,
      "recipientInformation.id"
    );

    const sortedShipmentByRecipient = lodash.sortBy(
      shipmentByRecipient,
      shipments => {
        return lodash
          .get(shipments, "[0].recipientInformation.name", "")
          .toLowerCase();
      }
    );

    return (
      <ContentLoader
        zIndex={1}
        isLoading={isLoading}
        customContainerStyles={{
          top: "34px",
          minHeight: "110px",
        }}
      >
        {Object.values(sortedShipmentByRecipient).map(shipments => {
          const recipient = lodash.get(
            shipments,
            "[0].recipientInformation",
            {}
          );
          const email = lodash.get(recipient, "emailAddress");
          return (
            <div key={lodash.get(recipient, "id")}>
              <div
                style={{
                  margin: "0 0",
                  fontWeight: "normal",
                }}
              >
                <h1
                  style={{
                    fontSize: 16,
                    color: "#4A5158",
                    fontWeight: "normal",
                  }}
                >
                  <span className="padding-right-10">
                    {lodash.get(recipient, "name")}
                  </span>
                  <small className="padding-right-10">
                    {email && `(${email})`}
                  </small>
                  <small className="padding-right-10">
                    <FormattedPhone
                      phoneNumber={lodash.get(recipient, "phoneNumber")}
                      countryCode={lodash.get(recipient, "countryCode")}
                    />
                  </small>
                  <small className="padding-right-10">
                    <FormattedAddress user={recipient} />
                  </small>
                </h1>
              </div>
              <CNGTable tableHeader={header} displayRowCheckbox={false}>
                {lodash.defaultTo(shipments, []).map((shipment, index) => {
                  return this._renderRow(shipment, index);
                })}
              </CNGTable>
            </div>
          );
        })}
      </ContentLoader>
    );
  }
}

export default ShipmentTable;
