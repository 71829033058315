import lodash from "lodash";
import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function productReducer(
  state = initialState.products,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_PRODUCTS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_PRODUCTS_SUCCESS:
    return {
      ...state,
      products: payload,
      productsCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_PRODUCTS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_PRODUCT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_PRODUCT_SUCCESS:
    return {
      ...state,
      product: payload,
      isLoading: false,
    };
  case actions.FETCH_PRODUCT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_PRODUCT_IMAGES_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_PRODUCT_IMAGES_SUCCESS:
    return {
      ...state,
      productImages: payload,
      isLoading: false,
    };
  case actions.FETCH_PRODUCT_IMAGES_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.FETCH_ORDER_ITEM_PRODUCT_REQUEST:
    return {
      ...state,
      isLoading: true,
    };

  case actions.UPDATE_ORDER_ITEM_PRODUCT_ART_ASSIGNMENTS: {
    const assignments = lodash.get(
      state.orderItemProduct,
      "orderItemProductAdornmentLocationArtAssignments"
    );

    return {
      ...state,
      orderItemProduct: {
        ...state.orderItemProduct,
        orderItemProductAdornmentLocationArtAssignments: assignments.map(
          assignment => {
            if (assignment.id === payload.id) {
              return {
                ...assignment,
                thumbnail: payload.thumbnail,
                imageUrl: payload.imageUrl,
                filename: payload.filename,
              };
            }
            return assignment;
          }
        ),
      },
    };
  }
  case actions.FETCH_ORDER_ITEM_PRODUCT_SUCCESS:
    return {
      ...state,
      orderItemProduct: payload,
      isLoading: false,
    };

  case actions.FETCH_ORDER_ITEM_PRODUCTS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ORDER_ITEM_PRODUCTS_SUCCESS:
    return {
      ...state,
      orderItemProducts: payload,
      isLoading: false,
    };
  case actions.FETCH_ORDER_ITEM_PRODUCT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  case actions.SEARCH_PRODUCT_SHIPMENTS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.SEARCH_PRODUCT_SHIPMENTS_SUCCESS:
    return {
      ...state,
      productShipments: payload,
      productShipmentCount: meta.count,
      isLoading: false,
    };
  case actions.SEARCH_PRODUCT_SHIPMENTS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
