import React, {Component} from "react";
import PropTypes from "prop-types";

import {formStyles} from "../../../styles/components/formStyles";
import TextField from "material-ui/TextField";

export default class NoteButton extends Component {
  static propTypes = {
    createNote: PropTypes.func,
    handleChange: PropTypes.func,
    closeNewNote: PropTypes.func,
    addNewNote: PropTypes.func,
    newNote: PropTypes.string,
  };

  _evaluateSaveButtonDisabledState() {
    const {newNote} = this.props;
    return newNote && newNote.length > 0 ? false : true;
  }

  _onSaveClickHandler() {
    const {addNewNote} = this.props;
    const isDisabeld = this._evaluateSaveButtonDisabledState();
    if (isDisabeld) {
      return;
    }
    addNewNote();
  }

  render() {
    return (
      <div className="note note-new">
        <TextField
          fullWidth={true}
          hintText="Add note"
          type="text"
          name="newNote"
          value={this.props.newNote}
          onChange={(e, value) => this.props.handleChange(e, value)}
          multiLine={true}
          rows={1}
          rowsMax={6}
          style={TextFieldStyles}
          inputStyle={TextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={underlineStyle}
          autoFocus={true}
        />
        <div className="note-new_bottom flex flex-row flex-center justify-content-end">
          <div
            className="note-new_cancel pointer"
            onClick={() => this.props.closeNewNote()}
          >
            CANCEL
          </div>
          <div
            className={`note-btn pointer ${
              this._evaluateSaveButtonDisabledState() ? "disabled" : ""
            }`}
            onClick={() => this._onSaveClickHandler()}
          >
            SAVE
            <img
              src="/assets/imgs/icons/check-blue.svg"
              className="btn-icon-size note-btn_icon"
            />
          </div>
        </div>
      </div>
    );
  }
}

const TextFieldStyles = {
  color: "#747D86",
  marginRight: 32,
};
const TextInputStyles = {
  fontSize: 16,
  marginTop: 0,
};
const underlineStyle = {
  borderColor: "#747D86",
};
