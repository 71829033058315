import AuthService from "../services/AuthService";
import deserialize from "../utils/deserializer";
import * as types from "../constants/actionTypes";
import {cloneDeep} from "lodash";
const service = new AuthService();

export function makeCommonAction(type, payload = null, error = null) {
  let meta = {};
  if (payload && payload.meta) {
    meta = payload.meta;
    payload = cloneDeep(payload.data);
  }
  return {type, payload, error, meta};
}

export function makeActionCreator(opts) {
  return async dispatch => {
    try {
      dispatch(opts.requestFunc());
      if (opts.requestValidationFunc && !opts.requestValidationFunc()) return;
      const [res] = await Promise.all([
        service.fetchWithAuth(opts.endpoint, opts.fetchOptions),
        new Promise(resolve => setTimeout(resolve, opts.delay || 0)),
      ]);
      return _dispatchResult(res, opts, dispatch);
    } catch (error) {
      _dispatchMessage(dispatch, error.message || opts.error_message);
      dispatch(
        opts.errorFunc({
          error: error,
          error_message: opts.error_message || "Error with request",
        })
      );
      return false;
    }
  };
}

async function _dispatchResult({status, body}, opts, dispatch) {
  if (status >= 200 && status < 300) {
    let deserializedData = {};
    if (body && status !== 204) {
      deserializedData = await _parseResult(body, opts);
      dispatch(opts.successFunc(deserializedData, dispatch));
    } else {
      dispatch(opts.successFunc(dispatch));
    }

    if (opts.successMessage) {
      dispatch({
        type: types.OPEN_NOTIFICATION,
        notification: {
          message: opts.successMessage,
          show: true,
        },
      });
    }

    return deserializedData;
  }

  setTimeout(() => {
    _dispatchMessage(dispatch, opts.error_message);
    dispatch(
      opts.errorFunc({
        error: status,
        error_message: opts.error_message || "Error with request",
      })
    );
  });
}

function _dispatchMessage(dispatch, message) {
  dispatch({
    type: types.OPEN_NOTIFICATION,
    notification: {
      message: message,
      show: true,
    },
  });
}

async function _parseResult(body, opts) {
  // mutable function
  body = Object.assign(
    {
      meta: {},
    },
    body
  );
  if (opts.afterReqFunc) opts.afterReqFunc(body);
  return await deserialize(body);
}

export const dispatchMessage = _dispatchMessage;
