import React, {Component} from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import Dialog from "material-ui/Dialog";
import FontIcon from "material-ui/FontIcon";
import {Table, TableBody, TableRow, TableRowColumn} from "material-ui/Table";
import TextField from "material-ui/TextField";

import ChillButton from "../../../../shared/ChillButton";
import Typography from "../../../shared/Typography";

class AssignCompanyModal extends Component {
  state = {
    selectedCompanyRow: 0,
    search: "",
  };

  componentWillReceiveProps(nextProps) {
    const {initialCompanySearch} = this.props;
    if (initialCompanySearch !== nextProps.initialCompanySearch) {
      this.setState(
        {search: nextProps.initialCompanySearch || ""},
        this.handleSearch
      );
    }
  }

  handleSearchChange = (event, value) => {
    this.setState({search: value});
    this.searchCallback(value);
  };

  handleSearch = () => {
    const {search} = this.state;
    this.searchCallback(search);
  };

  searchCallback = _debounce(
    search => {
      const options = {sort: "name", sortDirection: "ASC"};
      if (!search) {
        return this.props.fetchCompanies(options);
      }
      this.props.searchCompanies(search, options);
    },
    1000,
    {
      leading: false,
      trailing: true,
    }
  );

  handleCompanySelection = selection => {
    if (selection.length > 0) {
      this.setState({selectedCompanyRow: selection[0]});
    } else {
      this.setState({selectedCompanyRow: null});
    }
  };

  handleAssign = () => {
    const {companies, onAssignToNewCompany, onAssignToCompany} = this.props;
    const {selectedCompanyRow} = this.state;
    if (selectedCompanyRow === null) return;
    if (selectedCompanyRow === 0) {
      onAssignToNewCompany();
      return;
    }
    const company = companies[selectedCompanyRow - 1];
    onAssignToCompany(company.id);
  };

  handleCompanySelection = selection => {
    if (selection.length > 0) {
      this.setState({selectedCompanyRow: selection[0]});
    } else {
      this.setState({selectedCompanyRow: null});
    }
  };

  isAssignButtonDisabled = () => {
    const {selectedCompanyRow} = this.state;
    return selectedCompanyRow == null;
  };

  renderSearchInput = () => {
    const {search} = this.state;
    return (
      <div className="flex padding-left-24 padding-right-16">
        <TextField
          fullWidth
          hintText="Search"
          hintStyle={{bottom: 8}}
          underlineShow={false}
          value={search}
          onChange={this.handleSearchChange}
          style={{height: 40}}
        />
        <FontIcon
          className="material-icons pointer"
          style={{
            fontSize: 24,
            alignSelf: "center",
          }}
          onClick={this.handleSearch}
        >
          search
        </FontIcon>
      </div>
    );
  };

  renderCompanyList = () => {
    const {companies} = this.props;
    const {selectedCompanyRow} = this.state;
    return (
      <Table onRowSelection={this.handleCompanySelection}>
        <TableBody stripedRows deselectOnClickaway={false}>
          <TableRow displayBorder={false} selected={selectedCompanyRow === 0}>
            <TableRowColumn>New Company</TableRowColumn>
          </TableRow>
          {companies.map((company, idx) => (
            <TableRow
              key={company.id}
              displayBorder={false}
              selected={selectedCompanyRow === idx + 1}
            >
              <TableRowColumn>{company.name}</TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  render() {
    const {open, onClose} = this.props;
    return (
      <Dialog
        modal={false}
        open={open}
        contentStyle={{maxWidth: 640}}
        bodyStyle={{paddingLeft: 0, paddingRight: 0}}
      >
        <span style={{position: "absolute", right: 10, top: 10}}>
          <FontIcon
            className="material-icons pointer"
            onClick={onClose}
            style={{
              fontSize: 16,
              borderRadius: 9,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            close
          </FontIcon>
        </span>
        <Typography variant="h3" style={{textAlign: "center"}}>
          Assign Quote to Company
        </Typography>

        <div
          className="flex flex-column margin-top-24"
          style={{
            maxHeight: 340,
            borderWidth: 1,
            borderRightWidth: 0,
            borderLeftWidth: 0,
            borderStyle: "solid",
          }}
        >
          {this.renderSearchInput()}
          {this.renderCompanyList()}
        </div>

        <ChillButton
          useFontIcon
          isDisabled={this.isAssignButtonDisabled()}
          onClick={this.handleAssign}
          name="Assign"
          icon="check"
          fontIconStyles={{
            color: "#FFFFFF",
            fontSize: 18,
            marginRight: 4,
          }}
          className="chill-button  btn-1 flex chill-button--large uppercase"
          customStyles={{width: "85%"}}
          height={40}
          marginTop={40}
          marginRight="auto"
          marginBottom={24}
          marginLeft="auto"
        />
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer uppercase margin-bottom-24"
          color="primary.light"
          onClick={onClose}
        >
          Cancel
        </Typography>
      </Dialog>
    );
  }
}

AssignCompanyModal.propTypes = {
  open: PropTypes.bool,
  initialCompanySearch: PropTypes.string,
  companies: PropTypes.array,
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
  searchCompanies: PropTypes.func,
  fetchCompanies: PropTypes.func,
  onAssignToNewCompany: PropTypes.func,
  onAssignToCompany: PropTypes.func,
  onClose: PropTypes.func,
};

export default AssignCompanyModal;
