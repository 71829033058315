import React, {Component} from "react";
import PropTypes from "prop-types";

import PlaceholderImg from "../shared/PlaceholderImg";

export default class CNGImage extends Component {
  static propTypes = {
    src: PropTypes.string,
    placeHolderStyle: PropTypes.object,
    placeHolderText: PropTypes.string,
    hoverImage: PropTypes.string,
  };

  componentDidUpdate(PrevProps) {
    if (PrevProps.src !== this.props.src) {
      this.setState({errorImage: false});
    }
  }

  static defaultProps = {
    placeHolderText: "Image not found",
  };

  state = {
    errorImage: false,
    isHover: false,
  };

  handleError = () => {
    this.setState({errorImage: true});
  };

  handleMouseEnter = () => {
    this.setState({isHover: true});
  };

  handleMouseLeave = () => {
    this.setState({isHover: false});
  };

  render() {
    const {
      hoverImage,
      placeHolderStyle,
      src,
      placeHolderText,
      ...additionalProps
    } = this.props;
    const {errorImage, isHover} = this.state;
    if (errorImage) {
      return (
        <PlaceholderImg
          text={placeHolderText}
          title={additionalProps.title}
          style={placeHolderStyle}
        />
      );
    }

    const srcImage = isHover && hoverImage ? hoverImage : src;
    return (
      <img
        {...additionalProps}
        src={srcImage}
        onError={this.handleError}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      />
    );
  }
}
