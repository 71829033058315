import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {openNotification} from "../../actions/notificationActions";
import DSODCartPage from "../pages/DSOD/DSODCartPage";
import CNGCard from "../shared/CNGCard";
import PageLayout from "../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../shared/PageComponents/PageIndexHeader";

import {
  fetchShipments,
  updateShipmentItem,
  fetchCompanyShippingCarrierAssignments,
  fetchShippingCarriers,
  fetchShippingMethods,
  updateShipment,
} from "../../actions/shipmentActions";
import {fetchStates, fetchCountries} from "../../actions/geoActions";
import {
  createDsodItem,
  fetchDsodItems,
  updateDsodItem,
  deleteDsodItem,
} from "../../actions/dsodItemsActions";
import {
  fetchCompanies,
  fetchCompanyContacts,
  fetchCompany,
} from "../../actions/companyActions";

import {
  fetchOrder,
  fetchOrderItems,
  updateOrder,
  fetchOrderStatus,
  fetchOpenOrders,
  createOrder,
  archiveOrder,
  fetchCurrentOpenOrders,
} from "../../actions/orderActions";

import {fetchInventoryItems} from "../../actions/inventoryActions";
import {fetchPaymentTerms} from "../../actions/paymentTermsActions";

class DSODCart extends Component {
  state = {
    companyId: null,
    recipients: [{}],
    orderData: {},
    shipmentData: {},
    isValid: false,
    showEmptyState: false,
  };

  componentDidUpdate(prevProps) {
    const {match: {params: {company_id: companyId}}} = this.props;

    if (prevProps.dsodItems !== this.props.dsodItems) {
      const {fetchInventoryItems} = this.props;

      if (companyId) {
        fetchInventoryItems(companyId, {
          include:
            "orderItem.[orderItemProduct.art,fabricColor.[fabric,color],product]",
          filter: "used_quantity,lt,quantity",
          perPage: 6,
          orderBy: "id",
          orderDirection: "asc",
        });
      }
    }

    if (companyId !== prevProps.match.params.company_id) {
      this._fetchData();
    }
  }

  componentDidMount() {
    this._fetchData();
  }

  async _fetchShipmentAccounts(companyId) {
    const {fetchCompanyShippingCarrierAssignments} = this.props;

    return await fetchCompanyShippingCarrierAssignments({
      filter: `company_id,eq,${companyId}`,
    });
  }

  archiveOrder = async () => {
    const {archiveOrder, history} = this.props;
    const {companyId, order} = this.state;
    await archiveOrder(order.id);
    history.push(`/companies/${companyId}`);
  };

  _fetchData = async () => {
    const {
      fetchCompanies,
      fetchOrderStatus,
      fetchStates,
      fetchCountries,
      fetchShippingMethods,
      fetchOpenOrders,
      fetchShipments,
      fetchCompanyContacts,
      fetchCompany,
      fetchInventoryItems,
      fetchPaymentTerms,
      isInternal,
      selectedCompany,
      match: {params: {company_id: companyId}},
      history,
    } = this.props;

    if (companyId) {
      fetchCompany(companyId);
      this.setState({companyId: Number(companyId), isLoading: true});
      let shipmentAccount = {
        shippingCarrier: {},
      };
      let shipment = {};
      const openOrders = await fetchOpenOrders(companyId);
      await this._fetchShipmentAccounts(companyId);
      const order = openOrders && openOrders.data.find(order => order.isDsod);

      if (!order) {
        return this.setState({showEmptyState: true, isLoading: false});
      }

      if (!isInternal && Number(companyId) !== Number(selectedCompany)) {
        return history.push("/");
      }

      this._fetchDsodItems(order);
      const shipments = await fetchShipments(order.id);

      if (shipments) {
        shipment = shipments.data[0];
      }

      if (shipment.shipmentAccount) {
        shipmentAccount = shipment.shipmentAccount;
      }

      const {
        inHandDate,
        estimatedShipOn,
        clientNotes,
        internalNotes,
        createdUserId,
        paymentTermId,
      } = order;
      this.setState({
        shipmentData: {
          shipping_method_id: shipment.shippingMethodId,
          shipment_account_id: shipmentAccount.id,
          shipping_carrier_id:
            shipmentAccount.shippingCarrier &&
            shipmentAccount.shippingCarrier.id,
          shipping_carrier_name:
            shipmentAccount.shippingCarrier &&
            shipmentAccount.shippingCarrier.name,
          shipment_account_number: shipmentAccount.accountNumber,
          shipment_cost: shipment.shipmentCost,
        },
        order,
        orderData: {
          inHandDate,
          estimatedShipOn,
          clientNotes,
          internalNotes,
          createdUserId,
          paymentTermId,
        },
        isLoading: false,
      });

      fetchCompanyContacts(companyId, {include: "[country]"});
      fetchInventoryItems(companyId, {
        include:
          "orderItem.[orderItemProduct.art,fabricColor.[fabric,color],product]",
        filter: "used_quantity,lt,quantity",
        perPage: 6,
        orderBy: "id",
        orderDirection: "desc",
      });
    }

    fetchCompanies({
      include: "activeInventoryItems",
      sort: "name",
      sortDirection: "ASC",
      page: {
        size: 0,
      },
    });
    fetchStates();
    fetchCountries();
    fetchOrderStatus();
    fetchShippingMethods();
    fetchPaymentTerms();
  };

  handleCreateDSOD = async companyId => {
    const {history, fetchCurrentOpenOrders} = this.props;
    this.setState({isCreatingOrder: true});

    await this._findOrCreateDsodOrder(companyId);
    fetchCurrentOpenOrders();
    this.setState({isCreatingOrder: false});
    history.push(`/companies/${companyId}/dsod`);
  };

  handleSaveDraft = async () => {
    if (!this.state.order) return;
    const {orderData, shipmentData, order} = this.state;
    const {updateOrder, openNotification, fetchShipments} = this.props;
    await updateOrder(order.id, {
      ...orderData,
      shipment: shipmentData,
    });

    await fetchShipments(order.id);
    openNotification("Order changes successfully saved");
  };

  async _findOrCreateDsodOrder(companyId) {
    const {fetchOpenOrders, createOrder, createDsodItem} = this.props;
    const openOrders = await fetchOpenOrders(companyId);
    let dsodOrder = openOrders.data.find(order => order.isDsod);
    if (!dsodOrder) {
      dsodOrder = await createOrder(companyId, {
        order_source: "dsod",
        is_open: true,
        is_dsod: true,
        submitted_on: new Date(),
        shipment: {
          is_open: true,
        },
      });
      await createDsodItem(companyId, {
        orderId: dsodOrder.id,
        companyId: companyId,
      });
    }
    return dsodOrder;
  }

  handleSubmitOrder = () => {
    const {updateOrder, history} = this.props;
    const {order} = this.state;

    updateOrder(order.id, {isOpen: null, shipment: {}}, {}, () => {
      history.push({
        pathname: `/companies/${order.companyId}/dsod/${order.id}`,
        state: {
          dsodCreated: true,
        },
      });
    });
  };

  handleUpdateDsodRecipient = async (recipient, dsodItems) => {
    const {
      openNotification,
      fetchCompanyContacts,
      updateDsodItem,
      match: {params: {company_id: companyId}},
    } = this.props;
    const {order} = this.state;
    fetchCompanyContacts(companyId, {include: "[country]"});
    const dsodItem = dsodItems[0];

    if (recipient.id !== dsodItem.recipientId) {
      dsodItems.map(async dsodItem => {
        await updateDsodItem(order.companyId, dsodItem.id, {
          recipient_id: recipient.id,
        });
      });
    }

    openNotification("Order has been updated");
  };

  handleOrderChange = (event, value, formName, callback) => {
    this.handleChange(event, value, formName, "orderData", callback);
  };

  handleShipmentChange = (event, value, formName, callback) => {
    if (typeof value === "object") {
      return this.setState(
        {
          shipmentData: {
            ...this.state.shipmentData,
            ...value,
          },
        },
        () => {
          if (callback) callback();
        }
      );
    }
    this.handleChange(event, value, formName, "shipmentData", callback);
  };

  handleChange = (event, value, formName, stateName, callback) => {
    const name = formName || (event && event.target.name);

    this.setState(
      {
        [stateName]: {
          ...this.state[stateName],
          [name]: value,
        },
      },
      async () => {
        if (callback) await callback();
      }
    );
  };

  _fetchDsodItems = order => {
    const {
      fetchDsodItems,
      match: {params: {company_id: companyId}},
    } = this.props;

    fetchDsodItems({
      companyId,
      filter: `order_id,eq,${order.id}`,
      include:
        "[art.artSource.brand,inventoryHistories.inventoryItem.order,sourceOrderItem.orderItemProduct" +
        ".[orderItemProductAdornmentLocationArtAssignments.[art,productAdornmentLocation.adornmentLoc" +
        "ation],arts.artSource.brand],fabricAvailableColor.[fabric,color],product]",
      orderBy: "created_at",
      orderDirection: "asc",
    });
  };

  handleFetchInventoryItems = (companyId, options) => {
    const {fetchInventoryItems} = this.props;
    fetchInventoryItems(companyId, {
      ...options,
      include:
        "orderItem.[order,artThreadColorAssignment,orderItemProduct.[orderItemProductAdornmentLo" +
        "cationArtAssignments.[art,productAdornmentLocation.adornmentLocation],art.artSource.bra" +
        "nd],fabricColor.[fabric,color],product]",
    });
  };

  handleFetchDsodItems = () => {
    const {order} = this.state;
    this._fetchDsodItems(order);
  };

  handleSetIsValid = isValid => {
    this.setState({isValid});
  };

  _renderEmptyState() {
    window.closeSplash();
    const {
      userCompanies,
      match: {params: {company_id: companyId}},
    } = this.props;
    const selectedCompany = Array.isArray(userCompanies)
      ? userCompanies.find(company => Number(company.id) === Number(companyId))
      : null;
    return (
      <PageLayout subHeaderText={selectedCompany ? selectedCompany.name : ""}>
        <PageIndexHeader
          containerClass="dsodindex_top"
          title="DSOD Cart"
          titleIcon="DSOD"
          noButton
          noSearch
        />
        <CNGCard showDivider={false}>
          <h2
            className="userindex_noUsers"
            style={{
              marginBottom: 32,
            }}
          >
            You currently do not have an active DSOD Cart
          </h2>
        </CNGCard>
      </PageLayout>
    );
  }

  render() {
    if (this.state.showEmptyState) {
      return this._renderEmptyState();
    }

    const {
      companies,
      companyContacts,
      shipmentAccounts,
      shippingMethods,
      dsodItems,
      fetchCurrentOpenOrders,
      inventoryItems,
      company,
      inventoryItemsCount,
      updateDsodItem,
      createDsodItem,
      deleteDsodItem,
      history,
      openNotification,
      currentOpenOrders,
      fetchDsodItems,
      isInternal,
      isOrderLoading,
      userCompanies,
      currentUserId,
      match: {params: {company_id: companyId}},
      paymentTerms,
    } = this.props;
    const {order, orderData, recipients, shipmentData} = this.state;
    const selectedCompany = Array.isArray(userCompanies)
      ? userCompanies.find(company => Number(company.id) === Number(companyId))
      : null;

    return (
      <DSODCartPage
        isDisabled={()=>false}
        isCreatingOrder={this.state.isCreatingOrder}
        isOrderLoading={isOrderLoading}
        selectedCompany={selectedCompany}
        currentUserId={currentUserId}
        isInternal={isInternal}
        userCompanies={userCompanies}
        onSetIsValid={this.handleSetIsValid}
        openNotification={openNotification}
        onUpdateShipmentTrackingNumber={this.handleUpdateShipmentTrackingNumber}
        onShipmentChange={this.handleShipmentChange}
        onOrderChange={this.handleOrderChange}
        onSaveDraft={this.handleSaveDraft}
        fetchShippingMethodsByCarrier={this.fetchShippingMethodsByCarrier}
        fetchInventoryItems={this.handleFetchInventoryItems}
        fetchDsodItems={fetchDsodItems}
        fetchCurrentOpenOrders={fetchCurrentOpenOrders}
        onFetchDsodItems={this.handleFetchDsodItems}
        currentOpenOrders={currentOpenOrders}
        onUpdateShipmentTrackingItemNumber={
          this.handleUpdateShipmentTrackingItemNumber
        }
        archiveOrder={this.archiveOrder}
        updateDsodItem={updateDsodItem}
        createDsodItem={createDsodItem}
        onCreateDSOD={this.handleCreateDSOD}
        inventoryItems={inventoryItems}
        inventoryItemsCount={inventoryItemsCount}
        companyId={companyId}
        company={company}
        recipients={recipients}
        orderData={orderData}
        shipmentData={shipmentData}
        shippingMethods={shippingMethods}
        order={order}
        shipmentAccounts={shipmentAccounts}
        history={history}
        dsodItems={dsodItems}
        onUpdateDsodRecipient={this.handleUpdateDsodRecipient}
        companies={companies}
        companyContacts={companyContacts}
        deleteDsodItem={deleteDsodItem}
        isValid={this.state.isValid}
        onSubmitOrder={this.handleSubmitOrder}
        isLoading={this.state.isLoading}
        paymentTerms={paymentTerms}
      />
    );
  }
}

DSODCart.propTypes = {
  fetchCurrentOpenOrders: PropTypes.func.isRequired,
  fetchDsodItems: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompanyContacts: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchShipments: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrderItems: PropTypes.func.isRequired,
  fetchOrderStatus: PropTypes.func.isRequired,
  fetchOpenOrders: PropTypes.func.isRequired,
  fetchInventoryItems: PropTypes.func,
  currentUserId: PropTypes.number,
  updateOrder: PropTypes.func,
  deleteDsodItem: PropTypes.func,
  inventoryItems: PropTypes.array,
  inventoryItemsCount: PropTypes.number,
  createOrder: PropTypes.func.isRequired,
  createDsodItem: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func,
  fetchStates: PropTypes.func,
  fetchCompanyShippingCarrierAssignments: PropTypes.func,
  fetchShippingCarriers: PropTypes.func,
  fetchShippingMethods: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  updateShipmentItem: PropTypes.func,
  company: PropTypes.object,
  companies: PropTypes.array.isRequired,
  userCompanies: PropTypes.array,
  companyContacts: PropTypes.array.isRequired,
  order: PropTypes.object,
  shipments: PropTypes.array,
  openOrders: PropTypes.array,
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  isOrderLoading: PropTypes.bool,
  shippingMethods: PropTypes.array,
  shipmentAccounts: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  dsodItems: PropTypes.array,
  updateDsodItem: PropTypes.func,
  openNotification: PropTypes.func,
  currentOpenOrders: PropTypes.array,
  archiveOrder: PropTypes.func,
  selectedCompany: PropTypes.object,
  paymentTerms: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    auth: {isInternal, id: currentUserId},
    companies: {companies, company, companyContacts},
    shipment: {shipments, companyShippingCarrierAssignments, shippingMethods},
    geo: {states, countries},
    orders: {openOrders, currentOpenOrders},
    dsodItems: {dsodItems},
    inventory: {inventoryItemsCount, inventoryItems},
    paymentTerms: {paymentTerms},
  } = state;

  return {
    shipmentAccounts: companyShippingCarrierAssignments,
    openOrders,
    currentOpenOrders,
    company,
    companies,
    companyContacts,
    states: states,
    countries: countries,
    shippingMethods,
    shipments,
    dsodItems,
    currentUserId,
    inventoryItems,
    inventoryItemsCount,
    isInternal,
    userCompanies: companies,
    paymentTerms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    fetchDsodItems: bindActionCreators(fetchDsodItems, dispatch),
    fetchCompanyContacts: bindActionCreators(fetchCompanyContacts, dispatch),
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchStates: bindActionCreators(fetchStates, dispatch),
    fetchShippingCarriers: bindActionCreators(fetchShippingCarriers, dispatch),
    fetchShippingMethods: bindActionCreators(fetchShippingMethods, dispatch),
    fetchInventoryItems: bindActionCreators(fetchInventoryItems, dispatch),
    fetchCompanyShippingCarrierAssignments: bindActionCreators(
      fetchCompanyShippingCarrierAssignments,
      dispatch
    ),
    updateShipment: bindActionCreators(updateShipment, dispatch),
    updateDsodItem: bindActionCreators(updateDsodItem, dispatch),
    fetchOpenOrders: bindActionCreators(fetchOpenOrders, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    createOrder: bindActionCreators(createOrder, dispatch),
    createDsodItem: bindActionCreators(createDsodItem, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    deleteDsodItem: bindActionCreators(deleteDsodItem, dispatch),
    archiveOrder: bindActionCreators(archiveOrder, dispatch),
    fetchCurrentOpenOrders: bindActionCreators(
      fetchCurrentOpenOrders,
      dispatch
    ),
    fetchPaymentTerms: bindActionCreators(fetchPaymentTerms, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DSODCart)
);
