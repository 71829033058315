import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import Checkbox from "material-ui/Checkbox";
import Dialog from "material-ui/Dialog";
import Divider from "material-ui/Divider";
import AssignPasswordDialogBody from "./AssignPasswordDialogBody";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import {USA_COUNTRY_ID} from "../../../../constants/country";
import {isCountryUSAOrCanada} from "../../../../utils/countryRegionsHelper";

import {formStyles} from "../../../../styles/components/formStyles";

import CompanySelect from "./CompanySelect";
import ChillButton from "../../../shared/ChillButton";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import StateSelect from "../../../shared/StateSelect";
import PhoneNumberInput from "../../../shared/PhoneNumberInput";

import {validateForm} from "../../../../utils/formValidations";

const styles = {
  block: {
    maxWidth: 250,
  },
  checkInput: {
    marginRight: 10,
  },
};

export default class UserForm extends Component {
  static propTypes = {
    setContactInformation: PropTypes.func.isRequired,
    recipientId: PropTypes.string,
    contactsWithoutUser: PropTypes.array,
    handleChangeCompany: PropTypes.func.isRequired,
    handleDeleteCompany: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    companiesWallets: PropTypes.array.isRequired,
    addingCompany: PropTypes.bool,
    countries: PropTypes.array.isRequired,
    allCompanies: PropTypes.array.isRequired,
    toggleIsInternal: PropTypes.func,
    toggleCompanyWallet: PropTypes.func,
    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,
    companyId: PropTypes.string,
    user: PropTypes.object,
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    state: PropTypes.string,
    countryId: PropTypes.number,
    city: PropTypes.string,
    password: PropTypes.string,
    zipCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isInternal: PropTypes.bool,
    disableCompanyManagement: PropTypes.bool,

    errors: PropTypes.object,
    submitted: PropTypes.bool,
    allowPasswordAssignment: PropTypes.bool,
    currentCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  validations = {
    firstName: {
      format: "flexibleName",
      length: [2, 30],
    },
    lastName: {
      format: "flexibleName",
      length: [2, 30],
    },
    phoneNumber: {
      format: "any",
      length: [10],
    },
    emailAddress: {
      format: "email",
    },
    address1: {
      format: "any",
      length: [1, 255],
    },
    address2: {
      format: "any",
      length: [1, 255],
      nullable: true,
    },
    state: {
      format: "any",
      nullable: true,
    },
    countryId: {
      format: "integer",
    },
    city: {
      format: "any",
      length: [2, 30],
    },
  };
  state = {
    addingCompany: true,
    isInternal: false,
    errors: {},
    submitted: false,
    showPasswordField: false,
  };

  countryIsUnitedStates = () => {
    const {countries, countryId} = this.props;
    if ((countries && !countries.length) || !countryId) return false;
    const country = countries.find(({id}) => id == countryId);
    return country && country.name === "United States";
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.contactsWithoutUser &&
      this.props.contactsWithoutUser.length > 0 &&
      this.props.contactsWithoutUser !== prevProps.contactsWithoutUser &&
      this.props.recipientId
    ) {
      this.setCurrectContact(this.props.recipientId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {submitted} = this.state;
    this.setState(
      {
        isInternal: nextProps.isInternal,
        submitted: nextProps.submitted,
      },
      () => {
        if (nextProps.submitted && !submitted) {
          this.triggerValidations();
        }
      }
    );
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    if (!valid) return submitError();
    submitSuccess();
  }

  _validate() {
    const {countryId} = this.props;
    if (this.isSelectedUSA()) {
      this.validations["zipCode"] = {
        format: "integer",
        shouldMatch: value => {
          return value && value.length === 5;
        },
        shouldMatchMessage: "invalid zipcode should be 5 characters long",
      };
      this.validations["state"] = {
        format: "any",
      };
    } else {
      this.validations["zipCode"] = {
        format: "any",
      };
      this.validations["state"] = {
        format: "any",
        nullable: true,
      };
    }
    if (isCountryUSAOrCanada(countryId)) {
      this.validations["phoneNumber"] = {
        format: "any",
        length: [10],
      };
    } else {
      this.validations["phoneNumber"] = {
        format: "any",
      };
    }
    const errors = validateForm(this);
    this.setState({errors});
    return errors;
  }

  _validateDebounced = lodash.debounce(this._validate, 300);

  getAvailableCompanies() {
    return this.props.allCompanies.filter(
      company => !this.props.companies.includes(company.id)
    );
  }

  isSelectedUSA() {
    const {countryId} = this.props;
    return Number(countryId) === USA_COUNTRY_ID;
  }

  contactOptions() {
    const {contactsWithoutUser = []} = this.props;
    const options = [
      {id: "new", name: "Make New Recipient"},
      ...contactsWithoutUser,
    ];

    return options.map(({id, name}) => {
      return <CNGMenuItem key={id} value={id} primaryText={name} />;
    });
  }

  _getCountrySelectOptions() {
    const {countries} = this.props;
    return countries.reduce((countryOptions, country) => {
      const countryElement = (
        <CNGMenuItem
          key={country.id}
          value={country.id}
          primaryText={country.name}
        />
      );
      if (["USA", "US"].includes(country.code)) {
        return [
          countryElement,
          <Divider key={"usa_divider"} />,
          ...countryOptions,
        ];
      }
      countryOptions.push(countryElement);
      return countryOptions;
    }, []);
  }

  _renderCompanySelect() {
    return (
      <CompanySelect
        companies={this.getAvailableCompanies()}
        disabled={this.state.isInternal}
        handleChangeCompany={(e, index, value, name) => {
          this.setState({addingCompany: false});
          this.props.handleChangeCompany(e, index, value, name);
        }}
        name={"newCompany"}
        trashCan={false}
      />
    );
  }
  _renderAddCompanyButton() {
    const {disableCompanyManagement} = this.props;
    if (disableCompanyManagement) return "";
    return (
      <ChillButton
        className="btn--addCompany"
        name="ADD COMPANY"
        small={false}
        width={132}
        height={40}
        onClick={() =>
          this.setState({
            addingCompany: !this.state.addingCompany,
          })
        }
      />
    );
  }
  _renderInternalCheck() {
    if (this.props.companies.length > 0) return "";

    return (
      <div>
        <Checkbox
          label="Internal User"
          checked={this.state.isInternal || false}
          onClick={() => this.props.toggleIsInternal()}
          iconStyle={styles.checkInput}
        />
      </div>
    );
  }
  _renderAddCompany() {
    const {disableCompanyManagement} = this.props;
    if (
      disableCompanyManagement ||
      this.state.isInternal ||
      !this.getAvailableCompanies().length
    )
      return "";
    if (this.state.addingCompany) return this._renderCompanySelect();
    return this._renderAddCompanyButton();
  }
  _handleDeleteCompanyClick(id) {
    const {handleDeleteCompany, companies} = this.props;

    handleDeleteCompany(id);
    if (companies.length === 0) {
      this.setState({addingCompany: true});
    }
  }
  _renderCompanies() {
    const {
      disableCompanyManagement,
      companies,
      allCompanies,
      handleChangeCompany,
      currentCompanyId,
      user,
    } = this.props;

    const walletMembers = lodash.get(user, "recipient.walletMembers");
    return (
      companies &&
      companies.map(id => {
        const displayDeleteIcon =
          !disableCompanyManagement && Number(id) != Number(currentCompanyId);
        const walletMember = lodash
          .defaultTo(walletMembers, [])
          .find(({companyId}) => Number(companyId) === Number(id));

        return (
          <div className="flex" key={id}>
            <CompanySelect
              id={id}
              value={id}
              disabled={true}
              companies={allCompanies}
              handleChangeCompany={(e, index, value, name) =>
                handleChangeCompany(e, index, value, name)
              }
              handleDeleteCompany={() => this._handleDeleteCompanyClick(id)}
              name={"company"}
              trashCan={displayDeleteIcon}
            />
            <Checkbox
              label="Add to Wallet"
              disabled={
                disableCompanyManagement &&
                Number(currentCompanyId) !== Number(id)
              }
              defaultChecked={lodash.get(walletMember, "isWalletMember")}
              onClick={() => this.props.toggleCompanyWallet(id)}
              style={{
                margin: !displayDeleteIcon
                  ? "-10px 0 0 -10px"
                  : "-10px 0 0 32px",
              }}
              iconStyle={{
                marginRight: "6px",
              }}
            />
          </div>
        );
      })
    );
  }
  _renderNameRow() {
    return (
      <div className="usercreate_row">
        <ValidableTextField
          name="firstName"
          label="First name"
          value={this.props.firstName}
          errorText={this.state.errors.firstName}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
          autoFocus={true}
        />
        <ValidableTextField
          name="lastName"
          label="Last name"
          value={this.props.lastName}
          errorText={this.state.errors.lastName}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
        />
      </div>
    );
  }

  _renderPasswordAssignSection() {
    const {showPasswordField} = this.state;
    const {password} = this.props;
    if (!showPasswordField && password && password.length > 0) {
      return (
        <ChillButton
          className="btn"
          name="ASSIGN PASSWORD"
          customStyles={{
            alignSelf: "center",
            padding: "7px",
            paddingRight: "12px",
          }}
          small={true}
          width={"auto"}
          height={40}
          icon="check_circle"
          useFontIcon={true}
          fontIconStyles={{
            marginRight: "5px",
            color: "#ffffff",
          }}
          onClick={() => this.setState({showPasswordField: true})}
        />
      );
    }
    return (
      <ChillButton
        className="btn"
        name="ASSIGN PASSWORD"
        customStyles={{
          alignSelf: "center",
          padding: "12px",
        }}
        small={true}
        width={"auto"}
        height={40}
        onClick={() => this.setState({showPasswordField: true})}
      />
    );
  }

  _renderEmailField(asIndividualRow) {
    const {allowPasswordAssignment} = this.props;
    const emailField = (
      <ValidableTextField
        name="emailAddress"
        label="Email"
        value={this.props.emailAddress}
        errorText={this.state.errors.emailAddress}
        handleChange={(e, value) => this.props.handleChange(e, value)}
        validate={() => this._validateDebounced()}
      />
    );
    if (allowPasswordAssignment && asIndividualRow) {
      return (
        <div className="usercreate_row d-flex flex-row align-items-center">
          {emailField}
        </div>
      );
    } else if (!allowPasswordAssignment && asIndividualRow) {
      return null;
    } else {
      return emailField;
    }
  }

  renderPhoneNumber(countryCode) {
    return (
      <PhoneNumberInput
        name="phoneNumber"
        label="Phone"
        value={this.props.phoneNumber}
        countryCode={countryCode}
        errorText={this.state.errors.phoneNumber}
        handleChange={(e, value) =>
          this.props.handleChange(
            e,
            null,
            value.replace(/\D/g, ""),
            "phoneNumber"
          )
        }
        validate={() => this._validateDebounced()}
      />
    );
  }

  _renderAddressRow() {
    return (
      <div className="usercreate_row">
        <ValidableTextField
          name="address1"
          label="Address 1"
          value={this.props.address1}
          errorText={this.state.errors.address1}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          name="address2"
          label="Address 2"
          value={this.props.address2}
          errorText={this.state.errors.address2}
          handleChange={(e, value) => this.props.handleChange(e, value)}
          validate={() => this._validateDebounced()}
        />
      </div>
    );
  }

  _setUserPassword(password) {
    if (!password) {
      return;
    }
    const {handleChange} = this.props;
    this.setState(
      {
        showPasswordField: false,
      },
      () => {
        handleChange(null, null, password, "password");
      }
    );
  }

  _renderPasswordAssignmentDialog() {
    const {showPasswordField} = this.state;
    const onDialogClose = () => this.setState({showPasswordField: false});
    return (
      <Dialog
        modal={false}
        open={showPasswordField}
        onRequestClose={onDialogClose}
      >
        <AssignPasswordDialogBody
          onRequestClose={onDialogClose}
          onPasswordAssignmentHandler={password =>
            this._setUserPassword(password)
          }
        />
      </Dialog>
    );
  }

  renderCountryField() {
    const {handleChange, countryId} = this.props;
    const {errors} = this.state;
    return (
      <div className="usercreate_row">
        <ValidableSelect
          name="countryId"
          label="Country"
          value={countryId}
          errorText={errors.countryId}
          handleChange={(e, index, value) => {
            if (parseInt(value) !== parseInt(countryId)) {
              handleChange(e, index, Number(value), "countryId");
              handleChange(e, null, null, "state");
            }
          }}
          options={this._getCountrySelectOptions()}
          iconStyle={formStyles.CountryIconStyles}
          menuItemStyle={formStyles.wrappedMenuItemStyle}
        />
      </div>
    );
  }

  _renderLocationRow(countryCode) {
    const {handleChange, state} = this.props;
    return (
      <div className="usercreate_row usercreate_row_bot">
        <ValidableTextField
          name="city"
          label="City"
          value={this.props.city}
          errorText={this.state.errors.city}
          handleChange={(e, value) => handleChange(e, value)}
          validate={() => this._validateDebounced()}
          style={formStyles.CityTextFieldStyles}
          inputStyle={formStyles.CityTextFieldStyles}
        />
        <StateSelect
          countryCode={countryCode}
          value={state}
          errorText={this.state.errors.state}
          handleChange={handleChange}
          style={formStyles.halfSelectStyles}
          inputStyle={formStyles.halfSelectInputStyles}
          floatingLabelStyle={formStyles.halfSelectFloatingLabelStyles}
          menuStyle={formStyles.halfSelectMenuInputStyles}
          listStyle={formStyles.halfSelectMenuListStyles}
          iconStyle={{
            ...formStyles.StateIconStyles,
            right: -5,
          }}
        />
        <ValidableTextField
          name="zipCode"
          label="Zip Code"
          minLength={this.isSelectedUSA() ? 5 : null}
          maxLength={this.isSelectedUSA() ? 5 : null}
          value={this.props.zipCode}
          errorText={this.state.errors.zipCode}
          handleChange={(e, value) => handleChange(e, value)}
          validate={() => this._validateDebounced()}
          style={formStyles.PostCodeStyles}
          inputStyle={formStyles.PostCodeInputStyles}
        />
      </div>
    );
  }

  setCurrectContact(id) {
    if (id === "new") {
      this.props.setContactInformation({
        phoneNumber: "",
        address1: "",
        address2: "",
        city: "",
        stateId: null,
        zipCode: null,
        countryId: null,
      });
    } else {
      const contact = this.props.contactsWithoutUser.find(
        ({id: contactId}) => contactId === id
      );

      const [firstName, lastName] = contact.name.split(" ");
      this.props.setContactInformation({
        city: contact.city,
        phoneNumber: contact.phoneNumber,
        address1: contact.address1,
        address2: contact.address2,
        state: contact.state,
        stateId: contact.stateId,
        zipCode: contact.zipCode,
        countryId: contact.countryId,
        emailAddress: contact.emailAddress,
        lastName,
        firstName,
      });
    }
  }

  render() {
    const {handleChange, id, countries, countryId} = this.props;
    const selectedCountry = countries.find(
      country => Number(country.id) === countryId
    );
    const countryCode = selectedCountry ? selectedCountry.code : "US";

    return (
      <div className="user-form">
        {this._renderInternalCheck()}
        {!id ? (
          <div>
            <ValidableSelect
              name="recipientId"
              label="Related Contact"
              value={this.props.recipientId}
              handleChange={(e, index, value) => {
                this.setCurrectContact(value);
                handleChange(e, index, value, "recipientId");
              }}
              options={this.contactOptions()}
              iconStyle={{right: 20, color: "#747D86", padding: 0}}
            />
          </div>
        ) : null}
        {this._renderNameRow()}

        <div className="usercreate_row flex flex-row align-items-center">
          {this._renderEmailField(true)}
          {this._renderPasswordAssignSection()}
        </div>
        {this.renderCountryField()}
        {this.renderPhoneNumber(countryCode)}
        {this._renderAddressRow()}
        {this._renderLocationRow(countryCode)}
        {this._renderCompanies()}
        {this._renderAddCompany()}
        {this._renderPasswordAssignmentDialog()}
      </div>
    );
  }
}
