import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {fade} from "material-ui/utils/colorManipulator";

import withStyles from "../../../shared/WithStyles";
import Typography from "../../../shared/Typography";

const QuoteSidebar = ({
  showQuoteDetails,
  items,
  category,
  product,
  fabric,
  color,
  styles,
}) => {
  const {colors} = styles;
  const pageStyle = {
    sidebarContainer: {
      paddingLeft: 31,
      paddingTop: 16,
    },
    cardSection: {
      backgroundColor: fade(colors.gray.border, 0.2),
      borderBottom: `1px solid ${colors.gray.border}`,
      padding: "15px 15px 15px 31px",
    },
    largeMarginBottom: {
      marginBottom: 24,
    },
  };
  return (
    <Fragment>
      {showQuoteDetails &&
        items.map(item => (
          <div key={item.id} className="flex" style={pageStyle.cardSection}>
            <div className="flex-one">
              <Typography variant="h5">{item.product.productName}</Typography>
              <div className="flex justify-content-between">
                <Typography variant="subtitle">
                  {item.fabric.name}, {item.color.name}
                </Typography>
                <Typography variant="subtitle" className="margin-right-20">
                  {item.artFilename}
                </Typography>
              </div>
            </div>
            <div className="pointer">
              <img
                src="/assets/imgs/icons/edit-blue.svg"
                style={{padding: 6, width: 30, height: 30}}
              />
            </div>
          </div>
        ))}
      <div style={pageStyle.sidebarContainer}>
        <Typography
          variant="subtitleBold"
          color="gray.title"
          className="text-uppercase"
        >
          Category:
        </Typography>
        <Typography
          variant="h4"
          color="black"
          style={pageStyle.largeMarginBottom}
        >
          {category ? category.name : "-"}
        </Typography>
        {category && (
          <Fragment>
            <Typography
              variant="subtitleBold"
              color="gray.title"
              className="text-uppercase"
            >
              Product:
            </Typography>
            <Typography
              variant="h4"
              color="black"
              style={pageStyle.largeMarginBottom}
            >
              {product ? product.productName : "-"}
            </Typography>
            <Typography
              variant="subtitleBold"
              color="gray.title"
              className="text-uppercase"
            >
              Fabric:
            </Typography>
            <Typography
              variant="h4"
              color="black"
              style={pageStyle.largeMarginBottom}
            >
              {fabric ? fabric.name : "-"}
            </Typography>
            <Typography
              variant="subtitleBold"
              color="gray.title"
              className="text-uppercase"
            >
              Color:
            </Typography>
            <Typography
              variant="h4"
              color="black"
              style={pageStyle.largeMarginBottom}
            >
              {color ? color.name : "-"}
            </Typography>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

QuoteSidebar.propTypes = {
  showQuoteDetails: PropTypes.bool,
  items: PropTypes.array,
  category: PropTypes.object,
  product: PropTypes.object,
  fabric: PropTypes.object,
  color: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(QuoteSidebar);
