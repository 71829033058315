import React, {Component} from "react";
import PropTypes from "prop-types";

import SearchInput from "../../../../shared/SearchInput";
import ChillButton from "../../../../shared/ChillButton";
import Pagination from "../../../../shared/Pagination";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import OrderHistoryTable from "./OrderHistoryTable";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";

import SelectField from "material-ui/SelectField";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import {
  formStyles,
  SelectFieldIconStyle,
} from "../../../../../styles/components/formStyles";
import lodash from "lodash";

export default class OrderHistoryTab extends Component {
  static propTypes = {
    searchOrders: PropTypes.func,
    company: PropTypes.object,
    orders: PropTypes.array,
    ordersCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    searchTerm: PropTypes.string,
    setPage: PropTypes.number,
    page: PropTypes.number,
    addQueryParam: PropTypes.func,
    selectedOrderType: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    selectedTab: PropTypes.string,
    createReorderItem: PropTypes.func,
    currentUserId: PropTypes.number,
  };
  state = {
    currentPage: {
      all: 1,
      historical: 1,
      active: 1,
    },
    selectedOrderType: "all",
    openOrderModal: false,
  };

  componentDidMount() {
    this.props.searchOrders();
  }

  componentDidUpdate(prevProps) {
    if (this._hasParamsChanged(prevProps)) {
      this.props.searchOrders();
    }
  }

  _hasParamsChanged = prevProps => {
    const isActiveTab = this.props.selectedTab !== prevProps.selectedTab;

    const isSelectedTypeChanged =
      this.props.selectedOrderType !== prevProps.selectedOrderType;

    return (
      this.props.selectedTab === "orders" &&
      (isActiveTab ||
        hasQueryParamsChanged(this.props, prevProps) ||
        isSelectedTypeChanged)
    );
  };

  handleSelectPage = options => {
    const {selectedOrderType} = this.state;
    const {setPage} = this.props;

    setPage(options.page);
    this.setState({
      currentPage: {
        ...this.state.currentPage,
        [selectedOrderType]: options.page,
      },
    });
  };

  _handleSearchOrders(nextSearchTerm) {
    const {searchTerm, setSearchTerm} = this.props;
    if (nextSearchTerm != searchTerm) {
      this.setState({currentPage: 1});
    }
    setSearchTerm(nextSearchTerm);
  }

  _handleSearchOrdersDebounced = lodash.debounce(this._handleSearchOrders, 300);

  _handleTypeDropdownChange = value => {
    this.props.addQueryParam({selectedOrderType: value});
  };

  _renderPagination() {
    const {ordersCount, page} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={options => this.handleSelectPage(options)}
          count={ordersCount}
          currentPage={(page || 1) - 1}
        />
      </div>
    );
  }

  _renderOrderTable() {
    const {orders, ordersCount, isLoading, searchTerm} = this.props;
    const {selectedOrderType} = this.state;
    return (
      <div
        className={
          this.props.ordersCount <= 7
            ? "tab-container"
            : "tab-container tab-container__pagination"
        }
      >
        <OrderHistoryTable
          isLoading={isLoading}
          orders={orders}
          ordersCount={ordersCount}
          searching={!!searchTerm || selectedOrderType !== "all"}
        />{" "}
        {ordersCount > 7 && orders && orders.length
          ? this._renderPagination()
          : null}
      </div>
    );
  }

  _renderTypeDropdown() {
    const {selectedOrderType} = this.props;

    return (
      <SelectField
        value={selectedOrderType}
        onChange={(_, __, value) => this._handleTypeDropdownChange(value)}
        style={{
          ...formStyles.HalfFieldStyles,
          width: "auto",
          minWidth: "220px",
          marginLeft: "24px",
        }}
        iconStyle={{
          right: "-16px",
          ...SelectFieldIconStyle,
        }}
        menuStyle={formStyles.selectMenuStyle}
        underlineStyle={formStyles.underlineStyle}
        inputStyle={formStyles.inputStyle}
        floatingLabelStyle={formStyles.TextFieldLabelStyles}
      >
        <CNGMenuItem value="all" primaryText="All Orders" />
        <CNGMenuItem value="active_orders" primaryText="Active Orders" />
        <CNGMenuItem
          value="historical_orders"
          primaryText="Historical Orders"
        />
        <CNGMenuItem value="active_dsod" primaryText="Active DSOD Orders" />
        <CNGMenuItem
          value="historical_dsod"
          primaryText="Historical DSOD Orders"
        />
      </SelectField>
    );
  }

  createReorderItem = () => {
    const {company, createReorderItem} = this.props;
    if (!company) return;
    createReorderItem(company.id, []);
  };

  handleOrderCart = async () => {
    const {
      history,
      company,
      isInternal,
      currentUserId,
      fetchOpenOrders,
    } = this.props;
    if (!isInternal) {
      const allOpen = await fetchOpenOrders(company.id);
      const openOrders = allOpen.data.filter(order => !order.isDsod);
      if (
        openOrders.length > 0 &&
        openOrders[0].createdUserId !== currentUserId
      ) {
        this.setState({openOrderModal: true});
        return;
      }
    }
    history.push(`/companies/${company.id}/products`);
  };

  render() {
    const {orders, company} = this.props;
    const {openOrderModal} = this.state;
    return (
      <div className="tab-container">
        <div className="tab_top">
          <div className="flex w-100">
            <SearchInput
              initialValue={this.props.searchTerm}
              search={(_, value) => this._handleSearchOrdersDebounced(value)}
            />{" "}
            {this._renderTypeDropdown()}
            <div className="flex align-items-center w-100 flex-direction-reverse ">
              {orders.length > 0 && (
                <ChillButton
                  name={"Create Re-Order"}
                  height={32}
                  width={160}
                  icon={"check"}
                  onClick={this.handleOrderCart}
                  className="text-uppercase"
                />
              )}
            </div>
          </div>
        </div>
        {this._renderOrderTable()}
        <ConfirmationModal
          open={openOrderModal}
          height={400}
          title="OOPS - we’re sorry!!"
          content={
            <React.Fragment>
              <span>
                Another {company && company.name} user has your Order cart open.
                We can open only one cart for you at a time. Please be patient
                and try again later.
              </span>
              <br />
              <br />
              <span>Thanks for your understanding!</span>
              <br />
              <br />
              <span>Team chill-n-go</span>
            </React.Fragment>
          }
          confirmText="OK"
          cancelText=" "
          onConfirm={() => this.setState({openOrderModal: false})}
          onDismiss={() => this.setState({openOrderModal: false})}
        />
      </div>
    );
  }
}
