import React, {Component} from "react";

import Layout from "./Layout";
import {LayoutPropTypes} from "./LayoutProps";

export default class Flex extends Component {
  static propTypes = {
    ...LayoutPropTypes,
  };

  render() {
    return <Layout {...this.props} display={"flex"} />;
  }
}
