import React, {Component} from "react";
import classNames from "classnames";
import _get from "lodash/get";
import _map from "lodash/map";
import _reject from "lodash/reject";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import CircularProgress from "material-ui/CircularProgress";
import ValidableSelect from "../../../../shared/ValidableSelect";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import ChillButton from "../../../../shared/ChillButton";
import AttributeLine from "../../../../shared/AttributeLine";
import {
  SelectFieldIconStyle,
  formStyles,
} from "../../../../../styles/components/formStyles";
const iconStyle = {
  right: "-16px",
  ...SelectFieldIconStyle,
};

class ProductForm extends Component {
  state = {
    arts: [
      {
        artId: null,
      },
    ],
    productId: null,
    fabricId: null,
    colorId: null,
    leatherColorId: null,
  };

  async componentDidMount() {
    const {
      isEditingOrder,
      initialData,
      companyId,
      fetchProducts,
      fetchArtRevisionsByCompany,
    } = this.props;
    await fetchProducts({
      sort: "item_number",
      orderDirection: "asc",
    });
    fetchArtRevisionsByCompany(companyId, {
      sort: "name",
      sortDirection: "asc",
      filter: "active,eq,true",
    });

    if (isEditingOrder && initialData) {
      await this.setState({productId: _get(initialData, "product.id")});
      await this.setState({
        fabricId: _get(initialData, "fabricColor.fabricId"),
      });
      await this.setState({
        colorId: _get(initialData, "fabricColor.colorId"),
        leatherColorId: _get(initialData, "leatherColor.colorId"),
        arts: _get(
          initialData,
          "orderItemProduct.orderItemProductAdornmentLocationArtAssignments"
        ).map(item => ({
          artId: _get(item, "art.id"),
          adornmentLocationId: _get(
            item,
            "productAdornmentLocation.adornmentLocationId"
          ),
        })),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.productId !== prevState.productId) {
      if (this.isLeatherTrimProduct()) {
        this.props.fetchLeatherColors({
          sort: "name",
          sortDirection: "ASC",
        });
      }
      this.fetchFabrics();
      this.setState({
        fabricId: null,
        colorId: null,
        arts: [
          {
            artId: null,
          },
        ],
      });
    }
    if (this.state.fabricId !== prevState.fabricId) {
      this.fetchColors();
    }
  }

  fetchColors = () => {
    const {fabricId} = this.state;
    if (!fabricId) return;

    const filters = [
      ["fabricAvailableColor.fabric_id", "eq", this.state.fabricId],
      ["fabricAvailableColor.active", "eq", true],
    ];

    this.props.fetchColors({
      sort: "name",
      sortDirection: "asc",
      filter: filters.join("|"),
    });
  };

  fetchFabrics = () => {
    const {productId} = this.state;
    const filters = [["productFabricPricing.product_id", "eq", productId]];

    this.props.fetchFabrics({
      filter: filters.join("|"),
    });

    const adornmentLocationsOptions = {
      sort: "index",
      sortDirection: "ASC",
    };
    this.props.fetchAdornmentLocationsByProduct(
      productId,
      adornmentLocationsOptions
    );
  };

  handleChange = name => (event, index, value) => {
    value = event.target.value || value;
    this.setState({
      [name]: value,
    });
  };

  handleChangeArt = (name, artIndex) => (event, index, value) => {
    value = event.target.value || value;

    this.setState({
      arts: this.state.arts.map((art, index) => {
        if (index === artIndex) return {...art, [name]: value};
        return art;
      }),
    });
  };

  hasFabrics = () => {
    const {fabrics} = this.props;
    const {productId} = this.state;
    return !(productId && fabrics.length === 1 && fabrics[0].name === "None");
  };

  isLeatherTrimProduct = () => {
    const {products} = this.props;
    const {productId} = this.state;
    if (!products) return false;
    const product = products.find(
      product => Number(product.id) === Number(productId)
    );
    if (!product) return false;
    const match = product.itemNumber.match(/LT$/g);
    return Boolean(match);
  };

  hasLocations = () => {
    const {adornmentLocations} = this.props;
    return _get(adornmentLocations, "length", 0) > 0;
  };

  handleCreate = () => {
    const {
      createOrderItem,
      updateOrderItem,
      companyId,
      onClose,
      onCreated,
      selectedItemTypeId,
      isEditingOrder,
      initialData,
    } = this.props;
    const {arts, ...attributes} = this.state;
    if (isEditingOrder) {
      return updateOrderItem(
        initialData.id,
        {
          orderItemProductUpdate: true,
          ...attributes,
          arts: arts.filter(({artId}) => artId),
        },
        () => {
          onClose();
          onCreated();
        }
      );
    }
    createOrderItem(
      companyId,
      {
        ...attributes,
        arts: arts.filter(({artId}) => artId),
        orderItemTypeId: selectedItemTypeId,
      },
      () => {
        onClose();
        onCreated();
      }
    );
  };

  handleRemoveArt = removedKey => () => {
    this.setState({
      arts: this.state.arts.filter((_, index) => {
        return index !== removedKey;
      }),
    });
  };

  getProductOptions = () => {
    return _get(this.props, "products", []).map(
      ({id, productName, itemNumber}) => (
        <CNGMenuItem
          key={id}
          value={id}
          primaryText={`${itemNumber} - ${productName}`}
        />
      )
    );
  };

  getProductName = () => {
    const {productId} = this.state;
    return _get(
      _get(this.props, "products", []).find(({id}) => id === productId),
      "productName"
    );
  };

  getFabricOptions = () => {
    return _get(this.props, "fabrics", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  getColorOptions = () => {
    return _get(this.props, "colors", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  getLeatherColorOptions = () => {
    return _get(this.props, "leatherColors", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  getArtRevisionOptions = () => {
    return _get(this.props, "artRevisions", []).map(({id, name}) => (
      <CNGMenuItem key={id} value={id} primaryText={name} />
    ));
  };

  getNonSelectedAdornments = () => {
    const adornmentLocations = _get(this.props, "adornmentLocations", []);
    const selectedIds = _map(this.state.arts, "adornmentLocationId");
    return _reject(adornmentLocations, ({id}) => selectedIds.includes(id));
  };

  getAdornmentLocationOptions = selectedId => {
    const adornmentLocations = _get(this.props, "adornmentLocations", []);
    const selectedIds = _map(this.state.arts, "adornmentLocationId");
    const filteredOptions = _reject(
      adornmentLocations,
      ({id}) => selectedIds.includes(id) && selectedId !== id
    );
    return filteredOptions.map(({id, location}) => (
      <CNGMenuItem key={id} value={id} primaryText={location} />
    ));
  };

  isValidForm = () => {
    const attributes = this.hasFabrics()
      ? ["productId", "fabricId", "colorId"]
      : ["productId"];
    return attributes.every(attribute => !!this.state[attribute]);
  };

  hasFirstArt = () => {
    const {arts} = this.state;
    if (!arts.length) return true;

    const {artId, adornmentLocationId} = this.state.arts[0];
    return !!artId && !!adornmentLocationId;
  };

  renderArtList = () => {
    const {productId} = this.state;
    if (!this.hasFabrics()) return;
    if (!this.hasLocations()) return;

    return this.state.arts.map((art, index) => (
      <div className="flex  flex-center" key={index}>
        <ValidableSelect
          name={`artId${index}`}
          label="Art"
          errorText={null}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
          value={_get(art, "artId") || ""}
          handleChange={this.handleChangeArt("artId", index)}
          underlineStyle={formStyles.underlineStyle}
          style={{width: 260, marginRight: 24}}
          inputStyle={{width: "100%"}}
          iconStyle={iconStyle}
          disabled={!productId}
          floatingLabelFixed={true}
          hintText="Select"
          dropDownMenuProps={{
            style: {
              width: "100%",
            },
          }}
          options={this.getArtRevisionOptions()}
        />

        <ValidableSelect
          name={`artLocationId${index}`}
          label="Art Location"
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
          errorText={null}
          value={_get(art, "adornmentLocationId") || ""}
          style={{width: "45%"}}
          iconStyle={iconStyle}
          floatingLabelFixed={true}
          handleChange={this.handleChangeArt("adornmentLocationId", index)}
          underlineStyle={formStyles.underlineStyle}
          inputStyle={{width: "100%"}}
          disabled={!productId || !_get(art, "artId")}
          hintText="Location"
          dropDownMenuProps={{
            style: {
              width: "100%",
            },
          }}
          options={this.getAdornmentLocationOptions(
            _get(art, "adornmentLocationId")
          )}
        />

        <img
          src={"/assets/imgs/icons/trash.svg"}
          onClick={this.handleRemoveArt(index)}
          className="btn-icon-size pointer"
          style={{
            width: 18,
            height: 18,
            alignSelf: "flex-end",
            marginBottom: 15,
            marginRight: 0,
            marginLeft: 10,
          }}
        />
      </div>
    ));
  };

  render() {
    const {
      itemTypeOptions,
      selectedItemTypeId,
      fabrics,
      colors,
      leatherColors,
      onChangeOrderItemType,
      isEditingOrder,
    } = this.props;
    const {productId, fabricId, colorId, leatherColorId, arts} = this.state;

    return (
      <React.Fragment>
        <div className="flex">
          {isEditingOrder ? (
            <AttributeLine
              labelText="Item"
              text="Product"
              style={{width: 260, marginRight: 24}}
              marginTop={15}
              isInternal
            />
          ) : (
            <ValidableSelect
              name="orderItemTypeId"
              label="Item"
              errorText={null}
              value={selectedItemTypeId || ""}
              handleChange={onChangeOrderItemType}
              underlineStyle={formStyles.underlineStyle}
              underlineDisabledStyle={formStyles.underlineDisabledStyle}
              style={{width: 260, marginRight: 24}}
              iconStyle={iconStyle}
              inputStyle={{width: "100%"}}
              floatingLabelFixed={true}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
              }}
              options={itemTypeOptions}
              hintText="Select"
            />
          )}

          {isEditingOrder ? (
            <AttributeLine
              labelText="Product"
              text={this.getProductName()}
              style={{width: 260, marginRight: 24}}
              marginTop={15}
              isInternal
            />
          ) : (
            <ValidableSelect
              name="productId"
              label="Select Product"
              errorText={null}
              value={productId || ""}
              style={{width: "50%"}}
              floatingLabelFixed={true}
              handleChange={this.handleChange("productId")}
              underlineStyle={formStyles.underlineStyle}
              inputStyle={{width: "100%"}}
              iconStyle={iconStyle}
              underlineDisabledStyle={formStyles.underlineDisabledStyle}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
                autoWidth: true,
              }}
              options={this.getProductOptions()}
              hintText="Select"
            />
          )}
        </div>

        {this.hasFabrics() ? (
          <div className="flex">
            <ValidableSelect
              name="fabricId"
              label="Fabric"
              errorText={null}
              floatingLabelFixed={true}
              value={fabricId || ""}
              handleChange={this.handleChange("fabricId")}
              underlineStyle={formStyles.underlineStyle}
              style={{width: 260, marginRight: 24}}
              iconStyle={iconStyle}
              inputStyle={{width: "100%"}}
              underlineDisabledStyle={formStyles.underlineDisabledStyle}
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
              }}
              disabled={!_get(fabrics, "length", 0)}
              options={this.getFabricOptions()}
              hintText="Select"
            />

            <ValidableSelect
              name="colorId"
              label="Fabric Color"
              errorText={null}
              floatingLabelFixed={true}
              underlineDisabledStyle={formStyles.underlineDisabledStyle}
              value={colorId || ""}
              style={{width: "50%"}}
              iconStyle={iconStyle}
              handleChange={this.handleChange("colorId")}
              underlineStyle={formStyles.underlineStyle}
              inputStyle={{width: "100%"}}
              disabled={!_get(colors, "length", 0) || !fabricId}
              hintText="Select"
              dropDownMenuProps={{
                style: {
                  width: "100%",
                },
              }}
              options={this.getColorOptions()}
            />
          </div>
        ) : null}

        {this.isLeatherTrimProduct() ? (
          <ValidableSelect
            name="leatherColorId"
            label="Leather Trim"
            errorText={null}
            floatingLabelFixed={true}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            value={leatherColorId || ""}
            style={{width: "50%"}}
            iconStyle={iconStyle}
            handleChange={this.handleChange("leatherColorId")}
            underlineStyle={formStyles.underlineStyle}
            inputStyle={{width: "100%"}}
            disabled={!_get(leatherColors, "length", 0)}
            hintText="Select"
            dropDownMenuProps={{
              style: {
                width: "100%",
              },
            }}
            options={this.getLeatherColorOptions()}
          />
        ) : null}

        {this.renderArtList()}

        <div
          className={classNames({
            hidden:
              arts.length >= 4 || this.getNonSelectedAdornments().length === 0,
          })}
        >
          <span
            className="small-header new-order--add-link flex flex-center uppercase pointer"
            style={{
              marginTop: 24,
              color: this.hasFirstArt() ? "#1D9ABB" : "#BDC6CF",
            }}
            onClick={() => {
              if (this.hasFirstArt()) {
                this.setState({arts: [...this.state.arts, [{}]]});
              }
            }}
          >
            <FontIcon
              className="material-icons"
              style={{
                color: this.hasFirstArt() ? "#1D9ABB" : "#BDC6CF",
                fontSize: 26,
                marginRight: 8,
              }}
            >
              add_circle_outline
            </FontIcon>

            <span>{arts.length > 0 ? "Add another art" : "Add Art"}</span>
          </span>
        </div>

        {this.props.isLoading ? (
          <div
            style={{
              margin: " 25px 0",
              textAlign: "center",
            }}
          >
            <div className="auth-button-progress-container">
              <CircularProgress
                size={24}
                thickness={4}
                color="rgba(255, 255, 255, 0.5)"
              />
            </div>
            <span>Loading...</span>
          </div>
        ) : (
          <ChillButton
            onClick={this.handleCreate}
            name="Save"
            useFontIcon={true}
            icon="check"
            fontIconStyles={{
              color: "#FFFFFF",
              fontSize: 18,
              marginRight: 4,
            }}
            isDisabled={!this.isValidForm()}
            className="chill-button  btn-1 flex w-100 chill-button--large uppercase"
            height={41}
            marginTop={37}
            marginBottom={36}
          />
        )}
      </React.Fragment>
    );
  }
}

ProductForm.propTypes = {
  initialData: PropTypes.object,
  isLoading: PropTypes.bool,
  companyId: PropTypes.string,
  itemTypeOptions: PropTypes.array,
  fetchProducts: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchColors: PropTypes.func,
  fetchLeatherColors: PropTypes.func,
  fetchArtRevisionsByCompany: PropTypes.func,
  fetchAdornmentLocationsByProduct: PropTypes.func,
  onChangeOrderItemType: PropTypes.func,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  createOrderItem: PropTypes.func,
  updateOrderItem: PropTypes.func,
  selectedItemTypeId: PropTypes.number,
  fabrics: PropTypes.array,
  products: PropTypes.array,
  colors: PropTypes.array,
  leatherColors: PropTypes.array,
  adornmentLocations: PropTypes.array,
  artRevisions: PropTypes.array,
  isEditingOrder: PropTypes.bool,
};

export default ProductForm;
