import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "../../../shared/ChillButton";
import ValidableTextField from "../../../shared/ValidableTextField";
import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";

export default class AssignPasswordDialogBody extends Component {
  state = {
    password: "",
    confirm: "",
    errors: {},
    submitted: false,
  };

  validations = {
    password: {
      format: "any",
      length: [6, 30],
      nullable: false,
    },
    confirm: {
      format: "any",
      length: [6, 30],
      nullable: false,
      shouldMatch: () => this._evaluateConfirmPassword(),
      shouldMatchMessage: "passwords do not match",
    },
  };

  _evaluateConfirmPassword() {
    const {password, confirm} = this.state;
    return password === confirm;
  }

  _triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    if (!valid) {
      return;
    }
    const {onPasswordAssignmentHandler} = this.props;
    const {password} = this.state;
    onPasswordAssignmentHandler(password);
  }

  _validate() {
    const errors = validateForm(this);
    this.setState({errors});
    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  _evaluateSaveButtonState() {
    const {errors, password, confirm} = this.state;
    if (!password.length || !confirm.length) {
      return true;
    }
    if (password !== confirm) {
      return true;
    }
    return Object.keys(errors).length > 0 ? true : false;
  }

  render() {
    const {password, confirm, errors} = this.state;
    const {onRequestClose} = this.props;
    const styles = {
      title: {
        marginBottom: "24px",
      },
      message: {
        maxWidth: "465px",
      },
    };
    return (
      <div className="flex flex-column align-items-center">
        <img
          onClick={() => onRequestClose()}
          className="archiveModal_closeIcon"
          src="../../../../assets/imgs/icons/close.svg"
        />
        <h3 className="text-center" style={styles.title}>
          Assign Password
        </h3>
        <p className="text-center" style={styles.message}>
          The user will not be notified of the change. Their existing password
          will become invalid.
        </p>
        <ValidableTextField
          isPassword
          name="password"
          className="no-margin"
          label="Password"
          value={password}
          errorText={errors.password}
          handleChange={(_, value) => this.setState({password: value})}
          validate={() => this._validateDebounced()}
        />

        <ValidableTextField
          isPassword
          className="no-margin"
          name="confirm"
          label="Confirm Password"
          value={confirm}
          errorText={errors.confirm}
          handleChange={(_, value) => this.setState({confirm: value})}
          validate={() => this._validateDebounced()}
        />

        <ChillButton
          className="btn-1"
          name="ASSIGN PASSWORD"
          customStyles={{
            alignSelf: "center",
            padding: "12px",
            marginTop: "30px",
          }}
          small={true}
          width={"408px"}
          height={40}
          isDisabled={this._evaluateSaveButtonState()}
          onClick={() => this._triggerValidations()}
        />
      </div>
    );
  }
}

AssignPasswordDialogBody.propTypes = {
  onPasswordAssignmentHandler: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};
