import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import {Link} from "react-router-dom";

import PageLayout from "../../../shared/Layout/Layouts/PageLayout";
import ReorderForm from "../components/ReorderForm";
import ReorderHeader from "../components/ReorderHeader";
import ReorderItems from "../components/ReorderItems";
import NewOrderModal from "../components/NewOrderModal";

class OrderCartNew extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchCurrentOpenOrders();
    this.props.fetchCompanies({
      sort: "name",
      sortDirection: "ASC",
    });
  }

  getFilteredCompanies = () => {
    const {companies} = this.props;
    if (!companies) return [];
    const companyIds = lodash.map(this.getOpenReorder(), "companyId");
    return companies.filter(({id}) => !companyIds.includes(id));
  };

  getOpenReorder = () => {
    return this.props.openOrders.filter(({isDsod}) => !isDsod);
  };

  render() {
    const {
      isOpenOrderLoading,
      navigateTo,
      createReorderItem,
      paymentTerms,
    } = this.props;
    const filteredCompanies = this.getFilteredCompanies();

    return (
      <PageLayout
        isLoading={isOpenOrderLoading}
        loaderEnable={true}
        smallTop={true}
        subHeaderText={null}
      >
        <div className="row middle-xs start-xs margin-bottom-24">
          <Link to={"/"} className="reset-link-styles small-header">
            Back to previous page
          </Link>
        </div>
        <div>
          <ReorderHeader
            noButton
            noSearch
            titleMaterialIcon
            containerClass="margin-bottom-16"
            title="Create an Order"
            titleIcon="shopping_cart"
            createPage="/users/create"
            buttonIcon="check"
            buttonText="SUBMIT RE-ORDER"
            buttonWidth={160}
            buttonClick={() => {}}
          />
          <ReorderForm
            isDisabled={()=> false}
            orderData={{}}
            shipmentData={{}}
            paymentTerms={paymentTerms}
          />
          <ReorderItems
            isDisabled={()=> false}
            orderData={{}}
            companyContacts={[]}
            fetchCompanyContacts={() => {}}
            reorderItems={[
              {
                orderQuantity: 0,
                quantityToInventory: 0,
                quantityToShip: 0,
                netUnitCost: 0,
                totalDiscount: 0,
                totalAdornmentCost: 0,
                orderTotal: 0,
                orderItemProduct: {
                  fabricColor: {},
                  arts: [{}],
                },
              },
            ]}
            buttonIsDisabled={true}
          />

          <NewOrderModal
            isLoading={isOpenOrderLoading}
            onCreateOrder={companyId => {
              createReorderItem(companyId, []);
            }}
            companies={filteredCompanies}
            onClose={() => {
              navigateTo("/");
            }}
          />
        </div>
      </PageLayout>
    );
  }
}

OrderCartNew.propTypes = {
  isDisabled: PropTypes.func,
  createReorderItem: PropTypes.func,
  fetchCurrentOpenOrders: PropTypes.func,
  fetchCompanies: PropTypes.func,
  navigateTo: PropTypes.func,
  companies: PropTypes.array,
  isOpenOrderLoading: PropTypes.bool,
  openOrders: PropTypes.array,
  paymentTerms: PropTypes.array,
};

export default OrderCartNew;
