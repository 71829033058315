import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

class Pagination extends Component {
  _handleChangePage = val => {
    this.props.getData({
      page: val.selected + 1,
    });
  };
  _renderPreviousLabel() {
    if (this.props.currentPage <= 0) {
      return (
        <img
          src="/assets/imgs/icons/Back-disabled.svg"
          className="pagination_icon pagination_icon--left"
        />
      );
    } else {
      return (
        <img
          src="/assets/imgs/icons/Back.svg"
          className="pagination_icon pagination_icon--left"
        />
      );
    }
  }
  _renderNextLabel() {
    const perPage = this.props.perPage || 7;

    if (this.props.currentPage >= Math.ceil(this.props.count / perPage) - 1) {
      return (
        <img
          src="/assets/imgs/icons/next-disabled.svg"
          className="pagination_icon pagination_icon--right"
        />
      );
    } else {
      return (
        <img
          src="/assets/imgs/icons/next.svg"
          className="pagination_icon pagination_icon--right"
        />
      );
    }
  }
  _renderDots() {
    return <span>...</span>;
  }
  render() {
    const {perPage, count, currentPage} = this.props;
    const nextPerPage = perPage || 7;
    return (
      <ReactPaginate
        pageCount={Math.ceil(count / nextPerPage)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        nextLabel={this._renderNextLabel()}
        previousLabel={this._renderPreviousLabel()}
        disableInitialCallback={true}
        onPageChange={this._handleChangePage}
        containerClassName={"pagination"}
        pageClassName={"pagination_number"}
        previousClassName={"pagination_icon pagination_icon--left"}
        nextClassName={"pagination_icon pagination_icon--right"}
        breakClassName={"pagination_dots"}
        previousLinkClassName={"pagination_link"}
        nextLinkClassName={"pagination_link"}
        activeClassName={"pagination--active"}
        forcePage={currentPage}
      />
    );
  }
}

Pagination.propTypes = {
  getData: PropTypes.func,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default Pagination;
