export const hasQueryParamsChanged = (props, prevProps) => {
  const {
    sortBy: prevSortBy,
    sortDirection: prevSortDirection,
    page: prevPage,
    searchTerm: prevSearchTerm,
  } = prevProps;
  const {sortBy, sortDirection, page, searchTerm} = props;

  const hasSortChanged =
    sortBy !== prevSortBy || sortDirection !== prevSortDirection;
  const hasSearchChanged = searchTerm !== prevSearchTerm;
  const hasPageChanged = page !== prevPage;

  return hasSortChanged || hasSearchChanged || hasPageChanged;
};
