class QuoteStorageService {
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async base64ToFile(base64, filename, mimeType) {
    const result = await fetch(base64);
    const blob = await result.blob();
    return new File([blob], filename, {type: mimeType});
  }

  async storeQuote(quote) {
    if (quote.arts) {
      await Promise.all(
        quote.arts.map(async (art, index) => {
          if (art.file) {
            const filename = art.file.name;
            const mimeType = art.file.type;
            const base64 = await this.fileToBase64(art.file);
            quote.arts[index].file = {
              base64,
              filename,
              mimeType,
            };
          }
        })
      );
    }
    localStorage.setItem("new-quote", JSON.stringify(quote));
  }

  async loadQuote() {
    const quoteString = localStorage.getItem("new-quote");
    if (!quoteString) return quoteString;
    try {
      const quote = JSON.parse(quoteString);
      if (quote.arts) {
        await Promise.all(
          quote.arts.map(async (art, index) => {
            if (art.file) {
              const {base64, filename, mimeType} = art.file;
              quote.arts[index].file = await this.base64ToFile(
                base64,
                filename,
                mimeType
              );
            }
          })
        );
      }
      return quote;
    } catch (error) {
      return null;
    }
  }

  deleteQuote() {
    localStorage.removeItem("new-quote");
  }

  storeQuoteId(id) {
    localStorage.setItem("quote", id);
  }

  loadQuoteId() {
    return localStorage.getItem("quote");
  }
}

export default QuoteStorageService;
