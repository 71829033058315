import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import RecipientForm from "../../pages/DSOD/components/RecipientForm";

import {openNotification} from "../../../actions/notificationActions";
import {
  updateCompanyContact,
  createCompanyContact,
} from "../../../actions/companyActions";

function mapStateToProps(state) {
  const {geo: {countries, states}} = state;

  return {
    countries,
    states,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openNotification: bindActionCreators(openNotification, dispatch),
    createCompanyContact: bindActionCreators(createCompanyContact, dispatch),
    updateCompanyContact: bindActionCreators(updateCompanyContact, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipientForm)
);
