import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  FORGOT_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_ERROR,
  SELECT_COMPANY_REQUEST,
  SELECT_COMPANY_SUCCESS,
  SELECT_COMPANY_ERROR,
  OPEN_ERROR_MESSAGE,
} from "../constants/actionTypes";
import initialState from "./initialState";

export default function authReducer(
  state = initialState.auth,
  {type, payload, error}
) {
  switch (type) {
  case AUTH_REQUEST:
    return {
      ...state,
      isAuthenticated: false,
      user: "",
      email: "",
    };
  case AUTH_SUCCESS:
    return {
      ...state,
      isAuthenticated: true,
      id: payload.id,
      user: payload.name,
      email: payload.email,
      isInternal: payload.isInternal,
      companies: payload.companies,
      selectedCompany: payload.selectedCompany,
      selectedCompanyName: payload.selectedCompanyName,
      selectedCompanyCustomerMessage: payload.selectedCompanyCustomerMessage,
    };
  case AUTH_ERROR:
    return {
      ...state,
      isAuthenticated: false,
      user: "",
      email: "",
    };
  case LOGOUT_SUCCESS:
    return {
      ...initialState.auth,
      isLoggedOut: true,
    };
  case FORGOT_REQUEST:
    return {
      ...state,
      loading: true,
      error_message: "",
      success_message: "",
    };
  case FORGOT_SUCCESS:
    return {
      ...state,
      loading: false,
      success_message: payload.success_message,
    };
  case FORGOT_ERROR:
    return {
      ...state,
      loading: false,
      error: error,
      error_message: payload.error_message,
    };
  case LOGIN_REQUEST:
    return {
      ...state,
      loading: true,
      error_message: "",
      success_message: "",
    };
  case LOGIN_SUCCESS:
    return {
      ...state,
      token: payload.token,
      goto_order: payload.goto_order,
      isLoggedOut: false,
      loading: false,
    };
  case LOGIN_ERROR:
    return {
      ...state,
      loading: false,
      error: error,
      error_message: payload.error_message,
    };
  case RESET_REQUEST:
    return {
      ...state,
      success_message: "",
      error_message: "",
      loading: true,
    };
  case RESET_SUCCESS:
    return {
      ...state,
      loading: false,
      success_message: payload.success_message,
      error_message: "",
    };
  case RESET_ERROR:
    return {
      ...state,
      loading: false,
      error: error,
      success_message: "",
      error_message: payload.error_message,
    };
  case SELECT_COMPANY_REQUEST:
    return {
      ...state,
      error: error,
    };
  case SELECT_COMPANY_SUCCESS:
    return {
      ...state,
      error: error,
      selectedCompany: payload.company_id,
      selectedCompanyName: payload.name,
      selectedCompanyCustomerMessage: payload.customerMessage,
    };
  case SELECT_COMPANY_ERROR:
    return {
      ...state,
      error: error,
      error_message: payload.error_message,
    };
  case OPEN_ERROR_MESSAGE:
    return {
      ...state,
      openMessage: true,
      textMessage: payload ? payload.error : error,
      typeMessage: "error",
      isLoading: false,
    };
  default:
    return state;
  }
}
