import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import withPagination from "../../modules/withPagination";
import withSorting from "../../modules/withSorting";
import * as actions from "../../../actions/companyActions";

import CompaniesIndexPage from "../../pages/Companies/CompaniesIndexPage";

class CompaniesIndex extends Component {
  componentWillMount() {
    if (!this.props.isInternal) {
      this.props.history.push(`/companies/${this.props.selectedCompany}`);
    }
  }
  render() {
    return <CompaniesIndexPage {...this.props} />;
  }
}

CompaniesIndex.propTypes = {
  companies: PropTypes.array,
  fetchCompanies: PropTypes.func,
  fetchCompany: PropTypes.func,
  searchCompanies: PropTypes.func,
  isInternal: PropTypes.bool,
  selectedCompany: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    companies: state.companies.companies,
    companiesCount: state.companies.companiesCount,
    userCount: state.companies.userCount,
    currentPage: state.companies.currentPage,
    isLoading: state.companies.isLoading,
    isInternal: state.auth.isInternal,
    selectedCompany: state.auth.selectedCompany,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanies: bindActionCreators(actions.fetchCompanies, dispatch),
    searchCompanies: bindActionCreators(actions.searchCompanies, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
  };
}

export default withSorting(
  withPagination(connect(mapStateToProps, mapDispatchToProps)(CompaniesIndex))
);
