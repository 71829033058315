import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ReorderItemCard from "../../../pages/Reorders/components/ReorderItemCard";
import {openNotification} from "../../../../actions/notificationActions";
import {
  updateOrderItem,
  archiveOrderItem,
} from "../../../../actions/orderActions";
import {
  updateShipmentItem,
  createShipmentItem,
  deleteShipmentItem,
} from "../../../../actions/shipmentActions";

function mapStateToProps(state) {
  const {orders: {isLoading}, shipment: {shipments}} = state;

  return {
    isLoading,
    shipments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrderItem: bindActionCreators(updateOrderItem, dispatch),
    archiveOrderItem: bindActionCreators(archiveOrderItem, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    createShipmentItem: bindActionCreators(createShipmentItem, dispatch),
    deleteShipmentItem: bindActionCreators(deleteShipmentItem, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReorderItemCard)
);
