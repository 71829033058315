import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontIcon from "material-ui/FontIcon";

import withStyles from "../../../shared/WithStyles";
import QuoteLayout from "../../QuoteLayout";
import Typography from "../../../shared/Typography";
import QuoteFooter from "../QuoteFooter";

class QuoteComplete extends Component {
  componentDidMount() {
    const {store, fetchQuote} = this.props;
    const id = store.loadQuoteId();
    fetchQuote(id);
  }

  renderDone = () => (
    <Typography
      variant="h5"
      className="flex align-items-end padding-top-8 padding-bottom-8"
      color="secondary.light"
      style={this.pageStyle.bold}
    >
      <FontIcon
        className="material-icons"
        style={{
          color: "#1D9ABB",
          fontSize: 26,
        }}
      >
        check
      </FontIcon>
      Done!
    </Typography>
  );

  getStep1Text = () => {
    return "Thank you! Please expect a prompt emailed response with any questions or a link proceeding to the next step.";
  };

  getStep2Text = () => {
    return (
      <React.Fragment>
        Thanks for your initial approval!<br /> We’ll digitize your art and get
        back to you with any questions/observations, a print-out of your
        digitized art, the final numbers, mock-ups and a link to proceed with
        further options.
      </React.Fragment>
    );
  };

  getStep3Text = () => {
    const {quote} = this.props;
    if (quote && quote.companyId) {
      return "Thank you for this work! We’ll complete some details and create your order cart. You will receive an email with link to finalize details, add recipient(s), and confirm order placement.";
    }
    return "Great Job!  You’re all set!  The ball is in our court - you will hear back from us shortly with access to your account and order details, including further proofing if applicable.";
  };

  getStep3Title = () => {
    const {quote} = this.props;
    if (!quote) return 3;
    return quote.noAdornment || quote.noNewArt ? 2 : 3;
  };

  showStep2 = () => {
    const {quote} = this.props;
    if (!quote) return false;
    return !quote.noAdornment && !quote.noNewArt;
  };

  isStep2Complete = () => {
    const {quote} = this.props;
    return quote && quote.isApproved;
  };

  isStep3Complete = () => {
    const {quote} = this.props;
    if (!quote) return;
    const {status} = quote;
    return status === "art_approved";
  };

  get pageTitle() {
    return "Quote Requested!";
  }

  get pageSubtitle() {
    return "We'll get back to you shortly via email.";
  }

  get pageStyle() {
    return {
      wrapper: {
        padding: 41,
      },
      headerLogo: {
        width: 150,
        marginTop: 7,
      },
      title: {
        marginTop: 23,
        marginBottom: 16,
      },
      subtitle: {
        margin: 16,
      },
      bold: {
        fontWeight: "bold",
      },
      steps: {
        padding: 10,
      },
    };
  }

  render() {
    const {quote, isAuthenticated, user, isInternal, logout} = this.props;
    return (
      <QuoteLayout
        showMainHeader={isAuthenticated}
        showSidebar={false}
        quote={quote}
        user={user}
        isInternal={isInternal}
        subHeaderText={
          <img
            src="/assets/imgs/ChillNGo-white.png"
            style={this.pageStyle.headerLogo}
          />
        }
        withTopMargin={false}
        logout={logout}
      >
        <div className="flex">
          <div
            className="flex flex-column flex-center margin-auto"
            style={this.pageStyle.wrapper}
          >
            <Typography variant="h2" style={this.pageStyle.title}>
              {this.pageTitle}
            </Typography>
            <Typography
              variant="h5"
              color="secondary.main"
              style={{...this.pageStyle.subtitle, ...this.pageStyle.bold}}
            >
              Next steps:
            </Typography>
            <div
              className="steps flex margin-bottom-80"
              style={this.pageStyle.steps}
            >
              <div
                className={
                  "step flex flex-column flex-center text-center padding-40 active-step"
                }
              >
                <Typography
                  variant="h2"
                  color="secondary.main"
                  className="padding-bottom-8"
                  style={this.pageStyle.bold}
                >
                  1
                </Typography>
                <Typography
                  variant="h5"
                  className="padding-top-8 padding-bottom-8"
                >
                  {this.getStep1Text()}
                </Typography>
                {this.renderDone()}
              </div>
              {this.showStep2() && (
                <div
                  className={classNames(
                    "step flex flex-column flex-center text-center padding-40",
                    {
                      "active-step": this.isStep2Complete(),
                    }
                  )}
                  style={this.pageStyle.step}
                >
                  <Typography
                    variant="h2"
                    color="secondary.main"
                    className="padding-bottom-8"
                    style={this.pageStyle.bold}
                  >
                    2
                  </Typography>
                  <Typography
                    variant="h5"
                    className="padding-top-8 padding-bottom-8"
                  >
                    {this.getStep2Text()}
                  </Typography>
                  {this.isStep2Complete() && this.renderDone()}
                </div>
              )}
              <div
                className={classNames(
                  "step flex flex-column flex-center text-center padding-40",
                  {
                    "active-step": this.isStep3Complete(),
                  }
                )}
                style={this.pageStyle.step}
              >
                <Typography
                  variant="h2"
                  color="secondary.main"
                  className="padding-bottom-8"
                  style={this.pageStyle.bold}
                >
                  {this.getStep3Title()}
                </Typography>
                <Typography
                  variant="h5"
                  className="padding-top-8 padding-bottom-8"
                >
                  {this.getStep3Text()}
                </Typography>
                {this.isStep3Complete() && this.renderDone()}
              </div>
            </div>
            <QuoteFooter className="margin-auto" style={{width: "100%"}} />
          </div>
        </div>
      </QuoteLayout>
    );
  }
}

QuoteComplete.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.string,
  isInternal: PropTypes.bool,
  store: PropTypes.object,
  quote: PropTypes.object,
  styles: PropTypes.object,
  fetchQuote: PropTypes.func,
  logout: PropTypes.func,
};

export default withStyles(QuoteComplete);
