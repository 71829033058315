import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import FormattedPhone from "../../shared/FormattedPhone";

class UsersTable extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    isInternal: PropTypes.bool.isRequired,
    selectedCompany: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  _companyNames(user) {
    const {selectedCompany, isInternal} = this.props;
    const {companies} = user;
    if (!isInternal && selectedCompany) {
      return (
        <Link
          key={selectedCompany.id}
          to={`/companies/${selectedCompany.id}`}
          className="no-link-styles"
        >
          {selectedCompany.name}
        </Link>
      );
    }
    if (!companies) return;

    return companies.map(company => {
      return (
        <Link
          key={company.id}
          to={`/companies/${company.id}`}
          className="no-link-styles"
        >
          {company.name}
        </Link>
      );
    });
  }

  _renderRow(user, index) {
    let {recipient} = user;
    if (!recipient) {
      recipient = {...user};
    }

    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: "20%",
          }}
        >
          <Link to={`/users/${user.id}`} className="no-link-styles">
            {recipient.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: "30%",
          }}
        >
          {this._companyNames(user)}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={recipient.emailAddress}
          customStyles={{
            width: "15%",
          }}
        >
          {recipient.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "15%",
          }}
        >
          <FormattedPhone
            phoneNumber={recipient.phoneNumber}
            countryCode={recipient.country && recipient.country.code}
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "20%",
          }}
        >
          {recipient.address1}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "Name",
        customStyles: {
          width: "20%",
        },
      },
      {
        columnContent: "Companies",
        customStyles: {
          width: "30%",
        },
      },
      {
        columnContent: "E-mail",
        customStyles: {
          width: "15%",
        },
      },
      {
        columnContent: "Phone",
        customStyles: {
          width: "15%",
        },
      },
      {
        columnContent: "Address",
        customStyles: {
          width: "20%",
        },
      },
    ];
    const {users, isLoading} = this.props;

    return (
      <CNGTable
        tableHeader={header}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {users.map((user, index) => {
          return this._renderRow(user, index);
        })}
      </CNGTable>
    );
  }
}

export default UsersTable;
