import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "./ChillButton";

import Dialog from "material-ui/Dialog";

export default class ConfirmationModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    height: PropTypes.number,
    confirmText: PropTypes.string,
    disabledConfirm: PropTypes.bool,
    cancelText: PropTypes.string,
  };

  render() {
    const {
      disabledConfirm,
      title,
      content,
      onConfirm,
      onDismiss,
      open,
      height,
      confirmText,
      cancelText,
    } = this.props;
    return (
      <Dialog
        modal={false}
        open={open}
        contentStyle={{...ConfirmationModalStyles, height}}
        contentClassName={"archiveModalStyles"}
        bodyStyle={{padding: 0}}
        overlayClassName={"dialog-overlay"}
      >
        <h3 className="archiveModal_title">{title || "Confirm this action"}</h3>
        {content ? (
          <div
            className="flex flex-center justify-content-center"
            style={{marginBottom: "40px"}}
          >
            <p>{content}</p>
          </div>
        ) : null}
        <ChillButton
          isDisabled={disabledConfirm}
          onClick={() => {
            onConfirm && onConfirm();
          }}
          className="btn-1 archiveModal_btn"
          customStyles={{width: "300px"}}
          name={confirmText || "CONTINUE"}
        />
        <span
          onClick={() => {
            onDismiss && onDismiss();
          }}
          className="archiveModal_cancel"
        >
          {cancelText || "CANCEL"}
        </span>
      </Dialog>
    );
  }
}

const ConfirmationModalStyles = {
  height: 328,
  width: 545,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
};
