import lodash from "lodash";
import {PRODUCT_ORDER_ITEM_ID} from "../constants/orderItemType";

export default class OrderSummary {
  constructor(orderItems, shipment = {}) {
    this.orderItems = orderItems;
    this.shipment = shipment;
  }

  get totalShipmentCost() {
    const items = lodash.defaultTo(lodash.get(this.shipment, "items"), []);

    return (
      items.reduce(
        (total, shipment) => total + Number(shipment.shipmentCost),
        0
      ) + Number(lodash.get(this.shipment, "shipmentCost", 0))
    );
  }

  filterProductItems(orderItems) {
    return orderItems.filter(orderItem => {
      return (
        lodash.get(orderItem, "orderItemTypeId", PRODUCT_ORDER_ITEM_ID) ===
        PRODUCT_ORDER_ITEM_ID
      );
    });
  }

  get totalItems() {
    const {orderItems} = this;
    return this.filterProductItems(orderItems).reduce(
      (total, orderItem) => total + Number(orderItem.orderQuantity),
      0
    );
  }

  get totalShipped() {
    const {orderItems} = this;
    return this.filterProductItems(orderItems).reduce(
      (total, orderItem) => total + Number(orderItem.quantityToShip),
      0
    );
  }

  get totalToInventory() {
    const {orderItems} = this;
    return this.filterProductItems(orderItems).reduce(
      (total, orderItem) => total + Number(orderItem.quantityToInventory),
      0
    );
  }

  get totalUnitCost() {
    const {orderItems} = this;
    return orderItems.reduce(
      (total, orderItem) => total + Number(orderItem.totalUnitCost),
      0
    );
  }

  get totalDiscount() {
    const {orderItems} = this;
    return orderItems.reduce((total, orderItem) => {
      return total + Number(orderItem.totalDiscount);
    }, 0);
  }

  get totalAdornmentCost() {
    const {orderItems} = this;
    return orderItems.reduce(
      (total, orderItem) => total + Number(orderItem.totalAdornmentCost),
      0
    );
  }

  get totalNetUnitCost() {
    const {orderItems} = this;
    return orderItems.reduce(
      (total, orderItem) => total + Number(orderItem.totalUnitCost),
      0
    );
  }

  get totalOrder() {
    const {orderItems = []} = this;
    if (!orderItems) return 0;
    return orderItems.reduce(
      (total, orderItem) => total + Number(orderItem.orderTotal),
      0
    );
  }

  get totalOrderWithShipments() {
    return this.totalOrder + this.totalShipmentCost;
  }
}
