import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SetUpCostItemCard from "./SetUpCostItemCard";
import {openNotification} from "../../../../../actions/notificationActions";
import {
  archiveOrderItem,
  updateOrderItem,
} from "../../../../../actions/orderActions";
import {fetchDigitalizationTypePrices} from "../../../../../actions/digitalizacionTypePriceActions";

function mapStateToProps(state) {
  const {
    orders: {isLoading},
    digitalizationTypePrices: {digitalizationTypePrices},
  } = state;

  return {
    isLoading,
    digitalizationTypePrices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    archiveOrderItem: bindActionCreators(archiveOrderItem, dispatch),
    updateOrderItem: bindActionCreators(updateOrderItem, dispatch),
    fetchDigitalizationTypePrices: bindActionCreators(
      fetchDigitalizationTypePrices,
      dispatch
    ),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetUpCostItemCard)
);
