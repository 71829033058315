import React, {Component} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";
import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";

import ListComma from "../../../shared/ListComma";
import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import CNGTableRow from "../../../shared/CNGTable/CNGTableRow";
import CNGTableRowColumn from "../../../shared/CNGTable/CNGTableRowColumn";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
  thFirstLinkStyle,
  tableRowStyle,
} from "../../../../styles/components/tableStyles";

export default class CompanyIndexTable extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    fetchCompanies: PropTypes.func,
    sortColumns: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  _renderRow(company, index) {
    return (
      <CNGTableRow key={index} styles={tableRowStyle}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: 300,
          }}
        >
          <Link to={`/companies/${company.id}`} className="no-link-styles">
            {company.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          {company.brands &&
            company.brands.map((brand, index) => {
              return (
                <ListComma
                  key={index}
                  index={index}
                  len={company.brands.length}
                  text={brand.name}
                />
              );
            })}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderUserCompanyTableHeader() {
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          <TableRowColumn
            style={thFirstLinkStyle}
            className="CNG-th pointer"
            width={300}
          >
            <div onClick={() => this.props.sortColumns()}>
              COMPANY NAME
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            BRANDS
          </TableRowColumn>
        </TableRow>
      </TableHeader>
    );
  }

  render() {
    const {companies, sortColumns, isLoading} = this.props;
    return (
      <CNGTableCustom
        tableStyle={tableStyle}
        displayRowCheckbox={false}
        isLoading={isLoading}
        sort={() => sortColumns()}
        className="CNGTable CNGTable--removeHeaderHeight"
      >
        {this._renderUserCompanyTableHeader()}
        <TableBody displayRowCheckbox={false}>
          {companies &&
            companies.map((row, index) => {
              return this._renderRow(row, index);
            })}
        </TableBody>
      </CNGTableCustom>
    );
  }
}
