import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchDigitalizationTypePricesRequest = () =>
  makeCommonAction(types.FETCH_DIGITALIZATION_TYPE_PRICES_REQUEST);
const fetchDigitalizationTypePricesSuccess = payload =>
  makeCommonAction(types.FETCH_DIGITALIZATION_TYPE_PRICES_SUCCESS, payload);
const fetchDigitalizationTypePricesError = error =>
  makeCommonAction(types.FETCH_DIGITALIZATION_TYPE_PRICES_ERROR, error, true);

export function fetchDigitalizationTypePrices(options) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl("digitalization_type_prices", null, {
      sort: "complexity_level",
      sortDirection: "ASC",
      ...options,
    }),
    fetchOptions: {method: "GET"},
    requestFunc: fetchDigitalizationTypePricesRequest,
    successFunc: fetchDigitalizationTypePricesSuccess,
    errorFunc: fetchDigitalizationTypePricesError,
    error_message: "Error fetching digitalization type prices",
  });
}
