import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {fetchOrderItemProducts} from "../../../../../actions/productActions";
import {createReorderItem} from "../../../../../actions/orderActions";
import ProductsPage from "./ProductsPage";

function mapStateToProps(state) {
  const {
    auth: {
      isInternal,
      selectedCompany: selectedCompanyId,
      selectedCompanyName: selectedCompanyName,
    },
    products: {orderItemProducts, isLoading},
  } = state;

  return {
    selectedCompanyName,
    selectedCompanyId,
    orderItemProducts,
    isInternal,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrderItemProducts: bindActionCreators(
      fetchOrderItemProducts,
      dispatch
    ),
    createReorderItem: bindActionCreators(createReorderItem, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductsPage)
);
