import React from "react";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";
import FontIcon from "material-ui/FontIcon";
import withStyles from "./WithStyles";

const CheckboxWithTooltip = ({label, title, checked, onCheck}) => (
  <Checkbox
    labelStyle={{whiteSpace: "nowrap"}}
    label={
      <React.Fragment>
        {label}
        <FontIcon
          className="material-icons"
          title={title}
          style={{
            zIndex: 2,
            marginLeft: 8,
            color: "#0c5371",
            fontSize: 18,
          }}
        >
          help
        </FontIcon>
      </React.Fragment>
    }
    checked={checked}
    onCheck={onCheck}
  />
);

CheckboxWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default withStyles(CheckboxWithTooltip);
