import React, {Component} from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import DSODDetailPage from "../../pages/DSOD/DSODDetailPage";
import {openNotification} from "../../../actions/notificationActions";
import {fetchCompany} from "../../../actions/companyActions";
import {fetchShipments} from "../../../actions/shipmentActions";
import {fetchStates, fetchCountries} from "../../../actions/geoActions";
import {
  fetchOrder,
  fetchOrderItems,
  fetchOrderStatus,
  updateOrder,
  revertOrder,
} from "../../../actions/orderActions";

class DSODDetail extends Component {
  static propTypes = {
    fetchCountries: PropTypes.func,
    fetchStates: PropTypes.func,
    isLoading: PropTypes.bool,
    openNotification: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    fetchOrderStatus: PropTypes.func.isRequired,
    fetchOrderItems: PropTypes.func.isRequired,
    fetchShipments: PropTypes.func.isRequired,
    updateOrder: PropTypes.func,
    orderStatus: PropTypes.array,
    orderItems: PropTypes.array,
    fetchCompany: PropTypes.func.isRequired,
    states: PropTypes.array,
    countries: PropTypes.array,
    match: PropTypes.object.isRequired,
    company: PropTypes.object,
    order: PropTypes.object,
    shipments: PropTypes.array,
    isInternal: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    revertOrder: PropTypes.func.isRequired,
  };

  state = {};

  componentWillMount() {
    this._fetchOrderData();
  }

  componentDidUpdate() {
    this._redirectToOrder();
  }

  _redirectToOrder() {
    const {
      order,
      match: {params: {company_id: companyId, id: orderId}},
      history,
    } = this.props;

    if (order.id && !order.isDsod) {
      history.replace(`/companies/${companyId}/orders/${orderId}`);
    }
  }

  _fetchOrderData() {
    const {
      fetchCompany,
      fetchOrder,
      fetchOrderStatus,
      fetchOrderItems,
      fetchShipments,
      fetchStates,
      fetchCountries,
      match: {params: {company_id: companyId, id: orderId}},
    } = this.props;

    fetchCompany(companyId);
    fetchCountries();
    fetchStates();
    fetchOrder(orderId);
    fetchOrderStatus();
    fetchOrderItems(orderId);
    fetchShipments(orderId);
  }

  handleUpdateOrderStatus = async statusId => {
    const {updateOrder, order, openNotification, fetchOrder} = this.props;
    await updateOrder(order.id, {orderStatusId: statusId}, null, () => {
      fetchOrder(order.id);
      openNotification("Order changes successfully saved");
    });
  };

  render() {
    const {
      company,
      order,
      orderStatus,
      orderItems,
      shipments,
      history,
      location,
      states,
      countries,
      isInternal,
      revertOrder,
      isLoading,
      fetchOrder,
      match: {params: {company_id: companyId, id: orderId}},
    } = this.props;

    return (
      <DSODDetailPage
        revertOrder={revertOrder}
        states={states}
        countries={countries}
        onUpdateOrderStatus={this.handleUpdateOrderStatus}
        company={company}
        order={order}
        orderStatus={orderStatus}
        orderItems={orderItems}
        companyId={companyId}
        orderId={orderId}
        shipments={shipments}
        history={history}
        location={location}
        isInternal={isInternal}
        isLoading={isLoading}
        fetchOrder={fetchOrder}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    companies: {company},
    geo: {states, countries},
    orders: {order, orderItems, orderStatus, isLoading},
    shipment: {shipments},
  } = state;

  return {
    states,
    countries,
    company,
    order,
    orderItems,
    orderStatus,
    shipments,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    fetchOrderItems: bindActionCreators(fetchOrderItems, dispatch),
    fetchOrderStatus: bindActionCreators(fetchOrderStatus, dispatch),
    fetchShipments: bindActionCreators(fetchShipments, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    revertOrder: bindActionCreators(revertOrder, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchStates: bindActionCreators(fetchStates, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DSODDetail)
);
