import React, {Component} from "react";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
} from "material-ui/Table";

import {isObject} from "util";
import ContentLoader from "../ContentLoader";

class CNGTable extends Component {
  _renderHeader() {
    return (
      <TableRow
        displayBorder={false}
        style={{
          height: 16,
        }}
      >
        {this.props.tableHeader.map((headerItem, index) => {
          let columnStyle =
            index == 0
              ? {
                ...thStyle,
                paddingLeft: 33,
              }
              : thStyle;
          let content = headerItem;
          if (isObject(headerItem)) {
            const {customStyles, columnContent} = headerItem;
            columnStyle = {
              ...columnStyle,
              ...customStyles,
            };
            content = columnContent;
          }

          return (
            <TableHeaderColumn
              key={index}
              style={columnStyle}
              className="CNG-th"
            >
              {content}
            </TableHeaderColumn>
          );
        })}
      </TableRow>
    );
  }
  _renderRow(rowData) {
    return (
      <TableRow displayBorder={false} style={rowStyles}>
        {rowData.map((data, index) => {
          return (
            <TableRowColumn key={index} style={tbrcStyles}>
              {data}
            </TableRowColumn>
          );
        })}
      </TableRow>
    );
  }
  _renderBody() {
    return this.props.tableRows.map(row => this._renderRow(row));
  }
  render() {
    const {isLoading, tableBodyStyle} = this.props;
    return (
      <ContentLoader
        zIndex={1}
        isLoading={isLoading}
        customContainerStyles={{
          top: "34px",
          minHeight: "110px",
        }}
      >
        <div
          style={{
            minHeight: "133px",
          }}
        >
          <Table
            className={this.props.className}
            style={tableStyles}
            bodyStyle={tableBodyStyle}
            onRowSelection={this.props.onRowSelection}
            multiSelectable={this.props.multiSelectable || false}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={this.props.displayRowCheckbox}
              displayRowCheckbox={this.props.displayRowCheckbox}
              displayBorder={false}
              style={{
                borderWidth: "0",
                height: 16,
                lineHeight: "16px",
              }}
            >
              {this._renderHeader()}
            </TableHeader>

            <TableBody
              displayRowCheckbox={this.props.displayRowCheckbox}
              deselectOnClickaway={false}
            >
              {this.props.tableRows ? this._renderBody() : this.props.children}
            </TableBody>
          </Table>
        </div>
      </ContentLoader>
    );
  }
}

CNGTable.propTypes = {
  customTableHeader: PropTypes.func,
  displayRowCheckbox: PropTypes.bool,
  tableHeader: PropTypes.array,
  tableStyle: PropTypes.object,
  tableBodyStyle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
  highlight: PropTypes.bool,
  isOrderTotal: PropTypes.bool,
  customStyles: PropTypes.object,
  className: PropTypes.string,
  tbrcStyles: PropTypes.object,
  tableRows: PropTypes.array,
  onRowSelection: PropTypes.func,
  multiSelectable: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const tableStyles = {
  borderCollapse: "separate",
  borderSpacing: "0 8px",
  minHeight: "82px",
};
const rowStyles = {
  height: 66,
  borderRadius: 4,
  backgroundColor: "rgba(189, 198, 207, 0.07)",
};
const thStyle = {
  height: 16,
  fontWeight: "bold",
  color: "#929DA8",
  fontSize: 12,
};

const tbrcStyles = {
  color: "#747D86",
  fontSize: 14,
  lineHeight: "20px",
  height: 18,
  verticalAlign: "bottom",
};

export default CNGTable;
