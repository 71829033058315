import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import ContactInfo from "./Components/ContactInfo";
import Divider from "../../shared/Divider";
import ContactDetailHeader from "./Components/ContactDetailHeader";

export default class ContactDetailPage extends Component {
  static propTypes = {
    navigateTo: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    company: PropTypes.object,
    companyContact: PropTypes.object,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    history: PropTypes.object,
    router: PropTypes.object,
  };

  _middleQueryParams = () => {
    const {router} = this.props;
    if (!router || !router.previousSearch) return "";
    return router.previousSearch;
  };

  companyUrl = () =>
    this.props.company && `/companies/${this.props.company.id}?tab=recipients`;

  render() {
    const {
      company,
      states,
      countries,
      isLoading,
      isInternal,
      companyContact,
      history,
    } = this.props;

    return (
      <PageLayout isLoading={isLoading} loaderEnable={true} smallTop={true}>
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          disableRootLink={!isInternal}
          middle={lodash.get(company, "name")}
          middleLink={lodash.get(company, "id")}
          middletwo="Address Book"
          middletwoLink=""
          pageName={companyContact.name}
        />
        <Card>
          <div>
            <ContactDetailHeader
              cancelLink={this.companyUrl() || "/"}
              company={company}
              companyContact={companyContact}
              history={history}
            />
            <Divider marginBottom={24} marginTop={24} />
            <ContactInfo
              states={states}
              countries={countries}
              {...companyContact}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
