import React, {Component} from "react";
import PropTypes from "prop-types";

export default class ToggleIcon extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    initialValue: PropTypes.bool,
    className: PropTypes.string,
  };

  state = {
    toggle: false,
  };

  componentDidMount() {
    const {initialValue} = this.props;
    if (initialValue) {
      this.setState({toggle: initialValue});
    }
  }

  handleClick = () => {
    const {onChange} = this.props;
    this.setState({toggle: !this.state.toggle});
    if (onChange) onChange();
  };

  render() {
    const {toggle} = this.state;
    const {className} = this.props;

    return (
      <div
        onClick={this.handleClick}
        className={`toggle-icon pointer ${
          toggle ? "toggle-icon--toggled" : null
        } ${className}`}
      />
    );
  }
}
