import React, {Component} from "react";
import PropTypes from "prop-types";
import CircularProgress from "material-ui/CircularProgress";

import Dialog from "material-ui/Dialog";
import CompanySelect from "../../Users/components/CompanySelect";

export default class NewDSODModal extends Component {
  static propTypes = {
    onCreateDSOD: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
  };
  state = {
    companyId: null,
    open: true,
  };

  handleClose() {
    this.props.onClose();
    this.setState({open: false});
  }

  handleSelectRefUpdated(el) {
    if (!el) return;
    const button = el.querySelector("button");
    button.focus();
  }

  render() {
    const {companies} = this.props;
    return (
      <Dialog
        modal={false}
        open={this.state.open}
        contentStyle={NewDSODModalStyles}
        contentClassName={"archiveModalStyles"}
        bodyStyle={{padding: 0}}
        overlayClassName={"dialog-overlay"}
      >
        <h3 className="archiveModal_title">Choose Company to start DSOD</h3>
        <div
          className="flex flex-center justify-content-center"
          style={{paddingTop: "40px", marginLeft: "32px"}}
        >
          <CompanySelect
            id={this.state.companyId}
            name="companyId"
            companies={companies}
            handleChangeCompany={(e, index, value) => {
              this.setState({companyId: value});
            }}
            trashCan={false}
            updateRef={el => this.handleSelectRefUpdated(el)}
          />
        </div>
        {this.props.isLoading ? (
          <div className="flex flex-center justify-content-center">
            <div className="">
              <CircularProgress size={20} thickness={4} />
            </div>
            <span>Loading...</span>
          </div>
        ) : (
          <React.Fragment>
            <button
              disabled={!this.state.companyId}
              onClick={() => {
                this.props.onCreateDSOD(parseInt(this.state.companyId));
              }}
              className="btn-1 archiveModal_btn"
              style={{width: "300px"}}
            >
              CONTINUE
            </button>
            <span
              onClick={() => this.handleClose()}
              className="archiveModal_cancel"
            >
              CANCEL
            </span>
          </React.Fragment>
        )}
      </Dialog>
    );
  }
}

const NewDSODModalStyles = {
  height: 328,
  width: 545,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
};
