const JSONAPIDeserializer = require("jsonapi-serializer").Deserializer;
import changeCaseKeys from "change-case-keys";

const deserializeOptions = {
  keyForAttribute: "camelCase",
};

const deserialize = async data => {
  try {
    const meta = data.meta ? changeCaseKeys(data.meta, "camelize") : {};
    const deserializedData = await new JSONAPIDeserializer(
      deserializeOptions
    ).deserialize(data);

    // Include meta data
    if (Array.isArray(deserializedData)) {
      return {
        data: deserializedData,
        meta: meta,
      };
    }

    return deserializedData;
  } catch (e) {
    throw new Error("This is not a valid json api format", e.message);
  }
};

export default deserialize;
