import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import _get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import ValidableTextField from "../../../shared/ValidableTextField";
import LineHeader from "../../../shared/LineHeader";
import RecipientMoreAction from "../../../shared/RecipientMoreAction";
import InventoryChooserModal from "../../../containers/DSOD/InventoryChooserModal";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";
import CNGToolTip from "../../../shared/CNGToolTip";

import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../../../styles/components/formStyles";

import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";
import {CNGTableRow, CNGTableRowColumn} from "../../../shared/CNGTable";

import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
} from "../../../../styles/components/tableStyles";

const style = {
  container: {
    marginTop: "27px",
  },
  blankText: {
    padding: "24px",
    marginTop: "8px",
    textAlign: "center",
    background: "rgba(189,198,207,0.07)",
    color: "#BDC6CF",
  },
};

const MenuItemStyle = {
  color: "#747D86",
  fontSize: 14,
};

export default class RecipientProductsTable extends Component {
  static propTypes = {
    updateDsodItem: PropTypes.func,
    createDsodItem: PropTypes.func,
    products: PropTypes.array,
    dsodItems: PropTypes.array,
    inventoryItems: PropTypes.array,
    inventoryItemsCount: PropTypes.number,
    companyId: PropTypes.number,
    onUpdatedDsodItem: PropTypes.func,
    deleteDsodItem: PropTypes.func,
    activeContacts: PropTypes.array,
    fullDsodItems: PropTypes.array,
    onFetchDsodItems: PropTypes.func,
    canAddItems: PropTypes.bool,
  };

  state = {
    openInventory: false,
    filteredDsodItems: [],
    dsodQuantities: {},
    dsodErrors: {},
  };

  componentWillReceiveProps(nextProps) {
    const {dsodItems} = this.props;
    if (dsodItems !== nextProps.dsodItems) {
      const dsodQuantities = nextProps.dsodItems.reduce((result, dsodItem) => {
        result[dsodItem.id] = dsodItem.orderQuantity;
        return result;
      }, {});
      this.setState({dsodQuantities});
    }
  }

  showProductsChooser(filteredDsodItems) {
    this.setState({openInventory: true, filteredDsodItems});
  }

  matchProductReference = (orderItem, dsodItem) => {
    let validArt = true;
    if (_get(orderItem, "orderItemProduct.art")) {
      validArt =
        Number(orderItem.orderItemProduct.art.id) ===
        Number(dsodItem.artRevisionId);
    }

    return (
      validArt &&
      Number(_get(orderItem, "product.id")) === Number(dsodItem.productId) &&
      Number(_get(orderItem, "fabricColor.id")) ===
        Number(dsodItem.fabricAvailableColorId)
    );
  };

  matchDsodsReference = (firstDsodItem, dsodItem) => {
    return (
      Number(firstDsodItem.artRevisionId) === Number(dsodItem.artRevisionId) &&
      Number(firstDsodItem.productId) === Number(dsodItem.productId) &&
      Number(firstDsodItem.fabricAvailableColorId) ===
        Number(dsodItem.fabricAvailableColorId)
    );
  };

  getInventoryQuantity = dsodItem => {
    const {inventoryItems} = this.props;
    return inventoryItems.reduce((total, inventoryItem) => {
      const {orderItem} = inventoryItem;
      if (this.matchProductReference(orderItem, dsodItem)) {
        return total + inventoryItem.balance;
      }
      return total;
    }, 0);
  };
  handleUpdateOrderItem = async (event, dsodItem) => {
    event.preventDefault();
    const {updateDsodItem, onUpdatedDsodItem} = this.props;
    const inventoryQuantity = this.getInventoryQuantity(dsodItem);
    const quantity =
      this.state.dsodQuantities[dsodItem.id] || dsodItem.orderQuantity;
    if (quantity === Number(dsodItem.orderQuantity)) {
      return;
    }
    const isValidQuantity =
      quantity <= dsodItem.orderQuantity + inventoryQuantity ||
      (inventoryQuantity === 0 && quantity <= dsodItem.orderQuantity);
    if (isValidQuantity) {
      updateDsodItem(dsodItem.companyId, dsodItem.id, {
        orderQuantity: quantity,
      });

      onUpdatedDsodItem && onUpdatedDsodItem(dsodItem);

      return this.setState({
        dsodErrors: {
          ...this.state.dsodErrors,
          [dsodItem.id]: null,
        },
      });
    }

    this.setState({
      dsodErrors: {
        ...this.state.dsodErrors,
        [dsodItem.id]: `must be lower or equal to ${dsodItem.orderQuantity +
          inventoryQuantity}`,
      },
    });
  };

  saveSelectedInvetoryItems = () => {
    this.setState({openInventory: false});
  };

  handleDeleteProduct = async dsodItem => {
    const {
      updateDsodItem,
      deleteDsodItem,
      fullDsodItems,
      onFetchDsodItems,
    } = this.props;

    if (fullDsodItems.length === 1) {
      await updateDsodItem(dsodItem.companyId, dsodItem.id, {
        product_id: null,
        art_id: null,
        order_quantity: 0,
      });
    } else {
      await deleteDsodItem(dsodItem.companyId, dsodItem.id);
    }
    this.setState(
      {
        dsodQuantities: {},
      },
      onFetchDsodItems
    );
  };

  filterDsodItems(recipientId, newDsodItemId) {
    const {fullDsodItems} = this.props;

    return fullDsodItems.find(
      dsodItem =>
        Number(dsodItem.recipientId) === Number(recipientId) &&
        Number(newDsodItemId) === Number(dsodItem.id)
    );
  }

  filterEmptyDsodItems(recipientId) {
    const {fullDsodItems} = this.props;

    return fullDsodItems.find(
      dsodItem =>
        Number(dsodItem.recipientId) === Number(recipientId) &&
        !dsodItem.productId &&
        !dsodItem.artRevisionId &&
        !dsodItem.companyId
    );
  }

  handleMoveProduct = async (selectedRecipient, dsodItem) => {
    const {
      createDsodItem,
      updateDsodItem,
      deleteDsodItem,
      onFetchDsodItems,
    } = this.props;

    const emptyDsodItemsForRecipient = this.filterEmptyDsodItems(
      selectedRecipient.id
    );
    if (emptyDsodItemsForRecipient) {
      await updateDsodItem(dsodItem.companyId, emptyDsodItemsForRecipient.id, {
        ...cloneDeep(dsodItem),
        recipientId: selectedRecipient.id,
      });
    } else {
      const recipientMatchingDsodItem = this.filterDsodItems(
        selectedRecipient.id,
        dsodItem.id
      );

      if (!recipientMatchingDsodItem) {
        await createDsodItem(dsodItem.companyId, {
          ...cloneDeep(dsodItem),
          recipientId: selectedRecipient.id,
        });
      }
    }

    const currentDsodItemHolderItem = this.filterDsodItems(
      dsodItem.recipientId,
      dsodItem.id
    );
    if (currentDsodItemHolderItem) {
      await deleteDsodItem(dsodItem.companyId, dsodItem.id);
    }

    onFetchDsodItems();
  };

  handleCopyProduct = async (item, dsodItem) => {
    const {
      createDsodItem,
      updateDsodItem,
      fullDsodItems,
      onFetchDsodItems,
    } = this.props;

    const emptyDsodItem = fullDsodItems.find(
      dsodItem =>
        dsodItem.recipientId === Number(item.id) && !dsodItem.productId
    );

    if (emptyDsodItem) {
      await updateDsodItem(emptyDsodItem.companyId, emptyDsodItem.id, {
        ...cloneDeep(dsodItem),
        recipientId: item.id,
        orderQuantity: 0,
      });
    } else {
      await createDsodItem(dsodItem.companyId, {
        ...cloneDeep(dsodItem),
        orderQuantity: 0,
        recipientId: item.id,
      });
    }
    onFetchDsodItems();
  };

  _getProductReference = element => {
    return [
      element.artRevisionId,
      element.productId,
      element.fabricAvailableColorId,
    ].toString();
  };

  _getInventoryProductReferences = () => {
    const {inventoryItems} = this.props;

    return inventoryItems.map(inventoryItem => {
      const {orderItem} = inventoryItem;
      return [
        _get(orderItem, "orderItemProduct.art.id"),
        _get(orderItem, "product.id"),
        _get(orderItem, "fabricColor.id"),
      ].toString();
    });
  };

  _renderTableHeader() {
    const theaderStyle = {
      ...thStyle,
      width: 179,
    };
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          <TableRowColumn style={theaderStyle} className="CNG-th">
            ITEM NAME & ID
          </TableRowColumn>
          <TableRowColumn style={theaderStyle} className="CNG-th">
            FABRIC
          </TableRowColumn>
          <TableRowColumn style={theaderStyle} className="CNG-th">
            COLOR
          </TableRowColumn>
          <TableRowColumn style={theaderStyle} className="CNG-th">
            BRAND
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...theaderStyle,
              width: 110,
            }}
            className="CNG-th"
          >
            ART FILE
          </TableRowColumn>
          <TableRowColumn
            style={{
              ...theaderStyle,
              width: 100,
            }}
            className="CNG-th"
          >
            ADD QTY
          </TableRowColumn>
          <TableRowColumn className="CNG-th" />
        </TableRow>
      </TableHeader>
    );
  }

  _renderMenuItems = (contacts, onClick, dsodItem, fullDsodItems) => {
    return contacts.reduce((result, item) => {
      const existingItem = fullDsodItems.find(fullDsodItem => {
        return (
          this.matchDsodsReference(dsodItem, fullDsodItem) &&
          fullDsodItem.recipientId === Number(item.id)
        );
      });
      if (existingItem) return result;

      return [
        ...result,
        <CNGMenuItem
          style={MenuItemStyle}
          primaryText={item.name}
          key={item.id}
          onClick={() => onClick(item, dsodItem)}
        />,
      ];
    }, []);
  };

  componentDidMount() {
    const {dsodItems = []} = this.props;
    if (!dsodItems.length) return;
    const filteredDsodItems = dsodItems.filter(dsodItem => dsodItem.productId);
    const initialValue = filteredDsodItems.reduce((result, dsodItem) => {
      result[dsodItem.id] = dsodItem.orderQuantity;
      return result;
    }, {});

    this.setState({dsodQuantities: initialValue});
  }

  renderQtyField = (dsodItem, dsodErrors) => {
    return (
      <form
        key="quantity_field"
        onSubmit={event => {
          this.handleUpdateOrderItem(event, dsodItem);
        }}
      >
        <ValidableTextField
          type="text"
          name="orderQuantity"
          errorText={dsodErrors[dsodItem.id]}
          inputStyle={{}}
          label={""}
          value={
            this.state.dsodQuantities && this.state.dsodQuantities[dsodItem.id]
          }
          handleChange={(event, value) => {
            event.stopPropagation();
            if (value.length > 0 && !value.match(/^\d+$/gi)) return;
            this.setState({
              dsodQuantities: {
                ...this.state.dsodQuantities,
                [dsodItem.id]: value,
              },
            });
          }}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          onBlur={event => {
            this.handleUpdateOrderItem(event, dsodItem);
          }}
          errorStyle={{
            whiteSpace: "normal",
          }}
          style={{
            ...formStyles.tinySelectStyles,
            marginRight: 32,
            backgroundColor: "rgb(250, 251, 251)",
          }}
        />
      </form>
    );
  };

  _renderRow = dsodItem => {
    if (!dsodItem && !dsodItem.art.artRevision) return;
    const product = _get(dsodItem, "product", {});
    const fabricAvailableColor = _get(dsodItem, "fabricAvailableColor", {});
    const artRevision = _get(dsodItem, "art", {});
    const sourceOrderItem = _get(dsodItem, "sourceOrderItem", {});
    const inventoryHistories = _get(dsodItem, "inventoryHistories", {});
    const brand = _get(dsodItem, "art.artSource.brand", {});
    const {dsodErrors} = this.state;

    if (!fabricAvailableColor) return;
    const {fabric, color} = fabricAvailableColor;
    const {activeContacts, fullDsodItems, companyId} = this.props;
    const filteredActiveContacts = activeContacts.filter(
      contact => dsodItem.recipientId !== Number(contact.id)
    );

    let copyToMenuItems = [];
    let moveToMenuItems = [];
    const dsodItemReference = this._getProductReference(dsodItem);
    const productReferences = this._getInventoryProductReferences();

    if (productReferences.includes(dsodItemReference)) {
      moveToMenuItems = this._renderMenuItems(
        filteredActiveContacts,
        this.handleMoveProduct,
        dsodItem,
        fullDsodItems
      );
      copyToMenuItems = this._renderMenuItems(
        filteredActiveContacts,
        this.handleCopyProduct,
        dsodItem,
        fullDsodItems
      );
    }

    const customStyle = {
      width: 158,
      paddingLeft: 24,
    };
    const {orderItemProduct} = sourceOrderItem;

    const assignment = _get(
      orderItemProduct,
      "orderItemProductAdornmentLocationArtAssignments[0]"
    );

    return (
      <CNGTableRow key={dsodItem.id}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            ...customStyle,
            paddingTop: 16,
          }}
        >
          <CNGToolTip
            parentRef={`assignment-${_get(assignment, "id")}`}
            style={{width: 250}}
            element={assignment}
          >
            <Link
              to={`/companies/${companyId}/products/${orderItemProduct.id}`}
              className="reset-link-styles"
              title={_get(product, "productName")}
            >
              {_get(product, "productName")}
            </Link>
          </CNGToolTip>
          <div
            className="table-subtext"
            style={{
              paddingBottom: 16,
            }}
          >
            Item #{_get(product, "itemNumber")}{" "}
            {inventoryHistories &&
              inventoryHistories.map(inventoryHistory => (
                <div key={inventoryHistory.id}>
                  CNG #{" "}
                  {_get(
                    inventoryHistory,
                    "inventoryItem.order.cngReferenceNumber"
                  )}
                </div>
              ))}
          </div>
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={customStyle}>
          {_get(fabric, "name")}
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={customStyle}>
          {_get(color, "name")}
        </CNGTableRowColumn>
        <CNGTableRowColumn highlight={true} customStyles={customStyle}>
          <Link to={`/companies/${companyId}/brands/${_get(brand, "id")}`}>
            {_get(brand, "name")}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: 100,
            paddingLeft: 24,
          }}
        >
          <OrderItemArtDetails orderItem={sourceOrderItem || {}}>
            <Link
              to={`/brands/${_get(brand, "id")}/arts/${_get(
                artRevision,
                "id"
              )}`}
            >
              {_get(artRevision, "name")}
            </Link>
          </OrderItemArtDetails>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            paddingLeft: 24,
            width: 100,
            backgroundColor: "rgb(250, 251, 251)",
          }}
        >
          {this.renderQtyField(dsodItem, dsodErrors)}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          <RecipientMoreAction
            element={dsodItem}
            copyItems={copyToMenuItems}
            disabledCopy={copyToMenuItems.length === 0 && true}
            moveItems={moveToMenuItems}
            disabledMove={moveToMenuItems.length === 0 && true}
            onDelete={dsodItem => {
              this.handleDeleteProduct(dsodItem);
            }}
            showEdit={false}
          />
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  _renderTable() {
    const {dsodItems = [], canAddItems} = this.props;
    if (!dsodItems.length) return <div />;
    const filteredDsodItems = dsodItems.filter(dsodItem => dsodItem.productId);

    return (
      <div style={style.container}>
        {filteredDsodItems.length > 0 ? (
          <CNGTableCustom tableStyle={tableStyle}>
            {this._renderTableHeader()}
            <TableBody displayRowCheckbox={false}>
              {filteredDsodItems.map((dsodItem, index) =>
                this._renderRow(dsodItem, index)
              )}
            </TableBody>
          </CNGTableCustom>
        ) : (
          <p style={style.blankText}>No Products Added</p>
        )}

        <LineHeader
          title={
            filteredDsodItems.length > 0
              ? "ADD ANOTHER PRODUCT FROM INVENTORY"
              : "ADD A PRODUCT FROM INVENTORY"
          }
          icon="add"
          style={{
            marginBottom: 0,
          }}
          onClick={() => this.showProductsChooser(dsodItems)}
          disabled={!canAddItems}
        />
      </div>
    );
  }

  render() {
    const {
      inventoryItems,
      inventoryItemsCount,
      companyId,
      onFetchDsodItems,
    } = this.props;
    return (
      <div>
        {this._renderTable()}
        <InventoryChooserModal
          inventoryItems={inventoryItems}
          open={this.state.openInventory}
          onFetchDsodItems={onFetchDsodItems}
          inventoryItemsCount={inventoryItemsCount}
          dsodItems={this.state.filteredDsodItems}
          companyId={companyId}
          onConfirm={this.saveSelectedInvetoryItems}
          onDismiss={() => {
            this.setState({openInventory: false});
          }}
        />
      </div>
    );
  }
}
