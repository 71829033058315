import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import FilePreview from "react-preview-file";
import {FileIcon} from "@drawbotics/file-icons";
import Dialog from "material-ui/Dialog";
import FontIcon from "material-ui/FontIcon";

import {
  VALID_QUOTE_IMAGE_FORMATS,
  PREVIEWABLE_IMAGE_FORMATS,
} from "../../../../constants/art";
import RaisedButton from "../../shared/RaisedButton";
import Typography from "../../shared/Typography";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";

class QuoteArtModal extends Component {
  state = {
    filename: "",
    file: null,
  };

  inputField = null;

  openFileBrowserHandler = ev => {
    this.inputField.click(ev);
  };

  removeSelectedFile = event => {
    event.preventDefault();
    this.setState({file: null, filename: ""});
  };

  handleFileChange = async inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!file) return;
    if (!VALID_QUOTE_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Unable To Accept This Format. Please Contact Us.",
        10000
      );
    } else {
      await this.setState({file, filename: file.name});
      this.handleAddArt();
    }
  };

  handleAddArt = () => {
    const {onCreateArt, arts, openNotification} = this.props;
    const {filename, file} = this.state;
    const artWithName = arts.find(art => art.filename === filename);
    if (artWithName) {
      openNotification("This art is already uploaded");
      return;
    }
    onCreateArt(filename, file);
    this.setState({filename: "", file: null});
  };

  getArtTableHeaderConfig = () => {
    return [
      {columnContent: "Image", customStyles: {width: "25%"}},
      {columnContent: "Art Name", customStyles: {width: "75%"}},
    ];
  };

  checkArtCanBeDeleted = art => {
    const {item, items} = this.props;
    return ![...items, item].some(item =>
      item.arts.some(itemArt => itemArt.art === art.uuid)
    );
  };

  renderFilePreview = (url, name) => {
    const {pageStyle} = this.props;
    const isPreviewable = PREVIEWABLE_IMAGE_FORMATS.some(format =>
      name.includes(format.split("/")[1])
    );
    if (isPreviewable) {
      return <img src={url} style={pageStyle.artImage} />;
    }
    return <FileIcon filename={name} style={pageStyle.artImage} />;
  };

  renderFile = art => {
    if (art.thumbnail)
      return this.renderFilePreview(art.imageUrl, art.thumbnail);
    if (art.file && art.file.name) {
      return (
        <FilePreview file={art.file}>
          {preview => this.renderFilePreview(preview, art.file.name)}
        </FilePreview>
      );
    }
  };

  renderArtTable = () => {
    const {pageStyle, onDeleteArt} = this.props;
    const arts = _get(this.props, "arts", []);
    const companyArts = _get(this.props, "companyArts", []);
    return (
      <CNGTable
        tableBodyStyle={{maxHeight: 320, overflowY: "auto"}}
        tableHeader={this.getArtTableHeaderConfig()}
        displayRowCheckbox={false}
        className="CNG-table CNG-table--removeHeaderHeight"
      >
        {companyArts.map(art => (
          <CNGTableRow key={art.id}>
            <CNGTableRowColumn customStyles={{width: "25%"}}>
              {this.renderFile(art)}
            </CNGTableRowColumn>
            <CNGTableRowColumn highlight customStyles={{width: "75%"}}>
              <span className="flex justify-content-between">
                <span
                  title={art.filename}
                  style={{overflow: "hidden", textOverflow: "ellipsis"}}
                >
                  {art.name}
                </span>
              </span>
            </CNGTableRowColumn>
          </CNGTableRow>
        ))}
        {arts.map(art => (
          <CNGTableRow key={art.uuid}>
            <CNGTableRowColumn customStyles={{width: "25%"}}>
              {this.renderFile(art)}
            </CNGTableRowColumn>
            <CNGTableRowColumn highlight customStyles={{width: "75%"}}>
              <span className="flex justify-content-between">
                <span
                  title={art.filename}
                  style={{overflow: "hidden", textOverflow: "ellipsis"}}
                >
                  {art.filename}
                </span>
                {this.checkArtCanBeDeleted(art) && (
                  <FontIcon
                    className="pointer material-icons"
                    onClick={onDeleteArt(art.uuid)}
                    style={pageStyle.deleteIcon}
                  >
                    delete
                  </FontIcon>
                )}
              </span>
            </CNGTableRowColumn>
          </CNGTableRow>
        ))}
        {arts.length === 0 &&
          companyArts.length === 0 && (
          <CNGTableRow>
            <CNGTableRowColumn>No art has been uploaded</CNGTableRowColumn>
          </CNGTableRow>
        )}
      </CNGTable>
    );
  };

  render() {
    const {open, onClose, pageStyle} = this.props;
    return (
      <Dialog modal={false} open={open} contentStyle={{maxWidth: 840}}>
        <span style={{position: "absolute", right: 10, top: 10}}>
          <FontIcon
            className="material-icons pointer"
            onClick={onClose}
            style={{
              fontSize: 16,
              borderRadius: 9,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            close
          </FontIcon>
        </span>
        <div className="flex justify-content-center margin-top-24">
          <div className="flex flex-column flex-one">
            <Typography variant="h4">Upload art for quote</Typography>
            <Typography variant="h5">
              We appreciate ‘label size’ or larger art (4”/10cm). All standard
              MS/Adobe image formats are acceptable - call/email if problems.
            </Typography>
            <input
              type="file"
              onChange={this.handleFileChange}
              className="hidden-safe"
              style={{display: "none"}}
              name="artFile"
              tabIndex={-1}
              ref={input => (this.inputField = input)}
              accept={VALID_QUOTE_IMAGE_FORMATS.join(", ")}
            />
            <div className="flex flex-one flex-center justify-content-center">
              <RaisedButton
                secondary
                medium
                className="margin-top-24"
                label="Upload Art File"
                style={pageStyle.uploadArtButton}
                onClick={this.openFileBrowserHandler}
              />
            </div>
          </div>
          <div className="flex-one">{this.renderArtTable()}</div>
        </div>
        <Typography
          variant="bodyBold"
          className="flex flex-center justify-content-center pointer uppercase margin-top-24 margin-bottom-24"
          color="primary.light"
          onClick={onClose}
        >
          Go Back To Quote
        </Typography>
      </Dialog>
    );
  }
}

QuoteArtModal.propTypes = {
  open: PropTypes.bool,
  arts: PropTypes.array,
  companyArts: PropTypes.array,
  item: PropTypes.object,
  items: PropTypes.array,
  pageStyle: PropTypes.object,
  onCreateArt: PropTypes.func,
  onDeleteArt: PropTypes.func,
  onClose: PropTypes.func,
  openNotification: PropTypes.func,
};

export default QuoteArtModal;
