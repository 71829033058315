import React, {Component} from "react";
import PropTypes from "prop-types";

import ChillButton from "../../../../shared/ChillButton";
import SearchInput from "../../../../shared/SearchInput";

import AssociationTable from "./AssociationTable";
import ToolTip from "react-portal-tooltip";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import FlatButton from "material-ui/FlatButton";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import {
  formStyles,
  SelectFieldIconStyle,
} from "../../../../../styles/components/formStyles";

import lodash from "lodash";

export default class AssociationsTab extends Component {
  static propTypes = {
    company: PropTypes.object,
    companies: PropTypes.array,
    orders: PropTypes.array,
    associatedCompanies: PropTypes.array,
    createCompanyCompanyAssociation: PropTypes.func,
    deleteCompanyCompanyAssociation: PropTypes.func,
    searchAssociatedCompanies: PropTypes.func,
    isLoading: PropTypes.bool,
    persistedSearch: PropTypes.string,
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func,
  };
  state = {
    addingCompany: false,
    associatedCompanyId: null,
    notes: null,
    searchTerm: "",
    displayAddAssociationTooltip: false,
  };

  componentDidMount() {
    this.props.searchAssociatedCompanies();
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.props.searchAssociatedCompanies();
    }
  }

  handleAddCompanyClick() {
    this.setState({addingCompany: true});
  }

  handleChangeNote(value) {
    this.setState({notes: value});
  }

  _handleSearchAssociations(searchTerm) {
    this.props.setSearchTerm(searchTerm);
    const {searchAssociatedCompanies} = this.props;
    searchAssociatedCompanies();
  }

  _handleSearchAssociationsDebounced = lodash.debounce(
    this._handleSearchAssociations,
    300
  );

  _toggleAddAssociationButtonTooltip(isDisabled) {
    if (!isDisabled) return;
    const {displayAddAssociationTooltip} = this.state;
    this.setState({
      displayAddAssociationTooltip: !displayAddAssociationTooltip,
    });
  }

  _renderAddButton(nonAssociatedCompanies) {
    const isDisabled = !this._canShowCompanyFields(nonAssociatedCompanies);
    return (
      <div
        onMouseEnter={() => this._toggleAddAssociationButtonTooltip(isDisabled)}
        onMouseLeave={() => this._toggleAddAssociationButtonTooltip(isDisabled)}
      >
        <ChillButton
          id="add-association-button"
          isDisabled={isDisabled}
          className="add-wallet-button"
          name={"ADD ASSOCIATION"}
          height={40}
          width={162}
          marginLeft={16}
          onClick={() => this.handleAddCompanyClick()}
        />
        <ToolTip
          active={this.state.displayAddAssociationTooltip}
          position="top"
          key="tooltip"
          tooltipTimeout={0}
          parent="#add-association-button"
        >
          <span className="small-text text-bold-medium base-padding">
            All companies are associated
          </span>
        </ToolTip>
      </div>
    );
  }

  async handleCreateAssociationClick() {
    const {associatedCompanyId, notes} = this.state;
    const {company, createCompanyCompanyAssociation} = this.props;
    if (associatedCompanyId) {
      const params = {
        company_id_1: Number(company.id),
        company_id_2: Number(associatedCompanyId),
      };
      if (notes) {
        params.notes = notes;
      }
      await createCompanyCompanyAssociation(params);
      this.setState({
        addingCompany: false,
        associatedCompanyId: null,
        notes: null,
      });
    }
  }
  handleAssociatedCompanyIdSelected(value) {
    if (value) {
      this.setState({associatedCompanyId: value});
    }
  }
  _getNonAssociatedCompaniesDataSource() {
    if (!this.props.company) return null;
    const {associatedCompanies, companies} = this.props;
    return (
      companies &&
      companies
        .filter(
          company =>
            Number(company.id) != Number(this.props.company.id) &&
            !associatedCompanies.find(
              ({companyId1, companyId2}) =>
                Number(companyId1) === Number(company.id) ||
                Number(companyId2) === Number(company.id)
            )
        )
        .map(({id, name}) => {
          return {text: name, value: parseInt(id)};
        })
    );
  }
  _renderCompanyFields(nonAssociatedCompanies) {
    return (
      <div
        className="flex flex-row align-items-center"
        style={{
          padding: "5px 0",
        }}
      >
        <SelectField
          value={this.state.associatedCompanyId}
          onChange={(_, __, value) =>
            this.handleAssociatedCompanyIdSelected(value)
          }
          style={{
            marginRight: "24px",
          }}
          iconStyle={{
            ...SelectFieldIconStyle,
            right: "-16px",
          }}
          hintText="Select a Company"
          hintStyle={formStyles.TextFieldLabelStyles}
          menuStyle={formStyles.selectMenuStyle}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          inputStyle={formStyles.inputStyle}
        >
          {nonAssociatedCompanies.map(({value, text}) => (
            <CNGMenuItem
              key={`company_association_${value}`}
              value={value}
              primaryText={text}
            />
          ))}
        </SelectField>
        <TextField
          floatingLabelText="Additional Notes"
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          style={{
            marginTop: "-24px",
          }}
          value={this.state.notes || ""}
          onChange={(_, value) => this.handleChangeNote(value)}
        />
        <ChillButton
          name=""
          isDisabled={!this.state.associatedCompanyId}
          height={40}
          width={40}
          marginLeft={40}
          marginRightIcon={0}
          icon={"check"}
          onClick={() => this.handleCreateAssociationClick()}
        />
        <FlatButton
          label="CANCEL"
          labelPosition="before"
          primary={true}
          labelStyle={{
            fontWeight: "bold",
          }}
          style={{
            marginLeft: "16px",
          }}
          onClick={() =>
            this.setState({
              addingCompany: false,
              associatedCompanyId: null,
              notes: null,
            })
          }
        />
      </div>
    );
  }

  _canShowCompanyFields(nonAssociatedCompanies) {
    return nonAssociatedCompanies && nonAssociatedCompanies.length > 0;
  }

  render() {
    const {
      company,
      orders,
      associatedCompanies,
      deleteCompanyCompanyAssociation,
      isLoading,
      searchTerm,
    } = this.props;
    const nonAssociatedCompanies = this._getNonAssociatedCompaniesDataSource();
    const {addingCompany} = this.state;
    return (
      <div className="tab-container">
        <div className="flex">
          {!addingCompany && (
            <SearchInput
              initialValue={searchTerm}
              search={(_, value) =>
                this._handleSearchAssociationsDebounced(value)
              }
            />
          )}

          <div className="dashboard_top">
            {addingCompany
              ? this._renderCompanyFields(nonAssociatedCompanies)
              : this._renderAddButton(nonAssociatedCompanies)}
          </div>
        </div>
        <AssociationTable
          isLoading={isLoading}
          company={company}
          orders={orders}
          associatedCompanies={associatedCompanies}
          deleteCompanyCompanyAssociation={id =>
            deleteCompanyCompanyAssociation(id)
          }
          addAssociation={() => this.handleAddCompanyClick()}
          addingCompany={addingCompany}
          searching={!!searchTerm}
        />
      </div>
    );
  }
}
