import React from "react";

import MenuItem from "material-ui/MenuItem";

export default class CNGMenuItem extends MenuItem {
  handleKeyDown = ({nativeEvent: {key, target}}) => {
    if (key === "Enter") {
      target.click();
    }
  };

  render() {
    const component = super.render();
    return React.cloneElement(component, {
      onKeyDown: this.handleKeyDown,
    });
  }
}
