import React, {Component} from "react";
import PropTypes from "prop-types";
import {parse, stringify} from "query-string";

/**
 * A public higher-order component to include sort feature
 */

function withSorting(WrappedComponent) {
  class Sorting extends Component {
    static propTypes = {
      location: PropTypes.object,
      history: PropTypes.object,
    };

    componentWillMount() {
      this._initParams();
    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this._initParams();
      }
    }

    state = {
      sortBy: "",
      sortDirection: "ASC",
    };

    _initParams() {
      const {location: {search}} = this.props;
      const params = parse(search);
      const {sortBy = "", sortDirection = "ASC"} = params;
      this.setState({sortBy, sortDirection});
    }

    setSort = (sortAttribute, nextSortDirection) => {
      const {sortBy, sortDirection} = this._getSortingOptions(
        sortAttribute,
        nextSortDirection
      );
      this._addSortParam(sortBy, sortDirection);
      this.setState({sortBy, sortDirection});
    };

    _addSortParam(sortBy, sortDirection) {
      const {location, history} = this.props;
      const search = stringify(
        Object.assign({}, parse(location.search), {sortBy, sortDirection})
      );

      history.replace({search});
    }

    _getSortingOptions(sortAttribute, nextSortDirection = "DESC") {
      const {sortBy, sortDirection} = this.state;
      let nextOrderDirection = nextSortDirection ? nextSortDirection : "ASC";
      if (sortAttribute === sortBy) {
        nextOrderDirection = sortDirection === "ASC" ? "DESC" : "ASC";
      }

      return {sortDirection: nextOrderDirection, sortBy: sortAttribute};
    }

    render() {
      return (
        <WrappedComponent
          setSort={this.setSort}
          sortBy={this.state.sortBy}
          sortDirection={this.state.sortDirection}
          {...this.props}
        />
      );
    }
  }
  return Sorting;
}
export default withSorting;
