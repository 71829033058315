import React, {Component} from "react";
import PropTypes from "prop-types";
import {cloneDeep, get} from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import UserForm from "./components/UserForm";
import FormCardHeader from "../../shared/FormCardHeader";
import {diff, updatedDiff} from "deep-object-diff";
import serializePayloadData from "../../../utils/serializePayloadData";

export default class UserEditPage extends Component {
  static propTypes = {
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    navigateTo: PropTypes.func.isRequired,
    getUser: PropTypes.func,
    userId: PropTypes.string,
    userDetail: PropTypes.object,
    updateUserWithCompanies: PropTypes.func,
    isInternal: PropTypes.bool,
    company: PropTypes.object,
    isLoading: PropTypes.bool,
    useCompanyBreadcrumbs: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    errorMessage: PropTypes.string,
    isUpdating: PropTypes.bool,
  };
  state = {
    firstName: "",
    lastName: "",
    name: "",
    phoneNumber: "",
    emailAddress: "",
    address1: "",
    address2: "",
    password: "",
    city: "",
    state: null,
    zipCode: 0,
    countryId: 0,
    companies: [],
    companiesWallets: [],
    newCompany: null,
    isInternal: false,
    user: {},
    id: null,
  };

  componentWillReceiveProps() {
    this._evaluatePageState(...arguments);
  }

  async _evaluatePageState(nextProps) {
    const {
      error: nextError,
      errorMessage: nextErrorMessage,
      isUpdating: nextIsUpdating,
    } = nextProps;
    const {isUpdating} = this.props;

    if (!nextError && isUpdating && !nextIsUpdating) {
      this._evaluateOnSuccessAction();
      return;
    }

    if (nextError && nextErrorMessage && nextErrorMessage.length > 0) {
      this._evaluateErrors(nextError, nextErrorMessage);
      return;
    }

    this._evaluateUserData(...arguments);
  }

  _evaluateOnSuccessAction() {
    const {navigateTo, isInternal, company, useCompanyBreadcrumbs} = this.props;
    const {id} = this.state;
    if (useCompanyBreadcrumbs || !isInternal) {
      navigateTo(`/companies/${company.id}/users/${id}`);
      return;
    }
    navigateTo(`/users/${id}`);
  }

  async _evaluateErrors(nextError, nextErrorMessage) {
    if (nextError.response.bodyUsed) {
      return;
    }
    const {openNotification} = this.props;
    const errorResponse = await nextError.response.json();
    const isUniqueViolation =
      errorResponse &&
      errorResponse.type &&
      errorResponse.type === "UniqueViolation";
    const errorMessage = isUniqueViolation
      ? "There is already a user with the provided email address"
      : nextErrorMessage;
    openNotification(errorMessage, 6000);
    this.setState({submitted: false});
  }

  _evaluateUserData(nextProps) {
    const nextUserState = this._getUserObject(nextProps.userDetail);
    if (nextUserState) {
      this.setState(nextUserState);
    }
  }

  _getUserObject(userDetail) {
    const userData = cloneDeep(userDetail);
    if (!userData || !Object.keys(userData).length) {
      return false;
    }
    const {companies, recipient} = userData;
    const companyIds = (companies && companies.map(({id}) => id)) || [];
    const walletsIds =
      get(recipient, "walletMembers", [])
        .filter(({isWalletMember}) => isWalletMember)
        .map(({companyId}) => String(companyId)) || [];

    return {
      user: userData,
      id: Number(userData.id),
      firstName: userData.firstName,
      lastName: userData.lastName,
      name: userData.name,
      phoneNumber: get(recipient, "phoneNumber"),
      emailAddress: userData.emailAddress,
      address1: get(recipient, "address1"),
      address2: get(recipient, "address2"),
      city: get(recipient, "city"),
      state: get(recipient, "state"),
      zipCode: get(recipient, "zipCode"),
      countryId: Number(get(recipient, "countryId")),
      companies: companyIds,
      isInternal: userData.isInternal,
      password: userData.password,
      companiesWallets: walletsIds,
    };
  }

  async updateUser() {
    const {updateUserWithCompanies} = this.props;
    const user = cloneDeep(this.state);
    delete user.user;
    const formattedPayload = serializePayloadData(user);
    updateUserWithCompanies(formattedPayload);
  }

  toggleIsInternal() {
    this.setState({
      isInternal: !this.state.isInternal,
    });
  }
  toggleCompanyWallet(id) {
    let {companiesWallets} = this.state;
    companiesWallets = companiesWallets.includes(id)
      ? companiesWallets.filter(el => el != id)
      : [...companiesWallets, id];
    this.setState({companiesWallets});
  }

  handleChange(e, index, value, formName) {
    if (!value && formName !== "state") {
      value = index;
    }
    let name;
    if (!formName) {
      name = e.target.name;
    } else {
      name = formName;
    }
    this.setState({[name]: value});
  }
  handleChangeCompany(e, index, value) {
    if (this.state.companies.indexOf(value) > -1) {
      return;
    }
    this.setState({
      companies: [...this.state.companies, value],
      addingCompany: false,
    });
  }
  handleDeleteCompany(id) {
    const companies = this.state.companies;
    const index = companies.indexOf(id);
    companies.splice(index, 1);
    this.setState({companies: companies});
  }

  _evaluateUpdateButtonDisabledState() {
    const originalUserData = this._getUserObject(this.props.userDetail);
    if (!originalUserData) {
      return false;
    }
    const diffResult = diff(this.state, originalUserData);
    const updatedDiffResult = updatedDiff(this.state, originalUserData);
    if ("newCompany" in diffResult && Object.keys(diffResult).length === 1) {
      return true;
    }
    if (diffResult && Object.keys(diffResult).length > 1) {
      return false;
    }
    return !(Object.keys(updatedDiffResult).length > 0);
  }

  render() {
    const {isInternal, company, isLoading, useCompanyBreadcrumbs} = this.props;
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={this.state.name ? this.state.name : ""}
      >
        {useCompanyBreadcrumbs || !isInternal ? (
          <BreadCrumbs
            rootName={isInternal ? "Companies" : "Company"}
            rootUrl={"companies"}
            disableRootLink={!isInternal}
            middle={company && company.name}
            middleLink={company && `${company.id}`}
            pageName={this.state.name}
          />
        ) : (
          <BreadCrumbs
            rootName={"Users"}
            pageName={this.state.name && this.state.name}
          />
        )}
        <Card>
          <div>
            <FormCardHeader
              title="Edit"
              cancelLink={
                useCompanyBreadcrumbs || !isInternal
                  ? `/companies/${company && company.id}/users/${
                    this.state.user.id
                  }`
                  : `/users/${this.state.user.id}`
              }
              buttonText="Update"
              buttonIcon="check"
              noButton={this._evaluateUpdateButtonDisabledState()}
              includeHr={true}
              buttonClick={() => this.setState({submitted: true})}
            />
            <UserForm
              allowPasswordAssignment={isInternal}
              handleDeleteCompany={id => this.handleDeleteCompany(id)}
              handleChangeCompany={(e, index, value) =>
                this.handleChangeCompany(e, index, value)
              }
              handleChange={(e, index, value, formName) =>
                this.handleChange(e, index, value, formName)
              }
              toggleIsInternal={() => this.toggleIsInternal()}
              toggleCompanyWallet={id => this.toggleCompanyWallet(id)}
              submitSuccess={() => this.updateUser()}
              submitError={() => this.setState({submitted: false})}
              states={this.props.states}
              countries={this.props.countries}
              allCompanies={this.props.companies}
              disableCompanyManagement={!isInternal}
              currentCompanyId={company && company.id}
              isInternal={isInternal}
              {...this.state}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
