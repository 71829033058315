import React from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import Typography from "../../shared/Typography";
import withStyles from "../../shared/WithStyles";
import {PRICING_TABLE_INFO} from "../../../../constants/quote";

const PricingTableButton = ({onOpenPricingTable, style, styles}) => {
  const {colors} = styles;
  const iconStyle = {
    color: colors.primary.light,
    fontSize: 20,
    paddingRight: 4,
    ...style,
  };
  return (
    <Typography
      variant="bodyBold"
      color="primary.light"
      className="flex pointer"
      onClick={onOpenPricingTable}
      title={PRICING_TABLE_INFO}
    >
      <FontIcon className="material-icons" style={iconStyle}>
        border_all
      </FontIcon>
      Pricing Table
    </Typography>
  );
};

PricingTableButton.propTypes = {
  onOpenPricingTable: PropTypes.func,
  style: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(PricingTableButton);
