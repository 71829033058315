import React, {Component} from "react";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";
import PropTypes from "prop-types";
import FormattedAddress from "../../../shared/FormattedAddress";
import FormattedPhone from "../../../shared/FormattedPhone";
import {getFormattedDate} from "../../../../utils/dates";
import {Link} from "react-router-dom";

export default class ProductDetailShipmentHistoryTab extends Component {
  static propTypes = {
    shipments: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  _renderRow = (shipmentItem, index) => {
    const customStyle = {
      width: 110,
    };
    const {order} = shipmentItem;
    const recipient = shipmentItem.recipientInformation || {};
    const actualShipDate = shipmentItem.actualShipDate
      ? new Date(shipmentItem.actualShipDate)
      : null;

    const {country, countryCode} = recipient;
    const formattedPhone = (
      <FormattedPhone
        phoneNumber={recipient.phoneNumber}
        countryCode={country ? country.code : countryCode}
      />
    );
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: 182,
          }}
        >
          <Link
            to={`/companies/${order.companyId}?tab=recipients&query=${
              recipient.name
            }`}
            className="no-link-styles"
            title={recipient.name}
          >
            {recipient.name}
            <div className="subtext overflow-ellipsis">
              {recipient.companyName}
            </div>
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={order.cngReferenceNumber}
          customStyles={{
            ...customStyle,
            width: 135,
          }}
        >
          {order.cngReferenceNumber}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            ...customStyle,
            width: 217,
          }}
        >
          <FormattedAddress user={recipient} />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            ...customStyle,
            width: 130,
          }}
        >
          {formattedPhone}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={shipmentItem.shippedQuantity}
          customStyles={{
            ...customStyle,
            width: 103,
          }}
        >
          {shipmentItem.shippedQuantity}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={getFormattedDate(actualShipDate)}
          customStyles={{
            ...customStyle,
            width: 130,
          }}
        >
          {getFormattedDate(actualShipDate)}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={shipmentItem.trackingNumber}
          customStyles={{
            width: 150,
          }}
        >
          {shipmentItem.trackingNumber}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  _reduceShipments(updatedShipments, shipment) {
    if (shipment.recipient_id && !shipment.is_multiple) {
      updatedShipments.push(shipment);
      return updatedShipments;
    }
    if (Array.isArray(shipment.items) && shipment.items.length) {
      return [
        ...updatedShipments,
        ...shipment.items.map(item => ({
          ...item,
          order: shipment.order,
        })),
      ];
    }
    return updatedShipments;
  }

  render() {
    const header = [
      {
        columnContent: "RECIPIENT & COMPANY",
        customStyles: {
          width: 182,
        },
      },
      {
        columnContent: "ORDER",
        customStyles: {
          width: 135,
        },
      },
      {
        columnContent: "ADDRESS 1",
        customStyles: {
          width: 217,
        },
      },
      {
        columnContent: "PHONE",
        customStyles: {
          width: 130,
        },
      },
      {
        columnContent: "QTY SHIP",
        customStyles: {
          width: 103,
        },
      },
      {
        columnContent: "DATE SHIP",
        customStyles: {
          width: 130,
        },
      },
      {
        columnContent: "TRACKING #",
        customStyles: {
          width: 140,
        },
      },
    ];
    const {shipments, isLoading} = this.props;
    return (
      <CNGTable
        isLoading={isLoading}
        tableHeader={header}
        displayRowCheckbox={false}
      >
        {shipments &&
          shipments.map((shipment, index) => {
            return this._renderRow(shipment, index);
          })}
      </CNGTable>
    );
  }
}
