import React, {Component} from "react";
import PropTypes from "prop-types";

import CNGMenuItem from "../../../shared/CNGMenuItem";

import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import SectionHeading from "../../../shared/SectionHeading";
import AdornmentTypeForm from "../../Arts/components/AdornmentTypeForm";
import ArtFileInputContainer from "../../Arts/components/ArtFileInputContainer";

import {formStyles} from "../../../../styles/components/formStyles";

import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";

const adornmentSelectStyle = {
  ...formStyles.TextInputStyles,
  width: 300,
};

export default class BrandForm extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,
    adornment_type_id: PropTypes.number,
    adornmentTypes: PropTypes.array,
    applicabilities: PropTypes.array,
    details: PropTypes.object,
    brandName: PropTypes.string.isRequired,
    artName: PropTypes.string.isRequired,
    file: PropTypes.object,
    selectedArt: PropTypes.object,
    showUploadModal: PropTypes.bool,
    submitted: PropTypes.bool,
  };

  validations = {
    brandName: {
      format: "any",
      length: [2, 30],
    },
    artName: {
      format: "any",
      length: [2, 30],
    },
    adornment_type_id: {
      format: "integer",
    },
    filename: {
      format: "any",
      nullable: false,
    },
  };

  state = {
    errors: {},
    submitted: false,
    adornmentTypeFormValid: false,
  };

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.submitted === "undefined") {
      return;
    }

    const {submitted} = this.state;
    this.setState(
      {
        submitted: nextProps.submitted,
      },
      () => {
        if (nextProps.submitted && !submitted) {
          this.triggerValidations();
        }
      }
    );
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    const {adornmentTypeFormValid} = this.state;
    if (!valid || !adornmentTypeFormValid) return submitError();

    submitSuccess();
  }

  _validate() {
    const errors = validateForm(this);
    this.setState({errors});

    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  _adormentTypeOptions() {
    return this.props.adornmentTypes.map(adornment => {
      return (
        <CNGMenuItem
          key={adornment.id}
          value={adornment.id}
          primaryText={adornment.name}
        />
      );
    });
  }

  _handleAdornmentTypeFormValidChange = valid => {
    this.setState({adornmentTypeFormValid: valid});
  };

  _renderAdormentForm() {
    const {
      adornment_type_id,
      adornmentTypes,
      handleChange,
      applicabilities,
      details,
      onDetailChange,
      artName,
    } = this.props;
    return (
      <div>
        <SectionHeading
          text="Art Name"
          textTransform="none"
          fontSize={16}
          fontColor="#4A5158"
          fontWeight="normal"
        />
        <div className="usercreate_row">
          <ValidableTextField
            name="artName"
            tabIndex={2}
            label="Art Name"
            value={artName}
            errorText={this.state.errors.artName}
            handleChange={handleChange}
            style={formStyles.TextFieldStyles}
            inputStyle={formStyles.TextInputStyles}
            floatingLabelStyle={formStyles.TextFieldLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            validate={() => this._validateDebounced()}
          />
        </div>
        <SectionHeading
          text="Adornment Details"
          textTransform="none"
          fontSize={16}
          fontColor="#4A5158"
          fontWeight="normal"
          marginTop={32}
        />
        <ValidableSelect
          tabIndex={3}
          label="Adornment Type"
          name="adornment_type_id"
          errorText={this.state.errors.adornment_type_id}
          value={String(adornment_type_id) || ""}
          handleChange={(e, index, value) => {
            this._handleAdornmentTypeFormValidChange(false);
            this._validate();
            handleChange(e, index, Number(value), "adornment_type_id");
          }}
          style={adornmentSelectStyle}
          inputStyle={formStyles.HalfTextInputStyles}
          menuStyle={formStyles.WideMenuStyle}
          underlineStyle={formStyles.underlineStyle}
          options={this._adormentTypeOptions()}
        />
        <AdornmentTypeForm
          applicabilities={applicabilities}
          details={details}
          adornmentTypeId={adornment_type_id}
          adornmentTypes={adornmentTypes}
          onDetailChange={onDetailChange}
          onValidChange={this._handleAdornmentTypeFormValidChange}
          submitted={this.state.submitted}
        />
      </div>
    );
  }

  _renderArtUpload() {
    const {file, onFileChange, selectedArt, showUploadModal} = this.props;
    return (
      <div className="usercreate_row row middle-xs" style={{marginTop: 24}}>
        <ArtFileInputContainer
          errorText={this.state.errors.filename}
          tabIndex={3}
          file={file}
          onFileChange={onFileChange}
          art={selectedArt}
          isInternal={true}
          showDialogOption={showUploadModal}
          fileInputText={file ? "REPLACE" : "UPLOAD"}
          fileInputIcon={file ? "replacefile-white" : "add2-white"}
        />
      </div>
    );
  }

  _renderBrandFields() {
    const {brandName, handleChange} = this.props;
    return (
      <div className="usercreate_card_body">
        <SectionHeading
          text="General Info"
          textTransform="none"
          fontSize={16}
          fontColor="#4A5158"
          fontWeight="normal"
        />
        <div className="usercreate_row">
          <ValidableTextField
            name="brandName"
            label="Brand Name"
            value={brandName}
            errorText={this.state.errors.brandName}
            handleChange={handleChange}
            style={formStyles.TextFieldStyles}
            inputStyle={formStyles.TextInputStyles}
            floatingLabelStyle={formStyles.TextFieldLabelStyles}
            underlineStyle={formStyles.underlineStyle}
            validate={() => this._validateDebounced()}
            autoFocus={true}
            tabIndex={1}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="user-form">
        <div className="flex flex-row">
          <div>
            {this._renderBrandFields()}
            {this._renderArtUpload()}
          </div>
          {this._renderAdormentForm()}
        </div>
      </div>
    );
  }
}
