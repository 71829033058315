import React, {Component} from "react";
import PropTypes from "prop-types";

import ValidableTextField from "../../../shared/ValidableTextField";
import {formStyles} from "../../../../styles/components/formStyles";

import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";

export default class PatchForm extends Component {
  static propTypes = {
    onDetailChange: PropTypes.func.isRequired,
    handleUpdateValid: PropTypes.func.isRequired,
    details: PropTypes.object,
    submitted: PropTypes.bool.isRequired,
  };
  state = {
    errors: {},
    submitted: false,
  };
  validations = {
    shape_description: {format: "name", length: [2, 18]},
    height: {format: "decimal", length: [1, 10]},
    width: {format: "decimal", length: [1, 10]},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.submitted && !this.props.submitted) {
      this.setState({submitted: true}, () => {
        this._validate();
      });
    }
  }

  _validate() {
    const errors = validateForm(this, this.props.details);
    const submittedErrors = validateForm(
      {...this, state: {submitted: true}},
      this.props.details
    );
    const valid = !Object.keys(submittedErrors).length;
    const {handleUpdateValid} = this.props;
    this.setState({errors});
    this.props.details;
    if (!valid) return handleUpdateValid(false);
    handleUpdateValid(true);
  }

  _validateDebounced = debounce(this._validate, 300);

  render() {
    const {
      onDetailChange,
      details: {height, width, shape_description},
    } = this.props;

    return (
      <div
        className="flex flex-wrap"
        style={{
          width: 710,
        }}
      >
        <ValidableTextField
          tabIndex={5}
          floatingLabelText="Shape Description"
          name="shape_description"
          label="Shape Description"
          value={shape_description}
          maxLength={this.validations.shape_description.length[1]}
          errorText={this.state.errors.shape_description}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          tabIndex={6}
          name="width"
          label="Width Size (inch)"
          value={width}
          maxLength={this.validations.width.length[1]}
          errorText={this.state.errors.width}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          tabIndex={7}
          name="height"
          label="Height Size (inch)"
          value={height}
          maxLength={this.validations.height.length[1]}
          errorText={this.state.errors.height}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
      </div>
    );
  }
}

const detailsInputStyle = {
  ...formStyles.HalfFieldStyles,
  marginRight: 16,
};
