import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import ShippingCarrierCreate from "./ShippingCarrierCreate";
import {push} from "react-router-redux";
import {
  createCompanyShippingCarrierAssignment,
  fetchShippingCarriers,
} from "../../../../actions/shipmentActions";

import {fetchCompany} from "../../../../actions/companyActions";

function mapStateToProps(state) {
  const {
    shippingCarriers,
    isCarrierAssignmentsLoading,
    companyShippingCarrierAssignment,
  } = state.shipment;
  return {
    shippingCarriers,
    isInternal: state.auth.isInternal,
    isLoading: isCarrierAssignmentsLoading,
    companyShippingCarrierAssignment,
    company: state.companies.company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: payload => dispatch(push(payload)),
    fetchShippingCarriers: bindActionCreators(fetchShippingCarriers, dispatch),
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    createCompanyShippingCarrierAssignment: bindActionCreators(
      createCompanyShippingCarrierAssignment,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingCarrierCreate)
);
