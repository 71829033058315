export const ORDER_COMPLETED = "Order Completed";
export const ORDER_COMPLETED_ID = "OCOMP";

export const REQUEST_FULFILLED = "Request Fulfilled";
export const REQUEST_FULFILLED_ID = "RSHIP";

export const REGULAR_ORDER = "order";
export const DSOD_ORDER = "dsod";

export const SHIPPING_ACCOUNT_NONE = "NONE";

export const BILLING_REPORT = "Billing Report";
export const ORDER_DETAIL = "Order Detail";

export const ORDER_BUTTON_INTERNAL = "WREST";
export const ORDER_BUTTON_EXTERNAL = "CONFIRM";