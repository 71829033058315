import lodash from "lodash";
import React from "react";
import * as PropTypes from "prop-types";
const FDX_NAME = "FDX";

const TrackingNumber = ({children, shipment}) => {
  let trackingNumber = lodash.defaultTo(children, "");
  if (!shipment) return trackingNumber;
  const shippingCarrierName = lodash.get(
    shipment,
    "shippingMethod.shippingCarrier.name"
  );
  if (!shippingCarrierName) return trackingNumber;
  if (shippingCarrierName !== FDX_NAME) return trackingNumber;

  const src =
    "https://www.fedex.com/apps/fedextrack/" +
    `?action=track&trackingnumber=${children}&cntry_code=us&locale=en_US`;
  return (
    <a
      href={src}
      target="blank"
      style={{
        width: 110,
        color: "#0C5271",
        fontWeight: "bold",
        lineHeight: "24px",
      }}
    >
      {trackingNumber}
    </a>
  );
};

TrackingNumber.propTypes = {
  children: PropTypes.node,
  shipment: PropTypes.object,
};

export default TrackingNumber;
