import React, {Component} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import CNGMenuItem from "../../../shared/CNGMenuItem";

import {formStyles} from "../../../../styles/components/formStyles";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import {validateForm} from "../../../../utils/formValidations";

export default class ShippingCarrierForm extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,

    accountNumber: PropTypes.any,
    name: PropTypes.string,
    carrierAssignmentId: PropTypes.id,
    shippingCarriers: PropTypes.array,
    shippingCarrier: PropTypes.object,
    companyShippingCarrierAssignment: PropTypes.object,

    errors: PropTypes.object,
    submitted: PropTypes.bool,
  };

  validations = {
    accountNumber: {
      format: "any",
      length: [2, 30],
      nullable: true,
    },
    name: {
      format: "any",
      length: [2, 30],
    },
    shippingCarrierId: {
      format: "integer",
    },
  };

  state = {
    errors: {},
    shippingCarrierId: null,
    name: null,
    accountNumber: null,
  };

  componentDidUpdate(prevProps) {
    const {submitted} = this.props;
    if (prevProps.submitted !== submitted && submitted === true) {
      this.triggerValidations();
    }

    if (
      this.props.companyShippingCarrierAssignment !==
      prevProps.companyShippingCarrierAssignment
    ) {
      this.setState({
        ...this.props.companyShippingCarrierAssignment,
      });
    }
  }

  handleChange(e, index, value, formName, needValidation) {
    const name = formName || e.target.name;
    this.setState(
      {
        [name]: value || index,
      },
      () => {
        if (needValidation) this._validate();
      }
    );
  }

  triggerValidations = () => {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    if (!valid) return submitError();
    submitSuccess(
      lodash.pick(this.state, ["shippingCarrierId", "name", "accountNumber"])
    );
  };

  _validate = () => {
    if (!this.props.submitted && lodash.isEmpty(this.state.errors)) return;
    const errors = validateForm(this, {}, true);
    this.setState({errors});
    return errors;
  };
  _validateDebounced = lodash.debounce(this._validate, 300);

  render() {
    return (
      <div className="user-form">
        <div>
          <div className="flex">
            <ValidableTextField
              name="name"
              label="Name"
              value={this.state.name}
              errorText={this.state.errors.name}
              handleChange={(e, value) => this.handleChange(e, value)}
              validate={this._validateDebounced}
              autoFocus={true}
            />
            <ValidableTextField
              name="accountNumber"
              label="Account Number"
              value={this.state.accountNumber}
              errorText={this.state.errors.accountNumber}
              handleChange={(e, value) => this.handleChange(e, value)}
              validate={this._validateDebounced}
            />
          </div>
        </div>
        <div className="flex">
          <ValidableSelect
            name="shippingCarrierId"
            label="Shipping Carrier"
            value={this.state.shippingCarrierId}
            errorText={this.state.errors.shippingCarrierId}
            iconStyle={formStyles.CountryIconStyles}
            handleChange={(e, index, value) => {
              this.handleChange(
                e,
                index,
                Number(value),
                "shippingCarrierId",
                true
              );
            }}
            options={lodash
              .get(this.props, "shippingCarriers", [])
              .map(({id, name}) => (
                <CNGMenuItem key={id} value={id} primaryText={name} />
              ))}
          />
        </div>
      </div>
    );
  }
}
