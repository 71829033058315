import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import _cloneDeep from "lodash/cloneDeep";

import * as actions from "../../../actions/companyActions";
import withPagination from "../../modules/withPagination";
import {
  fetchOrdersByCompany,
  fetchOrderItems,
  searchOrdersByCompany,
  createReorderItem,
  fetchOpenOrders,
} from "../../../actions/orderActions";
import {createDsodItemsFromInventory} from "../../../actions/dsodItemsActions";
import {
  fetchUsersByCompany,
  searchUsersByCompany,
  updateUserWithCompanies,
} from "../../../actions/userActions";
import {fetchFabrics} from "../../../actions/fabricActions";
import {fetchDiscounted} from "../../../actions/discountedActions";
import {fetchBusinesses} from "../../../actions/businessActions";
import {fetchPaymentTerms} from "../../../actions/paymentTermsActions";
import {fetchStates, fetchCountries} from "../../../actions/geoActions";
import {
  fetchInventoryItems,
  searchInventoryItems,
} from "../../../actions/inventoryActions";
import {fetchQuotes, searchQuotes} from "../../../actions/v2/quoteActions";

import CompaniesDetailPage from "../../pages/Companies/CompaniesDetailPage";

class CompaniesDetail extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      tab: this.props.isInternal ? "notes" : "orders",
    };
  }

  componentWillMount() {
    const {match} = this.props;
    const {id: companyId} = match.params;
    this._fetchCompanyData(companyId);
    this._fetchCategories();
    this._evaluateQueryParams();
  }

  _evaluateQueryParams() {
    const {queryStringParams} = this.props;
    if (typeof queryStringParams === "object" && "tab" in queryStringParams) {
      const {tab} = queryStringParams;
      if (tab) {
        this.setState({tab});
      }
    }
  }

  _fetchCompanyData(companyId) {
    this.props.fetchCompany(companyId);
    this.props.fetchOrderItems(companyId);
    this.props.fetchCompanies({sort: "name", sortDirection: "ASC"});
  }

  _fetchCategories() {
    this.props.fetchFabrics();
    this.props.fetchDiscounted();
    this.props.fetchBusinesses();
    this.props.fetchPaymentTerms();
    this.props.fetchCountries();
    this.props.fetchStates();
  }

  _updateQueryParams(key, value) {
    const nextParams = {
      [key]: value,
      query: "",
      page: 1,
    };
    this.props.addQueryParam(nextParams);
    this.setState(nextParams);
  }

  render() {
    const updatedProps = {
      ...this.props,
      ..._cloneDeep(this.state),
      updateQueryParams: this._updateQueryParams.bind(this),
      page: this.props.page,
    };
    return <CompaniesDetailPage {...updatedProps} />;
  }
}

CompaniesDetail.propTypes = {
  company: PropTypes.object,
  companyContacts: PropTypes.array,
  companyContactsCount: PropTypes.number,
  companies: PropTypes.array,
  orders: PropTypes.array,
  ordersCount: PropTypes.number,
  updateCompany: PropTypes.func,
  inventoryItems: PropTypes.array,
  fetchCompany: PropTypes.func,
  fetchCompanies: PropTypes.func,
  fetchOrdersByCompany: PropTypes.func,
  fetchAssociatedCompaniesByCompany: PropTypes.func,
  fetchFabrics: PropTypes.func,
  fetchUsersByCompany: PropTypes.func,
  searchUsersByCompany: PropTypes.func,
  fetchCompanyContacts: PropTypes.func,
  searchCompanyContacts: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  fetchDiscounted: PropTypes.func,
  fetchBusinesses: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  fetchStates: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchInventoryItems: PropTypes.func,
  searchInventoryItems: PropTypes.func,
  searchOrdersByCompany: PropTypes.func,
  searchAssociatedCompaniesByCompany: PropTypes.func,
  createCompanyCompanyAssociation: PropTypes.func,
  deleteCompanyCompanyAssociation: PropTypes.func,
  createCompanyNote: PropTypes.func,
  deleteCompanyNote: PropTypes.func,
  updateUserWithCompanies: PropTypes.func,
  isCurrentUseExternal: PropTypes.bool,
  users: PropTypes.array,
  userCount: PropTypes.number,
  fetchOrderItems: PropTypes.func,
  isLoadingAssociatedCompanies: PropTypes.bool,
  isLoadingOrderHistory: PropTypes.bool,
  isLoadinginventory: PropTypes.bool,
  isLoadingUsers: PropTypes.bool,
  isCompanyContactsLoading: PropTypes.bool,
  isNoteRequestLoading: PropTypes.bool,
  queryStringParams: PropTypes.object,
  addQueryParam: PropTypes.func,
  isInternal: PropTypes.bool,
  page: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    company: state.companies.company,
    companies: state.companies.companies,
    orders: state.orders.orders,
    isLoadingOrderHistory: state.orders.isLoading,
    ordersCount: state.orders.ordersCount,
    orderItems: state.orders.orderItems,
    associatedCompanies: state.companies.associatedCompanies,
    countries: state.geo.countries,
    states: state.geo.states,
    fabrics: state.fabrics.fabrics,
    discounts: state.discounted.discounts,
    businesses: state.businesses.businesses,
    paymentTerms: state.paymentTerms.paymentTerms,
    inventoryItems: state.inventory.inventoryItems,
    inventoryItemsCount: state.inventory.inventoryItemsCount,
    isLoadinginventory: state.inventory.isLoading,
    companyContacts: state.companies.companyContacts,
    companyContactsCount: state.companies.companyContactsCount,
    quotes: state.quotes.quotes,
    quotesCount: state.quotes.quotesCount,
    isLoadingQuotes: state.quotes.isLoading,
    users: state.users.users,
    userCount: state.users.userCount,
    isLoadingUsers: state.users.isLoading,
    isInternal: state.auth.isInternal,
    currentUserId: state.auth.id,
    isLoadingAssociatedCompanies: state.companies.isLoadingAssociatedCompanies,
    isCompanyContactsLoading: state.companies.isCompanyContactsLoading,
    isLoadingCompany: state.companies.isLoading,
    isNoteRequestLoading: state.companies.isNoteRequestLoading,
    isLoading:
      state.companies.isLoading ||
      state.users.isLoading ||
      state.companies.isLoadingAssociatedCompanies ||
      state.companies.isCompanyContactsLoading ||
      state.companies.isNoteRequestLoading ||
      state.orders.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    archiveUserCompany: bindActionCreators(
      actions.archiveUserCompany,
      dispatch
    ),
    fetchCompany: id => dispatch(actions.fetchCompany(id)),
    fetchCompanies: options => dispatch(actions.fetchCompanies(options)),
    updateCompany: company => dispatch(actions.updateCompany(company)),
    fetchOrdersByCompany: bindActionCreators(fetchOrdersByCompany, dispatch),
    fetchFabrics: () => dispatch(fetchFabrics()),
    fetchOrderItems: id => dispatch(fetchOrderItems(id)),
    fetchUsersByCompany: (id, options) =>
      dispatch(fetchUsersByCompany(id, options)),
    fetchCompanyContacts: (id, options) =>
      dispatch(actions.fetchCompanyContacts(id, options)),
    searchCompanyContacts: (id, query, options) =>
      dispatch(actions.searchCompanyContacts(id, query, options)),
    searchUsersByCompany: (id, query, options) =>
      dispatch(searchUsersByCompany(id, query, options)),
    fetchAssociatedCompaniesByCompany: id =>
      dispatch(actions.fetchAssociatedCompaniesByCompany(id)),
    fetchDiscounted: () => dispatch(fetchDiscounted()),
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchStates: () => dispatch(fetchStates()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchPaymentTerms: () => dispatch(fetchPaymentTerms()),
    fetchInventoryItems: (id, options) =>
      dispatch(fetchInventoryItems(id, options)),
    searchInventoryItems: (id, query, options) =>
      dispatch(searchInventoryItems(id, query, options)),
    searchOrdersByCompany: bindActionCreators(searchOrdersByCompany, dispatch),
    searchAssociatedCompaniesByCompany: (id, query) =>
      dispatch(actions.searchAssociatedCompaniesByCompany(id, query)),
    createCompanyCompanyAssociation: data =>
      dispatch(actions.createCompanyCompanyAssociation(data)),
    deleteCompanyCompanyAssociation: id =>
      dispatch(actions.deleteCompanyCompanyAssociation(id)),
    createCompanyNote: note => dispatch(actions.createCompanyNote(note)),
    deleteCompanyNote: id => dispatch(actions.deleteCompanyNote(id)),
    updateUserWithCompanies: user => dispatch(updateUserWithCompanies(user)),
    createDsodItemsFromInventory: bindActionCreators(
      createDsodItemsFromInventory,
      dispatch
    ),
    createReorderItem: bindActionCreators(createReorderItem, dispatch),
    fetchOpenOrders: bindActionCreators(fetchOpenOrders, dispatch),
    fetchQuotes: bindActionCreators(fetchQuotes, dispatch),
    searchQuotes: bindActionCreators(searchQuotes, dispatch),
  };
}

export default withPagination(
  connect(mapStateToProps, mapDispatchToProps)(CompaniesDetail)
);
