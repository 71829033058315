import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {openNotification} from "../../../actions/notificationActions";
import InventoryChooserModal from "../../pages/DSOD/components/InventoryChooserModal";

import {
  createDsodItem,
  fetchDsodItems,
  updateDsodItem,
  createDsodItemsFromInventory,
} from "../../../actions/dsodItemsActions";
import {fetchInventoryItems} from "../../../actions/inventoryActions";

function mapStateToProps(state) {
  const {inventory: {inventoryItemsCount, inventoryItems, isLoading}} = state;

  return {inventoryItems, inventoryItemsCount, isLoading};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchInventoryItems: bindActionCreators(fetchInventoryItems, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    createDsodItem: bindActionCreators(createDsodItem, dispatch),
    fetchDsodItems: bindActionCreators(fetchDsodItems, dispatch),
    updateDsodItem: bindActionCreators(updateDsodItem, dispatch),
    createDsodItemsFromInventory: bindActionCreators(
      createDsodItemsFromInventory,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryChooserModal)
);
