import {connect} from "react-redux";
import withPagination from "../../modules/withPagination";
import {bindActionCreators} from "redux";
import {
  fetchInventoryByCompany,
  searchInventoryItems,
} from "../../../actions/inventoryActions";
import {fetchCompany} from "../../../actions/companyActions";
import {
  fetchOrderItemProduct,
  searchProductShipments,
} from "../../../actions/productActions";
import {createDsodItemsFromInventory} from "../../../actions/dsodItemsActions";
import {
  searchInventoryShipments,
  updateShipmentItem,
} from "../../../actions/shipmentActions";
import {uploadArtAssignmentImage} from "../../../actions/v2/ArtAssignmentActions";
import {createReorderItem, fetchOrderItem} from "../../../actions/orderActions";
import {push} from "react-router-redux";
import {openNotification} from "../../../actions/notificationActions";

import ProductDetailPage from "../../pages/Products/ProductDetailPage";

function mapStateToProps(state) {
  const {
    companies: {company, isLoading: companyIsLoading},
    inventory: {
      inventoryItem,
      isLoading: isLoadingInventory,
      inventoryItems,
      inventoryItemsCount,
    },
    shipment: {shipments, shipmentsCount, isLoading: isLoadingShipment},
    orders: {orderItem},
    auth: {isInternal},
    products: {orderItemProduct, productShipments, productShipmentCount},
    artAssignments: {isLoading: isLoadingArtAssignment},
  } = state;

  return {
    orderItem,
    inventoryItem,
    inventoryItems,
    isLoading: companyIsLoading,
    isLoadingInventory,
    isLoadingShipment,
    company,
    isLoadingArtAssignment,
    shipments,
    inventoryItemsCount,
    shipmentsCount,
    isInternal,
    orderItemProduct,
    productShipments,
    productShipmentCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    fetchOrderItem: bindActionCreators(fetchOrderItem, dispatch),
    fetchOrderItemProduct: bindActionCreators(fetchOrderItemProduct, dispatch),
    fetchInventoryByCompany: bindActionCreators(
      fetchInventoryByCompany,
      dispatch
    ),
    searchInventoryItems: bindActionCreators(searchInventoryItems, dispatch),
    searchInventoryShipments: bindActionCreators(
      searchInventoryShipments,
      dispatch
    ),
    searchProductShipments: bindActionCreators(
      searchProductShipments,
      dispatch
    ),
    createReorderItem: bindActionCreators(createReorderItem, dispatch),
    updateShipmentItem: bindActionCreators(updateShipmentItem, dispatch),
    uploadArtAssignmentImage: bindActionCreators(
      uploadArtAssignmentImage,
      dispatch
    ),
    openNotification: bindActionCreators(openNotification, dispatch),
    navigateTo: bindActionCreators(push, dispatch),
    createDsodItemsFromInventory: bindActionCreators(
      createDsodItemsFromInventory,
      dispatch
    ),
  };
}

export default withPagination(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage)
);
