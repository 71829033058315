import React, {Component} from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import _get from "lodash/get";
import CNGImage from "./CNGImage";

const artAssignmentModalStyles = {
  height: "85vh",
  maxWidth: 700,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
  margin: "-52px auto",
};

class MultipleArtModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    assignments: PropTypes.array,
    activeOrderItem: PropTypes.object,
  };

  state = {
    open: false,
    selectedAssignmentIndex: 0,
    activeIndex: 0,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.onClose && this.props.onClose();
  };

  componentDidMount() {
    const {open, activeOrderItem} = this.props;
    this.setState({open, activeOrderItem});
  }

  nextArt = () => {
    const {activeIndex} = this.state;
    const artLocations = this._getArtLocations();
    if (activeIndex + 1 >= artLocations.length) return;
    this.setState({activeIndex: activeIndex + 1});
  };

  previousArt = () => {
    const {activeIndex} = this.state;
    if (activeIndex === 0) return;
    this.setState({activeIndex: activeIndex - 1});
  };

  _getArtLocations = () => {
    const {activeOrderItem} = this.state;
    return _get(
      activeOrderItem,
      "orderItemProduct.orderItemProductAdornmentLocationArtAssignments",
      []
    );
  };

  render() {
    const {activeIndex, open} = this.state;
    const artLocations = this._getArtLocations();
    const artName = _get(artLocations, `[${activeIndex}].art.name`);
    const artActive = _get(artLocations, `[${activeIndex}].art.active`);
    const artLocation = _get(
      artLocations,
      `[${activeIndex}].productAdornmentLocation.adornmentLocation.location`
    );
    return (
      <div>
        <Dialog
          modal={false}
          open={open}
          onRequestClose={this.handleClose}
          contentStyle={artAssignmentModalStyles}
          contentClassName={"multiple-art-modal"}
          bodyStyle={{padding: 0}}
          overlayClassName={"dialog-overlay"}
        >
          <img
            onClick={() => this.handleClose()}
            className="archiveModal_closeIcon"
            src="../../../../assets/imgs/icons/close.svg"
          />

          <div className="art-assignments-modal__assignments">
            <div
              style={{marginRight: 31.5}}
              className={`arrow_container invert pointer ${activeIndex === 0 &&
                "disabled-button"}`}
              onClick={this.previousArt}
            >
              <img style={{width: "35px"}}  className="" src="/assets/imgs/icons/breadcrumb.svg" />
            </div>
            <div className="assignments__art">
              <span style={artActive ? {} : {color: "red"}}>
                {artName} ({artLocation})
              </span>

              <CNGImage
                src={_get(artLocations, `[${activeIndex}].art.imageUrl`)}
                placeHolderStyle={{
                  height: "calc(100% - 50px)",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{marginLeft: 31.5}}
              className={`arrow_container hover ${activeIndex + 1 >=
                artLocations.length && "disabled-button"}`}
              onClick={this.nextArt}
            >
              <img style={{width: "35px"}} className="" src="/assets/imgs/icons/breadcrumb.svg" />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default MultipleArtModal;
