import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../shared/CNGTable";

export default class ProductDetailOrderHistoryTab extends Component {
  static propTypes = {
    inventoryItems: PropTypes.array,
    isLoading: PropTypes.bool,
  };
  _renderRow(data, index) {
    const {order} = data;
    if (!order) return;
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn
          customStyles={{
            width: 165,
            paddingLeft: 40,
          }}
          highlight={true}
        >
          <Link
            to={`/companies/${order.companyId}/orders/${order.id}`}
            className="no-link-styles"
            title={order.jobName}
          >
            {order && order.jobName}
            <div className="subtext">{order.cngReferenceNumber}</div>
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 165,
          }}
        >
          {data.orderItem && data.orderItem.orderQuantity}
        </CNGTableRowColumn>
        <CNGTableRowColumn isOrderTotal={true}>
          {Number(data.quantity) - Number(data.usedQuantity)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      {
        columnContent: "CNG Ref. No.",
        customStyles: {
          width: 165,
          paddingLeft: 40,
        },
      },
      {
        columnContent: "TOTAL ORDERED",
        customStyles: {
          width: 165,
        },
      },
      {
        columnContent: "# REMAINING",
      },
    ];
    const {isLoading} = this.props;
    return (
      <CNGTable
        isLoading={isLoading}
        tableHeader={header}
        displayRowCheckbox={false}
      >
        {this.props.inventoryItems.map((row, index) => {
          return this._renderRow(row, index);
        })}
      </CNGTable>
    );
  }
}
