import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {fetchCountries} from "../../../actions/geoActions";
import {
  fetchCompany,
  createCompanyContact,
} from "../../../actions/companyActions";
import {push} from "react-router-redux";

import ContactCreatePage from "../../pages/Contacts/ContactCreatePage";

class ContactCreate extends Component {
  static propTypes = {
    fetchCountries: PropTypes.func,
    fetchCompany: PropTypes.func,
    createCompanyContact: PropTypes.func,
    company: PropTypes.object,
    match: PropTypes.object,
  };

  componentWillMount() {
    const {match: {params: {company_id}}} = this.props;
    this.props.fetchCountries();
    company_id && this.props.fetchCompany(company_id, "");
    window.closeSplash();
  }

  render() {
    return (
      <ContactCreatePage
        createCompanyContact={contact =>
          this.props.createCompanyContact(contact)
        }
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.companies.isLoadingCompanyContactRequest,
    countries: state.geo.countries,
    company: state.companies.company,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCompanyContact: contact => dispatch(createCompanyContact(contact)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompany: id => dispatch(fetchCompany(id)),
    navigateTo: payload => dispatch(push(payload)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactCreate)
);
