import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function inventoryReducer(
  state = initialState.inventory,
  {type, payload, meta}
) {
  switch (type) {
  case actions.FETCH_INVENTORY_ITEMS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_INVENTORY_ITEMS_SUCCESS:
    return {
      ...state,
      inventoryItems: payload,
      inventoryItemsCount: meta.count,
      isLoading: false,
    };
  case actions.FETCH_INVENTORY_ITEMS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_INVENTORY_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_INVENTORY_SUCCESS:
    return {
      ...state,
      inventory: payload,
      isLoading: false,
    };
  case actions.SEARCH_INVENTORY_ITEMS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.SEARCH_INVENTORY_ITEMS_SUCCESS:
    return {
      ...state,
      inventoryItems: payload,
      inventoryItemsCount: meta.count,
      isLoading: false,
    };
  case actions.SEARCH_INVENTORY_ITEMS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_INVENTORY_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_INVENTORY_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_INVENTORY_ITEM_SUCCESS:
    return {
      ...state,
      inventoryItem: payload,
      isLoading: false,
    };
  default:
    return state;
  }
}
