import React, {Component} from "react";
import PropTypes from "prop-types";

import {formStyles} from "../../../../styles/components/formStyles";

import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import CNGMenuItem from "../../../shared/CNGMenuItem";
import AdornmentTypeForm from "./AdornmentTypeForm";
import ArtFileInputContainer from "./ArtFileInputContainer";
import SectionHeading from "../../../shared/SectionHeading";

import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";

const adornmentSelectStyle = {
  ...formStyles.TextInputStyles,
  width: 300,
};

const artNameStyle = {
  ...formStyles.TextInputStyles,
  marginRight: 48,
};
const artFormDetails = {
  paddingLeft: 50,
};

const artForm = {
  marginTop: 45,
};

export default class ArtForm extends Component {
  static propTypes = {
    file: PropTypes.object,
    filename: PropTypes.string,
    name: PropTypes.string,
    adornment_type_id: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onUpdateImage: PropTypes.func,
    adornmentTypes: PropTypes.array,
    selectedArt: PropTypes.object,
    details: PropTypes.object,
    description: PropTypes.string,
    isInternal: PropTypes.bool,
    fileInputText: PropTypes.string,
    fileInputIcon: PropTypes.string,
    showUploadModal: PropTypes.bool,
    submitted: PropTypes.bool.isRequired,
    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,
    isDisabled: PropTypes.bool,
  };

  validations = {
    name: {
      format: "any",
      length: [2, 30],
    },
    adornment_type_id: {
      format: "integer",
    },
    filename: {
      format: "any",
      nullable: false,
    },
  };

  state = {
    errors: {},
    submitted: false,
    adornmentTypeFormValid: true,
  };

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.submitted === "undefined") {
      return;
    }

    const {submitted} = this.state;
    this.setState(
      {
        submitted: nextProps.submitted,
      },
      () => {
        if (nextProps.submitted && !submitted) {
          this.triggerValidations();
        }
      }
    );
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    const {adornmentTypeFormValid} = this.state;
    if (!valid || !adornmentTypeFormValid) return submitError();
    submitSuccess();
  }

  _validate() {
    const errors = validateForm(this);
    this.setState({errors});

    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  _getSelectedAdornmentType = () => {
    const {adornment_type_id, adornmentTypes = []} = this.props;

    return adornmentTypes.find(
      adornmentType => adornmentType.id == adornment_type_id
    );
  };

  _handleAdornmentTypeFormValidChange = valid => {
    this.setState({adornmentTypeFormValid: valid});
  };

  _getArtLatestRevision() {
    const {selectedArt} = this.props;
    if (!selectedArt) return null;
    const {revisions} = selectedArt;
    return Array.isArray(revisions) && revisions.length ? revisions[0] : null;
  }

  _adormentTypeOptions() {
    const {adornmentTypes} = this.props;
    return (
      adornmentTypes &&
      adornmentTypes.map(adornment => {
        return (
          <CNGMenuItem
            key={adornment.id}
            value={adornment.id}
            primaryText={adornment.name}
          />
        );
      })
    );
  }

  render() {
    const {
      onChange,
      onFileChange,
      onUpdateImage,
      adornment_type_id,
      adornmentTypes = [],
      file,
      name,
      selectedArt,
      isInternal,
      fileInputText,
      fileInputIcon,
      showUploadModal,
      onDetailChange,
      details,
      isDisabled,
    } = this.props;

    const latestRevision = this._getArtLatestRevision();

    return (
      <div className="art-form row" style={artForm}>
        <ArtFileInputContainer
          errorText={this.state.errors.filename}
          file={file}
          onFileChange={inputFile => {
            onFileChange(inputFile);
            this._validateDebounced();
          }}
          art={selectedArt}
          latestRevision={latestRevision}
          isInternal={isInternal}
          fileInputText={fileInputText}
          fileInputIcon={fileInputIcon}
          showDialogOption={showUploadModal}
          onUpdateImage={onUpdateImage}
        />
        <div style={artFormDetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 28,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SectionHeading
                text="Art Name"
                textTransform="none"
                fontSize={16}
                fontColor="#4A5158"
                fontWeight="normal"
              />
              <ValidableTextField
                autoFocus
                label="Art Name"
                type="text"
                name="name"
                value={name || ""}
                handleChange={onChange}
                style={artNameStyle}
                errorText={this.state.errors.name}
                inputStyle={formStyles.TwoTextInputStyles}
                floatingLabelStyle={formStyles.TextFieldLabelStyles}
                underlineStyle={formStyles.underlineStyle}
                validate={() => this._validateDebounced()}
              />
            </div>
          </div>
          <SectionHeading
            text="Adornment Details"
            textTransform="none"
            fontSize={16}
            fontColor="#4A5158"
            fontWeight="normal"
            marginTop={16}
          />
          <ValidableSelect
            name="adornment_type_id"
            label="Adornment Details"
            errorText={this.state.errors.adornment_type_id}
            value={`${adornment_type_id}` || ""}
            handleChange={(e, index, value) => {
              this._handleAdornmentTypeFormValidChange(false);
              this._validate();
              onChange(e, index, Number(value), "adornment_type_id");
            }}
            style={adornmentSelectStyle}
            inputStyle={formStyles.HalfTextInputStyles}
            menuStyle={{
              ...formStyles.MenuStyle,
              width: 320,
            }}
            disabled={isDisabled}
            underlineStyle={formStyles.underlineStyle}
            underlineDisabledStyle={formStyles.underlineDisabledStyle}
            options={this._adormentTypeOptions()}
          />
          <AdornmentTypeForm
            isDisabled={isDisabled}
            details={details}
            adornmentTypeId={adornment_type_id}
            adornmentTypes={adornmentTypes}
            onDetailChange={onDetailChange}
            onValidChange={this._handleAdornmentTypeFormValidChange}
            submitted={this.state.submitted}
          />
        </div>
      </div>
    );
  }
}
