import React, {Component} from "react";

import Flex from "../Base/Flex";
import {LayoutPropTypes} from "../Base/LayoutProps";

export default class Row extends Component {
  static propTypes = {
    ...LayoutPropTypes,
  };

  render() {
    return <Flex {...this.props} flexDirection={"column"} />;
  }
}
