import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ChatCenter from "./ChatCenter";
import {fetchChatsUnreadCounts} from "../../../../actions/v2/chatActions";
import Messenger from "../../../../services/MessagingService";

function mapStateToProps(state) {
  const {auth: {isAuthenticated, user, isInternal}} = state;
  const userData = {
    id: "internal",
    name: "Chill N Go",
  };
  const messenger = new Messenger(userData);
  return {
    isAuthenticated,
    user,
    isInternal,
    messenger,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChatsUnreadCounts: bindActionCreators(
      fetchChatsUnreadCounts,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatCenter)
);
