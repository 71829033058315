import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _get from "lodash/get";
import Dialog from "material-ui/Dialog";
import CNGImage from "../../../shared/CNGImage";

const artAssignmentModalStyles = {
  height: "85vh",
  maxWidth: 700,
  padding: 0,
  borderRadius: 4,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 8px 0 rgba(0,0,0,0.3)",
  margin: "-52px auto",
};

class ImagesModal extends Component {
  state = {
    selectedImageIndex: 0,
    activeIndex: 0,
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose && onClose();
  };

  nextArt = () => {
    const {activeIndex} = this.state;
    const artLocations = this.getImages();
    if (activeIndex + 1 >= artLocations.length) return;
    this.setState({activeIndex: activeIndex + 1});
  };

  previousArt = () => {
    const {activeIndex} = this.state;
    if (activeIndex === 0) return;
    this.setState({activeIndex: activeIndex - 1});
  };

  getImages = () => {
    const {images} = this.props;
    return images || [];
  };

  render() {
    const {open} = this.props;
    const {activeIndex} = this.state;
    const images = this.getImages();
    const title = _get(images, `[${activeIndex}].name`);
    return (
      <Dialog
        modal={false}
        open={open}
        onRequestClose={this.handleClose}
        contentStyle={artAssignmentModalStyles}
        contentClassName="multiple-art-modal"
        bodyStyle={{padding: 0}}
        overlayClassName="dialog-overlay"
      >
        <img
          onClick={this.handleClose}
          className="archiveModal_closeIcon"
          src="../../../../assets/imgs/icons/close.svg"
        />

        <div className="art-assignments-modal__assignments">
          <div
            style={{marginRight: 31.5}}
            className={classNames("arrow_container invert pointer", {
              "disabled-button": activeIndex === 0,
            })}
            onClick={this.previousArt}
          >
            <img style={{width: "35px"}} className="" src="/assets/imgs/icons/breadcrumb.svg" />
          </div>
          <div className="assignments__art">
            <span>{title}</span>

            <CNGImage
              src={_get(images, `[${activeIndex}].imageUrl`)}
              placeHolderStyle={{
                height: "calc(100% - 50px)",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{marginLeft: 31.5}}
            className={classNames("arrow_container hover", {
              "disabled-button": activeIndex + 1 >= images.length,
            })}
            onClick={this.nextArt}
          >
            <img style={{width: "35px"}} className="" src="/assets/imgs/icons/breadcrumb.svg" />
          </div>
        </div>
      </Dialog>
    );
  }
}

ImagesModal.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  onClose: PropTypes.func,
};

export default ImagesModal;
