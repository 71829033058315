import * as types from "../constants/actionTypes";
import RequestService from "../services/RequestService";

import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchUsersRequest = () => makeCommonAction(types.FETCH_USERS_REQUEST);
const fetchUsersSuccess = payload =>
  makeCommonAction(types.FETCH_USERS_SUCCESS, payload);
const fetchUsersError = error =>
  makeCommonAction(types.FETCH_USERS_ERROR, error, true);

const fetchUserRequest = () => makeCommonAction(types.FETCH_USER_REQUEST);
const fetchUserSuccess = payload =>
  makeCommonAction(types.FETCH_USER_SUCCESS, payload);
const fetchUserError = error =>
  makeCommonAction(types.FETCH_USER_ERROR, error, true);

const archiveUserRequest = () => makeCommonAction(types.ARCHIVE_USER_REQUEST);
const archiveUserSuccess = payload =>
  makeCommonAction(types.ARCHIVE_USER_SUCCESS, payload);
const archiveUserError = error =>
  makeCommonAction(types.ARCHIVE_USER_ERROR, error, true);

const createUserRequest = () => makeCommonAction(types.CREATE_USER_REQUEST);
const createUserSuccess = payload =>
  makeCommonAction(types.CREATE_USER_SUCCESS, payload);
const createUserError = error =>
  makeCommonAction(types.CREATE_USER_ERROR, error, true);

const updateUserRequest = () => makeCommonAction(types.UPDATE_USER_REQUEST);
const updateUserSuccess = payload =>
  makeCommonAction(types.UPDATE_USER_SUCCESS, payload);
const updateUserError = error =>
  makeCommonAction(types.UPDATE_USER_ERROR, error, true);

const createUserNoteRequest = () =>
  makeCommonAction(types.CREATE_USER_NOTE_REQUEST);
const createUserNoteSuccess = payload =>
  makeCommonAction(types.CREATE_USER_NOTE_SUCCESS, payload);
const createUserNoteError = error =>
  makeCommonAction(types.CREATE_USER_NOTE_ERROR, error, true);

const deleteUserNoteRequest = () =>
  makeCommonAction(types.DELETE_USER_NOTE_REQUEST);
const deleteUserNoteSuccess = payload =>
  makeCommonAction(types.DELETE_USER_NOTE_SUCCESS, payload);
const deleteUserNoteError = error =>
  makeCommonAction(types.DELETE_USER_NOTE_ERROR, error, true);

const USERS_DEFAULT_ORDER_BY = JSON.stringify([
  {
    field: "first_name",
    direction: "asc",
  },
  {
    field: "first_name",
    direction: "asc",
  },
]);

export function fetchUsers(options) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      "users",
      "[recipient.[country],companies]",
      {
        orderBy:
          options && options.orderBy ? options.orderBy : USERS_DEFAULT_ORDER_BY,
        ...options,
      }
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUsersRequest,
    successFunc: fetchUsersSuccess,
    errorFunc: fetchUsersError,
    afterReqFunc: response =>
      (response.meta["currentPage"] = (options && options.page) || 1),
    error_message: "Unable to get users.",
  });
}

export function fetchUsersByCompany(id, options) {
  const url = RequestService.buildIndexUrl(
    `usersbycompany/${id}`,
    options.include,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUsersRequest,
    successFunc: fetchUsersSuccess,
    errorFunc: fetchUsersError,
    afterReqFunc: response => (response.meta["currentPage"] = 1),
    error_message: "Unable to get users.",
  });
}

export function searchUsers(query, options) {
  const searchOptions = options || {
    orderBy: USERS_DEFAULT_ORDER_BY,
  };
  const url = RequestService.buildSearchUrl(
    "users",
    "[companies,country,recipient.country]",
    query,
    searchOptions
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUsersRequest,
    successFunc: fetchUsersSuccess,
    errorFunc: fetchUsersError,
    afterReqFunc: response => (response.meta["currentPage"] = 1),
    error_message: "Unable to get users.",
  });
}

export function searchUsersByCompany(id, query, options) {
  const searchOptions = options || {
    orderBy: "first_name",
    orderDirection: "ASC",
  };
  const url = RequestService.buildSearchUrl(
    `usersbycompany/${id}`,
    searchOptions.include,
    query,
    searchOptions
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUsersRequest,
    successFunc: fetchUsersSuccess,
    errorFunc: fetchUsersError,
    afterReqFunc: response => (response.meta["currentPage"] = 1),
    error_message: "Unable to get users.",
  });
}

export function fetchUser(id) {
  return makeActionCreator({
    endpoint: `/users/${id}/?include=[companies,notes.createdByUser,recipient.[country,walletMembers],companyAssociations]`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchUserRequest,
    successFunc: fetchUserSuccess,
    errorFunc: fetchUserError,
    error_message: "Error fetching user",
  });
}

export function archiveUser(id) {
  return makeActionCreator({
    endpoint: `/users/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: archiveUserRequest,
    successFunc: archiveUserSuccess,
    errorFunc: archiveUserError,
    error_message: "Error deleteing user",
  });
}

export function createUserWithCompanies(user) {
  return makeActionCreator({
    endpoint: "/createuserwithcompanies/",
    fetchOptions: {
      body: user,
      method: "POST",
    },
    requestFunc: createUserRequest,
    successFunc: createUserSuccess,
    errorFunc: createUserError,
    error_message: "Error creating user",
    successMessage: "User created successfully",
  });
}

export function updateUserCompanyAssociation(
  companyId,
  userId,
  data,
  callback
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/users/${userId}/association`,
    fetchOptions: {
      body: data,
      method: "PATCH",
    },
    requestFunc: updateUserRequest,
    successFunc: () => {
      callback && callback();
      return updateUserSuccess();
    },
    errorFunc: updateUserError,
    error_message: "Error updating user",
    successMessage: "Users saved successfully",
  });
}
export function updateUserWithCompanies(user) {
  return makeActionCreator({
    endpoint: `/updateuserwithcompanies/${user.id}`,
    fetchOptions: {
      body: user,
      method: "PUT",
    },
    requestFunc: updateUserRequest,
    successFunc: updateUserSuccess,
    errorFunc: updateUserError,
    error_message: "Error updating user",
    successMessage: "User updated successfully",
  });
}
export function resetWelcomeEmail(userId) {
  return makeActionCreator({
    endpoint: `/users/${userId}?send_welcome_email=true`,
    fetchOptions: {
      body: {},
      method: "PUT",
    },
    requestFunc: updateUserRequest,
    successFunc: updateUserSuccess,
    errorFunc: updateUserError,
    error_message: "Error updating user",
    successMessage: "Welcome email was sent successfully",
  });
}

export function createUserNote(newNote) {
  return makeActionCreator({
    endpoint: "/usernotes?include=[createdByUser]",
    fetchOptions: {
      body: newNote,
      method: "POST",
    },
    requestFunc: createUserNoteRequest,
    successFunc: createUserNoteSuccess,
    errorFunc: createUserNoteError,
    error_message: "Error deleting user note",
  });
}

export function deleteUserNote(noteId) {
  return makeActionCreator({
    endpoint: `/usernotes/${Number(noteId)}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteUserNoteRequest,
    successFunc: deleteUserNoteSuccess,
    errorFunc: deleteUserNoteError,
    error_message: "Error deleting user note",
  });
}
