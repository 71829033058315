import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function adornmentTypeReducer(
  state = initialState.adornmentTypes,
  {type, payload}
) {
  switch (type) {
  case actions.FETCH_ADORNMENT_TYPE_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_ADORNMENT_TYPE_SUCCESS:
    return {
      ...state,
      adornmentTypes: payload,
      isLoading: false,
    };
  case actions.FETCH_ADORNMENT_TYPE_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  default:
    return state;
  }
}
