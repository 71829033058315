import React, {Component} from "react";
import PropTypes from "prop-types";
import {Tabs, Tab} from "material-ui/Tabs";

import SearchInput from "../../../shared/SearchInput";
import Pagination from "../../../shared/Pagination";

import ProductDetailOrderHistoryTab from "./ProductDetailOrderHistoryTab";
import ProductDetailShipmentHistoryTab from "./ProductDetailShipmentHistoryTab";

const ORDER_HISTORY_INDEX = 0;

const inkBarStyle = {
  backgroundColor: "#34b8dd",
  height: 2,
  width: 150,
};
const inkBarContainerStyle = {
  width: 300,
};
const tabItemContainerStyle = {
  background: "transparent",
  width: "100%",
  borderBottom: "1px solid #BDC6CF",
};
const tabButtonStyle = {
  color: "rgba(12, 83, 113, 0.5)",
  width: 148,
  fontSize: 12,
  fontWeight: "bold",
  transition: "600ms color linear",
};
const selectedTabStyle = {
  ...tabButtonStyle,
  color: "#0C5371",
};
const tabsStyle = {
  marginBottom: -7,
};

export default class ProductDetailTabs extends Component {
  static propTypes = {
    inventoryItemsCount: PropTypes.number,
    shipmentsCount: PropTypes.number,
    company: PropTypes.object,
    inventoryItems: PropTypes.array,
    productShipments: PropTypes.array,
    productShipmentCount: PropTypes.number,
    searchProductShipments: PropTypes.func,
    searchInventoryItems: PropTypes.func,
    updateShipmentItem: PropTypes.func,
    openNotification: PropTypes.func,
    isInternal: PropTypes.bool,
    isLoadingInventory: PropTypes.bool,
    isLoadingShipment: PropTypes.bool,
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func,
    addQueryParam: PropTypes.func,
    tab: PropTypes.number,
  };

  state = {
    currentPage: {
      orders: 1,
      shipments: 1,
    },
    selectedTab: 0,
    searchOrderTerm: "",
  };

  componentDidMount() {
    document.getElementsByClassName("PageContainer")[0].scrollTop = 0;
    this.setState({selectedTab: this.props.tab});
  }

  _getTabButtonStyle(tabId) {
    const tabNumber = {
      orders: 0,
      shipment: 1,
    }[tabId];
    return this.state.selectedTab === tabNumber
      ? selectedTabStyle
      : tabButtonStyle;
  }

  _renderPagination(count) {
    const {selectedTab, currentPage, searchOrderTerm} = this.state;
    return (
      <div className="flex justify-content-center align-items-start">
        {count > 7 ? (
          <Pagination
            getData={({page}) => {
              const selected = ORDER_HISTORY_INDEX ? "orders" : "shipments";
              this.setState({
                currentPage: {
                  ...currentPage,
                  [selected]: page,
                },
              });
              this.handleSearch(null, searchOrderTerm, page);
            }}
            count={count}
            currentPage={
              selectedTab === ORDER_HISTORY_INDEX
                ? currentPage.orders - 1
                : currentPage.shipments - 1
            }
          />
        ) : null}
      </div>
    );
  }

  _setActiveTab = ({props: {index}}) => {
    this.setState({selectedTab: index});
  };

  handleSearch = (_, searchTerm, page = 1) => {
    const {selectedTab} = this.state;
    this.props.setSearchTerm(searchTerm);
    const {searchInventoryItems, searchProductShipments} = this.props;
    if (selectedTab === ORDER_HISTORY_INDEX) {
      searchInventoryItems(searchTerm, null, page);
    } else {
      searchProductShipments(searchTerm, null, page);
    }
  };

  _onTabChange(nextTab) {
    this.props.addQueryParam({tab: nextTab, query: ""});
    this.setState(
      {
        selectedTab: nextTab,
      },
      () => this.handleSearch(null, "")
    );
  }

  render() {
    const {
      productShipments,
      productShipmentCount,
      inventoryItems,
      inventoryItemsCount,
      updateShipmentItem,
      openNotification,
      isInternal,
      isLoadingInventory,
      isLoadingShipment,
      searchTerm,
    } = this.props;

    return (
      <Tabs
        inkBarContainerStyle={inkBarContainerStyle}
        tabItemContainerStyle={tabItemContainerStyle}
        value={this.state.selectedTab}
        onChange={value => this._onTabChange(value)}
        inkBarStyle={inkBarStyle}
        style={tabsStyle}
      >
        <Tab
          label="Order History"
          value={0}
          style={tabButtonStyle}
          buttonStyle={this._getTabButtonStyle("orders")}
        >
          <div className="tab-container">
            <div className="tab_top">
              <SearchInput
                initialValue={searchTerm}
                search={this.handleSearch}
              />{" "}
              {this._renderPagination(inventoryItemsCount)}
            </div>
            <ProductDetailOrderHistoryTab
              isLoading={isLoadingInventory}
              inventoryItems={inventoryItems}
            />
          </div>
        </Tab>
        <Tab
          label="Shipment History"
          style={tabButtonStyle}
          value={1}
          buttonStyle={this._getTabButtonStyle("shipment")}
        >
          <div className="tab-container">
            <div className="tab_top">
              <SearchInput
                initialValue={searchTerm}
                search={this.handleSearch}
              />{" "}
              {this._renderPagination(productShipmentCount)}
            </div>
            <ProductDetailShipmentHistoryTab
              isLoading={isLoadingShipment}
              shipments={productShipments}
              updateShipmentItem={updateShipmentItem}
              openNotification={openNotification}
              isInternal={isInternal}
            />
          </div>
        </Tab>
      </Tabs>
    );
  }
}
