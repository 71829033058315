import React, {Component} from "react";
import PropTypes from "prop-types";

import CNGMenuItem from "../../../shared/CNGMenuItem";
import {formStyles} from "../../../../styles/components/formStyles";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import StateSelect from "../../../shared/StateSelect";
import Divider from "material-ui/Divider";
import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";
import {isCountryUSAOrCanada} from "../../../../utils/countryRegionsHelper";
import {USA_COUNTRY_ID} from "../../../../constants/country";
import PhoneNumberInput from "../../../shared/PhoneNumberInput";

export default class ContactForm extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    submitSuccess: PropTypes.func,
    submitError: PropTypes.func,

    companyName: PropTypes.string,
    userId: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    state: PropTypes.string,
    countryId: PropTypes.number,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    companyContact: PropTypes.object,

    errors: PropTypes.object,
    submitted: PropTypes.bool,
  };
  validations = {
    companyName: {
      format: "any",
      length: [2, 50],
      nullable: true,
    },
    name: {
      format: "any",
      length: [2, 60],
    },
    phoneNumber: {
      format: "any",
      nullable: true,
      length: [10],
    },
    emailAddress: {
      format: "email",
      nullable: true,
    },
    address1: {
      format: "any",
      length: [2, 255],
    },
    address2: {
      format: "any",
      length: [2, 255],
      nullable: true,
    },
    state: {
      format: "any",
    },
    countryId: {
      format: "integer",
    },
    city: {
      format: "any",
      length: [2, 30],
    },
  };

  isSelectedUSA() {
    const {countryId} = this.props;
    return Number(countryId) === USA_COUNTRY_ID;
  }

  state = {
    errors: {},
    submitted: false,
  };

  countryIsUnitedStates = () => {
    const {countries, countryId} = this.props;
    if ((countries && !countries.length) || !countryId) return false;
    const country = countries.find(({id}) => id == countryId);
    return country && country.name === "United States";
  };

  componentDidUpdate(prevProps) {
    const {submitted} = prevProps;
    if (this.props.submitted !== submitted) {
      this.setState(
        {
          submitted: this.props.submitted,
        },
        () => {
          if (this.props.submitted && !submitted) {
            this.triggerValidations();
          }
        }
      );
    }
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    const {submitSuccess, submitError} = this.props;
    if (!valid) return submitError();
    submitSuccess();
  }

  _validate() {
    const {countryId} = this.props;
    if (this.isSelectedUSA()) {
      this.validations["zipCode"] = {
        format: "integer",
        shouldMatch: value => {
          return value && value.length === 5;
        },
        shouldMatchMessage: "invalid zipcode should be 5 characters long",
      };
      this.validations["state"] = {
        format: "any",
      };
    } else {
      this.validations["zipCode"] = {
        format: "any",
        nullable: true,
      };
      this.validations["state"] = {
        format: "any",
        nullable: true,
      };
    }
    if (isCountryUSAOrCanada(countryId)) {
      this.validations["phoneNumber"] = {
        format: "any",
        nullable: true,
        length: [10],
      };
    } else {
      this.validations["phoneNumber"] = {
        format: "any",
        nullable: true,
      };
    }
    const errors = validateForm(this);
    this.setState({errors});

    return errors;
  }
  _validateDebounced = debounce(this._validate, 300);

  _getCountrySelectOptions() {
    const {countries} = this.props;
    return countries.reduce((countryOptions, country) => {
      const countryElement = (
        <CNGMenuItem
          key={country.id}
          value={country.id}
          primaryText={country.name}
        />
      );
      if (["USA", "US"].includes(country.code)) {
        return [
          countryElement,
          <Divider key={"usa_divider"} />,
          ...countryOptions,
        ];
      }
      countryOptions.push(countryElement);
      return countryOptions;
    }, []);
  }

  _renderPersonalFields() {
    const {
      handleChange,
      companyContact = {},
      countries,
      countryId,
      state,
    } = this.props;
    const selectedCountry = countries.find(
      country => Number(country.id) === countryId
    );
    const countryCode = selectedCountry ? selectedCountry.code : "US";

    return (
      <div>
        <div className="flex">
          <ValidableTextField
            name="companyName"
            label="Company Name"
            value={this.props.companyName}
            errorText={this.state.errors.companyName}
            disabled={companyContact.userId ? true : false}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
            autoFocus={true}
          />
          <ValidableTextField
            name="name"
            label="Recipient Name"
            value={this.props.name}
            errorText={this.state.errors.name}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
        </div>
        <div className="flex">
          <ValidableSelect
            name="countryId"
            label="Country"
            value={countryId}
            errorText={this.state.errors.countryId}
            handleChange={(e, index, value) => {
              handleChange(e, index, Number(value), "countryId");
              handleChange(e, null, null, "state");
            }}
            options={this._getCountrySelectOptions()}
            iconStyle={formStyles.CountryIconStyles}
            menuItemStyle={formStyles.wrappedMenuItemStyle}
          />
        </div>
        <div className="flex">
          <PhoneNumberInput
            name="phoneNumber"
            label="Phone"
            value={this.props.phoneNumber}
            countryCode={countryCode}
            errorText={this.state.errors.phoneNumber}
            handleChange={(e, value) =>
              this.props.handleChange(
                e,
                null,
                value.replace(/\D/g, ""),
                "phoneNumber"
              )
            }
            validate={() => this._validateDebounced()}
          />
          <ValidableTextField
            name="emailAddress"
            label="Email"
            value={this.props.emailAddress}
            errorText={this.state.errors.emailAddress}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
        </div>
        <div className="flex">
          <ValidableTextField
            name="address1"
            label="Address 1"
            value={this.props.address1}
            errorText={this.state.errors.address1}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
          <ValidableTextField
            name="address2"
            label="Address 2"
            value={this.props.address2}
            errorText={this.state.errors.address2}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
          />
        </div>
        <div className="flex">
          <ValidableTextField
            name="city"
            label="City"
            value={this.props.city}
            errorText={this.state.errors.city}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
            style={formStyles.CityTextFieldStyles}
            inputStyle={formStyles.CityTextFieldStyles}
          />
          <StateSelect
            countryCode={countryCode}
            value={state}
            errorText={this.state.errors.state}
            handleChange={handleChange}
            style={formStyles.halfSelectStyles}
            inputStyle={formStyles.halfSelectInputStyles}
            floatingLabelStyle={formStyles.halfSelectFloatingLabelStyles}
            menuStyle={formStyles.halfSelectMenuInputStyles}
            listStyle={formStyles.halfSelectMenuListStyles}
            iconStyle={{
              ...formStyles.StateIconStyles,
              right: -5,
            }}
          />
          <ValidableTextField
            name="zipCode"
            label="Zip Code"
            minLength={this.isSelectedUSA() ? 5 : null}
            maxLength={this.isSelectedUSA() ? 5 : null}
            value={this.props.zipCode}
            errorText={this.state.errors.zipCode}
            handleChange={(e, value) => handleChange(e, value)}
            validate={() => this._validateDebounced()}
            style={formStyles.PostCodeStyles}
            inputStyle={formStyles.PostCodeInputStyles}
          />
        </div>
      </div>
    );
  }
  render() {
    return <div className="user-form">{this._renderPersonalFields()}</div>;
  }
}
