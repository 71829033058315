import React, {Component} from "react";
import TextField from "material-ui/TextField";
import lodash from "lodash";
import PropTypes from "prop-types";

import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../../../../styles/components/formStyles";

import RecipientForm from "../../../../containers/DSOD/RecipientFormContainer";
import CNGCard from "../../../../shared/CNGCard";
import LineHeader from "../../../../shared/LineHeader";
import RecipientTable from "../RecipientTable";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";

const closeIconStyle = {
  color: "#0C5371",
  fontWeight: "300",
  marginLeft: 4,
};

const BeforeCardTitle = {
  color: "#747D86",
  fontSize: 12,
  display: "block",
  lineHeight: 2,
};

const RemoveStyle = {
  cursor: "pointer",
  color: "#0C5271",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 14,
};

const ItemStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
  },

  data: {
    color: "#747D86",
    fontSize: 14,
    lineHeight: "20px",
    height: 18,
  },

  dataLink: {
    color: "#0C5271",
    fontWeight: "bold",
  },
};

export default class ReorderItemCard extends Component {
  static propTypes = {
    reorderItem: PropTypes.object,
    reorderItems: PropTypes.array,
    shipments: PropTypes.array,
    updateOrderItem: PropTypes.func,
    disableRecipient: PropTypes.bool,
    onItemUpdated: PropTypes.func,
    onItemArchived: PropTypes.func,
    companyId: PropTypes.number,
    companyContacts: PropTypes.array,
    activeOption: PropTypes.string,
    selectedRecipient: PropTypes.object,
    selectedOrderItem: PropTypes.object,
    onSelectedOption: PropTypes.func,
    shipmentItemSource: PropTypes.object,
    cleanSelectedAction: PropTypes.func,
    hasInvalidMiscellaneous: PropTypes.func,
    isDisabled: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRecipientForm: false,
      reorderItem: props.reorderItem,
      errors: {},
    };
  }

  handleToggleRecipientForm = () => {
    if (this.props.activeOption) {
      this.props.cleanSelectedAction();
    } else {
      this.setState({
        showRecipientForm: !this.state.showRecipientForm,
      });
    }
  };

  handleUpdateOrderItem = async event => {
    event && event.preventDefault();
    const {
      updateOrderItem,
      reorderItem,
      openNotification,
      onItemUpdated,
    } = this.props;
    const {reorderItem: {orderQuantity}, errors} = this.state;

    if (errors.orderQuantity) return;
    if (!orderQuantity) {
      this.setState({
        reorderItem: {
          ...this.state.reorderItem,
          orderQuantity: 0,
        },
      });
    }

    try {
      await updateOrderItem(reorderItem.id, {
        orderQuantity: orderQuantity || 0,
      });
      openNotification("Order item updated successfully");
      await onItemUpdated();
    } catch (error) {
      openNotification("Error Updating Order item");
    }
  };

  handleArchiveOrderItem = async () => {
    const {
      archiveOrderItem,
      reorderItem,
      openNotification,
      onItemArchived,
    } = this.props;

    try {
      await archiveOrderItem(reorderItem.id);
      openNotification("Order item was archived successfully");
      if (onItemArchived) onItemArchived();
    } catch (error) {
      openNotification("Error archiving Order item");
    }
  };

  hasInventoryQuantity = value => {
    const {reorderItem, shipments} = this.props;
    const shipment = shipments && shipments[0];
    return (
      shipment &&
      !shipment.recipientId &&
      Number(value) < Number(reorderItem.quantityToShip)
    );
  };

  handleChange = (event, value, formName) => {
    const {reorderItem} = this.props;
    const name = event ? event.target.name : formName;
    let errors = {};

    if (value.length > 0 && !value.match(/^\d+$/gi)) return;
    if (this.hasInventoryQuantity(value)) {
      errors["orderQuantity"] = `must be greater or equal to ${
        reorderItem.quantityToShip
      }`;
    }

    this.setState({
      errors,
      reorderItem: {
        ...this.state.reorderItem,
        [name]: value,
      },
    });
  };

  handleCopyToDifferenteOrderItem = async (
    shipmentItem,
    orderItem,
    quantity
  ) => {
    const {
      createShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await createShipmentItem({
        ...lodash.omit(lodash.cloneDeep(shipmentItem), ["id"]),
        shippedQuantity: quantity,
        orderItemId: orderItem,
      });
      await onShipmentUpdated();
      openNotification("Recipient was copied successfully");
    } catch (error) {
      openNotification("Error copying recipient");
    }
  };

  handleMoveToDifferenteOrderItem = async (
    shipmentItem,
    orderItem,
    quantity
  ) => {
    const {
      updateShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await updateShipmentItem(shipmentItem.id, {
        orderItemId: orderItem,
        shippedQuantity: quantity,
      });
      await onShipmentUpdated();
      openNotification("Recipient was moved successfully");
    } catch (error) {
      openNotification("Error moving recipient");
    }
  };

  handleDeleteShipmentItem = async shipmentItem => {
    const {
      deleteShipmentItem,
      openNotification,
      onShipmentUpdated,
    } = this.props;

    try {
      await deleteShipmentItem(shipmentItem.id);
      await onShipmentUpdated();
      openNotification("Recipient was deleted successfully");
    } catch (error) {
      openNotification("Error deleting recipient");
    }
  };

  _performOrDash = (value, callback) => {
    if (!value || value <= 0) return "-";

    return callback ? callback(value) : value;
  };

  renderItem = () => {
    if (!this.state.reorderItem) return null;

    const {reorderItem} = this.props;
    const {
      unitCostValue,
      quantityDiscountValue,
      unitAdornmentCostValue,
      netUnitCost,
      quantityToShip,
      quantityToInventory,
    } = reorderItem;
    const renderCurrencyColumn = (title, value) => (
      <div>
        <div style={ItemStyle.header}>{title}</div>
        <div style={ItemStyle.data}>
          {this._performOrDash(value, getCurrencyFormattedNumber)}
        </div>
      </div>
    );

    return (
      <div
        className="flex justify-content-between"
        style={{
          width: "75%",
          marginBottom: 26,
        }}
      >
        <div>
          <div style={ItemStyle.header}>Art used</div>
          <div
            style={{
              ...ItemStyle.dataLink,
            }}
          />
        </div>
        {renderCurrencyColumn("Unit cost", unitCostValue)}
        {renderCurrencyColumn("Qty. Discount", quantityDiscountValue)}
        {renderCurrencyColumn("Emb. Cost", unitAdornmentCostValue)}
        {renderCurrencyColumn("Net unit cost", netUnitCost)}

        <div>
          <div style={ItemStyle.header}>Total to ship</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(quantityToShip)}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header}>Total to inventory</div>
          <div style={ItemStyle.data}>
            {this._performOrDash(quantityToInventory)}
          </div>
        </div>
      </div>
    );
  };

  createShipmentItem = async (recipientId, quantity) => {
    const {
      createShipmentItem,
      reorderItem,
      shipments,
      openNotification,
      onItemUpdated,
    } = this.props;
    const shipment = shipments[0];
    try {
      await createShipmentItem({
        recipientId,
        shippedQuantity: quantity,
        orderItemId: reorderItem.id,
        shipmentId: shipment.id,
      });
      openNotification("Recipient was created successfully");
      onItemUpdated();
    } catch (error) {
      openNotification("Error creating Shipment Item");
    }
  };

  updateShipmentItem = async (shipmentItem, recipientId, quantity) => {
    const {
      updateShipmentItem,
      reorderItem,
      shipments,
      openNotification,
      onItemUpdated,
    } = this.props;
    const shipment = shipments[0];
    try {
      await updateShipmentItem(shipmentItem.id, {
        recipientId,
        shippedQuantity: quantity,
        orderItemId: reorderItem.id,
        shipmentId: shipment.id,
      });
      openNotification("Shipment Item was updated successfully");
      onItemUpdated();
    } catch (error) {
      openNotification("Error creating Shipment Item");
    }
  };

  handleSaveRecipient = (recipient, quantity) => {
    if (this.props.activeOption === "moveTo") {
      this.handleDeleteShipmentItem(this.props.shipmentItemSource);
    }
    this.createShipmentItem(recipient.id, quantity);
    this.props.cleanSelectedAction();
    this.setState({showRecipientForm: false});
  };

  getFilteredRecipients = () => {
    const {companyContacts} = this.props;
    const shipmentItems = this._getShipmentItemsByOrderItem();
    const recipientIds = shipmentItems.map(
      shipmentItem => shipmentItem.recipientId
    );
    return companyContacts.filter(
      contact => !recipientIds.includes(Number(contact.id))
    );
  };

  renderRecipient = () => {
    const {
      disableRecipient,
      companyId,
      reorderItem,
      activeOption,
      selectedRecipient,
      selectedOrderItem,
    } = this.props;
    if (disableRecipient) return;

    const filteredRecipients = this.getFilteredRecipients();

    const hasSelectedOption =
      activeOption && selectedOrderItem.id === reorderItem.id;
    const showRecipientForm = this.state.showRecipientForm || hasSelectedOption;

    return showRecipientForm ? (
      <RecipientForm
        hideIsProof
        onClose={this.handleToggleRecipientForm}
        containerStyle={{
          marginBottom: 32,
        }}
        companyId={companyId}
        companyContacts={filteredRecipients}
        showQuantityToShip={true}
        initialState={selectedRecipient}
        maxQuantityToShip={reorderItem.quantityToInventory}
        onSaveRecipient={this.handleSaveRecipient}
      />
    ) : (
      <LineHeader
        title="Add recipient"
        icon="add"
        onClick={this.handleToggleRecipientForm}
      />
    );
  };

  _getShipmentItemsByOrderItem = () => {
    const {shipments, reorderItem} = this.props;
    if (!shipments || !reorderItem) return [];
    const shipment = shipments[0];
    if (!shipment) return [];

    return shipment.items.filter(item => {
      return item.orderItemId === Number(reorderItem.id);
    });
  };

  _topRightContent = (errors, reorderItem) => {
    const {hasInvalidMiscellaneous} = this.props;
    const removeOption = (
      <span
        style={RemoveStyle}
        key="remove"
        onClick={this.handleArchiveOrderItem}
      >
        Remove
        <span key="remove-icon" style={closeIconStyle}>
          X
        </span>
      </span>
    );

    const recipientError = hasInvalidMiscellaneous(this.props.reorderItem)
      ? "All miscellaneous items must be assigned a recipient."
      : null;

    const quantityForm = (
      <form key="quantity_field" onSubmit={this.handleUpdateOrderItem}>
        {" "}
        <TextField
          floatingLabelText="Quantity *"
          type="text"
          name="orderQuantity"
          errorText={errors.orderQuantity || recipientError}
          value={reorderItem && reorderItem.orderQuantity}
          onChange={this.handleChange}
          floatingLabelStyle={TextFieldSmallLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          onBlur={this.handleUpdateOrderItem}
          style={{
            ...formStyles.quarterSelectInputStyles,
            marginRight: 32,
          }}
        />{" "}
      </form>
    );
    return [quantityForm, removeOption];
  };

  hasItemToOrder = () => {
    const {reorderItem} = this.props;

    return (
      reorderItem &&
      reorderItem.orderQuantity > 0 &&
      reorderItem.quantityToInventory > 0
    );
  };

  renderTitle = () => {
    const {reorderItem: {notes, isSewOut}} = this.props;
    if (isSewOut) {
      return (
        <div>
          <div style={BeforeCardTitle} />
          <span id="title">Sew-Out</span>
          <span style={BeforeCardTitle}>{notes}</span>
        </div>
      );
    }
    return (
      <div>
        <span style={BeforeCardTitle}>Miscellaneous</span>
        <span id="title">{notes}</span>
        <div style={BeforeCardTitle} />
      </div>
    );
  };

  render() {
    const {reorderItem, errors} = this.state;
    const {reorderItems, companyId, onSelectedOption, isDisabled} = this.props;

    const shipmentItems = this._getShipmentItemsByOrderItem();
    const filteredRecipients = this.getFilteredRecipients();
    const hasShipments = lodash.get(shipmentItems, "length", 0) > 0;

    return (
      <CNGCard
        title={this.renderTitle()}
        headerStyle={{
          alignItems: "center",
        }}
        topRightContent={this._topRightContent(errors, reorderItem)}
        style={{
          paddingBottom: 1,
          paddingTop: 27,
        }}
      >
        <div>
          {this.renderItem()}
          {hasShipments ? (
            <RecipientTable
              isDisabled={isDisabled}
              shipmentItems={shipmentItems}
              shipments={this.props.shipments}
              companyId={companyId}
              companyContacts={filteredRecipients}
              reorderItem={reorderItem}
              reorderItems={reorderItems}
              onSelectedOption={onSelectedOption}
              updateShipmentItem={this.updateShipmentItem}
              onCopyToDifferenteOrderItem={this.handleCopyToDifferenteOrderItem}
              onMoveToDifferenteOrderItem={this.handleMoveToDifferenteOrderItem}
              onDeleteShipmentItem={this.handleDeleteShipmentItem}
            />
          ) : null}
          {this.hasItemToOrder() ? this.renderRecipient() : null}
        </div>
      </CNGCard>
    );
  }
}
