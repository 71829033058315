import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../actions/userActions";
import {fetchCompany} from "../../../actions/companyActions";
import {push} from "react-router-redux";
import UserDetailPage from "../../pages/Users/UserDetailPage";

class UserDetail extends Component {
  componentWillMount() {
    const {fetchUser, fetchCompany, match: {params}} = this.props;
    fetchUser(params.id);
    if (params.company_id) {
      fetchCompany(params.company_id);
    }
  }

  render() {
    const {
      company,
      fetchUser,
      archiveUser,
      createUserNote,
      deleteUserNote,
      match: {params},
    } = this.props;
    return (
      <UserDetailPage
        userId={params.id}
        company={params.company_id && company}
        fetchUser={id => fetchUser(id)}
        archiveUser={id => archiveUser(id)}
        createUserNote={note => createUserNote(note)}
        deleteUserNote={id => deleteUserNote(id)}
        {...this.props}
      />
    );
  }
}

UserDetail.propTypes = {
  userDetail: PropTypes.object,
  fetchUser: PropTypes.func,
  archiveUser: PropTypes.func,
  createUserNote: PropTypes.func,
  deleteUserNote: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchStates: PropTypes.func,
  match: PropTypes.object,
  isInternal: PropTypes.bool,
  fetchCompany: PropTypes.func,
  company: PropTypes.object,
  loggedInUserId: PropTypes.number,
  userNoteRequestLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    userDetail: state.users.userDetail,
    isLoading: state.users.isLoading,
    isInternal: state.auth.isInternal,
    company: state.companies.company,
    loggedInUserId: state.auth.id,
    userNoteRequestLoading: state.users.userNoteRequestLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: id => dispatch(actions.fetchUser(id)),
    archiveUser: id => dispatch(actions.archiveUser(id)),
    createUserNote: note => dispatch(actions.createUserNote(note)),
    deleteUserNote: id => dispatch(actions.deleteUserNote(id)),
    navigateTo: payload => dispatch(push(payload)),
    fetchCompany: id => dispatch(fetchCompany(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetail)
);
