import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {List, ListItem} from "material-ui/List";

class ChatList extends Component {
  handleChannelClick = (channel, data) => () => {
    const {onChannelSelect} = this.props;
    onChannelSelect(data.userId, data.userName);
  };

  renderEmpty = () => {
    const {channels} = this.props;
    if (channels && channels.length) return;
    return <ListItem primaryText="No chats found" disabled />;
  };

  renderChannels = () => {
    const {channels} = this.props;
    if (!channels || !channels.length) return;
    return channels.map(channel => {
      const data = JSON.parse(channel.data);
      const unreadMessageCount = _get(channel, "unreadMessageCount");
      const isOnline =
        _get(channel, `memberMap.${data.userId}.connectionStatus`) === "online";
      return (
        <ListItem
          key={channel.name}
          primaryText={data.userName}
          secondaryText={_get(channel, "lastMessage.message")}
          rightIcon={
            <span>
              {unreadMessageCount ? (
                <span
                  className="badge"
                  style={{
                    top: 0,
                    left: -8,
                    textAlign: "center",
                    lineHeight: "22px",
                    fontSize: 12,
                  }}
                >
                  {unreadMessageCount}
                </span>
              ) : null}
              <span
                className="badge"
                style={{
                  top: 0,
                  right: -8,
                  backgroundColor: isOnline ? "lightgreen" : "lightgray",
                }}
              />
            </span>
          }
          onClick={this.handleChannelClick(channel, data)}
        />
      );
    });
  };

  render() {
    const {isLoading} = this.props;
    if (isLoading) {
      return (
        <List>
          <ListItem
            primaryText={<span className="flex loading">User Name</span>}
            secondaryText={<span className="flex loading">Last Message</span>}
            rightIcon={
              <span>
                <span
                  className="badge"
                  style={{
                    top: 0,
                    right: -8,
                    backgroundColor: "lightgray",
                  }}
                />
              </span>
            }
          />
          <ListItem
            primaryText={<span className="flex loading">User Name</span>}
            secondaryText={<span className="flex loading">Last Message</span>}
            rightIcon={
              <span>
                <span
                  className="badge"
                  style={{
                    top: 0,
                    right: -8,
                    backgroundColor: "lightgray",
                  }}
                />
              </span>
            }
          />
        </List>
      );
    }
    return (
      <List>
        {this.renderEmpty()}
        {this.renderChannels()}
      </List>
    );
  }
}

ChatList.propTypes = {
  isLoading: PropTypes.bool,
  channels: PropTypes.array,
  onChannelSelect: PropTypes.func,
};

export default ChatList;
