import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withPagination from "../../modules/withPagination";
import withSorting from "../../modules/withSorting";
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/userActions";

import UsersIndexPage from "../../pages/Users/UsersIndexPage";

class Users extends Component {
  render() {
    return <UsersIndexPage {...this.props} />;
  }
}

Users.propTypes = {
  users: PropTypes.array,
  fetchUsers: PropTypes.func,
  fetchUser: PropTypes.func,
  searchUsers: PropTypes.func,
  fetchUsersByCompany: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    users: state.users.users,
    userCount: state.users.userCount,
    currentPage: state.users.currentPage,
    isLoading: state.users.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    searchUsers: bindActionCreators(actions.searchUsers, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchUsersByCompany: opts => dispatch(actions.fetchUsersByCompany(opts)),
  };
}

export default withSorting(
  withPagination(connect(mapStateToProps, mapDispatchToProps)(Users))
);
