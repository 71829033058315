import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import PageIndexHeader from "../../shared/PageComponents/PageIndexHeader";
import ChillButton from "../../shared/ChillButton";
import ConfirmationModal from "../../shared/ConfirmationModal";
import ContentLoader from "../../shared/ContentLoader";

import DSODCartForm from "./components/DSODCartForm";
import NewDSODModal from "./components/NewDSODModal";

class DSODCartPage extends Component {
  state = {
    confirmOpen: false,
  };

  handleCancelDSOD = () => {
    const {history} = this.props;
    history.push("/");
  };

  _getCompaniesWithoutDSOD() {
    const {currentOpenOrders, companies} = this.props;
    if (!companies) return [];
    if (!currentOpenOrders) return [];
    const openOrders = currentOpenOrders.filter(order => order.isDsod);
    const companyIds = openOrders.map(order => Number(order.companyId));
    return companies.filter(
      ({id, activeInventoryItems}) =>
        !companyIds.includes(Number(id)) &&
        activeInventoryItems &&
        activeInventoryItems.length > 0
    );
  }

  goToCompanyPage = () => {
    const {history, companyId} = this.props;
    history.push(`/companies/${companyId}`);
  };

  render() {
    const {
      onSubmitOrder,
      onCreateDSOD,
      companies,
      companyContacts,
      recipients,
      isLoading,
      orderData,
      shipmentData,
      shipmentAccounts,
      onOrderChange,
      onShipmentChange,
      onSaveDraft,
      onUpdateDsodRecipient,
      shippingMethods,
      order,
      inventoryItems,
      dsodItems,
      fetchInventoryItems,
      inventoryItemsCount,
      updateDsodItem,
      createDsodItem,
      openNotification,
      deleteDsodItem,
      onFetchDsodItems,
      archiveOrder,
      isInternal,
      selectedCompany,
      companyId,
      company,
      isCreatingOrder,
      currentUserId,
      paymentTerms,
      isDisabled,
    } = this.props;

    return (
      <React.Fragment>
        {!companyId && (
          <NewDSODModal
            isLoading={isCreatingOrder}
            onCreateDSOD={onCreateDSOD}
            companies={this._getCompaniesWithoutDSOD()}
            onClose={this.handleCancelDSOD}
          />
        )}
        <PageLayout
          isLoading={isLoading}
          loaderEnable={true}
          subHeaderText={selectedCompany ? selectedCompany.name : ""}
        >
          <BreadCrumbs
            rootName={isInternal ? "Companies" : "Company"}
            rootUrl={"companies"}
            middle={company && company.name}
            middleLink={company && company.id}
            pageName={"DSOD Cart"}
          />
          <ContentLoader isLoading={isLoading}>
            {!isInternal &&
              (order && order.createdUserId !== currentUserId) && (
              <ConfirmationModal
                open
                height={400}
                title="OOPS - we’re sorry!!"
                content={
                  <React.Fragment>
                    <span>
                        Another {company && company.name} user has your DSOD
                        cart open. We can open only one cart for you at a time.
                        Please be patient and try again later.
                    </span>
                    <br />
                    <br />
                    <span>Thanks for your understanding!</span>
                    <br />
                    <br />
                    <span>Team chill-n-go</span>
                  </React.Fragment>
                }
                confirmText="OK"
                cancelText=" "
                onConfirm={() => this.goToCompanyPage()}
                onDismiss={() => this.goToCompanyPage()}
              />
            )}
            <PageIndexHeader
              containerClass="dsodindex_top margin-bottom-9"
              title="DSOD Cart"
              titleIcon="DSOD"
              buttonIcon="check"
              buttonText="SUBMIT DSOD"
              buttonIsDisabled={!this.props.isValid}
              onButtonClick={onSubmitOrder}
              noSearch
              customElement={
                <ChillButton
                  className="pageIndexHeader_btn"
                  name={"REMOVE"}
                  icon={"trash3"}
                  height={40}
                  width={140}
                  onClick={() => this.setState({confirmOpen: true})}
                />
              }
            />
            <DSODCartForm
              isDisabled={isDisabled}
              isInternal={isInternal}
              onSubmitOrder={onSubmitOrder}
              onSetIsValid={this.props.onSetIsValid}
              isValid={this.props.isValid}
              currentUserId={currentUserId}
              openNotification={openNotification}
              inventoryItems={inventoryItems}
              inventoryItemsCount={inventoryItemsCount}
              recipients={recipients}
              companyId={companyId}
              companies={companies}
              company={company}
              companyContacts={companyContacts}
              onFetchDsodItems={onFetchDsodItems}
              orderData={orderData}
              shipmentData={shipmentData}
              shipmentAccounts={shipmentAccounts}
              shippingMethods={shippingMethods}
              onOrderChange={onOrderChange}
              onShipmentChange={onShipmentChange}
              onSaveDraft={onSaveDraft}
              onUpdateDsodRecipient={onUpdateDsodRecipient}
              updateDsodItem={updateDsodItem}
              order={order}
              dsodItems={dsodItems}
              fetchInventoryItems={fetchInventoryItems}
              deleteDsodItem={deleteDsodItem}
              createDsodItem={createDsodItem}
              archiveOrder={archiveOrder}
              paymentTerms={paymentTerms}
            />
          </ContentLoader>
          <ConfirmationModal
            title="Are you sure you want to delete this DSOD order?"
            open={this.state.confirmOpen}
            onConfirm={() => {
              this.setState(
                {
                  confirmOpen: false,
                },
                () => archiveOrder()
              );
            }}
            onDismiss={() => {
              this.setState({confirmOpen: false});
            }}
            height={250}
          />
        </PageLayout>
      </React.Fragment>
    );
  }
}

DSODCartPage.propTypes = {
  updateDsodItem: PropTypes.func.isRequired,
  onSetIsValid: PropTypes.func.isRequired,
  onCreateDSOD: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  onShipmentChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  archiveOrder: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  fetchProducts: PropTypes.array,
  onUpdateDsodRecipient: PropTypes.func,
  companyId: PropTypes.number,
  currentOpenOrders: PropTypes.array,
  shippingMethods: PropTypes.array,
  shipment: PropTypes.object,
  shipmentData: PropTypes.object,
  orderData: PropTypes.object,
  companies: PropTypes.array.isRequired,
  company: PropTypes.object,
  companyContacts: PropTypes.array.isRequired,
  shipmentAccounts: PropTypes.array,
  recipients: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool,
  history: PropTypes.object,
  order: PropTypes.object,
  dsodItems: PropTypes.array,
  inventoryItems: PropTypes.array,
  fetchInventoryItems: PropTypes.func,
  inventoryItemsCount: PropTypes.number,
  openNotification: PropTypes.func,
  createDsodItem: PropTypes.func.isRequired,
  deleteDsodItem: PropTypes.func.isRequired,
  onFetchDsodItems: PropTypes.func.isRequired,
  onSubmitOrder: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  isInternal: PropTypes.bool,
  isDisabled:  PropTypes.func,
  isCreatingOrder: PropTypes.bool,
  paymentTerms: PropTypes.array,
};

export default DSODCartPage;
