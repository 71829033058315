import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";
import SearchInput from "../../shared/SearchInput";
import ChillButton from "../ChillButton";
import PageTitle from "../PageTitle";

class PageIndexHeader extends Component {
  renderTitleIcon = () => {
    const {titleIcon, titleMaterialIcon} = this.props;
    if (!titleIcon) return null;
    if (titleMaterialIcon) {
      return (
        <FontIcon
          className="material-icons pageIndexHeader_icon"
          style={{
            fontSize: 32,
            color: "rgb(32,164,195)",
          }}
        >
          {titleIcon}
        </FontIcon>
      );
    }
    return (
      <img
        src={`/assets/imgs/icons/${titleIcon}.svg`}
        className="pageIndexHeader_icon"
      />
    );
  };

  render() {
    const {
      containerClass,
      title,
      searchFunc,
      createPage,
      buttonIcon,
      buttonText,
      noButton,
      noSearch,
      buttonWidth,
      customOptions,
      buttonIsDisabled,
      onButtonClick,
      customElement,
      searchTerm,
    } = this.props;

    return (
      <div
        className={`pageIndexHeader row between-xs middle-xs ${containerClass}`}
      >
        <div className="row start-xs middle-xs">
          {this.renderTitleIcon()}
          <PageTitle text={title} />
        </div>
        <div className="row">
          {customOptions}
          <SearchInput
            search={(e, value) => searchFunc(e, value)}
            initialValue={searchTerm}
            className={noSearch ? "hidden" : null}
          />{" "}
          {noButton ? null : createPage ? (
            <Link to={createPage} className="reset-link-styles">
              <ChillButton
                className="pageIndexHeader_btn"
                name={buttonText}
                height={40}
                width={buttonWidth || 140}
                icon={buttonIcon}
                isDisabled={buttonIsDisabled}
              />
            </Link>
          ) : (
            <ChillButton
              className="pageIndexHeader_btn"
              name={buttonText}
              height={40}
              width={buttonWidth || 140}
              icon={buttonIcon}
              isDisabled={buttonIsDisabled}
              onClick={() => {
                !buttonIsDisabled && onButtonClick();
              }}
            />
          )}
          {customElement ? customElement : null}
        </div>
      </div>
    );
  }
}

PageIndexHeader.propTypes = {
  containerClass: PropTypes.string,
  searchTerm: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  titleMaterialIcon: PropTypes.bool,
  searchFunc: PropTypes.func,
  createPage: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.string,
  buttonWidth: PropTypes.number,
  noButton: PropTypes.bool,
  disableSearch: PropTypes.bool,
  noSearch: PropTypes.bool,
  customOptions: PropTypes.node,
  buttonIsDisabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  customElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default PageIndexHeader;
