import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../actions/companyActions";
import {fetchCountries} from "../../../actions/geoActions";
import {fetchDiscounted} from "../../../actions/discountedActions";
import {fetchBusinesses} from "../../../actions/businessActions";
import {fetchPaymentTerms} from "../../../actions/paymentTermsActions";
import {
  openNotification,
  closeNotification,
} from "../../../actions/notificationActions";
import {push} from "react-router-redux";

import CompaniesEditPage from "../../pages/Companies/CompaniesEditPage";

class CompaniesEdit extends Component {
  componentWillMount() {
    const {match: {params: {id}}} = this.props;
    this.props.fetchCompany(id);
    this.props.fetchCountries();

    this.props.fetchDiscounted();
    this.props.fetchBusinesses();
    this.props.fetchPaymentTerms();
  }

  render() {
    return (
      <CompaniesEditPage
        updateCompany={company => this.props.updateCompany(company)}
        updateCompanyContact={user => this.props.updateCompanyContact(user)}
        {...this.props}
      />
    );
  }
}

CompaniesEdit.propTypes = {
  updateCompany: PropTypes.func,
  updateCompanyContact: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchCompany: PropTypes.func,
  fetchDiscounted: PropTypes.func,
  fetchBusinesses: PropTypes.func,
  fetchPaymentTerms: PropTypes.func,
  match: PropTypes.object,
  openNotification: PropTypes.func,
  closeNotification: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLoading: state.companies.isLoading,
    countries: state.geo.countries,
    companies: state.companies.companies,
    discounts: state.discounted.discounts,
    businesses: state.businesses.businesses,
    paymentTerms: state.paymentTerms.paymentTerms,
    company: state.companies.company,
    notification: state.notification,
    error: state.companies.error,
    errorMessage: state.companies.errorMessage,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDiscounted: () => dispatch(fetchDiscounted()),
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchPaymentTerms: () => dispatch(fetchPaymentTerms()),
    updateCompany: company => dispatch(actions.updateCompany(company)),
    updateCompanyContact: user => dispatch(actions.updateCompanyContact(user)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompany: id => dispatch(actions.fetchCompany(id)),
    navigateTo: payload => dispatch(push(payload)),
    openNotification: message => dispatch(openNotification(message)),
    closeNotification: () => dispatch(closeNotification()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompaniesEdit)
);
