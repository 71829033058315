import React, {Component} from "react";
import PropTypes from "prop-types";

const attributeStyle = {
  borderBottom: "1px solid #BDC6CF",
  color: "#4A5158",
  fontSize: 16,
  width: 300,
  height: 27,
};

const labelStyle = {
  display: "inline-block",
  color: "#747D86",
  fontSize: 12,
  height: 16,
  fontWeight: "normal",
};

const disabledStyle = {
  color: "#BDC6CF",
};

export default class AttributeLine extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    labelText: PropTypes.string,
    style: PropTypes.object,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    isInternal: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  labelStyle = () => {
    const {isDisabled} = this.props;
    const style = isDisabled ? disabledStyle : {};

    return {
      ...labelStyle,
      ...style,
    };
  };

  attributeStyle = () => {
    const {style, isDisabled} = this.props;
    const disabledStyles = isDisabled ? disabledStyle : {};

    return {
      ...attributeStyle,
      ...style,
      ...disabledStyles,
    };
  };

  render() {
    const {
      style,
      text,
      labelText,
      marginBottom,
      marginTop,
      marginLeft,
      isInternal, // eslint-disable-line no-unused-vars
      isDisabled, // eslint-disable-line no-unused-vars
      ...otherProps
    } = this.props;

    return (
      <div
        style={{
          marginBottom,
          marginTop,
          marginLeft,
          ...style,
        }}
        {...otherProps}
      >
        <label style={this.labelStyle()}>{text ? labelText : null}</label>
        <p style={this.attributeStyle()}>{text ? text : labelText}</p>
      </div>
    );
  }
}
