import fetchAPI from "../../utils/fetchAPI";
import RequestService from "../../services/RequestService";
import {
  FETCH_BAG_TYPES_REQUEST,
  FETCH_BAG_TYPES_SUCCESS,
  FETCH_BAG_TYPES_ERROR,
} from "../../constants/actionTypes";

export const fetchBagTypes = options => {
  return async dispatch => {
    await fetchAPI(
      {
        url: RequestService.buildIndexUrl("bag_types", null, options),
        method: "GET",
        types: [
          FETCH_BAG_TYPES_REQUEST,
          FETCH_BAG_TYPES_SUCCESS,
          FETCH_BAG_TYPES_ERROR,
        ],
      },
      dispatch
    );
  };
};
