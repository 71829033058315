import * as React from "react";
import Downshift from "downshift";
import PropTypes from "prop-types";
import Toogle from "material-ui/Toggle";
import FontIcon from "material-ui/FontIcon";

const ALL = "All";
const selectedIsNull = (currentValue) => currentValue === null;
class BottleCapacityDropdown extends React.Component {

  state = {
    hoverColor: "rgb(116, 125, 134)",
  }

  handleMouseEnter = (color, state) => {
    this.setState({
      [state]: color
    });
  }

  handleSelection = selectedItem => {
    const { selectedCapacity } = this.props;

    if (selectedCapacity.includes(selectedItem)) {
      this.removeItem(selectedItem);
    } else {
      this.addSelectedItem(selectedItem);
    }
  }

  handleSelectAll() {
    const { values, selectedCapacity, onChange } = this.props;

    if (!selectedCapacity.every(selectedIsNull)) {
      onChange(null, null, [null]);
    } else {
      const allValues = values.map(({ id }) => id);
      onChange(null, null, allValues);
    }
  }

  removeItem = (item) => {
    const { onChange, selectedCapacity } = this.props;
    onChange(null, null, selectedCapacity.filter((i) => i !== item));
  }
  addSelectedItem(item) {
    const { onChange, selectedCapacity } = this.props;
    onChange(null, null, [...selectedCapacity, item]);
  }

  render() {
    const {
      values,
      selectedCapacity,
      floatingLabelText,
      disabled
    } = this.props;

    const { hoverColor } = this.state;

    return (
      <div
        style={{
          marginTop: 0,
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          width: "27%",
        }}
      >
        <span
          style={{
            height: "2em",
            width: "2em",
            padding: ".3em",
            borderRadius: "5px",
            marginRight: ".5em",
            position: "absolute",
          }}
        />
        <Downshift>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getLabelProps,
            isOpen,
            toggleMenu,
          }) => (
            <div style={{
              paddingLeft: 16,
              width: "100%",
            }
            }>
              <div>
                <label style={{
                  fontSize: 16,
                  position: "absolute",
                  lineHeight: "22px",
                  top: "38px",
                  zIndex: "1",
                  transform: "scale(0.75) translate(0px, -28px)",
                  color: disabled ? "rgb(189, 198, 207)" : "rgb(116, 125, 134)",
                  transformOrigin: "left top",
                }} {...getLabelProps()}>{floatingLabelText}</label>
              </div>
              <div style={{
                backgroundColor: "rgb(255, 255, 255)",
                cursor: disabled ? "not-allowed" : "pointer",
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "35px",
                orderTop: "none rgb(116, 125, 134)",
                borderLeft: disabled ? "none rgb(189, 198, 207)" : "none rgb(116, 125, 134)",
                borderRight: disabled ? "none rgb(189, 198, 207)" : "none rgb(116, 125, 134)",
                bottom: "8px",
                boxSizing: "content-box",
                borderBottom: disabled ? "2px solid rgb(189, 198, 207)" : "1px solid rgb(116, 125, 134)",
              }}
              onClick={() => {
                !disabled && toggleMenu();
              }}
              >
                <div>
                  <input
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      height: "35px",
                      border: "none",
                      fontSize: 16,
                      outline: "none",
                      backgroundColor: "white",
                      color: disabled ? "rgba(0, 0, 0, 0.3)" : "black",
                    }}
                    {...getInputProps({
                      placeholder: ALL,
                      value: !(selectedCapacity.length === values.length) ? (values.filter((i) => selectedCapacity.includes(i.id))).map(({ name }) => name) : ALL,
                      "data-testid": "combobox-input",
                    })}
                    disabled
                  />
                </div>
                <div style={{ height: 36, width: 36, padding: 9 }}>
                  <FontIcon style={{ justifyContent: "end", color: disabled ? "rgb(189, 198, 207)" : "rgb(116, 125, 134)" }} className="material-icons">arrow_drop_down</FontIcon>
                </div>
              </div>

              <div style={{
                color: "rgba(0, 0, 0, 0.87)",
                backgroundColor: "rgb(255, 255, 255)",
                boxSizing: "border-box",
                boxShadow: "rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px",
                borderRadius: 2,
                position: "fixed",
                zIndex: 2100,
                opacity: 1,
                transform: "scaleY(1)",
                transition: "transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                transformOrigin: "left top",
                maxHeight: 909,
                overflowY: "auto",
                width: "20%",
                top: "188px",
              }}>
                <div style={{
                  display: !isOpen && "none",
                  zIndex: 1000,
                  maxHeight: 500,
                  overflowY: "auto",
                }}>
                  <ul
                    style={{
                      padding: 0,
                      paddingTop: " 16px",
                      display: "flex",
                      listStyle: "none",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                    {...getMenuProps({
                      isOpen,
                    })}
                  >
                    {isOpen &&
                      values.map((item, index) => (
                        <div 
                          key={item.id}
                          style={{
                            width: "50%", padding: "0px 4px",
                          }}
                        >
                          <li
                            key={item.id}
                            {...getItemProps({
                              "data-testid": `downshift-item-${item.id}`,
                              item: item.name,
                              index,
                            })}
                            onClick={() => {
                              this.handleSelection(item.id);
                            }}
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 15,
                              cursor: "pointer",
                              width: "100%",
                              padding: "4px 10px",
                              justifyContent: "space-between",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div>
                              {item.name}&nbsp;&nbsp;&nbsp;
                            </div>
                            <div>
                              <Toogle
                                thumbStyle={{ backgroundColor: "#BDC6CF" }}
                                trackStyle={{ backgroundColor: "#D7DCE1" }}
                                thumbSwitchedStyle={{ backgroundColor: "#D7DCE1" }}
                                trackSwitchedStyle={{ backgroundColor: "#195B77" }}
                                toggled={selectedCapacity.includes(item.id) && true}
                              />
                            </div>
                          </li>
                        </div>
                      ))
                    }
                  </ul>
                  <div style={{ padding: "10px 14px" }}>
                    <a
                      style={{
                        color: hoverColor,
                        fontSize: 14,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.handleSelectAll();
                      }}
                      onMouseEnter={() => this.handleMouseEnter("rgb(255, 64, 129)", "hoverColor")}
                      onMouseLeave={() => this.handleMouseEnter("rgb(116, 125, 134)", "hoverColor")}
                    >
                      {selectedCapacity.every(selectedIsNull) ? "Select All" : "Clear All"}
                    </a>
                  </div>
                </div>
              </div>

            </div >
          )
          }
        </Downshift>
      </div >
    );
  }
}
BottleCapacityDropdown.propTypes = {
  name: PropTypes.object,
  disabled: PropTypes.object,
  floatingLabelText: PropTypes.object,
  onChange: PropTypes.func,
  selectedCapacity: PropTypes.array,
  values: PropTypes.array,
  iconStyle: PropTypes.object,
  menuStyle: PropTypes.object,
};

export default BottleCapacityDropdown;
