import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import fetchAPI from "../../utils/fetchAPI";
import serializePayloadData from "../../utils/serializePayloadData";

import {
  UPLOAD_CHAT_FILE_REQUEST,
  UPLOAD_CHAT_FILE_SUCCESS,
  OPEN_ERROR_MESSAGE,
  FETCH_CHAT_CHANNELS_REQUEST,
  FETCH_CHAT_CHANNELS_SUCCESS,
  FETCH_CHAT_CHANNELS_ERROR,
  FETCH_CHAT_CHANNEL_REQUEST,
  FETCH_CHAT_CHANNEL_SUCCESS,
  FETCH_CHAT_CHANNEL_ERROR,
  FETCH_CHAT_MESSAGES_REQUEST,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_ERROR,
  SEND_CHAT_MESSAGES_REQUEST,
  SEND_CHAT_MESSAGES_SUCCESS,
  SEND_CHAT_MESSAGES_ERROR,
  RECEIVE_CHAT_MESSAGES_SUCCESS,
  FETCH_CHATS_UNREAD_COUNTS,
  CLEAR_CHAT_MESSAGES,
} from "../../constants/actionTypes";

export const uploadFileMessage = (file, fileMesage) => {
  return async dispatch => {
    const payload = serializePayloadData(fileMesage);
    const formData = new FormData();
    formData.set("file", file);
    Object.keys(payload).forEach(key => {
      let value = payload[key];
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    const result = await fetchAPI(
      {
        url: "/chat/newfile",
        method: "POST",
        body: formData,
        contentType: false,
        types: [
          UPLOAD_CHAT_FILE_REQUEST,
          UPLOAD_CHAT_FILE_SUCCESS,
          OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );
    return result;
  };
};

export const fetchChatChannels = messenger => {
  return async dispatch => {
    dispatch({type: FETCH_CHAT_CHANNELS_REQUEST});
    try {
      const channels = await messenger.getChannelsList();
      dispatch({
        type: FETCH_CHAT_CHANNELS_SUCCESS,
        payload: channels.map(channel => _cloneDeep(channel)),
      });
    } catch (error) {
      dispatch({
        type: FETCH_CHAT_CHANNELS_ERROR,
        error,
      });
    }
  };
};

export const fetchChatMessagesRequest = () => dispatch =>
  dispatch({type: FETCH_CHAT_MESSAGES_REQUEST});

export const fetchChatMessages = messenger => {
  return async dispatch => {
    dispatch(fetchChatMessagesRequest());
    if (!messenger.currentChannel) {
      return dispatch({
        type: FETCH_CHAT_MESSAGES_ERROR,
        error: new Error("No channel selected"),
      });
    }
    try {
      let messages = await messenger.fetchPreviousMessages(
        messenger.currentChannel,
        100
      );
      messages = messages.map(message => ({
        ...message,
        sender: message.sender,
        isCurrentUser: messenger.isCurrentUser(message.sender),
      }));
      dispatch({
        type: FETCH_CHAT_MESSAGES_SUCCESS,
        payload: messages,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CHAT_MESSAGES_ERROR,
        error,
      });
    }
  };
};

export const fetchChatChannel = (
  messenger,
  type,
  typeData,
  typeIdName,
  userId
) => {
  return async (dispatch, getState) => {
    dispatch({type: FETCH_CHAT_CHANNEL_REQUEST});
    let {chat: {channels}} = getState();
    if (channels && !channels.length)
      await dispatch(fetchChatChannels(messenger));
    try {
      let channel = messenger.channelList.find(channel => {
        const data = JSON.parse(channel.data);
        return (
          _get(data, `${type}-${typeIdName}`) === _get(typeData, typeIdName)
        );
      });
      if (!channel) {
        await messenger.createNewUser(
          _get(typeData, typeIdName),
          type === "general" ? typeData.name : `${type}-${typeIdName}`
        );
        await messenger.init();
        channel = await messenger.createChannelFor(
          type,
          typeData,
          typeIdName,
          userId
        );
      }
      const data = JSON.parse(channel.data);
      if (data.type === type && userId) {
        const member = channel.members.find(
          member => member.userId === `${userId}`
        );
        if (!member) {
          await messenger.createNewUser(`${userId}`, `${userId}`);
          await messenger.init();
          await messenger.inviteWithUserIdsToChannel(channel, [`${userId}`]);
        }
      }
      messenger.currentChannel = channel;
      messenger.addOnMessageReceivedHandler(
        channel.url,
        onMessageReceiveHandler(messenger, dispatch, channel.url)
      );
      dispatch({
        type: FETCH_CHAT_CHANNEL_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CHAT_CHANNEL_ERROR,
        error,
      });
    }
  };
};

export const addMissingMember = async (messenger, channel, userId) => {
  if (!userId) return;
  const member = channel.members.find(member => member.userId === `${userId}`);
  if (!member) {
    await messenger.createNewUser(`${userId}`, "");
    await messenger.init();
  }
  await messenger.inviteWithUserIdsToChannel(channel, [`${userId}`]);
};

const onMessageReceiveHandler = (messenger, dispatch, channelUrl) => {
  return (channel, message) => {
    if (
      messenger.currentChannel.url === channel.url &&
      channel.url === channelUrl
    ) {
      dispatch({
        type: RECEIVE_CHAT_MESSAGES_SUCCESS,
        payload: {
          ...message,
          sender: message.sender,
          isCurrentUser: messenger.isCurrentUser(message.sender),
        },
      });
    }
  };
};

export const sendChatMessage = (messenger, data, message, file) => {
  return async dispatch => {
    dispatch({type: SEND_CHAT_MESSAGES_REQUEST});
    if (!messenger.currentChannel)
      return dispatch({
        type: SEND_CHAT_MESSAGES_ERROR,
        error: new Error("No channel selected"),
      });
    let sentMessage;
    try {
      if (file) {
        const fileData = await uploadFileMessage(file, data)(dispatch);
        sentMessage = await messenger.sendFile(
          file,
          {...data, ...fileData, message},
          messenger.currentChannel
        );
      } else {
        sentMessage = await messenger.sendMessage(
          message,
          data,
          messenger.currentChannel
        );
      }
      dispatch({
        type: SEND_CHAT_MESSAGES_SUCCESS,
        payload: {
          ...sentMessage,
          sender: sentMessage.sender,
          isCurrentUser: messenger.isCurrentUser(sentMessage.sender),
        },
      });
    } catch (error) {
      dispatch({
        type: SEND_CHAT_MESSAGES_ERROR,
        error,
      });
    }
  };
};

export const fetchChatsUnreadCounts = chatUnreadCounts => dispatch => {
  dispatch({
    type: FETCH_CHATS_UNREAD_COUNTS,
    payload: chatUnreadCounts,
  });
};

export const clearMessages = () => dispatch => {
  dispatch({type: CLEAR_CHAT_MESSAGES});
};
