import React from "react";
import styles from "../../../styles/v2";

export default WrappedComponent => {
  class WithStyles extends React.Component {
    render() {
      return <WrappedComponent styles={styles} {...this.props} />;
    }
  }

  return WithStyles;
};
