import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {CNGTable, CNGTableRow, CNGTableRowColumn} from "../../shared/CNGTable";
import {getFormattedDate} from "../../../utils/dates";
import OrderSummary from "../../../utils/orderSummary";
import FontIcon from "material-ui/FontIcon";
import {getCurrencyFormattedNumber} from "../../../utils/numberFormat";
import Pagination from "../../shared/Pagination";
import {ORDER_COMPLETED_ID} from "../../../constants/order";

class OrdersTable extends Component {
  state = {
    currentPage: 1,
  };

  _renderPagination = () => {
    const {ordersCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={this.handleSelectPage}
          count={ordersCount}
          currentPage={this.state.currentPage - 1}
        />
      </div>
    );
  };
  handleSelectPage = options => {
    const {
      searchOrders,
      searchTerm,
      isInternal,
      selectedCompanyId,
    } = this.props;
    const orderFilters = [];
    if (!searchTerm) {
      orderFilters.push(`status;code,neq,__${ORDER_COMPLETED_ID}__`);
    }
    if (!isInternal) {
      orderFilters.push(`company_id,eq,${selectedCompanyId}`);
    }
    this.setState({currentPage: options.page});
    searchOrders(searchTerm, "[status,items,company]", {
      ...options,
      page: options.page,
      filter: orderFilters.join("|"),
      orderBy: "orders;estimated_ship_on",
    });
  };
  _renderRow = (order, index) => {
    const {unreadMessagesCounts} = this.props;
    if(!order) return null;
    let {
      purchaseOrderDate,
      estimatedShipOn,
      purchaseOrderNumber,
      status,
      items,
      company,
      cngReferenceNumber,
      id,
    } = order;
    if (purchaseOrderDate) purchaseOrderDate = new Date(purchaseOrderDate);
    if (estimatedShipOn) estimatedShipOn = new Date(estimatedShipOn);

    const orderSummary = new OrderSummary(items);
    const unreadMessagesCount = unreadMessagesCounts
      ? unreadMessagesCounts[id]
      : 0;

    return (
      <CNGTableRow
        key={index}
        styles={{
          marginTop: 8,
        }}
      >
        <CNGTableRowColumn highlight={true}>
          {order.jobName ? (
            <Link
              to={`/companies/${order.companyId}/orders/${order.id}`}
              className="no-link-styles"
              title={order.jobName}
            >
              {order.jobName}
              {order.jobName && (
                <div className="table-subtext">{cngReferenceNumber}</div>
              )}
            </Link>
          ) : (
            <span>
              <FontIcon
                className="material-icons"
                style={{
                  color: "#F5A623",
                  fontSize: 12,
                  marginRight: 5,
                }}
              >
                warning
              </FontIcon>

              <Link
                to={`/companies/${order.companyId}/orders/${order.id}`}
                className="no-link-styles"
              >
                Pending assignment
                <div className="table-subtext">{cngReferenceNumber}</div>
              </Link>
            </span>
          )}
        </CNGTableRowColumn>
        {this.props.isInternal && (
          <CNGTableRowColumn highlight={true} id="testEle">
            {company && (
              <Link
                to={`/companies/${order.companyId}?tab=orders`}
                className="no-link-styles"
                title={company.name}
              >
                {company.name}
              </Link>
            )}
          </CNGTableRowColumn>
        )}
        <CNGTableRowColumn>
          {estimatedShipOn && getFormattedDate(estimatedShipOn)}
        </CNGTableRowColumn>
        <CNGTableRowColumn>{purchaseOrderNumber}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {purchaseOrderDate && getFormattedDate(purchaseOrderDate)}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <span title={status && status.name}>{status && status.name}</span>
        </CNGTableRowColumn>
        <CNGTableRowColumn bold={true} customStyles={{position: "relative"}}>
          {getCurrencyFormattedNumber(orderSummary.totalOrder)}
          {unreadMessagesCount > 0 && (
            <span className="badge" style={{top: 0, right: 0}}>
              {unreadMessagesCount}
            </span>
          )}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };
  calculateTotal = orders => {
    return orders.reduce((total, order) => {
      if(!order) return null;
      const summary = new OrderSummary(order.items);
      return total + summary.totalOrder;
    }, 0);
  };
  render() {
    const header = [
      "Job Info",
      "Company",
      "Est. Shipment Date",
      "Customer PO",
      "Customer PO Date",
      "Order Status",
      "Order Total",
    ];
    const {orders, ordersCount, isInternal, isLoading, showTotal} = this.props;

    if (!isInternal) {
      header.splice(1, 1);
    }

    return (
      <div>
        <div
          style={{
            minHeight: ordersCount > 10 ? "560px" : null,
          }}
          className={ordersCount === 0 ? "hidden" : ""}
        >
          <CNGTable
            tableHeader={header}
            displayRowCheckbox={false}
            isLoading={isLoading}
          >
            {orders.map(this._renderRow)}
            {showTotal && (
              <CNGTableRow styles={{marginTop: 8}}>
                <CNGTableRowColumn
                  TableRowColumnProps={{colSpan: isInternal ? 5 : 4}}
                />
                <CNGTableRowColumn customStyles={{textAlign: "right"}}>
                  Total:
                </CNGTableRowColumn>
                <CNGTableRowColumn bold={true}>
                  {getCurrencyFormattedNumber(this.calculateTotal(orders))}
                </CNGTableRowColumn>
              </CNGTableRow>
            )}
          </CNGTable>
        </div>
        {ordersCount > 7 && !isLoading ? this._renderPagination() : null}

        {ordersCount === 0 ? (
          <h2 className="userindex_noUsers">
            There are no order in process at this time.
          </h2>
        ) : null}
      </div>
    );
  }
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
  showTotal: PropTypes.bool,
  isInternal: PropTypes.bool,
  isLoading: PropTypes.bool,
  ordersCount: PropTypes.number,
  searchTerm: PropTypes.string,
  searchOrders: PropTypes.func,
  minHeight: PropTypes.string,
  selectedCompanyId: PropTypes.any,
  unreadMessagesCounts: PropTypes.object,
};

export default OrdersTable;
