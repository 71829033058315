import React, {Component} from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";


export default class ModalArchivedProduct extends Component {
	static propTypes = {
	  orderItemProduct: PropTypes.object,
	  orderItemProductId: PropTypes.string,
	  isInternal: PropTypes.bool,
	  };

	constructor(props) {
	    super(props);
	    this.state = {
		 open: false
	    };
	    this.handleClose = this.handleClose.bind(this);
	 }

	  componentWillReceiveProps(nextProps) {
	  if ( this.props.orderItemProductId == nextProps.orderItemProduct.orderItemId && nextProps.orderItemProduct.product.isDeleted) {
		  this.setState({ open: nextProps.orderItemProduct.product.isDeleted });
	    }
	  }
	
	  handleClose() {
	  this.setState({ open: false });
	  }
	
	 render() {
	  return (
	    <div>
	      <Dialog
		    overlayClassName={"dialog-overlay"}
	        className={"order-item-dialog"}
		  title="Archived Product"
	        modal={true}
		  open={this.state.open}
	      >
			 <img
	          onClick={() => this.handleClose()}
	          className="archiveModal_closeIcon"
	          src="../../../../../assets/imgs/icons/close.svg"
	        />
		   {this.props.isInternal ? "Product archived. Please contact an administrator for further assistance.": " Please contact chill-n-go if you wish to re-order or resurrect this item." }  
	      </Dialog>
	  </div>
	  );
	 }
}