import React, {Component} from "react";
import PropTypes from "prop-types";

import IconMenu from "material-ui/IconMenu";
import CNGMenuItem from "../../shared/CNGMenuItem";
import AuthService from "../../../services/AuthService";
const authService = new AuthService();

export default class HeaderUserMenu extends Component {
  static propTypes = {
    isInternal: PropTypes.bool,
    user: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    chatBadgeNumber: PropTypes.number,
  };
  constructor(props) {
    super(props);

    this.adminForm = null;
    this.setAdminForm = formElement => {
      this.adminForm = formElement;
    };
  }

  state = {
    menu: false,
  };

  _toggleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  _handleOnRequestChange = value => {
    this.setState({
      menu: value,
    });
  };

  handleSubmitForm = () => {
    this.adminForm.submit();
  };

  navigateToChatCenter = () => {
    const {history} = this.props;
    history.push("/chats");
  };

  _renderMenu() {
    const {isInternal} = this.props;

    return (
      <IconMenu
        width={"176px"}
        open={this.state.menu}
        iconButtonElement={this._renderOpenButton()}
        onRequestChange={this._handleOnRequestChange}
        anchorOrigin={{horizontal: "right", vertical: "top"}}
        targetOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        {isInternal ? (
          <CNGMenuItem
            onClick={this.handleSubmitForm}
            primaryText="Admin Console"
            className="header_right_menu_text"
          >
            <form
              ref={this.setAdminForm}
              action={`${process.env.ADMIN_DOMAIN}/sessions`}
              method="post"
              target="_blank"
              style={{display: "none"}}
            >
              <textarea name="token" value={authService.getToken()} readOnly />
              <button>Go to Admin</button>
            </form>
          </CNGMenuItem>
        ) : null}
        {isInternal ? (
          <CNGMenuItem
            onClick={this.navigateToChatCenter}
            primaryText="Chat Center"
            className="header_right_menu_text"
          />
        ) : null}
        <CNGMenuItem
          onClick={() => this.props.logout()}
          primaryText="Log Out"
          className="header_right_menu_text"
        />
      </IconMenu>
    );
  }

  _renderOpenButton() {
    const {user, chatBadgeNumber} = this.props;
    return (
      <span className="header_right_text" onClick={() => this._toggleMenu()}>
        {user}
        {chatBadgeNumber > 0 && (
          <span className="badge" style={{top: -16, right: -16}}>
            {chatBadgeNumber}
          </span>
        )}
      </span>
    );
  }

  render() {
    return <div className="header_right">{this._renderMenu()}</div>;
  }
}
