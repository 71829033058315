import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import Pagination from "../../shared/Pagination";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import ArtFormCardHeader from "./components/ArtFormCardHeader";
import Divider from "../../shared/Divider";
import ContentLoader from "../../shared/ContentLoader";

import ArtForm from "./components/ArtForm";
import ArtTabs from "./components/ArtTabs";

export default class ArtEditPage extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onCreateArt: PropTypes.func.isRequired,
    onUpdateRevision: PropTypes.func,
    onSelectPage: PropTypes.func.isRequired,
    brand: PropTypes.object,
    adornmentTypes: PropTypes.array,
    details: PropTypes.object,
    filename: PropTypes.string,
    name: PropTypes.string,
    adornment_type_id: PropTypes.number,
    file: PropTypes.object,
    artRevisions: PropTypes.array,
    artRevisionsCount: PropTypes.number,
    artCount: PropTypes.number,
    selectedArt: PropTypes.object,
    currentPage: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    updateArtRevision: PropTypes.func,
  };

  state = {
    submitted: false,
  };

  _renderPagination() {
    const {onSelectPage} = this.props;
    return (
      <div className="userindex_pagination">
        <Pagination
          getUsers={onSelectPage}
          count={this.props.artRevisionsCount}
          currentPage={this.props.currentPage}
        />
      </div>
    );
  }

  _getSelectedAdornmentType = () => {
    const {adornment_type_id, adornmentTypes = []} = this.props;

    return adornmentTypes.find(
      adornmentType => adornmentType.id == adornment_type_id
    );
  };

  _renderHeader = (brand, selectedArt) => {
    return (
      <ArtFormCardHeader
        title="Create Art Version"
        cancelLink={`/brands/${lodash.get(brand, "id")}/arts/${lodash.get(
          selectedArt,
          "id"
        )}/revisions/${lodash.get(selectedArt, "revisions[0].id")}`}
        isLoading={this.props.isLoading}
        buttonText="Create"
        buttonIcon="check"
        buttonClick={() => this.setState({submitted: true})}
        buttonHeight={32}
        buttonWidth={100}
      />
    );
  };

  handleCreateArt = () => {
    this.props.onCreateArt();
    this.setState({submitted: false});
  };

  handleErrorState = () => {
    this.setState({submitted: false});
  };

  _renderForm = () => {
    const {onChange, onFileChange, adornmentTypes} = this.props;

    return (
      <ArtForm
        onChange={onChange}
        onFileChange={onFileChange}
        adornmentTypes={adornmentTypes}
        fileInputText="REPLACE"
        fileInputIcon="replacefile-white"
        showUploalModal={true}
        submitSuccess={this.handleCreateArt}
        submitError={this.handleErrorState}
        submitted={this.state.submitted}
        isDisabled={true}
        {...this.props}
        selectedArt={{}}
      />
    );
  };
  render() {
    const {
      isLoading,
      brand,
      artRevisions,
      artRevisionsCount,
      selectedArt,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={lodash.get(brand, "company.name")}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={"Companies"}
          middle={lodash.get(brand, "company.name")}
          middleLink={lodash.get(brand, "company.id")}
          middletwo={lodash.get(brand, "name")}
          middletwoLink={`brands/${lodash.get(brand, "id")}`}
          pageName="Create Version"
        />

        <Card>
          <ContentLoader
            isLoading={isLoading}
            customContainerStyles={{width: "100%", height: 550}}
          >
            {this._renderHeader(brand, selectedArt)}
            <Divider marginBottom={24} marginTop={24} />
            {this._renderForm()}
          </ContentLoader>
          <ArtTabs
            revisions={artRevisions}
            brand={brand}
            isLoading={isLoading}
          />{" "}
          {artRevisions && artRevisionsCount > 7
            ? this._renderPagination()
            : null}
        </Card>
      </PageLayout>
    );
  }
}
