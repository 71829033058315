import React, {Component} from "react";
import PropTypes from "prop-types";
import {TableRowColumn} from "material-ui/Table";

class CNGTableRowColumn extends Component {
  _getColumnStyles() {
    const {highlight, isLink, customStyles, bold} = this.props;
    let styles = {
      color: "#747D86",
      fontSize: 14,
      lineHeight: "20px",
      height: 18,
      fontWeight: "normal",
      cursor: "default",
    };
    if (highlight) {
      styles = Object.assign(styles, {
        paddingLeft: 24,
        color: "#0C5271",
        fontWeight: "bold",
        lineHeight: "24px",
        cursor: "pointer",
      });
    }
    if (isLink) {
      styles = Object.assign(styles, {
        color: "#1D9ABB",
        fontWeight: "bold",
      });
    }
    if (customStyles) {
      styles = Object.assign(styles, customStyles);
    }

    if (bold) {
      styles.fontWeight = "bold";
    }
    return styles;
  }

  render() {
    const {title, TableRowColumnProps} = this.props;
    return (
      <TableRowColumn
        style={this._getColumnStyles()}
        id={this.props.id}
        className="CNG-table-row-column"
        title={title}
        {...TableRowColumnProps}
      >
        {this.props.children}
      </TableRowColumn>
    );
  }
}

CNGTableRowColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
  highlight: PropTypes.bool,
  isLink: PropTypes.bool,
  customStyles: PropTypes.object,
  bold: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.any,
  TableRowColumnProps: PropTypes.object,
};
export default CNGTableRowColumn;
