import React, {Component} from "react";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import ContactForm from "./Components/ContactForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";

export default class ContactEditPage extends Component {
  static propTypes = {
    updateCompanyContact: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    company: PropTypes.object,
    companyContact: PropTypes.object,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
  };
  state = {
    companyName: "",
    name: "",
    phoneNumber: "",
    emailAddress: "",
    address1: "",
    address2: "",
    city: "",
    state: null,
    zipCode: null,
    countryId: null,
    submitted: false,
  };
  companyUrl = () =>
    this.props.company && `/companies/${this.props.company.id}?tab=recipients`;

  componentWillReceiveProps(nextProps) {
    const contactData = cloneDeep(nextProps.companyContact);
    if (!contactData || !Object.keys(contactData).length) {
      return;
    }

    this.setState({
      id: Number(contactData.id),
      companyName: contactData.companyName,
      name: contactData.name,
      phoneNumber: contactData.phoneNumber,
      emailAddress: contactData.emailAddress,
      address1: contactData.address1,
      address2: contactData.address2,
      city: contactData.city,
      state: contactData.state,
      zipCode: contactData.zipCode,
      countryId: Number(contactData.countryId),
    });
  }

  async updateContact() {
    const {navigateTo, updateCompanyContact, company} = this.props;
    const {address1, address2, ...contactProps} = this.state;
    delete contactProps.submitted;

    const contact = {
      company_id: company.id,
      address_1: address1,
      address_2: address2,
      ...contactProps,
    };
    await updateCompanyContact(contact);
    navigateTo(this.companyUrl());
  }
  handleChange(e, index, value, formName) {
    const name = formName || e.target.name;
    if (!value && formName !== "state") {
      value = index;
    }
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      companyContact,
      company,
      countries,
      isLoading,
      isInternal,
    } = this.props;
    return (
      <PageLayout isLoading={isLoading} loaderEnable={true} smallTop={true}>
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          middletwo="Address Book"
          middletwoLink=""
          pageName="Edit Recipient"
        />
        <Card>
          <div>
            <FormCardHeader
              title="Edit Recipient"
              cancelLink={this.companyUrl() || "/"}
              buttonText="SAVE"
              buttonIcon="check"
              buttonClick={() => this.setState({submitted: true})}
            />
            <Divider marginBottom={24} marginTop={24} />
            <ContactForm
              handleChange={(e, index, value, formName) =>
                this.handleChange(e, index, value, formName)
              }
              submitSuccess={() => this.updateContact()}
              submitError={() => this.setState({submitted: false})}
              companyContact={companyContact}
              countries={countries}
              {...this.state}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
