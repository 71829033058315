import React, {Component} from "react";
import PropTypes from "prop-types";
import ChillButton from "../../../shared/ChillButton";
import SectionHeading from "../../../shared/SectionHeading";

export default class ProductDetailHeader extends Component {
  static propTypes = {
    inventoryItem: PropTypes.object,
    inventoryItems: PropTypes.array,
    onSubmitReorder: PropTypes.func,
    onSubmitDSOD: PropTypes.func,
    isDSODDisable: PropTypes.bool,
    orderItemProduct: PropTypes.object,
  };
  
  render() {
    const {
      isDSODDisable,
      orderItemProduct: {product: {itemNumber, productName,isDeleted}},
      inventoryItems,
    } = this.props;
    return (
      <div className="row between-xs" style={{marginBottom: 40}}>
        <div>
          <SectionHeading
            text={`Item No. ${itemNumber && itemNumber}`}
            textTransform={"none"}
            fontWeight={"normal"}
            marginBottom={8}
          />
          <h3 className="product-title">{productName} {isDeleted &&  <span className='disabled'>  - Archived Product</span> }</h3>
        </div>
        <div className="row">
          <ChillButton
            name={"RE-ORDER"}
            height={40}
            width={135}
            icon={"orderfilled-white"}
            isDisabled={isDeleted}
            onClick={this.props.onSubmitReorder}
          />
          <ChillButton
            name={"ADD TO DROP SHIP CART"}
            height={40}
            width={200}
            marginLeft={16}
            icon={"DSOD-white"}
            isDisabled={
              (inventoryItems && inventoryItems.length === 0) || isDSODDisable
            }
            onClick={this.props.onSubmitDSOD}
          />
        </div>
      </div>
    );
  }
}
