import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card, CardHeader, CardText} from "material-ui/Card";

import Divider from "../../../shared/Divider";
import {tableRowStyle} from "../../../../styles/components/tableStyles";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

const cardHeader = {
  ...tableRowStyle,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 48,
  paddingLeft: 10,
};

const cardColumns = {
  paddingRight: 24,
  color: "#747D86",
  fontSize: 14,
  lineHeight: "20px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const smallColumn = {
  ...cardColumns,
  width: 119,
};

const orderTotalStyles = {
  color: "#1D9ABB",
  fontWeight: "bold",
};

export default class DiscountOrderItem extends Component {
  static propTypes = {
    orderItem: PropTypes.object,
    index: PropTypes.number,
    title: PropTypes.string,
  };

  render() {
    const {orderItem, index} = this.props;

    const {
      notes,
      unitCostValue,
      unitAdornmentCostValue,
      orderTotal,
      netUnitCost,
      quantityDiscountValue,
    } = orderItem;

    const renderRow = (
      <div
        style={{
          ...cardHeader,
          paddingLeft: 0,
          height: 80,
        }}
        key={index}
        className="order-item-row"
      >
        <div
          style={{
            ...cardColumns,
            width: 355,
            display: "flex",
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
            paddingRight: 0,
          }}
        >
          <div className="overflow-ellipsis">
            {notes}
            <div className="table-subtext">Discount</div>
          </div>
        </div>
        <div
          style={{
            ...cardColumns,
            width: 110,
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
          }}
        />
        <div
          style={{
            ...smallColumn,
            width: 75,
          }}
        >
          {orderItem.orderQuantity}{" "}
        </div>
        <div
          style={{
            ...smallColumn,
            width: 105,
          }}
        >
          {getCurrencyFormattedNumber(unitCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            width: 90,
          }}
        >
          ({getCurrencyFormattedNumber(quantityDiscountValue)})
        </div>
        <div
          style={{
            ...smallColumn,
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(unitAdornmentCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(netUnitCost, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            ...orderTotalStyles,
            width: 130,
            color: "#747D86",
          }}
        >
          {getCurrencyFormattedNumber(orderTotal, true)}
        </div>
      </div>
    );

    return this._renderCard(renderRow, index);
  }

  _renderCard = (renderRow, index) => {
    return (
      <Card
        style={{
          marginBottom: 8,
          boxShadow: "none",
        }}
        containerStyle={{
          margin: "0px 32px",
        }}
        className="cardtest"
        key={index}
      >
        <CardHeader
          showExpandableButton={false}
          title={renderRow}
          textStyle={{
            paddingRight: 0,
          }}
          style={{
            padding: "0 0",
          }}
          className={"test order-item-card"}
        />
        <CardText
          expandable={true}
          style={{
            padding: 0,
            margin: "0 0px",
          }}
        >
          <Divider className={"ordershipment_card_divider"} />
        </CardText>
      </Card>
    );
  };
}
