import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function embroideryDetailReducer(
  state = initialState.art,
  {type, payload}
) {
  switch (type) {
  case actions.FETCH_EMBROIDERY_DETAILS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_EMBROIDERY_DETAILS_SUCCESS:
    return {
      ...state,
      artList: payload,
      isLoading: false,
    };
  case actions.FETCH_EMBROIDERY_DETAILS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.CREATE_EMBROIDERY_DETAILS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_EMBROIDERY_DETAILS_SUCCESS:
    return {
      ...state,
      artList: [...state, payload],
      isLoading: false,
    };
  case actions.CREATE_EMBROIDERY_DETAILS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
