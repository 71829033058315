import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import ChillButton from "../../../../shared/ChillButton";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";

import SearchInput from "../../../../shared/SearchInput";
import Pagination from "../../../../shared/Pagination";
import UsersTable from "./UsersTable";
import lodash from "lodash";

export default class UsersTab extends Component {
  static propTypes = {
    searchUsers: PropTypes.func,
    users: PropTypes.array,
    isInternal: PropTypes.bool.isRequired,
    currentUserId: PropTypes.number,
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    company: PropTypes.object,
    userCount: PropTypes.number,
    isLoading: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    page: PropTypes.number,
    searchTerm: PropTypes.string,
    setPage: PropTypes.func,
    archiveUserCompany: PropTypes.func,
    selectedTab: PropTypes.string,
    updateCompany: PropTypes.func,
  };

  componentDidMount() {
    this.props.searchUsers();
  }

  componentDidUpdate(prevProps) {
    const isActiveTab = this.props.selectedTab !== prevProps.selectedTab;

    if (
      this.props.selectedTab === "users" &&
      (hasQueryParamsChanged(this.props, prevProps) || isActiveTab)
    ) {
      this.props.searchUsers();
    }
  }

  handleSelectPage = options => {
    const {setPage} = this.props;

    setPage(options.page);
  };

  _handleSearchUsers(searchTerm) {
    this.props.setSearchTerm(searchTerm);
  }

  _handleSearchUsersDebounced = lodash.debounce(this._handleSearchUsers, 300);

  _renderPagination() {
    const {userCount} = this.props;
    return (
      <div
        className="flex justify-content-center"
        style={{
          marginTop: "16px",
        }}
      >
        <Pagination
          getData={options => this.handleSelectPage(options)}
          count={userCount}
          currentPage={this.props.page - 1}
        />
      </div>
    );
  }

  _renderUsersTable() {
    const {
      searchTerm,
      users,
      userCount,
      isInternal,
      companyId,
      company,
      isLoading,
      searchUsers,
      archiveUserCompany,
      updateCompany,
      currentUserId,
    } = this.props;
    return (
      <div className="tab-container">
        <UsersTable
          currentUserId={currentUserId}
          archiveUserCompany={archiveUserCompany}
          isInternal={isInternal}
          companyId={companyId}
          company={company}
          users={users}
          userCount={userCount}
          updateCompany={updateCompany}
          isLoading={isLoading}
          searching={!!searchTerm}
          searchUsers={searchUsers}
        />{" "}
        {userCount > 7 && users && users.length
          ? this._renderPagination()
          : null}
      </div>
    );
  }

  render() {
    const {companyId, isInternal, searchTerm} = this.props;

    return (
      <div className="tab-container">
        <div className="tab_top">
          <div className="flex">
            <SearchInput
              search={(event, value) => this._handleSearchUsersDebounced(value)}
              initialValue={searchTerm}
            />{" "}
            {isInternal && (
              <Link
                to={`/companies/${companyId}/users/create`}
                className="reset-link-styles"
              >
                <ChillButton
                  name={"ADD A USER"}
                  height={40}
                  width={157}
                  marginLeft={16}
                  icon={"adduser"}
                />
              </Link>
            )}
          </div>
        </div>
        {this._renderUsersTable()}
      </div>
    );
  }
}
