import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchPaymentTermsRequest = () =>
  makeCommonAction(types.FETCH_PAYMENTTERMS_REQUEST);
const fetchPaymentTermsSuccess = payload =>
  makeCommonAction(types.FETCH_PAYMENTTERMS_SUCCESS, payload);
const fetchPaymentTermsError = error =>
  makeCommonAction(types.FETCH_PAYMENTTERMS_ERROR, error, true);

export function fetchPaymentTerms() {
  return makeActionCreator({
    endpoint: "/paymentterms",
    fetchOptions: {method: "GET"},
    requestFunc: fetchPaymentTermsRequest,
    successFunc: fetchPaymentTermsSuccess,
    errorFunc: fetchPaymentTermsError,
    error_message: "Error fetching PaymentTerms cateogories",
  });
}
