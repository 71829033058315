import getMuiTheme from "material-ui/styles/getMuiTheme";
import colors from "./colors";
import typography from "./typography";
import {fade} from "material-ui/utils/colorManipulator";

export default getMuiTheme({
  spacing: {
    iconSize: 18,
  },
  raisedButton: {
    color: colors.primary.light,
    textColor: colors.white,
    secondaryColor: colors.secondary.light,
    secondaryTextColor: colors.white,
    disabledColor: colors.primary.light,
    disabledTextColor: colors.white,
    fontSize: typography.subtitleBold.fontSize,
    fontWeight: typography.subtitleBold.fontWeight,
    textTransform: "none",
  },
  button: {
    height: "24px",
  },
  textField: {
    textColor: colors.gray.main,
    floatingLabelColor: colors.gray.dark,
    focusColor: colors.secondary.light,
    errorColor: colors.red,
    borderColor: colors.gray.dark,
    disabledTextColor: "#BDC6CF",
  },
  tabs: {
    backgroundColor: colors.white,
    textColor: fade(colors.primary.light, 0.5),
    selectedTextColor: colors.primary.light,
  },
  inkBar: {
    backgroundColor: colors.secondary.light,
  },
  menuItem: {
    hoverColor: fade(colors.secondary.dark, 0.5),
  },
  tableRow: {
    stripeColor: "#f4f8f9",
  },
});
