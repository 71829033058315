import * as types from "../constants/actionTypes";
import RequestService from "../services/RequestService";

import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchCompaniesRequest = () =>
  makeCommonAction(types.FETCH_DSOD_COMPANIES_REQUEST);
const fetchCompaniesSuccess = payload =>
  makeCommonAction(types.FETCH_DSOD_COMPANIES_SUCCESS, payload);
const fetchCompaniesError = error =>
  makeCommonAction(types.FETCH_DSOD_COMPANIES_ERROR, error, true);

const fetchDSODCompaniesRequest = () =>
  makeCommonAction(types.FETCH_COMPANIES_WITH_DSOD_REQUEST);
const fetchDSODCompaniesSuccess = payload =>
  makeCommonAction(types.FETCH_COMPANIES_WITH_DSOD_SUCCESS, payload);
const fetchDSODCompaniesError = error =>
  makeCommonAction(types.FETCH_COMPANIES_WITH_DSOD_ERROR, error, true);

const createDsodItemsRequest = () =>
  makeCommonAction(types.CREATE_DSOD_ITEMS_REQUEST);
const createDsodItemsSuccess = payload =>
  makeCommonAction(types.CREATE_DSOD_ITEMS_SUCCESS, payload);
const createDsodItemsError = error =>
  makeCommonAction(types.CREATE_DSOD_ITEMS_ERROR, error, true);

const fetchDsodItemsRequest = () =>
  makeCommonAction(types.FETCH_DSOD_ITEMS_REQUEST);
const fetchDsodItemsSuccess = payload =>
  makeCommonAction(types.FETCH_DSOD_ITEMS_SUCCESS, payload);
const fetchDsodItemsError = error =>
  makeCommonAction(types.FETCH_DSOD_ITEMS_ERROR, error, true);

const createDsodItemRequest = () =>
  makeCommonAction(types.CREATE_DSOD_ITEM_REQUEST);
const createDsodItemSuccess = payload =>
  makeCommonAction(types.CREATE_DSOD_ITEM_SUCCESS, payload);
const createDsodItemError = error =>
  makeCommonAction(types.CREATE_DSOD_ITEM_ERROR, error, true);

const updateDsodItemRequest = () =>
  makeCommonAction(types.UPDATE_DSOD_ITEM_REQUEST);
const updateDsodItemSuccess = payload =>
  makeCommonAction(types.UPDATE_DSOD_ITEM_SUCCESS, payload);
const updateDsodItemError = error =>
  makeCommonAction(types.UPDATE_DSOD_ITEM_ERROR, error, true);

const deleteDsodItemRequest = () =>
  makeCommonAction(types.DELETE_DSOD_ITEM_REQUEST);
const deleteDsodItemSuccess = payload => {
  return makeCommonAction(types.DELETE_DSOD_ITEM_SUCCESS, payload);
};
const deleteDsodItemError = error =>
  makeCommonAction(types.DELETE_DSOD_ITEM_ERROR, error, true);

function _buildFetchUrl(opts) {
  const fetchOptions = {
    page: (opts && opts.page) || 1,
    perPage: 7,
    orderDirection: (opts && opts.orderDirection) || "asc",
    orderBy: (opts && opts.orderBy) || "id",
    filter: (opts && opts.filter) || "",
    include: (opts && opts.include) || "",
  };
  const url = `/companies/${opts.companyId}/dsod_items?include=${
    fetchOptions.include
  }&sort=${fetchOptions.orderBy}&page=${fetchOptions.page}&perPage=${
    fetchOptions.perPage
  }&meta=&sortDirection=${fetchOptions.orderDirection}&filter=${
    fetchOptions.filter
  }`;
  return url;
}

export function fetchDsodItems(opts) {
  return makeActionCreator({
    endpoint: _buildFetchUrl(opts),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchDsodItemsRequest,
    successFunc: fetchDsodItemsSuccess,
    errorFunc: fetchDsodItemsError,
    afterReqFunc: result =>
      (result.meta.currentPage = (opts && opts.page) || 1),
    error_message: "Error fetching dsod_items",
  });
}

export function createDsodItemsFromInventory(
  companyId,
  recipientId,
  inventoryItemIds,
  options,
  callback
) {
  const include = (options && options.include) || "";
  return makeActionCreator({
    endpoint: `/companies/${companyId}/dsod_items/bulk?include=${include}`,
    fetchOptions: {
      body: {
        recipientId,
        inventoryItemIds,
      },
      method: "POST",
    },
    requestFunc: createDsodItemsRequest,
    successFunc: () => {
      callback && callback();
      return createDsodItemsSuccess();
    },
    errorFunc: createDsodItemsError,
    error_message: "Error creating DsodItems",
  });
}

export function fetchDSODCompanies(options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      "companies",
      options.include || "[brands,activeInventoryItems]",
      {
        ...options,
        fields: "id,name",
      }
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchCompaniesRequest,
    successFunc: fetchCompaniesSuccess,
    errorFunc: fetchCompaniesError,
    error_message: "Unable to get companies.",
  });
}

export function fetchCompaniesWithDSOD(options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      "dsodcompanies",
      options.include || "[brands,activeInventoryItems]",
      options
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchDSODCompaniesRequest,
    successFunc: fetchDSODCompaniesSuccess,
    errorFunc: fetchDSODCompaniesError,
    error_message: "Unable to get companies.",
  });
}

export function createDsodItem(
  companyId,
  dsodItem,
  include = "[inventoryHistories.inventoryItem.order,sourceOrderItem.orderItemProduct,product,fabricAvailableColor" +
    ".fabric,art.artSource.brand]"
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/dsod_items/?include=${include}`,
    fetchOptions: {
      body: dsodItem,
      method: "POST",
    },
    requestFunc: createDsodItemRequest,
    successFunc: createDsodItemSuccess,
    errorFunc: createDsodItemError,
    error_message: "Error creating DsodItem",
  });
}

export function updateDsodItem(
  companyId,
  id,
  dsodData,
  include = "[art.artSource.brand,inventoryHistories.inventoryItem.order,sourceOrderItem.orderItemProduct.[orderItemProductAdornmentLocationArtAssignments],fabricAvailableColor" +
    ".[fabric,color],product]"
) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/dsod_items/${id}?include=${include}`,
    fetchOptions: {
      body: dsodData,
      method: "PUT",
    },
    requestFunc: updateDsodItemRequest,
    successFunc: updateDsodItemSuccess,
    errorFunc: updateDsodItemError,
    error_message: "Error updating DsodItem",
  });
}

export function deleteDsodItem(companyId, id, callback) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/dsod_items/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteDsodItemRequest,
    successFunc: () => {
      callback && callback();
      return deleteDsodItemSuccess(id);
    },
    errorFunc: deleteDsodItemError,
    error_message: "Error deleting brand",
  });
}
