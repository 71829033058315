import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";
import FlatButton from "material-ui/FlatButton";
import FontIcon from "material-ui/FontIcon";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import ContentLoader from "../../../../shared/ContentLoader";
import TextWithToolTip from "../../../../shared/TextWithToolTip";

export default class AssociationTable extends Component {
  static propTypes = {
    associatedCompanies: PropTypes.array.isRequired,
    company: PropTypes.object,
    deleteCompanyCompanyAssociation: PropTypes.func.isRequired,
    addingCompany: PropTypes.bool.isRequired,
    searching: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
  };

  async handleDeleteAssociationClick(companyAssociationId) {
    const {deleteCompanyCompanyAssociation} = this.props;
    if (companyAssociationId) {
      await deleteCompanyCompanyAssociation(companyAssociationId);
    }
  }
  _renderRow(companyAssociation, index) {
    const {
      id: companyAssociationId,
      associatedCompany,
      createdByUser,
      createdAt,
      notes,
    } = companyAssociation;
    const createdAtParsed = moment(createdAt).format("MMMM Do, YYYY");
    const addedBy = createdByUser
      ? `${createdByUser.name} added on ${createdAtParsed}`
      : "";
    return (
      <CNGTableRow key={`company_association__${index}`}>
        <CNGTableRowColumn
          highlight={true}
          customStyles={{
            width: "30%",
          }}
        >
          <Link
            to={`/companies/${associatedCompany && associatedCompany.id}`}
            className="no-link-styles"
          >
            {associatedCompany && associatedCompany.name}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={addedBy}
          customStyles={{
            width: "30%",
          }}
        >
          {createdByUser ? <span>{addedBy}</span> : "No data"}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "25%",
          }}
        >
          <TextWithToolTip
            textContainerStyles={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            disableTooltip={!notes}
            text={notes || "No message"}
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "15%",
          }}
        >
          <FlatButton
            label="Remove"
            labelPosition="before"
            primary={true}
            labelStyle={{
              fontWeight: "bold",
            }}
            icon={<FontIcon className="material-icons">clear</FontIcon>}
            onClick={() =>
              this.handleDeleteAssociationClick(companyAssociationId)
            }
          />
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderEmptyState = () => {
    const {searching, isLoading} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <div
          className="flex flex-column align-items-center"
          style={{
            minHeight: "82px",
          }}
        >
          <h2 className="userindex_noUsers">
            No associations {searching ? "found" : "yet"}.
          </h2>
        </div>
      </ContentLoader>
    );
  };

  _header = () => [
    {
      columnContent: "CURRENT ASOCCIATION",
      customStyles: {
        width: "30%",
      },
    },
    {
      columnContent: "ADDED BY",
      customStyles: {
        width: "30%",
      },
    },
    {
      columnContent: "NOTES",
      customStyles: {
        width: "25%",
      },
    },
    {
      columnContent: "",
      customStyles: {
        width: "15%",
      },
    },
  ];

  render() {
    const {associatedCompanies, isLoading} = this.props;
    if (associatedCompanies && associatedCompanies.length < 1)
      return this._renderEmptyState();
    return (
      <CNGTable
        isLoading={isLoading}
        className="association-table"
        tableHeader={this._header()}
        displayRowCheckbox={false}
      >
        {associatedCompanies &&
          associatedCompanies.map((association, index) =>
            this._renderRow(association, index)
          )}
      </CNGTable>
    );
  }
}
