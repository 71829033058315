import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchAdornmentApplicabilitiesRequest = () =>
  makeCommonAction(types.FETCH_ADORNMENT_APPLICABILITIES_REQUEST);
const fetchAdornmentApplicabilitiesSuccess = payload =>
  makeCommonAction(types.FETCH_ADORNMENT_APPLICABILITIES_SUCCESS, payload);
const fetchAdornmentApplicabilitiesError = error =>
  makeCommonAction(types.FETCH_ADORNMENT_APPLICABILITIES_ERROR, error, true);

export function fetchAdornmentApplicabilities() {
  return makeActionCreator({
    endpoint: "/adornmentapplicabilities/",
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchAdornmentApplicabilitiesRequest,
    successFunc: fetchAdornmentApplicabilitiesSuccess,
    errorFunc: fetchAdornmentApplicabilitiesError,
    error_message: "Error fetching Adornment Applicabilities",
  });
}
