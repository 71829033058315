import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchArtRequest = () => makeCommonAction(types.FETCH_ART_REQUEST);
const fetchArtSuccess = payload =>
  makeCommonAction(types.FETCH_ART_SUCCESS, payload);
const fetchArtError = error =>
  makeCommonAction(types.FETCH_ART_ERROR, error, true);

const fetchArtsRequest = () => makeCommonAction(types.FETCH_ALL_ART_REQUEST);
const fetchArtsSuccess = payload =>
  makeCommonAction(types.FETCH_ALL_ART_SUCCESS, payload);
const fetchArtsError = error =>
  makeCommonAction(types.FETCH_ALL_ART_ERROR, error, true);

const fetchArtRevisionsRequest = () =>
  makeCommonAction(types.FETCH_ART_REVISIONS_REQUEST);
const fetchArtRevisionsSuccess = payload =>
  makeCommonAction(types.FETCH_ART_REVISIONS_SUCCESS, payload);
const fetchArtRevisionsError = error =>
  makeCommonAction(types.FETCH_ART_REVISIONS_ERROR, error, true);

export const clearArtRevisions = () =>
  makeCommonAction(types.CLEAR_ART_REVISIONS);

const createArtRequest = () => makeCommonAction(types.CREATE_ART_REQUEST);
const createArtSuccess = payload =>
  makeCommonAction(types.CREATE_ART_SUCCESS, payload);
const createArtError = error => {
  throw error;
};

const updateArtRequest = () => makeCommonAction(types.UPDATE_ART_REQUEST);
const updateArtSuccess = payload =>
  makeCommonAction(types.UPDATE_ART_SUCCESS, payload);
const updateArtError = payload =>
  makeCommonAction(types.UPDATE_ART_ERROR, payload);

const updateArtRevisionRequest = () =>
  makeCommonAction(types.UPDATE_ART_REVISION_REQUEST);
const updateArtRevisionSuccess = payload =>
  makeCommonAction(types.UPDATE_ART_REVISION_SUCCESS, payload);
const updateArtRevisionError = error => {
  throw error;
};

const deleteArtRevisionRequest = () =>
  makeCommonAction(types.DELETE_ART_REVISION_REQUEST);
const deleteArtRevisionSuccess = payload =>
  makeCommonAction(types.DELETE_ART_REVISION_SUCCESS, payload);
const deleteArtRevisionError = error =>
  makeCommonAction(types.DELETE_ART_REVISION_ERROR, error, true);

export function fetchArt(id, revisionId) {
  return makeActionCreator({
    endpoint: revisionId ? `/arts/${id}?revision=${revisionId}` : `/arts/${id}`,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchArtRequest,
    successFunc: fetchArtSuccess,
    errorFunc: fetchArtError,
    error_message: "Error fetching Art",
  });
}

export function createArt(art) {
  const formData = new FormData();
  art.details = JSON.stringify(art.details);
  if (!art.file) delete art.file;

  Object.keys(art).forEach(key => {
    formData.append(key, art[key]);
  });

  return makeActionCreator({
    endpoint: "/arts?include=[revisions.adornmentType,brand]",
    fetchOptions: {
      body: formData,
      method: "POST",
      contentType: false,
    },
    requestFunc: createArtRequest,
    successFunc: createArtSuccess,
    errorFunc: createArtError,
    error_message: "Error creating Art",
    successMessage: "Art created successfully",
  });
}

export function updateArtRevisionActive(artId, id, callback) {
  return makeActionCreator({
    endpoint: `/arts/${artId}/revisions/${id}/active?include=[adornmentType,adornmentTypeApplicability]`,
    fetchOptions: {
      method: "PATCH",
      contentType: false,
    },
    requestFunc: updateArtRevisionRequest,
    successFunc: payload => {
      callback && callback(payload);
      return updateArtRevisionSuccess(payload);
    },
    errorFunc: updateArtRevisionError,
    error_message: "Error updating Art Revision",
    successMessage: "Art revision updated successfully",
  });
}

export function updateArtRevision(artId, id, art, callback) {
  const formData = new FormData();
  if (art.details) {
    art.details = JSON.stringify(art.details);
  }

  Object.keys(art).forEach(key => {
    formData.append(key, art[key]);
  });

  return makeActionCreator({
    endpoint: `/arts/${artId}/revisions/${id}`,
    fetchOptions: {
      body: formData,
      method: "PATCH",
      contentType: false,
    },
    requestFunc: updateArtRevisionRequest,
    successFunc: () => {
      callback && callback();
      return updateArtRevisionSuccess();
    },
    errorFunc: updateArtRevisionError,
    error_message: "Error creating Art Revision",
    successMessage: "Art revision updated successfully",
  });
}

export function updateArt(id, art, callback) {
  const formData = new FormData();
  if (art.details) {
    art.details = JSON.stringify(art.details);
  }

  Object.keys(art).forEach(key => {
    formData.append(key, art[key]);
  });

  return makeActionCreator({
    endpoint: `/arts/${id}?include=revisions`,
    fetchOptions: {
      body: formData,
      method: "PUT",
      contentType: false,
    },
    requestFunc: updateArtRequest,
    successFunc: art => {
      callback && callback(art);
      return updateArtSuccess(art);
    },
    errorFunc: updateArtError,
    error_message: "Error creating Art",
    successMessage: "Art created successfully",
  });
}

export function fetchArtRevisionsByCompany(companyId, options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `companies/${companyId}/revisions`,
      null,
      options
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchArtRevisionsRequest,
    successFunc: fetchArtRevisionsSuccess,
    errorFunc: fetchArtRevisionsError,
    error_message: "Error fetching Art Revisions",
  });
}

export function searchArts(query, options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildSearchUrl(
      "arts",
      "[revisions.adornmentType,brand]",
      query,
      options
    ),
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchArtsRequest,
    successFunc: fetchArtsSuccess,
    errorFunc: fetchArtsError,
    error_message: "Error fetching Arts",
  });
}

export function fetchArtsByBrand(brandId, options = {}) {
  const url = RequestService.buildIndexUrl(
    `brands/${brandId}/arts`,
    "",
    options
  );

  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchArtsRequest,
    successFunc: fetchArtsSuccess,
    errorFunc: fetchArtsError,
    error_message: "Error fetching Arts",
  });
}

export function fetchArtRevisions(
  artId,
  options = {
    orderBy: "revision",
    orderDirection: "DESC",
  }
) {
  const url = RequestService.buildIndexUrl(
    `arts/${artId}/revisions`,
    "[adornmentType,adornmentTypeApplicability]",
    options
  );

  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: fetchArtRevisionsRequest,
    successFunc: fetchArtRevisionsSuccess,
    errorFunc: fetchArtRevisionsError,
    error_message: "Error fetching Arts",
  });
}

export function deleteArtRevision(artId, id, callback) {
  return makeActionCreator({
    endpoint: `/arts/${artId}/revisions/${id}`,
    fetchOptions: {
      method: "DELETE",
    },
    requestFunc: deleteArtRevisionRequest,
    successFunc: () => {
      callback && callback();
      return deleteArtRevisionSuccess();
    },
    errorFunc: deleteArtRevisionError,
    error_message: "Error deleting Art revision",
    successMessage: "Art revision removed successfully",
  });
}
