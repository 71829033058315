import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../actions/authActions";
import {fetchCompanies} from "../../actions/companyActions";
import CompanySelectPage from "../pages/CompanySelectPage";

class CompanySelect extends Component {
  componentDidMount() {
    this.props.fetchCompanies({sort: "name", sortDirection: "ASC"});
  }
  componentWillMount() {
    if (this.props.selectedCompany) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedCompany) {
      if (this.props.goto_order) {
        this.props.history.push(
          `/companies/${this.props.goto_order.company_id}/reorders`
        );
        return;
      }
      this.props.history.push("/");
    }
  }

  handleSetSelectedCompany = companyId => {
    this.props.setSelectedCompany(companyId);
  };

  render() {
    return (
      <CompanySelectPage
        setSelectedCompany={this.handleSetSelectedCompany}
        companies={this.props.companies}
        history={this.props.history}
        isInternal={this.props.isInternal}
      />
    );
  }
}

CompanySelect.propTypes = {
  setSelectedCompany: PropTypes.func,
  selectedCompany: PropTypes.number,
  fetchCompanies: PropTypes.func,
  companies: PropTypes.array,
  history: PropTypes.object,
  isInternal: PropTypes.bool,
  goto_order: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    companies: state.companies.companies,
    goto_order: state.auth.goto_order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedCompany: company_id =>
      dispatch(actions.setSelectedCompany(company_id)),
    fetchCompanies: options => dispatch(fetchCompanies(options)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanySelect)
);
