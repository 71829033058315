import React, {Component} from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import classNames from "classnames";
import SelectField from "material-ui/SelectField";

import MenuItem from "../../../../shared/CNGMenuItem";
import Pagination from "../../../../shared/Pagination";
import PageLayout from "../../../../shared/Layout/Layouts/PageLayout";
import PageIndexHeader from "../../../../shared/PageComponents/PageIndexHeader";
import withStyles from "../../../shared/WithStyles";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";
import QuoteIndexTable from "./QuoteIndexTable";

class QuoteIndex extends Component {
  state = {
    filter: "active",
  };

  constructor(props) {
    super(props);
    this.searchQuote();
  }

  componentDidUpdate(prevProps) {
    if (hasQueryParamsChanged(this.props, prevProps)) {
      this.searchQuote();
    }
  }

  searchQuote() {
    const {
      fetchQuotes,
      searchQuotes,
      searchTerm,
      sortBy,
      sortDirection,
      page,
    } = this.props;
    const {filter} = this.state;

    const options = {
      orderBy: sortBy,
      orderDirection: sortDirection,
      page: page,
      perPage: 7,
      customFilter: filter,
    };

    if (searchTerm === "") {
      fetchQuotes(options);
      return;
    }

    searchQuotes(searchTerm, options);
  }

  searchQuotes = (e, value) => {
    this.props.setSearchTerm(value);
  };

  onPageChangeHandler = options => {
    this.props.setPage(options.page);
  };

  handleFilterChange = async (event, index, value) => {
    await this.setState({filter: value});
    this.searchQuote();
  };

  renderFilter() {
    const {isInternal} = this.props;
    const {filter} = this.state;
    return isInternal && (
      <SelectField
        onChange={this.handleFilterChange}
        value={filter}
        menuStyle={{borderRadius: "0px !important"}}
      >
        <MenuItem value="archived" primaryText="Archived Quotes" />
        <MenuItem value="active" primaryText="Active Quotes" />
        <MenuItem value="turned" primaryText="Turned to Orders" />
        <MenuItem value="historical" primaryText="Historical Quotes" />
      </SelectField>
    );
  }

  renderPagination() {
    return (
      <div className="userindex_pagination">
        <Pagination
          getData={this.onPageChangeHandler}
          count={this.props.quotesCount}
          currentPage={this.props.page - 1}
          perPage={7}
        />
      </div>
    );
  }

  render() {
    const {
      quotes,
      quotesCount,
      quotesUnreadCounts,
      searchTerm,
      isLoading,
      fetchQuotes,
      setSort,
      isInternal,
    } = this.props;
    const noQuotes = (quotes && quotes.length < 1) || !Array.isArray(quotes);
    return (
      <PageLayout loaderEnable isLoading={isLoading}>
        <PageIndexHeader
          noButton
          title="Quotes"
          searchFunc={_debounce(this.searchQuotes, 300, {
            leading: false,
            trailing: true,
          })}
          disableSearch={quotes && quotes.length < 1 && !searchTerm}
          searchTerm={searchTerm}
          customOptions={this.renderFilter()}
        />{" "}
        <h2
          className={classNames("userindex_noUsers", {
            hidden: quotesCount > 0,
          })}
        >
          Sorry, no results found for {`"${searchTerm}"`}
        </h2>
        <div className={classNames({hidden: noQuotes})}>
          <QuoteIndexTable
            quotes={quotes}
            isLoading={isLoading}
            fetchQuotes={fetchQuotes}
            sortRowsBy={setSort}
            unreadMessagesCounts={quotesUnreadCounts}
            isInternal={isInternal}
          />{" "}
          {this.renderPagination()}
        </div>
      </PageLayout>
    );
  }
}

QuoteIndex.propTypes = {
  isLoading: PropTypes.bool,
  isInternal: PropTypes.bool,
  searchTerm: PropTypes.string,
  filter: PropTypes.string,
  quotes: PropTypes.array,
  quotesCount: PropTypes.number,
  quotesUnreadCounts: PropTypes.object,
  page: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  location: PropTypes.object,
  setSearchTerm: PropTypes.func,
  setSort: PropTypes.func,
  fetchQuotes: PropTypes.func,
  searchQuotes: PropTypes.func,
  setPage: PropTypes.func,
  addQueryParam: PropTypes.func,
};

export default withStyles(QuoteIndex);
