import _snakeCase from "lodash/snakeCase";
import _isPlainObject from "lodash/isPlainObject";

const snakeCaseObject = payloadData => {
  return Object.keys(payloadData).reduce((nextPayloadData, fieldName) => {
    nextPayloadData[_snakeCase(fieldName)] = snakeCaseData(
      payloadData[fieldName]
    );
    return nextPayloadData;
  }, {});
};

const snakeCaseData = payloadData => {
  if (Array.isArray(payloadData)) {
    return payloadData.map(payloadRecod => snakeCaseData(payloadRecod));
  } else if (_isPlainObject(payloadData)) {
    return snakeCaseObject(payloadData);
  } else {
    return payloadData;
  }
};

export default snakeCaseData;
