// RaiseButton is not really open to changes
import lodash from "lodash";
import React, {cloneElement} from "react";
import PropTypes from "prop-types";
import MURaisedButton from "material-ui/RaisedButton";
import withStyles from "./WithStyles";

const borderRadius = 22;
const getMediumStyle = (props, buttonStyle) => {
  const {medium, labelStyle, style} = props;
  if (!medium) return buttonStyle;

  return {
    labelStyle: {
      ...lodash.defaultTo(labelStyle, {}),
      textTransform: "uppercase",
    },
    style: {
      ...lodash.defaultTo(style, {}),
      height: 32,
      minWidth: 130,
    },
  };
};

const getLargeStyle = (props, buttonStyle) => {
  const {large, labelStyle, style} = props;
  if (!large) return buttonStyle;

  return {
    labelStyle: {
      ...lodash.defaultTo(labelStyle, {}),
      textTransform: "uppercase",
    },
    style: {
      ...lodash.defaultTo(style, {}),
      height: 40,
      minWidth: 136,
    },
  };
};

const RaisedButton = props => {
  const {
    icon,
    className,
    styles,
    style,
    labelStyle,
    iconStyle,
    large, // eslint-disable-line no-unused-vars
    medium, // eslint-disable-line no-unused-vars
    ...additionalProps
  } = props;

  let buttonStyle = {
    style,
    labelStyle,
  };

  const overlayStyle = {
    borderRadius,
    backgroundColor: additionalProps.secondary
      ? styles.colors.secondary.lighter
      : styles.colors.primary.lighter,
  };

  buttonStyle = getMediumStyle(props, buttonStyle);
  buttonStyle = getLargeStyle(props, buttonStyle);

  return (
    <MURaisedButton
      {...additionalProps}
      buttonStyle={{borderRadius}}
      disabledBackgroundColor={
        additionalProps.secondary && styles.colors.secondary.light
      }
      overlayStyle={overlayStyle}
      labelStyle={buttonStyle.labelStyle}
      style={{...buttonStyle.style, borderRadius}}
      icon={
        icon &&
        cloneElement(icon, {
          style: {
            ...styles.icon,
            ...iconStyle,
          },
        })
      }
      className={[
        className,
        (additionalProps.secondary && "secondary") || "primary",
      ].join(" ")}
    />
  );
};

RaisedButton.propTypes = {
  icon: PropTypes.node,
  iconStyle: PropTypes.object,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(RaisedButton);
