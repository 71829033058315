import React from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import withStyles from "./WithStyles";

function Typography(props) {
  const {variant, styles, style, className, ...additionalProps} = props;

  const getStyle = () => {
    return styles.typography[variant] || styles.typography.body;
  };

  const getColor = () => {
    const {color} = props;
    if (!color) return;
    return {color: lodash.get(styles, `colors.${color}`)};
  };

  const getClassName = () => {
    const defaultClassName = lodash.defaultTo(className, "");
    return variant === "link"
      ? `${defaultClassName} link-style`
      : defaultClassName;
  };

  return React.createElement(
    lodash.defaultTo(props.component, "div"),
    {
      style: {...getStyle(), ...getColor(), ...style},
      className: getClassName(),
      ...additionalProps,
    },
    props.children
  );
}

Typography.propTypes = {
  color: PropTypes.string,
  styles: PropTypes.object,
  style: PropTypes.object,
  component: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withStyles(Typography);
