import React, {Component} from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class BreadCrumbs extends Component {
  static propTypes = {
    rootName: PropTypes.string.isRequired,
    rootUrl: PropTypes.string,
    pageName: PropTypes.string.isRequired,
    middletwo: PropTypes.string,
    middle: PropTypes.string,
    middleLink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    middletwoLink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disableMiddleTwoParams: PropTypes.bool,
    disableRootLink: PropTypes.bool,
    router: PropTypes.object,
  };

  componentDidMount() {
    const queryParams = this._middleQueryParams();
    this.setState({queryParams});
  }

  state = {
    queryParams: null,
    iconStyle: {
      fontSize: 18,
      color: "#0C5271",
      marginLeft: 3,
      fontWeight: 500,
    },
  };

  _renderMiddle() {
    const {rootUrl, rootName, middletwo, middle, middleLink} = this.props;
    return (
      <div className="row middle-xs start-xs">
        <FontIcon className="material-icons" style={this.state.iconStyle}>
          chevron_right
        </FontIcon>
        <Link
          to={`/${
            rootUrl ? rootUrl : rootName.toLowerCase()
          }/${middleLink}${(!middletwo && this.state.queryParams) || ""}`}
          className="reset-link-styles small-header"
        >
          {middle}
        </Link>
      </div>
    );
  }

  _renderMiddleTwo() {
    const {
      rootName,
      rootUrl,
      middletwo,
      middleLink,
      middletwoLink,
      disableMiddleTwoParams,
    } = this.props;

    return (
      <div className="row middle-xs start-xs">
        <FontIcon className="material-icons" style={this.state.iconStyle}>
          chevron_right
        </FontIcon>
        <Link
          to={`/${
            rootUrl ? rootUrl : rootName.toLowerCase()
          }/${middleLink}/${middletwoLink}${
            !disableMiddleTwoParams ? this.state.queryParams : ""
          }`}
          className="reset-link-styles small-header"
        >
          {middletwo}
        </Link>
      </div>
    );
  }

  _middleQueryParams = () => {
    const {router} = this.props;
    if (!router || !router.previousSearch) return "";
    return router.previousSearch;
  };

  render() {
    const {
      rootUrl,
      rootName,
      pageName,
      middle,
      middletwo,
      disableRootLink,
    } = this.props;

    return (
      <div className="breadcrumbs row middle-xs between-xs non-printable">
        <div className="row middle-xs start-xs">
          <Link
            to={`/${rootUrl ? rootUrl : rootName.toLowerCase()}/${(!middle &&
              this.state.queryParams) ||
              ""}`}
            disabled={disableRootLink}
            className="reset-link-styles small-header"
          >
            {rootName}
          </Link>
          {middle ? this._renderMiddle() : null}
          {middletwo ? this._renderMiddleTwo() : null}
          <FontIcon className="material-icons" style={this.state.iconStyle}>
            chevron_right
          </FontIcon>
          <span className="subtext breadcrumbs_pageName">{pageName}</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {router: state.router};
}

export default connect(mapStateToProps, () => ({}))(BreadCrumbs);
