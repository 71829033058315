import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/brandActions";
import {fetchArtsByBrand} from "../../../actions/artActions";
import {fetchCompany} from "../../../actions/companyActions";
import {openNotification} from "../../../actions/notificationActions";
import withPagination from "../../modules/withPagination";
import BrandDetailPage from "../../pages/Brands/BrandDetailPage";

class BrandDetail extends Component {
  static propTypes = {
    art: PropTypes.array,
    brand: PropTypes.object,
    company: PropTypes.object,
    deleteBrand: PropTypes.func.isRequired,
    fetchBrand: PropTypes.func.isRequired,
    fetchCompany: PropTypes.func.isRequired,
    fetchArtsByBrand: PropTypes.func.isRequired,
    isInternal: PropTypes.bool,
    match: PropTypes.object,
    openNotification: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    page: PropTypes.number,
    setPage: PropTypes.func,
  };

  state = {
    name: "",
  };

  componentWillMount() {
    const {match: {params: {company_id: companyId, id: brandId}}} = this.props;
    this.props.fetchCompany(companyId);
    this.props.fetchBrand(brandId, {include: ""});
    this._fetchArtsByBrand();
  }

  componentWillReceiveProps(nextProps) {
    const {brand} = nextProps;
    if (brand) {
      this.setState({name: brand && brand.name});
    }
  }

  componentDidUpdate(prevProps) {
    const {brand, page} = this.props;
    if (prevProps.brand !== brand) {
      this.setState({name: brand && brand.name});
    }

    if (prevProps.page !== page) {
      this._fetchArtsByBrand();
    }
  }

  handleUpdateBrandName = name => {
    const {updateBrand, brand} = this.props;
    updateBrand(brand.id, {name});
  };

  handleDeleteBrand = async () => {
    const {deleteBrand, brand, history, openNotification} = this.props;
    await deleteBrand(brand.id);

    openNotification("Brand has been archived");
    history.push(`/companies/${brand.companyId}/brands`);
  };

  handleSelectPage = options => {
    const {setPage} = this.props;
    setPage(options.page);
  };

  _fetchArtsByBrand() {
    const {fetchArtsByBrand, page, match: {params: {id: brandId}}} = this.props;

    fetchArtsByBrand(brandId, {
      relations: "revisions",
      perPage: 7,
      page,
      customSort: "revisions.name",
    });
  }

  render() {
    const {fetchBrand, brand, art, match: {params: {id}}} = this.props;
    return (
      <BrandDetailPage
        art={art}
        brand={brand}
        brandId={id}
        fetchBrand={id => fetchBrand(id)}
        onUpdateBrandName={this.handleUpdateBrandName}
        onDeleteBrand={this.handleDeleteBrand}
        onSelectPage={this.handleSelectPage}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    brand: state.brands.brand,
    art: state.art.artList,
    artCount: state.art.artCount,
    company: state.companies.company,
    count: state.companies.count,
    currentPage: state.companies.currentPage,
    isLoading: state.brands.isLoading,
    isLoadingArt: state.art.isLoading,
    isInternal: state.auth.isInternal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBrand: bindActionCreators(actions.fetchBrand, dispatch),
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    updateBrand: bindActionCreators(actions.updateBrand, dispatch),
    deleteBrand: bindActionCreators(actions.deleteBrand, dispatch),
    fetchArtsByBrand: bindActionCreators(fetchArtsByBrand, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
  };
}

export default withPagination(
  connect(mapStateToProps, mapDispatchToProps)(BrandDetail)
);
