import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Link} from "react-router-dom";
import FontIcon from "material-ui/FontIcon";
import {
  TableRow,
  TableRowColumn,
  TableHeader,
  TableBody,
} from "material-ui/Table";
import CNGTableCustom from "../../../../shared/CNGTable/CNGTableCustom";
import CNGTableRow from "../../../../shared/CNGTable/CNGTableRow";
import CNGTableRowColumn from "../../../../shared/CNGTable/CNGTableRowColumn";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
  thFirstLinkStyle,
  tableRowStyle,
} from "../../../../../styles/components/tableStyles";
import {getFormattedDate} from "../../../../../utils/dates";
import {status as statusData} from "../../../../../constants/quote";

class QuoteIndexTable extends Component {
  renderIndexTableHeader() {
    const {sortRowsBy} = this.props;
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          <TableRowColumn style={thFirstLinkStyle} className="CNG-th pointer">
            <div onClick={() => sortRowsBy("company_name")}>
              COMPANY
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            PRODUCTS
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            <div onClick={() => sortRowsBy("created_at")}>
              DATE
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
          <TableRowColumn style={thStyle} className="CNG-th">
            <div onClick={() => sortRowsBy("status")}>
              STATUS
              <img
                className="sorting-icon"
                src="../assets/imgs/icons/sorting.svg"
              />
            </div>
          </TableRowColumn>
        </TableRow>
      </TableHeader>
    );
  }

  _getQuoteStatus = quote => {
    const {isInternal} = this.props;
    const {status, noAdornment, noNewArt} = quote;
    const statusValue = statusData[status];
    if (!isInternal && statusValue.eu) {
      return noAdornment || noNewArt
        ? statusValue.eu.nameNoAdornment
        : statusValue.eu.name;
    }
    if (noAdornment || noNewArt) return statusValue.nameNoAdornment;
    return statusValue.name;
  };

  renderRow = quote => {
    const {unreadMessagesCounts} = this.props;
    const {id, uuid, companyName, items, createdAt, status} = quote;
    const unreadMessagesCount = unreadMessagesCounts
      ? unreadMessagesCounts[uuid]
      : 0;
    const productNames =
      items && items.map(({product}) => product.productName).join(", ");
    const statusValue = statusData[status];
    return (
      <CNGTableRow key={id} styles={tableRowStyle}>
        <CNGTableRowColumn highlight>
          <Link
            to={`/quotes/info/${id}`}
            className="no-link-styles"
            title={companyName}
          >
            {companyName}
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn>{productNames}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {createdAt && getFormattedDate(moment(createdAt))}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          title={statusValue.name}
          customStyles={{position: "relative"}}
        >
          <span className="flex flex-center" style={{color: statusValue.color}}>
            <FontIcon
              className="material-icons"
              style={{color: statusValue.color, padding: 4}}
            >
              {statusValue.icon}
            </FontIcon>
            {this._getQuoteStatus(quote)}
          </span>
          {unreadMessagesCount > 0 && (
            <span className="badge" style={{top: 0, right: 0}}>
              {unreadMessagesCount}
            </span>
          )}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  render() {
    const {quotes, isLoading} = this.props;
    const tableRows = Array.isArray(quotes) ? quotes.map(this.renderRow) : null;
    return (
      <CNGTableCustom
        tableStyle={tableStyle}
        displayRowCheckbox={false}
        isLoading={isLoading}
      >
        {this.renderIndexTableHeader()}
        <TableBody displayRowCheckbox={false}>{tableRows}</TableBody>
      </CNGTableCustom>
    );
  }
}

QuoteIndexTable.propTypes = {
  quotes: PropTypes.array,
  fetchQuotes: PropTypes.func,
  sortRowsBy: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  unreadMessagesCounts: PropTypes.object,
  isInternal: PropTypes.bool,
};

export default QuoteIndexTable;
