import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import classNames from "classnames";
import FilePreview from "react-preview-file";
import {FileIcon} from "@drawbotics/file-icons";
import FontIcon from "material-ui/FontIcon";
import IconButton from "material-ui/IconButton";
import TextField from "material-ui/TextField";

import {
  VALID_CHAT_IMAGE_FORMATS,
  PREVIEWABLE_IMAGE_FORMATS,
} from "../../../../constants/art";
import {loadingMessages} from "../../../../constants/chat";
import withStyles from "../../shared/WithStyles";
import Typography from "../../shared/Typography";
import RaisedButton from "../../shared/RaisedButton";
import Message from "./Message";

class MessagesSection extends Component {
  state = {
    file: null,
    message: "",
    messages: [],
    channels: [],
    currentChannel: null,
  };

  list = null;

  fileField = null;

  componentWillMount() {
    this.props.fetchChatMessagesRequest();
  }

  async componentDidMount() {
    const {messenger, setRef} = this.props;
    if (setRef) setRef(this);
    if (!messenger.sb.currentUser) await messenger.init();
    this.syncChannels();
  }

  componentDidUpdate(prevProps) {
    const {type, typeData, typeIdName, messages} = this.props;
    if (
      type !== prevProps.type ||
      _get(typeData, typeIdName) !==
        _get(prevProps.typeData, prevProps.typeIdName)
    ) {
      this.syncChannels();
    }
    if (messages.length !== prevProps.messages.length) {
      this.scrollListToBottom();
    }
  }

  componentWillUnmount() {
    const {clearMessages} = this.props;
    clearMessages();
  }

  syncChannels = async () => {
    const {
      messenger,
      type,
      typeData,
      typeIdName,
      userId,
      fetchChatChannel,
      fetchChatMessages,
    } = this.props;
    await fetchChatChannel(messenger, type, typeData, typeIdName, userId);
    await fetchChatMessages(messenger);
    this.scrollListToBottom();
  };

  scrollListToBottom = () => {
    const {messenger} = this.props;
    if (this.list) {
      if (messenger.currentChannel) messenger.currentChannel.markAsRead();
      this.list.scrollTop = this.list.scrollHeight;
    }
  };

  openFileBrowserHandler = ev => {
    this.fileField.click(ev);
  };

  removeSelectedFile = event => {
    event.preventDefault();
    this.setState({file: null});
  };

  handleFileChange = inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!file) return;
    if (!VALID_CHAT_IMAGE_FORMATS.includes(file.type)) {
      let types = VALID_CHAT_IMAGE_FORMATS.map(format => format.split("/")[1]);
      types = `${types.slice(0, -1).join(", ")} and ${types.slice(-1)[0]}`;
      openNotification(
        `Uploaded file is not a valid input image. Only ${types}.`
      );
    } else {
      this.setState({file});
    }
  };

  handleChangeMessage = (event, value) => {
    this.setState({message: value});
  };

  handleSendMessageOnPress = async event => {
    if (event.key !== "Enter") return;
    this.handleSendMessage();
  };

  handleSendMessage = async () => {
    const {type, typeData, typeIdName, messenger, sendChatMessage} = this.props;
    const {message, file} = this.state;
    if (!message && !file) return;
    const data = {
      type,
      typeId: typeData[typeIdName],
    };
    await sendChatMessage(messenger, data, message, file);
    this.setState({message: "", file: null});
    this.scrollListToBottom();
  };

  get pageStyle() {
    const {styles: {colors}, slimVersion, largeVersion} = this.props;
    const messagesSection = {
      maxHeight: 320,
    };
    if (slimVersion) {
      messagesSection.maxHeight = 290;
    }
    if (largeVersion) {
      messagesSection.maxHeight = 502;
      messagesSection.height = 523;
    }
    return {
      titleIcon: {
        color: colors.primary.light,
        fontSize: 20,
        paddingRight: 10,
      },
      content: {
        marginTop: slimVersion || largeVersion ? 0 : 10,
        backgroundColor: colors.white,
        borderRadius: slimVersion ? 0 : 4,
      },
      messagesSection: {
        minHeight: slimVersion ? 290 : 160,
        ...messagesSection,
        padding: 16,
        overflow: "auto",
      },
      inputSection: {
        paddingLeft: slimVersion ? 0 : 16,
        paddingRight: slimVersion ? 0 : 16,
        backgroundColor: `${colors.gray.border}4d`,
        borderRadius: 4,
      },
      inputStyle: {
        margin: slimVersion ? 8 : 16,
      },
      button: {
        margin: 16,
        minWidth: 48,
      },
      buttonLabel: {
        color: colors.white,
        justifyContent: "center",
        alignItems: "center",
      },
      imageFile: {
        width: slimVersion ? 36 : 144,
        height: slimVersion ? 36 : 144,
      },
      deleteIcon: {
        fontSize: 20,
        marginRight: 8,
        color: "#0C5371",
      },
    };
  }

  renderTitle = () => {
    const {customTitle} = this.props;
    if (customTitle) return customTitle;
    return (
      <Typography
        variant="h4"
        className="flex flex-center justify-content-center pointer"
        color="primary.light"
      >
        <FontIcon className="material-icons" style={this.pageStyle.titleIcon}>
          message
        </FontIcon>
        Messages
      </Typography>
    );
  };

  renderMessage = (isLoading = false) => message => (
    <Message key={message.messageId} message={message} isLoading={isLoading} />
  );

  renderMessages = () => {
    const {messages, isLoadingMessages} = this.props;
    return (
      <div
        className={`w-100 ${isLoadingMessages ? "padding-0" : ""}`}
        ref={el => (this.list = el)}
        style={this.pageStyle.messagesSection}
      >
        {isLoadingMessages
          ? loadingMessages.map(this.renderMessage(true))
          : messages.map(this.renderMessage())}
        {!isLoadingMessages &&
          messages.length < 1 && (
          <Typography
            variant="h5"
            className="flex flex-center justify-content-center"
            color="primary.light"
          >
              No Messages
          </Typography>
        )}
      </div>
    );
  };

  renderInput = () => {
    const {slimVersion, isLoadingMessages} = this.props;
    const {message, file} = this.state;
    return (
      <div className="flex flex-center" style={this.pageStyle.inputSection}>
        <input
          type="file"
          onChange={this.handleFileChange}
          className="hidden-safe"
          style={{display: "none"}}
          name="file"
          tabIndex={-1}
          ref={input => (this.fileField = input)}
        />
        {!file && (
          <IconButton
            iconClassName="material-icons"
            onClick={this.openFileBrowserHandler}
          >
            attach_file
          </IconButton>
        )}
        {file && (
          <FilePreview file={file}>
            {preview => (
              <div
                className="flex justify-content-center align-items-center"
                style={{}}
              >
                <div className="flex align-items-center" style={{padding: 8}}>
                  {PREVIEWABLE_IMAGE_FORMATS.includes(file.type) ? (
                    <img src={preview} style={this.pageStyle.imageFile} />
                  ) : (
                    <FileIcon
                      filename={file.name}
                      style={this.pageStyle.imageFile}
                    />
                  )}
                </div>
                <FontIcon
                  className="pointer material-icons"
                  onClick={this.removeSelectedFile}
                  style={this.pageStyle.deleteIcon}
                >
                  delete
                </FontIcon>
              </div>
            )}
          </FilePreview>
        )}
        <TextField
          fullWidth
          disabled={isLoadingMessages}
          className="flex flex-one"
          hintText="Write a message"
          name="message"
          value={message}
          onChange={this.handleChangeMessage}
          onKeyPress={this.handleSendMessageOnPress}
          style={this.pageStyle.inputStyle}
        />
        <RaisedButton
          large={!slimVersion}
          secondary
          disabled={!message && !file}
          onClick={this.handleSendMessage}
          label={slimVersion ? "" : "Send"}
          icon={
            <FontIcon
              className="material-icons"
              color="white"
              style={{padding: 4, fontSize: 20}}
            >
              send
            </FontIcon>
          }
          iconStyle={{marginLeft: 4}}
          style={this.pageStyle.button}
          labelStyle={this.pageStyle.buttonLabel}
        />
      </div>
    );
  };

  render() {
    const {slimVersion, largeVersion, style} = this.props;
    return (
      <div
        className={classNames(
          "chat-messages-section flex flex-column align-items-start w-100",
          {
            "margin-top-24": !slimVersion && !largeVersion,
          }
        )}
        style={style}
      >
        {this.renderTitle()}
        <div className="flex flex-column w-100" style={this.pageStyle.content}>
          {this.renderMessages()}
          {this.renderInput()}
        </div>
      </div>
    );
  }
}

MessagesSection.propTypes = {
  setRef: PropTypes.func,
  type: PropTypes.string,
  typeData: PropTypes.object,
  typeIdName: PropTypes.string,
  styles: PropTypes.object,
  messenger: PropTypes.object,
  openNotification: PropTypes.func,
  userId: PropTypes.string,
  isLoading: PropTypes.bool,
  customTitle: PropTypes.any,
  slimVersion: PropTypes.bool,
  largeVersion: PropTypes.bool,
  channel: PropTypes.object,
  channels: PropTypes.array,
  messages: PropTypes.array,
  isLoadingMessages: PropTypes.bool,
  fetchChatChannels: PropTypes.func,
  fetchChatChannel: PropTypes.func,
  fetchChatMessages: PropTypes.func,
  sendChatMessage: PropTypes.func,
  clearMessages: PropTypes.func,
  fetchChatMessagesRequest: PropTypes.func,
  style: PropTypes.object,
};

export default withStyles(MessagesSection);
