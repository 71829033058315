import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function chatReducer(
  state = initialState.chat,
  {type, payload, error}
) {
  switch (type) {
  case actions.FETCH_CHAT_CHANNELS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_CHAT_CHANNELS_SUCCESS:
    return {
      ...state,
      channels: payload,
      isLoading: false,
    };
  case actions.FETCH_CHAT_CHANNELS_ERROR:
    return {
      ...state,
      error: error,
      error_message: error,
      isLoading: false,
    };
  case actions.FETCH_CHAT_CHANNEL_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoadingMessages: true,
    };
  case actions.FETCH_CHAT_CHANNEL_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case actions.FETCH_CHAT_CHANNEL_ERROR:
    return {
      ...state,
      error: error,
      error_message: error,
      isLoading: false,
    };
  case actions.FETCH_CHAT_MESSAGES_REQUEST:
    return {
      ...state,
      isLoadingMessages: true,
    };
  case actions.FETCH_CHAT_MESSAGES_SUCCESS:
    return {
      ...state,
      messages: payload,
      isLoadingMessages: false,
    };
  case actions.FETCH_CHAT_MESSAGES_ERROR:
    return {
      ...state,
      error: error,
      error_message: error,
      isLoadingMessages: false,
    };
  case actions.SEND_CHAT_MESSAGES_REQUEST:
    return {
      ...state,
      sendingMessage: true,
    };
  case actions.SEND_CHAT_MESSAGES_SUCCESS:
    return {
      ...state,
      sendingMessage: false,
      messages: [...state.messages, payload],
    };
  case actions.SEND_CHAT_MESSAGES_ERROR:
    return {
      ...state,
      error: error,
      error_message: error,
      sendingMessage: false,
    };
  case actions.RECEIVE_CHAT_MESSAGES_SUCCESS:
    return {
      ...state,
      messages: [...state.messages, payload],
    };
  case actions.FETCH_CHATS_UNREAD_COUNTS:
    return {
      ...state,
      chatsUnreadCounts: payload,
    };
  case actions.CLEAR_CHAT_MESSAGES:
    return {
      messages: [],
    };
  default:
    return state;
  }
}
