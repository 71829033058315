import React, {Component} from "react";
import PropTypes from "prop-types";
import {Tabs, Tab} from "material-ui/Tabs";
import ArtsTab from "./ArtsTab";

const inkBarStyle = {
  height: 1,
  backgroundColor: "#BDBEC1",
};
const tabContainerStyle = {
  background: "transparent",
  marginTop: 70,
};
const tabButtonStyle = {
  color: "#0c5371",
  width: 148,
  borderBottom: "3px solid #1D9ABB",
  marginBottom: 1,
  fontSize: 12,
  fontWeight: "bold",
};

export default class ArtTabs extends Component {
  static propTypes = {
    revisions: PropTypes.array,
    brand: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  render() {
    const {revisions, brand, isLoading} = this.props;

    let selectedTab = 1;
    return (
      <Tabs
        tabItemContainerStyle={tabContainerStyle}
        inkBarStyle={inkBarStyle}
        initialSelectedIndex={selectedTab}
      >
        <Tab label="ART VERSIONS" buttonStyle={tabButtonStyle}>
          <ArtsTab revisions={revisions} brand={brand} isLoading={isLoading} />
        </Tab>
      </Tabs>
    );
  }
}
