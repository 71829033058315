import React, {Component} from "react";
import PropTypes from "prop-types";

import EmbroirderForm from "./EmbroideryForm";
import LeatherForm from "./LeatherForm";
import LeatherAndDyeForm from "./LeatherAndDyeForm";
import PatchForm from "./PatchForm";

export default class AdornmentTypeForm extends Component {
  static propTypes = {
    onDetailChange: PropTypes.func.isRequired,
    onValidChange: PropTypes.func.isRequired,
    details: PropTypes.object,
    adornmentTypeId: PropTypes.number,
    adornmentTypes: PropTypes.array,
    submitted: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
  };

  showAdormentTypeSection = () => {
    const detailOptions = {
      embroidery: EmbroirderForm,
      etching: LeatherForm,
      name_drop_or_monogram: LeatherForm,
      patch: PatchForm,
      etch_and_dye: LeatherAndDyeForm,
      embroidery_large_format: EmbroirderForm,
    };
    const selectedAdornmentType = this._getSelectedAdornmentType();
    if (!selectedAdornmentType) return;
    const detailForm = detailOptions[selectedAdornmentType.internalIdentifier];
    const {adornmentApplicabilities} = selectedAdornmentType;
    return React.createElement(detailForm, {
      ...this.props,
      applicabilities: adornmentApplicabilities,
      handleUpdateValid: this._handleUpdateValid,
    });
  };

  _handleUpdateValid = valid => {
    this.props.onValidChange(valid);
  };

  _getSelectedAdornmentType = () => {
    const {adornmentTypeId, adornmentTypes = []} = this.props;
    return adornmentTypes.find(
      adornmentType => adornmentType.id == adornmentTypeId
    );
  };

  render() {
    return (
      <div className="adornmentTypeForm">{this.showAdormentTypeSection()}</div>
    );
  }
}
