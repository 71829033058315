import React, {Component} from "react";
import PropTypes from "prop-types";

import FormattedPhone from "../../../shared/FormattedPhone";
import SectionHeading from "../../../shared/SectionHeading";
import FormattedAddress from "../../../shared/FormattedAddress";
import lodashObject from "lodash/object";

class UserDetailInfo extends Component {
  _getLocationResourceById(resources, value) {
    if (!Array.isArray(resources)) return null;
    const matched = resources.filter(({id}) => id == value);
    return matched.length ? matched[0] : null;
  }

  render() {
    const {
      userDetail,
      title,
      titleFontWeight,
      titleFontSize,
      titleTextTransform,
      titleFontColor,
      order,
      showInvoiceCopy,
    } = this.props;

    return (
      <div className="flex-column ">
        <SectionHeading
          text={title || "General Information"}
          marginBottom={14}
          fontSize={titleFontSize}
          fontWeight={titleFontWeight}
          textTransform={titleTextTransform}
          fontColor={titleFontColor}
        />
        <div className="company_details_section">
          <div>
            <span className="detail-heading">Company</span>
            <span className="subtext">
              {lodashObject.get(userDetail, "companyName")}
            </span>
          </div>
          <div>
            <span className="detail-heading">Phone</span>
            <span className="subtext">
              {userDetail ? (
                <FormattedPhone
                  phoneNumber={lodashObject.get(userDetail, "phoneNumber")}
                  countryCode={lodashObject.get(
                    userDetail,
                    "country.code",
                    userDetail.countryCode
                  )}
                />
              ) : (
                "NA"
              )}
            </span>
          </div>
          <div>
            <span className="detail-heading">Email</span>
            <span className="subtext">
              {userDetail ? userDetail.emailAddress : "NA"}
            </span>
          </div>
          <div>
            <span className="detail-heading">Address</span>
            <div className="detail_addressBlock">
              <span className="subtext">
                <FormattedAddress user={userDetail || {}} multilines />
              </span>
            </div>
          </div>
          {showInvoiceCopy && (
            <div className="flex align-items-end">
              <span
                className="detail-heading"
                style={{height: "auto", marginBottom: 0}}
              >
                Invoice Copied to
              </span>
              <div
                className="subtext"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <span className="subtext">
                  {order && order.createdUser && order.createdUser.name}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UserDetailInfo.propTypes = {
  title: PropTypes.string,
  userDetail: PropTypes.object.isRequired,
  titleFontSize: PropTypes.number,
  titleFontWeight: PropTypes.string,
  titleTextTransform: PropTypes.string,
  titleFontColor: PropTypes.string,
  countries: PropTypes.array,
  order: PropTypes.object,
  showInvoiceCopy: PropTypes.bool,
};

export default UserDetailInfo;
