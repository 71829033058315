import React, {Component} from "react";
import PropTypes from "prop-types";

import {Tabs, Tab} from "material-ui/Tabs";

import Notes from "../../../shared/Notes/Notes";
import {
  tabContainerStyle,
  inkBarStyle,
  tabButtonStyle,
} from "../../../../styles/components/tabStyles";

export default class UserDetailCardHeader extends Component {
  static propTypes = {
    userDetail: PropTypes.object.isRequired,
    notes: PropTypes.array,
    deleteUserNote: PropTypes.func.isRequired,
    createUserNote: PropTypes.func.isRequired,
    loggedInUserId: PropTypes.number,
    userNoteRequestLoading: PropTypes.bool,
  };

  _renderNotesTab() {
    const {
      notes,
      userDetail,
      loggedInUserId,
      deleteUserNote,
      createUserNote,
      userNoteRequestLoading,
    } = this.props;
    return (
      <Tab label="Notes" buttonStyle={tabButtonStyle}>
        <div className="tab-container">
          <Notes
            notes={notes}
            userDetail={userDetail}
            deleteNote={deleteUserNote}
            createNote={createUserNote}
            isLoading={userNoteRequestLoading}
            loggedInUserId={loggedInUserId}
          />
        </div>
      </Tab>
    );
  }

  render() {
    return (
      <Tabs
        tabItemContainerStyle={{
          ...tabContainerStyle,
          borderBottom: "1px solid #BDC6CF",
        }}
        inkBarStyle={inkBarStyle}
      >
        {this._renderNotesTab()}
      </Tabs>
    );
  }
}
