import * as types from "../constants/actionTypes";
import fetchAPI from "../utils/fetchAPI";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchProductsRequest = () =>
  makeCommonAction(types.FETCH_PRODUCTS_REQUEST);
const fetchProductsSuccess = payload =>
  makeCommonAction(types.FETCH_PRODUCTS_SUCCESS, payload);
const fetchProductsError = error =>
  makeCommonAction(types.FETCH_PRODUCTS_ERROR, error, true);

const fetchProductRequest = () => makeCommonAction(types.FETCH_PRODUCT_REQUEST);
const fetchProductSuccess = payload =>
  makeCommonAction(types.FETCH_PRODUCT_SUCCESS, payload);
const fetchProductError = error =>
  makeCommonAction(types.FETCH_PRODUCT_ERROR, error, true);

const fetchProductImagesRequest = () =>
  makeCommonAction(types.FETCH_PRODUCT_IMAGES_REQUEST);
const fetchProductImagesSuccess = payload =>
  makeCommonAction(types.FETCH_PRODUCT_IMAGES_SUCCESS, payload);
const fetchProductImagesError = error =>
  makeCommonAction(types.FETCH_PRODUCT_IMAGES_ERROR, error, true);

const fetchOrderItemOrderItemProductRequest = () =>
  makeCommonAction(types.FETCH_ORDER_ITEM_PRODUCT_REQUEST);
const fetchOrderItemOrderItemProductSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_ITEM_PRODUCT_SUCCESS, payload);
const fetchOrderItemProductError = error =>
  makeCommonAction(types.FETCH_ORDER_ITEM_PRODUCT_ERROR, error, true);

const searchProductShipmentRequest = () =>
  makeCommonAction(types.SEARCH_PRODUCT_SHIPMENTS_REQUEST);
const searchProductShipmentSuccess = payload =>
  makeCommonAction(types.SEARCH_PRODUCT_SHIPMENTS_SUCCESS, payload);
const searchProductShipmentError = error =>
  makeCommonAction(types.SEARCH_PRODUCT_SHIPMENTS_ERROR, error, true);

export function fetchOrderItemProduct(companyId, id, options = {}) {
  return makeActionCreator({
    endpoint: `/companies/${companyId}/order_item_products/${id}?include=${
      options.include
    }`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchOrderItemOrderItemProductRequest,
    successFunc: fetchOrderItemOrderItemProductSuccess,
    errorFunc: fetchOrderItemProductError,
    error_message: "Error fetching order item product",
  });
}

export const fetchOrderItemProducts = companyId => {
  return async dispatch => {
    return await fetchAPI(
      {
        url: `/companies/${companyId}/order_item_products/cart`,
        method: "GET",
        types: [
          types.FETCH_ORDER_ITEM_PRODUCTS_REQUEST,
          types.FETCH_ORDER_ITEM_PRODUCTS_SUCCESS,
          types.OPEN_ERROR_MESSAGE,
        ],
      },
      dispatch
    );
  };
};

export function fetchProducts(options = {}) {
  const {delay, ...opts} = options;
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl("products", opts.include, opts),
    fetchOptions: {method: "GET"},
    requestFunc: fetchProductsRequest,
    successFunc: fetchProductsSuccess,
    errorFunc: fetchProductsError,
    error_message: "Error fetching products",
    delay,
  });
}

export function fetchProduct(id) {
  return makeActionCreator({
    endpoint: `/products/${id}/`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchProductRequest,
    successFunc: fetchProductSuccess,
    errorFunc: fetchProductError,
    error_message: "Error fetching product",
  });
}

export function fetchProductImages(id) {
  return makeActionCreator({
    endpoint: `/productimages/?filter=product.id,eq,${id}`,
    fetchOptions: {method: "GET"},
    requestFunc: fetchProductImagesRequest,
    successFunc: fetchProductImagesSuccess,
    errorFunc: fetchProductImagesError,
    error_message: "Error fetching product images",
  });
}

export function searchProductShipments(
  query,
  companyId,
  productId,
  options = {}
) {
  const url = RequestService.buildIndexUrl(
    `companies/${companyId}/order_item_products/${productId}/shipments/search?search_term=${query}`,
    null,
    options
  );
  return makeActionCreator({
    endpoint: url,
    fetchOptions: {
      method: "GET",
    },
    requestFunc: searchProductShipmentRequest,
    successFunc: searchProductShipmentSuccess,
    errorFunc: searchProductShipmentError,
    error_message: "Error fetching orders",
  });
}
