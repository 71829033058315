import {connect} from "react-redux";
import {push} from "react-router-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";

import OrderCartNew from "./OrderCartNew";

import {fetchCompanies} from "../../../../actions/companyActions";
import {fetchPaymentTerms} from "../../../../actions/paymentTermsActions";

import {
  createReorderItem,
  fetchCurrentOpenOrders,
} from "../../../../actions/orderActions";

function mapStateToProps(state) {
  const {
    auth: {isInternal},
    companies: {companies},
    orders: {currentOpenOrders: openOrders, isOpenOrderLoading},
    paymentTerms: {paymentTerms},
  } = state;
  return {companies, isInternal, openOrders, isOpenOrderLoading, paymentTerms};
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: bindActionCreators(push, dispatch),
    createReorderItem: bindActionCreators(createReorderItem, dispatch),
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    fetchCurrentOpenOrders: bindActionCreators(
      fetchCurrentOpenOrders,
      dispatch
    ),
    fetchPaymentTerms: bindActionCreators(fetchPaymentTerms, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderCartNew)
);
