import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ArtRow from "./ArtRow";
import _sortBy from "lodash/sortBy";
import _get from "lodash/get";

import {TableRow, TableRowColumn, TableHeader} from "material-ui/Table";

import CNGTableCustom from "../../../shared/CNGTable/CNGTableCustom";
import {
  tableStyle,
  tableHeaderStyle,
  tableHeaderRowStyle,
  thStyle,
} from "../../../../styles/components/tableStyles";

export default class ArtList extends Component {
  static propTypes = {
    art: PropTypes.array,
    artCount: PropTypes.number,
    isLoading: PropTypes.bool,
  };

  _renderHeader() {
    const header = [
      {
        title: "Art Name",
        width: "22%",
      },
      {
        title: "Adornment Type",
        width: "18%",
      },
      {
        title: "Details",
        width: "60%",
      },
    ];

    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        displayRowCheckbox={false}
        displayBorder={false}
        style={tableHeaderStyle}
      >
        <TableRow displayBorder={false} style={tableHeaderRowStyle}>
          {header.map((header, key) => (
            <TableRowColumn
              style={{
                ...thStyle,
                width: header.width,
              }}
              key={key}
              className="CNG-th"
            >
              {header.title}
            </TableRowColumn>
          ))}
        </TableRow>
      </TableHeader>
    );
  }

  render() {
    const {art, artCount} = this.props;
    const sortedArt = _sortBy(art || [], artInstance =>
      _get(artInstance, "revisions", []).every(revision => !revision.active)
    );

    return (
      <div>
        <CNGTableCustom
          tableStyle={tableStyle}
          displayRowCheckbox={false}
          sort={this._sortRows}
          isLoading={this.props.isLoading}
        >
          {this._renderHeader()}
          <h2
            className={classNames("userindex_noUsers", {hidden: artCount < 1})}
          >
            No Arts
          </h2>
        </CNGTableCustom>

        {sortedArt.map(art => {
          return (
            <ArtRow art={art} key={art.id} renderHeader={this._renderHeader} />
          );
        })}
      </div>
    );
  }
}
