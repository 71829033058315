import React, {Component} from "react";
import PropTypes from "prop-types";
import ToolTip from "react-portal-tooltip";
import moment from "moment";

export default class TextWithToolTip extends Component {
  static propTypes = {
    text: PropTypes.string,
    disableTooltip: PropTypes.bool,
    textContainerStyles: PropTypes.object,
  };

  state = {
    showTooltip: false,
    id: `tooltip_parent_${moment().unix()}`,
  };

  _toggleTooltip() {
    const {disableTooltip} = this.props;
    if (disableTooltip) {
      return;
    }
    const {showTooltip} = this.state;
    this.setState({
      showTooltip: !showTooltip,
    });
  }

  render() {
    const {text, textContainerStyles} = this.props;
    const {id} = this.state;
    return (
      <div
        style={textContainerStyles ? textContainerStyles : {}}
        id={id}
        onMouseEnter={() => this._toggleTooltip()}
        onMouseLeave={() => this._toggleTooltip()}
      >
        {text}
        <ToolTip
          active={this.state.showTooltip}
          position="top"
          parent={`#${id}`}
        >
          <span className="small-text text-bold-medium base-padding">
            {text}
          </span>
        </ToolTip>
      </div>
    );
  }
}
