import {getCountryRegionsFromCountryCode} from "./countryRegionsHelper";

function getCountryCode(recipient, countries = []) {
  let {country, countryCode, countryId} = recipient;
  if (countryCode || country) return countryCode || country.code;

  country = countries.find(country => {
    return country && Number(country.id) === Number(countryId);
  });
  return country && country.code;
}

function getStateCode(countryCode, recipient) {
  let {state, stateCode} = recipient;
  if (stateCode || state) {
    const regions = getCountryRegionsFromCountryCode(countryCode);
    const region = regions.find(region =>
      [stateCode, state].includes(region.name)
    );
    const isNumber = region && !isNaN(region.shortCode);
    const isState = region ? (isNumber ? region.name : region.shortCode) : stateCode || state;
    return isState;

  }
}

export function formatAddress(recipient = {}, countries) {
  const {address1, address2, zipCode, city} = recipient || {};

  const countryCode = getCountryCode(recipient || {}, countries);
  const stateCode = getStateCode(countryCode, recipient || {});
  const parsedAddress2 = address2 ? `, ${address2}` : "";
  return {
    title: `${address1}${parsedAddress2}  ${city}, ${stateCode}  ${zipCode}  ${countryCode}`,
    address1,
    address2: parsedAddress2,
    city,
    stateCode,
    zipCode,
    countryCode,
  };
}
