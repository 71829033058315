import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const fetchAdornmentLocationsRequest = () =>
  makeCommonAction(types.FETCH_ADORNMENT_LOCATIONS_REQUEST);
const fetchAdornmentLocationsSuccess = payload =>
  makeCommonAction(types.FETCH_ADORNMENT_LOCATIONS_SUCCESS, payload);
const fetchAdornmentLocationsError = error =>
  makeCommonAction(types.FETCH_ADORNMENT_LOCATIONS_ERROR, error, true);

export function fetchAdornmentLocationsByProduct(productId, options) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `products/${productId}/adornment_locations`,
      null,
      options
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchAdornmentLocationsRequest,
    successFunc: fetchAdornmentLocationsSuccess,
    errorFunc: fetchAdornmentLocationsError,
    error_message: "Error fetching Adornment Locations",
  });
}
