import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import ChillButton from "../../../../shared/ChillButton";
import SearchInput from "../../../../shared/SearchInput";
import Pagination from "../../../../shared/Pagination";
import {hasQueryParamsChanged} from "../../../../../utils/indexHelper";

import AddressBookTable from "./AddressBookTable";

import lodash from "lodash";

export default class AddressBookTab extends Component {
  static propTypes = {
    searchContacts: PropTypes.func,
    fetchCompanyContacts: PropTypes.func,
    fetchWalletMembers: PropTypes.func,
    createWalletMember: PropTypes.func,
    archiveCompanyContact: PropTypes.func,
    companyContacts: PropTypes.array,
    companyContactsCount: PropTypes.number,
    company: PropTypes.object,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    searchTerm: PropTypes.string,
    setPage: PropTypes.func,
    page: PropTypes.number,
    selectedTab: PropTypes.string,
    companyId: PropTypes.number,
    history: PropTypes.object,
  };

  componentDidMount() {
    this.props.fetchCompanyContacts();
  }

  componentDidUpdate(prevProps) {
    const isActiveTab = this.props.selectedTab !== prevProps.selectedTab;

    if (
      this.props.selectedTab === "recipients" &&
      (hasQueryParamsChanged(this.props, prevProps) || isActiveTab)
    ) {
      this.props.fetchCompanyContacts();
    }
  }

  handleSelectPage = options => {
    const {setPage} = this.props;

    setPage(options.page);
  };

  _renderPagination() {
    const {companyContactsCount, isLoading} = this.props;
    return (
      <Pagination
        isLoading={isLoading}
        getData={this.handleSelectPage}
        count={companyContactsCount}
        currentPage={this.props.page - 1}
      />
    );
  }

  _handleSearchContacts(event, value) {
    this.props.setSearchTerm(value);
  }

  _handleSearchContactsDebounced = lodash.debounce(
    this._handleSearchContacts,
    300
  );

  archiveCompanyContact = companyContact => {
    const {
      archiveCompanyContact,
      fetchCompanyContacts,
      setSearchTerm,
      fetchWalletMembers,
      companyId,
    } = this.props;
    archiveCompanyContact(companyContact.companyId, companyContact.id, () => {
      setSearchTerm("");
      fetchCompanyContacts();
      fetchWalletMembers(companyId);
    });
  };

  render() {
    const {
      createWalletMember,
      companyContacts,
      company,
      companyContactsCount,
      isLoading,
      searchTerm,
      companyId,
      isInternal,
      history,
    } = this.props;

    return (
      <div className="tab-container">
        <div
          className="flex flex-row"
          style={{
            marginBottom: 16,
          }}
        >
          <div className="flex-one flex flex-row">
            <SearchInput
              search={(event, value) =>
                this._handleSearchContactsDebounced(event, value)
              }
              initialValue={searchTerm}
            />
            <Link
              to={`/companies/${company && company.id}/contacts/create`}
              className="reset-link-styles"
            >
              <ChillButton
                name={"ADD A RECIPIENT"}
                height={40}
                width={157}
                marginLeft={16}
                disabled={isLoading}
                icon={"adduser"}
              />
            </Link>
          </div>
          {companyContactsCount > 7 ? this._renderPagination() : null}
        </div>
        <AddressBookTable
          isLoading={isLoading}
          contacts={companyContacts}
          company={company}
          currentPage={this.props.page}
          isInternal={isInternal}
          history={history}
          companyId={companyId}
          searching={!!searchTerm}
          archiveCompanyContact={this.archiveCompanyContact}
          createWalletMember={createWalletMember}
        />
      </div>
    );
  }
}
