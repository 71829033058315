import React, {Component} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ChillButton from "../../../shared/ChillButton";
import CircularProgress from "material-ui/CircularProgress";
import Checkbox from "material-ui/Checkbox";
import queryString from "query-string";

const labelStyle = {
  color: "#0c5271",
  fontSize: 14,
  fontWeight: "bold",
  width: "calc(100% - 3px)",
};

export default class OrderPrint extends Component {
  static propTypes = {
    isInternal: PropTypes.bool,
    orderStatus: PropTypes.array,
    onClose: PropTypes.func,
    order: PropTypes.object,
    onUpdateOrderStatus: PropTypes.func,
  };

  state = {
    isLoading: false,
    shipmentResult: true,
    packingList: true,
    productRequirements: true,
    artRequirements: true,
    orderInformation: false,
  };

  _handleChangeStatus = (event, selectedOption) => {
    event.preventDefault();
    this.setState({orderStatusId: selectedOption});
  };

  _handleUpdate = async () => {
    const {order} = this.props;
    let options = lodash.pickBy(this.state);
    if (!this.hasQtyToShip()) {
      options = lodash.omit(options, ["shipmentResult", "packingList"]);
    }

    if (order.isDsod) {
      options = lodash.omit(options, [
        "productRequirements",
        "artRequirements",
      ]);
    }

    window.open(
      `/companies/${order.companyId}/orders/${
        order.id
      }/print?${queryString.stringify(options)}`,
      "_blank"
    );

    this.props.onClose();
  };

  _onCheck = option => {
    const previousValue = this.state[option];
    this.setState({[option]: !previousValue});
  };

  hasQtyToShip = () => {
    const {order} = this.props;
    return !!order.items.find(item => item.quantityToShip > 0);
  };

  render() {
    const {onClose, order, isInternal} = this.props;
    const {
      isLoading,
      shipmentResult,
      packingList,
      artRequirements,
      productRequirements,
      orderInformation,
    } = this.state;
    const hasQtyToShip = this.hasQtyToShip();

    return (
      <div className="flex flex-center flex-column">
        <h4 className="page-title" style={{fontSize: 24, padding: "18px 0"}}>
          Select Documents to Print
        </h4>

        <div>
          {!order.isDsod && (
            <Checkbox
              label="Art Requirements"
              labelStyle={labelStyle}
              checked={artRequirements}
              onCheck={() => {
                this._onCheck("artRequirements");
              }}
            />
          )}
          {hasQtyToShip && (
            <Checkbox
              label="Shipment Results"
              labelStyle={labelStyle}
              checked={shipmentResult}
              onCheck={() => {
                this._onCheck("shipmentResult");
              }}
            />
          )}
          {!order.isDsod && (
            <Checkbox
              label="Production Requirements"
              labelStyle={labelStyle}
              checked={productRequirements}
              onCheck={() => {
                this._onCheck("productRequirements");
              }}
            />
          )}

          {hasQtyToShip && (
            <Checkbox
              label="Packing List"
              labelStyle={labelStyle}
              checked={packingList}
              onCheck={() => {
                this._onCheck("packingList");
              }}
            />
          )}

          {isInternal && (
            <Checkbox
              label="Order Confirmation"
              labelStyle={labelStyle}
              checked={orderInformation}
              onCheck={() => {
                this._onCheck("orderInformation");
              }}
            />
          )}
        </div>
        <ChillButton
          className="btn--formCardHeader"
          name={
            isLoading ? (
              <CircularProgress
                size={24}
                thickness={4}
                color="rgba(255, 255, 255, 0.5)"
              />
            ) : (
              <span>Print</span>
            )
          }
          height={40}
          width={337}
          marginTop={32}
          icon={!isLoading && "check"}
          marginLeft={0}
          onClick={this._handleUpdate}
        />

        {!isLoading && (
          <Link
            to={""}
            onClick={onClose}
            style={{marginTop: 24}}
            className="text-cancel"
          >
            Cancel
          </Link>
        )}
      </div>
    );
  }
}
