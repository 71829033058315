import React, {Component} from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import ValidableTextField from "../../../shared/ValidableTextField";
import ValidableSelect from "../../../shared/ValidableSelect";
import CNGMenuItem from "../../../shared/CNGMenuItem";

import {formStyles} from "../../../../styles/components/formStyles";

import {debounce} from "lodash";
import {validateForm} from "../../../../utils/formValidations";

class EmbroirderForm extends Component {
  static propTypes = {
    onDetailChange: PropTypes.func.isRequired,
    handleUpdateValid: PropTypes.func.isRequired,
    details: PropTypes.object,
    submitted: PropTypes.bool.isRequired,
    applicabilities: PropTypes.array,
    embroideryTypes: PropTypes.array,
    isDisabled: PropTypes.bool,
  };
  state = {
    errors: {},
    submitted: false,
  };
  validations = {
    stitch_count: {format: "integer", length: [1, 10]},
    height: {format: "decimal", length: [1, 10]},
    width: {format: "decimal", length: [1, 10]},
    thread_colors: {format: "any", length: [1, 100]},
    applicability_id: {format: "integer", length: [1, 10]},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.submitted && !this.props.submitted) {
      this.setState({submitted: true}, () => {
        this._validate();
      });
    }
  }

  _validate() {
    const errors = validateForm(this, this.props.details);
    const submittedErrors = validateForm(
      {...this, state: {submitted: true}},
      this.props.details
    );
    const valid = !Object.keys(submittedErrors).length;
    const {handleUpdateValid} = this.props;
    this.setState({errors});
    this.props.details;
    if (!valid) return handleUpdateValid(false);
    handleUpdateValid(true);
  }

  _validateDebounced = debounce(this._validate, 300);

  _applicabilityOptions() {
    const {applicabilities} = this.props;
    return (
      applicabilities &&
      applicabilities.map(applicability => {
        return (
          <CNGMenuItem
            key={applicability.id}
            value={`${applicability.id}` || ""}
            primaryText={applicability.name}
          />
        );
      })
    );
  }

  render() {
    const {
      onDetailChange,
      isDisabled,
      details: {stitch_count, height, width, thread_colors, applicability_id},
    } = this.props;

    return (
      <div className="flex flex-wrap" style={{width: 710}}>
        <ValidableSelect
          tabIndex={5}
          name="applicability_id"
          label="Applicability"
          value={`${applicability_id}` || ""}
          errorText={this.state.errors.applicability_id}
          disabled={isDisabled}
          handleChange={(e, index, value) => {
            this._validate();
            onDetailChange(e, index, Number(value), "applicability_id");
          }}
          options={this._applicabilityOptions()}
          style={{...formStyles.HalfFieldStyles, marginRight: 16}}
          inputStyle={formStyles.HalfTextInputStyles}
          iconStyle={formStyles.HalfIconStyles}
          menuStyle={{...formStyles.MenuStyle, width: 200}}
          underlineStyle={formStyles.underlineStyle}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
        />

        <NumberFormat
          tabIndex={6}
          mask=""
          format="###K"
          customInput={ValidableTextField}
          isNumericString={true}
          name="stitch_count"
          label="Stitch Count (K)"
          errorText={this.state.errors.stitch_count}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          disabled={isDisabled}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
          value={stitch_count}
          onChange={event => {
            onDetailChange(
              event,
              null,
              event.target.value.replace(/\D/g, ""),
              "stitch_count"
            );
          }}
        />
        <ValidableTextField
          tabIndex={6}
          name="width"
          label="Width Size (inch)"
          value={width}
          maxLength={this.validations.width.length[1]}
          errorText={this.state.errors.width}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
          handleChange={onDetailChange}
          disabled={isDisabled}
          validate={() => this._validateDebounced()}
        />
        <ValidableTextField
          tabIndex={7}
          name="height"
          label="Height Size (inch)"
          value={height}
          maxLength={this.validations.height.length[1]}
          errorText={this.state.errors.height}
          style={detailsInputStyle}
          inputStyle={formStyles.TwoTextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          disabled={isDisabled}
          validate={() => this._validateDebounced()}
          underlineDisabledStyle={formStyles.underlineDisabledStyle}
        />
        <ValidableTextField
          tabIndex={9}
          name="thread_colors"
          label="Thread Colors"
          value={thread_colors}
          maxLength={this.validations.thread_colors.length[1]}
          errorText={this.state.errors.thread_colors}
          style={formStyles.TextFieldStyles}
          inputStyle={formStyles.TextInputStyles}
          floatingLabelStyle={formStyles.TextFieldLabelStyles}
          underlineStyle={formStyles.underlineStyle}
          handleChange={onDetailChange}
          validate={() => this._validateDebounced()}
        />
      </div>
    );
  }
}

const detailsInputStyle = {
  ...formStyles.HalfFieldStyles,
  marginRight: 16,
};

export default EmbroirderForm;
