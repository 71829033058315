import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import classNames from "classnames";

import CNGImage from "../../../../shared/CNGImage";
import Typography from "../../../shared/Typography";

const Product = ({product, selectedItem, isLoading, pageStyle, onChange}) => (
  <div
    className={classNames("item", {
      pointer: !isLoading,
      loading: isLoading,
    })}
    onClick={() => !isLoading && onChange({target: {}}, product)}
  >
    <div className="item-container">
      <div
        className={classNames("item-image", {
          selected:
            selectedItem.product &&
            _get(selectedItem, "product.id") === product.id,
        })}
      >
        <CNGImage
          src={_get(
            product,
            "productImages[0].imageUrlThumbnail",
            product.imageUrl
          )}
          title={product.shortDescription}
          style={pageStyle.image}
        />
      </div>
    </div>
    <Typography variant="body" style={pageStyle.name}>
      {_get(product, "itemNumber")}
    </Typography>
    <Typography variant="h5" style={pageStyle.name}>
      {_get(product, "productName")}
    </Typography>
  </div>
);

Product.propTypes = {
  selectedItem: PropTypes.object,
  onChange: PropTypes.func,
  product: PropTypes.object,
  isLoading: PropTypes.bool,
  pageStyle: PropTypes.object,
};

export default Product;
