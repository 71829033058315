import lodash from "lodash";
import React from "react";
import PropTypes from "prop-types";

const _getArtDetails = artRevision => {
  const styles = {
    separator: {
      marginLeft: "10px",
      marginRight: "10px",
      fontWeight: "bold",
    },
    description: {
      marginRight: "5px",
    },
  };
  if (!artRevision) return;

  const {adornmentDetails} = artRevision;
  const internalIdentifier = lodash.get(
    artRevision,
    "adornmentType.internalIdentifier"
  );

  if (!internalIdentifier) return;

  switch (internalIdentifier) {
  case "embroidery_large_format":
  case "embroidery":
    return (
      <div className="flex flex-wrap flex-row align-items-center">
        <span>
          <small style={styles.description}>Stitches </small>
          {`${adornmentDetails.stitchCount}K`}
        </span>
        <span style={styles.separator}>|</span>
        <span>
          <small style={styles.description}>Dimensions </small>
          {`${adornmentDetails.width}" x ${adornmentDetails.height}"`}
        </span>
        <span style={styles.separator}>|</span>
        <span>
          <small style={styles.description}>Thread Colors </small>
          <span
            className="overflow-ellipsis"
            style={{
              width: 400,
              display: "inline-block",
              verticalAlign: "top",
            }}
            title={adornmentDetails.threadColors}
          >
            {adornmentDetails.threadColors}
          </span>
        </span>
      </div>
    );
  case "etch_and_dye":
    return (
      <div className="flex flex-wrap flex-row align-items-center">
        <React.Fragment>
          <span>
            <small style={styles.description}>Dye Color </small>
            {`${adornmentDetails.dyeColor}`}
          </span>
          <span style={styles.separator}>|</span>
        </React.Fragment>
        <span>
          <small style={styles.description}>Dimensions </small>
          {`${adornmentDetails.width}" x ${adornmentDetails.height}"`}
        </span>
      </div>
    );
  case "etching":
  case "name_drop_or_monogram":
    return (
      <div className="flex flex-wrap flex-row align-items-center">
        <span>
          <small style={styles.description}>Dimensions </small>
          {`${adornmentDetails.width}" x ${adornmentDetails.height}"`}
        </span>
      </div>
    );
  case "patch":
    return (
      <div className="flex flex-wrap flex-row align-items-center">
        <span>
          <small style={styles.description}>Shape </small>
          {`${adornmentDetails.shapeDescription}`}
        </span>
        <span style={styles.separator}>|</span>
        <span>
          <small style={styles.description}>Dimensions </small>
          {`${adornmentDetails.width}" x ${adornmentDetails.height}"`}
        </span>
      </div>
    );
  default:
    return null;
  }
};

const ArtRevisionDetails = art => {
  const {artRevision} = art || {};
  return <span>{_getArtDetails(artRevision)}</span>;
};

ArtRevisionDetails.propTypes = {
  artRevision: PropTypes.object,
};

export default ArtRevisionDetails;
