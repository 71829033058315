import React, {Component} from "react";
import PropTypes from "prop-types";
import Add from "material-ui/svg-icons/content/add";
import IconMenu from "material-ui/IconMenu";

import {UserNavLink} from "./UserNavLink";
import ChillNavLink from "./ChillNavLink";
import MenuHeaderOption from "./MenuHeaderOption";
import CNGMenuItem from "../../shared/CNGMenuItem";
import {Colors} from "../../../styles/theme/Colors";

class HeaderUserMenu extends Component {
  state = {
    menuOpen: false,
    menuOpenOrdersOpen: false,
  };

  handleOnRequestChange = (menuName, value) => {
    this.setState({[menuName]: value});
  };

  activeStyle(path) {
    const {history: {location: {pathname}}} = this.props;
    return pathname.match(new RegExp(path))
      ? {
        background: Colors.activeColor,
      }
      : {};
  }

  _companiesReorderMenuItems() {
    const {companies} = this.props;
    return (
      companies &&
      companies.map((company, index) => (
        <CNGMenuItem
          innerDivStyle={DSODstyle.menuItem.innerDiv}
          primaryText={company.name}
          onClick={() =>
            this.props.history.push(`/companies/${company.id}/reorders`)
          }
          key={index}
        />
      ))
    );
  }

  _internalOrderSection() {
    const {isCreateOrderButtonVisible, companies} = this.props;

    return (
      <IconMenu
        animated={false}
        width="70vw"
        autoWidth={false}
        style={DSODstyle}
        listStyle={DSODstyle.list}
        menuStyle={DSODstyle.menu}
        menuItemStyle={DSODstyle.menuItem}
        open={this.state.menuOpenOrdersOpen}
        id="order-cart-menu-item"
        onRequestChange={value => {
          return companies.length === 0
            ? this.props.history.push("/orders/new")
            : this.handleOnRequestChange("menuOpenOrdersOpen", value);
        }}
        iconButtonElement={
          <MenuHeaderOption
            materialIcon
            icon="shopping_cart"
            isActive={this.state.menuOpenOrdersOpen}
            style={this.activeStyle("reorders|quotes")}
          />
        }
        anchorOrigin={{
          horizontal: "middle",
          vertical: "bottom",
        }}
        targetOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        {this._companiesReorderMenuItems()}

        {isCreateOrderButtonVisible ? (
          <CNGMenuItem
            onClick={() => this.props.history.push("/orders/new")}
            innerDivStyle={DSODstyle.menuItem.innerDiv}
            style={{
              fontWeight: "bold",
              fontSize: 12,
              textTransform: "uppercase",
            }}
            primaryText={
              <div className="flex flex-center justify-content-center">
                <Add
                  color="white"
                  style={{
                    width: 18,
                  }}
                />
                <span>Create Order from scratch</span>
              </div>
            }
          />
        ) : null}
      </IconMenu>
    );
  }

  _externalOrderSection() {
    const {selectedCompany} = this.props;
    return (
      <ChillNavLink
        exact
        materialIcon
        icon="shopping_cart"
        title="Reorder"
        to={`/companies/${selectedCompany}/reorders`}
      />
    );
  }

  _internalDSODSection() {
    const {companiesWithDsod} = this.props;
    const isActive = this._isDSODMenuActive();
    return (
      <IconMenu
        animated={false}
        width="70vw"
        autoWidth={false}
        style={DSODstyle}
        listStyle={DSODstyle.list}
        menuStyle={DSODstyle.menu}
        menuItemStyle={DSODstyle.menuItem}
        open={this.state.menuOpen}
        onRequestChange={value => this.handleOnRequestChange("menuOpen", value)}
        iconButtonElement={
          <MenuHeaderOption
            materialIcon
            icon="local_shipping"
            style={{
              background: isActive && "rgb(29, 154, 187)",
            }}
            isActive={this.state.menuOpen}
          />
        }
        anchorOrigin={{
          horizontal: "middle",
          vertical: "bottom",
        }}
        targetOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        {companiesWithDsod &&
          companiesWithDsod.map(company => (
            <CNGMenuItem
              innerDivStyle={DSODstyle.menuItem.innerDiv}
              primaryText={company.name}
              onClick={() =>
                this.props.history.push(`/companies/${company.id}/dsod`)
              }
              key={company.id}
            />
          ))}
        {this.props.isCreateDSODButtonVisible ? (
          <CNGMenuItem
            onClick={() => this.props.history.push("/dsod/new")}
            innerDivStyle={DSODstyle.menuItem.innerDiv}
            style={{
              fontWeight: "bold",
              fontSize: 12,
              textTransform: "uppercase",
            }}
            primaryText={
              <div className="flex flex-center justify-content-center">
                <Add
                  color="white"
                  style={{
                    width: 18,
                  }}
                />
                <span>Create DSOD from scratch</span>
              </div>
            }
          />
        ) : null}
      </IconMenu>
    );
  }

  _externalDSODSection() {
    const {selectedCompany} = this.props;
    return (
      <ChillNavLink
        exact
        materialIcon
        icon="local_shipping"
        title="DSOD"
        to={`/companies/${selectedCompany}/dsod`}
      />
    );
  }

  _isDSODMenuActive = () => {
    const {history: {location: {pathname}}} = this.props;

    if (pathname.match(/\/companies\/(\d+)\/dsod$/)) {
      return true;
    }
    return false;
  };

  _isReorderActive = () => {
    const {history: {location: {pathname}}} = this.props;

    if (pathname.match(/\/companies\/(\d+)\/reorders/)) {
      return true;
    }
    return false;
  };

  _isCompanyMenuActive = (match, location) => {
    const {pathname} = location;
    if (this._isDSODMenuActive() || this._isReorderActive()) return false;
    if (
      pathname.match(/\/companies(.*)$/) ||
      pathname.match(/\/brands(\/\d+)?(\/(.*)\/\d+)?(\/(.*))?$/) ||
      pathname.match(/\/contacts(\/\d+)?(\/(.*)\/\d+)?(\/(.*))?$/)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {isInternal, showBadge, badgeNumber} = this.props;
    return (
      <nav className="header_nav">
        <ChillNavLink
          exact
          materialIcon
          title="Dashboard"
          to="/"
          icon="dashboard"
          showBadge={showBadge}
          badgeNumber={badgeNumber}
        />{" "}
        {isInternal ? <UserNavLink /> : null}
        <ChillNavLink
          materialIcon
          title={isInternal ? "Companies" : "Company"}
          isActiveHandler={this._isCompanyMenuActive}
          to="/companies"
          icon="home"
        />{" "}
        {isInternal ? this._internalDSODSection() : this._externalDSODSection()}
        {isInternal
          ? this._internalOrderSection()
          : this._externalOrderSection()}
      </nav>
    );
  }
}

const DSODstyle = {
  paddingBottom: 5,
  marginTop: 5,
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
    background: "#08384D",
  },
  menuItem: {
    minHeight: 40,
    lineHeight: "40px",
    borderBottom: "1px solid #073143",
    color: "white",
    innerDiv: {
      padding: 0,
      height: 40,
    },
  },
};

HeaderUserMenu.propTypes = {
  user: PropTypes.string.isRequired,
  isInternal: PropTypes.bool,
  companies: PropTypes.array,
  companiesWithDsod: PropTypes.array,
  history: PropTypes.object,
  selectedCompany: PropTypes.string,
  isCreateDSODButtonVisible: PropTypes.bool,
  isCreateOrderButtonVisible: PropTypes.bool,
  showBadge: PropTypes.bool,
  badgeNumber: PropTypes.number,
};

export default HeaderUserMenu;
