import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QuoteSummary from "./QuoteSummary";
import {
  fetchQuote,
  fetchQuoteItemPriceTable,
  fetchQuoteItemPrice,
  updateQuote,
  deleteQuote,
} from "../../../../../actions/v2/quoteActions";
import {openNotification} from "../../../../../actions/notificationActions";
import {checkAuth, logout} from "../../../../../actions/authActions";
import {fetchArtRevisionsByCompany} from "../../../../../actions/artActions";
import QuoteStorageService from "../../../../../services/QuoteStorageService";

function mapStateToProps(state) {
  const {
    quotes: {quote, itemPrices, isLoadingPrices},
    auth: {isAuthenticated, user, isInternal},
    art: {artRevisions},
  } = state;

  return {
    quote,
    isAuthenticated,
    user,
    isInternal,
    itemPrices,
    isLoadingPrices,
    companyArts: artRevisions,
    store: new QuoteStorageService(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuote: bindActionCreators(fetchQuote, dispatch),
    fetchItemPriceTable: bindActionCreators(fetchQuoteItemPriceTable, dispatch),
    fetchItemPrice: bindActionCreators(fetchQuoteItemPrice, dispatch),
    updateQuote: bindActionCreators(updateQuote, dispatch),
    deleteQuote: bindActionCreators(deleteQuote, dispatch),
    openNotification: bindActionCreators(openNotification, dispatch),
    fetchArtRevisionsByCompany: bindActionCreators(
      fetchArtRevisionsByCompany,
      dispatch
    ),
    checkAuth: bindActionCreators(checkAuth, dispatch),
    logout: bindActionCreators(logout, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteSummary)
);
