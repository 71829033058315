import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import classNames from "classnames";
import Typography from "../../../shared/Typography";
import CNGImage from "../../../../shared/CNGImage";

const ColorSwatch = ({color, isLoading, onChange, item, pageStyle}) => {
  const fabricAvailableColors = _get(color, "fabricAvailableColor", []);
  const fabricAvailableColor = fabricAvailableColors.find(
    ({fabricId}) => `${fabricId}` === `${_get(item, "fabric.id")}`
  );
  const url = fabricAvailableColor
    ? _get(
      fabricAvailableColor,
      "imageUrlThumbnail",
      fabricAvailableColor.imageUrl
    )
    : _get(color, "imageUrlThumbnail", color.imageUrl);
  return (
    <div
      className={classNames("item color", {
        pointer: !isLoading,
        loading: isLoading,
      })}
      onClick={() => !isLoading && onChange({target: {}}, color)}
    >
      <div className="item-container">
        <div
          className={classNames("item-image color", {
            selected: item.color && item.color.id === color.id,
          })}
        >
          <CNGImage src={url} style={pageStyle.image} />
        </div>
      </div>
      <Typography variant="h5" style={pageStyle.name}>
        {_get(color, "name")}
      </Typography>
    </div>
  );
};

ColorSwatch.propTypes = {
  color: PropTypes.object,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  item: PropTypes.object,
  pageStyle: PropTypes.object,
};

export default ColorSwatch;
