import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import CompanyForm from "./Components/CompanyForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";
import Loader from "../../shared/Loader";

export default class CompaniesEditPage extends Component {
  static propTypes = {
    router: PropTypes.object,
    countries: PropTypes.array,
    companies: PropTypes.array,
    updateCompany: PropTypes.func.isRequired,
    updateCompanyContact: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    fetchDiscounted: PropTypes.func,
    discounts: PropTypes.array,
    businesses: PropTypes.array,
    paymentTerms: PropTypes.array,
    company: PropTypes.object,
    primary_user_id: PropTypes.number,
    openNotification: PropTypes.func.isRequired,
    closeNotification: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    errorMessage: PropTypes.string,
  };

  state = {
    company_name: "",
    discount_category_id: null,
    business_category_id: null,
    payment_terms_id: null,
    send_primary_user_notification: null,
    resale_number: null,
    primary_user_id: null,
    company_id: null,
    submitted: false,
  };

  componentWillReceiveProps(nextProps) {
    this._setPrimaryContact(nextProps);
  }

  _setPrimaryContact(nextProps) {
    if (!nextProps.company) {
      return;
    }
    const {company} = nextProps;
    const userAssociation = company.userAssociations.find(
      ({isPrimary}) => isPrimary
    );

    this.setState({
      company_id: company.id,
      company_name: company.name,
      discount_category_id: company.discountCategoryId,
      business_category_id: company.businessCategoryId,
      payment_term_id: company.paymentTermId,
      resale_number: company.resaleNumber,
      primary_user_id: userAssociation && userAssociation.userId,
      send_primary_user_notification: company.sendPrimaryUserNotification,
    });
  }

  async updateCompany() {
    const {openNotification, navigateTo} = this.props;
    try {
      const company = {
        id: this.state.company_id,
        name: this.state.company_name,
        discount_category_id: this.state.discount_category_id,
        business_category_id: this.state.business_category_id,
        payment_term_id: this.state.payment_term_id,
        resale_number: this.state.resale_number,
        primary_user_id: this.state.primary_user_id,
        send_primary_user_notification: this.state
          .send_primary_user_notification,
      };
      this.props.updateCompany(company);
      navigateTo(`/companies/${this.state.company_id}`);
    } catch (error) {
      openNotification("Update company request failed");
    }
  }

  handleChange(e, index, value, formName) {
    if (!value) {
      value = index;
    }
    let name;
    if (!formName) {
      name = e.target.name;
    } else {
      name = formName;
    }
    this.setState({[name]: value});
  }

  render() {
    const {
      company,
      discounts,
      businesses,
      paymentTerms,
      primary_user_id,
      closeNotification,
      notification,
      isLoading,
      router,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company ? company.name : ""}
        closeNotification={closeNotification}
        notification={notification}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={"Companies"}
          pageName={company ? company.name : ""}
        />
        <Card>
          {isLoading ? (
            <Loader height={583} />
          ) : (
            <div>
              {company ? (
                <FormCardHeader
                  title="Edit Company"
                  cancelLink={`/companies/${company.id}${
                    router.previousSearch
                  }`}
                  buttonText="SAVE"
                  buttonIcon="check"
                  buttonClick={() => this.setState({submitted: true})}
                />
              ) : null}
              <Divider marginBottom={24} marginTop={24} />
              <CompanyForm
                handleChange={(e, index, value, formName) =>
                  this.handleChange(e, index, value, formName)
                }
                company={company}
                discount_category_id={this.state.discount_category_id}
                business_category_id={this.state.business_category_id}
                payment_term_id={this.state.payment_term_id}
                discounts={discounts}
                businesses={businesses}
                paymentTerms={paymentTerms}
                primary_user_id={primary_user_id}
                send_primary_user_notification={
                  this.state.send_primary_user_notification
                }
                isEdit={true}
                submitSuccess={() => {
                  this.setState({submitted: false}, () => this.updateCompany());
                }}
                submitError={() => this.setState({submitted: false})}
                {...this.state}
              />
            </div>
          )}
        </Card>
      </PageLayout>
    );
  }
}
