import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import {printOrderDocuments} from "../../../actions/orderActions";

class OrderPrint extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    url: null,
    focusCount: 0,
  };

  closeWindow = () => {
    window.close();
  };
  componentDidMount() {
    window.closeSplash();
    this.fetchPDFFile();
  }

  fetchPDFFile = () => {
    const {match: {params: {company_id, id}}, location} = this.props;
    printOrderDocuments(
      company_id,
      id,
      queryString.parse(location.search)
    ).then(orderURL => {
      this.setState({url: orderURL});
    });
  };

  handlePDFOnLoad = event => {
    const {url} = this.state;
    const frame = event.target;
    if (!url) return;

    setTimeout(function() {
      frame.contentWindow.print();
      frame.parentElement.focus();
    }, 0);
  };

  handleFocus = () => {
    const {focusCount} = this.state;
    this.setState({focusCount: focusCount + 1});
    if (focusCount === 1) {
      this.closeWindow();
    }
  };

  render() {
    const {url} = this.state;

    return (
      <div>
        {!url && (
          <section className="print-icon">
            <div className="icon-container">
              <FontIcon
                className="material-icons"
                style={{
                  fontSize: 85,
                  color: "#a7a7a7de",
                }}
              >
                local_printshop
              </FontIcon>
            </div>
            <span style={{fontSize: 40}}>Printing...</span>
          </section>
        )}
        <object
          data={url}
          type="application/pdf"
          height="100%"
          id="pdf"
          onFocus={this.handleFocus}
        >
          <iframe src={url} id="frame" onLoad={this.handlePDFOnLoad} />
        </object>
      </div>
    );
  }
}

export default withRouter(OrderPrint);
