import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import CompanyForm from "./Components/CompanyForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";

import {cloneDeep} from "lodash";

class CompaniesCreatePage extends Component {
  state = {
    user_first_name: "",
    user_last_name: "",
    phone_number: "",
    email_address: "",
    address_1: "",
    address_2: "",
    city: "",
    state: null,
    zip_code: null,
    country_id: null,
    company_name: "",
    send_primary_user_notification: null,
    discount_category_id: null,
    business_category_id: null,
    payment_term_id: null,
    resale_number: null,
    submitted: false,
    fromQuote: false,
    quoteId: null
  };

  componentWillReceiveProps() {
    this._setDefaultCountry(...arguments);
    this._evaluatePageState(...arguments);
    this._loadDataFromLocationState(...arguments);
  }

  _setDefaultCountry(nextProps) {
    const {countries} = nextProps;
    const {countryId: currentCountryId} = this.state;
    if (currentCountryId) {
      return;
    }
    const defaultCountry =
      countries.length > 0
        ? countries.find(country => ["USA", "US"].includes(country.code))
        : null;
    if (defaultCountry) {
      this.setState({
        country_id: Number(defaultCountry.id),
      });
    }
  }

  _evaluatePageState(nextProps) {
    const {
      isLoading: nextIsLoading,
      company: nextCompany,
      location: nextLocation,
    } = nextProps;
    const {isLoading, company, navigateTo} = this.props;

    if (!nextIsLoading && isLoading && nextCompany && !company) {
      if (nextLocation.state) {
        return navigateTo(`/quotes/info/${nextLocation.state.id}`);
      }
      navigateTo(`/companies/${nextCompany.id}`);
    }
  }

  _loadDataFromLocationState(nextProps) {
    const {state} = nextProps.location;
    if (state) {
      this.setState({
        company_name: state.companyName,
        user_first_name: state.name,
        user_last_name: state.lastName,
        email_address: state.emailAddress,
        address_1: state.address1,
        address_2: state.address2,
        city: state.city,
        state: state.state,
        zip_code: state.zipCode,
        country_id: state.countryId,
        phone_number: state.phoneNumber,
        fromQuote: true,
        quoteId: state.quoteId,
      });
    }
  }

  async createCompany() {
    const {openNotification, createCompany } = this.props;
    try {
      const {fromQuote, ...state} = this.state;
      let quoteId;
      if (fromQuote) {
        quoteId = state.quoteId;
      }
      const sourceData = cloneDeep(state);
      const companyContact = {
        company_name: sourceData.company_name,
        first_name: sourceData.user_first_name,
        last_name: sourceData.user_last_name,
        phone_number: sourceData.phone_number,
        email_address: sourceData.email_address,
        address_1: sourceData.address_1,
        address_2: sourceData.address_2,
        city: sourceData.city,
        state: sourceData.state,
        zip_code: sourceData.zip_code,
        country_id: Number(sourceData.country_id),
        is_primary: true,
      };
      const company = {
        name: sourceData.company_name,
        discount_category_id: sourceData.discount_category_id,
        business_category_id: sourceData.business_category_id,
        payment_term_id: sourceData.payment_term_id,
        resale_number: sourceData.resale_number,
        send_primary_user_notification:
          sourceData.send_primary_user_notification,
        companyContact,
      };
      const companyCreated = await createCompany(company, {fromQuote, quoteId});
      if (!companyCreated) {
        this.setState({submitted: false});
      }
    } catch (error) {
      openNotification("Create company with primary contact request failed");
    }
  }

  handleChange(event, index, value, formName) {
    if (!value && formName !== "state") {
      value = index;
    }
    let name;
    if (!formName) {
      name = event.target.name;
    } else {
      name = formName;
    }
    this.setState({[name]: value});
  }

  render() {
    const {
      countries,
      discounts,
      businesses,
      paymentTerms,
      closeNotification,
      notification,
      isLoading,
    } = this.props;
    const {fromQuote, ...state} = this.state;
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
        closeNotification={closeNotification}
        notification={notification}
      >
        <BreadCrumbs rootName={"Companies"} pageName="Create New" />
        <Card>
          <div>
            <FormCardHeader
              title="Create New Company"
              cancelLink="/companies"
              buttonText="ADD COMPANY"
              buttonIcon="check"
              hideCancel={fromQuote}
              buttonClick={() => this.setState({submitted: true})}
            />
            <Divider marginBottom={24} marginTop={24} />
            <CompanyForm
              handleChange={(e, index, value, formName) =>
                this.handleChange(e, index, value, formName)
              }
              formSubmit={() => this.createCompany()}
              countries={countries}
              discounts={discounts}
              businesses={businesses}
              paymentTerms={paymentTerms}
              submitSuccess={() => this.createCompany()}
              submitError={() => this.setState({submitted: false})}
              {...state}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}

CompaniesCreatePage.propTypes = {
  countries: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  createCompany: PropTypes.func.isRequired,
  createCompanyContact: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  fetchDiscounted: PropTypes.func,
  discounts: PropTypes.array,
  businesses: PropTypes.array,
  paymentTerms: PropTypes.array,
  openNotification: PropTypes.func.isRequired,
  closeNotification: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isLoadingCompanyContactRequest: PropTypes.bool,
  company: PropTypes.object,
  location: PropTypes.object,
};

export default CompaniesCreatePage;
