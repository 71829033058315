import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import Divider from "material-ui/Divider";

import Typography from "../../shared/Typography";

const pageStyle = {
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
};

const QuoteFooter = ({style, className}) => (
  <div
    className={classNames("flex flex-column padding-top-40", className)}
    style={style}
  >
    <Typography variant="bodyMedium">
      If you have any questions we&apos;re here to help. Please email (
      <Typography
        component="a"
        variant="link"
        color="secondary.light"
        href="mailto:info@chill-n-go.com"
      >
        info@chill-n-go.com
      </Typography>
      ) or call{" "}
      <Typography
        component="a"
        variant="link"
        color="secondary.light"
        href="tel:+19166310102"
      >
        (916) 631-0102
      </Typography>
      .
    </Typography>
    <Divider className="no-border" style={pageStyle.divider} />
    <Typography variant="subtitle">
      Copyright {moment().format("YYYY")} by chill-go, Inc
    </Typography>
  </div>
);

QuoteFooter.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default QuoteFooter;
