import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchBusinessRequest = () =>
  makeCommonAction(types.FETCH_BUSINESSES_REQUEST);
const fetchBusinessSuccess = payload =>
  makeCommonAction(types.FETCH_BUSINESSES_SUCCESS, payload);
const fetchBusinessError = error =>
  makeCommonAction(types.FETCH_BUSINESSES_ERROR, error, true);

export function fetchBusinesses() {
  return makeActionCreator({
    endpoint: "/businesscategories",
    fetchOptions: {method: "GET"},
    requestFunc: fetchBusinessRequest,
    successFunc: fetchBusinessSuccess,
    errorFunc: fetchBusinessError,
    error_message: "Error fetching Business cateogories",
  });
}
