import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import IconMenu from "material-ui/IconMenu";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import FormattedAddress from "../../../../shared/FormattedAddress";
import {Link} from "react-router-dom";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import FormattedPhone from "../../../../shared/FormattedPhone";
import ContentLoader from "../../../../shared/ContentLoader";

export default class WalletTable extends Component {
  static propTypes = {
    company: PropTypes.object,
    walletMembers: PropTypes.array,
    updateWalletMember: PropTypes.func.isRequired,
    fetchWalletMembers: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  state = {
    openArchiveModal: false,
    newDefaultWalletMember: null,
  };

  async makeDefaultWalletUser(walletMember) {
    const {updateWalletMember, fetchWalletMembers} = this.props;
    if (walletMember) {
      const params = {
        isWalletDefaultMember: true,
      };
      await updateWalletMember(
        walletMember.companyId,
        parseInt(walletMember.id),
        params,
        () => {
          fetchWalletMembers(walletMember.companyId);
        }
      );
    }
  }
  async removeWalletUser(walletMember) {
    const {updateWalletMember, fetchWalletMembers} = this.props;
    if (walletMember) {
      const params = {
        isWalletMember: false,
        isWalletDefaultMember: false,
      };
      await updateWalletMember(
        walletMember.companyId,
        walletMember.id,
        params,
        () => {
          fetchWalletMembers(walletMember.companyId);
        },
        "Wallet member removed successfully"
      );
    }
  }
  _renderRow(walletMember, isDefault, index) {
    const {companyContact} = walletMember;
    const {company, walletMembers} = this.props;
    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn highlight={true}>
          <Link
            to={`/companies/${company &&
              company.id}/contacts/${companyContact && companyContact.id}`}
            className="no-link-styles"
          >
            {companyContact.name}
            <div
              className="subtext"
              style={{fontSize: 12}}
              title={
                lodash.isEmpty(lodash.get(companyContact, "userId"))
                  ? lodash.get(company, "name")
                  : lodash.get(companyContact, "companyName")
              }
            >
              {lodash.isEmpty(lodash.get(companyContact, "userId"))
                ? lodash.get(companyContact, "companyName")
                : lodash.get(company, "name")}
            </div>
          </Link>
        </CNGTableRowColumn>
        <CNGTableRowColumn customStyles={{width: 250}}>
          <FormattedAddress user={companyContact || {}} />
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          <FormattedPhone
            phoneNumber={companyContact.phoneNumber}
            countryCode={
              companyContact.country
                ? companyContact.country.code
                : companyContact.countryCode
            }
          />
        </CNGTableRowColumn>
        <CNGTableRowColumn title={companyContact.emailAddress}>
          {companyContact.emailAddress}
        </CNGTableRowColumn>
        <CNGTableRowColumn>{isDefault ? "Yes" : ""}</CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: 100,
          }}
        >
          {walletMembers.length > 1 ||
          (!isDefault && walletMembers.length === 1) ? (
              <IconMenu
                iconButtonElement={
                  <IconButton>
                    <MoreVertIcon color="#0C5371" />
                  </IconButton>
                }
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                targetOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                {!isDefault ? (
                  <CNGMenuItem
                    primaryText="Make Default"
                    onClick={() => this.makeDefaultWalletUser(walletMember)}
                  />
                ) : null}
                {walletMembers.length > 1 ? (
                  <CNGMenuItem
                    primaryText="Remove"
                    onClick={() => this.removeWalletUser(walletMember)}
                  />
                ) : null}
              </IconMenu>
            ) : null}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  _renderEmptyState() {
    const {isLoading} = this.props;
    return (
      <ContentLoader isLoading={isLoading}>
        <h2 className="userindex_noUsers">No contacts yet.</h2>
      </ContentLoader>
    );
  }

  render() {
    const header = [
      "NAME & COMPANY",
      {
        columnContent: "ADDRESS",
        customStyles: {
          width: 250,
        },
      },
      "PHONE",
      "EMAIL ADDRESS",
      "Default",
      "",
    ];

    const {isLoading, walletMembers} = this.props;
    if (walletMembers && walletMembers.length === 0) {
      return this._renderEmptyState();
    }

    const rows =
      (walletMembers &&
        walletMembers.map((walletMember, index) => {
          return this._renderRow(
            walletMember,
            walletMember.isWalletDefaultMember,
            index
          );
        })) ||
      [];
    return (
      <CNGTable
        isLoading={isLoading}
        className="wallet-table CNG-table--withActions"
        tableHeader={header}
        displayRowCheckbox={false}
      >
        {rows}
      </CNGTable>
    );
  }
}
