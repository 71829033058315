import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {Link} from "react-router-dom";
import Checkbox from "material-ui/Checkbox";
import {Card, CardHeader, CardText} from "material-ui/Card";
import FontIcon from "material-ui/FontIcon";

import Divider from "../../../shared/Divider";
import OrderShipmentTable from "./OrderShipmentTable";
import DSODTable from "./DSODTable";
import OrderItemArtDetails from "../../../shared/OrderItemArtDetails";
import CNGToolTip from "../../../shared/CNGToolTip";
import EditOrderItemModal from "../../../shared/EditOrderItemModal";

import {tableRowStyle} from "../../../../styles/components/tableStyles";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";

import {INACTIVE_ART_TITLE} from "../../../../constants/art";
export const getArtTitle = revision => {
  if (!revision) return "";

  if (_get(revision, "active")) return _get(revision, "name");
  return INACTIVE_ART_TITLE;
};

const cardHeader = {
  ...tableRowStyle,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 48,
  paddingLeft: 10,
};

const cardColumns = {
  paddingRight: 24,
  color: "#747D86",
  fontSize: 14,
  lineHeight: "20px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const smallColumn = {
  ...cardColumns,
  width: 119,
};

const tbrcLinkStyles = {
  cursor: "pointer",
};

const orderTotalStyles = {
  color: "#1D9ABB",
  fontWeight: "bold",
};

class ProductOrderItem extends Component {
  state = {
    openEditOrderItemModal: false,
  };

  getSubTitleText = (fabricColor, leatherColor) => {
    const fabricName = _get(fabricColor, "fabric.name");
    const colorName = _get(fabricColor, "color.name");
    const leatherColorName = _get(leatherColor, "color.name", "");

    if (fabricName === "None") return null;
    let subtitle = `${fabricName} ${colorName}`;
    if (leatherColorName) subtitle += `, ${leatherColorName}`;
    return subtitle;
  };

  handleOpenEditOrderItemModal = () => {
    this.setState({openEditOrderItemModal: true});
  };

  handleCloseEditOrderItemModal = () => {
    this.setState({openEditOrderItemModal: false});
  };

  render() {
    const {
      isReordering,
      reorderItems,
      onToggleReorderItem,
      shipments,
      companyId,
      inventoryItems,
      orderItem,
      index,
      isEditingOrder,
    } = this.props;

    const shipment = shipments && shipments[0];

    const {
      unitCostValue,
      unitAdornmentCostValue,
      orderTotal,
      netUnitCost,
      quantityDiscountValue,
      id,
      product,
      orderItemProduct: {art, id: orderItemProductId},
      fabricColor,
      leatherColor,
      orderId,
    } = orderItem;

    const inventoryItem = (inventoryItems || []).find(
      inventoryItem =>
        Number(inventoryItem.orderItemId) === Number(orderItem.id)
    );

    const items =
      (shipment && shipment.items.filter(item => item.orderItemId == id)) || [];
    const linkStyles = shipment && shipment.isMultiple ? tbrcLinkStyles : {};

    const {artSource} = art || {};
    const {brand} = artSource || {};

    const assignment = _get(
      orderItem,
      "orderItemProduct.orderItemProductAdornmentLocationArtAssignments[0]"
    );

    const imageInstance =
      assignment && assignment.filename
        ? assignment
        : _get(product, "productImages[0]");

    const location = _get(
      orderItem,
      "orderItemProduct.orderItemProductAdornmentLocationArtAssignments[0].productAdornmentLocation.adornmentLocation.location",
      ""
    );
    const subtitleText = this.getSubTitleText(fabricColor, leatherColor);
    const renderRow = (
      <div
        style={{
          ...cardHeader,
          paddingLeft: 0,
          height: 80,
        }}
        key={index}
        className="order-item-row"
      >
        <div
          style={{
            ...linkStyles,
            ...cardColumns,
            width: 175,
            display: "flex",
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
            paddingRight: 0,
          }}
        >
          {isReordering ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {orderItem.product.isDeleted ? (
                <div pr={20} style={{fontSize: 12, cursor: "default"}}>
                  Archived
                </div>
              ) : (
                <Checkbox
                  style={{
                    width: "unset",
                  }}
                  checked={
                    !!reorderItems.find(item => item.id === orderItem.id)
                  }
                  onCheck={() => {
                    onToggleReorderItem(orderItem);
                  }}
                />
              )}
            </div>
          ) : null}

          {isEditingOrder ? (
            <FontIcon
              className="pointer material-icons"
              onClick={this.handleOpenEditOrderItemModal}
              style={{
                position: "absolute",
                fontSize: 14,
                lineHeight: "24px",
                color: "#0C5271",
                top: 14,
                left: -16,
              }}
            >
              edit
            </FontIcon>
          ) : null}
          <div className="overflow-ellipsis">
            <CNGToolTip
              parentRef={`order-item-${assignment && assignment.id}`}
              style={{width: 250}}
              element={imageInstance}
            >
              <Link
                to={`/companies/${companyId}/orders/${orderId}/products/${orderItemProductId}`}
                className="reset-link-styles"
                title={_get(product, "productName")}
              >
                {_get(product, "productName")}
              </Link>
            </CNGToolTip>
            <div className="table-subtext">
              Item #{_get(product, "itemNumber")}
            </div>
            <div className="table-subtext" title={subtitleText}>
              {subtitleText}
            </div>
          </div>
        </div>
        <div
          style={{
            ...cardColumns,
            width: 95,
            color: "#0C5271",
            fontWeight: "bold",
            lineHeight: "24px",
            paddingRight: 0,
            paddingLeft: 10,
          }}
        >
          <OrderItemArtDetails orderItem={orderItem}>
            <Link
              title={getArtTitle(art)}
              to={`/brands/${brand && brand.id}/arts/${_get(
                artSource,
                "id"
              )}/revisions/${_get(art, "id")}`}
              style={_get(art, "active") ? {} : {color: "red"}}
            >
              {_get(art, "name")}
            </Link>
            <div
              className="table-subtext"
              style={_get(art, "active") ? {} : {color: "red"}}
            >
              {location}
            </div>
          </OrderItemArtDetails>
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 85,
          }}
        >
          {orderItem.quantityToShip}
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 110,
          }}
        >
          {orderItem.quantityToInventory}
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 75,
          }}
        >
          {orderItem.orderQuantity}
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 105,
          }}
        >
          {getCurrencyFormattedNumber(unitCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            paddingRight: 0,
            width: 90,
          }}
        >
          ({getCurrencyFormattedNumber(quantityDiscountValue)})
        </div>
        <div
          style={{
            ...smallColumn,
            paddingRight: 0,
            width: 100,
          }}
        >
          {getCurrencyFormattedNumber(unitAdornmentCostValue, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            paddingRight: 0,
            width: 104,
          }}
        >
          {getCurrencyFormattedNumber(netUnitCost, true)}
        </div>
        <div
          style={{
            ...cardColumns,
            ...orderTotalStyles,
            color: "#747D86",
          }}
        >
          {getCurrencyFormattedNumber(orderTotal, true)}
        </div>
      </div>
    );

    return this._renderCard(
      renderRow,
      index,
      shipment,
      items,
      inventoryItem,
      orderItem
    );
  }

  _renderCard = (
    renderRow,
    index,
    shipment,
    items,
    inventoryItem,
    orderItem
  ) => {
    const {
      isInternal,
      companyId,
      onUpdateShipmentItem,
      updateShipment,
      states,
      countries,
      onUpdateOrderItem,
    } = this.props;
    const {openEditOrderItemModal} = this.state;
    const isMultipleShipmentsOrder = _get(shipment, "isMultiple");
    const hasShipments =
      isMultipleShipmentsOrder && items.length > 0
        ? true
        : shipment && !!shipment.recipientId;
    const inventoryHistories = _get(inventoryItem, "inventoryHistories") || [];

    const hasInventoryHistories =
      inventoryHistories.filter(
        inventoryHistory => inventoryHistory.sourceType === "dsod"
      ).length > 0;
    const showExpandable = hasShipments || hasInventoryHistories;
    return (
      <Card
        style={{
          marginBottom: 8,
          boxShadow: "none",
        }}
        containerStyle={{
          margin: "0px 32px",
        }}
        className="cardtest"
        key={index}
      >
        <CardHeader
          showExpandableButton={showExpandable}
          title={renderRow}
          textStyle={{
            paddingRight: 0,
          }}
          style={{
            padding: "0 0",
          }}
          openIcon={<img src={"/assets/imgs/icons/ExpandMore.svg"} />}
          closeIcon={<img src={"/assets/imgs/icons/ExpandLess.svg"} />}
          className={"test order-item-card"}
        />
        <CardText
          expandable={true}
          style={{
            padding: 0,
            margin: "0 0px",
          }}
        >
          <Divider className={"ordershipment_card_divider"} />
          {hasShipments ? (
            <div
              style={{
                margin: "0 0",
                fontWeight: "normal",
              }}
            >
              <h1
                style={{
                  fontSize: 16,
                  color: "#4A5158",
                  fontWeight: "normal",
                }}
              >
                {isMultipleShipmentsOrder ? "Shipments" : "Shipment"}
              </h1>
            </div>
          ) : null}
          {hasShipments ? (
            <OrderShipmentTable
              shipmentItems={items}
              shipment={shipment}
              isInternal={isInternal}
              onUpdateShipmentItem={onUpdateShipmentItem}
              updateShipment={updateShipment}
              orderItem={orderItem}
            />
          ) : null}
          {hasInventoryHistories ? (
            <div
              style={{
                margin: "0 0",
                fontWeight: "normal",
              }}
            >
              <h1
                style={{
                  fontSize: 16,
                  color: "#4A5158",
                  fontWeight: "normal",
                }}
              >
                DSOD
              </h1>
            </div>
          ) : null}

          {hasInventoryHistories ? (
            <DSODTable
              inventoryItem={inventoryItem}
              states={states}
              countries={countries}
              companyId={companyId}
            />
          ) : null}
        </CardText>
        {openEditOrderItemModal ? (
          <EditOrderItemModal
            orderItem={orderItem}
            onClose={this.handleCloseEditOrderItemModal}
            onSave={this.handleSaveOrderItem}
            onCreated={onUpdateOrderItem}
          />
        ) : null}
      </Card>
    );
  };
}

ProductOrderItem.propTypes = {
  companyId: PropTypes.string,
  items: PropTypes.array.isRequired,
  isReordering: PropTypes.bool,
  isInternal: PropTypes.bool,
  reorderItems: PropTypes.array,
  shipments: PropTypes.array,
  onToggleReorderItem: PropTypes.func,
  onUpdateShipmentItem: PropTypes.func,
  updateShipment: PropTypes.func,
  states: PropTypes.array,
  countries: PropTypes.array,
  inventoryItems: PropTypes.array,
  isPrintActive: PropTypes.any,
  orderItem: PropTypes.object,
  index: PropTypes.number,
  isEditingOrder: PropTypes.bool,
  onUpdateOrderItem: PropTypes.func,
};

export default ProductOrderItem;
