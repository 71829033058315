import React, {Component} from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";
import ChillButton from "../../../shared/ChillButton";
import PageTitle from "../../../shared/PageTitle";
import {ORDER_BUTTON_INTERNAL, ORDER_BUTTON_EXTERNAL} from "../../../../constants/order";

export default class ReorderHeader extends Component {
  renderTitleIcon = () => {
    const {titleIcon, titleMaterialIcon} = this.props;
    if (!titleIcon) return null;
    if (titleMaterialIcon) {
      return (
        <FontIcon
          className="material-icons-outlined pageIndexHeader_icon"
          style={{
            fontSize: 32,
            color: "rgb(32,164,195)",
          }}
        >
          {titleIcon}
        </FontIcon>
      );
    }
    return (
      <img
        src={`/assets/imgs/icons/${titleIcon}.svg`}
        className="pageIndexHeader_icon"
      />
    );
  };

  render() {
    const {
      containerClass,
      title,
      buttonIcon,
      buttonText,
      noButton,
      buttonWidth,
      customOptions,
      buttonClick,
      buttonIsDisabled,
      customElement,
      isConfirmed,
      isInternal,
      confirmOrderModal,
      validateFields,
    } = this.props;

    const handleClick = () => {
      !buttonIsDisabled ? buttonClick() : null;
    };

    return (
      <div
        className={`pageIndexHeader row between-xs middle-xs ${containerClass}`}
      >
        <div className="row start-xs middle-xs">
          {this.renderTitleIcon()}
          <PageTitle text={title} />
        </div>
        <div className="row flex flex-center">
          {customOptions}
          {noButton ? null : (
            <ChillButton
              className="pageIndexHeader_btn"
              name={buttonText}
              height={40}
              width={buttonWidth || 140}
              icon={buttonIcon}
              onClick={handleClick}
              isDisabled={buttonIsDisabled}
            />
          )}
          {!isConfirmed &&
            <ChillButton
              className="pageIndexHeader_btn"
              name={isInternal ? ORDER_BUTTON_INTERNAL : ORDER_BUTTON_EXTERNAL}
              height={40}
              width={140}
              icon="check"
              onClick={() => {
                validateFields(true) && confirmOrderModal();
              }
              }
            />
          }
          {customElement ? customElement : null}
        </div>
      </div>
    );
  }
}

ReorderHeader.propTypes = {
  title: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  titleIcon: PropTypes.string,
  titleMaterialIcon: PropTypes.bool,
  searchFunc: PropTypes.func,
  buttonWidth: PropTypes.number,
  buttonClick: PropTypes.func,
  buttonIsDisabled: PropTypes.bool,
  noButton: PropTypes.bool,
  disableSearch: PropTypes.bool,
  noSearch: PropTypes.bool,
  customOptions: PropTypes.node,
  customElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  isConfirmed: PropTypes.bool,
  isInternal: PropTypes.bool,
  confirmOrderModal: PropTypes.func,
  validateFields: PropTypes.func,
};
