import React, {Component} from "react";
import PropTypes from "prop-types";
import {AsYouType} from "libphonenumber-js";
import {
  isCountryUSAOrCanadaByCode,
  getValidCountryCodeForHistoricalOrders,
} from "../../utils/countryRegionsHelper";

export default class FormattedPhone extends Component {
  getFormattedPhone = () => {
    const {phoneNumber, countryCode = "US"} = this.props;
    if (!phoneNumber) return "";
    const parsedCountryCode = getValidCountryCodeForHistoricalOrders(
      countryCode
    );

    const rawPhone = phoneNumber.replace(/\D/g, "");
    const formatter = new AsYouType(parsedCountryCode);
    formatter.input(rawPhone);
    const formatterNumber = formatter.getNumber();
    return isCountryUSAOrCanadaByCode(parsedCountryCode)
      ? formatterNumber.formatNational()
      : formatterNumber.formatInternational();
  };

  render() {
    const formattedPhone = this.getFormattedPhone();
    return <span title={formattedPhone}>{formattedPhone}</span>;
  }
}

FormattedPhone.propTypes = {
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
};
