import React, {Component} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import {parse} from "query-string";

import ProductDetailHeader from "./Components/ProductDetailHeader";
import ProductDetails from "./Components/ProductDetails";
import ProductDetailTabs from "./Components/ProductDetailTabs";
import {VALID_IMAGE_FORMATS} from "../../../constants/art";
import ModalArchivedProduct from "../Reorders/components/ModalArchivedProduct";

export default class ProductDetailPage extends Component {
  static propTypes = {
    shipments: PropTypes.array,
    inventoryItemsCount: PropTypes.number,
    shipmentsCount: PropTypes.number,
    company: PropTypes.object,
    navigateTo: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    fetchCompany: PropTypes.func.isRequired,
    fetchOrderItemProduct: PropTypes.func.isRequired,
    fetchOrderItem: PropTypes.func.isRequired,
    createReorderItem: PropTypes.func.isRequired,
    createDsodItemsFromInventory: PropTypes.func.isRequired,
    searchInventoryItems: PropTypes.func.isRequired,
    openNotification: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isInternal: PropTypes.bool,
    orderItemProduct: PropTypes.object,
    orderItem: PropTypes.object,
    inventoryItems: PropTypes.array,
    isLoadingInventory: PropTypes.bool,
    isLoadingShipment: PropTypes.bool,
    disableTabs: PropTypes.bool,
    setSearchTerm: PropTypes.func,
    addQueryParam: PropTypes.func,
    searchTerm: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    productShipments: PropTypes.array,
    productShipmentCount: PropTypes.number,
    uploadArtAssignmentImage: PropTypes.func,
    isLoadingArtAssignment: PropTypes.bool,
  };

  componentWillMount() {
    this._fetchInitialData();
  }

  _fetchInitialData = () => {
    const {
      fetchCompany,
      fetchOrderItemProduct,
      match: {params: {company_id: companyId, id}},
    } = this.props;

    fetchCompany(companyId);
    this.searchProductShipments("");

    fetchOrderItemProduct(companyId, id, {
      include:
        "[orderItem.[order,product.productImages],product,fabricColor.[fabric,color],leatherColor.color," +
        "orderItemProductAdornmentLocationArtAssignments.[adornmentLocation,art],art]",
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.orderItemProduct !== this.props.orderItemProduct) {
      const queryParams = parse(this.props.location.search);
      this.searchInventoryItems(queryParams.query, this.props);

      this.fetchOrderItem();
    }
  }

  fetchOrderItem = () => {
    const {orderItemProduct, fetchOrderItem} = this.props;
    if (!orderItemProduct) return;
    fetchOrderItem(
      orderItemProduct.orderItemId,
      "[artThreadColorAssignment,inventoryItem,product]"
    );
  };

  searchProductShipments = (searchTerm, nextProps, page = 1) => {
    const {
      searchProductShipments,
      match: {params: {company_id: companyId, id}},
    } =
      nextProps || this.props;

    searchProductShipments(searchTerm, companyId, id, {
      page,
      perPage: 7,
      include: "order",
      sort: " ",
    });
  };

  searchInventoryItems = (searchTerm, nextProps, page) => {
    if (!this.props.orderItemProduct) return;

    const props = nextProps || this.props;
    const {
      orderItemProduct: {productId, art, fabricAvailableColorId},
      searchInventoryItems,
      match: {params: {company_id: companyId}},
    } = props;

    const query = [
      `"orderItem:orderItemProduct";product_id,eq,${productId}`,
      `"orderItem:orderItemProduct";fabric_available_color_id,eq,${fabricAvailableColorId}`,
    ];

    if (art) {
      query.push(`art_revision_id,eq,${lodash.get(art, "id")}`);
    }

    searchInventoryItems(companyId, searchTerm, {
      include:
        "[orderItem.[product.productImages,fabricColor.[fabric,color],orderItemProduct." +
        "[art,orderItemProductAdornmentLocationArtAssignments.adornmentLocation]," +
        "artThreadColorAssignment],order]",
      filter: query.join("|"),
      page,
    });
  };

  hasInventoryItems = () => {
    const {inventoryItems} = this.props;
    return inventoryItems && inventoryItems.length;
  };

  isDSODDisable = () => {
    if (!this.hasInventoryItems()) return;

    const {inventoryItems} = this.props;
    const hasInventory = inventoryItem =>
      inventoryItem.quantity <= inventoryItem.usedQuantity;
    return inventoryItems.every(hasInventory);
  };

  handleSubmitDSOD = () => {
    const {company, history, inventoryItems} = this.props;
    const firstInventory = inventoryItems.find(
      inventory => inventory.quantity - inventory.usedQuantity > 0
    );
    if (!firstInventory) return;

    this.props.createDsodItemsFromInventory(
      company.id,
      null,
      [firstInventory.id],
      {},
      () => {
        history.push(`/companies/${company.id}/dsod`);
      }
    );
  };

  handleSubmitReorder = () => {
    const {company, orderItem} = this.props;
    this.props.createReorderItem(company.id, [orderItem.id]);
  };

  uploadArtAssignmentImage = (file, id) => {
    const {match: {params: {id: orderItemProductId}}} = this.props;
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      this.props.openNotification(
        "Uploaded file is not a valid input image. Only png, jpg and jpg"
      );
    }
    this.props.uploadArtAssignmentImage(orderItemProductId, id, file);
  };

  render() {
    const {
      isLoading,
      isInternal,
      company,
      inventoryItems,
      inventoryItemsCount,
      shipmentsCount,
      openNotification,
      orderItemProduct,
      isLoadingInventory,
      isLoadingShipment,
      isLoadingArtAssignment,
      searchTerm,
      setSearchTerm,
      addQueryParam,
      location,
      disableTabs,
      match: {params: {order_id: orderId}},
      productShipments,
      productShipmentCount,
    } = this.props;

    const cngReferenceNumber = lodash.get(
      orderItemProduct,
      "orderItem.order.cngReferenceNumber",
      ""
    );

    const middletTwo = disableTabs
      ? `Order#${cngReferenceNumber}`
      : "Inventory";
    const middleTwoLink = disableTabs ? `orders/${orderId}` : "";
    const pageName = disableTabs ? "Order Item Details" : "Product";
    const queryParams = parse(location.search);
    const {match: {params: {id: orderItemProductId}}} = this.props;
    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        smallTop={true}
        subHeaderText={company && company.name}
      >
        <BreadCrumbs
          rootName={isInternal ? "Companies" : "Company"}
          rootUrl={"companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          middletwo={middletTwo}
          middletwoLink={middleTwoLink}
          pageName={pageName}
        />
        <Card className="margin-bottom-80">
          {orderItemProduct && <ModalArchivedProduct orderItemProduct={orderItemProduct} orderItemProductId={orderItemProductId} isInternal={isInternal}/> }
          {orderItemProduct && (
            <React.Fragment>
              <ProductDetailHeader
                orderItemProduct={orderItemProduct}
                inventoryItems={inventoryItems}
                onSubmitDSOD={this.handleSubmitDSOD}
                onSubmitReorder={this.handleSubmitReorder}
                isDSODDisable={this.isDSODDisable()}
              />
              <ProductDetails
                isInternal={isInternal}
                orderItemProduct={orderItemProduct}
                uploadArtAssignmentImage={this.uploadArtAssignmentImage}
                isLoadingArtAssignment={isLoadingArtAssignment}
              />
            </React.Fragment>
          )}
          {!disableTabs && (
            <ProductDetailTabs
              tab={Number(queryParams.tab) || 0}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              addQueryParam={addQueryParam}
              inventoryItems={inventoryItems}
              company={company}
              inventoryItemsCount={inventoryItemsCount}
              shipmentsCount={shipmentsCount}
              productShipments={productShipments}
              productShipmentCount={productShipmentCount}
              openNotification={openNotification}
              searchProductShipments={this.searchProductShipments}
              searchInventoryItems={this.searchInventoryItems}
              isInternal={isInternal}
              isLoadingInventory={isLoadingInventory}
              isLoadingShipment={isLoadingShipment}
            />
          )}
        </Card>
      </PageLayout>
    );
  }
}
