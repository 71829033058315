import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import NumberFormat from "react-number-format";
import {FileIcon} from "@drawbotics/file-icons";
import {TextValidator, SelectValidator} from "react-material-ui-form-validator";
import FontIcon from "material-ui/FontIcon";
import Checkbox from "material-ui/Checkbox";

import {artFileDownload} from "../../../../../utils/fileDownload";
import {getDigitizationOptionsValues} from "../../../../../utils/digitizationOptionsValues";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import {isFileTypePreviewable} from "../../../../../utils/artImages";
import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import CNGMenuItem from "../../../../shared/CNGMenuItem";
import CNGToolTip from "../../../../shared/CNGToolTip";
import Typography from "../../../shared/Typography";
import CreateBrandModal from "../../../shared/CreateBrandModal";
import { NO_CHILL_DIGITACION_PRICE_TITLE } from "../../../../../constants/art";


class QuoteItemArtsTable extends Component {
  state = {
    createBrandModalOpen: false,
    artForNewBrand: null,
  };

  componentDidMount() {
    const {isInternal, fetchDigitalizationTypePrices} = this.props;
    if (!isInternal) return;
    fetchDigitalizationTypePrices({
      sort: JSON.stringify([
        {
          field: "adornment_type",
          direction: "ASC",
        },
        {
          field: "complexity_level",
          direction: "ASC",
        },
      ]),
    });
  }

  openCreateBrandModal = artUuid => {
    this.setState({
      createBrandModalOpen: true,
      artForNewBrand: artUuid,
    });
  };

  dismissCreateBrandModal = () => {
    this.setState({
      createBrandModalOpen: false,
      artForNewBrand: null,
    });
  };

  handleBrandCreated = async brand => {
    const {onArtChange, onFieldBlur} = this.props;
    const {artForNewBrand} = this.state;
    await onArtChange(artForNewBrand, "brand")(null, brand);
    this.dismissCreateBrandModal();
    onFieldBlur();
  };

  renderStitchCount = (stitchCount, isLargeFormat, artUuid) => {
    const {
      quoteStatus,
      disableFields,
      isInternal,
      validatorListener,
      onArtChange,
      onFieldBlur,
    } = this.props;
    if (!isInternal || quoteStatus !== "new") {
      return stitchCount ? `${stitchCount} K` : "";
    }
    return (
      <div className="flex">
        <NumberFormat
          key={artUuid}
          disabled={disableFields}
          thousandSeparator={true}
          allowNegative={false}
          format="###K"
          customInput={TextValidator}
          validators={["required"]}
          errorMessages={["this field is required"]}
          validatorListener={validatorListener}
          name="stitchCount"
          type="text"
          value={stitchCount}
          style={{width: 100, marginRight: 24}}
          inputStyle={{
            width: 100,
            color: "#747D86",
          }}
          onChange={event =>
            onArtChange(artUuid, "stitchCount")(
              null,
              event.target.value.replace(/\D/g, "")
            )
          }
          onBlur={onFieldBlur}
        />
        <Checkbox
          labelStyle={{whiteSpace: "nowrap"}}
          label="Lrg. Format"
          checked={isLargeFormat}
          onCheck={() =>
            onArtChange(artUuid, "isLargeFormat")(null, !isLargeFormat)
          }
          onBlur={onFieldBlur}
        />
      </div>
    );
  };

  getDigitizationOptions = digitizationTypePrices => {
    return digitizationTypePrices.map(digitizationTypePrice => {
      const level = digitizationTypePrice.complexityLevel
        ? digitizationTypePrice.complexityLevel
        : "";
      const price = digitizationTypePrice.price
        ? getCurrencyFormattedNumber(digitizationTypePrice.price)
        : "";
      const text = `${digitizationTypePrice.adornmentType} ${level} ${price}`;
      return (
        <CNGMenuItem
          key={digitizationTypePrice.key}
          value={digitizationTypePrice.key}
          primaryText={text}
        />
      );
    });
  };

  renderDigitization = (digitization, artUuid, alreadyRendered) => {
    const {
      quoteStatus,
      disableFields,
      isInternal,
      validatorListener,
      onArtChange,
      onFieldBlur,
    } = this.props;
    if (alreadyRendered) {
      return <FontIcon className="material-icons">remove</FontIcon>;
    }
    if (!isInternal || quoteStatus !== "new") {
      if(!digitization) return null;
      return digitization.price > 0 ? getCurrencyFormattedNumber(digitization.price) : NO_CHILL_DIGITACION_PRICE_TITLE;
      // return getCurrencyFormattedNumber(digitization ? digitization.price : 0);
    }
    const digitizationTypePrices = getDigitizationOptionsValues(
      _get(this.props, "digitalizationTypePrices", [])
    );
    return (
      <SelectValidator
        disabled={disableFields}
        menuStyle={{borderRadius: "0px !important"}}
        style={{width: 240}}
        inputStyle={{
          width: 240,
          color: "#747D86",
        }}
        validators={["required"]}
        errorMessages="this field is required"
        validatorListener={validatorListener}
        name="digitization"
        value={digitization ? digitization.key : ""}
        onChange={async (event, index, value) => {
          const digitization = digitizationTypePrices.find(
            type => type.key === value
          );
          await onArtChange(artUuid, "digitization")(null, digitization);
          onFieldBlur();
        }}
        selectionRenderer={value => {
          const digitizationTypePrice = digitizationTypePrices.find(
            ({key}) => key === value
          );

          return digitizationTypePrices && digitizationTypePrice.price > 0 ? getCurrencyFormattedNumber(digitizationTypePrice.price) : NO_CHILL_DIGITACION_PRICE_TITLE;
        }}
      >
        {this.getDigitizationOptions(digitizationTypePrices)}
      </SelectValidator>
    );
  };

  getBrandOptions = () => {
    const {brands} = this.props;
    if (!brands) return;
    return brands.map(brand => (
      <CNGMenuItem
        key={brand.id}
        value={brand.id}
        primaryText={brand.name}
        title={brand.name}
        style={{width: 140, overflow: "hidden", textOverflow: "ellipsis"}}
        className="brand-select-menu-item"
      />
    ));
  };

  renderBrand = (brand, artUuid) => {
    const {brands, validatorListener, onArtChange, onFieldBlur} = this.props;
    return (
      <SelectValidator
        style={{width: 140}}
        menuStyle={{borderRadius: "0px !important", width: 140}}
        inputStyle={{
          width: 140,
          color: "#747D86",
        }}
        iconStyle={{right: 10}}
        validators={["required"]}
        errorMessages="this field is required"
        validatorListener={validatorListener}
        name="brand"
        value={brand ? brand.id : ""}
        onChange={async (event, index, value) => {
          if (value === "new") {
            this.openCreateBrandModal(artUuid);
            return;
          }
          const brand = brands.find(({id}) => id === value);
          await onArtChange(artUuid, "brand")(null, brand);
          onFieldBlur();
        }}
      >
        <CNGMenuItem key="new" value="new" primaryText="New Brand" />
        {this.getBrandOptions()}
      </SelectValidator>
    );
  };

  getArtFrom = uuid => {
    const {quoteArts, companyArts} = this.props;
    let art;
    if (companyArts) {
      art = companyArts.find(art => art.id === uuid);
      if (art) return art;
    }
    if (!art) {
      art = quoteArts.find(art => art.uuid === uuid);
    }
    return art;
  };

  getAdornmentDetails = art => {
    const {pageStyle} = this.props;
    if (!art) return;

    const {internalIdentifier, details} = art;

    if (!internalIdentifier) return;

    switch (internalIdentifier) {
    case "embroidery_large_format":
    case "embroidery":
      return (
        <div className="flex flex-wrap flex-row align-items-center">
          {`${details.stitchCount}K`}
          <span style={pageStyle.separator}>|</span>
          {`${details.width}" x ${details.height}"`}
          <span style={pageStyle.separator}>|</span>
          {details.threadColors}
        </div>
      );
    case "etch_and_dye":
    case "patch":
      return (
        <div className="flex flex-wrap flex-row align-items-center">
          {`${details.dyeColor}`} | {`${details.width}" x ${details.height}"`}
        </div>
      );
    case "etching":
    case "name_drop_or_monogram":
      return (
        <div className="flex flex-wrap flex-row align-items-center">
          {`${details.width}" x ${details.height}"`}
        </div>
      );
    default:
      return null;
    }
  };

  handleDownloadFile = art => async () => {
    const {quoteId} = this.props;
    await artFileDownload(quoteId, art.uuid, art.filename);
  };

  renderActions = (art, isQuoteApproved, renderDigitization) => {
    const {
      itemId,
      detailOnly,
      onAddDigitizedArt,
      onUnlinkArt,
      isInternal,
    } = this.props;
    if (!isInternal) return;
    return (
      <React.Fragment>
        {isQuoteApproved && !detailOnly ? (
          <FontIcon
            className="material-icons pointer"
            style={{
              color: "#497E95",
              fontSize: 20,
            }}
            onClick={onAddDigitizedArt && onAddDigitizedArt(art.art)}
          >
            edit
          </FontIcon>
        ) : null}

        {!renderDigitization && !detailOnly ? (
          <FontIcon
            className="material-icons pointer"
            style={{
              color: "#497E95",
              fontSize: 20,
            }}
            onClick={onUnlinkArt && onUnlinkArt(art.art, itemId, art.artIndex)}
          >
            link_off
          </FontIcon>
        ) : null}
      </React.Fragment>
    );
  };

  renderArtRow = art => {
    const {
      quoteStatus,
      pageStyle,
      detailOnly,
      showBrand,
      artDigitizationRenderConfig,
    } = this.props;
    const {adornmentLocation} = art;
    const quoteArt = this.getArtFrom(art.art);
    const isQuoteApproved = !["new", "sent_for_approval"].includes(quoteStatus);
    const renderDigitization = artDigitizationRenderConfig[art.artIndex];
    const adornmentDetails = this.getAdornmentDetails(quoteArt);
    return (
      <CNGTableRow key={art.art}>
        <CNGTableRowColumn
          highlight
          customStyles={{
            width: showBrand ? "10%" : "20%",
          }}
        >
          {quoteArt && (
            <div className="flex flex-center">
              {isFileTypePreviewable(quoteArt.filename) ? (
                <CNGToolTip
                  parentRef={`art-${quoteArt && quoteArt.uuid}`}
                  style={{
                    width: 250,
                    border: "1px solid #BDC6CF",
                  }}
                  wrapperStyle={{display: "flex", width: "100%"}}
                  element={quoteArt}
                >
                  <span style={pageStyle.artFilename}>{quoteArt.filename}</span>
                </CNGToolTip>
              ) : (
                <span style={pageStyle.artFilename}>{quoteArt.filename}</span>
              )}
              {quoteArt.imageUrl &&
                !detailOnly && (
                <FontIcon
                  className="material-icons pointer"
                  style={pageStyle.artIcon}
                  onClick={this.handleDownloadFile(quoteArt)}
                >
                    get_app
                </FontIcon>
              )}
            </div>
          )}
        </CNGTableRowColumn>
        <CNGTableRowColumn
          customStyles={{
            width: "15%",
          }}
        >
          {adornmentLocation && adornmentLocation.location}
        </CNGTableRowColumn>
        {!detailOnly && isQuoteApproved ? (
          <CNGTableRowColumn
            customStyles={{
              width: "20%",
            }}
          >
            {adornmentDetails}
          </CNGTableRowColumn>
        ) : (
          <CNGTableRowColumn>
            {this.renderStitchCount(
              isQuoteApproved && quoteArt.details
                ? quoteArt.details.stitchCount
                : quoteArt.stitchCount,
              quoteArt.isLargeFormat,
              art.art
            )}
          </CNGTableRowColumn>
        )}
        <CNGTableRowColumn
          customStyles={{
            width: isQuoteApproved && !detailOnly ? "15%" : "30%",
          }}
        >
          {this.renderDigitization(
            quoteArt.digitization,
            art.art,
            !renderDigitization
          )}
        </CNGTableRowColumn>
        {!detailOnly &&
          isQuoteApproved && (
          <CNGTableRowColumn
            customStyles={{
              width: "20%",
            }}
          >
            <div className="flex flex-center">
              {quoteArt.digitizedFilename ? (
                <CNGToolTip
                  parentRef={`digitized-art-${quoteArt && quoteArt.uuid}`}
                  style={{
                    width: 250,
                    border: "1px solid #BDC6CF",
                  }}
                  element={{imageUrl: quoteArt.digitizedImageUrl}}
                  position="top"
                >
                  <div
                    className="flex justify-content-between align-items-center z-index-3"
                    style={{
                      ...pageStyle.artFile,
                    }}
                  >
                    <div
                      className="flex align-items-center"
                      style={{overflow: "hidden"}}
                    >
                      {isFileTypePreviewable(quoteArt.digitizedFilename) ? (
                        <img
                          src={quoteArt.digitizedImageUrl}
                          style={pageStyle.artImage}
                        />
                      ) : (
                        <FileIcon
                          filename={quoteArt.digitizedFilename}
                          style={pageStyle.artImage}
                        />
                      )}
                      <Typography
                        component="span"
                        variant="bodyBold"
                        color="secondary.light"
                        className="version--2 secondary-link-style"
                        style={pageStyle.artFileName}
                      >
                        {quoteArt.name}
                      </Typography>
                    </div>
                  </div>
                </CNGToolTip>
              ) : (
                <Typography variant="body" color="red">
                    Add Digitized Art File
                </Typography>
              )}
            </div>
          </CNGTableRowColumn>
        )}
        {showBrand && (
          <CNGTableRowColumn
            customStyles={{
              width: "15%",
            }}
          >
            {this.renderBrand(quoteArt.brand, art.art)}
          </CNGTableRowColumn>
        )}

        <CNGTableRowColumn
          customStyles={{
            width: "7%",
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          {this.renderActions(art, isQuoteApproved, renderDigitization)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  };

  getTableHeaders = () => {
    const {
      quoteStatus,
      detailOnly,
      showBrand,
      artDigitizationRenderConfig,
    } = this.props;
    const isQuoteApproved = !["new", "sent_for_approval"].includes(quoteStatus);
    const showActionColumnHeader =
      !detailOnly &&
      (isQuoteApproved || artDigitizationRenderConfig.some(config => config));
    const stitchCountHeader = `${
      detailOnly && isQuoteApproved ? "Final " : "Estimated "
    } Stitch Count`;
    let headers = [
      {
        columnContent: "Art",
        customStyles: {
          width: showBrand ? "10%" : "20%",
        },
      },
      {
        columnContent: "Art Location",
        customStyles: {
          width: "15%",
        },
      },
    ];

    if (isQuoteApproved && !detailOnly) {
      headers.push(
        {
          columnContent: "Adornment Details",
          customStyles: {
            width: "20%",
          },
        },
        {
          columnContent: "Digitization or Etch Set-Up",
          customStyles: {
            width: "18%",
          },
        },
        {
          columnContent: "Digitized Art File",
          customStyles: {
            width: "20%",
          },
        }
      );
    } else {
      headers.push(stitchCountHeader, {
        columnContent: "Digitization or Etch Set-Up",
        customStyles: {
          width: "37%",
        },
      });
    }
    if (showBrand) {
      headers.push({
        columnContent: "Brand",
        customStyles: {
          width: "15%",
        },
      });
    }
    if (showActionColumnHeader) {
      headers.push({
        columnContent: "",
        customStyles: {
          width: "7%",
        },
      });
    }
    return headers;
  };

  render() {
    const {arts, companyId} = this.props;
    const {createBrandModalOpen} = this.state;
    return (
      <div className="margin-top-8">
        <CNGTable
          tableHeader={this.getTableHeaders()}
          displayRowCheckbox={false}
          className="CNG-table CNG-table--removeHeaderHeight "
        >
          {arts &&
            _sortBy(arts, "adornmentLocation.index").map(this.renderArtRow)}
        </CNGTable>
        <CreateBrandModal
          companyId={companyId}
          open={createBrandModalOpen}
          onBrandCreated={this.handleBrandCreated}
          onClose={this.dismissCreateBrandModal}
        />
      </div>
    );
  }
}

QuoteItemArtsTable.propTypes = {
  arts: PropTypes.array,
  companyArts: PropTypes.array,
  quoteArts: PropTypes.array,
  quoteStatus: PropTypes.string,
  quoteId: PropTypes.string,
  pageStyle: PropTypes.object,
  onArtChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  validatorListener: PropTypes.func,
  onArtSetUp: PropTypes.func,
  disableFields: PropTypes.bool,
  detailOnly: PropTypes.bool,
  isInternal: PropTypes.bool,
  showBrand: PropTypes.bool,
  brands: PropTypes.array,
  companyId: PropTypes.number,
  artDigitizationRenderConfig: PropTypes.array,
  digitalizationTypePrices: PropTypes.array,
  fetchDigitalizationTypePrices: PropTypes.func,
  onAddDigitizedArt: PropTypes.func,
  onUnlinkArt: PropTypes.func,
  itemId: PropTypes.string,
};

export default QuoteItemArtsTable;
