import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _last from "lodash/last";

import {
  CNGTable,
  CNGTableRow,
  CNGTableRowColumn,
} from "../../../../shared/CNGTable";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import {getFormattedDate, getMomentDate} from "../../../../../utils/dates";
import OrderSummary from "../../../../../utils/orderSummary";
import ContentLoader from "../../../../shared/ContentLoader";

export default class OrdersTable extends Component {
  static propTypes = {
    orders: PropTypes.array,
    ordersCount: PropTypes.number,
    searching: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
  };

  _getRelatedOrders = ({items}) => {
    if (!items) return;
    if (items.length <= 0) return;

    const relatedOrders = items.reduce(function(
      relatedOrders,
      {inventoryHistories}
    ) {
      if (inventoryHistories && inventoryHistories.length <= 0) relatedOrders;

      const orders = _map(inventoryHistories, "inventoryItem.order");
      return relatedOrders.concat(orders);
    },
    []);
    return _uniqBy(relatedOrders, "id");
  };

  _renderRelatedOrdes = order => {
    const relatedOrders = this._getRelatedOrders(order);
    return (relatedOrders || []).map(function(relatedOrder) {
      if (!relatedOrder) return;
      return (
        <div className="table-subtext" key={relatedOrder.id}>
          <Link
            to={`/companies/${relatedOrder.companyId}/orders/${
              relatedOrder.id
            }`}
            className="no-link-styles"
            title={relatedOrder.jobName}
          >
            {relatedOrder.cngReferenceNumber}
          </Link>
        </div>
      );
    });
  };

  getPurchaseOrderNumber(order) {
    return order.isDsod ? "N/A" : order.purchaseOrderNumber;
  }

  getPurchaseOrderDate(order) {
    return order.isDsod
      ? getMomentDate(order.createdAt)
      : getMomentDate(order.purchaseOrderDate);
  }

  getOrderTotal(order) {
    const orderSummary = new OrderSummary(
      order.items,
      _get(order, "shipments")
    );
    return order.isDsod
      ? "N/A"
      : getCurrencyFormattedNumber(orderSummary.totalOrderWithShipments);
  }

  getActualShipDate = order => {
    if (!_get(order, "shipments")) return;

    let actualShipDate = order.shipments.actualShipDate;
    if (order.shipments.isMultiple && _get(order, "shipments.items.length")) {
      const {items} = order.shipments;
      const lastItem = _last(items.filter(item => item.actualShipDate));
      actualShipDate = lastItem && lastItem.actualShipDate;
    }

    if (!actualShipDate) {
      if (!order.shipments.actualShipDate) return;
      actualShipDate = order.shipments.actualShipDate;
    }
    return new Date(actualShipDate);
  };

  getPODate = order => {
    if (order.isDsod) return order.submittedOn;
    return order.purchaseOrderDate || order.createdAt;
  };

  _renderRow(order, index) {
    if(!order) return null;
    const order_path = `/companies/${order.companyId}/${
      order.isDsod ? "dsod" : "orders"
    }/${order.id}`;

    const {isDsod, isOpen} = order;
    const dsodDate = new Date(_get(order, "updatedAt"));

    const completedAt = isDsod && !isOpen ? dsodDate : _get(order, "completedAt");
    const PODate = this.getPODate(order);

    return (
      <CNGTableRow key={index}>
        <CNGTableRowColumn highlight={true}>
          <Link
            to={order_path}
            className="no-link-styles"
            title={order.jobName}
          >
            {order.jobName || order.cngReferenceNumber}
            {order.jobName && (
              <div className="table-subtext">{order.cngReferenceNumber}</div>
            )}
          </Link>
          {order.isDsod ? this._renderRelatedOrdes(order) : null}
        </CNGTableRowColumn>
        <CNGTableRowColumn>
          {this.getPurchaseOrderNumber(order)}
        </CNGTableRowColumn>
        <CNGTableRowColumn>{getFormattedDate(PODate)}</CNGTableRowColumn>
        <CNGTableRowColumn>
          {completedAt && getFormattedDate(completedAt)}
        </CNGTableRowColumn>
        <CNGTableRowColumn bold={true}>
          {this.getOrderTotal(order)}
        </CNGTableRowColumn>
      </CNGTableRow>
    );
  }

  render() {
    const header = [
      "CNG Ref. No.",
      "CUSTOMER PO",
      "PO DATE",
      "Date Completed",
      "Order Total",
    ];
    const {orders, ordersCount, isLoading, searching} = this.props;
    if (!ordersCount) {
      return (
        <ContentLoader isLoading={isLoading}>
          <div
            className="flex flex-column align-items-center"
            style={{
              minHeight: "82px",
            }}
          >
            <h2 className="userindex_noUsers">
              No orders {searching ? "found" : "yet"}.
            </h2>
          </div>
        </ContentLoader>
      );
    }

    return (
      <ContentLoader isLoading={isLoading}>
        <CNGTable
          isLoading={isLoading}
          className="order-history-table"
          tableHeader={header}
          displayRowCheckbox={false}
        >
          {orders &&
            orders.map((row, index) => {
              return this._renderRow(row, index);
            })}
        </CNGTable>
      </ContentLoader>
    );
  }
}
