import React, {Component} from "react";
import PropTypes from "prop-types";
import SectionHeading from "../../../shared/SectionHeading";
import UserDetailInfo from "../../Users/components/UserDetailInfo";
import {getCurrencyFormattedNumber} from "../../../../utils/numberFormat";
import OrderSummaryService from "../../../../utils/orderSummary";
import {ORDER_COMPLETED_ID} from "../../../../constants/order";
import lodash from "lodash/object";

const summaryStyle = {
  marginTop: 25,
};

class OrderSummary extends Component {
  _totalDiscount = () => {
    const {orderItems} = this.props;
    return orderItems.reduce((total, orderItem) => {
      return total + orderItem.totalDiscount;
    }, 0);
  };

  _totalEmbroideryCost = () => {
    const {orderItems} = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.totalAdornmentCost,
      0
    );
  };

  _totalNetUnitCost = () => {
    const {orderItems} = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.netUnitCost,
      0
    );
  };

  _totalOrder = () => {
    const {orderItems} = this.props;
    return orderItems.reduce(
      (total, orderItem) => total + orderItem.orderTotal,
      0
    );
  };

  render() {
    const {order, countries, shipments, orderItems} = this.props;
    const orderStatus = lodash.get(order, "stateMachine.state", {});
    if (!(order && shipments)) return <div />;
    const billingCompanyContact = orderStatus === ORDER_COMPLETED_ID ? lodash.get(order, "billingInformation", {}) : order.billingCompanyContact;
    const orderSummary = new OrderSummaryService(
      orderItems,
      shipments && shipments[0]
    );

    return (
      <div className="flex summary" style={summaryStyle}>
        <div className="flex-column billing-info">
          {billingCompanyContact && (
            <SectionHeading
              text={"BILLED TO"}
              marginBottom={17}
              fontWeight={"bold"}
            />
          )}

          {billingCompanyContact && (
            <UserDetailInfo
              order={order}
              userDetail={billingCompanyContact}
              title={billingCompanyContact.name}
              titleFontSize={24}
              titleFontColor={"#0C5371"}
              titleFontWeight={"normal"}
              countries={countries}
              titleTextTransform={"normal"}
              showInvoiceCopy={
                billingCompanyContact.userId !== order.createdUserId
              }
            />
          )}
        </div>
        <div className="flex totals">
          <div>
            <SectionHeading text="Shipping" marginBottom={8} />
            <p className="normal-title">
              {getCurrencyFormattedNumber(orderSummary.totalShipmentCost)}
            </p>
          </div>

          <div>
            <SectionHeading text="Total Unit Cost" marginBottom={8} />
            <p className="normal-title">
              {getCurrencyFormattedNumber(orderSummary.totalUnitCost)}
            </p>
          </div>

          <div>
            <SectionHeading text="Total Disc." marginBottom={8} />
            <p className="normal-title">
              ({getCurrencyFormattedNumber(orderSummary.totalDiscount)})
            </p>
          </div>

          <div>
            <SectionHeading text="Total Adorn. Cost" marginBottom={8} />
            <p className="normal-title">
              {getCurrencyFormattedNumber(orderSummary.totalAdornmentCost)}
            </p>
          </div>

          <div />

          <div>
            <SectionHeading text="Order Total" marginBottom={8} />
            <p className="normal-title">
              {getCurrencyFormattedNumber(
                orderSummary.totalOrder +
                  lodash.get(orderSummary, "totalShipmentCost", 0)
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

OrderSummary.propTypes = {
  shipments: PropTypes.array,
  order: PropTypes.object,
  countries: PropTypes.array,
  orderItems: PropTypes.array,
};

export default OrderSummary;
