import _get from "lodash/get";

function getSewOuts(items) {
  return items.filter(item => item.isSewOut);
}

function getSewOutsWithShipments(items, allShipmentItems) {
  return getSewOuts(items).filter(item => {
    const shipmentItems = allShipmentItems.filter(
      shipmentItem => shipmentItem.orderItemId === Number(item.id)
    );
    return shipmentItems.length > 0;
  });
}

function getSentSewOuts(sewOuts, allShipmentItems) {
  return sewOuts.filter(item => {
    const shipmentItems = allShipmentItems.filter(shipmentItem => {
      return (
        shipmentItem.orderItemId === Number(item.id) &&
        shipmentItem.trackingNumber
      );
    });
    return shipmentItems.length > 0;
  });
}

function getProofs(shipmentItems) {
  return shipmentItems.filter(shipmentItem => shipmentItem.isProof);
}

function getSentProofs(proofs) {
  return proofs.filter(shipmentItem => shipmentItem.trackingNumber);
}

export function orderCanBeSubmitted(order, shipments) {
  const shipmentItems = _get(shipments, "[0].items", []);
  const sewOutsWithShipments = getSewOutsWithShipments(
    _get(order, "items", []),
    shipmentItems
  );
  const proofs = getProofs(shipmentItems);
  return !(sewOutsWithShipments.length < 1 || proofs.length < 1);
}

export function orderCanBeApproved(order, shipments) {
  const shipmentItems = _get(shipments, "[0].items", []);
  const sewOuts = getSewOuts(_get(order, "items", []));
  const hasSewOuts = sewOuts.length > 0;
  const sewOutsSent = getSentSewOuts(sewOuts, shipmentItems);
  const hasSentSewOuts = sewOutsSent.length > 0;
  const proofs = getProofs(shipmentItems);
  const hasProofs = proofs.length > 0;
  const proofsSent = getSentProofs(proofs);
  const hasSentProofs = proofsSent.length > 0;
  return (!hasSewOuts || hasSentSewOuts) && (!hasProofs || hasSentProofs);
}
