import fetchAPI from "../../utils/fetchAPI";
import RequestService from "../../services/RequestService";
import {
  FETCH_PRICE_TABLE_LINKS_REQUEST,
  FETCH_PRICE_TABLE_LINKS_SUCCESS,
  FETCH_PRICE_TABLE_LINKS_ERROR,
} from "../../constants/actionTypes";

export const fetchPriceTableLinks = options => {
  const {delay, ...opts} = options || {};
  return async dispatch => {
    await fetchAPI(
      {
        url: RequestService.buildIndexUrl("price_table_links", null, opts),
        method: "GET",
        types: [
          FETCH_PRICE_TABLE_LINKS_REQUEST,
          FETCH_PRICE_TABLE_LINKS_SUCCESS,
          FETCH_PRICE_TABLE_LINKS_ERROR,
        ],
        delay,
      },
      dispatch
    );
  };
};
