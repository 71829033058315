import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as actions from "../../actions/authActions";
import ForgotPage from "../pages/ForgotPage";

class Forgot extends Component {
  render() {
    return (
      <ForgotPage
        sendForgotEmail={email => this.props.sendForgotEmail(email)}
        loading={this.props.loading}
        error_message={this.props.error_message}
        success_message={this.props.success_message}
      />
    );
  }
}

Forgot.propTypes = {
  loading: PropTypes.bool,
  sendForgotEmail: PropTypes.func.isRequired,
  error_message: PropTypes.string,
  success_message: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    error_message: state.auth.error_message,
    success_message: state.auth.success_message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendForgotEmail: email => dispatch(actions.sendForgotEmail(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
