import React, {Component} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _last from "lodash/last";

import {formStyles} from "../../../../styles/components/formStyles";
import {getFormattedDate} from "../../../../utils/dates";
import {SHIPPING_ACCOUNT_NONE} from "../../../../constants/order";
import AttributeLine from "../../../shared/AttributeLine";
import TrackingNumber from "../../../shared/TrackingNumber";

const artNameStyle = {
  ...formStyles.HalfFieldStyles,
  marginRight: 23,
  width: 151,
};

const smallInputStyles = {
  marginRight: 23,
  width: 120,
};

const artInfo = {
  flex: 1,
  marginTop: 33,
  marginBottom: 10,
  flexWrap: "wrap",
};

export default class OrderInfo extends Component {
  static propTypes = {
    isFullyShipped: PropTypes.func,
    order: PropTypes.object,
    company: PropTypes.object,
    shipments: PropTypes.array,
    onChange: PropTypes.func,
    onFileChange: PropTypes.func,
    isInternal: PropTypes.bool,
    isDSOD: PropTypes.bool,
  };

  renderTrackingNumber = () => {
    const {isInternal} = this.props;
    const shipment = this._getShipment();
    const hasSingleRecipient =
      _get(shipment, "isMultiple") === false && _get(shipment, "recipientId");

    if (hasSingleRecipient)
      return (
        <AttributeLine
          labelText="Tracking Number"
          text={
            <TrackingNumber shipment={shipment}>
              {_get(shipment, "trackingNumber", "")}
            </TrackingNumber>
          }
          style={artNameStyle}
          isInternal={isInternal}
        />
      );
  };

  _renderShipmentInfo = () => {
    const {order, isInternal, isDSOD} = this.props;
    const shipment = this._getShipment();
    const {shipmentAccount, shippingMethod} = shipment || {};

    const shipmentAccountNumber = _get(shipmentAccount, "accountNumber", "");
    const showPaymentTerms = !isDSOD || !shipmentAccountNumber;
    const isShippingAccountNone =
      shipmentAccountNumber === SHIPPING_ACCOUNT_NONE;

    return (
      <div className="flex">
        <AttributeLine
          labelText="Shipping Account"
          text={_get(shipmentAccount, "name", "")}
          style={{
            ...artNameStyle,
            width: isShippingAccountNone ? 200 : artNameStyle.width,
          }}
          isInternal={isInternal}
        />
        {!isShippingAccountNone && (
          <AttributeLine
            labelText="Shipping Company"
            text={_get(shippingMethod, "shippingCarrier.name", "")}
            style={artNameStyle}
            isInternal={isInternal}
          />
        )}
        {!isShippingAccountNone && (
          <AttributeLine
            labelText="Shipping Method"
            text={_get(shippingMethod, "name", "")}
            style={artNameStyle}
            isInternal={isInternal}
          />
        )}
        {!isShippingAccountNone && (
          <AttributeLine
            labelText="Account Number"
            text={shipmentAccountNumber}
            style={artNameStyle}
            isInternal={isInternal}
          />
        )}
        {showPaymentTerms ? (
          <AttributeLine
            labelText="Payment Terms"
            text={_get(order, "paymentTerm.name", "")}
            style={artNameStyle}
            isInternal={isInternal}
          />
        ) : null}
        {this.renderTrackingNumber()}
      </div>
    );
  };

  _getShipment() {
    const {shipments} = this.props;
    if (!shipments) return;
    return shipments[0];
  }

  _getDSODFields() {
    const {order, isInternal} = this.props;
    return (
      <AttributeLine
        labelText="Date of Request"
        text={getFormattedDate(_get(order, "submittedOn"))}
        style={{
          ...smallInputStyles,
          borderBottom: "none",
          width: 104,
        }}
        isInternal={isInternal}
      />
    );
  }

  _getOrderFields() {
    const {order, isInternal} = this.props;
    return (
      <React.Fragment>
        <AttributeLine
          labelText="Customer PO #"
          text={order.purchaseOrderNumber}
          style={artNameStyle}
          isInternal={isInternal}
        />
        <AttributeLine
          labelText="Customer PO Date"
          text={getFormattedDate(_get(order, "purchaseOrderDate"))}
          isInternal={isInternal}
          style={smallInputStyles}
        />
      </React.Fragment>
    );
  }

  getActualShipDate = shipment => {
    if (!shipment) return;

    let actualShipDate = shipment.actualShipDate;
    if (shipment.isMultiple && _get(shipment, "items.length")) {
      const lastItem = _last(
        shipment.items.filter(item => item.actualShipDate)
      );
      actualShipDate = lastItem && lastItem.actualShipDate;
    }

    if (!actualShipDate) {
      if (!shipment.actualShipDate) return;
      actualShipDate = shipment.actualShipDate;
    }

    return new Date(actualShipDate);
  };

  _renderOrderInfo = () => {
    const {order, isInternal, isDSOD} = this.props;
    const completedAt = _get(order, "completedAt");

    return (
      <div className="flex order-info-row">
        <AttributeLine
          id="cng-ref-number"
          labelText="CNG Ref #"
          text={order.cngReferenceNumber}
          style={{
            ...artNameStyle,
            borderBottom: "none",
            width: 92,
          }}
          isInternal={isInternal}
        />{" "}
        {isDSOD ? this._getDSODFields() : this._getOrderFields()}
        <AttributeLine
          labelText="In-Hand Date"
          text={getFormattedDate(_get(order, "inHandDate"))}
          style={smallInputStyles}
          isInternal={isInternal}
        />
        <AttributeLine
          labelText="Est. Ship Date"
          text={getFormattedDate(_get(order, "estimatedShipOn"))}
          style={smallInputStyles}
          isInternal={isInternal}
        />
        {completedAt && (
          <AttributeLine
            labelText="Date Completed"
            text={getFormattedDate(completedAt)}
            style={smallInputStyles}
          />
        )}
        <AttributeLine
          labelText="Requesting User"
          text={_get(order, "createdUser.name")}
          style={smallInputStyles}
          isInternal={isInternal}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="order-form " style={artInfo}>
        {this._renderOrderInfo()}
        <div
          className="section-header"
          style={{
            marginTop: 25,
            marginBottom: 5,
          }}
        >
          Shipping Information
        </div>
        {this._renderShipmentInfo()}
      </div>
    );
  }
}
