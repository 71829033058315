import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {push} from "react-router-redux";

import * as actions from "../../../actions/brandActions";
import {fetchAdornmentTypes} from "../../../actions/adornmentTypeActions";
import {createEmbroideryDetail} from "../../../actions/embroideryDetailActions";
import {createArt} from "../../../actions/artActions";
import {fetchCompany} from "../../../actions/companyActions";
import {fetchAdornmentApplicabilities} from "../../../actions/adornmentApplicabilitiesActions";
import {openNotification} from "../../../actions/notificationActions";

import BrandCreatePage from "../../pages/Brands/BrandCreatePage";

import {VALID_IMAGE_FORMATS} from "../../../constants/art";

class BrandCreate extends Component {
  state = {
    filename: null,
    name: null,
    adornment_type_id: null,
    file: null,
    description: null,
    brandName: "",
    artName: "",
  };

  handleChange = (event, eventValue, value, formName) => {
    let name = formName || event.target.name;

    if (name === "adornment_type_id") {
      this.setState({details: {}});
    }

    this.setState({
      [name]: value || eventValue,
    });
  };

  handleDetailChange = (event, eventValue, value, formName) => {
    const name = formName || event.target.name;
    this.setState({
      details: {
        ...this.state.details,
        [name]: value || eventValue,
      },
    });
  };

  handleFileChange = inputFile => {
    const {currentTarget: {files}} = inputFile;
    const {openNotification} = this.props;
    const file = files[0];
    if (!VALID_IMAGE_FORMATS.includes(file.type)) {
      openNotification(
        "Uploaded file is not a valid input image. Only png, jpg and jpg"
      );
    } else {
      this.setState({file: file, filename: file.name});
    }
  };

  componentWillMount() {
    window.closeSplash();
    const {
      match: {params: {company_id}},
      fetchCompany,
      fetchAdornmentTypes,
      fetchAdornmentApplicabilities,
    } = this.props;
    fetchCompany(company_id);
    fetchAdornmentTypes();
    fetchAdornmentApplicabilities();
  }

  render() {
    return (
      <BrandCreatePage
        createBrand={brand => this.props.createBrand(brand)}
        createArt={art => this.props.createArt(art)}
        handleChange={this.handleChange}
        onFileChange={this.handleFileChange}
        onDetailChange={this.handleDetailChange}
        createEmbroideryDetail={details =>
          this.props.createEmbroideryDetail(details)
        }
        {...this.props}
        {...this.state}
      />
    );
  }
}

BrandCreate.propTypes = {
  fetchAdornmentTypes: PropTypes.func.isRequired,
  fetchAdornmentApplicabilities: PropTypes.func.isRequired,
  createBrand: PropTypes.func,
  createArt: PropTypes.func,
  createEmbroideryDetail: PropTypes.func,
  fetchCompany: PropTypes.func,
  openNotification: PropTypes.func,
  match: PropTypes.object,
  company: PropTypes.object,
  brand: PropTypes.object,
  adornmentTypes: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    brand: state.brands.brand,
    company: state.companies.company,
    isLoading: state.art.isLoading,
    adornmentTypes: state.adornmentTypes.adornmentTypes,
    applicabilities: state.embroideryApplicabilities.embroideryApplicabilities,
    embroideryTypes: state.embroideryTypes.embroideryTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBrand: brand => dispatch(actions.createBrand(brand)),
    createArt: art => dispatch(createArt(art)),
    createEmbroideryDetail: brand => dispatch(createEmbroideryDetail(brand)),
    navigateTo: payload => dispatch(push(payload)),
    fetchCompany: id => dispatch(fetchCompany(id)),
    fetchAdornmentTypes: () => dispatch(fetchAdornmentTypes()),
    fetchAdornmentApplicabilities: () =>
      dispatch(fetchAdornmentApplicabilities()),
    openNotification: message => dispatch(openNotification(message)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandCreate)
);
