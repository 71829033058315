import React, {Component} from "react";
import PropTypes from "prop-types";

import AttributeLine from "../../../shared/AttributeLine";
import {formStyles} from "../../../../styles/components/formStyles";
import FormattedPhone from "../../../shared/FormattedPhone";
import {getCountryRegionsFromCountryCode} from "../../../../utils/countryRegionsHelper";

export default class ContactInfo extends Component {
  static propTypes = {
    countries: PropTypes.array.isRequired,

    companyName: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    state: PropTypes.string,
    countryId: PropTypes.number,
    city: PropTypes.string,
    zipCode: PropTypes.string,
  };

  getSelectedCountryName(countryId) {
    const {countries} = this.props;
    if (!countries) return "";
    const country = countries.find(({id}) => Number(id) === Number(countryId));
    return country ? country.name : "";
  }

  getSelectedStateName(state, countryCode) {
    if (!countryCode || !state) return "";
    const regions = getCountryRegionsFromCountryCode(countryCode);
    const region = regions.find(({name}) => name === state);
    return region && region.shortCode ? region.shortCode : state;
  }

  _renderPersonalFields() {
    const twoInputStyle = {
      justifyContent: "space-between",
      width: "61%",
      marginBottom: 23,
    };

    const country = this.props.countries.find(
      country => Number(country.id) === this.props.countryId
    );
    return (
      <div>
        <div className="flex" style={twoInputStyle}>
          <AttributeLine
            labelText="Company Name"
            text={this.props.companyName}
          />
          <AttributeLine labelText="Recipient Name" text={this.props.name} />
        </div>
        <div className="flex" style={twoInputStyle}>
          <AttributeLine
            labelText="Phone"
            text={
              <FormattedPhone
                phoneNumber={this.props.phoneNumber}
                countryCode={country && country.code}
              />
            }
          />
          <AttributeLine labelText="Email" text={this.props.emailAddress} />
        </div>
        <div className="flex" style={twoInputStyle}>
          <AttributeLine labelText="Address 1" text={this.props.address1} />
          <AttributeLine labelText="Address 2" text={this.props.address2} />
        </div>
        <div
          className="flex"
          style={{
            width: "61%",
            marginBottom: 23,
          }}
        >
          <AttributeLine
            labelText="City"
            text={this.props.city}
            style={formStyles.CityTextFieldStyles}
          />
          <AttributeLine
            labelText="State"
            text={this.getSelectedStateName(
              this.props.state,
              country && country.code
            )}
            style={formStyles.quarterSelectStyles}
          />
          <AttributeLine
            labelText="Zip Code"
            text={this.props.zipCode}
            style={{...formStyles.quarterSelectStyles, width: 136}}
          />
          <AttributeLine
            labelText="Country"
            text={this.getSelectedCountryName(this.props.countryId)}
            style={{...formStyles.quarterSelectStyles, width: 160}}
          />
        </div>
      </div>
    );
  }
  render() {
    return <div className="user-form">{this._renderPersonalFields()}</div>;
  }
}
