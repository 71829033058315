import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";

const fetchOrderItemTypesRequest = () =>
  makeCommonAction(types.FETCH_ORDER_ITEM_TYPES_REQUEST);
const fetchOrderItemTypesSuccess = payload =>
  makeCommonAction(types.FETCH_ORDER_ITEM_TYPES_SUCCESS, payload);
const fetchOrderItemTypesError = error =>
  makeCommonAction(types.FETCH_ORDER_ITEM_TYPES_ERROR, error, true);

export function fetchOrderItemTypes() {
  return makeActionCreator({
    endpoint: "/order_item_types",
    fetchOptions: {method: "GET"},
    requestFunc: fetchOrderItemTypesRequest,
    successFunc: fetchOrderItemTypesSuccess,
    errorFunc: fetchOrderItemTypesError,
    error_message: "Error fetching order item types",
  });
}
