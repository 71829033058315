import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ValidableTextField from "../shared/ValidableTextField";
import AuthFooter from "../shared/Footer/AuthFooter";
import CircularProgress from "material-ui/CircularProgress";
import { debounce } from "lodash";
import { validateForm } from "../../utils/formValidations";

export default class ResetPage extends Component {
  state = {
    password: "",
    confirm: "",
    isSuccessfull: false,
    errors: {},
    submitted: false,
  };

  validations = {
    password: {
      format: "any",
      length: [6, 30],
      nullable: false,
    },
    confirm: {
      format: "any",
      length: [6, 30],
      nullable: false,
      shouldMatch: () => this._evaluateConfirmPassword(),
      shouldMatchMessage: "passwords do not match",
    },
  };

  componentWillReceiveProps() {
    this._evaluatePageState(...arguments);
  }

  componentDidMount() {
    window.closeSplash();
  }

  _evaluateConfirmPassword() {
    const { password, confirm } = this.state;
    return password === confirm;
  }

  _evaluatePageState(nextProps) {
    const {
      success_message: nextSuccessMessage,
      error_message: nextErrorMessage,
      loading: nextLoading,
    } = nextProps;
    const { loading: loading } = this.props;

    if (loading && !nextLoading) {
      const isSuccessfull =
        nextSuccessMessage.length && !nextErrorMessage.length ? true : false;
      this.setState({ isSuccessfull: isSuccessfull });
    }
  }

  handleChange(key, value) {
    this.setState({ [key]: value });
  }

  triggerValidations() {
    const errors = this._validate();
    const valid = Object.keys(errors).length === 0;
    if (!valid) return;
    const { resetPassword, token } = this.props;
    const { password, confirm } = this.state;
    resetPassword(token, password, confirm);
  }

  _validate() {
    const errors = validateForm(this);
    this.setState({ errors });
    return errors;
  }

  _validateDebounced = debounce(this._validate, 300);

  _getSaveButtonDisabledState() {
    const { loading } = this.props;
    const { errors, password, confirm } = this.state;
    if (loading) {
      return true;
    }
    if (!password.length || !confirm.length) {
      return true;
    }
    if (password !== confirm) {
      return true;
    }
    return Object.keys(errors).length > 0 ? true : false;
  }

  _renderForm() {
    const { password, confirm, errors } = this.state;
    const { loading, error_message: errorMessage } = this.props;
    const containerStyle = {
      height: "calc(100% - 80px)",
      display: "flex",
      justifyContent: "center",
    };
    return (
      <div className="auth-page_mid" style={containerStyle}>
        <img className="auth-page_chillLogo" src="./assets/imgs/ChillNGo.svg" />
        <div className="auth-page_input">
          <ValidableTextField
            isPassword
            style={{
              marginRight: 0,
            }}
            name="password"
            label="Password"
            value={password}
            errorText={errors.password}
            handleChange={(_, value) => this.handleChange("password", value)}
            validate={() => this._validateDebounced()}
          />
        </div>

        <div className="auth-page_input">
          <ValidableTextField
            isPassword
            style={{
              marginRight: 0,
            }}
            name="confirm"
            label="Confirm"
            value={confirm}
            errorText={errors.confirm}
            handleChange={(_, value) => this.handleChange("confirm", value)}
            validate={() => this._validateDebounced()}
          />
        </div>
        <div className="auth-page-btn-container">
          <button
            className="chill-button btn-1 btn-icon auth-page_btn"
            disabled={this._getSaveButtonDisabledState()}
            onClick={() => this.triggerValidations()}
          >
            {loading ? (
              <div>
                <div className="auth-button-progress-container">
                  <CircularProgress
                    size={24}
                    thickness={4}
                    color="rgba(255, 255, 255, 0.5)"
                  />
                </div>
                <span>Loading...</span>
              </div>
            ) : (
              <div className="auth-page_btn-icon-label-wrapper">
                <span>UPDATE PASSWORD</span>
              </div>
            )}
          </button>
        </div>

        {errorMessage && (
          <div className="error-text auth-page_error">{errorMessage}</div>
        )}
      </div>
    );
  }

  _renderNextSteps() {
    const successfullMessageStyles = {
      padding: "3em",
      maxWidth: "485px",
      textAlign: "center",
    };
    return (
      <div className="auth-page_mid">
        <img className="auth-page_chillLogo" src="./assets/imgs/ChillNGo.svg" />
        <p style={successfullMessageStyles}>
          Password reset completed, proceed to the login page by clicking the
          button below
        </p>

        <p>
          Back to Log in by clicking {" "}
          <Link to="/login" className="link-text">
            here
          </Link>
        </p>
      </div>
    );
  }

  render() {
    const { isSuccessfull } = this.state;
    return (
      <div>
        <div className="app-body auth-page-body">
          <div
            className="content-container auth-page w-100"
            style={{ height: "100%" }}
          >
            <div className="auth-page_top">
              <img
                className="auth-page_top_img"
                src="./assets/imgs/Icon@2x.png"
              />
            </div>

            {!isSuccessfull ? this._renderForm() : this._renderNextSteps()}
          </div>
        </div>
        <AuthFooter style={{ position: "inherit", marginTop: 0 }} />
      </div>
    );
  }
}

ResetPage.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
  error_message: PropTypes.string,
  success_message: PropTypes.string,
  error: PropTypes.bool,
};
