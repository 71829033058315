import * as actions from "../constants/actionTypes";
import initialState from "./initialState";

export default function dsodReducer(
  state = initialState.dsodItems,
  {type, payload}
) {
  switch (type) {
  case actions.CREATE_DSOD_ITEMS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_DSOD_ITEMS_SUCCESS:
    return {
      ...state,
      dsodItems: payload,
      isLoading: false,
    };
  case actions.CREATE_DSOD_ITEMS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.FETCH_DSOD_ITEMS_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.FETCH_DSOD_ITEMS_SUCCESS:
    return {
      ...state,
      dsodItems: payload,
      isLoading: false,
    };
  case actions.FETCH_DSOD_ITEMS_ERROR:
    return {
      ...state,
      error: payload.error,
      error_message: payload.errorMessage,
      isLoading: false,
    };
  case actions.CREATE_DSOD_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.CREATE_DSOD_ITEM_SUCCESS:
    return {
      ...state,
      dsodItems: [...state.dsodItems, payload],
      isLoading: false,
    };
  case actions.CREATE_DSOD_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.UPDATE_DSOD_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.UPDATE_DSOD_ITEM_SUCCESS:
    return {
      ...state,
      dsodItem: payload,
      dsodItems: state.dsodItems.map(dsodItem => {
        if (Number(dsodItem.id) === Number(payload.id)) return payload;
        return dsodItem;
      }),
      isLoading: false,
    };
  case actions.UPDATE_DSOD_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };

  case actions.DELETE_DSOD_ITEM_REQUEST:
    return {
      ...state,
      isLoading: true,
    };
  case actions.DELETE_DSOD_ITEM_SUCCESS:
    return {
      ...state,
      dsodItem: null,
      dsodItems: state.dsodItems.filter(dsodItem => dsodItem.id !== payload),
      isLoading: false,
    };
  case actions.DELETE_DSOD_ITEM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload.error,
      error_message: payload.error_message,
    };
  default:
    return state;
  }
}
