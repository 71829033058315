import React, {Component} from "react";
import TextField from "material-ui/TextField";
import PropTypes from "prop-types";

import CNGCard from "../../../../shared/CNGCard";
import {getCurrencyFormattedNumber} from "../../../../../utils/numberFormat";
import lodash from "lodash";
import {
  formStyles,
  TextFieldSmallLabelStyles,
} from "../../../../../styles/components/formStyles";

const closeIconStyle = {
  color: "#0C5371",
  fontWeight: "300",
  marginLeft: 4,
};

const BeforeCardTitle = {
  color: "#747D86",
  fontSize: 12,
  display: "block",
  lineHeight: 2,
};

const RemoveStyle = {
  cursor: "pointer",
  color: "#0C5271",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 14,
};

const ItemStyle = {
  header: {
    fontWeight: "bold",
    color: "#929DA8",
    fontSize: 12,
    textTransform: "uppercase",
  },

  data: {
    color: "#747D86",
    fontSize: 14,
    lineHeight: "20px",
    height: 18,
  },
};

export default class DiscountItemCard extends Component {
  static propTypes = {
    reorderItem: PropTypes.object,
    archiveOrderItem: PropTypes.func,
  };

  handleArchiveOrderItem = async () => {
    const {
      archiveOrderItem,
      reorderItem,
      openNotification,
      onItemArchived,
    } = this.props;

    try {
      await archiveOrderItem(reorderItem.id);
      openNotification("Order item was archived successfully");
      if (onItemArchived) onItemArchived();
    } catch (error) {
      openNotification("Error archiving Order item");
    }
  };

  _topRightContent = () => {
    const {reorderItem} = this.props;
    const removeOption = (
      <span
        style={RemoveStyle}
        key="remove"
        className="discount--remove"
        onClick={this.handleArchiveOrderItem}
      >
        Remove
        <span key="remove-icon" style={closeIconStyle}>
          X
        </span>
      </span>
    );

    const quantityForm = (
      <TextField
        key="qty-discount"
        floatingLabelText="Quantity"
        type="text"
        name="orderQuantity"
        disabled={true}
        value={reorderItem && reorderItem.orderQuantity}
        onChange={this.handleChange}
        floatingLabelStyle={TextFieldSmallLabelStyles}
        underlineStyle={formStyles.noUnderlineStyle}
        onBlur={this.handleUpdateOrderItem}
        style={{
          ...formStyles.quarterSelectInputStyles,
          marginRight: 32,
        }}
      />
    );
    return [quantityForm, removeOption];
  };

  _performOrDash = (value, callback) => {
    if (!value || value === 0) return "-";

    return callback(value);
  };

  renderItem = () => {
    const {reorderItem} = this.props;

    return (
      <div
        className="flex justify-content-between"
        style={{
          width: "66%",
          marginBottom: 26,
          marginLeft: 10,
        }}
      >
        <div>
          <div style={ItemStyle.header} />
          <div
            style={{
              ...ItemStyle.dataLink,
            }}
          />
        </div>
        <div>
          <div style={ItemStyle.header}>Unit cost</div>
          <div style={ItemStyle.data} className="unit-cost">
            {this._performOrDash(
              reorderItem.unitCostValue,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header} />
          <div style={ItemStyle.data} />
        </div>
        <div>
          <div style={ItemStyle.header} />
          <div style={ItemStyle.data} />
        </div>
        <div>
          <div style={ItemStyle.header}>Net unit discount</div>
          <div style={ItemStyle.data} className="net-unit-discount">
            {this._performOrDash(
              reorderItem.totalUnitCost,
              getCurrencyFormattedNumber
            )}
          </div>
        </div>
        <div>
          <div style={ItemStyle.header} />
          <div style={ItemStyle.data} />
        </div>
        <div>
          <div style={ItemStyle.header} />
          <div style={ItemStyle.data} />
        </div>
      </div>
    );
  };

  render() {
    const title = (
      <div>
        <span style={BeforeCardTitle}>Discount</span>
        <span className="discount--title">
          {lodash.get(this.props, "reorderItem.notes")}
        </span>
      </div>
    );

    return (
      <CNGCard
        title={title}
        topRightContent={this._topRightContent()}
        headerStyle={{
          alignItems: "center",
        }}
        style={{
          paddingBottom: 1,
          paddingTop: 49,
          height: 220,
          fontWeight: "normal",
        }}
      >
        <div>{this.renderItem()}</div>
      </CNGCard>
    );
  }
}
