import React, {Component} from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";
import PageTitle from "./PageTitle";
import ChillButton from "./ChillButton";

import {formStyles} from "../../styles/components/formStyles";

const customerFieldStyles = {
  color: "#747D86",
};
const customerInputStyles = {
  width: "100%",
  fontSize: 32,
};

const editInputContainerStyle = {};

const pencilIconStyle = {
  width: 12,
};

export default class EditInputAttribute extends Component {
  static propTypes = {
    formatLabel: PropTypes.func,
    onChange: PropTypes.func,
    initialValue: PropTypes.node,
    onSave: PropTypes.func,
    onToggleEditable: PropTypes.func,
    containerStyle: PropTypes.object,
    textStyle: PropTypes.object,
    fieldStyle: PropTypes.object,
    width: PropTypes.number,
    hideUnderline: PropTypes.bool,
    maxLength: PropTypes.number,
    validateWith: PropTypes.string,
    value: PropTypes.string,
  };

  state = {
    text: null,
    editMode: false,
  };

  getText = () => {
    const {initialValue, value} = this.props;
    return value || initialValue;
  };

  constructor(props) {
    super(props);
    this.state = {text: this.getText()};
  }

  componentDidUpdate(prevProps) {
    const {initialValue} = this.props;
    if (initialValue !== prevProps.initialValue) {
      this.setState({text: this.getText()});
    }
  }

  saveHandler = event => {
    if (event) event.preventDefault();
    const {initialValue, onSave} = this.props;
    const {text} = this.state;
    if (text !== initialValue) {
      onSave(text);
    }

    this._toggleEditMessage();
  };

  _toggleEditMessage = () => {
    const {onToggleEditable} = this.props;
    const editable = !this.state.editMode;
    this.setState({
      editMode: editable,
    });
    if (onToggleEditable) onToggleEditable(editable);
  };

  _updateInputHandler = (event, text) => {
    const {maxLength, validateWith, onChange} = this.props;
    event.preventDefault();
    if (maxLength && text.length > maxLength) return;
    if (validateWith && !RegExp(validateWith).test(text)) return;

    this.setState({text});
    onChange && onChange(text);
  };

  _renderEditingCustomerMessage() {
    const {containerStyle, textStyle, fieldStyle} = this.props;

    return (
      <div className="row between-xs w-100">
        <form
          style={{width: "90%", ...containerStyle}}
          onSubmit={this.saveHandler}
        >
          <TextField
            type="text"
            name="customer_message"
            value={this.state.text}
            onChange={this._updateInputHandler}
            style={{...customerFieldStyles, width: "100%", ...fieldStyle}}
            inputStyle={{...customerInputStyles, ...textStyle}}
            floatingLabelStyle={formStyles.TextFieldLabelStyles}
            underlineStyle={formStyles.underlineStyle}
          />
        </form>
        <div className="row middle-xs">
          <ChillButton
            height={30}
            width={30}
            marginLeft={0}
            marginRightIcon={0}
            widthIcon={16}
            icon={"check"}
            onClick={this.saveHandler}
            name=""
          />
        </div>
      </div>
    );
  }
  _renderMessageEditButton() {
    const {textStyle, hideUnderline, formatLabel, initialValue} = this.props;
    const underlineStyle = hideUnderline
      ? {}
      : {borderBottom: "1px solid #BDC6CF"};

    let label = initialValue || "";
    if (formatLabel) {
      label = formatLabel(label);
    }

    return (
      <div className="row between-xs w-100" style={underlineStyle}>
        <PageTitle text={label} styles={textStyle} />
        <div onClick={this._toggleEditMessage} className="pointer flex">
          <img src="/assets/imgs/icons/edit-blue.svg" style={pencilIconStyle} />
        </div>
      </div>
    );
  }

  render() {
    const {editMode} = this.state;
    const {width} = this.props;
    return (
      <div style={{...editInputContainerStyle, width}}>
        {editMode
          ? this._renderEditingCustomerMessage()
          : this._renderMessageEditButton()}
      </div>
    );
  }
}
