import React, {Component} from "react";
import PropTypes from "prop-types";

import PageLayout from "../../shared/Layout/Layouts/PageLayout";
import BreadCrumbs from "../../shared/BreadCrumbs";
import Card from "../../shared/Card";
import BrandForm from "./Components/BrandForm";
import Divider from "../../shared/Divider";
import FormCardHeader from "../../shared/FormCardHeader";

export default class BrandCreatePage extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    createBrand: PropTypes.func.isRequired,
    onDetailChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func,
    openNotification: PropTypes.func,
    navigateTo: PropTypes.func,
    company: PropTypes.object,
    brand: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    adornmentTypes: PropTypes.array,
    applicabilities: PropTypes.array,
    details: PropTypes.object,
    adornment_type_id: PropTypes.number,
    brandName: PropTypes.string.isRequired,
    artName: PropTypes.string.isRequired,
    file: PropTypes.object,
    filename: PropTypes.string,
    selectedArt: PropTypes.object,
    showUploadModal: PropTypes.bool,
  };

  state = {
    submitted: false,
  };

  async createBrand() {
    const {openNotification} = this.props;
    try {
      const {
        brandName,
        artName,
        company: {id: company_id},
        createBrand,
        createArt,
        navigateTo,
        file,
        filename,
        adornment_type_id,
        details,
      } = this.props;
      const newBrand = {
        name: brandName,
        company_id,
      };
      const newArt = {
        name: artName,
        file,
        filename,
        adornment_type_id,
        details,
      };

      const {id: brand_id} = await createBrand(newBrand);
      await createArt({brand_id: brand_id, ...newArt});

      openNotification("Brand saved successfully");
      navigateTo(`/companies/${company_id}/brands`);
    } catch (error) {
      openNotification("Error Creating Brand");
    }
  }

  render() {
    const {
      company,
      isLoading,
      adornmentTypes,
      handleChange,
      applicabilities,
      details,
      onDetailChange,
      adornment_type_id,
      brandName,
      artName,
      file,
      filename,
      onFileChange,
      selectedArt,
      showUploadModal,
    } = this.props;

    return (
      <PageLayout
        isLoading={isLoading}
        loaderEnable={true}
        subHeaderText={company ? company.name : null}
        smallTop={true}
      >
        <BreadCrumbs
          rootName={"Companies"}
          middle={company && company.name}
          middleLink={company && company.id}
          pageName="Create New"
        />
        <Card>
          <div>
            <FormCardHeader
              title="New Brand"
              cancelLink={`/companies/${company && company.id}/brands`}
              buttonText="SAVE"
              buttonIcon="check"
              buttonClick={() => this.setState({submitted: true})}
              buttonHeight={32}
              buttonWidth={84}
            />
            <Divider marginBottom={32} marginTop={24} />
            <BrandForm
              handleChange={handleChange}
              formSubmit={() => this.createBrand()}
              adornmentTypes={adornmentTypes}
              applicabilities={applicabilities}
              details={details}
              onDetailChange={onDetailChange}
              adornment_type_id={adornment_type_id}
              brandName={brandName}
              artName={artName}
              file={file}
              filename={filename}
              selectedArt={selectedArt}
              showUploadModal={showUploadModal}
              onFileChange={onFileChange}
              submitSuccess={() => this.createBrand()}
              submitError={() => this.setState({submitted: false})}
              submitted={this.state.submitted}
            />
          </div>
        </Card>
      </PageLayout>
    );
  }
}
