import * as types from "../constants/actionTypes";
import {makeActionCreator, makeCommonAction} from "./commonActions";
import RequestService from "../services/RequestService";

const searchInventoryItemsRequest = () =>
  makeCommonAction(types.SEARCH_INVENTORY_ITEMS_REQUEST);
const searchInventoryItemsSuccess = payload =>
  makeCommonAction(types.SEARCH_INVENTORY_ITEMS_SUCCESS, payload);
const searchInventoryItemsError = error =>
  makeCommonAction(types.SEARCH_INVENTORY_ITEMS_ERROR, error, true);

const fetchInventoryItemRequest = () =>
  makeCommonAction(types.FETCH_INVENTORY_ITEM_REQUEST);
const fetchInventoryItemSuccess = payload =>
  makeCommonAction(types.FETCH_INVENTORY_ITEM_SUCCESS, payload);
const fetchInventoryError = error =>
  makeCommonAction(types.FETCH_INVENTORY_ERROR, error, true);

const fetchInventoryItemsRequest = () =>
  makeCommonAction(types.FETCH_INVENTORY_ITEMS_REQUEST);
const fetchInventoryItemsSuccess = payload =>
  makeCommonAction(types.FETCH_INVENTORY_ITEMS_SUCCESS, payload);
const fetchInventoryItemsError = error =>
  makeCommonAction(types.FETCH_INVENTORY_ITEMS_ERROR, error, true);

export function searchInventoryItems(
  companyId,
  query,
  options = {include: "[orderItem.product]"}
) {
  return makeActionCreator({
    endpoint: RequestService.buildSearchUrl(
      `companies/${companyId}/inventory_items`,
      options.include,
      query,
      {
        sort: "inventory_items;updated_at",
        ...options,
      }
    ),
    fetchOptions: {method: "GET"},
    requestFunc: searchInventoryItemsRequest,
    successFunc: searchInventoryItemsSuccess,
    errorFunc: searchInventoryItemsError,
    error_message: "Error searching inventory items",
  });
}
export function fetchInventoryItems(
  companyId,
  options = {include: "[orderItem.product]"}
) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `companies/${companyId}/inventory_items`,
      options.include,
      options
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchInventoryItemsRequest,
    successFunc: fetchInventoryItemsSuccess,
    errorFunc: fetchInventoryItemsError,
    error_message: "Error fetching inventory items",
  });
}

export function fetchInventoryByCompany(companyId, id, options = {}) {
  return makeActionCreator({
    endpoint: RequestService.buildIndexUrl(
      `companies/${companyId}/inventory_items/${id}`,
      options.include,
      options
    ),
    fetchOptions: {method: "GET"},
    requestFunc: fetchInventoryItemRequest,
    successFunc: fetchInventoryItemSuccess,
    errorFunc: fetchInventoryError,
    error_message: "Error fetching inventory item",
  });
}
